import React, { useEffect, useState } from 'react';
import './pagination-bars.sass';
import { faAngleDoubleLeft, faMinusCircle, faPlusCircle, faTag, faTrash } from '@fortawesome/pro-solid-svg-icons';
import TintIcon from '../../../../components/interface/icon/icon';
import DropdownButton, { ButtonType } from '../../../../components/interface/dropdown/button/dropdown-button';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router';
import { DropdownHeader } from '../../../../components/interface/dropdown/button/header/dropdown-header';
import DropdownSortList from '../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list';
import { Button as TintButton } from '../../../../components/interface/button/button';
import { CheckboxSelect } from '../../../../components/interface/inputs/checkbox-select/checkbox-select';
import { useDispatch, useSelector } from 'react-redux';
import { setIsLoading, updatePageSize, updateSelectedPosts } from '../../actions/posts.actions';
import { deletePosts, getLastPage, getPostsFromURL, updatePosts } from '../../actions/posts.fat-actions';
import { toast } from 'react-toastify';
import AddTags from '../../../../components/interface/add-tags/add-tags';
import Button from '../../../../components/button';
import { GuardNames } from '../../../../guards/guards-list';
import ConfirmModal, { ConfirmModalResult } from '../../../../components/confirm-modal/confirm-modal';
import { useQuery } from '../../../../components/hooks/use-query/use-query';
import { TintEditorConfig } from '../../utils/tint-editor.config';
import { TintEditorViewState } from '../../containers/tint-editor/tint-editor.container';
import Tooltip from '../../../transform-ui/components/tooltip/tooltip';
import FeatureGuard from '../../../../guards/feature-guard';
import { ReloadPostsButton } from '../reload-posts-button/reload-posts-button';
import { colors } from '../../../../utils/variables/colors';
import { Config } from '../../../../utils/config';

const PaginationBar = ({
  scrollRef,
  getPosts,
  isScrollingDown,
  isAnyFilterSelected,
  isSelectedMenuVisible,
  setIsSelectedMenuVisible,
}) => {
  const { tintId } = useParams();
  const dispatch = useDispatch();
  const session = useSelector(state => state.session);
  const selectedPosts = useSelector(state => state.tintEditorPosts.selectedPosts);
  const pageSize = useSelector(state => state.tintEditorPosts.pageSize);
  const currentPageStart = useSelector(state => state.tintEditorPosts.currentPageStart);
  const currentPageEnd = useSelector(state => state.tintEditorPosts.currentPageEnd);
  const posts = useSelector(state => state.tintEditorPosts.data);
  const links = useSelector(state => state.tintEditorPosts.links);
  const isLastPage = useSelector(state => state.tintEditorPosts.isLastPage);
  const isLoading = useSelector(state => state.tintEditorPosts.isLoading);
  const [tags, setTags] = useState([]);
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState();
  const [isTagMenuOpen, setIsTagMenuOpen] = useState(false);

  const onSelectCheckboxStatusChanged = () => {
    isSelectedMenuVisible ? dispatch(updateSelectedPosts([])) : dispatch(updateSelectedPosts(posts));
    setIsSelectedMenuVisible(!isSelectedMenuVisible);
  };
  const queryParams = useQuery();
  const isTrashState = queryParams.get(TintEditorConfig.urlSearchParamState) === TintEditorViewState.TRASH;

  const paginationButtonsConfig = {
    firstPage: 1,
    previousPage: currentPageStart - pageSize,
    nextPage: currentPageStart + pageSize,
    currentPageNumber: currentPageEnd / pageSize,
  };

  const hasPosts = posts?.length > 0;

  useEffect(() => {
    setIsSelectedMenuVisible(selectedPosts?.length > 0);
    setTags([]);
    setIsTagMenuOpen(false);
  }, [selectedPosts]);

  useEffect(() => {
    if (posts.length <= pageSize) {
      dispatch(getLastPage(links?.next));
    }
  }, [links, dispatch]);

  const definePaginationBarClass = () => {
    return isScrollingDown
      ? 'tint-editor-pagination-bar--collapsed'
      : isAnyFilterSelected
      ? 'tint-editor-pagination-bar--filter-selected'
      : '';
  };

  const pageSizes = [
    { name: '20', value: 20 },
    { name: '40', value: 40 },
    { name: '60', value: 60 },
    { name: '80', value: 80 },
    { name: '100', value: 100 },
    { name: '120', value: 120 },
  ];

  const patchedPosts = patchedData => {
    return selectedPosts.map(post => ({
      ...post,
      attributes: {
        ...post.attributes,
        ...patchedData,
      },
    }));
  };

  const onUpdateFeedCard = ({ newTags }) => {
    dispatch(
      updatePosts({
        updatedPosts: patchedPosts({ tags: newTags }),
      })
    )
      .then(() => {
        setTags(newTags);
      })
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const menuItems = [
    {
      icon: faPlusCircle,
      action: () =>
        dispatch(
          updatePosts({
            updatedPosts: patchedPosts({ status: 'public' }),
          })
        ).catch(() => {
          toast.error('Something went wrong');
        }),
      tooltipDescription: 'Make Selected Posts Public',
    },
    {
      icon: faMinusCircle,
      action: () =>
        dispatch(
          updatePosts({
            updatedPosts: patchedPosts({ status: 'private' }),
          })
        ).catch(() => {
          toast.error('Something went wrong');
        }),
      tooltipDescription: 'Make Selected Posts Private',
    },
    {
      icon: faTag,
      action: () => setIsTagMenuOpen(!isTagMenuOpen),
      tooltipDescription: 'Tag Selected Posts',
      guard: GuardNames.TAGGING,
    },
    {
      icon: faTrash,
      action: () => {
        isTrashState ? setIsConfirmModalVisible(true) : setDeletedPostsStatus();
      },
      tooltipDescription: 'Delete Selected Posts',
    },
  ];

  const navigationItems = [
    {
      icon: faAngleDoubleLeft,
      action: () => {
        dispatch(setIsLoading(true));
        dispatch(getPostsFromURL(links?.first, paginationButtonsConfig.firstPage));
      },
      isDisabled: currentPageStart === 1,
    },
    {
      icon: faAngleLeft,
      action: () => {
        scrollRef.current.scrollIntoView();
        dispatch(setIsLoading(true));
        dispatch(getPostsFromURL(links?.previous, paginationButtonsConfig.previousPage));
      },
      isDisabled: !hasPosts || currentPageStart === 1,
    },
    {
      icon: faAngleRight,
      action: () => {
        scrollRef.current.scrollIntoView();
        dispatch(setIsLoading(true));
        dispatch(getPostsFromURL(links?.next, paginationButtonsConfig.nextPage));
      },
      isDisabled: !hasPosts || currentPageEnd < pageSize || isLastPage || isLoading,
    },
  ];

  const onChangePageSize = selectedSize => {
    dispatch(updatePageSize(selectedSize.value));
  };

  const setDeletedPostsStatus = () =>
    dispatch(
      updatePosts({
        updatedPosts: patchedPosts({ status: 'deleted' }),
      })
    ).catch(() => {
      toast.error('Something went wrong');
    });

  const removePosts = () => {
    const data = selectedPosts.map(post => ({
      id: post.id,
      type: post.type,
    }));

    dispatch(
      deletePosts({
        data,
        teamId: session?.data?.team_id,
      })
    )
      .then(() => dispatch(updateSelectedPosts([])))
      .catch(() => {
        toast.error('Something went wrong');
      });
  };

  const onModalChange = type => {
    switch (type) {
      case ConfirmModalResult.CONFIRM:
        removePosts();
        setIsConfirmModalVisible(false);
        break;

      case ConfirmModalResult.REJECT:
        setIsConfirmModalVisible(false);
        break;

      default:
        return;
    }
  };

  return (
    <div className={`tint-editor-pagination-bar ${definePaginationBarClass()}`}>
      <div className='tint-editor-pagination-bar__inner'>
        <div
          className={`tint-editor-pagination-bar__content ${
            isSelectedMenuVisible ? 'tint-editor-pagination-bar__content--post-selected' : ''
          }`}>
          <CheckboxSelect onClick={onSelectCheckboxStatusChanged} isChecked={isSelectedMenuVisible} />
          <label className='tint-editor-pagination-bar__select-label' onClick={onSelectCheckboxStatusChanged}>
            {selectedPosts.length ? (
              <span>
                {selectedPosts.length} {selectedPosts.length === 1 ? 'Post' : 'Posts'} Selected
              </span>
            ) : (
              'Select All'
            )}
          </label>
          {isSelectedMenuVisible && (
            <TintButton
              type='no-style'
              onClick={onSelectCheckboxStatusChanged}
              className='tint-editor-pagination-bar__deselect'>
              Deselect All
            </TintButton>
          )}
          {isConfirmModalVisible && (
            <ConfirmModal
              confirmButtonType='error'
              isOpen={isConfirmModalVisible}
              description='Selected content will be permanently deleted and cannot be recovered.'
              rightButtonText='Yes, Delete Content'
              leftButtonText='No, Keep Content'
              onChange={onModalChange}
            />
          )}
          <ReloadPostsButton onClick={getPosts} tintId={tintId} />
        </div>
        <FeatureGuard featureName={GuardNames.MODERATE_TINT}>
          <div
            className={`tint-editor-pagination-bar__moderate-wrapper ${
              isSelectedMenuVisible ? 'tint-editor-pagination-bar__moderate-wrapper--collapsed' : ''
            }`}>
            {menuItems.map((item, index) => (
              <FeatureGuard featureName={item.guard ? item.guard : GuardNames.ALL} key={item.tooltipDescription}>
                <div>
                  <Tooltip
                    component={
                      <TintButton
                        type='no-style'
                        key={index}
                        onClick={item.action}
                        className='tint-editor-pagination-bar__icon'>
                        <TintIcon icon={item.icon} />
                      </TintButton>
                    }
                    text={item.tooltipDescription}
                  />
                </div>
              </FeatureGuard>
            ))}
          </div>
        </FeatureGuard>
        {isTagMenuOpen && (
          <div className='tint-editor-pagination-bar__tag'>
            <AddTags tagList={tags} onAddTag={setTags} onDeleteTag={setTags} focusOnInit={true} />
            <TintButton
              text='Done'
              type='primary'
              onClick={() => {
                onUpdateFeedCard({ newTags: tags });
                setIsTagMenuOpen(false);
              }}
            />
          </div>
        )}
        <div
          className={`tint-editor-pagination-bar__navigation ${
            isSelectedMenuVisible ? 'tint-editor-pagination-bar__navigation--post-selected' : ''
          }`}>
          <DropdownButton
            currentItem={pageSizes.find(size => size.value === pageSize)}
            dropdownHeader={props => <DropdownHeader {...props} />}
            dropdownHeaderStyle={{
              color: colors.textSecondary,
              border: `1px solid ${colors.grey}`,
              borderRadius: '4px',
              height: '40px',
              padding: '0 8px',
            }}
            dropdownList={props => <DropdownSortList {...props} />}
            list={pageSizes}
            onChangeValue={onChangePageSize}
            buttonType={ButtonType.BUTTON_NO_STYL}
            iconRight='fa fa-caret-down'
          />
          <span className='tint-editor-pagination-bar__page-count'>per page</span>
          <span
            className={`tint-editor-pagination-bar__current-page ${
              isSelectedMenuVisible ? 'tint-editor-pagination-bar__current-page--post-selected' : ''
            }`}>
            {hasPosts ? `${currentPageStart}-${currentPageEnd}` : ''}
          </span>
          <div className='tint-editor-pagination-bar__navigation-container'>
            {navigationItems.map((item, index) => (
              <Button
                key={index}
                disabled={item.isDisabled}
                onClick={item.action}
                className='tint-editor-pagination-bar__navigation-icon'>
                <TintIcon icon={item.icon} fontSize='16px' />
              </Button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

PaginationBar.propTypes = {};

export default PaginationBar;
