import { SidebarIcons, SidebarItemTypes } from '../utils/meta/consts';
import { SocialFeedsFooterLabels } from './helpers';
import { SocialFeedsSource } from '../../../../../../../types/social-feeds';
import { validationSchemaVesta } from '../utils/validation-schema/vesta-schema';
import {
  SocialFeedsAccountTypes,
  BackendKeys,
  CommunityActivitySources,
  CommunityActivitySourcesTranslations,
} from '../consts';

const initializeActivitySourceCheckedValue = (socialFeed, option) => {
  if (socialFeed) {
    return socialFeed.attributes?.options?.activity_item_types.includes(option) ? option : null;
  } else {
    return option === CommunityActivitySources.SHARE_LINK ? null : option;
  }
};

export const vesta = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext,
    },
    editFooter: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepSave,
    },
    accountSourceType: SocialFeedsSource.VESTA,
    selectedOption: { id: 'mission', title: 'Add Mission' },
    header: 'Add Community Content',
    description: 'Fill out the section below to pull in submitted content from your Mission Hub.',
    formSchema: {
      mission: {
        validationSchema: validationSchemaVesta(),
        submitMapper: values => {
          const checkedActivities = Object.values(CommunityActivitySources).reduce((acc, curr) => {
            const checked = !!values.checked.find(i => i === curr);

            if (checked) {
              acc.push(curr);
            } else {
              acc.filter(e => e !== curr);
            }

            return acc;
          }, []);

          return {
            external_id: values?.mission?.value,
            type: undefined,
            options: {
              activity_item_types: checkedActivities,
            },
          };
        },
        inputs: [
          {
            id: SocialFeedsAccountTypes.MISSION,
            backendId: BackendKeys.EXTERNAL_ID,
            label: 'SELECT MISSION',
            placeholder: 'Select Mission...',
            icon: SidebarIcons.MEGAPHONE,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
          },
          {
            id: 'label',
            type: SidebarItemTypes.LABEL,
            label: 'ACTIVITY ITEM TYPES',
          },
          ...Object.entries(CommunityActivitySources).map(e => {
            const option = e[1];

            return {
              id: option,
              backendId: 'activity_item_types',
              label: CommunityActivitySourcesTranslations[option],
              type: SidebarItemTypes.CHECKBOX,
              defaultValue: initializeActivitySourceCheckedValue,
            };
          }),
        ],
        editInputs: [
          {
            id: 'label',
            type: SidebarItemTypes.LABEL,
            label: 'ACTIVITY ITEM TYPES',
          },
          ...Object.entries(CommunityActivitySources).map(e => {
            const option = e[1];

            return {
              id: option,
              backendId: 'activity_item_types',
              label: CommunityActivitySourcesTranslations[option],
              type: SidebarItemTypes.CHECKBOX,
              defaultValue: initializeActivitySourceCheckedValue,
            };
          }),
        ],
      },
    },
  },
];
