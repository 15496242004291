import React, { Component } from 'react';
import './connect-form-builder-box.sass';

export class ConnectFormBuilderBox extends Component {
  render() {
    return (
      <div onClick={this.props.onClick} className='contest-builder-page__connect-form-builder-box'>
        <span>{this.props.text}</span>
      </div>
    );
  }
}
