import { createPrefixedActionName } from '../../../../services/functions/redux-routines/redux-routines';
import { EXPERIENCE_TRANSLATIONS } from '../../consts';
import store from '../../../../store';
export const TRANSLATION_ACTIONS = {
  STANDARDIZE_TRANSLATIONS: createPrefixedActionName(EXPERIENCE_TRANSLATIONS, 'STANDARDIZE_TRANSLATIONS'),
  UPDATE_TRANSLATION: createPrefixedActionName(EXPERIENCE_TRANSLATIONS, 'UPDATE_TRANSLATION'),
  SAVE_NEW_TRANSLATION: createPrefixedActionName(EXPERIENCE_TRANSLATIONS, 'SAVE_NEW_TRANSLATION'),
  STANDARDIZE_ALL_TRANSLATIONS: createPrefixedActionName(EXPERIENCE_TRANSLATIONS, 'STANDARDIZE_ALL_TRANSLATIONS'),
};

export const standardizeTranslation = (key, value) => {
  const currentLanguage = store && store.getState().languages.currentLanguage.value;
  return {
    type: TRANSLATION_ACTIONS.STANDARDIZE_TRANSLATIONS,
    payload: { key, value, currentLanguage },
  };
};

export const saveTranslationDraft = (key, value) => {
  const currentLanguage = store && store.getState().languages.currentLanguage.value;
  return {
    type: TRANSLATION_ACTIONS.UPDATE_TRANSLATION,
    payload: { key, value, currentLanguage },
  };
};

export const saveNewTranslationDraft = (key, value) => {
  const currentLanguage = store && store.getState().languages.currentLanguage.value;
  return {
    type: TRANSLATION_ACTIONS.SAVE_NEW_TRANSLATION,
    payload: { key, value, currentLanguage },
  };
};

export const standardizeAllTranslations = translationSupport => {
  const experience = store.getState().experience.dataDraft;
  translationSupport.setExperience(experience);
  translationSupport.run();
  return {
    type: TRANSLATION_ACTIONS.STANDARDIZE_ALL_TRANSLATIONS,
  };
};
