import Header from '../../components/experience/page-sections/header/header-section';
import Banner from '../../components/experience/page-sections/banner/banner-section';
import CookieBanner from '../../components/experience/page-sections/cookie-banner/cookie-banner-section';
import Form from '../../components/experience/page-sections/form/form-section';
import Tint from '../../components/experience/page-sections/tint/tint-section';
import Social from '../../components/experience/page-sections/social/social-section';
import Footer from '../../components/experience/page-sections/footer/footer-section';
import Timer from '../../components/experience/page-sections/timer/timer-section';
import BackToTop from '../../components/experience/page-sections/back-to-top/back-to-top';
import Poll from '../../components/experience/page-sections/poll/poll-section';
import PostSection from '../../components/experience/page-sections/post/post-section';
import HeadlineSection from '../../components/experience/page-sections/custom/headline/headline-section';
import ImageSection from '../../components/experience/page-sections/custom/image/image-section';
import VideoSection from '../../components/experience/page-sections/custom/video/video-section';
import React from 'react';
import { pageSectionType } from '../../../../model/page-section-type.model';
import ParagraphSection from '../../components/experience/page-sections/custom/paragraph/paragraph-section';
import CustomButtonSection from '../../components/experience/page-sections/custom/button/button-section';
import CustomSpacerSection from '../../components/experience/page-sections/custom/spacer/spacer-section';
import TagOWarSectionContainer from '../../components/experience/page-sections/tag-o-war/tag-o-war-section-container';
import SocialFeedCounterSection from '../../components/experience/page-sections/social-feed-counter/social-feed-counter-section';
import Logger from '../../../../services/logger/logger';
import SurveySection from '../../components/experience/page-sections/survey/survey-section';
import SurveySectionCounter from '../../components/experience/page-sections/survey/survey-section-counter';
import MultiColumnSection from '../../components/experience/page-sections/multi-column/multi-column-section';
import MediaSection from '../../components/experience/page-sections/media/media-section';
import FormBuilderSection from '../../components/experience/page-sections/form-builder/form-builder-section';

export const elementToComponent = ({
  element,
  pageWidth,
  _id,
  index,
  translations,
  parentSectionIndex,
  addSectionForMultiColumn,
  parentLocation,
  cookieEnabled,
  handleAction,
  isPreviewMode = true,
  isGDPRAllowed,
  isGDPRSeen,
  language,
  teamId,
}) => {
  switch (element.sectionType) {
    case pageSectionType.HEADER:
      return (
        <Header
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.HEADER}
          translations={translations}
        />
      );
    case pageSectionType.BANNER:
      return (
        <Banner
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.BANNER}
          pageWidth={pageWidth}
          translations={translations}
        />
      );

    case pageSectionType.COOKIE_BANNER:
      return (
        (isPreviewMode ? (navigator.userAgent === 'TINT' ? false : cookieEnabled && !isGDPRSeen) : cookieEnabled) && (
          <CookieBanner
            key={_id + index}
            index={index}
            data={element.data}
            pageSection={pageSectionType.COOKIE_BANNER}
            pageWidth={pageWidth}
            translations={translations}
            onGPDRAllow={handleAction}
          />
        )
      );

    case pageSectionType.FOOTER:
      return (
        <Footer
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.FOOTER}
          translations={translations}
        />
      );
    case pageSectionType.FORM_BUILDER:
      return (
        <FormBuilderSection
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.FORM_BUILDER}
          translations={translations}
          isPreviewMode={isPreviewMode}
        />
      );
    case pageSectionType.FORM:
      return (
        <Form
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.FORM}
          pageWidth={pageWidth}
          translations={translations}
        />
      );
    case pageSectionType.SOCIAL:
      return (
        <Social
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.SOCIAL}
          translations={translations}
        />
      );
    case pageSectionType.TINT:
      return (
        <Tint
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.TINT}
          pageWidth={pageWidth}
          translations={translations}
          parentSectionIndex={parentSectionIndex}
          addSectionForMultiColumn={addSectionForMultiColumn}
          isGDPRAllowed={isGDPRAllowed}
          cookieEnabled={cookieEnabled}
          isPreviewMode={isPreviewMode}
        />
      );
    case pageSectionType.TIMER:
      return (
        <Timer
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.TIMER}
          pageWidth={pageWidth}
          translations={translations}
          addSectionForMultiColumn={addSectionForMultiColumn}
        />
      );
    case pageSectionType.BACK_TO_TOP:
      return (
        <BackToTop
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.BACK_TO_TOP}
          pageWidth={pageWidth}
          translations={translations}
        />
      );
    case pageSectionType.POST:
      return (
        <PostSection
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.POST}
          pageWidth={pageWidth}
          translations={translations}
          language={language}
          teamId={teamId}
        />
      );
    case pageSectionType.POLL:
      return (
        <Poll
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.POLL}
          pageWidth={pageWidth}
          translations={translations}
        />
      );
    case pageSectionType.CUSTOM_HEADLINE:
      return (
        <HeadlineSection
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.CUSTOM_HEADLINE}
          pageWidth={pageWidth}
          translations={translations}
          parentSectionIndex={parentSectionIndex}
          addSectionForMultiColumn={addSectionForMultiColumn}
        />
      );
    case pageSectionType.CUSTOM_PARAGRAPH:
      return (
        <ParagraphSection
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.CUSTOM_PARAGRAPH}
          pageWidth={pageWidth}
          translations={translations}
          parentSectionIndex={parentSectionIndex}
          addSectionForMultiColumn={addSectionForMultiColumn}
        />
      );

    case pageSectionType.CUSTOM_BUTTON:
      return (
        <CustomButtonSection
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.CUSTOM_BUTTON}
          pageWidth={pageWidth}
          translations={translations}
          parentSectionIndex={parentSectionIndex}
          addSectionForMultiColumn={addSectionForMultiColumn}
        />
      );
    case pageSectionType.IMAGE:
      return <ImageSection key={_id + index} index={index} data={element.data} pageSection={pageSectionType.IMAGE} />;

    case pageSectionType.VIDEO:
      return (
        <VideoSection
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.VIDEO}
          parentLocation={parentLocation}
        />
      );

    case pageSectionType.MEDIA:
      return (
        <MediaSection
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.MEDIA}
          pageWidth={pageWidth}
          translations={translations}
          parentSectionIndex={parentSectionIndex}
          addSectionForMultiColumn={addSectionForMultiColumn}
          parentLocation={parentLocation}
        />
      );

    case pageSectionType.CUSTOM_SPACER:
      return (
        <CustomSpacerSection
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.CUSTOM_SPACER}
          pageWidth={pageWidth}
          translations={translations}
        />
      );

    case pageSectionType.TAG_O_WAR:
      return (
        <TagOWarSectionContainer
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.TAG_O_WAR}
          pageWidth={pageWidth}
          translations={translations}
        />
      );
    case pageSectionType.SOCIAL_FEED_COUNTER:
      return (
        <SocialFeedCounterSection
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.SOCIAL_FEED_COUNTER}
          pageWidth={pageWidth}
          translations={translations}
        />
      );

    case pageSectionType.SURVEY_COUNTER:
      return (
        <SurveySectionCounter
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.SURVEY_COUNTER}
          pageWidth={pageWidth}
          translations={translations}
        />
      );

    case pageSectionType.SURVEY:
      return (
        <SurveySection
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.SURVEY}
          pageWidth={pageWidth}
          translations={translations}
        />
      );
    case pageSectionType.MULTI_COLUMN:
      return (
        <MultiColumnSection
          key={_id + index}
          index={index}
          data={element.data}
          pageSection={pageSectionType.MULTI_COLUMN}
          pageWidth={pageWidth}
          translations={translations}
        />
      );
    default:
      Logger.error(`Section ${element.sectionType} doesn't exist`);
      return null;
  }
};
