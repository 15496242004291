import { createPrefixedActionName, createRoutine } from '../../../../services/functions/redux-routines/redux-routines';
import { MISSION_HUBS } from '../../../../actions/consts';

export const ACTIONS = {
  GET_MISSIONS: createRoutine(createPrefixedActionName(MISSION_HUBS, 'GET_MISSIONS')),
  POST_MISSION: createRoutine(createPrefixedActionName(MISSION_HUBS, 'POST_MISSION')),
  DUPLICATE_MISSION: createRoutine(createPrefixedActionName(MISSION_HUBS, 'DUPLICATE_MISSION')),
  DELETE_MISSION: createRoutine(createPrefixedActionName(MISSION_HUBS, 'DELETE_MISSION')),
};

export const getMissionsStart = () => ({
  type: ACTIONS.GET_MISSIONS.REQUEST,
});

export const getMissionsSuccess = data => ({
  type: ACTIONS.GET_MISSIONS.SUCCESS,
  payload: data,
});

export const getMissionsError = err => ({
  type: ACTIONS.GET_MISSIONS.FAILURE,
  payload: err,
});

export const postMissionStart = () => ({
  type: ACTIONS.POST_MISSION.REQUEST,
});

export const postMissionSuccess = data => ({
  type: ACTIONS.POST_MISSION.SUCCESS,
  payload: data,
});

export const postMissionError = err => ({
  type: ACTIONS.POST_MISSION.FAILURE,
  payload: err,
});

export const duplicateMissionStart = duplicatedId => ({
  type: ACTIONS.DUPLICATE_MISSION.REQUEST,
  payload: duplicatedId,
});

export const duplicateMissionSuccess = data => ({
  type: ACTIONS.DUPLICATE_MISSION.SUCCESS,
  payload: data,
});

export const duplicateMissionError = err => ({
  type: ACTIONS.DUPLICATE_MISSION.FAILURE,
  payload: err,
});

export const deleteMissionStart = missionId => ({
  type: ACTIONS.DELETE_MISSION.REQUEST,
  payload: missionId,
});

export const deleteMissionSuccess = data => ({
  type: ACTIONS.DELETE_MISSION.SUCCESS,
  payload: data,
});

export const deleteMissionError = err => ({
  type: ACTIONS.DELETE_MISSION.FAILURE,
  payload: err,
});
