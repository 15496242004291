import { ACTIONS } from '../../actions/assets-aggregations/assets-aggregations.actions';
import { AggsTypes } from '../../../../types/assets-aggregations';
import produce from 'immer';

export const initialState = {
  [AggsTypes.EMOTIONS]: [],
  [AggsTypes.MIME_TYPES]: [],
  [AggsTypes.OBJECTS]: [],
  [AggsTypes.SOURCES]: [],
  [AggsTypes.TAGS]: [],
  isFetching: false,
  error: undefined,
};

export const assetsAggregations = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_ASSETS_AGGREGATIONS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case ACTIONS.GET_ASSETS_AGGREGATIONS.SUCCESS:
      return {
        ...state,
        ...action.payload?.reduce((result, item) => {
          return Object.assign(result, { [item.id]: Object.keys(item?.attributes?.buckets) });
        }, {}),
        error: undefined,
        isFetching: false,
      };

    case ACTIONS.GET_ASSETS_AGGREGATIONS.FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };

    case ACTIONS.UPDATE_ASSETS_AGGREGATIONS_TAGS:
      return produce(state, draft => {
        draft.tags = [...new Set([...draft.tags, ...action.payload])];
      });

    default:
      return state;
  }
};
