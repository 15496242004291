import { useEffect, useState } from 'react';

const useClickOutside = (ref, callback) => {
  const [isVisible, setVisibility] = useState(false);

  const handleClick = e => {
    if (ref && ref?.current && ref?.current?.contains?.(e.target)) {
      return;
    }
    setVisibility(false);
    callback && callback();
  };

  const handleBlur = () => {
    if (document.activeElement.tagName === 'IFRAME') {
      setVisibility(false);
      callback && callback();
    }
  };

  useEffect(() => {
    window.addEventListener('mousedown', handleClick);
    window.addEventListener('blur', handleBlur);
    return () => {
      window.removeEventListener('mousedown', handleClick);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  return [isVisible, setVisibility];
};

export default useClickOutside;
