import React from 'react';
import './modal-footer.sass';
import { Button } from '../../../../interface/button/button';

const ModalFooter = ({
  closeButtonText = 'Cancel',
  confirmButtonText = 'Ok',
  onCloseClick,
  onConfirm,
  isDisabled,
  confirmButtonType,
}) => {
  return (
    <footer className='tint-modal-footer'>
      <Button onClick={onCloseClick} action='submit' type='gray' text={closeButtonText} />
      <Button
        onClick={onConfirm}
        isDisabled={isDisabled}
        action='submit'
        type={confirmButtonType}
        text={confirmButtonText}
      />
    </footer>
  );
};
export default ModalFooter;
