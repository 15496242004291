import React from 'react';
import { FIELD_TYPE, transformToSendAble } from '../form-helper';
import InputPrimary from '../../../../../../../components/interface/inputs/input-primary/input-primary';
import ColorParser from '../../../../../../../services/color-parser/color-parser';
import TextareaPrimary from '../../../../../../../components/interface/inputs/textarea-primary/textarea-primary';
import DropdownButton, { ButtonType } from '../../../../../../../components/interface/dropdown/button/dropdown-button';
import { DropdownHeader } from '../../../../../../../components/interface/dropdown/button/header/dropdown-header';
import DropdownSimpleList from '../../../../../../../components/interface/dropdown/lists/simple-list/dropdown-simple-list';
import CheckboxButton, {
  CheckboxButtonType,
} from '../../../../../../../components/interface/inputs/checkbox-button/checkbox-button';

const _getName = ({ e, data, getTranslation }) => {
  return data.content.hasAdvancedSettings && e.key
    ? e.key
    : getTranslation(e.placeholder)
    ? transformToSendAble(getTranslation(e.placeholder))
    : e.placeholder;
};
const _getPlaceholder = ({ e, getTranslation }) => {
  return getTranslation(e.placeholder)
    ? `${getTranslation(e.placeholder)}${e.required === undefined || e.required ? '*' : ''}`
    : '';
};

const renderHiddenInput = ({
  i,
  e,
  data,
  forceErrorVisibility,
  dirty,
  errors,
  values,
  touched,
  handleChange,
  isPreviewMode,
  getTranslation,
}) => {
  return (
    <InputPrimary
      className='input'
      classes='no-margin'
      id={i}
      key={i}
      placeholder={_getPlaceholder({ e, getTranslation })}
      name={_getName({ e, data, getTranslation })}
      dirty={dirty}
      errorColor={ColorParser.defaultColor(data.styles.errorColor)}
      error={errors[Object.keys(values)[i]] || forceErrorVisibility}
      touched={touched[Object.keys(values)[i]] || !!forceErrorVisibility}
      handleChange={handleChange}
      disabled={!isPreviewMode}
      isCustomPlaceholderColor={true}
      value={_getPlaceholder({ e, getTranslation })}
      style={{
        display: 'none',
        border: `1px solid ${
          data.styles.inputBorderColor ? ColorParser.defaultColor(data.styles.inputBorderColor) : '#fff'
        }`,
        color: ColorParser.defaultColor(data.styles.formFieldTextColor),
      }}
    />
  );
};

const renderInput = ({
  i,
  e,
  data,
  forceErrorVisibility,
  dirty,
  errors,
  values,
  touched,
  handleChange,
  isPreviewMode,
  getTranslation,
}) => {
  return (
    <InputPrimary
      className='input'
      id={i}
      key={i}
      placeholder={_getPlaceholder({ e, getTranslation })}
      name={_getName({ e, data, getTranslation })}
      dirty={dirty}
      errorColor={ColorParser.defaultColor(data.styles.errorColor)}
      error={errors[Object.keys(values)[i]] || forceErrorVisibility}
      touched={touched[Object.keys(values)[i]] || !!forceErrorVisibility}
      handleChange={handleChange}
      disabled={!isPreviewMode}
      isCustomPlaceholderColor={true}
      style={{
        border: `1px solid ${
          data.styles.inputBorderColor ? ColorParser.defaultColor(data.styles.inputBorderColor) : '#fff'
        }`,
        color: ColorParser.defaultColor(data.styles.formFieldTextColor),
      }}
    />
  );
};

const renderTextarea = ({
  i,
  e,
  data,
  forceErrorVisibility,
  dirty,
  errors,
  values,
  touched,
  handleChange,
  isPreviewMode,
  getTranslation,
}) => {
  return (
    <TextareaPrimary
      className='textarea'
      id={i}
      key={i}
      placeholder={_getPlaceholder({ e, getTranslation })}
      name={_getName({ getTranslation, e, data })}
      dirty={dirty}
      errorColor={ColorParser.defaultColor(data.styles.errorColor)}
      error={errors[Object.keys(values)[i]] || forceErrorVisibility}
      touched={touched[Object.keys(values)[i]] || !!forceErrorVisibility}
      //handleBlur={handleBlur}
      handleChange={handleChange}
      disabled={!isPreviewMode}
      isCustomPlaceholderColor={true}
      style={{
        border: `1px solid ${
          data.styles.inputBorderColor ? ColorParser.defaultColor(data.styles.inputBorderColor) : '#fff'
        }`,
        color: ColorParser.defaultColor(data.styles.formFieldTextColor),
      }}
    />
  );
};

const renderDropdown = ({
  i,
  e,
  data,
  forceErrorVisibility,
  errors,
  values,
  getTranslation,
  setFieldValue,
  touched,
  key,
}) => {
  const list = e.options.map(el => {
    return {
      value: getTranslation(el.placeholder),
      name: getTranslation(el.placeholder),
    };
  });

  const error =
    (touched[data.content.fields[i].key || getTranslation(e.placeholder)] &&
      errors[data.content.fields[i].key || getTranslation(e.placeholder)]) ||
    forceErrorVisibility;
  const borderColor = error
    ? ColorParser.defaultColor(data.styles.errorColor)
    : data.styles.inputBorderColor
    ? ColorParser.defaultColor(data.styles.inputBorderColor)
    : '#fff';
  const fontColor = error
    ? ColorParser.defaultColor(data.styles.errorColor)
    : ColorParser.defaultColor(data.styles.formFieldTextColor);

  const currentValue = values[key];
  const currentItem = {
    value: currentValue ? currentValue : getTranslation(e.placeholder),
    name: currentValue ? currentValue : `${getTranslation(e.placeholder)}${e.required ? '*' : ''}`,
  };

  return (
    <div className='tint-dropdown-container' key={i}>
      <DropdownButton
        currentItem={currentItem}
        dropdownHeader={props => <DropdownHeader {...props} />}
        dropdownHeaderStyle={{ color: fontColor, borderColor: borderColor }}
        dropdownList={props => <DropdownSimpleList {...props} isDefaultOption={false} />}
        list={list}
        placeholder={_getPlaceholder({ e, getTranslation })}
        iconRight='fa fa-caret-down'
        buttonType={ButtonType.BUTTON_GRAY}
        styles={{
          border: `1px solid ${borderColor}`,
          color: fontColor,
        }}
        onChangeValue={_value => {
          setFieldValue(_getName({ e, data, getTranslation }), _value.value);
        }}
      />
      <span
        style={{ color: ColorParser.defaultColor(data.styles.errorColor) }}
        className='tint-dropdown-container__error'>
        {error}
      </span>
    </div>
  );
};

const renderCheckbox = ({
  i,
  e,
  data,
  forceErrorVisibility,
  errors,
  values,
  getTranslation,
  setFieldValue,
  touched,
}) => {
  const list = e.options.map(el => {
    return {
      value: getTranslation(el.placeholder),
      name: getTranslation(el.placeholder),
    };
  });

  const fieldName = _getName({ e, data, getTranslation });
  const error = (touched[Object.keys(values)[i]] && errors[Object.keys(values)[i]]) || forceErrorVisibility;
  const borderColor = error
    ? ColorParser.defaultColor(data.styles.errorColor)
    : data.styles.inputBorderColor
    ? ColorParser.defaultColor(data.styles.inputBorderColor)
    : '#fff';
  const fontColor = error
    ? ColorParser.defaultColor(data.styles.errorColor)
    : ColorParser.defaultColor(data.styles.formFieldTextColor);

  const _setFormValue = item => {
    const value = list[item].value;
    let _tmpValues;
    if (values[fieldName]) {
      _tmpValues = JSON.parse(values[fieldName]);
    } else {
      _tmpValues = [];
    }
    if (_tmpValues.find(e => e === value)) {
      _tmpValues = _tmpValues.filter(e => e !== value);
    } else {
      _tmpValues.push(value);
    }
    if (_tmpValues.length === 0) {
      _tmpValues = '';
    } else {
      _tmpValues = JSON.stringify(_tmpValues);
    }
    setFieldValue(fieldName, _tmpValues);
  };

  return (
    <div className='tint-checkbox-container' key={i}>
      <span className='tint-checkbox-container__title' style={{ color: fontColor }}>
        {_getPlaceholder({
          e,
          getTranslation,
        })}
      </span>
      {list.map((option, optionI) => {
        const checkboxUID = `${optionI}-${Math.floor(Math.random() * Date.now())}`;
        return (
          <div className='tint-checkbox-container__box' key={optionI}>
            <CheckboxButton
              id={`${checkboxUID}`}
              type={CheckboxButtonType.PRIMARY}
              markColor={borderColor}
              onStatusChange={() => _setFormValue(optionI)}
              borderColor={borderColor}
              color={borderColor}
            />
            <span style={{ color: fontColor }}>{option.name}</span>
          </div>
        );
      })}
      <span
        style={{ color: ColorParser.defaultColor(data.styles.errorColor) }}
        className='tint-dropdown-container__error'>
        {error}
      </span>
    </div>
  );
};

const render = ({
  e,
  i,
  data,
  forceErrorVisibility,
  dirty,
  errors,
  values,
  touched,
  handleChange,
  isPreviewMode,
  getTranslation,
  setFieldValue,
  key,
}) => {
  const renderData = {
    e,
    i,
    data,
    forceErrorVisibility,
    dirty,
    errors,
    values,
    touched,
    handleChange,
    isPreviewMode,
    getTranslation,
    setFieldValue,
    key,
  };

  switch (e.type) {
    case FIELD_TYPE.INPUT:
      return renderInput(renderData);
    case FIELD_TYPE.HIDDEN_INPUT:
      return renderHiddenInput({ ...renderData, isHidden: true });
    case FIELD_TYPE.TEXTAREA:
      return renderTextarea(renderData);
    case FIELD_TYPE.DROPDOWN:
      return renderDropdown(renderData);
    case FIELD_TYPE.CHECKBOX:
      return renderCheckbox(renderData);
  }
};

export const FormFieldRenderer = {
  render,
};
