import { createPrefixedActionName, createRoutine } from '../../../../services/functions/redux-routines/redux-routines';
import { USER_PROFILE } from '../../consts';

export const ACTIONS = {
  CLOSE_CONFIRM_MODAL: createPrefixedActionName(USER_PROFILE, 'CLOSE_CONFIRM_MODAL'),
  OPEN_CONFIRM_MODAL: createPrefixedActionName(USER_PROFILE, 'OPEN_CONFIRM_MODAL'),
  PATCH_USER_PROFILE: createRoutine(createPrefixedActionName(USER_PROFILE, 'PATCH_USER_PROFILE')),
  GET_AUTHENTICATION_FACTORS: createRoutine(createPrefixedActionName(USER_PROFILE, 'GET_AUTHENTICATION_FACTORS')),
  POST_TWO_FACTOR_AUTH: createRoutine(createPrefixedActionName(USER_PROFILE, 'POST_TWO_FACTOR_AUTH')),
  PATCH_TWO_FACTOR_AUTH: createRoutine(createPrefixedActionName(USER_PROFILE, 'PATCH_TWO_FACTOR_AUTH')),
  DELETE_TWO_FACTOR_AUTH: createRoutine(createPrefixedActionName(USER_PROFILE, 'DELETE_TWO_FACTOR_AUTH')),
};

export const patchUsersProfileStart = () => ({
  type: ACTIONS.PATCH_USER_PROFILE.REQUEST,
});

export const patchUsersProfileSuccess = data => ({
  type: ACTIONS.PATCH_USER_PROFILE.SUCCESS,
  payload: data,
});

export const patchUsersProfileError = err => ({
  type: ACTIONS.PATCH_USER_PROFILE.FAILURE,
  payload: err,
});

export const getAuthenticationFactorsStart = () => ({
  type: ACTIONS.GET_AUTHENTICATION_FACTORS.REQUEST,
});

export const getAuthenticationFactorsSuccess = data => ({
  type: ACTIONS.GET_AUTHENTICATION_FACTORS.SUCCESS,
  payload: data,
});

export const getAuthenticationFactorsError = err => ({
  type: ACTIONS.GET_AUTHENTICATION_FACTORS.FAILURE,
  payload: err,
});

export const postTwoFactorAuthStart = () => ({
  type: ACTIONS.POST_TWO_FACTOR_AUTH.REQUEST,
});

export const postTwoFactorAuthSuccess = data => ({
  type: ACTIONS.POST_TWO_FACTOR_AUTH.SUCCESS,
  payload: data,
});

export const postTwoFactorAuthError = err => ({
  type: ACTIONS.POST_TWO_FACTOR_AUTH.FAILURE,
  payload: err,
});

export const pathTwoFactorAuthStart = () => ({
  type: ACTIONS.PATCH_TWO_FACTOR_AUTH.REQUEST,
});

export const pathTwoFactorAuthSuccess = data => ({
  type: ACTIONS.PATCH_TWO_FACTOR_AUTH.SUCCESS,
  payload: data,
});

export const pathTwoFactorAuthError = err => ({
  type: ACTIONS.PATCH_TWO_FACTOR_AUTH.FAILURE,
  payload: err,
});

export const deleteTwoFactorAuthStart = () => ({
  type: ACTIONS.DELETE_TWO_FACTOR_AUTH.REQUEST,
});

export const deleteTwoFactorAuthSuccess = id => ({
  type: ACTIONS.DELETE_TWO_FACTOR_AUTH.SUCCESS,
  payload: id,
});

export const deleteTwoFactorAuthError = err => ({
  type: ACTIONS.DELETE_TWO_FACTOR_AUTH.FAILURE,
  payload: err,
});

export const closeConfirmPasswordModal = () => ({
  type: ACTIONS.CLOSE_CONFIRM_MODAL,
});

export const setConfirmPasswordModalDetails = (isVisible, onConfirmPassword) => ({
  type: ACTIONS.OPEN_CONFIRM_MODAL,
  payload: {
    isVisible,
    onConfirmPassword,
  },
});
