import { createPrefixedActionName, createRoutine } from '../../../../services/functions/redux-routines/redux-routines';

export const ACTIONS = {
  GET_ACTIVE_VISITORS_COUNT: createRoutine(
    createPrefixedActionName('INSIGHTS_PERFORMANCE', 'GET_ACTIVE_VISITORS_COUNT')
  ),
  GET_ENGAGEMENT_CTA_COUNT: createRoutine(createPrefixedActionName('INSIGHTS_PERFORMANCE', 'GET_ENGAGEMENT_CTA_COUNT')),
  GET_ENGAGEMENT_COUNT: createRoutine(createPrefixedActionName('INSIGHTS_PERFORMANCE', 'GET_ENGAGEMENT_COUNT')),
  GET_ENGAGEMENT_IMPRESSIONS: createRoutine(
    createPrefixedActionName('INSIGHTS_PERFORMANCE', 'GET_ENGAGEMENT_IMPRESSIONS')
  ),
  GET_TINT_EMBED_LOCATION: createRoutine(createPrefixedActionName('INSIGHTS_PERFORMANCE', 'GET_TINT_EMBED_LOCATION')),
  GET_ENGAGEMENT_POST_COUNT: createRoutine(createPrefixedActionName('INSIGHTS_PERFORMANCE', 'ENGAGEMENT_POST_COUNT')),
  GET_DAILY_EMBED_VIEWS: createRoutine(createPrefixedActionName('INSIGHTS_PERFORMANCE', 'GET_DAILY_EMBED_VIEWS')),
  GET_DAILY_EMBED_CLICKS: createRoutine(createPrefixedActionName('INSIGHTS_PERFORMANCE', 'GET_DAILY_EMBED_CLICKS')),
  GET_TOP_USED_OMNICHANNEL_TYPES: createRoutine(
    createPrefixedActionName('INSIGHTS_PERFORMANCE', 'GET_TOP_USED_OMNICHANNEL_TYPES')
  ),
  GET_TOP_USED_THEMES: createRoutine(createPrefixedActionName('INSIGHTS_PERFORMANCE', 'GET_TOP_USED_THEMES')),
  GET_ENGAGEMENT_BY_TYPE: createRoutine(createPrefixedActionName('INSIGHTS_PERFORMANCE', 'GET_ENGAGEMENT_BY_TYPE')),
  GET_ENGAGEMENT_PER_DEVICE: createRoutine(
    createPrefixedActionName('INSIGHTS_PERFORMANCE', 'GET_ENGAGEMENT_PER_DEVICE')
  ),
  GET_VISITOR_DEMOGRAPHIC: createRoutine(createPrefixedActionName('INSIGHTS_PERFORMANCE', 'GET_VISITOR_DEMOGRAPHIC')),
};

export const getEngagementCTACountStart = () => ({
  type: ACTIONS.GET_ENGAGEMENT_CTA_COUNT.REQUEST,
});

export const getEngagementCTACountSuccess = data => ({
  type: ACTIONS.GET_ENGAGEMENT_CTA_COUNT.SUCCESS,
  payload: data,
});

export const getEngagementCTACountError = err => ({
  type: ACTIONS.GET_ENGAGEMENT_CTA_COUNT.FAILURE,
  payload: err,
});

export const getActiveVisitorsCountStart = () => ({
  type: ACTIONS.GET_ACTIVE_VISITORS_COUNT.REQUEST,
});

export const getActiveVisitorsCountSuccess = data => ({
  type: ACTIONS.GET_ACTIVE_VISITORS_COUNT.SUCCESS,
  payload: data,
});

export const getActiveVisitorsCountError = err => ({
  type: ACTIONS.GET_ACTIVE_VISITORS_COUNT.FAILURE,
  payload: err,
});

export const getEngagementCountStart = () => ({
  type: ACTIONS.GET_ENGAGEMENT_COUNT.REQUEST,
});

export const getEngagementCountSuccess = data => ({
  type: ACTIONS.GET_ENGAGEMENT_COUNT.SUCCESS,
  payload: data,
});

export const getEngagementCountError = err => ({
  type: ACTIONS.GET_ENGAGEMENT_COUNT.FAILURE,
  payload: err,
});

export const getEngagementPostCountStart = () => ({
  type: ACTIONS.GET_ENGAGEMENT_POST_COUNT.REQUEST,
});

export const getEngagementPostCountSuccess = data => ({
  type: ACTIONS.GET_ENGAGEMENT_POST_COUNT.SUCCESS,
  payload: data,
});

export const getEngagementPostCountError = err => ({
  type: ACTIONS.GET_ENGAGEMENT_POST_COUNT.FAILURE,
  payload: err,
});

export const getTopUsedOmnichannelTypesStart = () => ({
  type: ACTIONS.GET_TOP_USED_OMNICHANNEL_TYPES.REQUEST,
});

export const getTopUsedOmnichannelTypesSuccess = data => ({
  type: ACTIONS.GET_TOP_USED_OMNICHANNEL_TYPES.SUCCESS,
  payload: data,
});

export const getTopUsedOmnichannelTypesError = err => ({
  type: ACTIONS.GET_TOP_USED_OMNICHANNEL_TYPES.FAILURE,
  payload: err,
});

export const getDailyEmbedViewsStart = () => ({
  type: ACTIONS.GET_DAILY_EMBED_VIEWS.REQUEST,
});

export const getDailyEmbedViewsSuccess = data => ({
  type: ACTIONS.GET_DAILY_EMBED_VIEWS.SUCCESS,
  payload: data,
});

export const getDailyEmbedViewsError = err => ({
  type: ACTIONS.GET_DAILY_EMBED_VIEWS.FAILURE,
  payload: err,
});

export const getDailyEmbedClicksStart = () => ({
  type: ACTIONS.GET_DAILY_EMBED_CLICKS.REQUEST,
});

export const getDailyEmbedClicksSuccess = data => ({
  type: ACTIONS.GET_DAILY_EMBED_CLICKS.SUCCESS,
  payload: data,
});

export const getDailyEmbedClicksError = err => ({
  type: ACTIONS.GET_DAILY_EMBED_CLICKS.FAILURE,
  payload: err,
});

export const getVisitorDemographicStart = () => ({
  type: ACTIONS.GET_VISITOR_DEMOGRAPHIC.REQUEST,
});

export const getVisitorDemographicSuccess = data => ({
  type: ACTIONS.GET_VISITOR_DEMOGRAPHIC.SUCCESS,
  payload: data,
});

export const getVisitorDemographicError = err => ({
  type: ACTIONS.GET_VISITOR_DEMOGRAPHIC.FAILURE,
  payload: err,
});

export const getEngagementPerDeviceStart = () => ({
  type: ACTIONS.GET_ENGAGEMENT_PER_DEVICE.REQUEST,
});

export const getEngagementPerDeviceSuccess = data => ({
  type: ACTIONS.GET_ENGAGEMENT_PER_DEVICE.SUCCESS,
  payload: data,
});

export const getEngagementPerDeviceError = err => ({
  type: ACTIONS.GET_ENGAGEMENT_PER_DEVICE.FAILURE,
  payload: err,
});

export const getEngagementByTypeStart = () => ({
  type: ACTIONS.GET_ENGAGEMENT_BY_TYPE.REQUEST,
});

export const getEngagementByTypeSuccess = data => ({
  type: ACTIONS.GET_ENGAGEMENT_BY_TYPE.SUCCESS,
  payload: data,
});

export const getEngagementByTypeError = err => ({
  type: ACTIONS.GET_ENGAGEMENT_BY_TYPE.FAILURE,
  payload: err,
});

export const getTopUsedThemesStart = () => ({
  type: ACTIONS.GET_TOP_USED_THEMES.REQUEST,
});

export const getTopUsedThemesSuccess = data => ({
  type: ACTIONS.GET_TOP_USED_THEMES.SUCCESS,
  payload: data,
});

export const getTopUsedThemesError = err => ({
  type: ACTIONS.GET_TOP_USED_THEMES.FAILURE,
  payload: err,
});

export const getEngagementImpressionsStart = () => ({
  type: ACTIONS.GET_ENGAGEMENT_IMPRESSIONS.REQUEST,
});

export const getEngagementImpressionsSuccess = data => ({
  type: ACTIONS.GET_ENGAGEMENT_IMPRESSIONS.SUCCESS,
  payload: data,
});

export const getEngagementImpressionsError = err => ({
  type: ACTIONS.GET_ENGAGEMENT_IMPRESSIONS.FAILURE,
  payload: err,
});

export const getTintEmbedLocationsStart = () => ({
  type: ACTIONS.GET_TINT_EMBED_LOCATION.REQUEST,
});

export const getTintEmbedLocationsSuccess = data => ({
  type: ACTIONS.GET_TINT_EMBED_LOCATION.SUCCESS,
  payload: data,
});

export const getTintEmbedLocationsError = err => ({
  type: ACTIONS.GET_TINT_EMBED_LOCATION.FAILURE,
  payload: err,
});
