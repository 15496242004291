export const pageSectionType = {
  HEADER: 'header',
  BANNER: 'banner',
  COOKIE_BANNER: 'cookie_banner',
  TINT: 'tint',
  FORM: 'form',
  FORM_BUILDER: 'form_builder',
  FOOTER: 'footer',
  TIMER: 'timer',
  TAG_O_WAR: 'tag_o_war',
  SOCIAL: 'social',
  BACK_TO_TOP: 'back_to_top',
  POST: 'post',
  POLL: 'poll',
  SURVEY: 'survey',
  CUSTOM_SPACER: 'spacer',
  CUSTOM_BUTTON: 'button',
  CUSTOM_HEADLINE: 'headline',
  CUSTOM_PARAGRAPH: 'paragraph',
  SOCIAL_FEED_COUNTER: 'Social feed counter',
  MULTI_COLUMN: 'Multi-Column',
  MEDIA: 'Media',
  VIDEO: 'video',
  IMAGE: 'image',
  SURVEY_COUNTER: 'Survey Counter',
};

export const pageSectionToTranslation = {
  header: 'HEADER',
  banner: 'BANNER',
  cookie_banner: 'COOKIE_BANNER',
  tint: 'TINT',
  form: 'FORM',
  form_builder: 'FORM_BUILDER',
  footer: 'FOOTER',
  timer: 'TIMER',
  tag_o_war: 'TAG_O_WAR',
  social: 'SOCIAL',
  back_to_top: 'BACK_TO_TOP',
  post: 'POST',
  poll: 'POLL',
  survey: 'SURVEY',
  spacer: 'CUSTOM_SPACER',
  button: 'CUSTOM_BUTTON',
  headline: 'CUSTOM_HEADLINE',
  paragraph: 'CUSTOM_PARAGRAPH',
  social_feed_counter: 'SOCIAL_FEED_COUNTER',
  survey_counter: 'SURVEY_COUNTER',
};
