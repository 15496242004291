import React, { useState } from 'react';
import Button from '../../../button';
import './add-link.sass';
import { toast } from 'react-toastify';
import { getUrlAddress, isDomainAddress, isUrlAddress } from '../../../../services/functions/url-checker/url-address';
import EditInput from '../../../../feature/experience/components/experience/edit-input/edit-input';

const AddLink = ({ urlLink, dataUpdate, onClose }) => {
  const [urlValue, setUrlValue] = useState(urlLink);

  function onCancelClick() {
    onClose(false);
  }

  function onSaveClick() {
    if (urlValue === '' || isUrlAddress(urlValue) || isDomainAddress(urlValue)) {
      onSubmit();
      onClose(false);
    } else {
      toast.error('It is not correct address URL, example: https://example.com');
    }
  }

  function onSubmit() {
    dataUpdate({ urlLink: getUrlAddress(urlValue) });
  }

  return (
    <form
      noValidate
      onSubmit={e => {
        e.preventDefault();
      }}>
      <div className='tint-font-menu-url' id='tint-font-menu-url-input'>
        <EditInput
          defaultValue={urlValue}
          placeholder='Enter URL'
          onChange={e => {
            setUrlValue(e.target.value);
          }}
          autoFocus={true}
        />

        <div className='tint-font-menu-url__nav'>
          <Button className='tint-btn tint-btn--primary' onClick={() => onSaveClick()}>
            <span className='icon fas fa-check' />
          </Button>
          <Button className='tint-btn tint-btn--secondary' onClick={() => onCancelClick()}>
            <span className='icon fas fa-times' />
          </Button>
        </div>
      </div>
    </form>
  );
};

export default AddLink;
