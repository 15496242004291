import React, { Fragment } from 'react';
import './input-group.sass';
import ValidationAlert from '../validation-alert/validation-alert';

export const InputGroupPosition = {
  LEFT: 'left',
  RIGHT: 'right',
};

export const InputGroup = props => {
  const {
    inputGroupLabel,
    inputGroupIcon,
    children,
    label,
    required,
    labelPosition = InputGroupPosition.LEFT,
    error,
    touched,
  } = props;
  const isError = error && touched;

  return (
    <div className={`tint-input-group ${isError ? 'tint-input-group--error' : ''}`}>
      {label ? (
        <label>
          {label}
          {required ? '*' : null}
        </label>
      ) : null}
      <div className='tint-input-group__wrapper'>
        {labelPosition === InputGroupPosition.LEFT && inputGroupIcon && (
          <span className={`icon icon--group ${inputGroupIcon} ${InputGroupPosition.LEFT}`} />
        )}
        {labelPosition === InputGroupPosition.LEFT && inputGroupLabel && (
          <span className={`icon icon--group icon--label ${InputGroupPosition.LEFT}`}>{inputGroupLabel}</span>
        )}
        {React.Children.map(children, (child, i) => (
          <Fragment key={i}>
            {React.cloneElement(child, {
              ...child.props,
              label: undefined,
              error: isError ? undefined : child.props.error,
            })}
          </Fragment>
        ))}
        {labelPosition === InputGroupPosition.RIGHT && inputGroupIcon && (
          <span className={`icon icon--group ${inputGroupIcon} ${InputGroupPosition.RIGHT}`} />
        )}
        {labelPosition === InputGroupPosition.RIGHT && inputGroupLabel && (
          <span className={`icon icon--group icon--label ${InputGroupPosition.RIGHT}`}>{inputGroupLabel}</span>
        )}
      </div>
      <ValidationAlert errorMsg={error} isVisible={isError} />
    </div>
  );
};
