export const getPercentValue = (value, naturalImageWidth) => {
  const hundredPercent = 100;
  return (value * hundredPercent) / naturalImageWidth;
};

export const removePixelsFromValue = value => {
  return value?.toString()?.replace('px', '');
};

export const addPixelsToValue = value => {
  return `${value}px`;
};

export const findElementByValue = (array, value) => {
  return array?.find(el => el.value === value);
};
