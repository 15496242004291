import React, { useRef, useEffect, useState } from 'react';
import './validation-alert.sass';

const ValidationAlert = ({ errorMsg, isVisible, color = '#990000' }) => {
  const elementRef = useRef();
  const [currentHeight, setCurrentHeight] = useState();

  useEffect(() => {
    if (isVisible) {
      elementRef.current && setCurrentHeight(elementRef.current.offsetHeight);
    }
  }, [isVisible]);

  const renderValidationAlert = err => {
    return (
      <p
        style={{
          margin: !isVisible ? '0' : '4px 0',
          height: isVisible ? currentHeight : '0px',
          color: color,
        }}>
        <span ref={elementRef}>{err}</span>
      </p>
    );
  };

  return Array.isArray(errorMsg) ? (
    errorMsg.map((e, i) => {
      return (
        <div key={i} className='validation-alert'>
          {renderValidationAlert(e)}
        </div>
      );
    })
  ) : (
    <div className='validation-alert'>{renderValidationAlert(errorMsg)}</div>
  );
};

export default ValidationAlert;
