import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { Button } from '../../../../../../../../components/interface/button/button';
import { FileStack } from '../../../../../../../../services/filestack/file-stack';
import './image-content.sass';

export class ImageContent extends Component {
  onUploadClick = () => {
    return new FileStack().openFilePicker({
      accept: ['image/*'],
      maxSize: this.props.maxFileSize,
      onUploadDone: res => {
        if (res.filesUploaded.length > 0) {
          this.props.onContentUpdate({
            mediaUrl: res.filesUploaded[0].url,
            mediaName: res.filesUploaded[0].filename,
          });
        }
      },
    });
  };

  renderImagePreview = () => {
    return (
      <div className='tint-edit-header-content__center'>
        <div
          className='tint-edit-upload-design__image'
          style={{
            backgroundImage: `url(${this.props.data.content.mediaUrl})`,
          }}
        />
      </div>
    );
  };

  render() {
    return (
      <EditModalBody title='Edit Image' className='image-content'>
        <form
          noValidate
          onSubmit={e => {
            e.preventDefault();
          }}>
          <Button
            onClick={this.onUploadClick}
            type='gray'
            size='full-width'
            text='Upload media'
            icon='far fa-arrow-to-top'
            className='tint-edit-answer__upload-button'
          />
          {this.props.data.content.mediaUrl === undefined ? null : this.renderImagePreview()}
          {this.props.data.content.mediaName !== '' && (
            <h1 className='edit-media__file-name'>Current file name: {this.props.data.content.mediaName}</h1>
          )}
          <button style={{ display: 'none' }} type='submit'>
            Submit
          </button>
        </form>
      </EditModalBody>
    );
  }
}
