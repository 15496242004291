import React from 'react';
import './date-picker.sass';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { Button } from '../button/button';
import InputPrimary from '../inputs/input-primary/input-primary';
import { TintDatePickerDefineRangeList } from './components/date-picker-define-range-list';
import { DateRange } from 'react-date-range';
import { useFormik } from 'formik';
import { format, differenceInDays, isAfter } from 'date-fns';
import { toast } from 'react-toastify';
import * as Yup from 'yup';

const timeFormat = 'MM/dd/yyyy';

const calculateDateDifference = ({ startDate, endDate }) => {
  const startDateObj = new Date(startDate);
  const endDateObj = new Date(endDate);
  endDateObj.setHours(23, 59, 59, 999);

  if (startDate === endDate) {
    return 0;
  }

  if (!isAfter(endDateObj, startDateObj)) {
    toast.error('End date must be after start date');
    return 0;
  }

  const timeDifference = Math.abs(differenceInDays(endDateObj, startDateObj));

  return timeDifference;
};

export const TintDatePicker = ({
  currentDate,
  onApply,
  onCancel,
  minDate,
  maxDate,
  noAllTime = false,
  numberOfDaysAllow,
}) => {
  const regexp = /^(0[1-9]|1[0-2])\/(0[1-9]|[12]\d|3[01])\/\d{4}$/;
  const dateValidationSchema = Yup.string()
    .matches(regexp, 'Date must be in MM/DD/YYYY format')
    .required('Date is required');

  const validationSchema = Yup.object().shape({
    startDate: dateValidationSchema,
    endDate: dateValidationSchema,
  });

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      startDate: currentDate?.startDate ? format(currentDate?.startDate, timeFormat) : new Date(),
      endDate: currentDate?.endDate
        ? format(currentDate?.endDate, timeFormat)
        : new Date(new Date().setDate(new Date().getDate() + 7)),
      key: 'selection',
    },
    validationSchema,
  });

  const currentTime = new Date().getTime();
  const datePlaceholder = new Date(currentTime);

  const onChange = date => {
    if (numberOfDaysAllow && calculateDateDifference(date) > numberOfDaysAllow) {
      toast.error(`Date range should be lower than ${numberOfDaysAllow} days`);
      return;
    }

    formik.setFieldValue('startDate', format(date.startDate, timeFormat));
    formik.setFieldValue('endDate', format(date.endDate, timeFormat));
  };

  return (
    <div className='tint-dropdown-date-range-picker'>
      <div className='tint-dropdown-date-range-picker__row'>
        {!numberOfDaysAllow ? <TintDatePickerDefineRangeList onSelectDate={onChange} noAllTime={noAllTime} /> : null}
        <DateRange
          onChange={data => onChange(data.selection)}
          ranges={[
            {
              ...{
                ...formik.values,
                startDate: (regexp.test(formik.values.startDate) && new Date(formik.values.startDate)) || new Date(),
                endDate: (regexp.test(formik.values.endDate) && new Date(formik.values.endDate)) || new Date(),
              },
            },
          ]}
          months={2}
          minDate={minDate}
          maxDate={maxDate}
          direction='horizontal'
          showDateDisplay={false}
        />
      </div>

      <div className='tint-dropdown-date-range-picker__row tint-dropdown-date-range-picker__row--footer'>
        <div className='tint-dropdown-date-range-picker-custom-fields'>
          <span className='tint-dropdown-date-range-picker-custom-fields__label'>Custom</span>
          <div className='tint-dropdown-date-range-picker-custom-fields__inputs'>
            <InputPrimary
              name={'startDate'}
              type={'string'}
              value={formik.values.startDate}
              placeholder={datePlaceholder}
              error={formik.errors.startDate}
              touched={formik.touched.startDate}
              onBlur={formik.handleBlur}
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
            <span className='icon fas fa-arrow-right' />
            <InputPrimary
              name={'endDate'}
              type={'string'}
              value={formik.values.endDate}
              placeholder={datePlaceholder}
              error={formik.errors.endDate}
              touched={formik.touched.endDate}
              handleBlur={formik.handleBlur}
              handleChange={formik.handleChange}
            />
          </div>
        </div>

        <div className='tint-dropdown-date-range-picker-custom-fields__actions'>
          <Button type={'stroked'} text={'Cancel'} size={'36'} onClick={onCancel} />
          <Button
            type={'info'}
            text={'Apply'}
            size={'36'}
            isDisabled={!formik.isValid}
            onClick={() => {
              if (formik.values.startDate && formik.values.endDate) {
                const startDateStr = format(new Date(formik.values.startDate), 'yyyy-MM-dd');
                const endDateStr = format(new Date(formik.values.endDate), 'yyyy-MM-dd');
                const startDate = new Date(startDateStr + 'T00:00:00'); // Without this, Date constructor will consider the date as UTC and will add the offset
                const endDate = new Date(endDateStr + 'T23:59:59'); // same as above but for end date to include the whole day
                onApply({
                  startDate: startDate,
                  endDate: endDate,
                  areCustomValues: true,
                });
              } else {
                onApply({ ...formik.values, areCustomValues: false });
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};
