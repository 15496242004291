import React, { Component } from 'react';
import { PageSectionHoc } from '../../../../../../components/HOC/page-section/page-section.hoc';
import { connect } from 'react-redux';
import ColorParser from '../../../../../../services/color-parser/color-parser';
import { EditModal } from '../../../../../../components/edit-modal/edit-modal/edit-modal';
import { EditModalStep } from '../../../../../../components/edit-modal/edit-modal-step/edit-modal-step';
import { EditModalSwitcher } from '../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher';
import { SurveyEditContent } from './edit/survey-edit-content';
import SurveyEditStyles from './edit/survey-edit-styles';
import SingleAnswer from './components/single-answer/single-answer';
import './survey-section.sass';
import { toast } from 'react-toastify';
import FormSuccessMessageStep from '../form/steps/form-success-message-step';
import { SubmissionSuccess } from '../form/submission-success/submission-success';
import Button from '../../../../../../components/button';
import { isUrlAddress } from '../../../../../../services/functions/url-checker/url-address';
import Logger from '../../../../../../services/logger/logger';
import { ApiService } from '../../../../../../services/api-service/api-service';
import { getFormSubmissionUrl } from '../../../../utils/experience-helper';

const surveyIdKey = 'tint-survey-submit';

class SurveySection extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      selectedAnswersId: [],
      isEditingSuccessMessage: false,
      isSurveySubmitted: false,
      isAbleToSubmit: this.isAbleToSubmit(),
      isApplyButtonDisabled: false,
    };
  }

  setApplyButtonDisabled = value => {
    this.setState({ isApplyButtonDisabled: value });
  };

  renderColumnsPagination(elements, chunkSize) {
    const chunkElements = [];
    while (elements.length) {
      chunkElements.push(elements.splice(0, chunkSize));
    }
    return chunkElements.map((chunkElement, i) => (
      <div
        key={i}
        className={`tint-page-section__column-row tint-page-section__column-row--${chunkSize}`}
        data-testid='answersRowId'>
        {chunkElement}
      </div>
    ));
  }

  editSuccessMessage = () => {
    this.props.onDataSave();
    this.setState({ isEditingSuccessMessage: true });
  };

  setAsAnswered() {
    if (!localStorage.getItem(surveyIdKey)) {
      localStorage.setItem(surveyIdKey, JSON.stringify([this.props.experience.id]));
    } else {
      const currentExperienceList = JSON.parse(localStorage.getItem(surveyIdKey));
      const aaa = [...currentExperienceList, ...[this.props.experience.id]];
      localStorage.setItem(surveyIdKey, JSON.stringify(aaa));
    }
  }

  isAbleToSubmit = () => {
    const surveySubmitExperienceList = localStorage.getItem(surveyIdKey) || [];
    return !surveySubmitExperienceList.includes(this.props.experience.id);
  };

  saveSuccessMessage = () => {
    this.props.onDataSave().then(() => {
      this.finishEditingSuccessMessage();
      this.props.selectStep(0);
    });
  };

  areSuccessLinksInvalid = successMessageLinks => {
    return successMessageLinks.some(e => {
      return this.props.getTranslation(e.buttonUrl) && !isUrlAddress(this.props.getTranslation(e.buttonUrl));
    });
  };

  onSuccessMessageSave = () => {
    if (this.props.data.content.successMessageLinks && this.props.data.content.successMessageLinks.length > 0) {
      this.areSuccessLinksInvalid(this.props.data.content.successMessageLinks)
        ? toast.error('It is not correct address URL, example: https://example.com')
        : this.saveSuccessMessage();
    } else {
      this.saveSuccessMessage();
    }
  };

  sendSurvey = () => {
    if (this.state.isAbleToSubmit && this.state.selectedAnswersId.length > 0) {
      const postData = this.props.data.content.answers.reduce((map, obj) => {
        map[this.props.data.content.hasAdvancedSettings && obj.key ? obj.key : obj.title] =
          this.state.selectedAnswersId.indexOf(obj.id) > -1 ? 1 : 0;
        return map;
      }, {});

      const bodyFormData = new FormData();
      for (const key of Object.keys(postData)) {
        bodyFormData.set(key, postData[key]);
      }

      if (document.referrer) {
        bodyFormData.set('http_referer', document.referrer);
      }

      const paramObj = this.getQueryParams();
      Object.keys(paramObj).map(key => {
        if (key.includes('utm_')) {
          bodyFormData.set(key, paramObj[key]);
        }
      });

      const url = getFormSubmissionUrl(this.props.experienceId || this.props.experience.id);
      ApiService.pureInstance()
        .post(url, bodyFormData)
        .then(() => {
          this.setAsAnswered();
          this.setState({
            isSurveySubmitted: true,
          });
        })
        .catch(e => {
          Logger.error(`Can not post survey answers: ${e}`);
          toast.error('Something went wrong, try again later');
        });
    } else if (this.state.selectedAnswersId.length === 0) {
      toast.error('Please select at least one answer.');
    } else {
      toast.error('You are already answered.');
    }
  };

  getQueryParams() {
    const params = {};
    let parser = document.createElement('a');
    parser.href = location.href;
    let query = parser.search.substring(1);
    let vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
      let pair = vars[i].split('=');
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  }

  updateAnswerStatus = id => {
    this.setState({
      selectedAnswersId: this.state.selectedAnswersId.includes(id)
        ? this.state.selectedAnswersId.filter(answerId => answerId !== id)
        : [...this.state.selectedAnswersId, id],
    });
  };

  finishEditingSuccessMessage = () => {
    this.props.selectStep(0);
    this.setState({
      isEditingSuccessMessage: false,
    });
  };

  renderSurveyForm() {
    return (
      <form
        id='formId'
        className={`form ${!this.props.isPreviewMode ? 'form--read-only' : ''} tint-page-section__survey-form`}
        noValidate
        onSubmit={e => {
          e.preventDefault();
          this.sendSurvey(e);
        }}>
        {this.renderColumnsPagination(
          this.props.data.content.answers.map((answer, i) => {
            return (
              <div key={i}>
                <SingleAnswer
                  getTranslation={this.props.getTranslation}
                  answer={answer}
                  key={answer.id}
                  imageType={this.props.data.content.imageType}
                  onUpdateAnswerStatus={this.updateAnswerStatus}
                  styles={this.props.data.styles}
                />
              </div>
            );
          }),
          this.props.data.content.displayCount
        )}
        <div className='tint-page-section__survey-submit-wrapper'>
          {this.state.isAbleToSubmit ? (
            <Button
              style={{
                backgroundColor: ColorParser.defaultColor(this.props.data.styles.buttonBackgroundColor),
                color: ColorParser.defaultColor(this.props.data.styles.buttonTextColor),
              }}
              className='tint-page-section__survey-submit'
              type='submit'>
              {this.props.getTranslation(this.props.data.content.buttonText)}
            </Button>
          ) : (
            <Button
              style={{
                backgroundColor: ColorParser.defaultColor(this.props.data.styles.buttonBackgroundColor),
                color: ColorParser.defaultColor(this.props.data.styles.buttonTextColor),
                opacity: '0.6',
              }}
              disabled
              className='tint-page-section__survey-submit'
              type='transparent'>
              {this.props.getTranslation(this.props.data.content.buttonText)}
            </Button>
          )}
        </div>
      </form>
    );
  }

  isEveryTitleExist = () => !this.props.data.content.answers.every(answer => answer.title && answer.title.length > 0);

  render() {
    return (
      <div
        className={`tint-page-section tint-page-section--survey ${this.props.getSectionClass()}`}
        style={{
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`,
        }}>
        {this.props.renderGenericComponents()}
        <div className='tint-page-section__survey-success-wrapper'>
          {this.state.isSurveySubmitted || this.state.isEditingSuccessMessage ? (
            <SubmissionSuccess
              getTranslation={this.props.getTranslation}
              title={this.props.getTranslation(this.props.data.content.successMessageTitle)}
              description={this.props.getTranslation(this.props.data.content.successMessageDescription)}
              successMessageLinks={this.props.data.content.successMessageLinks}
            />
          ) : (
            this.renderSurveyForm()
          )}
        </div>
        {this.props.isModalOpen && (
          <EditModal
            nextStep={this.props.nextStep}
            stepIndex={this.props.stepIndex}
            isVisible={this.props.isModalOpen}
            saveData={this.props.onDataSave}
            dismissModal={this.props.dismissModal}
            closeModal={this.props.closeModal}>
            <EditModalStep
              title='Edit Survey'
              isApplyButtonDisabled={this.isEveryTitleExist() || this.state.isApplyButtonDisabled}>
              <EditModalSwitcher>
                <SurveyEditContent
                  experienceType={this.props.experienceType}
                  onTranslationUpdate={this.props.onTranslationUpdate}
                  onNewTranslationUpdate={this.props.onNewTranslationUpdate}
                  getTranslation={this.props.getTranslation}
                  data={this.props.data}
                  selectStep={this.props.selectStep}
                  dataUpdate={this.props.onContentUpdate}
                  editSuccessMessage={this.editSuccessMessage}
                  setApplyButtonDisabled={this.setApplyButtonDisabled}
                  getMaxVideoSizePlanLimit={this.props.getMaxVideoSizePlanLimit}
                />
                <SurveyEditStyles
                  data={this.props.data}
                  dataUpdate={this.props.onContentUpdate}
                  styleDataUpdate={this.props.onStylesUpdate}
                />
              </EditModalSwitcher>
            </EditModalStep>
            <FormSuccessMessageStep
              experienceType={this.props.experienceType}
              onNewTranslationUpdate={this.props.onNewTranslationUpdate}
              onTranslationUpdate={this.props.onTranslationUpdate}
              getTranslation={this.props.getTranslation}
              content={this.props.data.content}
              onContentUpdate={this.props.onContentUpdate}
              finishEditingSuccessMessage={this.finishEditingSuccessMessage}
              onSuccessMessageSave={this.onSuccessMessageSave}
            />
          </EditModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  experienceId: state.experience.data && state.experience.data.id,
});

export default connect(mapStateToProps)(PageSectionHoc(SurveySection));
