import React, { Component } from 'react';
import './tint-section.sass';
import { ConnectTintBox } from './connect-tint-box/connect-tint-box';
import { defaultTintPersonalization } from './edit/edit-tint-content';
import { EditTintContentNew } from './edit/edit-tint-content-new';
import { EditTintStyles } from './edit/edit-tint-styles';
import { PageSectionHoc } from '../../../../../../components/HOC/page-section/page-section.hoc';
import { EditModalSwitcher } from '../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher';
import { EditModal } from '../../../../../../components/edit-modal/edit-modal/edit-modal';
import { EditModalStep } from '../../../../../../components/edit-modal/edit-modal-step/edit-modal-step';
import { Config } from '../../../../../../utils/config';
import ColorParser from '../../../../../../services/color-parser/color-parser';
import { connect } from 'react-redux';

class TintSection extends Component {
  constructor(props, context) {
    super(props, context);
    this.isDevEnv = Config.isDevEnv();
    this.isStagingEnv = Config.isStagingEnv();

    this.state = {
      isDevEnv: /localhost|tintup.dev/.test(window.location.href),
      baseUrl: this.isDevEnv ? `${window.location.protocol}//${window.location.hostname}` : 'https://www.tintup.com',
    };
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
      this.reRenderIframe();
    }

    if (this.props.data.content.tintName === '' && document.querySelector('.a5b5e4-inner')) {
      this.clearTintScriptStuff();
    }
  }

  componentDidMount() {
    const themesScript = document.querySelector("script[src*='/app/dist/embedded.js']");
    if (themesScript) {
      this.reRenderIframe();
    } else {
      const script = document.createElement('script');
      script.src = this.isDevEnv
        ? 'https://www.tintup.dev/app/dist/embedded.js'
        : `${
            this.isStagingEnv ? `${window.location.protocol}//${window.location.hostname}` : 'https://www.tintup.com'
          }/app/dist/embedded.js`;
      document.body.appendChild(script);
    }
  }

  clearTintScriptStuff() {
    if (document.querySelector('.a5b5e4-inner')) {
      document.querySelector('.a5b5e4-inner').remove();
      document.querySelector('.rectangle-container').style.height = '';
    }
  }

  isIframeOnSite() {
    return this.tintNode && this.tintNode.children.length > 0;
  }

  reRenderIframe() {
    if (document.querySelector('#tint-page')) {
      document.querySelector('#tint-page').remove();
    }
    if (document.querySelector('#tint-popup')) {
      document.querySelector('#tint-popup').remove();
    }
    if (document.querySelector('.a5b5e4-inner')) {
      document.querySelector('.a5b5e4-inner').remove();
    }
    if (document.querySelector("script[src*='/a5b5e5.js']")) {
      document.querySelector("script[src*='/a5b5e5.js']").remove();
    }
    if (document.querySelector("script[src*='/embed.js']")) {
      document.querySelector("script[src*='/embed.js']").remove();
    }
    if (document.querySelector("script[src*='/app/dist/embedded.js']")) {
      document.querySelector("script[src*='/app/dist/embedded.js']").remove();
      const script = document.createElement('script');
      script.src = this.isDevEnv
        ? 'https://www.tintup.dev/app/dist/embedded.js'
        : `${
            this.isStagingEnv ? `${window.location.protocol}//${window.location.hostname}` : 'https://www.tintup.com'
          }/app/dist/embedded.js`;
      document.body.appendChild(script);
    }

    setTimeout(() => {
      if (window.HM && !this.isIframeOnSite()) {
        HM.init();
        HM.render();
        HM.bind();
      }
    });
  }

  getRectangleBox(isSmallFirst) {
    return (
      <div className='rectangle-container__rectangle-box'>
        <div className={`rectangle ${isSmallFirst ? 'rectangle--small' : ''}`} />
        <div className={`rectangle ${!isSmallFirst ? 'rectangle--small' : ''}`} />
      </div>
    );
  }

  renderContent() {
    return (
      <div className='rectangle-container'>
        <ConnectTintBox onClick={this.props.openModal.bind(this)} text='+ Connect TINT' />
        {this.getRectangleBox(true)}
        {this.getRectangleBox(false)}
        {this.getRectangleBox(true)}
        {this.getRectangleBox(false)}
        {this.getRectangleBox(true)}
      </div>
    );
  }

  renderTint = () => {
    let content = this.props.data.content;
    let displayType;
    try {
      displayType = JSON.parse(content.displayType);
    } catch (e) {
      displayType = {};
    }
    const height = content.heightType === 'Fixed' ? Number(content.height) : '';
    const position = content.heightType === 'Fixed' ? 'relative' : undefined;
    const style = {
      height,
      maxHeight: Number(height) === 0 ? '' : height,
      position,
    };

    let additionalAttributes = content.heightType === 'Fixed' ? undefined : JSON.parse('{"data-expand": "true"}');

    let personalizationIdAttribute =
      content.personalizationId === defaultTintPersonalization || content.personalizationId === ''
        ? undefined
        : JSON.parse(`{"data-personalization-id": "${content.personalizationId}"}`);

    return (
      <div
        className='tintup'
        ref={node => (this.tintNode = node)}
        data-baseurl={`${this.state.baseUrl}`}
        data-id={content.tintName}
        data-columns={content.ofColumns.toString()}
        style={{ ...style }}
        {...displayType}
        {...additionalAttributes}
        {...personalizationIdAttribute}
        data-saved-filter-id={content.savedFilterId}
        {...(!this.props.isPreviewMode
          ? null
          : this.props.cookieEnabled && !this.props.isGDPRAllowed
          ? { ['data-notrack']: true }
          : null)}
      />
    );
  };

  render() {
    return (
      <div
        dir='auto'
        className={`tint-page-section tint-page-section--tint ${this.props.getSectionClass()}`}
        style={{
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          display: !this.props.data.content.tintName && this.props.isPreviewMode ? 'none' : '',
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`,
          // If we don't want to show full content tin in preview:
          // ...(!this.props.isPreviewMode  ? {'overflow': 'hidden'} : {}),
          // maxHeight: !this.props.isPreviewMode ? '1000px' : '',
        }}>
        {this.props.renderGenericComponents()}
        {this.props.isModalOpen && (
          <EditModal
            isVisible={this.props.isModalOpen}
            saveData={this.props.onDataSave}
            closeModal={this.props.closeModal}
            dismissModal={this.props.dismissModal}>
            <EditModalStep title='Edit TINT Board'>
              <EditModalSwitcher>
                <EditTintContentNew
                  updateForm={this.onDataSave}
                  data={this.props.data}
                  dataUpdate={this.props.onContentUpdate}
                />
                <EditTintStyles
                  data={this.props.data}
                  saveData={this.props.onDataSave}
                  dataUpdate={this.props.onContentUpdate}
                  styleDataUpdate={this.props.onStylesUpdate}
                />
              </EditModalSwitcher>
            </EditModalStep>
          </EditModal>
        )}
        {this.props.data.content.tintName ? this.renderTint() : this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  session: state.session.data,
});

export default connect(mapStateToProps)(PageSectionHoc(TintSection));
