import { useState, useEffect } from 'react';

const useColumnSetter = (firstPercentValue, secondPercentValue) => {
  const [columnClass, setColumnCount] = useState('');

  function setColumnWrapperRangeClass(firstValue, secondValue) {
    if (firstValue === undefined || secondValue === undefined) {
      return setColumnCount('no-value');
    }
    if (firstValue <= 20) {
      return setColumnCount('min-left');
    }
    if (firstValue < 40 && firstValue > 20) {
      return setColumnCount('30-70');
    }
    if (firstValue < 45 && firstValue > 30) {
      return setColumnCount('40-60');
    }
    if (firstValue < 60 && firstValue > 40) {
      return setColumnCount('50-50');
    }
    if (firstValue < 65 && firstValue > 50) {
      return setColumnCount('60-40');
    }
    if (firstValue < 80 && firstValue > 60) {
      return setColumnCount('70-30');
    }
    if (firstValue >= 80) {
      return setColumnCount('min-right');
    }
  }

  useEffect(() => {
    setColumnWrapperRangeClass(firstPercentValue, secondPercentValue);
  }, [firstPercentValue]);

  return {
    columnClass,
  };
};

export default useColumnSetter;
