import { createPrefixedActionName } from '../../../../services/functions/redux-routines/redux-routines';
import { COLLABORATORS } from '../../consts';

export const ACTIONS = {
  OPEN_SIDEBAR: createPrefixedActionName(COLLABORATORS, 'OPEN_SIDEBAR'),
  CLOSE_SIDEBAR: createPrefixedActionName(COLLABORATORS, 'CLOSE_SIDEBAR'),
  SET_PENDING_COLLABORATOR: createPrefixedActionName(COLLABORATORS, 'SET_PENDING_COLLABORATOR'),
  CLEAR_PENDING_COLLABORATOR: createPrefixedActionName(COLLABORATORS, 'CLEAR_PENDING_COLLABORATOR'),
};

export const openSidebar = data => ({
  type: ACTIONS.OPEN_SIDEBAR,
  payload: data,
});

export const closeSidebar = () => ({
  type: ACTIONS.CLOSE_SIDEBAR,
});

export const setPendingCollaborator = data => ({
  type: ACTIONS.SET_PENDING_COLLABORATOR,
  payload: data,
});

export const clearPendingCollaborator = () => ({
  type: ACTIONS.CLEAR_PENDING_COLLABORATOR,
});
