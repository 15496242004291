import { ACTIONS } from '../../actions/filters/asset-manager-filters.actions';
import { produce } from 'immer';
import { AssetStatus } from '../../components/settings-dropdown/asset-settings-dropdown';

export const initialState = {
  sortValue: '-created_at',
  filter: {
    sentiment: [],
    objects: [],
    tags: [],
    collectionId: '',
    collections: [],
    assetType: '',
    source: '',
    date: undefined,
    status: 'active',
  },
  searchPhrase: '',
};

export const assetManagerFilters = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_SORT_OPTION:
      return {
        ...state,
        ...{
          sortValue: action.payload,
        },
      };

    case ACTIONS.SET_SEARCH_PHRASE:
      return {
        ...state,
        ...{
          searchPhrase: action.payload,
        },
      };

    case ACTIONS.SET_FILTER_OPTION:
      return {
        ...state,
        ...{
          filter: { ...state.filter, ...action.payload },
        },
      };

    case ACTIONS.CLEAR_FILTER_OPTION:
      return produce(state, draftState => {
        draftState.filter.objects = draftState.filter.objects.map(e => {
          e.isActive = false;
          return e;
        });
        (draftState.filter.tags = []), (draftState.filter.date = undefined);
        draftState.filter.assetType = '';
        draftState.filter.source = '';
        draftState.filter.collectionId = '';
        draftState.filter.status = AssetStatus.ACTIVE;
      });

    default:
      return state;
  }
};
