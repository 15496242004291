export const timeZones = [
  {
    offset: '-12:00',
    display: '(GMT -12:00) Eniwetok, Kwajalein',
  },
  {
    offset: '-11:00',
    display: '(GMT -11:00) Midway Island, Samoa',
  },
  {
    offset: '-10:00',
    display: '(GMT -10:00) Hawaii',
  },
  {
    offset: '-09:00',
    display: '(GMT -9:00) Alaska',
  },
  {
    offset: '-08:00',
    display: '(GMT -8:00) Pacific Time (US & Canada)',
  },
  {
    offset: '-07:00',
    display: '(GMT -7:00) Mountain Time (US & Canada)',
  },
  {
    offset: '-06:00',
    display: '(GMT -6:00) Central Time (US & Canada), Mexico City',
  },
  {
    offset: '-05:00',
    display: '(GMT -5:00) Eastern Time (US & Canada), Bogota, Lima',
  },
  {
    offset: '-04:00',
    display: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz',
  },
  {
    offset: '-03:30',
    display: '(GMT -3:30) Newfoundland',
  },
  {
    offset: '-03:00',
    display: '(GMT -3:00) Brazil, Buenos Aires, Georgetown',
  },
  {
    offset: '-02:00',
    display: '(GMT -2:00) Mid-Atlantic',
  },
  {
    offset: '-01:00',
    display: '(GMT -1:00 hour) Azores, Cape Verde Islands',
  },
  {
    offset: '+00:00',
    display: '(GMT) Western Europe Time, London, Lisbon, Casablanca',
  },
  {
    offset: '+01:00',
    display: '(GMT +1:00 hour) Brussels, Copenhagen, Madrid, Paris',
  },
  {
    offset: '+02:00',
    display: '(GMT +2:00) Kaliningrad, South Africa',
  },
  {
    offset: '+03:00',
    display: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg',
  },
  {
    offset: '+03:30',
    display: '(GMT +3:30) Tehran',
  },
  {
    offset: '+04:00',
    display: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi',
  },
  {
    offset: '+04:30',
    display: '(GMT +4:30) Kabul',
  },
  {
    offset: '+05:00',
    display: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent',
  },
  {
    offset: '+05:30',
    display: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi',
  },
  {
    offset: '+05:45',
    display: '(GMT +5:45) Kathmandu',
  },
  {
    offset: '+06:00',
    display: '(GMT +6:00) Almaty, Dhaka, Colombo',
  },
  {
    offset: '+07:00',
    display: '(GMT +7:00) Bangkok, Hanoi, Jakarta',
  },
  {
    offset: '+08:00',
    display: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong',
  },
  {
    offset: '+9:00',
    display: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk',
  },
  {
    offset: '+09:30',
    display: '(GMT +9:30) Adelaide, Darwin',
  },
  {
    offset: '+10:00',
    display: '(GMT +10:00) Eastern Australia, Guam, Vladivostok',
  },
  {
    offset: '+11:00',
    display: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia',
  },
  {
    offset: '+12:00',
    display: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka',
  },
];
