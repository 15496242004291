import React from 'react';
import useFeatureAccessibility from '../components/hooks/use-feature-accessibility/use-feature-accessibility';
import { useDispatch, useSelector } from 'react-redux';
import { openSidebar, SIDEBAR_TYPE } from '../actions/sidebar';
import { sidebarUpgradeSchema } from '../components/sidebar-upgrade/sidebar-upgrade.schema';
import { GuardNames } from './guards-list';
import './feature-guard.sass';
import { GuardsRules } from './guards-rules';

const FeatureGuard = ({ hasAccess, featureName, children, showUpgradeSidebar = true }) => {
  const hasFeatureAccessibility = useFeatureAccessibility(featureName);
  const dispatch = useDispatch();
  const permissionList = useSelector(state => state?.session?.data?.permissions);
  const featureKey = Object.keys(GuardNames).find(key => GuardNames[key] === featureName);
  const currentFeatureRule = featureKey ? GuardsRules[GuardNames[featureKey]] : null;

  const mapFeatureToSchema = featureName => {
    switch (featureName) {
      case GuardNames.USER_RIGHTS:
        return sidebarUpgradeSchema.rightsManagement;
      case GuardNames.TAG_PRODUCTS:
        return sidebarUpgradeSchema.tagProducts;
      case GuardNames.NEW_SIMILAR_IMAGES:
        return sidebarUpgradeSchema.visualSearch;
      case GuardNames.COMMUNITY_UPDATE:
        return sidebarUpgradeSchema.vesta;
      default:
        return sidebarUpgradeSchema.default;
    }
  };

  const openUpgradeSidebar = () => {
    dispatch(
      openSidebar({
        modalType: SIDEBAR_TYPE.UPGRADE_SIDEBAR,
        data: {
          sidebarUpgradeData: mapFeatureToSchema(featureName),
        },
      })
    );
  };

  const checkIsPermissionAvailable = list => {
    return list.map(key => permissionList[key]).every(val => val);
  };

  const renderUpgradeWrapper = () => {
    // Check permission from session if false display nothing also hide only if the feature is missing
    if (
      currentFeatureRule &&
      currentFeatureRule.permissions &&
      !checkIsPermissionAvailable(currentFeatureRule.permissions) &&
      (typeof hasAccess === 'undefined' || (typeof hasAccess !== 'undefined' && hasAccess))
    ) {
      return null;
    }

    return (
      <div onClick={openUpgradeSidebar} className='feature-guard-wrapper'>
        <span className='feature-guard-wrapper__inner'>{children}</span>
      </div>
    );
  };

  const renderCorrectGuard = () => {
    if (featureName && typeof hasAccess !== 'undefined') {
      return hasFeatureAccessibility && hasAccess ? (
        <>{children}</>
      ) : showUpgradeSidebar ? (
        renderUpgradeWrapper()
      ) : null;
    } else if (featureName) {
      return hasFeatureAccessibility ? <>{children}</> : showUpgradeSidebar ? renderUpgradeWrapper() : null;
    }

    return hasAccess ? <>{children}</> : showUpgradeSidebar ? renderUpgradeWrapper() : null;
  };

  return renderCorrectGuard();
};

export default FeatureGuard;
