import { ACTIONS } from '../../actions/roles/roles-sidebar.actions';
import { SelectAccessType } from '../../components/roles/utils/filter-helper/filter-helper';
import { getRoleAccessIds } from './roles.reducer';

const initialValues = {
  selectedAccess: SelectAccessType.MANUAL,
  totalCount: 0,
  ids: {
    valuesToAdd: undefined,
    valuesToRemove: undefined,
  },
  data: [],
  isInitial: true,
};

export const initialState = {
  type: undefined,
  tints: initialValues,
  personalizations: initialValues,
  collections: initialValues,
  accounts: initialValues,
  experiences: initialValues,
  fonts: initialValues,
  domains: initialValues,
  product_accounts: initialValues,
  product_feeds: initialValues,
  webhooks: initialValues,
  oauth_applications: initialValues,
  forms: initialValues,
  mixes: initialValues,
  isOpen: false,
  isFetching: false,
  error: undefined,
};

export const rolesSidebar = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_SIDEBAR:
      return {
        ...state,
        type: action.payload,
        isOpen: true,
      };

    case ACTIONS.SET_PENDING_TINTS:
    case ACTIONS.SET_PENDING_PERSONALIZATIONS:
    case ACTIONS.SET_PENDING_COLLECTIONS:
    case ACTIONS.SET_PENDING_SOCIAL_ACCOUNTS:
    case ACTIONS.SET_PENDING_EXPERIENCES:
    case ACTIONS.SET_PENDING_DOMAINS:
    case ACTIONS.SET_PENDING_FONTS:
    case ACTIONS.SET_PENDING_PRODUCT_ACCOUNTS:
    case ACTIONS.SET_PENDING_PRODUCT_FEEDS:
    case ACTIONS.SET_PENDING_WEBHOOKS:
    case ACTIONS.SET_PENDING_OAUTH_APPLICATIONS:
    case ACTIONS.SET_PENDING_FORMS:
    case ACTIONS.SET_PENDING_MIXES:
      return {
        ...state,
        ...{
          [action.payload.type]: {
            ...state[action.payload.type],
            ...{
              ids: {
                valuesToAdd: action.payload.valuesToAdd,
                valuesToRemove: action.payload.valuesToRemove,
              },
              ...action.payload,
            },
          },
        },
      };

    case ACTIONS.CLEAR_PENDING_DATA:
      return {
        ...state,
        ...{
          type: undefined,
          tints: initialValues,
          personalizations: initialValues,
          collections: initialValues,
          accounts: initialValues,
          experiences: initialValues,
          fonts: initialValues,
          domains: initialValues,
          product_accounts: initialValues,
          product_feeds: initialValues,
          webhooks: initialValues,
          oauth_applications: initialValues,
          forms: initialValues,
          mixes: initialValues,
        },
      };

    case ACTIONS.CLOSE_SIDEBAR:
      return {
        ...state,
        isOpen: false,
      };

    case ACTIONS.GET_ROLE_TINTS.SUCCESS:
    case ACTIONS.GET_ROLE_PERSONALIZATIONS.SUCCESS:
    case ACTIONS.GET_ROLE_ACCOUNTS.SUCCESS:
    case ACTIONS.GET_ROLE_COLLECTIONS.SUCCESS:
    case ACTIONS.GET_ROLE_EXPERIENCES.SUCCESS:
    case ACTIONS.GET_ROLE_FONTS.SUCCESS:
    case ACTIONS.GET_ROLE_DOMAINS.SUCCESS:
    case ACTIONS.GET_ROLE_PRODUCT_ACCOUNTS.SUCCESS:
    case ACTIONS.GET_ROLE_PRODUCT_FEEDS.SUCCESS:
    case ACTIONS.GET_ROLE_WEBHOOKS.SUCCESS:
    case ACTIONS.GET_ROLE_OAUTH_APPLICATIONS.SUCCESS:
    case ACTIONS.GET_ROLE_FORMS.SUCCESS:
    case ACTIONS.GET_ROLE_MIXES.SUCCESS:
      return {
        ...state,
        ...{
          [action.payload.type]: {
            ...state[action.payload.type],
            ids: {
              valuesToAdd: getRoleAccessIds(action.payload.data),
            },
            data: action.payload.data,
            isInitial: false,
          },
          isFetching: false,
          error: undefined,
        },
      };

    case ACTIONS.GET_ROLE_ACCOUNTS.REQUEST:
    case ACTIONS.GET_ROLE_TINTS.REQUEST:
    case ACTIONS.GET_ROLE_PERSONALIZATIONS.REQUEST:
    case ACTIONS.GET_ROLE_COLLECTIONS.REQUEST:
    case ACTIONS.GET_ROLE_EXPERIENCES.REQUEST:
    case ACTIONS.GET_ROLE_FONTS.REQUEST:
    case ACTIONS.GET_ROLE_DOMAINS.REQUEST:
    case ACTIONS.GET_ROLE_PRODUCT_ACCOUNTS.REQUEST:
    case ACTIONS.GET_ROLE_PRODUCT_FEEDS.REQUEST:
    case ACTIONS.GET_ROLE_WEBHOOKS.REQUEST:
    case ACTIONS.GET_ROLE_OAUTH_APPLICATIONS.REQUEST:
    case ACTIONS.GET_ROLE_FORMS.REQUEST:
    case ACTIONS.GET_ROLE_MIXES.REQUEST:
      return {
        ...state,
        ...{
          isFetching: true,
          error: undefined,
        },
      };

    case ACTIONS.GET_ROLE_ACCOUNTS.FAILURE:
    case ACTIONS.GET_ROLE_TINTS.FAILURE:
    case ACTIONS.GET_ROLE_PERSONALIZATIONS.FAILURE:
    case ACTIONS.GET_ROLE_COLLECTIONS.FAILURE:
    case ACTIONS.GET_ROLE_EXPERIENCES.FAILURE:
    case ACTIONS.GET_ROLE_FONTS.FAILURE:
    case ACTIONS.GET_ROLE_DOMAINS.FAILURE:
    case ACTIONS.GET_ROLE_PRODUCT_ACCOUNTS.FAILURE:
    case ACTIONS.GET_ROLE_PRODUCT_FEEDS.FAILURE:
    case ACTIONS.GET_ROLE_WEBHOOKS.FAILURE:
    case ACTIONS.GET_ROLE_OAUTH_APPLICATIONS.FAILURE:
    case ACTIONS.GET_ROLE_FORMS.FAILURE:
    case ACTIONS.GET_ROLE_MIXES.FAILURE:
      return {
        ...state,
        ...{
          isFetching: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
