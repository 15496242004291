import React from 'react';
import PreloaderComponent from '../preloaders/preloader/preloader';
import './thumbnail.sass';
import useImageValidation from '../../hooks/use-image-validation/use-image-validation';
import PropTypes from 'prop-types';

const TintThumbnail = ({ imageUrl, style, type, noAlt = false }) => {
  const { newImageUrl, isPending } = useImageValidation(imageUrl);

  const defaultStyle = {
    width: '48px',
    height: '48px',
  };

  const typeClassName = type === 'rigid' ? 'tint-thumbnail--rigid' : '';

  return (
    <div
      className={`tint-thumbnail ${typeClassName} ${noAlt && 'tint-thumbnail--no-image'}`}
      style={{ ...defaultStyle, ...style }}>
      {isPending ? (
        <PreloaderComponent />
      ) : noAlt ? (
        <img src={imageUrl} data-testid='imageUrl' />
      ) : (
        <img src={newImageUrl} data-testid='imageUrl' />
      )}
    </div>
  );
};

export default TintThumbnail;

TintThumbnail.propTypes = {
  imageUrl: PropTypes.string,
  type: PropTypes.string,
  style: PropTypes.any,
};
