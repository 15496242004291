import { ACTIONS } from '../../actions/upload-dialog/upload-dialog';

export const initialState = {
  isFinished: false,
  isPending: false,
  title: '',
  files: [],
  type: '',
  onCloseClick: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_UPLOAD_DIALOG:
      return {
        ...state,
        ...action.payload,
      };

    case ACTIONS.CLEAR_UPLOAD_DIALOG:
      return initialState;

    default:
      return state;
  }
};
