import { produce } from 'immer';
import { ACTIONS } from '../actions/tint-editor.actions';
import { tintStatusType } from '../../../model/tint-status-type.model';

export const initialState = {
  data: undefined,
  ctas: [],
  searched: [],
  links: undefined,
  included: undefined,
  isFetching: false,
  error: undefined,
  modal: undefined,
  modalType: undefined,
  modalEcommerce: undefined,
  selectedTints: [],
};

export const tintEditor = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CLEAR_STATE:
      return initialState;

    case ACTIONS.DELETE_CTA_ASSOCIATIONS.REQUEST:
    case ACTIONS.POST_CTA_ASSOCIATIONS.REQUEST:
    case ACTIONS.APPLY_CTA_ASSOCIATIONS.REQUEST:
    case ACTIONS.DELETE_CTA.REQUEST:
    case ACTIONS.POST_CTA.REQUEST:
    case ACTIONS.GET_CTAS.REQUEST:
    case ACTIONS.PATCH_CTA.REQUEST:
    case ACTIONS.GET_TINTS.REQUEST:
    case ACTIONS.GET_SEARCH_TINTS.REQUEST:
    case ACTIONS.GET_TINT.REQUEST:
    case ACTIONS.UPDATE_TINT_STATUS.REQUEST:
    case ACTIONS.BULK_UPDATE_TINTS_STATUS.REQUEST:
    case ACTIONS.UPDATE_TINT_PRIVACY.REQUEST:
    case ACTIONS.DELETE_TINT.REQUEST:
    case ACTIONS.BULK_DELETE_TINTS.REQUEST:
    case ACTIONS.PATCH_TINT.REQUEST:
    case ACTIONS.DUPLICATE_TINT.REQUEST:
    case ACTIONS.CREATE_TINT.REQUEST:
      return {
        ...state,
        ...{
          isFetching: true,
          isError: false,
        },
      };
    case ACTIONS.DELETE_TINT.SUCCESS:
      return produce(state, draftState => {
        draftState.tints = draftState.tints.filter(tint => tint.id !== action.payload);
        draftState.isFetching = false;
        draftState.isError = false;
      });

    case ACTIONS.BULK_DELETE_TINTS.SUCCESS:
      return produce(state, draftState => {
        draftState.tints = draftState.tints.filter(tint => {
          return !action.payload.some(t => t.id === tint.id);
        });
        draftState.isFetching = false;
        draftState.isError = false;
        draftState.selectedTints = [];
      });

    case ACTIONS.BULK_UPDATE_TINTS_STATUS.SUCCESS:
      return produce(state, draftState => {
        draftState.selectedTints = draftState.selectedTints.map(tint => ({
          ...tint,
          attributes: {
            ...tint.attributes,
            status: tint.attributes.status === tintStatusType.ACTIVE ? tintStatusType.INACTIVE : tintStatusType.ACTIVE,
          },
        }));
      });

    case ACTIONS.PATCH_TINT.SUCCESS:
      return produce(state, draftState => {
        draftState.data = action.payload;
      });

    case ACTIONS.TOGGLE_CTA_SIDEBAR:
      return produce(state, draftState => {
        draftState.CTAModal = {
          postId: action.payload.postId,
          isCTASidebarOpen: action.payload.isVisible,
          isAssignMode: action.payload.isAssignMode,
        };
      });

    case ACTIONS.TOGGLE_SIDEBAR:
      return produce(state, draftState => {
        draftState.modals = {
          [action.payload.sidebarName]: {
            isOpen: action.payload.isVisible,
          },
        };
      });

    case ACTIONS.GET_TINTS.SUCCESS:
      return {
        ...state,
        ...{
          tints: action.payload.tints,
          included: action.payload.included,
          links: action.payload.links,
          isFetching: false,
          isError: false,
        },
      };

    case ACTIONS.OPEN_MODAL:
      return produce(state, draftState => {
        draftState.modal = {
          postId: action.payload,
        };
        draftState.modalType = undefined;
      });

    case ACTIONS.OPEN_VISUAL_SEARCH_MODAL:
      return produce(state, draftState => {
        draftState.modalVisualSearch = true;
      });

    case ACTIONS.CLOSE_VISUAL_SEARCH_MODAL:
      return produce(state, draftState => {
        draftState.modalVisualSearch = undefined;
      });

    case ACTIONS.OPEN_ECOMMERCE_MODAL:
      return produce(state, draftState => {
        draftState.modalEcommerce = action.payload;
      });

    case ACTIONS.CLOSE_ECOMMERCE_MODAL:
      return produce(state, draftState => {
        draftState.modalEcommerce = undefined;
      });

    case ACTIONS.CHANGE_MODAL_POST:
      return produce(state, draftState => {
        draftState.modal = {
          postId: action.payload,
        };
      });

    case ACTIONS.CLOSE_MODAL:
      return produce(state, draftState => {
        draftState.modal = undefined;
        draftState.modalType = undefined;
      });

    case ACTIONS.INCREASE_VISUAL_SEARCH_COUNT:
      return produce(state, draftState => {
        draftState.data.meta.visual_searches_count = draftState.data.meta.visual_searches_count + 1;
      });

    case ACTIONS.CHANGE_MODAL_ORIENTATION:
      return produce(state, draftState => {
        draftState.modalType = action.payload;
      });

    case ACTIONS.POST_CTA_ASSOCIATIONS.SUCCESS:
      return produce(state, draftState => {
        const currentCTA = draftState.ctas.find(cta => cta.id === action.payload.ctaId);
        draftState.ctas = draftState.ctas.filter(cta => cta.id !== action.payload.ctaId);

        draftState.ctas = [
          {
            ...currentCTA,
            savedFilterId: action.payload.savedFilterId,
            ctaAssociationId: action.payload.id,
          },
          ...draftState.ctas,
        ];
      });

    case ACTIONS.APPLY_CTA_ASSOCIATIONS.SUCCESS:
      return produce(state, draftState => {
        const cta = draftState.ctas.find(cta => cta.id === action.payload.ctaId);
        const posts = cta.posts?.length > 0 ? cta.posts : [];
        cta.posts = [action.payload, ...posts];
        cta.ctaAssociationId = action.payload.id;
      });

    case ACTIONS.DELETE_CTA_ASSOCIATIONS.SUCCESS:
      return produce(state, draftState => {
        const cta = draftState.ctas.find(cta => cta.id === action.payload.ctaId);
        cta.posts = cta?.posts?.filter(post => post?.id !== action.payload.ctaAssociationId);
      });

    case ACTIONS.GET_CTAS.SUCCESS:
      return produce(state, draftState => {
        draftState.ctas = action.payload?.ctas?.map(cta => ({
          ...cta.attributes,
          id: cta.id,
          posts: action.payload?.ctaAssociations?.filter(
            el => el.relationships.cta.data.id === cta.id && el.relationships.post.data
          ),
          ctaAssociationId: action.payload?.ctaAssociations
            ? action.payload.ctaAssociations?.find(ctaAssociations => {
                return ctaAssociations.relationships.cta?.data?.id === cta.id;
              })?.id
            : undefined,
          savedFilterId: action.payload?.savedFilter
            ? action.payload?.savedFilter?.find(ctaAssociations => {
                return ctaAssociations.relationships.saved_filter?.data?.id === draftState.ctaAssociationId;
              })?.id
            : undefined,
        }));
        draftState.isFetching = false;
        draftState.isError = false;
      });

    case ACTIONS.POST_CTA.SUCCESS:
      return produce(state, draftState => {
        const currentCTA = {
          ...action.payload.attributes,
          id: action.payload.id,
        };
        draftState.ctas = [currentCTA, ...draftState.ctas];
        draftState.isFetching = false;
        draftState.isError = false;
      });

    case ACTIONS.GET_SEARCH_TINTS.SUCCESS:
      return {
        ...state,
        ...{
          searched: action.payload.tints,
          isFetching: false,
          isError: false,
        },
      };

    case ACTIONS.GET_TINT.SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload,
          isFetching: false,
          isError: false,
        },
      };

    case ACTIONS.PATCH_MODERATION_RULES.SUCCESS:
      return produce(state, draftState => {
        draftState.data.attributes.auto_moderation_rules = action.payload;
      });

    case ACTIONS.PATCH_CTA.SUCCESS:
      return produce(state, draftState => {
        const currentCTAIndex = draftState.ctas.findIndex(cta => cta.id === action.payload.id);

        draftState.ctas[currentCTAIndex] = {
          ...draftState.ctas[currentCTAIndex],
          ...action.payload.data.attributes,
        };
      });

    case ACTIONS.DELETE_CTA.SUCCESS:
      return produce(state, draftState => {
        draftState.ctas = draftState.ctas.filter(cta => cta.id !== action.payload);
      });

    case ACTIONS.MARK_PREVIEW_MODE:
      return produce(state, draft => {
        draft.isPreviewMode = action.payload;
      });

    case ACTIONS.UPDATE_TINT_TAGS:
      return produce(state, draft => {
        draft.data.attributes.tags = [...new Set([...draft.data.attributes.tags, ...action.payload])];
      });

    case ACTIONS.SELECT_TINT:
      return produce(state, draft => {
        draft.selectedTints = [...draft.selectedTints, action.payload];
      });

    case ACTIONS.UNSELECT_TINT:
      return produce(state, draft => {
        draft.selectedTints = draft.selectedTints.filter(({ id }) => {
          return action.payload !== id;
        });
      });

    case ACTIONS.DESELECT_ALL_TINTS:
      return produce(state, draft => {
        draft.selectedTints = [];
      });

    case ACTIONS.DELETE_CTA_ASSOCIATIONS.FAILURE:
    case ACTIONS.APPLY_CTA_ASSOCIATIONS.FAILURE:
    case ACTIONS.POST_CTA_ASSOCIATIONS.FAILURE:
    case ACTIONS.DELETE_CTA.FAILURE:
    case ACTIONS.PATCH_CTA.FAILURE:
    case ACTIONS.POST_CTA.FAILURE:
    case ACTIONS.GET_CTAS.FAILURE:
    case ACTIONS.GET_TINTS.FAILURE:
    case ACTIONS.GET_SEARCH_TINTS.FAILURE:
    case ACTIONS.GET_TINT.FAILURE:
    case ACTIONS.UPDATE_TINT_STATUS.FAILURE:
    case ACTIONS.BULK_UPDATE_TINTS_STATUS.FAILURE:
    case ACTIONS.UPDATE_TINT_PRIVACY.FAILURE:
    case ACTIONS.DELETE_TINT.FAILURE:
    case ACTIONS.BULK_DELETE_TINTS.FAILURE:
    case ACTIONS.PATCH_TINT.FAILURE:
    case ACTIONS.DUPLICATE_TINT.FAILURE:
    case ACTIONS.CREATE_TINT.FAILURE:
      return {
        ...state,
        ...{
          isError: action.payload,
          isFetching: false,
        },
      };
    default:
      return state;
  }
};

export const getPostCTAs = (state, postId) => {
  return (
    state &&
    state?.tintEditor?.ctas?.filter(cta => {
      return cta?.posts?.some(post => post.relationships?.post?.data?.id === postId);
    })
  );
};

export const getTagList = state => {
  //added since backend started to return some random string here
  if (!Array.isArray(state?.tintEditor?.data?.attributes?.tags)) {
    return [];
  }
  return state?.tintEditor?.data?.attributes?.tags;
};
export const getTintList = state => (state && state?.tintEditor?.tints) || [];
export const getSlugName = state => state && state?.tintEditor?.data?.attributes?.slug;
export const getSelectedTints = state => state?.tintEditor?.selectedTints;
