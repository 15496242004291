import React from 'react';
import './button-icon.sass';
import TintIcon from '../icon/icon';

export const TintButtonIconTypes = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  BORDER: 'border',
  DARK: 'dark',
  GREY: 'grey',
  LIGHT: 'light',
  WHITE: 'white',
  NONE: 'none',
  NAVIGATION: 'navigation',
  ERROR: 'error',
};

export const TintButtonIconShape = {
  CIRCLE: 'circle',
  SQUARE: 'square',
};

const TintButtonIcon = ({
  type = TintButtonIconTypes.PRIMARY,
  shape = TintButtonIconShape.SQUARE,
  icon,
  iconSize = 16,
  width,
  height,
  color,
  svgIconSrc,
  isDisabled,
  className,
  onClick,
}) => {
  return (
    <button
      className={`tint-button-icon tint-button-icon--${type} ${className} tint-button-icon-shape--${shape}`}
      disabled={isDisabled}
      onClick={onClick}
      style={{ width: `${width}px`, height: `${height}px` }}>
      <TintIcon icon={icon} svgIconSrc={svgIconSrc} fontSize={iconSize} color={color} />
    </button>
  );
};

export default TintButtonIcon;
