import { ACTIONS } from '../actions/transform-ui.actions';

export const initialState = {
  data: {},
  isFetching: false,
  error: undefined,
};

export const assetManagerTransformUI = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.POST_ASSET.REQUEST:
      return {
        ...state,
        ...{
          isFetching: true,
          error: false,
        },
      };

    case ACTIONS.POST_ASSET.FAILURE:
      return {
        ...state,
        ...{
          error: action.payload,
          isFetching: false,
        },
      };

    default:
      return state;
  }
};
