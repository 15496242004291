export const ExperienceType = {
  SWEEPSTAKE: 'sweepstakes',
  CONTEST: 'contest',
  COUNT_DOWN: 'countdown',
  LADING_PAGE: 'landing_page',
  TAG_O_WAR: 'tag_o_war',
  CONTRIBUTOR: 'contributor',
  EVENT: 'event',
  POLL: 'poll',
  COUNTER: 'counter',
  LINK_IN_BIO: 'link_in_bio',
  SURVEY: 'survey',
  SOCIAL_FEED_COUNTER: 'counter',
  INFLUENCER: 'influencer',
};
