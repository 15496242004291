import React, { useCallback, useEffect, useMemo } from 'react';
import './form-builder.form.interface.matrix.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_INPUT_CLASSES, FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';

const FormBuilderFormInterfaceMatrix = ({
  item,
  styles,
  values,
  formId,
  styleElement,
  styleElementLegend,
  styleElementTable,
  styleElementTbodyTh,
  styleElementTbodyTd,
  styleElementTheadTh,
  styleElementInput,
}) => {
  if (!item) return null;

  const { t } = useTranslation();
  const legend = item.elements[0];
  const table = item.elements[1];
  const thead = table.elements[0];
  const tbody = table.elements[1];
  const theadtr = thead.elements[0];
  const input = tbody.elements[0].elements[1].elements[0];
  const isMultipleMatrix = item?.class?.includes(FORM_BUILDER_INPUT_CLASSES.MULTIPLE_MATRIX);
  const inputs = useMemo(() => {
    return {};
  }, []);

  const onFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
  };

  const validateInputs = useCallback(() => {
    Object.values(inputs)?.some(el => {
      const isAnyInputChecked = el?.some(input => input?.checked);

      if (isAnyInputChecked) {
        el?.forEach(input => {
          input?.setCustomValidity('');
        });
      } else {
        const firstInvalidInput = el?.[0];
        firstInvalidInput?.setCustomValidity('Please tick any box if you want to proceed.');
      }
    });
  }, [inputs]);

  useEffect(() => {
    if (isMultipleMatrix && input?.required) {
      const formDiv = document.querySelector(`[data-form-id="${formId}"] div`);

      tbody.elements.forEach(tr => {
        const tdElements = tr.elements.filter(el => el?.nodeName === 'td');
        tdElements.forEach(td => {
          const input = td.elements[0];

          const inputHTML = formDiv?.shadowRoot?.getElementById(input.id);
          if (inputs?.[input?.name]) {
            inputs[input.name].push(inputHTML);
          } else {
            inputs[input.name] = [inputHTML];
          }
        });
      });

      validateInputs();
    }
  }, [input?.id, input?.required, inputs, formId, isMultipleMatrix, tbody.elements, validateInputs]);

  const onChange = () => {
    if (isMultipleMatrix && input?.required) {
      validateInputs();
    }
  };

  return (
    <fieldset style={{ ...styleElement }} className={`${item.class}`}>
      <legend
        style={{
          ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.LABEL],
          ...styleElementLegend,
        }}
        id={legend.id}>
        {`${t(legend?.nodeValue)}${input?.required ? '*' : ''}`}
      </legend>
      <table style={{ ...styleElementTable }} aria-describedby={table['aria-describedby']}>
        <thead>
          <tr>
            {theadtr.elements.map((th, i) => (
              <th style={{ ...styleElementTheadTh }} id={th.id} key={`${t(th.nodeValue)}-${i}`}>
                {t(th.nodeValue)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {tbody.elements.map((tr, i) => {
            return (
              <tr key={i}>
                {tr.elements.map((el, i) => {
                  const Tag = el.nodeName;
                  return (
                    <Tag
                      style={Tag === 'td' ? { ...styleElementTbodyTd } : { ...styleElementTbodyTh }}
                      id={el?.id}
                      key={i}
                      data-label={Tag === 'td' ? t(theadtr.elements[i].nodeValue) : ''}>
                      {el?.elements
                        ? el.elements.map(el => {
                            const defaultValue = values?.getAll(el.name);
                            return (
                              <input
                                onFocus={onFocus}
                                key={i}
                                type={el.type}
                                id={el.id}
                                name={el.name}
                                value={el.value}
                                defaultChecked={defaultValue?.indexOf(el?.value) >= 0}
                                aria-labelledby={el['aria-labelledby']}
                                required={!isMultipleMatrix ? el?.required : false}
                                style={{ ...styleElementInput }}
                                onChange={onChange}
                              />
                            );
                          })
                        : t(el.nodeValue)}
                    </Tag>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </fieldset>
  );
};

FormBuilderFormInterfaceMatrix.displayName = 'FormBuilderFormInterfaceMatrix';
export default React.memo(FormBuilderFormInterfaceMatrix);
