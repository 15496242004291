import React from 'react';
import './search-list.sass';
import * as PropTypes from 'prop-types';

export const SearchListType = {
  IMAGES: 'image',
  VIDEOS: 'video',
  NONE: 'none',
};

const SearchList = ({ images, videos, searchTerm, onChange }) => {
  const isNoValue = images.length > 0 || videos.length > 0;
  const maxCountItem = 9;

  const renderListItems = (list, title, icon, type) => {
    return (
      list &&
      list.length > 0 && (
        <div className='tint-search-input-list__group'>
          <span className='tint-search-input-list__label'>
            <span className={`icon ${icon}`} />
            {title}
          </span>
          <ul className='search-list'>
            {list.map((object, i) =>
              i <= maxCountItem ? (
                <li
                  data-testid={`searchListItem_${i}`}
                  className='search-list__item'
                  key={i}
                  onClick={() => onChange(object, type)}>
                  <span>{markTextAsSelect(object.name)}</span>
                </li>
              ) : null
            )}
          </ul>
        </div>
      )
    );
  };

  const markTextAsSelect = value => {
    if (!value) {
      return '';
    }
    const indexOfValue = value.toLowerCase().indexOf(searchTerm.toLowerCase());

    return (
      <>
        {value.substr(0, indexOfValue)}
        <span className='light'>{value.substr(indexOfValue, searchTerm.length)}</span>
        {value.substr(indexOfValue + searchTerm.length, value.length)}
      </>
    );
  };

  return (
    <div className='tint-search-input-list' data-testid='dropdownSearchInputList'>
      {isNoValue ? (
        <>
          {renderListItems(images, 'Images', 'fas fa-image', SearchListType.IMAGES)}
          {renderListItems(videos, 'Videos', 'fas fa-video', SearchListType.VIDEOS)}
        </>
      ) : (
        <span className='tint-search-input-list__no-found'>No items found</span>
      )}
    </div>
  );
};

export default SearchList;

SearchList.propTypes = {
  images: PropTypes.array,
  videos: PropTypes.array,
  searchTerm: PropTypes.string,
  onChange: PropTypes.func,
};
