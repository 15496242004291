import { instagramDisabledButton, SocialFeedsFooterLabels } from './helpers';
import { SidebarIcons, SidebarItemTypes } from '../utils/meta/consts';
import {
  validationSchemaInstagramBrandContent,
  validationSchemaInstagramMentions,
  validationSchemaInstagramSocialListening,
  validationSchemaInstagramStories,
  validationSchemaInstagramTagged,
  validationSchemaIntagramHashtags,
  validationSchemaIntagramMentions,
} from '../utils/validation-schema/instagram-schema';
import { GuardNames } from '../../../../../../../guards/guards-list';

const dropdownUserNameDefaultValue = '';

const clearSubAccountsDropdown = formik => formik.setFieldValue('username', dropdownUserNameDefaultValue);

export const instagram_business = [
  {
    header: 'Add Instagram',
    description: 'Choose a Social Feed type below:',
    typeList: [
      {
        id: 'posted',
        title: 'Brand Content',
        description: "This social feed type allows you to access your authorized Instagram Account's posts.",
      },
      {
        id: 'stories',
        title: 'Stories',
        description: "This social feed type allows you to access your authorized Instagram Account's stories.",
      },
      {
        id: 'tagged',
        title: 'Tagged',
        description:
          'This social feed type allows you to access Instagram Posts in which your business has been photo tagged.',
      },
      {
        id: 'mentioned',
        title: 'Mentions',
        description:
          'This social feed type allows you to access Instagram Posts in which your business has been mentioned.',
      },
      {
        id: 'hashtags',
        title: 'Hashtags',
        description:
          'This social feed type allows you to find public Instagram photos and videos tagged with a chosen #hashtag.',
      },
      {
        id: 'comments',
        title: 'Comment Mentions',
        description:
          'This social feed type allows you to access Instagram Posts where your business was mentioned in comments.',
      },
      {
        id: 'social_listening',
        title: 'Social Listening',
        description:
          'This social feed type allows you to access public Instagram Posts for an Instagram business or creator',
        featureName: GuardNames.TINT_EDITOR_SOCIAL_FEED_INSTAGRAM,
      },
    ],
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepNext,
    },
    zendeskUrl: undefined,
    isAlert: false,
    formSchema: undefined,
  },
  {
    header: undefined,
    description: undefined,
    zendeskUrl: undefined,
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.finalStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext,
    },
    accountSourceType: 'instagram_business',
    formSchema: {
      posted: {
        validationSchema: validationSchemaInstagramBrandContent(),
        footerCopy: '',
        submitMapper: values => ({
          external_id: values.username.value,
          account_id: values.account.value,
        }),
        inputs: [
          {
            id: 'account',
            backendId: 'account_id',
            label: 'SELECT ACCOUNT',
            placeholder: 'Select Account...',
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
          },
          {
            id: 'username',
            backendId: 'external_id',
            label: 'SELECT USERNAME',
            placeholder: 'Select Username...',
            description: 'To ingest branded content, please select an Instagram username below:',
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.SELECT,
            disabled: instagramDisabledButton,
          },
        ],
      },
      stories: {
        validationSchema: validationSchemaInstagramStories(),
        footerCopy:
          'Please Note: All content <strong>24 hours old or newer</strong> will be collected upon connecting your stories to TINT. Live videos and reshares are excluded.',
        submitMapper: values => ({
          external_id: values.username.value,
          account_id: values.account.value,
        }),
        inputs: [
          {
            id: 'account',
            backendId: 'account_id',
            label: 'SELECT ACCOUNT',
            placeholder: 'Select Account...',
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
            clearSubAccountsDropdown,
          },
          {
            id: 'username',
            backendId: 'external_id',
            label: 'SELECT USERNAME',
            placeholder: 'Select Username...',
            description: 'To ingest Stories, please select an Instagram username below:',
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.SELECT,
            disabled: instagramDisabledButton,
          },
        ],
      },
      tagged: {
        validationSchema: validationSchemaInstagramTagged(),
        footerCopy:
          'Note: This social feed type allows you to access Instagram Posts in which your business has been photo tagged.',
        submitMapper: values => ({
          external_id: values.username.value,
          account_id: values.account.value,
        }),
        inputs: [
          {
            id: 'account',
            backendId: 'account_id',
            label: 'SELECT ACCOUNT',
            placeholder: 'Select Account...',
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
            clearSubAccountsDropdown,
          },
          {
            id: 'username',
            backendId: 'external_id',
            label: 'SELECT USERNAME',
            placeholder: 'Select Username...',
            description: 'To ingest tagged posts, please select an Instagram username below:',
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.SELECT,
            disabled: instagramDisabledButton,
          },
        ],
      },
      mentioned: {
        validationSchema: validationSchemaInstagramMentions(),
        footerCopy:
          'Please Note: Unlike many other TINT social feeds, Mentions cannot retrieve historical content. Only new @mentions posted to Instagram after you add this social feed will be aggregated.',
        submitMapper: values => ({
          external_id: values.username.value,
          account_id: values.account.value,
        }),
        inputs: [
          {
            id: 'account',
            backendId: 'account_id',
            label: 'SELECT ACCOUNT',
            placeholder: 'Select Account...',
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
            clearSubAccountsDropdown,
          },
          {
            id: 'username',
            backendId: 'external_id',
            label: 'SELECT USERNAME',
            placeholder: 'Select Username...',
            description:
              'To ingest posts in which your business has been mentioned, please select an Instagram username below:',
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.SELECT,
            disabled: instagramDisabledButton,
          },
        ],
      },
      hashtags: {
        validationSchema: validationSchemaIntagramHashtags(),
        submitMapper: values => ({
          external_id: values.username.value,
          account_id: values.account.value,
          search_term: `#${values.hashtag}`,
        }),
        footerCopy:
          'This social feed type allows you to find public photos and videos that have been tagged with a #hashtag relevant to your authorized Business Account. All content <strong>24 hours old or newer</strong> will be collected upon connecting your hashtag to TINT.',
        inputs: [
          {
            id: 'account',
            backendId: 'account_id',
            label: 'SELECT ACCOUNT',
            placeholder: 'Select Account...',
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
            clearSubAccountsDropdown,
          },
          {
            id: 'username',
            backendId: 'external_id',
            label: 'SELECT USERNAME',
            placeholder: 'Select Username...',
            description: 'To ingest hashtag content, please select an Instagram username below:',
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.SELECT,
            disabled: instagramDisabledButton,
          },
          {
            id: 'hashtag',
            backendId: 'search_term',
            label: 'ENTER HASHTAG',
            placeholder: 'Enter hashtag',
            icon: SidebarIcons.HASHTAG,
            type: SidebarItemTypes.TEXT,
            disabled: instagramDisabledButton,
          },
        ],
      },
      comments: {
        validationSchema: validationSchemaIntagramMentions(),
        submitMapper: values => ({
          external_id: values.username.value,
          account_id: values.account.value,
        }),
        footerCopy:
          'Please Note: Unlike many other TINT social feeds, Comment Mentions cannot retrieve historical content. Only new comment @mentions posted to Instagram after you add this social feed will be aggregated. We ingest the posts, not the comment themselves.',
        inputs: [
          {
            id: 'account',
            backendId: 'account_id',
            label: 'SELECT ACCOUNT',
            placeholder: 'Select Account...',
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
            clearSubAccountsDropdown,
          },
          {
            id: 'username',
            backendId: 'external_id',
            label: 'SELECT USERNAME',
            placeholder: 'Select Username...',
            description:
              'To ingest Instagram Posts in which your business has been mentioned in the comments, please select an Instagram username below:',
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.SELECT,
            disabled: instagramDisabledButton,
          },
        ],
      },
      social_listening: {
        validationSchema: validationSchemaInstagramSocialListening(),
        submitMapper: values => ({
          external_id: values.username.value,
          account_id: values.account.value,
          search_term: values.searchTerm,
        }),
        footerCopy: '',
        inputs: [
          {
            id: 'account',
            backendId: 'account_id',
            label: 'SELECT ACCOUNT',
            placeholder: 'Select Account...',
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
            clearSubAccountsDropdown,
          },
          {
            id: 'username',
            backendId: 'external_id',
            label: 'SELECT USERNAME',
            placeholder: 'Select Username...',
            description: 'Select an Instagram username below:',
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.SELECT,
            disabled: instagramDisabledButton,
          },
          {
            id: 'searchTerm',
            backendId: 'search_term',
            label: 'ENTER USERNAME',
            placeholder: 'ENTER USERNAME',
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.TEXT,
            disabled: instagramDisabledButton,
          },
        ],
      },
    },
  },
];
