import { ACTIONS } from '../../actions/sidebar';

export const initialState = {
  modalType: undefined,
  data: {},
};

const sidebar = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_SIDEBAR_MODAL:
      return {
        ...state,
        ...action.payload,
      };

    case ACTIONS.CLOSE_SIDEBAR_MODAL:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default sidebar;
