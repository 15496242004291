import { ACTIONS } from '../actions/performance/insights-performance.actions';
import { ACTIONS as GLOBAL_INSIGHTS_ACTIONS } from '../actions/content/insights-content.actions';
import { produce } from 'immer';
import { parseISO, format, differenceInDays, addDays } from 'date-fns';

import { PERSONALIZATION_TYPE } from '../components/graphs/performance/top-used-omnichannel-types/top-used-omnichannel-types';
import { THEMES } from '../../tint-editor/schemas/themes';
import { MEDIA_TYPE } from '../components/graphs/performance/engagement-by-type/engagement-by-type';
import { DEVICE_TYPE } from '../components/graphs/performance/engagement-per-device/engagement-per-device';

export const initialState = {
  activeVisitorsCount: {
    data: undefined,
    isFetching: false,
  },
  engagementCount: {
    data: undefined,
    isFetching: false,
  },
  engagementPostCount: {
    data: undefined,
    isFetching: false,
  },
  engagementCTACount: {
    data: undefined,
    isFetching: false,
  },
  engagementImpressions: {
    data: undefined,
    isFetching: false,
  },
  topUsedOmnichannelTypes: {
    data: undefined,
    isFetching: false,
  },
  dailyEmbedViews: {
    data: undefined,
    isFetching: false,
  },
  dailyEmbedClicks: {
    data: undefined,
    isFetching: false,
  },
  topUsedThemes: {
    data: undefined,
    isFetching: false,
  },
  engagementByType: {
    data: undefined,
    isFetching: false,
  },
  engagementPerDevice: {
    data: undefined,
    isFetching: false,
  },
  visitorDemographic: {
    data: undefined,
    isFetching: false,
  },
  embeddedLocation: {
    isFetching: false,
    data: undefined,
  },
};

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const insightsPerformance = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_INSIGHTS_ACTIONS.CLEAR_ALL_INSIGHTS:
      return initialState;

    case ACTIONS.GET_ENGAGEMENT_CTA_COUNT.FAILURE:
      return produce(state, draftState => {
        draftState.engagementCTACount.isFetching = false;
      });

    case ACTIONS.GET_ENGAGEMENT_CTA_COUNT.REQUEST:
      return produce(state, draftState => {
        draftState.engagementCTACount.isFetching = true;
      });

    case ACTIONS.GET_ENGAGEMENT_CTA_COUNT.SUCCESS:
      return produce(state, draftState => {
        draftState.engagementCTACount.isFetching = false;
        draftState.engagementCTACount.data = action.payload;
      });

    case ACTIONS.GET_ENGAGEMENT_POST_COUNT.FAILURE:
      return produce(state, draftState => {
        draftState.engagementPostCount.isFetching = false;
      });

    case ACTIONS.GET_ENGAGEMENT_POST_COUNT.REQUEST:
      return produce(state, draftState => {
        draftState.engagementPostCount.isFetching = true;
      });

    case ACTIONS.GET_ENGAGEMENT_POST_COUNT.SUCCESS:
      return produce(state, draftState => {
        draftState.engagementPostCount.isFetching = false;
        draftState.engagementPostCount.data = action.payload;
      });

    case ACTIONS.GET_ENGAGEMENT_COUNT.FAILURE:
      return produce(state, draftState => {
        draftState.engagementCount.isFetching = false;
      });

    case ACTIONS.GET_ENGAGEMENT_COUNT.REQUEST:
      return produce(state, draftState => {
        draftState.engagementCount.isFetching = true;
      });

    case ACTIONS.GET_ENGAGEMENT_COUNT.SUCCESS:
      return produce(state, draftState => {
        draftState.engagementCount.isFetching = false;
        draftState.engagementCount.data = action.payload;
      });

    case ACTIONS.GET_ACTIVE_VISITORS_COUNT.FAILURE:
      return produce(state, draftState => {
        draftState.activeVisitorsCount.isFetching = false;
      });

    case ACTIONS.GET_ACTIVE_VISITORS_COUNT.REQUEST:
      return produce(state, draftState => {
        draftState.activeVisitorsCount.isFetching = true;
      });

    case ACTIONS.GET_ACTIVE_VISITORS_COUNT.SUCCESS:
      return produce(state, draftState => {
        draftState.activeVisitorsCount.isFetching = false;
        draftState.activeVisitorsCount.data = action.payload;
      });

    case ACTIONS.GET_DAILY_EMBED_CLICKS.FAILURE:
      return produce(state, draftState => {
        draftState.dailyEmbedClicks.isFetching = false;
      });

    case ACTIONS.GET_DAILY_EMBED_CLICKS.REQUEST:
      return produce(state, draftState => {
        draftState.dailyEmbedClicks.isFetching = true;
      });

    case ACTIONS.GET_DAILY_EMBED_CLICKS.SUCCESS:
      return produce(state, draftState => {
        draftState.dailyEmbedClicks.isFetching = false;
        draftState.dailyEmbedClicks.data = action.payload;
      });

    case ACTIONS.GET_DAILY_EMBED_VIEWS.FAILURE:
      return produce(state, draftState => {
        draftState.dailyEmbedViews.isFetching = false;
      });

    case ACTIONS.GET_DAILY_EMBED_VIEWS.REQUEST:
      return produce(state, draftState => {
        draftState.dailyEmbedViews.isFetching = true;
      });

    case ACTIONS.GET_DAILY_EMBED_VIEWS.SUCCESS:
      return produce(state, draftState => {
        draftState.dailyEmbedViews.isFetching = false;
        draftState.dailyEmbedViews.data = action.payload;
      });

    case ACTIONS.GET_VISITOR_DEMOGRAPHIC.FAILURE:
      return produce(state, draftState => {
        draftState.visitorDemographic.isFetching = false;
      });

    case ACTIONS.GET_VISITOR_DEMOGRAPHIC.REQUEST:
      return produce(state, draftState => {
        draftState.visitorDemographic.isFetching = true;
      });

    case ACTIONS.GET_VISITOR_DEMOGRAPHIC.SUCCESS:
      return produce(state, draftState => {
        draftState.visitorDemographic.isFetching = false;
        draftState.visitorDemographic.data = action.payload;
      });

    case ACTIONS.GET_ENGAGEMENT_PER_DEVICE.FAILURE:
      return produce(state, draftState => {
        draftState.engagementPerDevice.isFetching = false;
      });

    case ACTIONS.GET_ENGAGEMENT_PER_DEVICE.REQUEST:
      return produce(state, draftState => {
        draftState.engagementPerDevice.isFetching = true;
      });

    case ACTIONS.GET_ENGAGEMENT_PER_DEVICE.SUCCESS:
      return produce(state, draftState => {
        draftState.engagementPerDevice.isFetching = false;
        draftState.engagementPerDevice.data = action.payload;
      });

    case ACTIONS.GET_ENGAGEMENT_BY_TYPE.FAILURE:
      return produce(state, draftState => {
        draftState.engagementByType.isFetching = false;
      });

    case ACTIONS.GET_ENGAGEMENT_BY_TYPE.REQUEST:
      return produce(state, draftState => {
        draftState.engagementByType.isFetching = true;
      });

    case ACTIONS.GET_ENGAGEMENT_BY_TYPE.SUCCESS:
      return produce(state, draftState => {
        draftState.engagementByType.isFetching = false;
        draftState.engagementByType.data = action.payload;
      });

    case ACTIONS.GET_TOP_USED_OMNICHANNEL_TYPES.FAILURE:
      return produce(state, draftState => {
        draftState.topUsedOmnichannelTypes.isFetching = false;
      });

    case ACTIONS.GET_TOP_USED_OMNICHANNEL_TYPES.REQUEST:
      return produce(state, draftState => {
        draftState.topUsedOmnichannelTypes.isFetching = true;
      });

    case ACTIONS.GET_TOP_USED_OMNICHANNEL_TYPES.SUCCESS:
      return produce(state, draftState => {
        draftState.topUsedOmnichannelTypes.isFetching = false;
        draftState.topUsedOmnichannelTypes.data = action.payload;
      });

    case ACTIONS.GET_TOP_USED_THEMES.FAILURE:
      return produce(state, draftState => {
        draftState.topUsedThemes.isFetching = false;
      });

    case ACTIONS.GET_TOP_USED_THEMES.REQUEST:
      return produce(state, draftState => {
        draftState.topUsedThemes.isFetching = true;
      });

    case ACTIONS.GET_TOP_USED_THEMES.SUCCESS:
      return produce(state, draftState => {
        draftState.topUsedThemes.isFetching = false;
        draftState.topUsedThemes.data = action.payload;
      });

    case ACTIONS.GET_ENGAGEMENT_IMPRESSIONS.FAILURE:
      return produce(state, draftState => {
        draftState.engagementImpressions.isFetching = false;
      });

    case ACTIONS.GET_ENGAGEMENT_IMPRESSIONS.REQUEST:
      return produce(state, draftState => {
        draftState.engagementImpressions.isFetching = true;
      });

    case ACTIONS.GET_ENGAGEMENT_IMPRESSIONS.SUCCESS:
      return produce(state, draftState => {
        draftState.engagementImpressions.isFetching = false;
        draftState.engagementImpressions.data = action.payload;
      });

    case ACTIONS.GET_TINT_EMBED_LOCATION.REQUEST:
      return produce(state, draftState => {
        draftState.embeddedLocation.isFetchin = false;
      });

    case ACTIONS.GET_TINT_EMBED_LOCATION.FAILURE:
      return produce(state, draftState => {
        draftState.embeddedLocation.isFetchin = false;
      });

    case ACTIONS.GET_TINT_EMBED_LOCATION.SUCCESS:
      return produce(state, draftState => {
        draftState.embeddedLocation.isFetchin = false;
        draftState.embeddedLocation.data = action.payload;
      });

    default:
      return state;
  }
};

export const getEngagementPostCountData = state => {
  if (!state || !state.engagementPostCount.data) {
    return {};
  }
};

export const getActiveUsersCountData = state => {
  if (!state || !state.activeVisitorsCount.data) {
    return {};
  }
};

export const getDailyEmbedViewsData = (state, insightsState) => {
  if (!state || !state.dailyEmbedViews?.data) {
    return {};
  }

  const { data } = state.dailyEmbedViews.data;

  const _helperArr = data.map(e => ({
    time: parseISO(e.attributes.dimensions.time),
    name: `${monthNames[parseISO(e.attributes.dimensions.time).getMonth()]?.substring(0, 3)} ${parseISO(
      e.attributes.dimensions.time
    ).getDate()}`,
    count: Number(e.attributes.count),
  }));

  let { startDate, endDate } = insightsState.filter.date;

  const ensureISOString = date => {
    if (typeof date === 'string') {
      return date;
    } else if (typeof date === 'number') {
      return new Date(date).toISOString();
    } else if (date instanceof Date) {
      return date.toISOString();
    } else {
      throw new Error(`Unsupported date format: ${date}`);
    }
  };

  try {
    startDate = ensureISOString(startDate);
    endDate = ensureISOString(endDate);
  } catch (error) {
    console.error('Invalid date format:', { startDate, endDate });
    return [];
  }

  const start = parseISO(startDate);
  const end = parseISO(endDate);

  const days = differenceInDays(end, start);
  const dayArray = [];

  for (let i = 0; i <= days; i++) {
    dayArray.push(format(addDays(start, i), 'yyyy-MM-dd'));
  }

  const _arr = dayArray.map(e => {
    const time = parseISO(e);
    const element = _helperArr.find(f => format(f.time, 'yyyy-MM-dd') === e);
    return {
      time: time,
      name: `${monthNames[time.getMonth()]?.substring(0, 3)} ${time.getDate()}`,
      count: element ? Number(element.count) : 0,
    };
  });

  const sum = _helperArr.reduce((a, b) => a + Number(b.count), 0);
  return { data: _arr, totalPosts: sum };
};

export const getDailyEmbedClicks = (state, insightsState) => {
  if (!state || !state.dailyEmbedClicks?.data) {
    return {};
  }

  const { data } = state.dailyEmbedClicks.data;

  const _helperArr = data.map(e => ({
    time: parseISO(e.attributes.dimensions.time),
    name: `${monthNames[parseISO(e.attributes.dimensions.time).getMonth()]?.substring(0, 3)} ${parseISO(
      e.attributes.dimensions.time
    ).getDate()}`,
    count: Number(e.attributes.count),
  }));

  let { startDate, endDate } = insightsState.filter.date;

  const ensureISOString = date => {
    if (typeof date === 'string') {
      return date;
    } else if (typeof date === 'number') {
      return new Date(date).toISOString();
    } else if (date instanceof Date) {
      return date.toISOString();
    } else {
      throw new Error(`Unsupported date format: ${date}`);
    }
  };

  try {
    startDate = ensureISOString(startDate);
    endDate = ensureISOString(endDate);
  } catch (error) {
    console.error('Invalid date format:', { startDate, endDate });
    return [];
  }

  const start = parseISO(startDate);
  const end = parseISO(endDate);

  const days = differenceInDays(end, start);
  const dayArray = [];

  for (let i = 0; i <= days; i++) {
    dayArray.push(format(addDays(start, i), 'yyyy-MM-dd'));
  }

  const _arr = dayArray.map(e => {
    const time = parseISO(e);
    const element = _helperArr.find(f => format(f.time, 'yyyy-MM-dd') === e);
    return {
      time: time,
      name: `${monthNames[time.getMonth()]?.substring(0, 3)} ${time.getDate()}`,
      count: element ? Number(element.count) : 0,
    };
  });

  const sum = _helperArr.reduce((a, b) => a + Number(b.count), 0);
  return { data: _arr, totalPosts: sum };
};

export const getTopUsedOmnichannelTypesData = state => {
  if (!state || !state.topUsedOmnichannelTypes.data) {
    return [];
  }
  const { data } = state.topUsedOmnichannelTypes.data;
  const sum = data.reduce((a, b) => a + Number(b.attributes.count), 0);
  const returnArray = data
    .map(e => {
      return {
        percentage: Math.round((Number(e.attributes.count) * 100) / sum),
        count: e.attributes.count,
        type: e.attributes.dimensions.personalization_type,
      };
    })
    .sort((a, b) => b.count - a.count);

  Object.values(PERSONALIZATION_TYPE).map(e => {
    const item = returnArray.find(f => f.type === e);
    if (!item) {
      returnArray.push({ percentage: 0, count: 0, type: e });
    }
  });
  return returnArray.filter(e => e.count !== 0);
};

export const getTopUsedThemesData = state => {
  if (!state || !state.topUsedThemes.data) {
    return [];
  }
  //FIXME that shouldn't be here! we shouldn't use stuff from another module
  const themeObject = {};

  THEMES.map(e => {
    themeObject[e.key] = e;
  });

  const { data } = state.topUsedThemes.data;
  const sum = data.reduce((a, b) => a + Number(b.attributes.count), 0);
  const returnArray = data
    .map(e => {
      return {
        percentage: Math.round((Number(e.attributes.count) * 100) / sum),
        count: e.attributes.count,
        theme: themeObject[e.attributes.dimensions.theme]?.name,
      };
    })
    .sort((a, b) => b.count - a.count);
  THEMES.map(e => {
    const item = returnArray.find(f => f.theme === e.name);
    if (!item) {
      returnArray.push({ percentage: 0, count: 0, theme: e.name });
    }
  });
  return returnArray.filter(e => e.count !== 0);
};

export const getEngagementPerDevice = state => {
  if (!state || !state.engagementPerDevice.data) {
    return [];
  }
  const { data } = state.engagementPerDevice.data;
  const sum = data.reduce((a, b) => a + Number(b.attributes.count), 0);
  const returnArray = data
    .map(e => {
      const media_type = e.attributes.dimensions.device_category;
      return {
        name: media_type,
        value: Number(e.attributes.count),
        percentage: Math.round((Number(e.attributes.count) * 100) / sum),
        type: 'pie',
      };
    })
    .filter(e => e.name !== null)
    .sort((a, b) => b.value - a.value);
  Object.values(DEVICE_TYPE).map(e => {
    const item = returnArray.find(f => f.name === e);
    if (!item) {
      returnArray.push({ name: e, percentage: 0, value: 0, type: 'pie' });
    }
  });

  return returnArray.filter(e => e.value !== 0);
};

export const getEngagementByTypeData = state => {
  if (!state || !state.engagementByType.data) {
    return [];
  }
  const { data } = state.engagementByType.data;
  const sum = data.reduce((a, b) => a + Number(b.attributes.count), 0);
  const returnArray = data
    .map(e => {
      const media_type = e.attributes.dimensions.media_type;
      return {
        name: media_type,
        value: Number(e.attributes.count),
        percentage: Math.round((Number(e.attributes.count) * 100) / sum),
        type: 'pie',
      };
    })
    .sort((a, b) => b.value - a.value);
  Object.values(MEDIA_TYPE).map(e => {
    const item = returnArray.find(f => f.name === e);
    if (!item) {
      returnArray.push({ name: e, percentage: 0, value: 0, type: 'pie' });
    }
  });

  return returnArray.filter(e => e.value !== 0);
};

export const getVisitorDemographicData = state => {
  if (!state || !state.visitorDemographic.data) {
    return [];
  }
  const { data } = state.visitorDemographic.data;
  const _returnArray = [];
  data.map(e => {
    const _elem = { name: e.attributes.dimensions.remote_ip_country, count: e.attributes.count };
    _returnArray.push(_elem);
  });
  return _returnArray;
};

export const hasAllPerformanceInsightsData = state => {
  return (
    !!state.activeVisitorsCount.data ||
    (!state.activeVisitorsCount.isFetching && !!state.engagementCount.data) ||
    (!state.engagementCount.isFetching && !!state.engagementPostCount.data) ||
    (!state.engagementPostCount.isFetching && !!state.engagementCTACount.data) ||
    (!state.engagementCTACount.isFetching && !!state.engagementImpressions.data) ||
    (!state.engagementImpressions.isFetching && !!state.topUsedOmnichannelTypes.data) ||
    (!state.topUsedOmnichannelTypes.isFetching && !!state.dailyEmbedViews.data) ||
    (!state.dailyEmbedViews.isFetching && !!state.dailyEmbedClicks.data) ||
    (!state.dailyEmbedClicks.isFetching && !!state.topUsedThemes.data) ||
    (!state.topUsedThemes.isFetching && !!state.engagementByType.data) ||
    (!state.engagementByType.isFetching && !!state.engagementPerDevice.data) ||
    (!state.engagementPerDevice.isFetching && !!state.visitorDemographic.data) ||
    !state.visitorDemographic.isFetching
  );
};
