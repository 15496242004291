import { SocialPublishingPath } from '../../feature/social-publishing/utils/social-publishing.paths';
import { formBuilderRoutePaths } from '../../feature/form-builder/utils/form-builder.route-paths';
import { missionHubsRoutePaths } from '../../feature/mission-hubs/utils/mission-hubs.route-paths';

export const routePaths = {
  main: {
    title: 'Boards',
    react_path: '/app/tints',
    path: '/app/',
    settings: {
      path: '/app/settings',
    },
  },
  socialPublishing: {
    ...SocialPublishingPath,
  },
  billing: {
    title: 'Billing',
    path: '/app/billing',
  },
  upgrade: {
    title: 'No team',
    path: '/app/upgrade',
  },
  newTeam: {
    title: 'Welcome to TINT',
    path: '/app/teams/new',
    createUrl: ({ plan }) => `/app/teams/new?plan=${plan}`,
  },
  mediaComposer: {
    title: 'Media Composer',
    plainPath: '/app/media-composer',
    path: '/app/media-composer/:assetId',
    createUrl: ({ assetId }) => `/app/media-composer/${assetId}`,
  },
  personalizations: {
    title: 'Personalizations List',
    path: '/app/personalizations',

    personalization: {
      title: 'Personalization builder',
      path: '/app/personalizations/:id',
      plainPath: '/app/personalizations/',
      createUrl: id => `/app/personalizations/${id}`,
    },
  },
  discover: {
    title: 'Launchpad',
    path: '/app/',
    details: {
      title: 'Discover',
      path: '/app/details/:category/:title',
      createUrl: (category, title) => `/app/details/${category}/${title}`,
    },
  },
  creatorsAndInfluencers: {
    title: 'Creators & Influencers',
    path: '/app/influencer-discovery',
  },
  formBuilder: { ...formBuilderRoutePaths },
  missionHubs: { ...missionHubsRoutePaths },
  subscribePlan: {
    title: 'Subscribe',
    path: '/app/subscribe',
  },
  planAddons: {
    title: 'Plan Add-Ons',
    path: '/app/plan-addons',
  },
  authVerifier: {
    title: 'Auth Verifier',
    path: '/app/auth-verifier',
  },
  assetManager: {
    title: 'UGC Studio',
    myAssetsTitle: 'My Assets',
    path: '/app/asset-manager',
    archived: {
      title: 'Archived',
      path: '/app/asset-manager/archived',
    },
    asset: {
      path: '/app/asset-manager/asset/:assetId',
      creatUrl: assetId => `/app/asset-manager/asset/${assetId}`,
    },
    gifBuilder: {
      path: '/app/asset-manager/gif-builder',
    },
    collections: {
      title: 'My Collections',
      path: '/app/asset-manager/collections',
      specified: {
        path: '/app/asset-manager/collections/:collectionId',
        creatUrl: collectionId => `/app/asset-manager/collections/${collectionId}`,
      },
    },
  },
  transformUI: {
    title: 'Transform UI',
    path: '/app/transform-ui/asset/:assetId',
    createUrl: assetId => `/app/transform-ui/asset/${assetId}`,
  },
  tintMix: {
    title: 'TINTmix',
    path: '/admin/mix',
  },
  newTintMix: {
    title: 'TINTmix',
    path: '/app/tint-mix',
    list: {
      path: '/app/tint-mix/list',
    },
    scene: {
      path: '/app/tint-mix/:tintMixId',
      createUrl: tintMixId => `/app/tint-mix/${tintMixId}`,
    },
    new: {
      path: '/app/tint-mix/new',
    },
    mix: {
      path: '/mix',
      preview: {
        path: '/mix/:tintMixId',
        createUrl: tintMixId => `/mix/${tintMixId}`,
      },
    },
  },
  insights: {
    title: 'Insights',
    path: '/app/insights',
    content: {
      title: 'Content',
      path: '/app/insights/content',
    },
    performance: {
      title: 'Performance',
      path: '/app/insights/performance',
    },
    social: {
      title: 'Social',
      path: '/app/insights/social/:id',
      overview: {
        title: 'Overview',
        path: '/app/insights/social/overview',
      },
      audience: {
        title: 'Audience',
        path: '/app/insights/social/audience',
      },
      posts: {
        title: 'Posts',
        path: '/app/insights/social/posts',
      },
      stories: {
        title: 'Stories',
        path: '/app/insights/social/stories',
      },
      trends: {
        title: 'Trends',
        path: '/app/insights/social/trends',
      },
    },
    commerce: {
      title: 'Commerce',
      path: '/app/insights/commerce',
    },
  },
  experienceBuilder: {
    title: 'Experience Builder',
    path: '/app/experiences',
    new: {
      path: '/app/experiences/new',
    },
    experience: {
      path: '/app/experiences/:id',
      createUrl: id => `/app/experiences/${id}`,
    },
    preview: {
      path: '/app/experiences/:id/preview',
      createUrl: id => `/app/experiences/${id}/preview`,
    },
    submission: {
      path: '/app/experiences/:id/submissions',
      createUrl: id => `/app/experiences/${id}/submissions`,
    },
    customDomains: {
      title: 'Custom Domains',
      path: '/app/experiences/custom-domains',
    },
  },
  personalSettings: {
    title: 'Personal Settings',
    path: '/app/profile',
    information: {
      title: 'Profile',
      path: '/app/profile/information',
    },
    accountSecurity: {
      title: 'Account Security',
      path: '/app/profile/security',
    },
    supportAccess: {
      title: 'Support Access',
      path: '/app/profile/support-access',
    },
    api: {
      path: '/app/profile/api',
    },
    teamWorkspaces: {
      path: '/app/profile/team-workspaces',
    },
    deleteAccount: {
      path: '/app/profile/delete-account',
    },
  },
  accountSettings: {
    title: 'Team Settings',
    path: '/app/account-settings/:id',
  },
  teamSettings: {
    title: 'Team Settings',
    plainPath: '/app/team-settings',
    path: '/app/team-settings/:id',
    socialConnections: {
      title: 'Social Accounts',
      path: '/app/team-settings/social-connections',

      socialConnectionsEdit: {
        path: '/app/team-settings/social-connections/:id',
        createUrl: id => `/app/team-settings/social-connections/${id}`,
      },
    },
    marketingIntegrations: {
      title: 'Integrations',
      path: '/app/team-settings/integrations',
    },
    emailDomain: {
      title: 'Email Domains',
      path: '/app/team-settings/email-domains/:id',
      createUrl: id => `/app/team-settings/email-domains/${id}`,
    },
    emailDomains: {
      title: 'Email Domains',
      path: '/app/team-settings/email-domains',
    },
    customFonts: {
      title: 'Fonts',
      path: '/app/team-settings/fonts',
    },
    roles: {
      title: 'Roles',
      path: '/app/team-settings/roles',

      rolesEdit: {
        path: '/app/team-settings/roles/:id',
        createUrl: id => {
          return `/app/team-settings/roles/${id}`;
        },
      },
      rolesAdd: {
        path: '/app/team-settings/roles/new',
      },
    },
    security: {
      title: 'Security',
      path: '/app/team-settings/security',
    },
    collaborators: {
      title: 'Collaborators',
      path: '/app/team-settings/collaborators',

      collaboratorsEdit: {
        path: '/app/team-settings/collaborators/:id',
        createUrl: id => {
          return `/app/team-settings/collaborators/${id}`;
        },
      },
    },
    api: {
      title: 'API',
      path: '/app/team-settings/api',
    },
    productAccounts: {
      title: 'Product Accounts',
      path: '/app/team-settings/product-accounts',
    },
    productFeeds: {
      title: 'Product Feeds',
      path: '/app/team-settings/product-feeds',

      productFeedsEdit: {
        path: '/app/team-settings/product-feeds/:id',
        createUrl: id => `/app/team-settings/product-feeds/${id}`,
      },
    },
    team: {
      title: 'Team Profile',
      path: '/app/team-settings/team',
    },
    preferences: {
      title: 'Preferences',
      path: '/app/team-settings/preferences',
    },
    rightsManagement: {
      title: 'Rights Management',
      path: '/app/team-settings/rights-management',
    },
  },
  community: {
    title: 'Community',
    path: '/app/community',
    iframePath: '/community-admin/dashboard',

    settings: {
      points: {
        title: 'Community',
        path: '/app/community/settings/points',
        iframePath: '/community-admin/accounts/system_configuration',
      },
    },
  },
  ecommerce: {
    path: '/app/ecommerce',
  },
  instintUpdates: {
    title: 'InsTINT Updates',
    path: '/admin/instint_updates',
  },
  logOut: {
    title: 'Log Out',
    path: '/credentials/sign_out',
  },
  globalModeration: {
    title: 'Global Feed',
    plainPath: '/app/tints/global',
    path: '/t/globalfeed/edit/moderate/all',
  },
  helpCenter: {
    title: 'Help Center',
    path: 'https://support.tintup.com',
  },
  audience: {
    title: 'Audience Builder',
    path: '/app/audience-builder',
    overview: {
      title: 'Overview',
      path: '/app/audience-builder/overview',
    },
    members: {
      title: 'Members',
      path: '/app/audience-builder/members',
      memberDetails: {
        path: '/app/audience-builder/members/:id',
        createUrl: id => {
          return `/app/audience-builder/members/${id}`;
        },
      },
    },
    audiences: {
      title: 'Audiences',
      path: '/app/audience-builder/audiences',
      audiencesDetails: {
        path: '/app/audience-builder/audiences/:id',
        createUrl: id => {
          return `/app/audience-builder/audiences/${id}`;
        },
      },
    },
    badges: {
      title: 'Badges',
      path: '/app/audience-builder/badges',
    },
  },
};

export const urlToPageTitle = {
  [routePaths.main.path]: routePaths.discover.title,
  ['/app']: routePaths.discover.title,
  [routePaths.main.react_path]: routePaths.main.title,
  [routePaths.newTintMix.path]: routePaths.newTintMix.title,
  [routePaths.socialPublishing.calendar.path]: routePaths.socialPublishing.calendar.title,
  [routePaths.assetManager.path]: `${routePaths.assetManager.title} - ${routePaths.assetManager.myAssetsTitle}`,
  [routePaths.assetManager.collections
    .path]: `${routePaths.assetManager.title} - ${routePaths.assetManager.collections.title}`,
  [routePaths.assetManager.archived
    .path]: `${routePaths.assetManager.title} - ${routePaths.assetManager.archived.title}`,
  [routePaths.experienceBuilder.path]: routePaths.experienceBuilder.title,
  [routePaths.experienceBuilder.customDomains
    .path]: `${routePaths.experienceBuilder.title} - ${routePaths.experienceBuilder.customDomains.title}`,
  [routePaths.insights.content.path]: `${routePaths.insights.title} - ${routePaths.insights.content.title}`,
  [routePaths.teamSettings.team.path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.team.title}`,
  [routePaths.teamSettings.socialConnections
    .path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.socialConnections.title}`,
  [routePaths.teamSettings.marketingIntegrations
    .path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.marketingIntegrations.title}`,
  [routePaths.teamSettings.security
    .path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.security.title}`,
  [routePaths.teamSettings.collaborators
    .path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.collaborators.title}`,
  [routePaths.teamSettings.roles.path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.roles.title}`,
  [routePaths.teamSettings.api.path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.api.title}`,
  [routePaths.teamSettings.productAccounts
    .path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.productAccounts.title}`,
  [routePaths.teamSettings.productFeeds
    .path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.productFeeds.title}`,
  [routePaths.teamSettings.preferences
    .path]: `${routePaths.teamSettings.title} - ${routePaths.teamSettings.preferences.title}`,
  [routePaths.personalSettings.information
    .path]: `${routePaths.personalSettings.title} - ${routePaths.personalSettings.information.title}`,
  [routePaths.personalSettings.accountSecurity
    .path]: `${routePaths.personalSettings.title} - ${routePaths.personalSettings.accountSecurity.title}`,
  [routePaths.insights.performance.path]: `${routePaths.insights.title} - ${routePaths.insights.performance.title}`,
  [routePaths.insights.commerce.path]: `${routePaths.insights.title} - ${routePaths.insights.commerce.title}`,
  [routePaths.personalizations.path]: routePaths.personalizations.title,
  [routePaths.creatorsAndInfluencers.path]: routePaths.creatorsAndInfluencers.title,
  [routePaths.subscribePlan.path]: routePaths.subscribePlan.title,
  [routePaths.formBuilder.new.path]: routePaths.formBuilder.title,
  [routePaths.formBuilder.path]: routePaths.formBuilder.title,
  [routePaths.formBuilder.builder.path]: routePaths.formBuilder.title,
  [routePaths.missionHubs.path]: routePaths.missionHubs.title,
  [routePaths.community.path]: routePaths.community.title,
  [routePaths.community.settings.points.path]: routePaths.community.settings.points.title,
  [routePaths.audience.overview.path]: `${routePaths.audience.title} - ${routePaths.audience.overview.title}`,
  [routePaths.audience.members.path]: `${routePaths.audience.title} - ${routePaths.audience.members.title}`,
  [routePaths.audience.audiences.path]: `${routePaths.audience.title} - ${routePaths.audience.audiences.title}`,
  [routePaths.audience.badges.path]: `${routePaths.audience.title} - ${routePaths.audience.badges.title}`,
};

export const urlToPageHeader = {
  [routePaths.main.path]: routePaths.discover.title,
  [routePaths.main.react_path]: routePaths.main.title,
  [routePaths.socialPublishing.calendar.path]: routePaths.socialPublishing.calendar.title,
  [routePaths.personalizations.path]: routePaths.personalizations.title,
  [routePaths.personalizations.personalization.plainPath]: routePaths.personalizations.personalization.title,
  [routePaths.newTintMix.path]: routePaths.newTintMix.title,
  [routePaths.assetManager.path]: routePaths.assetManager.title,
  [routePaths.experienceBuilder.path]: routePaths.experienceBuilder.title,
  [routePaths.insights.path]: routePaths.insights.title,
  [routePaths.personalSettings.path]: routePaths.personalSettings.title,
  [routePaths.teamSettings.plainPath]: routePaths.teamSettings.title,
  [routePaths.creatorsAndInfluencers.path]: routePaths.creatorsAndInfluencers.title,
  [routePaths.subscribePlan.path]: routePaths.subscribePlan.title,
  [routePaths.formBuilder.new.path]: routePaths.formBuilder.title,
  [routePaths.formBuilder.path]: routePaths.formBuilder.title,
  [routePaths.formBuilder.builder.path]: routePaths.formBuilder.title,
  [routePaths.missionHubs.path]: routePaths.missionHubs.title,
  [routePaths.community.path]: routePaths.community.title,
  [routePaths.community.settings.points.path]: routePaths.community.settings.points.title,
  [routePaths.audience.overview.path]: routePaths.audience.title,
  [routePaths.audience.members.path]: routePaths.audience.title,
  [routePaths.audience.audiences.path]: routePaths.audience.title,
  [routePaths.audience.badges.path]: routePaths.audience.title,
};
