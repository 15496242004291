import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import './select-comment.sass';
import { DropdownHeader } from '../../../interface/dropdown/button/header/dropdown-header';
import DropdownSortList from '../../../interface/dropdown/lists/sort-list/dropdown-sort-list';
import DropdownButton, { ButtonType } from '../../../interface/dropdown/button/dropdown-button';
import { Button } from '../../../interface/button/button';
import {
  setRequestRightsModalProgress,
  setRequestRightStep,
} from '../../../../actions/request-rights/request-rights.actions';
import { REQUEST_RIGHTS_SOURCE, REQUEST_RIGHTS_STEP } from '../../request-rights';
import { useSocialAccountsAuthorizationService } from '../../../../feature/account-settings/hooks/use-social-accounts-authorization-service';
import { SocialConnectionsTypes } from '../../../../feature/account-settings/components/social-connections/social-connections';
import { EditCommentBox } from '../edit-comment-box/edit-comment-box';
import {
  getSocialConnections,
  getSubAccounts,
} from '../../../../feature/account-settings/actions/social-connections/social-connections.fat-actions';
import PreloaderComponent, {
  PreloaderBackdropColorType,
  PreloaderPositionType,
} from '../../../interface/preloaders/preloader/preloader';
import DropdownSearchList from '../../../interface/dropdown/lists/search-list/dropdown-search-list';
import useFeatureAccessibility from '../../../hooks/use-feature-accessibility/use-feature-accessibility';
import { GuardNames } from '../../../../guards/guards-list';
import { getRequestRightsTemplates } from '../../../../actions/request-rights/request-rights.fat-actions';
import { toast } from 'react-toastify';
import DropdownInfiniteScrollList from '../../../interface/dropdown/lists/infinite-scroll-list/dropdown-infinite-scroll-list';
import { Config } from '../../../../utils/config';

export const Source = {
  TWITTER: 2,
  INSTAGRAM_BUSINESS: 21,
};

export const SocialFeedType = {
  TWITTER: 'twitter',
  INSTAGRAM_BUSINESS: 'instagram_business',
};

const ADD_ACCOUNT = 'Add account';
const TYPE_CUSTOM_COMMENT = 'TYPE_CUSTOM_COMMENT';
const defaultTemplateText =
  "We'd like to share this image on our social media channels and tag you in our post with your permission. Please review our terms. {{username}} if you agree, reply {{hashtag}} {{terms_url}}\n";
const defaultHashtag = 'yes';

export const SelectComment = () => {
  const dispatch = useDispatch();
  const requestRights = useSelector(state => state.requestRights);
  const socialConnections = useSelector(state => state.socialConnections);
  const session = useSelector(state => state.session.data);
  const [isCustomComment, setIsCustomComment] = useState(false);
  const [isPending, setIsPending] = useState(false);
  const [socialConnectionAccounts, setSocialConnectionAccounts] = useState([]);
  const [filterValue, setFilterValue] = useState();
  const defaultAccountValue = { name: ADD_ACCOUNT, value: ADD_ACCOUNT };
  const defaultCommentValue = { type: 'button', name: 'Type Custom Comment', value: TYPE_CUSTOM_COMMENT };
  const isAbleToLoad = useSelector(state => state.requestRights?.requestRightsData?.links?.next);
  const [selectedComment, setComment] = useState({
    name: 'Select Comment',
    value: undefined,
  });
  const selectedAccountId = requestRights.modal.account_id;
  const selectedSubAccountId = requestRights.modal.subaccount_id;
  const [socialConnectionSubAccounts, setSocialConnectionSubAccounts] = useState([]);
  const { addSocialConnection } = useSocialAccountsAuthorizationService({
    session,
    onNewAccountSuccess: () => dispatch(getSocialConnections({ url: '/accounts?page[size]=500' })),
  });

  const [contentList, setContentList] = useState([]);
  const [isFetching, setIsFetching] = useState(false);

  const tintId = requestRights.postMessageData.payload.user_id || requestRights.postMessageData.tintId;
  const isSeparateSettingsAvailable = useFeatureAccessibility(GuardNames.SEPARATE_SETTINGS);

  useEffect(() => {
    if (requestRights.modal.templateName) {
      // open custom box if templatName is selected
      setIsCustomComment(true);
      setComment({ value: requestRights.modal.template, name: requestRights.modal.templateName });
    } else if (requestRights.modal.template) {
      // select previously selected comment
    } else if (!selectedComment && requestRights.requestRightsData !== undefined) {
      // select first comment if none is selected
      const attr = requestRights.requestRightsData.data[0]?.attributes;
      setComment({ value: attr?.template, name: attr?.template });
      dispatch(setRequestRightsModalProgress({ template: attr?.template, hashtag: attr?.hashtag }));
    }
  }, [requestRights.requestRightsData]);

  useEffect(() => {
    const accountId = requestRights.modal ? requestRights.modal.account_id : null;
    const subAccountId = requestRights.modal ? requestRights.modal.subaccount_id : null;

    switch (requestRights.postMessageData.payload.source) {
      case SocialFeedType.TWITTER:
      case Source.TWITTER:
        setupTwitterDropdown({
          type: REQUEST_RIGHTS_SOURCE.TWITTER,
          data: socialConnections.accounts.data,
          selectedId: accountId,
        });
        break;
      case SocialFeedType.INSTAGRAM_BUSINESS:
      case Source.INSTAGRAM_BUSINESS:
        setupInstagramDropdown({
          type: REQUEST_RIGHTS_SOURCE.INSTAGRAM_BUSINESS,
          data: socialConnections.accounts.data,
          selectedId: accountId,
          selectedSubAccountId: subAccountId,
        });
        break;
    }
  }, [socialConnections.accounts.data]);

  const setupInstagramDropdown = ({ data, type, selectedSubAccountId }) => {
    const _socialAccounts = data
      .filter(e => e.attributes.type === type)
      .map(e => {
        return {
          ...e.attributes,
          id: e.id,
          title: e.attributes,
          value: e.id,
          name: e.attributes.name || e.attributes.username,
        };
      });

    setSocialConnectionAccounts([..._socialAccounts, defaultAccountValue]);

    if (selectedSubAccountId) {
      const subAccountsData = socialConnections.subAccountsData.data.map(e => ({
        id: e.id,
        ...e.attributes,
        name: e.attributes.username || e.attributes.name,
        value: e.id,
      }));
      setSocialConnectionSubAccounts(subAccountsData);
    }
  };

  const getCurrentSubAccountItem = () => {
    return socialConnectionSubAccounts.find(subAccount => subAccount.id === selectedSubAccountId.toString());
  };

  const getCurrentAccountItem = () => {
    return socialConnectionAccounts.find(account => account.id === selectedAccountId.toString());
  };

  const setupTwitterDropdown = ({ data, type }) => {
    const _socialAccounts = data
      .filter(e => e.attributes.type === type)
      .map(e => {
        return {
          ...e.attributes,
          id: e.id,
          title: e.attributes,
          value: e.id,
          name: e.attributes.name || e.attributes.username,
        };
      });

    setSocialConnectionAccounts([..._socialAccounts, defaultAccountValue]);
  };

  const getTemplates = ({ currentPageSize, filterValue }) => {
    dispatch(
      getRequestRightsTemplates({
        pageSize: currentPageSize,
        filterValue,
      })
    ).catch(() => toast('Cannot fetch comments list'));
  };

  const prepareCommentList = () => {
    const _comments = requestRights.requestRightsData.data.map(e => ({
      name: e?.attributes?.template,
      header: e?.attributes?.name,
      ...e,
    }));
    setContentList([..._comments, defaultCommentValue]);
  };

  const onSubAccountSelect = element => {
    dispatch(setRequestRightsModalProgress({ subaccount_id: element.id }));
  };

  const onAccountSelect = element => {
    if (element.value === ADD_ACCOUNT) {
      _addSocialConnection();
    } else {
      dispatch(setRequestRightsModalProgress({ account_id: Number.parseInt(element.id), source: element.type }));
      if (element.type === 'instagram_business') {
        setIsPending(true);
        dispatch(getSubAccounts({ accountId: Number.parseInt(element.id) })).then(data => {
          setSocialConnectionSubAccounts(data.map(e => ({ id: e.id, ...e.attributes, name: e.attributes.username })));
          setIsPending(false);
        });
      }
    }
  };

  const onSelectComment = element => {
    if (element.value !== 'TYPE_CUSTOM_COMMENT') {
      setIsCustomComment(false);
      dispatch(
        setRequestRightsModalProgress({
          template: element.attributes.template,
          hashtag: element.attributes.hashtag,
          templateName: '',
        })
      );
    } else {
      dispatch(
        setRequestRightsModalProgress({
          template: defaultTemplateText,
          templateName: 'Type Custom Comment',
          hashtag: defaultHashtag,
        })
      );
      setIsCustomComment(true);
    }
  };

  const onCommentSubmit = ({ template, hashtag, setIsEditing }) => {
    hashtag = hashtag.replace('#', '');
    dispatch(setRequestRightsModalProgress({ template, hashtag }));
    setIsEditing(false);
  };
  const _addSocialConnection = () => {
    switch (requestRights.postMessageData.payload.source) {
      case SocialFeedType.TWITTER:
      case Source.TWITTER:
        addSocialConnection(SocialConnectionsTypes.twitter.backendMapping);
        break;
      case SocialFeedType.INSTAGRAM_BUSINESS:
      case Source.INSTAGRAM_BUSINESS:
        addSocialConnection(SocialConnectionsTypes.instagram.backendMapping);
        break;
    }
  };

  return (
    <div className='tint-select-comment'>
      {isPending ? (
        <PreloaderComponent
          positionType={PreloaderPositionType.ABSOLUTE}
          backdropColor={PreloaderBackdropColorType.DARK}
        />
      ) : null}
      <div className='tint-select-comment__content-container' key={socialConnectionAccounts.length}>
        <h5>STEP 1: POST FROM</h5>
        {socialConnectionAccounts.length > 0 ? (
          <DropdownButton
            currentItem={getCurrentAccountItem()}
            dropdownHeaderStyle={{ justifyContent: 'space-between' }}
            dropdownHeader={props => <DropdownHeader {...props} />}
            dropdownList={props => <DropdownSearchList isDefaultOption={false} {...props} />}
            list={socialConnectionAccounts}
            placeholder='Select Account...'
            iconRight='fa fa-caret-down'
            buttonType={ButtonType.BUTTON_BORDER_FULL_WIDTH}
            onChangeValue={onAccountSelect}
          />
        ) : null}
      </div>
      {socialConnectionSubAccounts.length > 0 ? (
        <div className='tint-select-comment__content-container'>
          <h5>SELECT SUBACCOUNT</h5>
          <DropdownButton
            currentItem={getCurrentSubAccountItem()}
            dropdownHeaderStyle={{ justifyContent: 'space-between' }}
            dropdownHeader={props => <DropdownHeader {...props} />}
            dropdownList={props => <DropdownSearchList {...props} isDefaultOption={false} />}
            list={socialConnectionSubAccounts}
            placeholder='Select Sub Account...'
            iconRight='fa fa-caret-down'
            buttonType={ButtonType.BUTTON_BORDER_FULL_WIDTH}
            onChangeValue={onSubAccountSelect}
          />
        </div>
      ) : null}

      <div className='tint-select-comment__content-container'>
        <h5>STEP 2: SELECT A SAVED COMMENT</h5>
        {requestRights?.requestRightsData?.data?.length > 0 || isFetching ? (
          <>
            <DropdownButton
              currentItem={selectedComment}
              dropdownHeader={props => <DropdownHeader {...props} />}
              dropdownList={props => (
                <DropdownInfiniteScrollList
                  props={props}
                  filterValue={filterValue}
                  setFilterValue={setFilterValue}
                  setIsFetching={setIsFetching}
                  getData={getTemplates}
                  pageSize={Config.defaultPageSize}
                  tintId={tintId}
                  setList={prepareCommentList}
                  isAbleToLoad={isAbleToLoad}
                  isSeparateSettingsAvailable={isSeparateSettingsAvailable}
                  data={requestRights?.requestRightsData}
                />
              )}
              dropdownHeaderStyle={{ justifyContent: 'space-between' }}
              list={contentList}
              placeholder='Select Comment'
              iconRight='fa fa-caret-down'
              buttonType={ButtonType.BUTTON_BORDER_FULL_WIDTH}
              onChangeValue={onSelectComment}
            />
          </>
        ) : null}
        {isCustomComment ? (
          <EditCommentBox
            onSubmit={onCommentSubmit}
            hashtag={requestRights.modal.hashtag}
            text={requestRights.modal.template}
            isCustom={true}
          />
        ) : (
          ''
        )}
        <Button
          onClick={() => dispatch(setRequestRightStep(REQUEST_RIGHTS_STEP.COMMENTS))}
          styles={{ width: '100%', height: '52px' }}
          text='Edit TINT Comments'
          type='gray'
        />
      </div>
    </div>
  );
};
