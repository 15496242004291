import React, { forwardRef } from 'react';
import { createParamsForUrl, filters } from '../../../feature/account-settings/utils/filter-helper/filter-helper';
import ApiDropdown from '../api-dropdown';

export const termsAndConditionsDropdownId = 'termsAndConditions';

const DropdownTermsAndConditions = (
  { currentElement, onChange, styles, placeholder = 'Select Terms and Conditions...' },
  ref
) => {
  const baseURL = '/right_requests/terms';
  const getFetchUrl = (url, inputValue) =>
    url ||
    createParamsForUrl({
      baseUrl: baseURL,
      filters: [
        {
          by: filters.QUERY,
          value: inputValue,
        },
      ],
    });

  return (
    <div className='tint-terms-conditions-dropdown'>
      <ApiDropdown
        ref={ref}
        label={'Terms and Conditions'}
        placeholder={placeholder}
        fetchUrl={getFetchUrl}
        baseUrl={baseURL}
        currentElement={currentElement}
        dropdownId={termsAndConditionsDropdownId}
        onChange={onChange}
        styles={styles}
      />
    </div>
  );
};

DropdownTermsAndConditions.displayName = 'DropdownTermsAndConditions';
export default forwardRef(DropdownTermsAndConditions);
