import { createPrefixedActionName } from '../../../../services/functions/redux-routines/redux-routines';
import { FORM_BUILDER } from '../../../../actions/consts';

// that file will be used only for hosted forms so actions here should be reduced to minimum
export const ACTIONS = {
  SET_LANGUAGE: createPrefixedActionName('FORM_BUILDER.FORM', 'SET_LANGUAGE'),
  RESET_STATE: createPrefixedActionName(FORM_BUILDER, 'RESET_STATE'),
};

export const setLanguage = lang => ({
  type: ACTIONS.SET_LANGUAGE,
  payload: lang,
});
