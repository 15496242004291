import React, { useMemo, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import './api-dropdown.sass';
import { DropdownHeader } from '../interface/dropdown/button/header/dropdown-header';
import DropdownInfiniteList from '../interface/dropdown/lists/infinite-list/dropdown-infinite-list';
import DropdownButton, { ButtonType, DropdownButtonType } from '../interface/dropdown/button/dropdown-button';
import { dropdownStyles } from '../../feature/tint-editor/components/tint-editor/sidebars/social-feed-sidebar/utils/common/common';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getDropdownData } from '../../reducers/ui/ui.selector';
import { clearDropdownData, setDropdownData } from '../../actions/ui/ui.actions';
import { colors } from '../../utils/variables/colors';
import { Typography, TypographyColors, TypographyVariants } from '../interface/typography/typography';
import { ApiService } from '../../services/api-service/api-service';
import { handleApiError } from '../../services/functions/error-handler/error-handler';
import { toast } from 'react-toastify';

/** (option) => ({ name: option.attributes.(name/slug/account_name/subaccount etc.), value: option?.id });*/
const defaultFetchMapFn = el => ({ name: el?.attributes?.name || el?.attributes?.username, value: el?.id });

const ApiDropdown = forwardRef(
  (
    {
      label,
      placeholder = 'Select Account',
      styles,
      fetchOnInit = true,
      dropdownId,
      fetchFnMapper = defaultFetchMapFn,
      filterDataFn,
      onChange,
      currentElement,
      additionalOption,
      unselectOption,
      fixedOption,
      baseUrl,
      fetchUrl,

      ...props
    },
    ref
  ) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const [isLoading, setLoading] = useState(false);
    const [selectedElement, setSelectedElement] = useState();
    const { data, links, isAbleToLoad } = useSelector(s => getDropdownData(s, dropdownId, fetchFnMapper, filterDataFn));

    const fetchData = (url, inputValue) => {
      setLoading(true);
      ApiService.get(fetchUrl?.(url, inputValue))
        .then(res => {
          dispatch(
            setDropdownData({
              id: dropdownId,
              data: res?.data?.data,
              query: inputValue,
              links: res?.data?.links,
            })
          );
        })
        .catch(err => handleApiError(err, toast.error))
        .finally(() => {
          setLoading(false);
        });
    };

    useImperativeHandle(ref, () => ({
      refetch: () => {
        dispatch(clearDropdownData({ id: dropdownId }));
        fetchData();
      },
    }));

    useEffect(() => {
      if (!fetchOnInit) {
        return;
      }
      fetchData();

      if (currentElement && typeof currentElement === 'string') {
        // Fetch current element when is not exist on first page;
        ApiService.get(`${baseUrl}/${currentElement}`)
          .then(res => {
            setSelectedElement({
              name: res?.data?.data?.attributes?.name,
              value: res?.data?.data?.id,
            });
          })
          .catch(err => handleApiError(err, toast.error));
      }

      return () => {
        if (fetchOnInit) {
          dispatch(clearDropdownData({ id: dropdownId }));
        }

        dispatch(
          setDropdownData({
            id: dropdownId,
            data: [],
            query: '',
            links: undefined,
          })
        );
      };
    }, [fetchOnInit, dispatch]);

    const onOpenDropdown = () => {
      if (fetchOnInit || data.length > 0) {
        return;
      }

      fetchData();
    };

    const currentItem = useMemo(() => {
      return (
        selectedElement ||
        data.find(el =>
          typeof currentElement === 'string' ? el.value === currentElement : el.value === currentElement?.value
        )
      );
    }, [currentElement, selectedElement, data]);

    const resetSearchQueryParam = () => {
      dispatch(
        setDropdownData({
          id: dropdownId,
          query: undefined,
        })
      );
    };

    return (
      <div className='tint-api-dropdown'>
        {label && (
          <Typography
            color={TypographyColors.PRIMARY}
            variant={TypographyVariants.LABEL}
            component={'label'}
            className='tint-api-dropdown__label'>
            {label}
          </Typography>
        )}
        <DropdownButton
          onOpen={onOpenDropdown}
          currentItem={currentItem || currentElement}
          styles={styles ? styles : dropdownStyles}
          dropdownHeader={props => <DropdownHeader {...props} />}
          placeholder={placeholder}
          dropdownType={DropdownButtonType.SEARCH_INPUT}
          dropdownList={_props => (
            <DropdownInfiniteList
              {..._props}
              refreshSocialConnection={props?.refreshSocialConnection}
              lastChildColor={additionalOption ? colors.info : undefined}
              onLoadData={() => fetchData(links?.next)}
              isAbleToLoad={isAbleToLoad}
              isLoading={isLoading}
              onInputChange={e => {
                if (e) {
                  fetchData(undefined, e);
                } else {
                  resetSearchQueryParam();
                }
              }}
              onChange={e => {
                if (e.value === additionalOption?.value && additionalOption.link) {
                  history.push(additionalOption?.link);
                } else if (e.value === additionalOption?.value && additionalOption.action) {
                  additionalOption.action(additionalOption.value);
                } else {
                  _props.onChange(e);
                }
              }}
              fixedOption={fixedOption}
            />
          )}
          list={[...data, ...(unselectOption && currentItem ? [unselectOption] : []), additionalOption].filter(Boolean)}
          iconRight='fa fa-caret-down'
          buttonType={ButtonType.BUTTON_GRAY_BORDER}
          onChangeValue={onChange}
        />
      </div>
    );
  }
);

ApiDropdown.displayName = 'ApiDropdown';
export default ApiDropdown;
