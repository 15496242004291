import React, { useEffect, useRef, useState } from 'react';
import './wysiwyg.sass';
import WysiwygNavigationContainer from './components/wysiwyg-navigation/wysiwyg-navigation-container';
import useClickOutside from '../../../../../components/hooks/use-click-outside/use-click-outside';
import { commandIdList, wysiwygNavigationItemList } from './components/wysiwyg-navigation/wysiwyg-navigation-options';
import * as PropTypes from 'prop-types';
import WysiwygContentEditor from './components/wysiwyg-content-editor/wysiwyg-content-editor';
import useAnimation from '../../../../../components/hooks/use-animation/use-animation';
import { Config } from '../../../../../utils/config';
import { getCurrentStyle, getPropertyValueOfElement } from './wysiwyg-container-DOM-utils';

const Wysiwyg = ({
  onBlur,
  onNavigationOptionChoose,
  style,
  contentValue,
  onContentChange,
  onNavigationRender,
  saveSelection,
  getPrevSelection,
  contentEditableRef,
  textDirection,
}) => {
  const node = useRef();
  const [isNavVisible, setNavVisibility] = useClickOutside(node);
  const [currentHTML, setCurrentHTML] = useState();
  const [wysiwygProperties, setWysiwygProperties] = useState();

  const [animationState, onAnimationEnd, animationName] = useAnimation(
    isNavVisible,
    'wysiwyg-fade-in',
    'wysiwyg-fade-out'
  );

  const currentSelection = window.getSelection();

  const contentPropertiesList = wysiwygNavigationItemList.map(option => {
    return {
      commandId: option.commandId,
      value: isNavVisible ? document.queryCommandValue(option.commandId) : '',
      key: option.value,
    };
  });

  const getCSSStyle = cssProperty => {
    const selectedElement = currentSelection.focusNode.parentNode || currentSelection.anchorNode.parentNode;
    if (cssProperty === 'fontFamily' || cssProperty === 'fontSize') {
      return getCurrentStyle(selectedElement, cssProperty)
        ? getCurrentStyle(selectedElement, cssProperty)
        : getPropertyValueOfElement(selectedElement, 'font');
    } else {
      return getCurrentStyle(selectedElement, cssProperty);
    }
  };

  const getContentEditableProperties = () => {
    return contentPropertiesList.reduce((obj, item) => {
      switch (item.commandId) {
        case commandIdList.COLOR:
          obj[item.key] = document.queryCommandValue(item.commandId);
          break;

        case commandIdList.BACKGROUND_COLOR:
          obj[item.key] = getCSSStyle('backgroundColor') || 'rgba(255,255,255,0)';
          break;

        case commandIdList.FONT_SIZE:
          obj[item.key] = Number(
            getCSSStyle(commandIdList.FONT_SIZE) && getCSSStyle(commandIdList.FONT_SIZE).replace('px', '')
          );
          break;

        case commandIdList.LINK:
          obj['link'] = getLinkUrl() ? getLinkUrl() : '';
          break;

        case commandIdList.FONT_FAMILY:
          obj[item.key] = document.queryCommandValue(item.commandId)
            ? document.queryCommandValue(item.commandId).replace(/["']/g, '')
            : Config.fonts.secondaryFont;
          break;

        case commandIdList.UNDERLINE:
          obj[item.key] = isQueryCommandState(item.commandId) ? 'underline' : 'none';
          break;

        case commandIdList.FONT_STYLE:
          obj[item.key] = isQueryCommandState(item.commandId) ? 'italic' : 'normal';
          break;

        case commandIdList.FONT_WEIGHT:
          obj[item.key] = isQueryCommandState(item.commandId) ? 'bold' : 'normal';
          break;

        case commandIdList.TEXT_ALIGN_CENTER:
        case commandIdList.TEXT_ALIGN_RIGHT:
        case commandIdList.TEXT_ALIGN_LEFT:
          obj[item.key] = isQueryCommandState(item.commandId) ? item.commandId : undefined;
          break;

        default:
          obj[item.key] = document.queryCommandValue(item.commandId);
      }

      return obj;
    }, {});
  };

  const getLinkUrl = () => {
    const selectedElement = currentSelection.focusNode.parentNode || currentSelection.anchorNode.parentNode;

    return getPropertyValueOfElement(selectedElement, 'href');
  };

  const isQueryCommandState = commandId => {
    return document.queryCommandState(commandId);
  };

  useEffect(() => {
    onNavigationRender(isNavVisible);
  }, [isNavVisible]);

  useEffect(() => {
    setCurrentHTML(
      <WysiwygContentEditor
        textDirection={textDirection}
        onBlur={onBlur}
        onChange={onContentChange}
        onUpdateContentProperties={() => {
          setTimeout(() => {
            setWysiwygProperties(getContentEditableProperties());
          });
        }}
        style={style}
        value={contentValue}
        contentEditableRef={contentEditableRef}
        onSelectAll={onNavigationClick}
      />
    );
  }, []);

  const onNavigationClick = (commandId, value = '', showUI = false) => {
    onNavigationOptionChoose(commandId, value, showUI);

    setTimeout(() => {
      setWysiwygProperties(getContentEditableProperties());
    });
  };

  return (
    <div
      data-testid='tint-wysiwyg'
      className='tint-wysiwyg'
      ref={node}
      onClick={() => {
        setNavVisibility(true);
      }}>
      {animationState && wysiwygProperties && (
        <div
          className='tint-wysiwyg__navigation'
          onAnimationEnd={onAnimationEnd}
          style={{
            animation: `${animationName} 0.2s`,
          }}>
          <WysiwygNavigationContainer
            onNavigationClick={onNavigationClick}
            valueProperties={wysiwygProperties}
            onSaveSelection={saveSelection}
            getPrevSelection={getPrevSelection}
          />
        </div>
      )}
      {currentHTML}
    </div>
  );
};

export default Wysiwyg;

Wysiwyg.propTypes = {
  onBlur: PropTypes.func,
  onNavigationOptionChoose: PropTypes.func,
  styles: PropTypes.any,
  contentValue: PropTypes.string,
  onContentChange: PropTypes.func,
  onNavigationRender: PropTypes.func,
  saveSelection: PropTypes.func,
  getPrevSelection: PropTypes.func,
  contentEditableRef: PropTypes.object,
};
