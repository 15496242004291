import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import './dropdown-current-usage-list.sass';
import useDropdownListPosition from '../../../../hooks/use-dropdown-list-position/use-dropdown-list-position';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button } from '../../../button/button';
import ProgressBar from '../../../progress-bar/progress-bar';
import { Config } from '../../../../../utils/config';
import { numberWithCommas } from '../../../../../services/functions/number-format/number-format';

export const isLimitReached = currentUsageList => {
  const limits = currentUsageList.filter(e => e.activeCount + e.activeOnPlan > 0 && e.activeCount >= e.activeOnPlan);
  return limits.length > 0;
};

const DropdownCurrentUsageList = ({
  headerTitle = 'Current Usage',
  hasUpgradeButton = true,
  parentRef,
  currentItems,
}) => {
  const elementRef = useRef();
  const session = useSelector(state => state.session);
  const team = session.data.currentTeam;
  const history = useHistory();
  const { positionHorizontal, positionVertical } = useDropdownListPosition(elementRef, parentRef);

  const onClick = () => {
    if (team.attributes.self_service) {
      history.push(Config.routePaths.subscribePlan.path);
    } else {
      window.open(Config.url.contactUpgrade, '_blank');
    }
  };

  return (
    <div
      ref={elementRef}
      data-testid='dropdown-current-usage-list'
      className={`dropdown-current-usage-list dropdown-list--${positionHorizontal} dropdown-list--${positionVertical} dropdown-list--click dropdown-list--wide dropdown-list--with-description`}>
      <div className='dropdown-current-usage-list__header'>
        <span>{headerTitle}</span>
      </div>
      <div className='dropdown-current-usage-list__body'>
        {currentItems.map((item, i) => {
          return item.activeCount !== undefined ? (
            <div key={i} className='dropdown-current-usage-list__element'>
              <span>
                {item.customDescription ? (
                  item.customDescription
                ) : (
                  <>
                    Using {numberWithCommas(item.activeCount)}{' '}
                    {item.activeOnPlan ? `of ${numberWithCommas(item.activeOnPlan)}` : ''} {item.name}
                  </>
                )}
              </span>
              {item.activeOnPlan && item.activeCount ? (
                <ProgressBar totalProgress={item.activeOnPlan} currentProgress={item.activeCount} />
              ) : null}
            </div>
          ) : null;
        })}
      </div>
      {hasUpgradeButton && (
        <div className='dropdown-current-usage-list__footer'>
          <Button text='Upgrade for More' onClick={onClick} size='full-width' type='black' />
        </div>
      )}
    </div>
  );
};

export default DropdownCurrentUsageList;

DropdownCurrentUsageList.propTypes = {
  currentItems: PropTypes.array,
  parentRef: PropTypes.any,
};
