export const colors = {
  starRating: '#f5a623',

  waveGrey6: '#1B242D',
  textPrimary: '#1b242d',
  veryDarkGrey: '#2c2d30',
  shadow: '#00000008',
  buttonSecondary: '#4b5056',
  placeholder: '#70757e',
  semiDarkGrey: '#797E86',
  textSecondary: '#838e94',
  placeholderGrey: '#aab7c4',
  grey: '#b6b8bb',
  lines: '#e9edf3',
  semiLightGray: '#EDEFF1',
  veryLightGrey: '#f6f7f8',

  brand: '#FF564B',
  primary: '#26e0bd',
  primaryLight: '#49e2cc',

  darkGray: '#373740',
  black: '#000000',
  gray: '#797E86',
  lightGray: '#CED0D1',
  bgGray: '#EDEFF1',
  white: '#FCFCFC',
  backdropGray: '#797e8666',

  error: '#D32F2F',
  errorLight: '#DE7B7B',
  warning: '#F5A623',
  warningLight: '#F2C374',
  info: '#3B99FC',
  infoLight: '#83BBF6',
  success: '#00A752',
  successLight: '#5FC390',
};
