import React, { Component } from 'react';
import './connect-tint-box.sass';

export class ConnectTintBox extends Component {
  render() {
    return (
      <div onClick={this.props.onClick} className='contest-builder-page__connect-tint-box'>
        <span>{this.props.text}</span>
      </div>
    );
  }
}
