import React, { Component } from 'react';
import './add-block.sass';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ClickOutsideHOC } from '../../../../../components/HOC/click-outside/click-outside.hoc';
import { CustomSectionComponentModal } from './custom-section-component-modal/custom-section-component-modal';

class AddBlock extends Component {
  onIconClick = () => (this.props.isMenuElementVisible ? this.props.hideMenu() : this.props.displayMenu());

  render() {
    return (
      <div
        className={`tint-add-block ${
          !this.props.isFetching && this.props.isMenuElementVisible ? 'tint-add-block--active' : ''
        }`}
        ref={node => this.props.setMenuNode(node)}>
        <div className='plus-container' onClick={this.onIconClick}>
          <span className='fas fa-plus' />
        </div>

        <div className='tint-add-block__title-container'>
          <span>Add Block</span>
        </div>
        {!this.props.isFetching && this.props.isMenuElementVisible ? (
          this.props.isForMultiColumn ? (
            <div className='tint-add-block__custom-modal-container'>
              <div className='tint-add-block__custom-modal-wrapper'>
                <CustomSectionComponentModal
                  isForMultiColumn={this.props.isForMultiColumn}
                  hideMenu={this.props.hideMenu}
                  addSection={this.props.addSection}
                  pageSectionIndex={this.props.pageSectionIndex}
                  styles={this.props.styles}
                  enabled={this.props.session.features}
                  experience={this.props.experience}
                />
              </div>
            </div>
          ) : (
            <CustomSectionComponentModal
              isForMultiColumn={this.props.isForMultiColumn}
              hideMenu={this.props.hideMenu}
              addSection={this.props.addSection}
              pageSectionIndex={this.props.pageSectionIndex}
              styles={this.props.styles}
              enabled={this.props.session.features}
              experience={this.props.experience}
            />
          )
        ) : null}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  session: state.session.data,
  isFetching: state.experience.isFetching,
  experience: state.experience.dataDraft,
});

export default connect(mapStateToProps)(ClickOutsideHOC(AddBlock));

AddBlock.propTypes = {
  pageSectionIndex: PropTypes.number,
  displayMenu: PropTypes.func,
  hideMenu: PropTypes.func,
  setMenuNode: PropTypes.func,
  isMenuElementVisible: PropTypes.bool,
  styles: PropTypes.object,
  isForMultiColumn: PropTypes.bool,
};
