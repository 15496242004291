import React from 'react';
import PropTypes from 'prop-types';

const EditForm = ({ onSubmit, children, novalidate, id }) => {
  return (
    <form onSubmit={onSubmit} noValidate={novalidate} id={id}>
      {!onSubmit ? (
        <button style={{ display: 'none' }} type='submit'>
          Submit
        </button>
      ) : null}
      {children}
    </form>
  );
};
EditForm.propTypes = {
  onSubmit: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  novalidate: PropTypes.bool,
  id: PropTypes.string,
};

EditForm.defaultProps = {
  onSubmit: e => e.preventDefault(),
  novalidate: false,
};

export default EditForm;
