import * as Yup from 'yup';

export const validationSchemaPublicPosting = () =>
  Yup.object().shape(
    {
      form: Yup.object().required('Form is required.'),
      text: Yup.object().when(['media', 'alternative_text'], {
        is: (media, alternative_text) =>
          (!media || media.length === 0) && (!alternative_text || alternative_text.length === 0),
        then: Yup.object().required('Text is required.'),
        otherwise: Yup.object(),
      }),
      media: Yup.object().when(['text', 'alternative_text'], {
        is: (text, alternative_text) => !text || text.length === 0 || (alternative_text && alternative_text.length > 0),
        then: Yup.object().required('Media is required.'),
        otherwise: Yup.object(),
      }),
    },
    [['text', 'media']]
  );

export const validationSchemaRatingsAndReviews = () =>
  Yup.object().shape(
    {
      email_from: Yup.string()
        .notRequired()
        .test('is-email', 'Invalid Email', value => {
          if (value) {
            return Yup.string()
              .email()
              .isValidSync(value);
          }
          return true;
        }),
      form: Yup.object().required('Form is required.'),
      text: Yup.object().when(['media', 'alternative_text'], {
        is: (media, alternative_text) =>
          (!media || media.length === 0) && (!alternative_text || alternative_text.length === 0),
        then: Yup.object().required('Text is required.'),
        otherwise: Yup.object(),
      }),
      media: Yup.object().when(['text', 'alternative_text'], {
        is: (text, alternative_text) => !text || text.length === 0 || (alternative_text && alternative_text.length > 0),
        then: Yup.object().required('Media is required.'),
        otherwise: Yup.object(),
      }),
    },
    [['text', 'media']]
  );

export const hasSelectedForm = (socialFeed, form) =>
  socialFeed?.attributes?.options?.form_id &&
  form?.id &&
  Number(socialFeed.attributes.options.form_id) === Number(form.id);

export const initializeFormId = (socialFeed, form) => {
  if (hasSelectedForm(socialFeed, form)) {
    const formId = socialFeed.attributes.options.form_id;
    return formId ? { value: formId, name: form.attributes.name } : '';
  } else {
    return form ? { value: form?.id, name: form?.attributes?.name } : '';
  }
};

export const initializeEmailFromValue = (socialFeed, form) => {
  if (hasSelectedForm(socialFeed, form)) {
    const emailFrom = socialFeed?.attributes?.options?.['email_from'];
    return emailFrom ? emailFrom : '';
  } else {
    return '';
  }
};

export const initializeAuthorNameValue = (socialFeed, form) => {
  if (hasSelectedForm(socialFeed, form)) {
    const authorName = socialFeed?.attributes?.options?.['author.name'];
    return authorName ? { value: authorName, name: authorName } : '';
  } else {
    return '';
  }
};

export const initializeAuthorEmailValue = (socialFeed, form) => {
  if (hasSelectedForm(socialFeed, form)) {
    const authorEmail = socialFeed?.attributes?.options?.['author.email'];
    return authorEmail ? { value: authorEmail, name: authorEmail } : '';
  } else {
    return '';
  }
};

export const initializeAuthorAvatarValue = (socialFeed, form) => {
  if (hasSelectedForm(socialFeed, form)) {
    const authorAvatar = socialFeed?.attributes?.options?.['author.avatar'];
    return authorAvatar ? { value: authorAvatar, name: authorAvatar } : '';
  } else {
    return '';
  }
};

export const initializeMediaValue = (socialFeed, form) => {
  if (hasSelectedForm(socialFeed, form)) {
    const media = socialFeed?.attributes?.options?.media;
    return media ? { value: media, name: media } : '';
  } else {
    return '';
  }
};

export const initializePostTextValue = (socialFeed, form) => {
  if (hasSelectedForm(socialFeed, form)) {
    const text = socialFeed?.attributes?.options?.text;
    return text ? { value: text, name: text } : '';
  } else {
    return '';
  }
};

export const initializePostIncentivizedValue = (socialFeed, form) => {
  if (hasSelectedForm(socialFeed, form)) {
    const incentivized = socialFeed?.attributes?.options?.incentivized;
    return incentivized ? { value: incentivized, name: incentivized } : '';
  } else {
    return '';
  }
};

export const initializeTagsValue = (socialFeed, form) => {
  if (hasSelectedForm(socialFeed, form)) {
    const tags = socialFeed?.attributes?.options?.tags;
    return tags ? { value: tags, name: tags } : '';
  } else {
    return '';
  }
};

export const initializePostAlternativeTextValue = (socialFeed, form) => {
  if (hasSelectedForm(socialFeed, form)) {
    const altText = socialFeed?.attributes?.options?.alternative_text;
    return altText ? { value: altText, name: altText } : '';
  } else {
    return '';
  }
};

export const initializeRatingValue = (socialFeed, form) => {
  if (hasSelectedForm(socialFeed, form)) {
    const rating = socialFeed?.attributes?.options?.rating;
    return rating ? { value: rating, name: rating } : '';
  } else {
    return '';
  }
};

export const initializeTitleValue = (socialFeed, form) => {
  if (hasSelectedForm(socialFeed, form)) {
    const title = socialFeed?.attributes?.options?.title;
    return title ? { value: title, name: title } : '';
  } else {
    return '';
  }
};
