import React from 'react';
import { EditModalBody } from '../../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import PropTypes from 'prop-types';
import { EditUploadDesign } from '../../../../';

const EditCustonSpacerStyles = ({ data, dataUpdate, styleDataUpdate }) => {
  return (
    <EditModalBody title='Customize this section with the color fields below.'>
      <EditUploadDesign
        dataUpdate={dataUpdate}
        styleDataUpdate={styleDataUpdate}
        data={data}
        isPaddingDisabled={true}
      />
    </EditModalBody>
  );
};

export default EditCustonSpacerStyles;

EditCustonSpacerStyles.propTypes = {
  dataUpdate: PropTypes.func,
  backgroundColor: PropTypes.string,
};
