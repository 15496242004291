import { ACTIONS } from '../../actions/asset-variants/asset-variants.actions';

export const initialState = {
  isFetching: false,
  asset: undefined,
  tints: [],
};

export const assetReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ASSET:
      return initialState;

    case ACTIONS.GET_ASSET.REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case ACTIONS.GET_ASSET.ERROR:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };

    case ACTIONS.GET_ASSET.SUCCESS:
      return {
        ...state,
        isFetching: false,
        asset: action.payload.asset,
        tints: action.payload.tints,
      };

    default:
      return state;
  }
};
