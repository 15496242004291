import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import './edit-banner-content.sass';
import { EditUploadDesign } from '../../../';

export class EditBannerContent extends Component {
  render() {
    return (
      <EditModalBody title='Edit your banner background image below or upload your own.'>
        <form
          noValidate
          onSubmit={e => {
            e.preventDefault();
          }}>
          <EditUploadDesign
            dataUpdate={this.props.dataUpdate}
            styleDataUpdate={this.props.styleDataUpdate}
            data={this.props.data}
          />
          <button style={{ display: 'none' }} type='submit'>
            Submit
          </button>
        </form>
      </EditModalBody>
    );
  }
}
