import { variables } from '../variables/variables';

export const MuiSliderVariants = {
  coloredProgressSlider: 'coloredProgressSlider',
  markedTrackSlider: 'markedTrackSlider',
};

export const MuiSlider = {
  variants: [
    {
      props: { variant: 'coloredProgressSlider' },
      style: {
        backgroundColor: variables.colors.lightGray,
        color: variables.colors.info,
        padding: 0,

        '.MuiSlider-rail': {
          opacity: 0,
        },
      },
    },
    {
      props: { variant: 'markedTrackSlider' },
      style: {
        backgroundColor: variables.colors.lightGray,
        color: variables.colors.info,
        padding: 0,

        '.MuiSlider-rail': {
          opacity: 0,
        },

        '.MuiSlider-mark': {
          opacity: 1,

          '&::before': {
            position: 'absolute',
            content: '""',
            width: '12px',
            height: '12px',
            borderRadius: '50%',
            background: variables.colors.lightGray,
            left: '-5px',
            top: '-5px',
            transition: 'background 0.3s',
          },
        },

        '.MuiSlider-markActive': {
          '&::before': {
            background: variables.colors.info,
          },
        },

        '.MuiSlider-thumb': {
          width: '16px',
          height: '16px',
        },
      },
    },
  ],
};
