import Logger from '../../logger/logger';
import { toast } from 'react-toastify';

export const UNPROCESSABLE_ENTITY = 422;
export const NOT_FOUND = 404;
export const INTERNAL_SERVER_ERROR = 500;

const handleBackendErrors = errorCode => {
  switch (errorCode) {
    case INTERNAL_SERVER_ERROR:
      toast.error('Internal server error, try again latter');
      break;

    case NOT_FOUND:
    default:
      toast.error('Can not found');
      break;
  }
};

export const handleApiError = (err, fn) => {
  if (err.response && err.response.data && err.response.data.error) {
    const code = Object.keys(err.response.data.error);
    handleBackendErrors(code);
  } else {
    try {
      err.response.data.errors.map(e => {
        const source = e.source && e.source.pointer && e.source.pointer.split('/').splice(-1)[0];
        return e.detail ? fn(`${source ? source : ''} ${e.detail}`) : fn(e.title);
      });
    } catch (e) {
      Logger.error('Could not handle error message', e);
    }
  }

  // TODO this could be potentially used when we add new way of handling errors from backend
  //try {
  //  err.response.data.errors.find(e => e.code === `${errorNumber}`) !==
  //    undefined && fn();
  //} catch (e) {
  //  console.error("couldn't handle error message");
  //}
};

export const handleFormValidation = err => {
  try {
    if (err.response.status === UNPROCESSABLE_ENTITY) {
      err.response.data.errors.map(e => {
        const source = e.source.pointer.split('/').splice(-1)[0];
        return (err[source] = err[source] ? [...err[source], e.detail] : [e.detail]);
      });
    }
    return err;
  } catch (e) {
    handleApiError(err, toast.error);
    return {};
  } finally {
    Logger.error('Could not fetch error details');
  }
};
