const SOCIAL_FEEDS = 'SOCIAL_FEEDS';

const names = {
  SOCIAL_FEEDS,
};

export const SocialFeedsAccountTypes = {
  EXPERIENCE: 'experience',
  MISSION: 'mission',
  FORM: 'form',
  ACCOUNT: 'account',
  PAGE: 'page',
};

export const BackendKeys = {
  EXTERNAL_ID: 'external_id',
  FILE: 'file',
  MEDIA: 'media',
  FORM_SUBMISSION_KEY: 'form_submission_key',
  POST_MEDIA_URL_KEY: 'post_media_url_key',
  POST_BODY_FIELD: 'post_body_field',
  AUTHOR_NAME_FIELD: 'author_name_field',
};

export const OptionsKeys = {
  AUTHOR_NAME_FIELD: 'author.name',
  AUTHOR_EMAIL_FIELD: 'author.email',
  AUTHOR_AVATAR_FIELD: 'author.avatar',
  POST_RATING_FIELD: 'rating',
  POST_TITLE_FIELD: 'title',
  POST_TEXT_FIELD: 'text',
  MEDIA: 'media',
  POST_ALT_TEXT_FIELD: 'alternative_text',
  TAGS: 'tags',
  EMAIL_FROM: 'email_from',
  INCENTIVIZED: 'incentivized',
};

export const FormMediaKeys = {
  MEDIA: 'media',
  AVATAR: 'avatar',
  POST_MEDIA_ALT_TEXT: 'alternative_text',
};

export const CommunityActivitySources = {
  TIKTOK: 'tiktok',
  LINKED_IN: 'linkedin',
  FACEBOOK: 'facebook',
  FACEBOOK_PAGE: 'facebook_page',
  TWITTER: 'twitter',
  PINTEREST: 'pinterest',
  INSTAGRAM: 'instagram',
  BLOG: 'blog',
  YOUTUBE: 'youtube',
  SHARE_LINK: 'share_link',
  UPLOAD_PHOTO: 'upload_photo',
  UPLOAD_PHOTO_TWITTER: 'upload_photo_twitter',
  UPLOAD_PHOTO_FACEBOOK: 'upload_photo_facebook',
  UPLOAD_PHOTO_FB_PAGE: 'upload_photo_fb_page',
  BRAND_CONNECT: 'brand_connect',
  CUSTOM_UPLOAD: 'custom_upload',
  BAZAARVOICE: 'bazaarvoice',
  POWER_REVIEWS: 'power_reviews',
  PRODUCT_REVIEW: 'form',
  THREADS: 'threads',
  UPLOAD_PHOTO_THREADS: 'upload_photo_threads',
};

export const CommunityActivitySourcesTranslations = {
  [CommunityActivitySources.TIKTOK]: 'TikTok',
  [CommunityActivitySources.LINKED_IN]: 'LinkedIn',
  [CommunityActivitySources.FACEBOOK]: 'Facebook',
  [CommunityActivitySources.FACEBOOK_PAGE]: 'Facebook Page',
  [CommunityActivitySources.TWITTER]: 'X',
  [CommunityActivitySources.PINTEREST]: 'Pinterest',
  [CommunityActivitySources.INSTAGRAM]: 'Instagram',
  [CommunityActivitySources.BLOG]: 'Blog post',
  [CommunityActivitySources.YOUTUBE]: 'YouTube',
  [CommunityActivitySources.SHARE_LINK]: 'Link sharing',
  [CommunityActivitySources.UPLOAD_PHOTO]: 'Photo upload',
  [CommunityActivitySources.UPLOAD_PHOTO_TWITTER]: 'Photo to X',
  [CommunityActivitySources.UPLOAD_PHOTO_FACEBOOK]: 'Photo to Facebook',
  [CommunityActivitySources.UPLOAD_PHOTO_FB_PAGE]: 'Photo to Facebook Pages',
  [CommunityActivitySources.BRAND_CONNECT]: 'Brand Connect',
  [CommunityActivitySources.CUSTOM_UPLOAD]: 'Custom Upload',
  [CommunityActivitySources.BAZAARVOICE]: 'Bazaarvoice Product Review',
  [CommunityActivitySources.POWER_REVIEWS]: 'PowerReviews Product Review',
  [CommunityActivitySources.PRODUCT_REVIEW]: 'Product Review',
  [CommunityActivitySources.THREADS]: 'Threads',
  [CommunityActivitySources.UPLOAD_PHOTO_THREADS]: 'Photo to Threads',
};

export { names as default, SOCIAL_FEEDS };
