import React, { forwardRef } from 'react';
import './textarea-primary.sass';
import ValidationAlert from '../validation-alert/validation-alert';

const TextareaPrimary = forwardRef(
  (
    {
      name,
      type,
      value,
      label,
      placeholder,
      error,
      touched,
      onClick,
      handleBlur,
      dirty,
      handleChange,
      disabled,
      errorColor,
      style,
      defaultValue,
      readonly,
      isCustomPlaceholderColor = false,
      rows,
      onFocus
    },
    ref
  ) => {
    const isError = error && touched;
    const errorClassName = isError && !errorColor ? 'textarea-primary--error' : '';
    const validClassName = !error && touched !== undefined && dirty ? 'textarea-primary--valid' : '';
    const customPlaceholderColorClass = isCustomPlaceholderColor ? 'textarea-primary--custom-placeholder-color' : '';

    const getErrorStyles = () => {
      return isError ? { border: `1px solid ${errorColor}`, color: `${errorColor}` } : {};
    };

    return (
      <div
        onClick={() => onClick && onClick()}
        className={`textarea-primary ${errorClassName} ${validClassName} ${customPlaceholderColorClass}`}>
        {label ? <label>{label}</label> : null}
        <textarea
          data-testid='textareaPrimary'
          ref={ref}
          defaultValue={defaultValue}
          placeholder={placeholder}
          onChange={handleChange}
          onBlur={handleBlur}
          onFocus={onFocus}
          name={name}
          type={type}
          disabled={disabled}
          style={{ ...style, ...getErrorStyles() }}
          value={value}
          rows={rows}
          readOnly={readonly}>
          {value}
        </textarea>

        <ValidationAlert errorMsg={error} isVisible={isError} color={errorColor} />
      </div>
    );
  }
);

TextareaPrimary.displayName = 'TextareaPrimary';
export default TextareaPrimary;
