import {
  postCustomFontError,
  postCustomFontStart,
  postCustomFontSuccess,
  deleteCustomFontError,
  deleteCustomFontStart,
  deleteCustomFontSuccess,
  getCustomFontsError,
  getCustomFontsStart,
  getCustomFontsSuccess,
} from './custom-fonts.actions';
import { toast } from 'react-toastify';
import { ApiService } from '../../../../services/api-service/api-service';

export const getCustomFonts = ({ pageSize = undefined, paginationUrl = undefined } = {}) => {
  const _url = `/fonts?${pageSize ? `page[size]=${pageSize}&` : ''}sort=-id`;

  return dispatch => {
    dispatch(getCustomFontsStart());
    return ApiService.get(paginationUrl || _url)
      .then(function(res) {
        dispatch(getCustomFontsSuccess(res.data));
      })
      .catch(err => {
        dispatch(getCustomFontsError(err));
        toast.error('Something went wrong');
      });
  };
};

export const deleteCustomFont = id => {
  return dispatch => {
    dispatch(deleteCustomFontStart());
    return ApiService.delete(`/fonts/${id}`)
      .then(function() {
        dispatch(deleteCustomFontSuccess(id));
      })
      .catch(err => {
        dispatch(deleteCustomFontError(err));
        throw err;
      });
  };
};

export const postCustomFont = file => {
  const body = {
    data: {
      type: 'font',
      attributes: {
        upload_id: file.uploadId,
        ...(file.name ? { name: file.name } : {}),
      },
    },
  };

  return dispatch => {
    dispatch(postCustomFontStart());
    return ApiService.post('/fonts', body)
      .then(function(res) {
        dispatch(postCustomFontSuccess(res.data));
      })
      .catch(err => {
        dispatch(postCustomFontError(err));
        throw err;
      });
  };
};
