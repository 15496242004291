import { ACTIONS } from '../actions/session';
import Logger from '../services/logger/logger';
import produce from 'immer';
import { hasOneOfThePermission } from '../services/functions/permissions-checker/permissions-checker';
import { isAllowed } from '../services/functions/features-checker/features-checker';
import { GuardsRules } from '../guards/guards-rules';
import { Config } from '../utils/config';

export const initialState = {
  data: undefined,
  guards: {},
  isPending: false,
  error: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_SESSION.SUCCESS:
      return {
        ...state,
        data: action.payload,
        isPending: false,
        error: undefined,
      };

    case ACTIONS.UPDATE_SESSION.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload.data.attributes,
        },
        isPending: false,
        error: undefined,
      };

    case ACTIONS.UPDATE_TEAMS.FAILURE:
    case ACTIONS.POST_SESSION_TOKEN.FAILURE:
    case ACTIONS.UPDATE_SESSION.FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };

    case ACTIONS.GET_SESSION.FAILURE:
      return {
        ...state,
        data: undefined,
        isPending: false,
        error: action.payload,
      };

    case ACTIONS.SET_FEATURES_ACCESSIBILITY:
      return {
        ...state,
        guards: Object.keys(GuardsRules)
          .sort()
          .map(key => {
            return {
              [key]: mapUserGuardsAvailability({
                session: state,
                optionalFeatures: GuardsRules[key].optionalFeatures,
                features: GuardsRules[key].features,
                permissions: GuardsRules[key].permissions,
              }),
            };
          })
          .reduce((acc, value) => {
            return { ...acc, ...value };
          }, {}),
      };

    case ACTIONS.UPDATE_TEAMS.REQUEST:
    case ACTIONS.POST_SESSION_TOKEN.REQUEST:
    case ACTIONS.GET_SESSION.REQUEST:
    case ACTIONS.UPDATE_SESSION.REQUEST:
      return {
        ...state,
        isPending: true,
      };

    case ACTIONS.UPDATE_SESSION_PROFILE_DATA:
      return {
        ...state,
        data: {
          ...state.data,
          ...{
            first_name: action.payload.first_name,
            last_name: action.payload.last_name,
            image_url: action.payload.image_url,
            email: action.payload.email,
          },
        },
      };

    case ACTIONS.UPDATE_SESSION_CURRENT_TEAM:
      return produce(state, draft => {
        draft.data.currentTeam.attributes.name = action.payload.name;
        draft.data.currentTeam.attributes.image_url = action.payload.image_url;
      });

    case ACTIONS.UPDATE_TEAMS.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          currentTeam: action.payload,
        },
      };

    case ACTIONS.POST_SESSION_TOKEN.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          ...action.payload,
        },
      };

    case ACTIONS.PATCH_PRIMARY_FACTOR_AUTH.SUCCESS:
      return {
        ...state,
        data: {
          ...state.data,
          default_authentication_factor_id: action.payload,
        },
      };

    default:
      return state;
  }
};

export const getGuardPermission = (state, permissionType) => {
  return state && state?.guards && state.guards[permissionType];
};

const mapUserGuardsAvailability = ({ session, features, permissions, optionalFeatures, forbiddenFeatures }) => {
  const featureList = session.data.features;
  const permissionsList = session.data.permissions;

  const isFeatureAble = features?.length > 0 ? isAllowed(featureList, features) : true;

  const isOptionalFeatureAble =
    optionalFeatures && optionalFeatures?.length > 0 ? optionalFeatures?.some(feature => featureList[feature]) : true;

  const hasForbiddenFeatures =
    forbiddenFeatures && forbiddenFeatures?.length > 0
      ? !forbiddenFeatures?.some(feature => featureList[feature])
      : true;

  const isPermissionsAble = permissions?.length > 0 ? hasOneOfThePermission(permissionsList, permissions) : true;

  return isFeatureAble && isPermissionsAble && isOptionalFeatureAble && hasForbiddenFeatures;
};

export const isAnyAssetUploaded = state => {
  if (state && state.data) {
    try {
      const team = state.data.currentTeam;
      return team && team.relationships.assets.meta.total;
    } catch (e) {
      Logger.error(e);
      return undefined;
    }
  }
  return undefined;
};

export const isAnyTintAdded = state => {
  if (state && state?.session?.data) {
    try {
      const team = state.session.data.currentTeam;
      return team && team.relationships.tints.meta.count;
    } catch (e) {
      Logger.error(e);
      return undefined;
    }
  }
  return undefined;
};

export const isUploadAllowed = session => {
  const plan = session.data.currentPlan;
  const team = session.data.currentTeam;
  if (plan && team) {
    return plan.attributes.uploaded_assets - team.relationships.assets.meta.uploaded_total > 0;
  }
  return false;
};

export const getPermissions = session => {
  const _session = session.data;
  return _session ? _session.permissions : {};
};

export const getFeatures = session => {
  const _session = session.data;
  return _session ? _session.features : {};
};

export const hasTeam = session => {
  return !!session.data.currentTeam;
};

export const getUserId = session => {
  return session?.data?.attributes.id;
};

export const findTeam = (session, teamId) => {
  if (!session || !session.data || !session.data.included) {
    return null;
  }
  const params = new URLSearchParams(window.location.search);
  const queryParamTeamId = params.get('teamId');

  if (queryParamTeamId) {
    params.delete('teamId');
    const _params = params.toString() ? '?' + params.toString() : '';
    window.history.pushState({}, '', window.location.pathname + _params);
  }

  const _teamId = queryParamTeamId || teamId;
  const teams = session.data.included.filter(e => e.type === 'team');

  const isTeamIdWithinTeamsRange = teams.some(e => e.id === _teamId);

  if (!isTeamIdWithinTeamsRange) {
    return teams[0];
  }
  return teams.find(e => (_teamId ? e.id === _teamId : true));
};

export const getMaxImageSizePlanLimit = state => {
  return state && state.session.data && state.session.data.included
    ? state.session.data.currentPlan?.attributes.maximum_image_size
    : Config.maxFileSize;
};

export const getMaxVideoSizePlanLimit = state => {
  return state && state.session.data && state.session.data.included
    ? state.session.data.currentPlan?.attributes.maximum_video_size
    : Config.maxVideoFileSize;
};

export const getSocialFeedsLimits = state => {
  const plan = state?.session?.data?.currentPlan;
  const team = state?.session?.data?.currentTeam;

  return {
    activeSocialFeedsLimit: plan ? plan.attributes.active_social_feeds : 0,
    activeSocialFeedsCount:
      team && team.relationships.social_feeds ? team.relationships.social_feeds.meta.active_social_feeds_count : 0,
  };
};

export const getFormsLimits = state => {
  const plan = state?.session?.data?.currentPlan;
  const team = state?.session?.data?.currentTeam;

  return {
    activeFormsLimit: plan ? plan.attributes.forms : 0,
    activeFormsCount: team && team.relationships.forms ? team.relationships.forms.meta.published_count : 0,
  };
};

export const getPlan = state => {
  return state?.session?.data?.currentPlan;
};

export const getTeam = state => {
  return state?.session?.data?.currentTeam;
};

export const getTeamId = state => {
  return state?.session?.data?.currentTeam?.id;
};

export const getOnBoardingState = state => {
  const team = state?.session?.data?.currentTeam;
  if (!team) return;

  return team?.attributes?.onboarding;
};
