import { ACTIONS } from '../../actions/posts';
import { Config } from '../../../../utils/config';

export const initialState = {
  data: [],
  isLoading: false,
  error: null,
};

export const posts = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.FETCH_POSTS.REQUEST: {
      return { ...state, isLoading: true };
    }

    case ACTIONS.FETCH_POSTS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload,
        error: null,
      };
    }

    case ACTIONS.FETCH_POSTS.FAILURE: {
      return { ...state, isLoading: false, error: action.payload };
    }

    default:
      return state;
  }
};

export const selectPosts = state => state.data;
export const selectIsLoading = state => state.isLoading;
export const selectPostsWithImages = state =>
  state.data.filter(
    post => post.attributes.image_url !== null && Config.regExp.httpPattern.test(post.attributes.image_url)
  );

export default posts;
