import produce from 'immer';
import { format, startOfDay, endOfDay } from 'date-fns';
import { ACTIONS } from '../actions/social-publishing.filters.actions';
import { SocialPublishingConstants } from '../utils/constants';

export const periodType = {
  WEEK: 'WEEK',
  MONTH: 'MONTH',
};

const currentDate = new Date();

const startOfWeek = date => {
  let _date = new Date(date);
  return startOfDay(_date.setDate(_date.getDate() - _date.getDay() + SocialPublishingConstants.startDateDay));
};

const endOfWeek = date => {
  let _date = new Date(date);
  return endOfDay(_date.setDate(_date.getDate() - _date.getDay() + SocialPublishingConstants.endDateDay));
};

export const initialState = {
  period: periodType.WEEK,
  date: {
    $gte: startOfWeek(currentDate),
    $lte: endOfWeek(currentDate),
  },
  content: {},
  mimeType: {},
  selectedFilters: {},
};

export const socialPublishingFilters = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_PERIOD_TYPE:
      return {
        ...state,
        period: action.payload,
      };

    case ACTIONS.SET_DATE:
      return {
        ...state,
        date: action.payload,
      };

    case ACTIONS.SET_CONTENT_STATUS:
      return produce(state, draftState => {
        draftState.content[action.payload.key] = action.payload.value;
        return draftState;
      });

    case ACTIONS.SET_MIME_TYPE:
      return produce(state, draftState => {
        draftState.mimeType[action.payload.key] = action.payload.value;
        return draftState;
      });

    default:
      return state;
  }
};

export const getFilters = state => {
  return state?.socialPublishingFilters?.selectedFilters;
};

export const getFiltersDate = state => {
  return state?.socialPublishingFilters?.date;
};

export const getFiltersAmount = state => {
  if (!state || !state.socialPublishingFilters) {
    return;
  }

  return Object.values(state.socialPublishingFilters.selectedFilters)?.flat()?.length;
};
