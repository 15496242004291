import React from 'react';
import './units-input.sass';
import DropdownButton, { ButtonType } from '../dropdown/button/dropdown-button';
import { DropdownHeader } from '../dropdown/button/header/dropdown-header';
import DropdownSimpleList from '../dropdown/lists/simple-list/dropdown-simple-list';
import InputPrimary from '../inputs/input-primary/input-primary';

const Units = {
  PIXEL: 'px',
  PERCENT: '%',
};

export const unitList = Object.values(Units).map(unit => ({
  name: unit,
  value: unit,
}));

const UnitsInput = ({ isDisabled, label, formik, id, name, formikValueKey }) => {
  return (
    <div className='tint-units-input'>
      {label && <label>{label}</label>}

      <div className='tint-units-input__wrapper'>
        <InputPrimary
          type='number'
          name={name}
          id={id}
          value={formik.values[formikValueKey]}
          placeholder='ex. 100'
          handleChange={e => {
            formik.handleChange(e);
          }}
          disabled={isDisabled}
          error={formik.errors[formikValueKey]}
          touched={!!formik.errors[formikValueKey]}
          style={{
            border: 'none',
          }}
        />

        <DropdownButton
          currentItem={formik.values.unit}
          dropdownHeader={props => <DropdownHeader {...props} />}
          dropdownList={props => <DropdownSimpleList {...props} isDefaultOption={false} />}
          list={unitList}
          disabled={isDisabled}
          placeholder='px'
          iconRight='fa fa-caret-down'
          buttonType={ButtonType.BUTTON_NO_STYL}
          onChangeValue={selectedItem => {
            formik.setFieldValue('unit', selectedItem);
          }}
        />
      </div>
    </div>
  );
};

export default UnitsInput;
