import React from 'react';
import PropTypes from 'prop-types';
import './stripe-checkout-form-footer.sass';
import { Button } from '../../interface/button/button';
import { getPlan } from '../../../reducers/session';
import { useSelector } from 'react-redux';

export const StripeCheckoutFormFooter = ({ onClose, onClickPay = 0, numberOfDays = 1, disabled }) => {
  const plan = useSelector(getPlan);
  const instintUpdatesDailyPrice = plan?.attributes?.instint_updates_daily_price * numberOfDays || 0;
  const value = (Math.round(instintUpdatesDailyPrice) / 100).toFixed(2);

  return (
    <div className='stripe-checkout-form-footer'>
      <Button type='secondary' text='Cancel' onClick={onClose} />
      <Button type='primary' text={`Pay $${value}`} onClick={onClickPay} isDisabled={disabled} />
    </div>
  );
};

StripeCheckoutFormFooter.propTypes = {
  previousStep: PropTypes.func,
  onClickPay: PropTypes.func,
  estimatedQuote: PropTypes.number,
};
