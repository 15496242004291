import { ACTIONS } from '../actions/ui-ecommerce.actions';
import produce from 'immer';
import { ImageDraggableRectStatus } from '../components/image-draggable-rect/image-draggable-rect';
import { AssignProductsSidebarStepType } from '../components/assign-products-sidebar/assign-products-sidebar';

export const initialState = {
  isPending: false,
  error: undefined,
  data: undefined,
  selectedProduct: undefined,
  hoveredProduct: undefined,
  currentRect: undefined,
  taggedProducts: [],
  sidebarStatus: AssignProductsSidebarStepType.STEP_ONE,
  isNoDrawable: false,
};

export const uiEcommerce = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_POST_TAGGED_PRODUCTS.REQUEST:
    case ACTIONS.PATCH_PRODUCT_TAG.REQUEST:
    case ACTIONS.POST_PRODUCT_TAG.REQUEST:
    case ACTIONS.DELETE_PRODUCT_TAG.REQUEST:
      return {
        ...state,
        isPending: true,
        error: undefined,
      };

    case ACTIONS.GET_POST_TAGGED_PRODUCTS.FAILURE:
    case ACTIONS.PATCH_PRODUCT_TAG.FAILURE:
    case ACTIONS.POST_PRODUCT_TAG.FAILURE:
    case ACTIONS.DELETE_PRODUCT_TAG.FAILURE:
      return produce(state, draft => {
        draft.isPending = false;
        draft.error = action.payload;
      });

    case ACTIONS.PATCH_PRODUCT_TAG.SUCCESS:
    case ACTIONS.POST_PRODUCT_TAG.SUCCESS:
      return produce(state, draft => {
        draft.isPending = false;
        draft.error = undefined;
        draft.taggedProducts = draft.taggedProducts.map(product => {
          if (product.rectId === action.payload.rectId) {
            return {
              ...product,
              ...draft.selectedProduct,
              ...action.payload.data.attributes,
              rectId: action.payload.data.id,
              productId: action.payload.productId,
            };
          } else {
            return product;
          }
        });
        draft.currentRect = {
          ...action.payload.data.attributes,
          rectId: action.payload.data.id,
          productId: action.payload.productId,
        };
      });

    case ACTIONS.GET_POST_TAGGED_PRODUCTS.SUCCESS:
      return produce(state, draft => {
        draft.isPending = false;
        draft.data = action.payload;
        draft.data.included = action.payload.included ? mergeProductTagsWithProducts(action.payload.included) : [];

        draft.taggedProducts =
          action.payload.included && action.payload.included
            ? action.payload.included.map(taggedProduct => ({
                ...taggedProduct.attributes,
                product_id: undefined,
                productId: taggedProduct.attributes.product_id,
                rectId: taggedProduct.id,
                status: ImageDraggableRectStatus.COMPLETE,
              }))
            : [];
      });

    case ACTIONS.DRAW_RECT:
      return produce(state, draft => {
        draft.currentRect = action.payload;
        draft.taggedProducts.push(action.payload);
        draft.sidebarStatus = AssignProductsSidebarStepType.STEP_TWO;
      });

    case ACTIONS.ADD_NO_DRAWABLE_PRODUCT:
      return produce(state, draft => {
        draft.currentRect = action.payload;
        draft.sidebarStatus = AssignProductsSidebarStepType.STEP_TWO;
        draft.isNoDrawable = true;
        draft.taggedProducts.push(action.payload);
      });

    case ACTIONS.CANCEL_DRAW_RECT:
      return produce(state, draft => {
        draft.taggedProducts = draft.taggedProducts.filter(
          product =>
            product.status === ImageDraggableRectStatus.COMPLETE ||
            (product.status === ImageDraggableRectStatus.EDITABLE && product.productId)
        );
        draft.currentRect = undefined;
        draft.selectedProduct = undefined;
        draft.hoveredProduct = undefined;
        draft.sidebarStatus = AssignProductsSidebarStepType.STEP_ONE;
        draft.isNoDrawable = false;
      });

    case ACTIONS.DISMISS_DRAW_RECT:
      return produce(state, draft => {
        draft.taggedProducts = draft.taggedProducts.map(product => ({
          ...product,
          status: ImageDraggableRectStatus.COMPLETE,
        }));
        draft.currentRect = undefined;
        draft.isNoDrawable = false;
      });

    case ACTIONS.EDIT_RECT_UI_STATUS:
      return produce(state, draft => {
        draft.taggedProducts = draft.taggedProducts.map(taggedProduct => {
          if (taggedProduct.rectId === action.payload.rectId) {
            return {
              ...taggedProduct,
              status: action.payload.status,
            };
          } else {
            return {
              ...taggedProduct,
              status: ImageDraggableRectStatus.COMPLETE,
            };
          }
        });

        const isEditable = draft.taggedProducts.find(
          taggedProduct => taggedProduct.status === ImageDraggableRectStatus.EDITABLE
        );
        draft.sidebarStatus = isEditable
          ? AssignProductsSidebarStepType.STEP_ONE
          : AssignProductsSidebarStepType.STEP_TWO;
      });

    case ACTIONS.ASSIGN_PRODUCT:
      return produce(state, draft => {
        draft.taggedProducts = draft.taggedProducts.map(product => {
          if (product.status === ImageDraggableRectStatus.ASSIGN) {
            return {
              ...product,
              productId: action.payload.productDetails.id,
              rectId: action.payload.rectId,
            };
          } else {
            return product;
          }
        });
        draft.sidebarStatus = AssignProductsSidebarStepType.STEP_THREE;
      });

    case ACTIONS.DELETE_PRODUCT_TAG.SUCCESS:
      return produce(state, draft => {
        draft.taggedProducts = draft.taggedProducts.filter(product => product.rectId !== action.payload.rectId);
        draft.isPending = false;
      });

    case ACTIONS.UPDATE_RECT_UI:
      return produce(state, draft => {
        draft.taggedProducts = draft.taggedProducts.map(product => {
          if (product.rectId === action.payload.rectId) {
            return {
              ...product,
              ...action.payload,
            };
          } else {
            return product;
          }
        });
        draft.currentRect = action.payload;
      });

    case ACTIONS.SELECT_PRODUCT:
      return produce(state, draft => {
        draft.selectedProduct = {
          ...action.payload,
        };
      });

    case ACTIONS.HOVER_PRODUCT:
      return produce(state, draft => {
        draft.hoveredProduct = action.payload;
      });

    case ACTIONS.UN_HOVER_PRODUCT:
      return produce(state, draft => {
        draft.hoveredProduct = undefined;
      });

    case ACTIONS.UNSELECT_PRODUCT:
      return produce(state, draft => {
        draft.selectedProduct = undefined;
      });

    case ACTIONS.RESTORE_DEFAULT_TAGGED_PRODUCTS:
      return produce(state, draft => {
        draft.taggedProducts = draft.taggedProducts
          ? draft.taggedProducts.map(taggedProduct => ({
              ...taggedProduct.attributes,
              product_id: undefined,
              productId: taggedProduct.id,
              rectId: taggedProduct.id,
              status: ImageDraggableRectStatus.COMPLETE,
            }))
          : [];
      });

    default:
      return state;
  }
};

export const getTaggedProducts = state =>
  state
    ? state.taggedProducts.find(
        e =>
          e.status === ImageDraggableRectStatus.EDITABLE ||
          e.status === ImageDraggableRectStatus.UNASSIGNED ||
          e.status === ImageDraggableRectStatus.ASSIGN
      )
    : undefined;

const mergeProductTagsWithProducts = data => {
  const products = data.filter(e => e.type === 'product');
  const productTags = data.filter(e => e.type === 'product_tag');

  return productTags.map(productTag => {
    const product = products.find(
      e =>
        productTag.relationships &&
        productTag.relationships.product &&
        productTag.relationships.product.data &&
        productTag.relationships.product.data.id === e.id
    );

    const productDetails = product ? product.attributes : {};
    return {
      ...productTag,
      attributes: {
        ...productTag.attributes,
        ...productDetails,
        product_id: product.id,
      },
    };
  });
};
