import React from 'react';
import PropTypes from 'prop-types';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { EditInput, EditForm } from '../../../';

const PostEditPopup = ({ data, isCustomFieldAvailable, onTranslationUpdate, getTranslation }) => {
  return (
    <EditModalBody>
      <EditForm>
        <EditInput
          defaultValue={getTranslation(data.popupTitle)}
          label='popup title'
          onChange={e => {
            onTranslationUpdate(data.popupTitle, e.target.value);
          }}
        />
        <EditInput
          defaultValue={getTranslation(data.popupButtonText)}
          label='button text'
          onChange={e => {
            onTranslationUpdate(data.popupButtonText, e.target.value);
          }}
        />
        {isCustomFieldAvailable ? (
          <EditInput
            defaultValue={getTranslation(data.customFieldPlaceholder)}
            label='custom field'
            onChange={e => {
              onTranslationUpdate(data.customFieldPlaceholder, e.target.value);
            }}
          />
        ) : null}
      </EditForm>
    </EditModalBody>
  );
};

PostEditPopup.propTypes = {
  dataUpdate: PropTypes.func,
  data: PropTypes.object,
};

export default PostEditPopup;
