import { ACTIONS } from '../actions/tint-editor.actions';

export const initialState = {
  data: [],
  isFetching: false,
  error: undefined,
};

export const instintUpdates = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_INSTINT_UPDATES.REQUEST:
    case ACTIONS.POST_INSTINT_UPDATES.REQUEST:
      return {
        ...state,
        ...{
          isFetching: true,
          isError: false,
        },
      };

    case ACTIONS.POST_INSTINT_UPDATES.SUCCESS:
      return {
        ...state,
        ...{
          data: [...state.data, action.payload],
          isFetching: false,
          isError: false,
        },
      };

    case ACTIONS.GET_INSTINT_UPDATES.SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload,
          isFetching: false,
          isError: false,
        },
      };

    case ACTIONS.GET_INSTINT_UPDATES.FAILURE:
    case ACTIONS.POST_INSTINT_UPDATES.FAILURE:
      return {
        ...state,
        ...{
          isError: action.payload,
          isFetching: false,
        },
      };
    default:
      return state;
  }
};
