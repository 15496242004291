import React, { Component, Fragment } from 'react';
import './edit-modal-step.sass';
import PropTypes from 'prop-types';
import { Button } from '../../../components/interface/button/button';

export class EditModalStep extends Component {
  render() {
    return (
      <Fragment>
        <div className='tint-edit-modal__header'>
          <span className='tint-edit-modal__header__title'>{this.props.title}</span>
          <i
            className='fal fa-times'
            onClick={() => {
              this.props.closeModalMain && this.props.closeModalMain();
              this.props.closeModal && this.props.closeModal();
            }}
          />
        </div>
        <div className={`tint-edit-modal__body ${this.props.type ? `tint-edit-modal__body--${this.props.type}` : ''}`}>
          {this.props.children}
        </div>
        <div className={`tint-edit-modal__footer ${this.props.footer ? '' : 'tint-edit-modal__footer--default'}`}>
          {this.props.footer ? (
            React.cloneElement(this.props.footer, {
              saveData: this.props.customSaveData || this.props.saveData,
              onModalClose: this.props.closeModalMain,
              goToNextStep: this.props.nextStep,
              goToPreviousStep: this.props.previousStep,
            })
          ) : (
            <Fragment>
              {this.props.previousStep ? (
                <Button onClick={this.props.previousStep} type='secondary' size='long' text='Previous' />
              ) : (
                <Button
                  onClick={() => {
                    this.props.closeModalMain && this.props.closeModalMain();
                    this.props.onCancelClick && this.props.onCancelClick();
                  }}
                  type='secondary'
                  size='long'
                  text='Cancel'
                />
              )}
              <Button
                isDisabled={this.props.isApplyButtonDisabled}
                onClick={this.props.saveData}
                type='primary'
                size='long'
                text='Apply'
              />
            </Fragment>
          )}
        </div>
      </Fragment>
    );
  }
}

EditModalStep.propTypes = {
  title: PropTypes.string,
  isVisible: PropTypes.bool,
  isApplyButtonDisabled: PropTypes.bool,
  dismissModal: PropTypes.func,
  customSaveData: PropTypes.func,
  saveData: PropTypes.func,
  closeModal: PropTypes.func,
  closeModalMain: PropTypes.func,
  onCancelClick: PropTypes.func,
  footer: PropTypes.object,
  nextStep: PropTypes.func,
  previousStep: PropTypes.func,
  type: PropTypes.string,
};
