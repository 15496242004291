import React, { Fragment } from 'react';
import './form-builder.form.interface.dropdown.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';
import { FormBuilderFormHint } from '../common/hint/form-builder-form.hint';
import { FormBuilderElementsMappingService } from '../../../../services/builder/form-builder.elements-mapping.service';

export const FormBuilderFormInterfaceDropdown = ({ styles, item, values }) => {
  if (!item) return null;

  const { t } = useTranslation();

  const { label, select, p: hint } = FormBuilderElementsMappingService.getSingleElementHTMLTags(item);

  return (
    <div className={item.class} style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS]}>
      <label style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.LABEL]} htmlFor={label.for}>
        {t(label.nodeValue)}
      </label>
      <select
        id={select.id}
        style={{
          ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SELECT],
          ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT],
          ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS],
        }}
        name={select?.name}
        defaultValue={values?.getAll(select.name)?.length ? values.getAll(select.name) : ''}
        required={select?.required}
        aria-describedby={select['aria-describedby']}>
        {select.elements.map((el, index) => {
          if (el.nodeName === 'option') {
            return (
              <Fragment key={index}>
                <option value={el.value}>{t(el.nodeValue)}</option>
              </Fragment>
            );
          }
        })}
      </select>
      <FormBuilderFormHint
        item={t(hint?.nodeValue)}
        id={hint?.id}
        style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.HINT]}
      />
    </div>
  );
};
