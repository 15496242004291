import { ACTIONS } from '../actions/tint-mix-preview.actions';
const initialState = {
  currentTemplateId: 0,
};

export const tintMixPreview = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_CURRENT_SCENE_ID:
      return {
        ...state,
        currentTemplateId: action.payload,
      };

    case ACTIONS.GET_MIX.SUCCESS:
      return {
        data: action.payload,
      };

    default:
      return state;
  }
};
