import React from 'react';
import useFeatureAccessibility from '../../hooks/use-feature-accessibility/use-feature-accessibility';
import { Config } from '../../../utils/config';
import { createParamsForUrl, filters } from '../../../feature/account-settings/utils/filter-helper/filter-helper';
import { EmailDomainsStatus } from '../../../types/status';
import { dropdownDefaultSelectField } from '../../../feature/tint-editor/components/tint-editor/sidebars/social-feed-sidebar/utils/common/common';
import ApiDropdown from '../api-dropdown';

const emailDomainsDropdownId = 'emailDomains';

const createEmailDomainsAdditionalOption = {
  name: '+ Add Domain',
  value: 'add_domain',
  link: Config.routePaths.teamSettings.emailDomains.path,
};

export const DropdownEmailDomains = ({ currentElement, onChange, styles, placeholder = 'Select...' }) => {
  const { domainsEmail } = useFeatureAccessibility();
  const baseUrl = '/email_domains';

  const getUrl = (url, inputValue, pageNumber) => {
    return (
      url ||
      createParamsForUrl({
        baseUrl: baseUrl,
        filters: [
          {
            by: filters.QUERY,
            value: inputValue,
          },
          {
            by: filters.STATUS,
            value: EmailDomainsStatus.COMPLETE,
          },
        ],
        pageNumber,
      })
    );
  };

  if (!domainsEmail) return null;

  return (
    <div className='tint-email-dropdown'>
      <ApiDropdown
        placeholder={placeholder}
        fetchUrl={getUrl}
        baseUrl={baseUrl}
        currentElement={currentElement}
        dropdownId={emailDomainsDropdownId}
        onChange={onChange}
        unselectOption={dropdownDefaultSelectField}
        additionalOption={createEmailDomainsAdditionalOption}
        styles={styles}
      />
    </div>
  );
};
