import { useState, useEffect } from 'react';
import { SocialFeedsSource } from '../../../types/social-feeds';
import { MarketingIntegrations } from '../../../types/marketing-integrations';
import { ProductAccounts } from '../../../types/product-accounts';
import { ProductFeeds } from '../../../types/product-feeds';
import { removePixelsFromValue } from '../../../feature/ecommerce/hooks/helpers';

const CustomSizeSources = [
  SocialFeedsSource.WEBEX,
  SocialFeedsSource.SLACK,
  SocialFeedsSource.TIK_TOK,
  ProductAccounts.SHOPIFY,
  ProductAccounts.MAGENTO,
  ProductAccounts.BIGCOMMERCE,
  ProductAccounts.SALESFORCE_COMMERCE_CLOUD,
  MarketingIntegrations.ADOBE,
  MarketingIntegrations.BYNDER,
  MarketingIntegrations.DROPBOX,
  MarketingIntegrations.HUBSPOT,
  MarketingIntegrations.MAILCHIMP,
  MarketingIntegrations.SALESFORCE,
  MarketingIntegrations.BRANDFOLDER,
  MarketingIntegrations.WIDEN,
  MarketingIntegrations.HOOTSUITE,
  ProductFeeds.MANUAL,
  ProductFeeds.SALESFORCE_COMMERCE_CLOUD,
];

const IconScales = {
  SMALL: 0.6,
  MEDIUM: 0.85,
};

const useSvgIconCustomSize = ({ source, currentWidth, currentHeight, resizeValue }) => {
  const [width, setWidth] = useState();
  const [height, setHeight] = useState();

  const resizeSvgIcon = (w, h, size) => {
    const isSmallIcon = size > 0 && size <= 20;
    const isMediumIcon = size > 20 && size < 42;

    if (isSmallIcon) {
      setWidth(w * IconScales.SMALL);
      setHeight(h * IconScales.SMALL);
    } else if (isMediumIcon) {
      setWidth(w * IconScales.MEDIUM);
      setHeight(h * IconScales.MEDIUM);
    } else {
      setWidth(w);
      setHeight(h);
    }
  };

  useEffect(() => {
    if (source && currentWidth && currentHeight) {
      const isCustomSize = CustomSizeSources.includes(source);
      const defaultWidth = Number(removePixelsFromValue(currentWidth));
      const defaultHeight = Number(removePixelsFromValue(currentHeight));

      if (isCustomSize) {
        resizeSvgIcon(defaultWidth, defaultHeight, resizeValue);
      } else {
        setWidth(resizeValue || defaultWidth);
        setHeight(resizeValue || defaultHeight);
      }
    }
  }, [source, currentWidth, currentHeight, resizeValue]);

  return { iconWidth: width, iconHeight: height };
};

export default useSvgIconCustomSize;
