import { createPrefixedActionName } from '../../../../services/functions/redux-routines/redux-routines';

export const ACTIONS = {
  SET_SORT_OPTION: createPrefixedActionName('ASSET_MANAGER_FILTERS', 'SET_SORT_OPTION'),
  SET_FILTER_OPTION: createPrefixedActionName('ASSET_MANAGER_FILTERS', 'SET_FILTER_OPTION'),
  SET_SEARCH_PHRASE: createPrefixedActionName('ASSET_MANAGER_FILTERS', 'SET_SEARCH_PHRASE'),
  CLEAR_FILTER_OPTION: createPrefixedActionName('ASSET_MANAGER_FILTERS', 'CLEAR_FILTER_OPTION'),
};

export const setSortOption = sortOption => ({
  type: ACTIONS.SET_SORT_OPTION,
  payload: sortOption,
});

export const setFilterOption = objectFilterOption => ({
  type: ACTIONS.SET_FILTER_OPTION,
  payload: objectFilterOption,
});

export const clearFilterOption = () => ({
  type: ACTIONS.CLEAR_FILTER_OPTION,
});

export const setSearchPhraseOption = phrase => ({
  type: ACTIONS.SET_SEARCH_PHRASE,
  payload: phrase,
});
