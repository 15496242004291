import * as React from 'react';
import Masonry from 'react-masonry-component';
import './masonry.sass';
import PropTypes from 'prop-types';

class MasonryLayout extends React.Component {
  renderChildElements() {
    return this.props.elements.map((item, i) => {
      return (
        <div className={'masonry-list__item'} key={i}>
          {this.props.children(item)}
        </div>
      );
    });
  }

  render() {
    return (
      <Masonry
        enableResizableChildren={true}
        options={{ percentPosition: true }}
        className={'masonry-list'}
        updateOnEachImageLoad={true}>
        {this.renderChildElements()}
      </Masonry>
    );
  }
}

export default MasonryLayout;

MasonryLayout.propTypes = {
  elements: PropTypes.array,
  children: PropTypes.func,
};
