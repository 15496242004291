import { tumblrDisabledButton, SocialFeedsFooterLabels } from './helpers';
import { SidebarIcons, SidebarItemTypes } from '../utils/meta/consts';
import {
  validationSchemaTumblrUsername,
  validationSchemaTumblrHashtag,
} from '../utils/validation-schema/tumblr-validation';

export const tumblr = [
  {
    header: 'Add Tumblr',
    description: 'Choose a Social Feed type below:',
    typeList: [
      {
        id: 'account',
        title: 'U' + 'sername',
        description: 'Import content from a Tumblr username.',
      },
      {
        id: 'hashtag',
        title: 'Hashtag',
        description: 'Import content from a Tumblr hashtag.',
      },
    ],
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepNext,
    },
    zendeskUrl: undefined,
    isAlert: false,
    formSchema: undefined,
  },
  {
    header: undefined,
    description: undefined,
    zendeskUrl: undefined,
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.finalStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext,
    },
    accountSourceType: 'tumblr',
    formSchema: {
      account: {
        validationSchema: validationSchemaTumblrUsername(),
        submitMapper: values => ({
          account_id: values.account.value,
          external_id: values.username,
          type: 'account',
        }),
        inputs: [
          {
            id: 'account',
            backendId: 'account_id',
            label: 'SELECT ACCOUNT',
            placeholder: 'Select Account...',
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
          },
          {
            id: 'username',
            backendId: 'external_id',
            label: 'ENTER USERNAME',
            placeholder: 'Username',
            description: 'Enter a Tumblr username to ingest content.',
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.TEXT,
            disabled: tumblrDisabledButton,
          },
        ],
      },
      hashtag: {
        validationSchema: validationSchemaTumblrHashtag(),
        submitMapper: values => ({
          account_id: values.account.value,
          external_id: `#${values.hashtag}`,
          type: 'hashtag',
        }),
        inputs: [
          {
            id: 'account',
            backendId: 'account_id',
            label: 'SELECT ACCOUNT',
            placeholder: 'Select Account...',
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
          },
          {
            id: 'hashtag',
            backendId: 'external_id',
            label: 'ENTER HASHTAG',
            placeholder: 'Hashtag',
            description: 'Enter a Tumblr hashtag to ingest content.',
            icon: SidebarIcons.HASHTAG,
            type: SidebarItemTypes.TEXT,
            disabled: tumblrDisabledButton,
          },
        ],
      },
    },
  },
];
