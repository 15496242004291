import { ACTIONS } from '../../actions/oauth-applications/oauth-applications-table-rows.actions';

export const initialState = {
  data: [],
};

export const oAuthApplicationsTableRows = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ADD_ROWS_TO_TABLE:
      return {
        ...state,
        data: action.payload.map(e => {
          return { id: e.id };
        }),
      };

    case ACTIONS.ADD_ROW_TO_TABLE:
      return {
        ...state,
        data: state.data
          .filter(item => {
            return item.id !== action.payload.id;
          })
          .concat(action.payload),
      };

    case ACTIONS.REMOVE_ROW_FROM_TABLE:
      return {
        ...state,
        data: state.data.filter(e => e.id !== action.payload),
      };

    case ACTIONS.CLEAR_TABLE:
      return initialState;

    case ACTIONS.SHOW_SECRET_ID:
      return {
        ...state,
        data: state.data.map(e => (e.id === action.payload ? { ...e, isShownSecretId: true } : e)),
      };

    case ACTIONS.HIDE_SECRET_ID:
      return {
        ...state,
        data: state.data.map(e => (e.id === action.payload ? { ...e, isShownSecretId: false } : e)),
      };

    case ACTIONS.EXPAND_TABLE_ROW:
      return {
        ...state,
        data: state.data.map(e => (e.id === action.payload ? { ...e, isExpanded: true } : e)),
      };

    case ACTIONS.COLLAPSE_TABLE_ROW:
      return {
        ...state,
        data: state.data.map(e => (e.id === action.payload ? { ...e, isExpanded: false } : e)),
      };

    default:
      return state;
  }
};
