import { ACTIONS } from '../../actions/collaborators/collaborators.actions';
import { produce } from 'immer';

export const initialState = {
  data: [],
  included: [],
  links: undefined,
  meta: undefined,
  isFetching: false,
  error: undefined,
  selectedCollaborators: [],
};

export const collaborators = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_COLLABORATORS.REQUEST:
    case ACTIONS.BULK_DELETE_COLLABORATORS.REQUEST:
      return {
        ...state,
        ...{
          isFetching: true,
          error: undefined,
        },
      };
    case ACTIONS.DELETE_COLLABORATOR.REQUEST:
    case ACTIONS.PATCH_COLLABORATOR.REQUEST:
    case ACTIONS.BULK_PATCH_COLLABORATORS.REQUEST:
      return {
        ...state,
        ...{
          error: undefined,
        },
      };
    case ACTIONS.POST_COLLABORATOR.REQUEST:
      return {
        ...state,
        ...{
          error: undefined,
        },
      };
    case ACTIONS.GET_COLLABORATORS.SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload.data,
          links: action.payload.links,
          meta: action.payload.meta,
          included: action.payload.included,
          isFetching: false,
          error: undefined,
        },
      };

    case ACTIONS.DELETE_COLLABORATOR.SUCCESS:
      return {
        ...state,
        isFetching: false,
        data: state.data.filter(e => e.id !== action.payload),
        error: undefined,
      };

    case ACTIONS.PATCH_COLLABORATOR.SUCCESS:
      return {
        ...state,
        data: state.data.map(e => (e.id === action.payload.data.id ? action.payload.data : e)),
        included: state.included.concat(
          action.payload.included ? action.payload.included.filter(e => !state.included.some(r => r.id === e.id)) : []
        ),
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.BULK_PATCH_COLLABORATORS.SUCCESS:
      return {
        ...state,
        data: state.data.map(e => {
          return action.payload.data.find(d => d.id === e.id) ? action.payload.data.find(d => d.id === e.id) : e;
        }),
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.POST_COLLABORATOR.SUCCESS:
      return {
        ...state,
        data: [...action.payload.data, ...state.data],
        included: state.included
          ? state.included.concat(
              action.payload.included
                ? action.payload.included.filter(e => !state.included.some(r => r.id === e.id))
                : []
            )
          : [],
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.GET_COLLABORATORS.FAILURE:
    case ACTIONS.POST_COLLABORATOR.FAILURE:
    case ACTIONS.DELETE_COLLABORATOR.FAILURE:
    case ACTIONS.PATCH_COLLABORATOR.FAILURE:
    case ACTIONS.BULK_PATCH_COLLABORATORS.FAILURE:
    case ACTIONS.BULK_DELETE_COLLABORATORS.FAILURE:
      return {
        ...state,
        ...{
          error: action.payload,
          isFetching: false,
        },
      };

    case ACTIONS.SELECT_COLLABORATOR:
      return {
        ...state,
        selectedCollaborators: [...state.selectedCollaborators, action.payload],
      };

    case ACTIONS.UNSELECT_COLLABORATOR:
      return {
        ...state,
        selectedCollaborators: state.selectedCollaborators.filter(({ id }) => {
          return action.payload !== id;
        }),
      };

    case ACTIONS.DESELECT_ALL_COLLABORATORS:
      return {
        ...state,
        selectedCollaborators: [],
      };

    case ACTIONS.BULK_DELETE_COLLABORATORS.SUCCESS:
      return produce(state, draftState => {
        draftState.data = draftState.data.filter(collaborator => {
          return !action.payload.some(c => c.id === collaborator.id);
        });
        draftState.isFetching = false;
        draftState.isError = false;
        draftState.selectedCollaborators = [];
      });

    default:
      return state;
  }
};

export const getAlreadyInvitedEmails = state => (state ? state.map(e => e.attributes.email) : []);

export const filterCollaboratorsRoles = collaborators => {
  const _state = collaborators.data;
  return _state
    ? _state.map(collaborator => {
        return {
          ...collaborator,
          role:
            collaborators.included && !!collaborator.relationships.role.data
              ? collaborators.included.find(e => e.id === collaborator.relationships.role.data.id)
              : null,
        };
      })
    : [];
};
