import React, { Component } from 'react';
import DragAndDropItem from './drag-and-drop-item';
import './drag-and-drop-item.sass';
import update from 'immutability-helper';
import PropTypes from 'prop-types';

export const DragAndDropType = {
  PARENT: 'parent',
  CHILD: 'children',
};

class DragAndDropContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: this.mapSectionList(),
      isDragging: false,
      dragItemClass: 'inactive',
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sectionList !== this.props.sectionList) {
      this.setState({ sections: this.mapSectionList() });
    }
  }

  mapSectionList() {
    return this.props.sectionList.map((item, index) => ({
      ...item,
      elementId: index,
    }));
  }

  onMoveElement = (dragIndex, hoverIndex) => {
    const dragCard = this.state.sections[dragIndex];

    this.setState(state => {
      return {
        sections: update(state.sections, {
          $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
        }),
      };
    });
  };

  onDroppedElement = () => {
    const unMapSections = this.state.sections.map(item => {
      const majorItem = {
        ...item,
        elementId: item.elementId,
      };

      return item.column === undefined ? majorItem : { ...majorItem, column: item.column };
    });

    this.props.onChangeOrder(unMapSections);
    this.setState({ isDragging: false });
    this.setState({ dragItemClass: 'inactive' });
  };

  onElementDrag = () => {
    this.setState({ isDragging: true });
  };

  onMouseDown = e => {
    e.stopPropagation();
    this.setState({ dragItemClass: 'active' });
  };

  onMouseUp = () => {
    this.setState({ dragItemClass: 'inactive' });
  };

  render() {
    return (
      <div
        className={`drag-and-drop-container ${this.state.isDragging ? 'drag-and-drop-container--dragging' : ''} ${
          this.state.dragItemClass
        }`}
        onMouseDown={this.onMouseDown}
        onMouseEnter={this.onMouseDown}
        onMouseLeave={this.onMouseUp}>
        {this.state.sections.map(card => (
          <DragAndDropItem
            isAnyElementDragging={this.state.isDragging}
            currentDragClass={this.state.dragItemClass}
            itemType={this.props.itemType}
            key={card.elementId}
            index={card.elementId}
            onMoveElement={this.onMoveElement}
            onElementDropped={this.onDroppedElement}
            onElementDrag={this.onElementDrag}
            dndItemComponent={this.props.dndItemComponent}
            item={card}
            isVerticalDisplay={this.props.isVerticalDisplay}
          />
        ))}
      </div>
    );
  }
}

export default DragAndDropContainer;

DragAndDropContainer.propTypes = {
  sectionList: PropTypes.array,
  onChangeOrder: PropTypes.func,
  dndItemComponent: PropTypes.func,
  isVerticalDisplay: PropTypes.bool,
};
