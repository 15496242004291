import React from 'react';
import TintThumbnail from '../../../../../../../../components/interface/thumbnail/thumbnail';
import PropTypes from 'prop-types';
import './post-item.sass';
import ColorParser from '../../../../../../../../services/color-parser/color-parser';

const PostItem = function({ thumb, title, styles }) {
  return (
    <div
      className='tint-post-item'
      style={{
        background: ColorParser.defaultColor(styles.backgroundColor),
        borderBottom: `4px solid ${ColorParser.defaultColor(styles.backgroundColorRange)}`,
        color: ColorParser.defaultColor(styles.textColor),
      }}>
      <div className={'tint-post-item__wrapper'}>
        <div className='tint-post-item__thumb'>
          <TintThumbnail noAlt={true} imageUrl={thumb} style={{ width: 'auto', height: 'auto' }} />
        </div>
        {title ? (
          <div className='tint-post-item__content'>
            <p style={{ color: ColorParser.defaultColor(styles.textColor) }}>{title}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default PostItem;

PostItem.propTypes = {
  thumb: PropTypes.string,
  title: PropTypes.string,
  styles: PropTypes.any,
};
