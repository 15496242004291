import React, { useState, useEffect } from 'react';
import './form-builder.sass';
import { ApiService } from '../../services/api-service/api-service';
import { handleApiError } from '../../services/functions/error-handler/error-handler';
import { toast } from 'react-toastify';
import { FormBuilderForm } from '../../feature/form-builder/components/form/form-builder.form';
import { Config } from '../../utils/config';

const FormBuilder = ({ id, language = Config.language.defaultLanguage, teamId }) => {
  const [data, setData] = useState();

  useEffect(() => {
    ApiService.pureInstance()
      .get(`/v2/teams/${teamId}/forms/${id}?include=media`)
      .then(res =>
        setData({
          ...res?.data?.data?.attributes,
          teamId,
          language,
          formId: id,
          apiUrl: Config.getApiUrl(),
        })
      )
      .catch(err => handleApiError(err, toast.error));
  }, [id, teamId]);

  return (
    <div className='post-modal-form-builder'>{data && <FormBuilderForm injectedData={data} language={language} />}</div>
  );
};

export default FormBuilder;
