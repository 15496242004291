// this is responsible for extracting only formElementId from form builder elements when their id
// consists of their name + '[]' or '[questions_1]' which is the case in multiple choice and matrix elements
export const getCleanFormElementId = formElementId => {
  const regex = /^([a-zA-Z0-9_]+)(?:\[[a-zA-Z0-9_]+\])?(?:\[\])?$/;
  const match = formElementId.match(regex);
  const cleanSlugIndex = 1;
  return match?.[cleanSlugIndex] || '';
};

// this is responsible for extracting only element slug with its associated index,
// so when the element id === 'multiple_choice_0_1', we only want to extract the multiple_choice_0 part
// in order to be able to dynamically add new inputs to that element with different subindex
export const getSlugFromId = id => {
  const parts = id.split('_');
  parts.pop();
  return parts.join('_');
};
