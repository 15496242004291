import { useEffect, useCallback } from 'react';
import { getSavedFilters } from '../../../feature/tint-editor/actions/posts.fat-actions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { getMappedSavedFilters } from '../../../feature/tint-editor/reducers/posts.reducer';
import { clearSavedFilters } from '../../../feature/tint-editor/actions/posts.actions';

const useSavedFilters = ({ tintId }) => {
  const dispatch = useDispatch();
  const mappedSavedFilters = useSelector(getMappedSavedFilters);
  const savedFilters = useCallback(() => mappedSavedFilters);

  useEffect(() => {
    if (!tintId) return;

    savedFilters?.length === 0 &&
      dispatch(getSavedFilters(tintId)).catch(() => toast.error('Cannot get Saved Filters, try again later'));

    return () => {
      dispatch(clearSavedFilters());
    };
  }, [tintId, dispatch]);

  return savedFilters;
};

export default useSavedFilters;
