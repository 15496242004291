import React from 'react';
import PropTypes from 'prop-types';

import './status-error.sass';

const StatusError = ({ error }) => {
  return error ? <div className='status-error'>{error}</div> : null;
};

StatusError.propTypes = {
  error: PropTypes.string,
};

export default StatusError;
