import { ACTIONS } from '../actions/comments.action';

export const initialState = {};

export const comments = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.POST_COMMENT.REQUEST:
      return {
        ...state,
        ...{
          isFetching: true,
          isError: false,
        },
      };

    case ACTIONS.POST_COMMENT.SUCCESS:
      return {
        ...state,
        ...{
          data: state.data
            .filter(item => {
              return item.tintId !== action.payload.tintId;
            })
            .concat(action.payload),
          isFetching: false,
          isError: false,
        },
      };
    case ACTIONS.POST_COMMENT.FAILURE:
      return {
        ...state,
        ...{
          isError: action.payload,
          isFetching: false,
        },
      };
    default:
      return state;
  }
};
