import React from 'react';
import './dropdown-header.sass';
import TintIcon from '../../../icon/icon';
import PreloaderComponent, {
  PreloaderBackdropType,
  PreloaderPositionType,
  PreloaderSizeType,
} from '../../../preloaders/preloader/preloader';

export const DropdownHeader = ({
  dropdownHeaderStyle,
  iconLeft,
  iconRight,
  iconCenter,
  iconOverlap,
  title,
  children,
  isLoading,
}) => {
  return (
    <div
      className={`tint-dropdown-header ${!title ? 'tint-dropdown-header--no-title' : ''}`}
      style={dropdownHeaderStyle}>
      {iconLeft && typeof iconLeft === 'string' && !iconCenter && (
        <span className={`icon icon__left ${iconLeft}`} data-testid='iconLeft' />
      )}
      {iconLeft && typeof iconLeft !== 'string' && !iconCenter && (
        <span className='icon icon__left'>
          <TintIcon icon={iconLeft} data-testid='iconLeft' />
        </span>
      )}
      {iconCenter && !iconOverlap ? <span className={`icon ${iconCenter}`} data-testid='iconCenter' /> : null}
      {iconCenter && iconOverlap ? (
        <>
          <span className={`icon ${iconCenter}`} data-testid='iconCenter' />
          <span className={`icon__overlap ${iconOverlap}`} data-testid='iconOverlap' />
        </>
      ) : null}
      {title && !iconCenter ? (
        <span
          className='tint-dropdown-header__title'
          style={{ color: dropdownHeaderStyle && dropdownHeaderStyle.color ? dropdownHeaderStyle.color : undefined }}>
          {title}
        </span>
      ) : null}
      {children && <>{children}</>}

      {isLoading ? (
        <div className='tint-dropdown-header__icons'>
          {isLoading && (
            <PreloaderComponent
              positionType={PreloaderPositionType.RELATIVE}
              backdropType={PreloaderBackdropType.TRANSPARENT}
              size={PreloaderSizeType.SMALL}
            />
          )}
        </div>
      ) : (
        iconRight &&
        !iconCenter && (
          <div className='tint-dropdown-header__icons'>
            {typeof iconRight === 'string' && (
              <span className={`icon icon__right ${iconRight}`} data-testid='iconRight' />
            )}
            {typeof iconRight !== 'string' && (
              <span className='icon icon__right'>
                <TintIcon icon={iconRight} data-testid='iconRight' />
              </span>
            )}
          </div>
        )
      )}
    </div>
  );
};
