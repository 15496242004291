import { ACTIONS } from '../actions/social-feeds-sidebar.actions';

export const initialState = {
  opened: false,
  type: undefined,
  step: undefined,
  tintId: undefined,
};

export const socialFeedsSidebar = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_SOCIAL_FEEDS_SIDEBAR:
      return {
        ...state,
        opened: true,
        ...action.payload,
      };

    case ACTIONS.UPDATE_SOCIAL_FEEDS_SIDEBAR:
      return {
        ...state,
        ...action.payload,
      };

    case ACTIONS.CLOSE_SOCIAL_FEEDS_SIDEBAR:
      return {
        ...state,
        opened: false,
        type: undefined,
        step: undefined,
        tintId: undefined,
      };
    default:
      return state;
  }
};

export const selectCurrentActiveSocialFeedType = state => state.type;
export const selectCurrentActiveSocialFeedStep = state => state.step;
