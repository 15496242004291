import { createPrefixedActionName, createRoutine } from '../../services/functions/redux-routines/redux-routines.js';

export const ACTIONS = {
  GET_TEAM_PAYMENTS: createRoutine(createPrefixedActionName('PROFILE_DROPDOWN', 'GET_TEAM_PAYMENTS')),
};

export const getTeamPaymentsStart = () => ({
  type: ACTIONS.GET_TEAM_PAYMENTS.REQUEST,
});

export const getTeamPaymentsSuccess = data => ({
  type: ACTIONS.GET_TEAM_PAYMENTS.SUCCESS,
  payload: data,
});

export const getTeamPaymentsError = err => ({
  type: ACTIONS.GET_TEAM_PAYMENTS.FAILURE,
  payload: err,
});
