import React, { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';
import './dropdown-sort-list-radio-list.sass';
import useDropdownListPosition from '../../../../hooks/use-dropdown-list-position/use-dropdown-list-position';
import PropTypes from 'prop-types';
import CustomizableRadioButton from '../../../inputs/customizable-radio-button/customizable-radio-button';

export const ORDER_SORT_KEY = 'ORDER_SORT_KEY';

export const getSortKey = team => {
  return `${ORDER_SORT_KEY}_TEAM_${team.id}`;
};

const DropdownSortListRadioList = ({ onChange, currentItems, currentItem, parentRef }) => {
  const listRef = useRef();
  const session = useSelector(state => state.session);
  const { positionHorizontal, positionVertical } = useDropdownListPosition(listRef, parentRef);

  const _onChange = item => {
    onChange(item);
    if (item.value === 'score') {
      return;
    }
    localStorage.setItem(getSortKey(session?.data?.currentTeam), item.value);
  };

  return (
    <div className='dropdown-multi-select-list-radio'>
      <ul
        className={`multi-select-radio-list multi-select-radio-list--${positionHorizontal} multi-select-radio-list--${positionVertical}`}
        ref={listRef}
        data-testid='dropdown-multi-select-list'>
        <li className='multi-select-radio-list__item multi-select-radio-list__item--label'>
          <span>Order posts by</span>
        </li>
        {currentItems.map((item, i) => (
          <li className={`multi-select-radio-list__item ${item.isActive ? 'active' : ''}`} key={i}>
            <CustomizableRadioButton
              name={'formName'}
              label={item.name}
              id={item.value}
              value={item.name}
              isSelected={item.value === currentItem.value}
              displayTypeClass={[]}
              onSelect={() => _onChange(item)}
              key={item.value}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};
export default DropdownSortListRadioList;

DropdownSortListRadioList.propTypes = {
  onChange: PropTypes.func,
  parentRef: PropTypes.any,
  onChangeTextComponent: PropTypes.func,
  currentItems: PropTypes.array,
};
