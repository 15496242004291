import React, { Component } from 'react';
import './post-section.sass';
import { PageSectionHoc } from '../../../../../../components/HOC/page-section/page-section.hoc';
import { EditModalStep } from '../../../../../../components/edit-modal/edit-modal-step/edit-modal-step';
import { EditModal } from '../../../../../../components/edit-modal/edit-modal/edit-modal';
import { EditModalSwitcher } from '../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher';
import { PostEditContent } from './edit/post-edit-content';
import { PostEditStyles } from './edit/post-edit-styles';
import { connect } from 'react-redux';
import PostEditPopup from './edit/post-edit-popup';
import ColorParser from '../../../../../../services/color-parser/color-parser';
import { handleApiError } from '../../../../../../services/functions/error-handler/error-handler';
import { toast } from 'react-toastify';
import { ApiService } from '../../../../../../services/api-service/api-service';
import { Button } from '../../../../../../components/interface/button/button';
import TintModal from '../../../../../../components/modal/modal';
import ModalHeader from '../../../../../../components/modal/components/modal-header/modal-header';
import ModalFooter from '../../../../../../components/modal/components/footers/modal-footer/modal-footer';
import PostModalBody from './modal/post-modal-body';
import FormBuilder from '../../../../../../components/form-builder/form-builder';

const POST_MODAL_FORM_ID = 'post-modal-form';

class PostSection extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isPostModalOpened: false,
      isEmailRequired: false,
      isCustomFieldAvailable: false,
      isFetching: true,
    };
  }

  componentDidMount() {
    if (!this.props.data.content.tintSlug) {
      return;
    }

    this.getTintSlugDetails();
  }

  getTintSlugDetails = () => {
    if (!this.props.isPreviewMode) {
      ApiService.get('/tints?include=social_feeds&page[size]=500')
        .then(this.onTintFetchSuccess, this.onTintFetchError)
        .finally(() => {
          this.setState({ isFetching: false });
        });
    } else {
      ApiService.pureInstance()
        .get(`/v2/tints/${this.props.data.content.tintSlug}?include=social_feeds&page[size]=500`)
        .then(this.onTintFetchSuccess, this.onTintFetchError)
        .finally(() => {
          this.setState({ isFetching: false });
        });
    }
  };

  onTintFetchSuccess = res => {
    this.tints = res.data;
    this.determineRequiredFields(this.tints);
  };

  onTintFetchError = err => {
    handleApiError(err, toast.error);
  };

  componentDidUpdate(prevProps) {
    const tintSlug = this.props.data.content.tintSlug;
    if (prevProps.data.content.tintSlug !== tintSlug && tintSlug !== '') {
      this.determineRequiredFields(this.tints);
    }
    if (this.props.data.content.tintSlug && !prevProps.data.content.tintSlug) {
      this.getTintSlugDetails();
    }
  }

  determineRequiredFields = tints => {
    if (tints && !!this.props.data.content.tintSlug) {
      const publicPostFeed = this.getCurrentPublicPostFeed(tints);
      this.setState({
        isCustomFieldAvailable: publicPostFeed.attributes.options
          ? publicPostFeed.attributes.options.custom_field
          : false,
        isEmailRequired: publicPostFeed.attributes.options ? publicPostFeed.attributes.options.email : false,
      });
    }
  };

  openPostModal = () => {
    this.setState({ isPostModalOpened: true });
  };

  closePostModal = () => {
    this.setState({ isPostModalOpened: false });
  };

  renderContent() {
    // TODO substitute it by button
    // ? Should we disable it after submitting
    return (
      <div
        className='tint-page-section--post__submit-button'
        style={{
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.buttonBackgroundColor),
        }}
        onClick={this.openPostModal}>
        <span
          style={{
            color: ColorParser.defaultColor(this.props.data.styles.buttonTextColor),
          }}>
          {this.props.getTranslation(this.props.data.content.buttonText)}
        </span>
      </div>
    );
  }

  getCurrentPublicPostFeed = data => {
    const isArray = Array.isArray(data.data);
    const tintSocialFeeds = isArray && data.data.find(e => e.attributes.slug === this.props.data.content.tintSlug);
    const tintSocialFeedsData = tintSocialFeeds
      ? tintSocialFeeds.relationships.social_feeds.data
      : data.data.relationships.social_feeds.data;

    return data.included
      .filter(e => e.type === 'social_feed' && e.attributes.source === 'public_post')
      .find(e => tintSocialFeedsData.some(r => e.id === r.id));
  };

  render() {
    return (
      <div
        className={`tint-page-section tint-page-section--post ${this.props.getSectionClass()}`}
        style={{
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`,
        }}>
        {this.props.renderGenericComponents()}
        {this.props.isModalOpen && (
          <EditModal
            nextStep={this.props.nextStep}
            stepIndex={this.props.stepIndex}
            isVisible={this.props.isModalOpen}
            saveData={this.props.onDataSave}
            dismissModal={this.props.dismissModal}
            closeModal={this.props.closeModal}>
            <EditModalStep closeModal={this.closePostModal} title='Edit Post'>
              <EditModalSwitcher>
                <PostEditContent
                  getTranslation={this.props.getTranslation}
                  onTranslationUpdate={this.props.onTranslationUpdate}
                  data={this.props.data.content}
                  saveData={this.props.onDataSave}
                  dataUpdate={this.props.onContentUpdate}
                  nextStep={this.props.nextStep}
                  openPostModal={this.openPostModal}
                  isCustomFieldAvailable={this.state.isCustomFieldAvailable}
                />
                <PostEditStyles
                  data={this.props.data}
                  saveData={this.props.onDataSave}
                  dataUpdate={this.props.onContentUpdate}
                  styleDataUpdate={this.props.onStylesUpdate}
                />
              </EditModalSwitcher>
            </EditModalStep>
            <EditModalStep
              title='Customize Popup'
              closeModal={() => {
                this.closePostModal();
                this.props.previousStep();
              }}
              footer={
                <div className='edit-modal-button'>
                  <Button
                    type='secondary'
                    width='100%'
                    onClick={() => {
                      this.closePostModal();
                      this.props.previousStep();
                    }}
                    text='Done'
                    size='secondary'
                  />
                </div>
              }>
              <PostEditPopup
                getTranslation={this.props.getTranslation}
                onTranslationUpdate={this.props.onTranslationUpdate}
                isCustomFieldAvailable={this.state.isCustomFieldAvailable}
                data={this.props.data.content}
                saveData={this.props.onDataSave}
                dataUpdate={this.props.onContentUpdate}
              />
            </EditModalStep>
          </EditModal>
        )}

        {this.renderContent()}

        <TintModal
          zIndex={1049}
          className={`${
            !this.props.data.content.formBuilderId
              ? 'post-section-modal'
              : 'form-builder-modal  post-section-modal--no-padding'
          }`}
          headerTitle={this.props.getTranslation(this.props.data.content.popupTitle)}
          modalHeader={props => <ModalHeader {...props} title={''} />}
          modalFooter={props => (!this.props.data.content.formBuilderId ? <ModalFooter {...props} /> : <></>)}
          isOpen={this.state.isPostModalOpened}
          isBackDropVisible={this.props.isPreviewMode}
          onClose={this.closePostModal}>
          {this.props.data.content.formBuilderId ? (
            <FormBuilder
              id={this.props.data.content.formBuilderId}
              language={this.props.language}
              teamId={this.props.teamId}
            />
          ) : (
            <PostModalBody
              translations={this.props.translations}
              experienceId={this.props.experienceId}
              formId={POST_MODAL_FORM_ID}
              tintSlug={this.props.data.content.tintSlug}
              onSuccessPost={this.closePostModal}
              isFetching={this.state.isFetching}
              isEmailRequired={this.state.isEmailRequired}
              isCustomFieldAvailable={this.state.isCustomFieldAvailable}
              customFieldPlaceholder={this.props.getTranslation(this.props.data.content.customFieldPlaceholder)}
              buttonText={this.props.getTranslation(this.props.data.content.popupButtonText)}
              maxVideoFileSize={this.props.getMaxVideoSizePlanLimit()}
              defaultValue
            />
          )}
        </TintModal>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  experienceId: state.experience.data && state.experience.data.id,
});

export default connect(mapStateToProps, null)(PageSectionHoc(PostSection));
