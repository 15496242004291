import { ACTIONS } from '../actions/audience-builder.action';
import produce from 'immer';

export const initialState = {
  audiences: [],
  members: [],
  selectedMembers: [],
  selectedAudiences: [],
  isFetching: false,
  isAudienceFetching: false,
  isError: false,
  person: undefined,
  isMemberFilterOpen: false,
  currentMembersFilter: {},
};

export const audienceBuilder = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_MEMBERS.REQUEST:
    case ACTIONS.BULK_DELETE_MEMBERS.REQUEST:
    case ACTIONS.DELETE_MEMBER.REQUEST:
    case ACTIONS.DELETE_AUDIENCE.REQUEST:
    case ACTIONS.BULK_DELETE_AUDIENCES.REQUEST:
    case ACTIONS.ADD_AUDIENCE.REQUEST:
    case ACTIONS.GET_PERSON.REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
      };

    case ACTIONS.GET_AUDIENCES.REQUEST:
    case ACTIONS.UPDATE_AUDIENCE.REQUEST:
      return {
        ...state,
        isAudienceFetching: true,
        isError: false,
      };

    case ACTIONS.GET_AUDIENCES.SUCCESS:
      return {
        ...state,
        isAudienceFetching: false,
        audiences: action.payload.data,
      };

    case ACTIONS.GET_MEMBERS.SUCCESS:
      return {
        ...state,
        isFetching: false,
        members: action.payload.data,
      };

    case ACTIONS.SELECT_MEMBER:
      return produce(state, draft => {
        draft.selectedMembers = [...draft.selectedMembers, action.payload];
      });

    case ACTIONS.UNSELECT_MEMBER:
      return produce(state, draft => {
        draft.selectedMembers = draft.selectedMembers.filter(({ id }) => {
          return action.payload !== id;
        });
      });

    case ACTIONS.DESELECT_ALL_MEMBERS:
      return produce(state, draft => {
        draft.selectedMembers = [];
      });

    case ACTIONS.SELECT_AUDIENCE:
      return produce(state, draft => {
        draft.selectedAudiences = [...draft.selectedAudiences, action.payload];
      });

    case ACTIONS.UNSELECT_AUDIENCE:
      return produce(state, draft => {
        draft.selectedAudiences = draft.selectedAudiences.filter(({ id }) => {
          return action.payload !== id;
        });
      });

    case ACTIONS.DESELECT_ALL_AUDIENCES:
      return produce(state, draft => {
        draft.selectedAudiences = [];
      });

    case ACTIONS.BULK_DELETE_MEMBERS.SUCCESS:
      return produce(state, draftState => {
        draftState.members = draftState.members.filter(member => {
          return !action.payload.some(p => p.id === member.id);
        });
        draftState.isFetching = false;
        draftState.isError = false;
        draftState.selectedMembers = [];
      });

    case ACTIONS.DELETE_MEMBER.SUCCESS:
      return produce(state, draft => {
        draft.members = draft.members.filter(member => member.id !== action.payload);
        draft.isFetching = false;
      });

    case ACTIONS.BULK_DELETE_AUDIENCES.SUCCESS:
      return produce(state, draftState => {
        draftState.audiences = draftState.audiences.filter(a => {
          return !action.payload.some(p => p.id === a.id);
        });
        draftState.isFetching = false;
        draftState.isError = false;
        draftState.selectedAudiences = [];
      });

    case ACTIONS.DELETE_AUDIENCE.SUCCESS:
      return produce(state, draft => {
        draft.audiences = draft.audiences.filter(a => a.id !== action.payload);
        draft.isFetching = false;
      });

    case ACTIONS.ADD_AUDIENCE.SUCCESS:
      return produce(state, draft => {
        draft.audiences = [...state.audiences, action.payload.data];
      });

    case ACTIONS.UPDATE_AUDIENCE.SUCCESS:
      return produce(state, draft => {
        draft.audiences = state.audiences.map(a => {
          if (a.id === action.payload.id) {
            return action.payload;
          }

          return a;
        });
      });

    case ACTIONS.GET_PERSON.SUCCESS:
      return {
        ...state,
        isFetching: false,
        person: action.payload.data,
      };

    case ACTIONS.ADD_PERSON.SUCCESS:
      return produce(state, draft => {
        draft.members = [...draft.members, action.payload];
      });

    case ACTIONS.OPEN_MEMBERS_FILTER:
      return {
        ...state,
        isMemberFilterOpen: true,
      };

    case ACTIONS.CLOSE_MEMBERS_FILTER:
      return {
        ...state,
        isMemberFilterOpen: false,
      };

    case ACTIONS.UPDATE_MEMBERS_FILTER:
      return produce(state, draft => {
        draft.currentMembersFilter = {
          ...draft.currentMembersFilter,
          ...action.payload,
        };
      });

    case ACTIONS.CLEAR_MEMBERS_FILTER:
      return produce(state, draft => {
        draft.currentMembersFilter = {};
      });

    case ACTIONS.GET_AUDIENCES.FAILURE:
    case ACTIONS.GET_MEMBERS.FAILURE:
    case ACTIONS.BULK_DELETE_MEMBERS.FAILURE:
    case ACTIONS.DELETE_MEMBER.FAILURE:
    case ACTIONS.DELETE_AUDIENCE.FAILURE:
    case ACTIONS.BULK_DELETE_AUDIENCES.FAILURE:
    case ACTIONS.ADD_AUDIENCE.FAILURE:
    case ACTIONS.UPDATE_AUDIENCE.FAILURE:
    case ACTIONS.GET_PERSON.FAILURE:
    case ACTIONS.GET_EXPORT_MEMBERS.FAILURE:
    case ACTIONS.GET_EXPORT_MEMBERS_STATUS.FAILURE:
    case ACTIONS.GET_IMPORT_MEMBERS.FAILURE:
    case ACTIONS.GET_IMPORT_MEMBERS_STATUS.FAILURE:
      return {
        ...state,
        isError: action.payload,
        isFetching: false,
      };

    default:
      return state;
  }
};
