export const getExperienceStatus = function(data) {
  if (data.attributes.status === EXPERIENCE_STATUS.ARCHIVED) {
    return EXPERIENCE_STATUS.ARCHIVED;
  }

  return data.links && data.links.public ? EXPERIENCE_STATUS.ACTIVE : EXPERIENCE_STATUS.DRAFT;
};

export const isDataPublished = (draftExperience, currentExperience) => {
  return (
    JSON.stringify(currentExperience.attributes.data.locales.draft) ===
      JSON.stringify(draftExperience.attributes.data.locales.published) &&
    JSON.stringify(currentExperience.attributes.data.draft) ===
      JSON.stringify(currentExperience.attributes.data.published) &&
    JSON.stringify(currentExperience.attributes.data.translations.draft) ===
      JSON.stringify(currentExperience.attributes.data.translations.published)
  );
};

export const EXPERIENCE_STATUS = {
  ACTIVE: 'active',
  ARCHIVED: 'archived',
  DRAFT: 'draft',
};

export const EXPERIENCE_PUBLISHED = {
  YES: true,
  NO: false,
};
