import { ACTIONS } from '../../actions/languages/languages.actions';

export const initialState = {
  currentLanguage: {
    value: undefined,
    direction: undefined,
  },
};

export const languages = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_CURRENT_LANGUAGE:
      return {
        ...state,
        currentLanguage: {
          value: action.payload.value,
          direction: action.payload.direction,
        },
      };

    default:
      return state;
  }
};

export default languages;
