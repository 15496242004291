import React, { useRef } from 'react';
import './wysiwyg.sass';
import { commandIdList } from './components/wysiwyg-navigation/wysiwyg-navigation-options';
import useSelectContentEditable from './hooks/useSelectContentEditable';
import * as PropTypes from 'prop-types';
import { Config } from '../../../../../utils/config';
import Wysiwyg from './wysiwyg';
import {
  addBackgroundToChild,
  copyParentStylesToChildren,
  setFontSizeOnFireFoxOnly,
  setChildrenFontSize,
} from './wysiwyg-container-DOM-utils';

const WysiwygContainer = ({ contentValue, style, onBlur, onChange, onNavigationRender, textDirection }) => {
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
  const contentEditableRef = useRef();
  const [saveSelection, getPrevSelection] = useSelectContentEditable();

  const onNavigationOptionChoose = (commandId, value, showUI) => {
    switch (commandId) {
      case commandIdList.FONT_SIZE:
        setFontSizeCssValue(value);
        break;

      case commandIdList.FONT_FAMILY:
        setFontNameCssValue(value);
        break;

      case commandIdList.LINK:
        setLink(value);
        break;

      case commandIdList.BACKGROUND_COLOR:
        setBackgroundColor(value, commandIdList.BACKGROUND_COLOR);
        break;

      case commandIdList.COLOR:
        setColor(value, commandIdList.COLOR);
        break;

      case commandIdList.DEFAULT:
      default:
        addExecCommand(commandId, showUI, value);
        break;
    }
  };

  const addExecCommand = (commandId, showUI, value) => {
    document.execCommand('styleWithCSS', !isFirefox, null);
    document.execCommand(commandId, showUI, value);
    addBackgroundColorToChildrenElements();
    setAHtmlTagProperties();
    saveSelection();
  };

  const setFontNameCssValue = value => {
    addExecCommand(
      commandIdList.FONT_FAMILY,
      false,
      !isFirefox ? value.replace(Config.regExp.lettersOnlyPattern, '') : `'${value}'`
    );
    replaceUnsupportedSigns(value);
  };

  const replaceUnsupportedSigns = value => {
    const fontElements = contentEditableRef.current.querySelectorAll('span');
    for (let i = 0; i < fontElements.length; ++i) {
      if (fontElements[i].style.fontFamily === value.replace(Config.regExp.lettersOnlyPattern, '')) {
        fontElements[i].style.fontFamily = `'${value}'`;
      }
    }
  };

  const setFontSizeCssValue = value => {
    addExecCommand(commandIdList.FONT_SIZE, false, `${value}px`);

    if (!isFirefox) {
      const spanElements = contentEditableRef.current.querySelectorAll('span');
      setChildrenFontSize(spanElements, value);
      const aElements = contentEditableRef.current.querySelectorAll('a');
      setChildrenFontSize(aElements, value);
    } else {
      setFontSizeOnFireFoxOnly(value, contentEditableRef);
    }
  };

  const markTextAsLink = urlLink => {
    addExecCommand(commandIdList.LINK, false, urlLink);
    addExecCommand(commandIdList.UNDERLINE, false);
  };

  const markLinkAsText = () => {
    addExecCommand(commandIdList.UN_LINK, false, null);
    addExecCommand(commandIdList.UNDERLINE, false);
  };

  const setColor = (color, commandId) => {
    getPrevSelection();
    addExecCommand(commandId, false, color);
  };

  const setBackgroundColor = (color, commandId) => {
    setColor(color, commandId);
  };

  const addBackgroundColorToChildrenElements = () => {
    const spanElements = contentEditableRef.current.querySelectorAll('span');
    const fontElements = contentEditableRef.current.querySelectorAll('font');
    addBackgroundToChild(spanElements);
    addBackgroundToChild(fontElements);
  };

  const setLink = urlLink => {
    getPrevSelection();
    urlLink ? markTextAsLink(urlLink) : markLinkAsText();
  };

  const setAHtmlTagProperties = () => {
    const fontElements = contentEditableRef.current.querySelectorAll('a');
    for (let j = 0; j < fontElements.length; j++) {
      if (fontElements[j].href) {
        fontElements[j].setAttribute('target', '_blank');
        fontElements[j].setAttribute('rel', 'noopener noreferrer');
      }
    }
    copyParentStylesToChildren(fontElements);
  };

  return (
    <div className='tint-wysiwyg-container' data-testid='tint-wysiwyg-container'>
      <Wysiwyg
        textDirection={textDirection}
        style={style}
        onBlur={onBlur}
        contentEditableRef={contentEditableRef}
        saveSelection={saveSelection}
        getPrevSelection={getPrevSelection}
        contentValue={contentValue}
        onContentChange={onChange}
        onNavigationRender={onNavigationRender}
        onNavigationOptionChoose={onNavigationOptionChoose}
      />
    </div>
  );
};

export default WysiwygContainer;

WysiwygContainer.propTypes = {
  contentValue: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  addFontFamily: PropTypes.func,
  styles: PropTypes.any,
  onNavigationRender: PropTypes.func,
};
