import React from 'react';
import './edit-modal-body.sass';
import PropTypes from 'prop-types';

export const EditModalBody = ({ title, titleClass, children }) => {
  return (
    <div className='tint-edit-modal-body'>
      {title ? (
        <span className={`tint-edit-modal-body__title tint-edit-modal-body__title--${titleClass}`}>{title}</span>
      ) : null}
      {children}
    </div>
  );
};

EditModalBody.propTypes = {
  title: PropTypes.string,
  titleClass: PropTypes.string,
};
