import React, { useState } from 'react';
import './sidebar-stepper.sass';
import TintSidebar from '../sidebar/sidebar';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

export const TintSidebarStepperStateType = {
  FIRST: 'main',
  NEXT: 'edit',
  FINALLY: 'create',
};

const TintSidebarStepper = ({ modalDetails, onChangeStep, onClose, stepsLength = 2 }) => {
  const debounceDelayMs = 100;
  const [currentSidebarDetails, setCurrentSidebarDetails] = useState();
  const [sidebarDataList, setSidebarDataList] = useState([]);
  const [isModalVisible, setModalVisibility] = useState(modalDetails.isVisible);

  const onChangeData = debounce(data => {
    setCurrentSidebarDetails(data);

    setSidebarDataList(s => ({
      ...s,
      [modalDetails.step]: {
        ...data,
      },
    }));
  }, debounceDelayMs);

  const renderCurrentComponent = () => {
    return (
      modalDetails.component &&
      modalDetails.component({
        onClose: onSidebarClose,
        onChangeStep,
        onChangeData: onChangeData,
        data: sidebarDataList[modalDetails.step],
        switcherData: sidebarDataList,
        step: modalDetails.step,
      })
    );
  };

  const onFooterLeftButtonClick = () => {
    if (modalDetails.step === 1) {
      onClose();
    }
    onChangeStep({
      step: modalDetails.step - 1,
      data: {
        ...sidebarDataList[modalDetails.step - 1],
      },
    });

    setCurrentSidebarDetails({
      step: modalDetails.step - 1,
      data: {
        ...sidebarDataList[modalDetails.step - 1],
      },
      isValid: sidebarDataList[modalDetails.step - 1]?.isValid,
    });
  };

  const onRightButtonClick = () => {
    onChangeStep({
      step: modalDetails.step + 1,
      data: {
        ...currentSidebarDetails,
        isValid: undefined,
      },
    });
    setCurrentSidebarDetails(undefined);

    if (stepsLength === modalDetails.step) {
      onClose?.();
    }
  };

  const onSidebarClose = () => {
    setModalVisibility(false);
    onClose && onClose();
  };

  return (
    isModalVisible && (
      <TintSidebar
        sidebarClassName={'tint-sidebar-stepper'}
        title={modalDetails.headerTitle}
        isOpen={isModalVisible}
        onClose={onSidebarClose}
        defaultFooterStyle={false}
        onLeftButtonClick={onFooterLeftButtonClick}
        leftButtonText={modalDetails.step !== 1 ? 'Previous' : 'Cancel'}
        rightButtonText={modalDetails.step !== stepsLength ? 'Next' : 'Done'}
        isRightButtonDisabled={
          !sidebarDataList[modalDetails.step] ||
          (sidebarDataList[modalDetails.step] && !sidebarDataList[modalDetails.step]?.isValid)
        }
        onRightButtonClick={onRightButtonClick}>
        <div className='tint-sidebar-switcher__content'>{renderCurrentComponent()}</div>
      </TintSidebar>
    )
  );
};

TintSidebarStepper.propTypes = {
  modalDetails: PropTypes.any,
  onChangeStep: PropTypes.func,
  onClose: PropTypes.func,
  stepsLength: PropTypes.number,
};
export default TintSidebarStepper;
