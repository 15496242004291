import React from 'react';
import { Button } from '../../../../../../../../components/interface/button/button';
import './vote-post-item.sass';
import PropTypes from 'prop-types';
import useImageValidation from '../../../../../../../../components/hooks/use-image-validation/use-image-validation';
import { Config } from '../../../../../../../../utils/config';
import { pollImageType } from '../../../../../../../../model/poll-image-type.model';

const VotePostItem = ({
  styles,
  imageUrl,
  url,
  username,
  type,
  isClicked,
  onVoteClick,
  onUnVoteClick,
  buttonText,
  buttonVotedText,
  isPreviewMode,
  isVoteAllowed,
  imageType,
}) => {
  const { newImageUrl, isImageValid } = useImageValidation(Config.getFileStackCdnUrl(`b64://${window.btoa(imageUrl)}`));
  return (
    <>
      <div
        className={
          !isPreviewMode || isVoteAllowed || isClicked
            ? 'vote-post-item'
            : 'vote-post-item vote-post-item--vote-blocked'
        }>
        <div className='vote-post-item__overlay' style={{ background: styles.overlayBackgroundColor }} />
        <div
          className={`vote-post-item__thumb ${!isImageValid ? 'vote-post-item__thumb--fail' : ''} ${
            imageType !== undefined && imageType === pollImageType.FIT ? 'vote-post-item__thumb--fit' : ''
          }`}
          style={{ backgroundImage: `url("${newImageUrl}")` }}
        />

        <div className='vote-post-item__details'>
          <div className='social-media'>
            <a rel='noopener noreferrer' href={url} target='_blank' style={{ color: styles.overlayTextColor }}>
              <span className={`icon fab fa-${type}`} style={{ color: styles.overlayTextColor }} />
              {username ? <span style={{ color: styles.overlayTextColor }}>@{username}</span> : null}
            </a>
          </div>

          {isPreviewMode ? (
            <>
              {!isClicked ? (
                <div>
                  <Button
                    onClick={() => {
                      onVoteClick();
                    }}
                    type='white'
                    text={buttonText}
                    size='long'
                    styles={{
                      background: styles.buttonBackgroundColor,
                      borderColor: styles.buttonBackgroundColor,
                      color: styles.buttonTextColor,
                    }}
                  />
                </div>
              ) : (
                <Button
                  type='transparent'
                  text={buttonVotedText || 'Voted'}
                  size='long'
                  icon='fas fa-check'
                  onClick={() => {
                    onUnVoteClick();
                  }}
                  styles={{
                    background: styles.buttonBackgroundColor,
                    borderColor: styles.buttonBackgroundColor,
                    color: styles.buttonTextColor,
                  }}
                />
              )}
            </>
          ) : null}
        </div>
      </div>
    </>
  );
};

VotePostItem.propTypes = {
  styles: PropTypes.any,
  imageUrl: PropTypes.string,
  url: PropTypes.string,
  username: PropTypes.string,
  type: PropTypes.string,
  isClicked: PropTypes.bool,
  onVoteClick: PropTypes.func,
  onUnVoteClick: PropTypes.func,
  buttonText: PropTypes.string,
  buttonVotedText: PropTypes.string,
  imageType: PropTypes.string,
};

export default VotePostItem;
