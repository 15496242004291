import { ACTIONS } from '../actions/app-route-actions';

/*
  Core route are all routes from old codebase
 */
export default (state = { path: '', pageTitle: '', pageHeader: '' }, action) => {
  switch (action.type) {
    case ACTIONS.CHANGE_MAIN_APP_ROUTE:
      return { ...state, ...{ path: action.payload.route, id: action.payload.id } };
    case ACTIONS.SET_PAGE_TITLE:
      return { ...state, ...{ pageTitle: action.payload } };
    case ACTIONS.SET_PAGE_HEADER:
      return { ...state, ...{ pageHeader: action.payload } };
    default:
      return state;
  }
};
