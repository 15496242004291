import { ACTIONS } from '../../actions/gif-builder/asset-manager-gif-builder.actions';
import { Config } from '../../../../utils/config';

export const initialState = {
  assets: [],
  options: {
    delay: 1000,
    loop: 0,
    width: 470,
    height: 470,
    fit: 'clip',
    align: 'center',
    background: 'transparent',
  },
  zoomLevel: 100,
};

export const assetManagerGifBuilder = (state = initialState, action) => {
  let draftAssets;
  switch (action.type) {
    case ACTIONS.SELECT_ASSET:
      return {
        ...state,
        assets: state.assets.map(e => {
          e.isSelected = e.id === action.payload;
          return e;
        }),
      };

    case ACTIONS.ADD_ASSETS:
      action.payload[0].isSelected = true;
      return {
        ...state,
        assets: action.payload,
      };

    case ACTIONS.REMOVE_ASSETS:
      draftAssets = state.assets.filter(e => e.id !== action.payload);
      if (!draftAssets.every(e => e.isSelected) && draftAssets.length > 0) {
        draftAssets[0].isSelected = true;
      }

      return {
        ...state,
        assets: draftAssets,
      };

    case ACTIONS.SET_OPTION:
      return {
        ...state,
        options: {
          ...state.options,
          [action.payload.key]: action.payload.value,
        },
      };

    default:
      return state;
  }
};

export const getSelectedGifAsset = state => {
  return state.assets.find(e => e.isSelected);
};

const _getTransformedFSUrl = (state, id) => {
  // if its preset id we need to chain it with resize
  const _id = id.includes('preset=name') ? `https://process.filestackapi.com/${id}` : id;
  return `"https://process.filestackapi.com/${Config.fileStackApiKey.general()}/resize=w:${state.options.width},h:${
    state.options.height
  },fit:crop/${_id}",`;
};

export const getFilestackGifUrl = state => {
  let url = 'animate=';
  for (let [key, value] of Object.entries(state.options)) {
    if (value && key !== 'background') {
      url += `${key}:${value},`;
    }
  }
  url = url.slice(0, -1);
  url += '/[';
  state.assets.map(e => {
    let id = e.attributes.url.replace('https://cdn.filestackcontent.com/', '');
    url += _getTransformedFSUrl(state, id);
  });
  url = url.slice(0, -1);
  url += ']';
  return url;
};
