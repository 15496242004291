export const ProductFeeds = {
  FACEBOOK: 'facebook',
  MAGENTO: 'magento',
  FTP: 'ftp',
  SFTP: 'sftp',
  SHOPIFY: 'shopify',
  WOOCOMMERCE: 'woocommerce',
  BIGCOMMERCE: 'bigcommerce',
  CSV: 'csv',
  CUSTOM: 'custom',
  SALESFORCE_COMMERCE_CLOUD: 'salesforce_commerce_cloud',
  MANUAL: 'manual',
  BAZAARVOICE: 'bazaarvoice',
};
