import {
  faAlignLeft,
  faSquareCheck,
  faCircleDot,
  faEnvelope,
  faIdCardClip,
  faInputPipe,
  faLeaf,
  faParagraph,
  faPhone,
  faCircleCheck,
  faStarSharpHalfStroke,
  faUpload,
  faHorizontalRule,
  faLocationDot,
  faICursor,
  faHeading,
  faSquareH,
  faGlobe,
  faThumbsUp,
  faFaceSmile,
  faSlider,
  faPeopleGroup,
  faCheck,
  faCalendar,
  faCakeCandles,
  faImage,
  faShoppingCart,
  faEyeSlash,
  faGridRound,
} from '@fortawesome/pro-solid-svg-icons';
import { GuardNames } from '../../../guards/guards-list';
import { faGrid } from '@fortawesome/sharp-solid-svg-icons';
import { faYoutube } from '@fortawesome/free-brands-svg-icons';

export const FORM_BUILDER_INPUT_CLASSES = {
  SINGLE_LINE_TEXT: 'input input-single',
  MULTI_LINE_TEXT: 'input input-textarea',
  WEBSITE: 'input input-website',
  STAR_RATING: 'input input-rating',
  SINGLE_CHOICE: 'input input-single-choice',
  MULTIPLE_CHOICE: 'input input-multiple-choice',
  DROPDOWN: 'input input-dropdown',
  HEADER: 'div div-header',
  HEADING: 'div div-heading',
  PARAGRAPH: 'div div-paragraph',
  FULL_NAME: 'input input-name',
  EMAIL: 'input input-email',
  PHONE: 'input input-phone',
  ADDRESS: 'input input-address',
  SPACER: 'div div-spacer',
  LINE: 'div div-line',
  FILE_UPLOAD: 'input input-file',
  IMAGE: 'div div-image',
  YOUTUBE: 'div div-youtube',
  COMMUNITY_CONSENT: 'input input-community-consent',
  ACCEPTANCE: 'input input-acceptance',
  CONSENT: 'input input-consent',
  PRODUCT_ASSOCIATION: 'input input-product-association',
  HIDDEN: 'input input-hidden',
  SMILEY_SCALE: 'input input-smile-scale',
  THUMB_SCALE: 'input input-thumb-scale',
  NUMBER_SCALE: 'input input-number-scale',
  DATE: 'input input-date',
  BIRTHDAY: 'input input-birthday',
  SINGLE_MATRIX: 'input input-single-matrix',
  MULTIPLE_MATRIX: 'input input-multiple-matrix',
};

export const FORM_BUILDER_TITLE_NODE_NAMES = {
  SELF: '',
  LABEL: 'label',
  LEGEND: 'legend',
  H1: 'h1',
  H2: 'h2',
  IMG: 'img',
  TEXT: '#text',
  INPUT: 'input',
};

export const FORM_BUILDER_TITLE_PROPERTIES = {
  ALT: 'alt',
  SRC: 'src',
  CONTENT: 'content',
  NAME: 'name',
  VALUE: 'value',
  NODE_VALUE: 'nodeValue',
};

export const FORM_BUILDER_ELEMENTS = {
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_LINE_TEXT]: {
    class: FORM_BUILDER_INPUT_CLASSES.SINGLE_LINE_TEXT,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LABEL,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.MULTI_LINE_TEXT]: {
    class: FORM_BUILDER_INPUT_CLASSES.MULTI_LINE_TEXT,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LABEL,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.WEBSITE]: {
    class: FORM_BUILDER_INPUT_CLASSES.WEBSITE,
    guardName: GuardNames.FORMS_ADVANCED,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LABEL,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.STAR_RATING]: {
    class: FORM_BUILDER_INPUT_CLASSES.STAR_RATING,
    guardName: GuardNames.REVIEWS,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LEGEND,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_CHOICE]: {
    class: FORM_BUILDER_INPUT_CLASSES.SINGLE_CHOICE,
    guardName: GuardNames.FORMS_ADVANCED,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LEGEND,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.MULTIPLE_CHOICE]: {
    class: FORM_BUILDER_INPUT_CLASSES.MULTIPLE_CHOICE,
    guardName: GuardNames.FORMS_ADVANCED,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LEGEND,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.DROPDOWN]: {
    class: FORM_BUILDER_INPUT_CLASSES.DROPDOWN,
    guardName: GuardNames.FORMS_ADVANCED,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LEGEND,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.HEADER]: {
    class: FORM_BUILDER_INPUT_CLASSES.HEADER,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.H1,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.HEADING]: {
    class: FORM_BUILDER_INPUT_CLASSES.HEADING,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.H2,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.PARAGRAPH]: {
    class: FORM_BUILDER_INPUT_CLASSES.PARAGRAPH,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.SELF,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.FULL_NAME]: {
    class: FORM_BUILDER_INPUT_CLASSES.FULL_NAME,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LEGEND,
      property: FORM_BUILDER_TITLE_PROPERTIES.CONTENT,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.EMAIL]: {
    class: FORM_BUILDER_INPUT_CLASSES.EMAIL,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LABEL,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.PHONE]: {
    class: FORM_BUILDER_INPUT_CLASSES.PHONE,
    guardName: GuardNames.FORMS_ADVANCED,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LABEL,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.ADDRESS]: {
    class: FORM_BUILDER_INPUT_CLASSES.ADDRESS,
    guardName: GuardNames.FORMS_ADVANCED,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LEGEND,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.SPACER]: {
    class: FORM_BUILDER_INPUT_CLASSES.SPACER,
  },
  [FORM_BUILDER_INPUT_CLASSES.LINE]: {
    class: FORM_BUILDER_INPUT_CLASSES.LINE,
  },
  [FORM_BUILDER_INPUT_CLASSES.FILE_UPLOAD]: {
    class: FORM_BUILDER_INPUT_CLASSES.FILE_UPLOAD,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LABEL,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.IMAGE]: {
    class: FORM_BUILDER_INPUT_CLASSES.IMAGE,
    guardName: GuardNames.FORMS_ADVANCED,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.IMG,
      property: FORM_BUILDER_TITLE_PROPERTIES.ALT,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.YOUTUBE]: {
    class: FORM_BUILDER_INPUT_CLASSES.YOUTUBE,
    guardName: GuardNames.FORMS_ADVANCED,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.SELF,
      property: FORM_BUILDER_TITLE_PROPERTIES.SRC,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.COMMUNITY_CONSENT]: {
    class: FORM_BUILDER_INPUT_CLASSES.COMMUNITY_CONSENT,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LABEL,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.ACCEPTANCE]: {
    class: FORM_BUILDER_INPUT_CLASSES.ACCEPTANCE,
    guardName: GuardNames.FORMS_ADVANCED,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.TEXT,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.CONSENT]: {
    class: FORM_BUILDER_INPUT_CLASSES.CONSENT,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.TEXT,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.PRODUCT_ASSOCIATION]: {
    class: FORM_BUILDER_INPUT_CLASSES.PRODUCT_ASSOCIATION,
    guardName: GuardNames.ECOMMERCE,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.SELF,
      property: FORM_BUILDER_TITLE_PROPERTIES.NAME,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.HIDDEN]: {
    class: FORM_BUILDER_INPUT_CLASSES.HIDDEN,
    guardName: GuardNames.FORMS_ADVANCED,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.SELF,
      property: FORM_BUILDER_TITLE_PROPERTIES.NAME,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.SMILEY_SCALE]: {
    class: FORM_BUILDER_INPUT_CLASSES.SMILEY_SCALE,
    guardName: GuardNames.FORMS_INSIGHTS,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LEGEND,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.THUMB_SCALE]: {
    class: FORM_BUILDER_INPUT_CLASSES.THUMB_SCALE,
    guardName: GuardNames.FORMS_INSIGHTS,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LEGEND,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.NUMBER_SCALE]: {
    class: FORM_BUILDER_INPUT_CLASSES.NUMBER_SCALE,
    guardName: GuardNames.FORMS_INSIGHTS,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LEGEND,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.DATE]: {
    class: FORM_BUILDER_INPUT_CLASSES.DATE,
    guardName: GuardNames.FORMS_ADVANCED,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LABEL,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.BIRTHDAY]: {
    class: FORM_BUILDER_INPUT_CLASSES.BIRTHDAY,
    guardName: GuardNames.FORMS_ADVANCED,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LABEL,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_MATRIX]: {
    class: FORM_BUILDER_INPUT_CLASSES.SINGLE_MATRIX,
    guardName: GuardNames.FORMS_INSIGHTS,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LEGEND,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
  [FORM_BUILDER_INPUT_CLASSES.MULTIPLE_MATRIX]: {
    class: FORM_BUILDER_INPUT_CLASSES.MULTIPLE_MATRIX,
    guardName: GuardNames.FORMS_INSIGHTS,
    titleProps: {
      element: FORM_BUILDER_TITLE_NODE_NAMES.LEGEND,
      property: FORM_BUILDER_TITLE_PROPERTIES.NODE_VALUE,
    },
  },
};

export const FORM_BUILDER_LAYOUT_ELEMENT_TYPE = {
  SUCCESS_MESSAGE: 'div div-success-message',
  SUBMIT_BUTTON: 'div div-submit-button',
};

export const itemClassToType = _class => {
  if (!_class) {
    return;
  }

  return Object.values(FORM_BUILDER_INPUT_CLASSES).find(inputType => {
    const splitInputType = inputType.split(' ');
    const splitClass = _class.split(' ');

    if (splitClass[1] === splitInputType[1]) {
      return `${splitInputType[0]}-${splitInputType[1]}`;
    }
    return null;
  });
};

export const FORM_BUILDER_TYPES_TO_ICONS = {
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_LINE_TEXT]: faInputPipe,
  [FORM_BUILDER_INPUT_CLASSES.MULTI_LINE_TEXT]: faAlignLeft,
  [FORM_BUILDER_INPUT_CLASSES.STAR_RATING]: faStarSharpHalfStroke,
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_CHOICE]: faCircleDot,
  [FORM_BUILDER_INPUT_CLASSES.MULTIPLE_CHOICE]: faSquareCheck,
  [FORM_BUILDER_INPUT_CLASSES.DROPDOWN]: faCircleCheck,
  [FORM_BUILDER_INPUT_CLASSES.HEADER]: faSquareH,
  [FORM_BUILDER_INPUT_CLASSES.HEADING]: faHeading,
  [FORM_BUILDER_INPUT_CLASSES.PARAGRAPH]: faParagraph,
  [FORM_BUILDER_INPUT_CLASSES.FULL_NAME]: faIdCardClip,
  [FORM_BUILDER_INPUT_CLASSES.EMAIL]: faEnvelope,
  [FORM_BUILDER_INPUT_CLASSES.PHONE]: faPhone,
  [FORM_BUILDER_INPUT_CLASSES.ADDRESS]: faLocationDot,
  [FORM_BUILDER_INPUT_CLASSES.SPACER]: faICursor,
  [FORM_BUILDER_INPUT_CLASSES.LINE]: faHorizontalRule,
  [FORM_BUILDER_INPUT_CLASSES.WEBSITE]: faGlobe,
  [FORM_BUILDER_INPUT_CLASSES.FILE_UPLOAD]: faUpload,
  [FORM_BUILDER_INPUT_CLASSES.IMAGE]: faImage,
  [FORM_BUILDER_INPUT_CLASSES.CONSENT]: faLeaf,
  [FORM_BUILDER_INPUT_CLASSES.SMILEY_SCALE]: faFaceSmile,
  [FORM_BUILDER_INPUT_CLASSES.THUMB_SCALE]: faThumbsUp,
  [FORM_BUILDER_INPUT_CLASSES.NUMBER_SCALE]: faSlider,
  [FORM_BUILDER_INPUT_CLASSES.COMMUNITY_CONSENT]: faPeopleGroup,
  [FORM_BUILDER_INPUT_CLASSES.ACCEPTANCE]: faCheck,
  [FORM_BUILDER_INPUT_CLASSES.DATE]: faCalendar,
  [FORM_BUILDER_INPUT_CLASSES.BIRTHDAY]: faCakeCandles,
  [FORM_BUILDER_INPUT_CLASSES.PRODUCT_ASSOCIATION]: faShoppingCart,
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_MATRIX]: faGridRound,
  [FORM_BUILDER_INPUT_CLASSES.MULTIPLE_MATRIX]: faGrid,
  [FORM_BUILDER_INPUT_CLASSES.HIDDEN]: faEyeSlash,
  [FORM_BUILDER_INPUT_CLASSES.YOUTUBE]: faYoutube,
};

export const FORM_BUILDER_TYPES_TO_SLUGS = {
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_LINE_TEXT]: 'single_line',
  [FORM_BUILDER_INPUT_CLASSES.MULTI_LINE_TEXT]: 'multi_line',
  [FORM_BUILDER_INPUT_CLASSES.STAR_RATING]: 'rating',
  [FORM_BUILDER_INPUT_CLASSES.HEADER]: 'header',
  [FORM_BUILDER_INPUT_CLASSES.HEADING]: 'heading',
  [FORM_BUILDER_INPUT_CLASSES.FULL_NAME]: 'first_name',
  [FORM_BUILDER_INPUT_CLASSES.EMAIL]: 'email',
  [FORM_BUILDER_INPUT_CLASSES.FILE_UPLOAD]: 'media',
  [FORM_BUILDER_INPUT_CLASSES.CONSENT]: 'consent',
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_CHOICE]: 'single_choice',
  [FORM_BUILDER_INPUT_CLASSES.MULTIPLE_CHOICE]: 'multiple_choice',
  [FORM_BUILDER_INPUT_CLASSES.WEBSITE]: 'website',
  [FORM_BUILDER_INPUT_CLASSES.IMAGE]: 'image',
  [FORM_BUILDER_INPUT_CLASSES.YOUTUBE]: 'youtube',
  [FORM_BUILDER_INPUT_CLASSES.SMILEY_SCALE]: 'smiley_scale',
  [FORM_BUILDER_INPUT_CLASSES.PHONE]: 'phone',
  [FORM_BUILDER_INPUT_CLASSES.ADDRESS]: 'address',
  [FORM_BUILDER_INPUT_CLASSES.COMMUNITY_CONSENT]: 'community_consent',
  [FORM_BUILDER_INPUT_CLASSES.DROPDOWN]: 'dropdown',
  [FORM_BUILDER_INPUT_CLASSES.THUMB_SCALE]: 'thumb_scale',
  [FORM_BUILDER_INPUT_CLASSES.SPACER]: 'spacer',
  [FORM_BUILDER_INPUT_CLASSES.NUMBER_SCALE]: 'number_scale',
  [FORM_BUILDER_INPUT_CLASSES.DATE]: 'date',
  [FORM_BUILDER_INPUT_CLASSES.ACCEPTANCE]: 'acceptance',
  [FORM_BUILDER_INPUT_CLASSES.BIRTHDAY]: 'birthday',
  [FORM_BUILDER_INPUT_CLASSES.PRODUCT_ASSOCIATION]: 'product_association',
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_MATRIX]: 'single_matrix',
  [FORM_BUILDER_INPUT_CLASSES.MULTIPLE_MATRIX]: 'multiple_matrix',
  [FORM_BUILDER_INPUT_CLASSES.HIDDEN]: 'hidden',
};

export const FORM_BUILDER_TYPES_TO_LABELS = {
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_LINE_TEXT]: 'Single-Line Text',
  [FORM_BUILDER_INPUT_CLASSES.MULTI_LINE_TEXT]: 'Multi-Line Text',
  [FORM_BUILDER_INPUT_CLASSES.STAR_RATING]: 'Star Rating',
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_CHOICE]: 'Single Choice',
  [FORM_BUILDER_INPUT_CLASSES.MULTIPLE_CHOICE]: 'Multiple Choice',
  [FORM_BUILDER_INPUT_CLASSES.DROPDOWN]: 'Dropdown',
  [FORM_BUILDER_INPUT_CLASSES.HEADER]: 'Header',
  [FORM_BUILDER_INPUT_CLASSES.HEADING]: 'Heading',
  [FORM_BUILDER_INPUT_CLASSES.PARAGRAPH]: 'Paragraph',
  [FORM_BUILDER_INPUT_CLASSES.FULL_NAME]: 'Full Name',
  [FORM_BUILDER_INPUT_CLASSES.EMAIL]: 'Email',
  [FORM_BUILDER_INPUT_CLASSES.PHONE]: 'Phone',
  [FORM_BUILDER_INPUT_CLASSES.ADDRESS]: 'Address',
  [FORM_BUILDER_INPUT_CLASSES.SPACER]: 'Spacer',
  [FORM_BUILDER_INPUT_CLASSES.WEBSITE]: 'Website',
  [FORM_BUILDER_INPUT_CLASSES.LINE]: 'Line',
  [FORM_BUILDER_INPUT_CLASSES.FILE_UPLOAD]: 'File Upload',
  [FORM_BUILDER_INPUT_CLASSES.IMAGE]: 'Image',
  [FORM_BUILDER_INPUT_CLASSES.YOUTUBE]: 'YouTube',
  [FORM_BUILDER_INPUT_CLASSES.CONSENT]: 'Rights Management Consent',
  [FORM_BUILDER_INPUT_CLASSES.SMILEY_SCALE]: 'Smiley Scale',
  [FORM_BUILDER_INPUT_CLASSES.THUMB_SCALE]: 'Thumb Scale',
  [FORM_BUILDER_INPUT_CLASSES.NUMBER_SCALE]: 'Number Scale',
  [FORM_BUILDER_INPUT_CLASSES.COMMUNITY_CONSENT]: 'Community Sign Up',
  [FORM_BUILDER_INPUT_CLASSES.ACCEPTANCE]: 'Acceptance',
  [FORM_BUILDER_INPUT_CLASSES.DATE]: 'Date',
  [FORM_BUILDER_INPUT_CLASSES.BIRTHDAY]: 'Birthday',
  [FORM_BUILDER_INPUT_CLASSES.PRODUCT_ASSOCIATION]: 'Product Association',
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_MATRIX]: 'Single Matrix',
  [FORM_BUILDER_INPUT_CLASSES.MULTIPLE_MATRIX]: 'Multiple Matrix',
  [FORM_BUILDER_INPUT_CLASSES.HIDDEN]: 'Hidden',
};

export const FORM_BUILDER_STYLE_ELEMENT_TYPE = {
  INPUT_CLASS: '.input',
  INPUT: 'input',
  INPUT_FOCUS: 'input:focus',
  SELECT: 'select',
  LABEL: 'label',
  FORM: '.div-form',
  HEADER_TITLE: '.div-header__header-title',
  HEADER_CAPTION: '.div-header__header-description',
  HEADING: '.div-heading',
  HEADING_TITLE: '.div-heading h2',
  HEADING_CAPTION: '.div-heading p',
  SPACER: '.div-spacer',
  SUBMIT_BUTTON: '.div-submit-button',
  SUCCESS_MESSAGE_TITLE: '.div-success-message__title',
  SUCCESS_MESSAGE_CAPTION: '.div-success-message__caption',
  SUCCESS_MESSAGE_BUTTON: '.div-success-message__button',
  STAR_RATING_FIELDSET: '.input-rating fieldset',
  STAR_RATING_LABEL: '.input-rating label',
  STAR_RATING_INPUT: '.input-rating input[type="radio"]',
  SINGLE_CHOICE_FIELDSET: '.input-single-choice fieldset',
  SINGLE_CHOICE_LEGEND: '.input-single-choice legend',
  SINGLE_CHOICE_LABEL: '.input-single-choice label',
  SINGLE_CHOICE_LABEL_AFTER: '.input-single-choice label:has(+ :required):after',
  SINGLE_CHOICE_INPUT: '.input-single-choice input',
  SINGLE_CHOICE_INPUT_CHECKED: '.input-single-choice input:checked',
  MULTIPLE_CHOICE_FIELDSET: '.input-multiple-choice fieldset',
  MULTIPLE_CHOICE_LEGEND: '.input-multiple-choice legend',
  MULTIPLE_CHOICE_LABEL: '.input-multiple-choice label',
  MULTIPLE_CHOICE_LABEL_AFTER: '.input-multiple-choice label:has(+ :required):after',
  MULTIPLE_CHOICE_INPUT: '.input-multiple-choice input',
  MULTIPLE_CHOICE_INPUT_CHECKED: '.input-multiple-choice input:checked',
  IMAGE: '.div-image',
  IMAGE_IMG: '.div-image img',
  NO_IMAGE: '.div-image--no-preview',
  SMILEY_SCALE_FIELDSET: '.input-smile-scale fieldset',
  SMILEY_SCALE_INPUT: '.input-smile-scale input',
  SMILEY_SCALE_LABEL: '.input-smile-scale label',
  CONSENT: '.input-consent',
  CONSENT_INPUT: '.input-consent input[type="checkbox"]',
  CONSENT_LABEL: '.input-consent label',
  CONSENT_A: '.input-consent a',
  COMMUNITY_CONSENT: '.input-community-consent',
  COMMUNITY_CONSENT_LABEL: '.input-community-consent label',
  COMMUNITY_CONSENT_INPUT: '.input-community-consent input[type="checkbox"]',
  THUMB_SCALE_FIELDSET: '.input-thumb-scale fieldset',
  THUMB_SCALE_INPUT: '.input-thumb-scale input[type="checkbox"]',
  THUMB_SCALE_LABEL: '.input-thumb-scale label',
  FILE_UPLOAD_FILE: '.input-file output li',
  FILE_UPLOAD_FILE_NAME: '.input-file output li div:first-of-type',
  FILE_UPLOAD_ERROR: '.input-file output li div:last-of-type',
  FILE_UPLOAD_BUTTON: '.input-file output button',
  FILE_UPLOAD_PROGRESS: '.input-file output progress',
  ACCEPTANCE: '.input-acceptance',
  ACCEPTANCE_LABEL: '.input-acceptance label',
  ACCEPTANCE_INPUT: '.input-acceptance input',
  DATE_INPUT: '.input-date input',
  BIRTHDAY_INPUT: '.input-birthday input',
  NUMBER_SCALE: '.input-number-scale',
  NUMBER_SCALE_FIELDSET: '.input-number-scale fieldset',
  NUMBER_SCALE_LEGEND: '.input-number-scale legend',
  NUMBER_SCALE_INPUT: '.input-number-scale input[type="checkbox"]',
  NUMBER_SCALE_LABEL: '.input-number-scale label',
  NUMBER_SCALE_DIV: '.input-number-scale div',
  PARAGRAPH: '.div-paragraph',
  SINGLE_MATRIX: '.input-single-matrix',
  SINGLE_MATRIX_LEGEND: '.input-single-matrix legend',
  SINGLE_MATRIX_TABLE: '.input-single-matrix table',
  SINGLE_MATRIX_TBODY_TD: '.input-single-matrix tbody td',
  SINGLE_MATRIX_TBODY_TH: '.input-single-matrix tbody th',
  SINGLE_MATRIX_THEAD_TH: '.input-single-matrix thead th',
  SINGLE_MATRIX_INPUT: '.input-single-matrix input',
  MULTIPLE_MATRIX: '.input-multiple-matrix',
  MULTIPLE_MATRIX_LEGEND: '.input-multiple-matrix legend',
  MULTIPLE_MATRIX_TABLE: '.input-multiple-matrix table',
  MULTIPLE_MATRIX_TBODY_TD: '.input-multiple-matrix tbody td',
  MULTIPLE_MATRIX_TBODY_TH: '.input-multiple-matrix tbody th',
  MULTIPLE_MATRIX_THEAD_TH: '.input-multiple-matrix thead th',
  MULTIPLE_MATRIX_INPUT: '.input-multiple-matrix input',
  ADDRESS: '.input-address',
  ADDRESS_LEGEND: '.input-address legend',
  ADDRESS_DIV: '.input-address div',
  ADDRESS_SELECT: '.input-address select',
  ADDRESS_LABEL: '.input-address label',
  HINT: '.div-hint',
};

// used to know which classes are correlated to which input type
export const FORM_BUILDER_INPUT_TYPE_TO_CLASSES = {
  [FORM_BUILDER_INPUT_CLASSES.DROPDOWN]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SELECT,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS,
  ],
  [FORM_BUILDER_INPUT_CLASSES.HEADING]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.HEADING_TITLE,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.HEADING_CAPTION,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.HEADING,
  ],
  [FORM_BUILDER_INPUT_CLASSES.SPACER]: [FORM_BUILDER_STYLE_ELEMENT_TYPE.SPACER],
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_CHOICE]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_CHOICE_FIELDSET,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_CHOICE_LEGEND,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_CHOICE_LABEL,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_CHOICE_INPUT,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_CHOICE_INPUT_CHECKED,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS,
  ],
  [FORM_BUILDER_INPUT_CLASSES.MULTIPLE_CHOICE]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_CHOICE_FIELDSET,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_CHOICE_LEGEND,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_CHOICE_LABEL,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_CHOICE_INPUT,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_CHOICE_INPUT_CHECKED,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS,
  ],
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_LINE_TEXT]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS,
  ],
  [FORM_BUILDER_INPUT_CLASSES.MULTI_LINE_TEXT]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS,
  ],
  [FORM_BUILDER_INPUT_CLASSES.EMAIL]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS,
  ],
  [FORM_BUILDER_INPUT_CLASSES.FULL_NAME]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS,
  ],
  [FORM_BUILDER_INPUT_CLASSES.FILE_UPLOAD]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.FILE_UPLOAD_FILE,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.FILE_UPLOAD_FILE_NAME,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.FILE_UPLOAD_BUTTON,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.FILE_UPLOAD_PROGRESS,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.FILE_UPLOAD_ERROR,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS,
  ],
  [FORM_BUILDER_INPUT_CLASSES.CONSENT]: [FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS],
  [FORM_BUILDER_INPUT_CLASSES.STAR_RATING]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.STAR_RATING_FIELDSET,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.STAR_RATING_INPUT,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.STAR_RATING_LABEL,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS,
  ],
  [FORM_BUILDER_INPUT_CLASSES.IMAGE]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.IMAGE,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.IMAGE_IMG,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.NO_IMAGE,
  ],
  [FORM_BUILDER_INPUT_CLASSES.SMILEY_SCALE]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SMILEY_SCALE_FIELDSET,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SMILEY_SCALE_INPUT,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SMILEY_SCALE_LABEL,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS,
  ],
  [FORM_BUILDER_INPUT_CLASSES.THUMB_SCALE]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.THUMB_SCALE_FIELDSET,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.THUMB_SCALE_INPUT,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.THUMB_SCALE_LABEL,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS,
  ],
  [FORM_BUILDER_INPUT_CLASSES.COMMUNITY_CONSENT]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.COMMUNITY_CONSENT,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.COMMUNITY_CONSENT_INPUT,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.COMMUNITY_CONSENT_LABEL,
  ],
  [FORM_BUILDER_INPUT_CLASSES.ACCEPTANCE]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.ACCEPTANCE,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.ACCEPTANCE_INPUT,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.ACCEPTANCE_LABEL,
  ],
  [FORM_BUILDER_INPUT_CLASSES.DATE]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.DATE_INPUT,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS,
  ],
  [FORM_BUILDER_INPUT_CLASSES.BIRTHDAY]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.BIRTHDAY_INPUT,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS,
  ],
  [FORM_BUILDER_INPUT_CLASSES.NUMBER_SCALE]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_FIELDSET,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_LEGEND,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_INPUT,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_LABEL,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.NUMBER_SCALE_DIV,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS,
  ],
  [FORM_BUILDER_INPUT_CLASSES.PARAGRAPH]: [FORM_BUILDER_STYLE_ELEMENT_TYPE.PARAGRAPH],
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_MATRIX]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_LEGEND,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_TABLE,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_TBODY_TD,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_TBODY_TH,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_THEAD_TH,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.SINGLE_MATRIX_INPUT,
  ],
  [FORM_BUILDER_INPUT_CLASSES.MULTIPLE_MATRIX]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_LEGEND,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_TABLE,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_TBODY_TD,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_TBODY_TH,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_THEAD_TH,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.MULTIPLE_MATRIX_INPUT,
  ],
  [FORM_BUILDER_INPUT_CLASSES.ADDRESS]: [
    FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS_LEGEND,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS_LABEL,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS_DIV,
    FORM_BUILDER_STYLE_ELEMENT_TYPE.ADDRESS_SELECT,
  ],
};

export const FORM_BUILDER_ELEMENTS_WITH_HINTS = [
  [FORM_BUILDER_INPUT_CLASSES.SINGLE_LINE_TEXT],
  [FORM_BUILDER_INPUT_CLASSES.MULTI_LINE_TEXT],
  [FORM_BUILDER_INPUT_CLASSES.WEBSITE],
  [FORM_BUILDER_INPUT_CLASSES.DROPDOWN],
  [FORM_BUILDER_INPUT_CLASSES.EMAIL],
  [FORM_BUILDER_INPUT_CLASSES.PHONE],
  [FORM_BUILDER_INPUT_CLASSES.FILE_UPLOAD],
];

export const FORM_BUILDER_STYLE_TYPE = {
  DISPLAY: 'display',
  BACKGROUND: 'background-color',
  COLOR: 'color',
  BORDER: 'border',
  BORDER_COLOR: 'border-color',
  BORDER_RADIUS: 'border-radius',
  BORDER_TOP_WIDTH: 'border-top-width',
  BORDER_RIGHT_WIDTH: 'border-right-width',
  BORDER_BOTTOM_WIDTH: 'border-bottom-width',
  BORDER_LEFT_WIDTH: 'border-left-width',
  BORDER_STYLE: 'border-style',
  BORDER_WIDTH: 'border-width',
  FONT_SIZE: 'font-size',
  FONT_STYLE: 'font-style',
  FONT_WEIGHT: 'font-weight',
  LINE_HEIGHT: 'line-height',
  BOX_SHADOW: 'box-shadow',
  JUSTIFY_SELF: 'justify-self',
  WIDTH: 'width',
  PADDING_TOP: 'padding-top',
  PADDING_RIGHT: 'padding-right',
  PADDING_BOTTOM: 'padding-bottom',
  PADDING_LEFT: 'padding-left',
  MARGIN_TOP: 'margin-top',
  MARGIN_RIGHT: 'margin-right',
  MARGIN_BOTTOM: 'margin-bottom',
  MARGIN_LEFT: 'margin-left',
  TEXT_ALIGN: 'text-align',
  ASPECT_RATIO: 'aspect-ratio',
  OUTLINE_COLOR: 'outline-color',
  OBJECT_FIT: 'object-fit',
};
