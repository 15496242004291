import * as Yup from 'yup';
import { Config } from '../../../../../../../../utils/config';
import { parse, isDate } from 'date-fns';

const parseDateString = (value, originalValue) => {
  const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'MM/dd/yyyy', new Date());

  return parsedDate;
};

const checkIfValidHashtag = str => {
  return Config.regExp.hashtag.test(str);
};

export const validationSchemaInfluencerContent = () => {
  return Yup.object({
    email: Yup.string()
      .required('Email is required.')
      .matches(Config.regExp.email, 'Incorrect email address.'),
    message: Yup.string().required('Message is required.'),
    right_request_term_id: Yup.string().required('To continue, please select Terms & Conditions.'),
    start_at: Yup.date()
      .transform(parseDateString)
      .typeError('Invalid date format.'),
    end_at: Yup.date()
      .transform(parseDateString)
      .typeError('Invalid date format.'),
    hashtags: Yup.string().test({
      name: 'hashtags',
      test: function(text) {
        if (!text) {
          return true;
        }

        const hashtags = text.split(',').map(hashtags => checkIfValidHashtag(hashtags.trim()));

        if (hashtags.includes(false)) {
          return this.createError({
            message: 'Invalid hashtag.',
          });
        }

        return true;
      },
    }),
  });
};
