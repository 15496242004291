import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Config } from '../../../utils/config';
import { getCustomFontsList } from '../../../feature/experience/reducers/experience/experience';
import { pageSectionType } from '../../../model/page-section-type.model';

export const AddFontLinkHoc = PassedComponent => {
  class AddLinkScript extends Component {
    constructor(props) {
      super(props);

      this.state = {
        isLoading: false,
        loadedFontList: [],
        teamId: undefined,
      };
    }

    isGoogleFont(fontName) {
      return Config.fontFamilyList.includes(fontName);
    }

    addGoogleFont(fontFamily) {
      if (!fontFamily) return;
      const fontFamilyValue = typeof fontFamily === 'object' ? fontFamily.value : fontFamily;

      if (this.state.loadedFontList.includes(fontFamilyValue)) return;

      const url = Config.getGoogleFontSource(fontFamilyValue);

      this.setState(previousState => ({
        loadedFontList: [...previousState.loadedFontList, fontFamilyValue],
      }));
      this.embedScript(url);
      this.setState({ isLoading: true });
    }

    addCustomFont(teamId, fontFamilyIds) {
      if (this.state.loadedFontList.includes(fontFamilyIds)) return;
      if (!fontFamilyIds) return;
      const fontUrl = this.getFontUrl(teamId, fontFamilyIds);
      this.setState(previousState => ({
        loadedFontList: [...previousState.loadedFontList, fontFamilyIds],
      }));

      this.embedScript(fontUrl);
      this.setState({ isLoading: true });
    }

    getFontUrl(teamId, fontFamilyIds) {
      // For testing tintpages locally
      if (window.location.host.includes('localhost')) {
        return `https://api.tintup.com/v2/teams/${teamId}/fonts/${fontFamilyIds}.css`;
      } else {
        return `${Config.getApiUrl()}/v2/teams/${teamId}/fonts/${fontFamilyIds}.css`;
      }
    }

    embedScript(url) {
      const fontFamilyScript = document.createElement('link');
      fontFamilyScript.href = url;

      fontFamilyScript.rel = 'stylesheet';

      fontFamilyScript.onload = () => {
        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 700);
      };
      fontFamilyScript.onerror = () => {
        this.setState({ isLoading: false });
        // TODO uncomment this code when convert whole font files in assets to .eot, .ttf, .woff2
        // fontFamilyScript.href = `${this.state.assetsPath}/fonts/${
        //   fontFamily.includes(' ') ? fontFamily.replace(' ', '_') : fontFamily
        // }/stylesheet.css`;
      };
      document.head.appendChild(fontFamilyScript);
    }

    createElement = element => {
      let currentFontFamilies = [];
      const elementList = element.querySelectorAll('span');

      elementList.forEach(span => {
        if (span.style.fontFamily !== '') {
          currentFontFamilies.push(span.style.fontFamily);
        }
      });

      return currentFontFamilies;
    };

    getDefaultFontFamilyList() {
      let currentFontList = [];
      const property = this.props.experienceDataDraft.attributes.data.draft ? 'draft' : 'published';

      this.props.experienceDataDraft.attributes.data[property].forEach(item => {
        if (item.data.content.sections) {
          item.data.content.sections
            .filter(column => {
              return column.data && column.data.content.customizableHeaders;
            })
            .forEach(columnHeader => {
              return columnHeader.data.content.customizableHeaders.forEach(item => {
                currentFontList = [...currentFontList, item.fontFamily];
              });
            });
        } else if (item.data.content.customizableHeaders) {
          return item.data.content.customizableHeaders.forEach(item => {
            currentFontList = [...currentFontList, item.fontFamily];
          });
        } else if (item.sectionType === pageSectionType.SURVEY_COUNTER) {
          currentFontList = [...currentFontList, item.data.styles.fontFamily];
        }
      });

      return currentFontList;
    }

    matchFontFamily(string) {
      const matchFonts = string.match(Config.regExp.fontFamilyStyle);
      return (matchFonts && matchFonts.map(font => font.replace('font-family: ', ''))) || [];
    }

    getFontFamilies(data) {
      let currentFontFamilies = [];

      Object.keys(data).forEach(key => {
        if (key.includes('TEXTAREA') || key.includes('HEADER')) {
          const fontFamilyData = data[key].includes('&quot;')
            ? this.matchFontFamily(data[key].replace(/&quot;/g, ''))
            : this.matchFontFamily(data[key]);

          currentFontFamilies = [...currentFontFamilies, ...fontFamilyData];
        }

        return [...new Set(currentFontFamilies)];
      });

      const innerHtmlFontList = currentFontFamilies.map(item => ({
        name: item ? item.replace(/["']/g, '') : item,
        value: item ? item.replace(/["']/g, '') : item,
      }));

      return this.getUniqueFonts([...this.getDefaultFontFamilyList(), ...innerHtmlFontList]);
    }

    getUniqueFonts(fontList) {
      return fontList.filter((font, index, arr) => {
        return (
          arr
            .filter(() => font && font.name)
            .map(font => font.name)
            .indexOf(font.name) === index
        );
      });
    }

    getCSSIdFromUrl(url) {
      const removeSlash = url.replace(/\//g, ' ');
      const getLastWord = removeSlash.split(' ').splice(-1);
      return getLastWord[0].replace('.css', '');
    }

    getCustomFontId(fontName) {
      return (
        this.props.customFonts &&
        this.props.customFonts.find(customFont => customFont && customFont.name === fontName) &&
        this.props.customFonts.find(customFont => customFont.name === fontName).id
      );
    }

    addCustomFontScript = (fontList, teamId) => {
      const customFonts = fontList
        .filter(font => !this.isGoogleFont(font.name))
        .map(font => ({
          value: font.value,
          name: font.name,
          id: font.url ? this.getCSSIdFromUrl(font.url) : font.id ? font.id : this.getCustomFontId(font.name),
        }));

      let customFontsIds = customFonts
        .map(font => font.id)
        .filter(font => font !== undefined)
        .join();
      this.addCustomFont(this.getTeamId(teamId), customFontsIds);
    };

    getTeamId(teamId) {
      return teamId
        ? teamId
        : this.props.customDomain && this.props.customDomain.domain
        ? this.props.customDomain.domain.name
        : this.props.experienceDataDraft.relationships.user.data.id;
    }

    addGoogleFontScript = fontList =>
      fontList.forEach(font => {
        this.addGoogleFont(font);
      });

    addFontsScript = (translations, teamId) => {
      const fontList = this.getFontFamilies(translations);

      this.addCustomFontScript(fontList.filter(font => !this.isGoogleFont(font.name)), teamId);
      this.addGoogleFontScript(fontList.filter(font => this.isGoogleFont(font.name)));
    };

    loadFont = font => {
      if (this.isGoogleFont(font.name)) {
        this.addGoogleFont(font.name);
      } else {
        this.addCustomFont(this.getTeamId(), font.id);
      }
    };

    render() {
      return (
        <>
          <PassedComponent
            {...this.props}
            isFontLoading={this.state.isLoading}
            loadFonts={this.addFontsScript}
            loadFont={this.loadFont}
          />
        </>
      );
    }
  }

  const mapStateToProps = state => ({
    experienceDataDraft: state.experience.dataDraft,
    customFonts: getCustomFontsList(state.experience.data),
    customDomain: state.customDomain,
  });

  return connect(mapStateToProps)(AddLinkScript);
};
