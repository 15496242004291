export const TintEditorPaths = {
  title: 'TINT Editor',
  path: '/app/tints/:tintId',
  createUrl: ({ tintId }) => `/app/tints/${tintId}`,

  preview: {
    title: 'TINT Editor Preview',
    path: '/app/tints/:tintId/preview',
    createUrl: ({ tintSlug }) => `/app/tints/${tintSlug}/preview`,
  },
  global: {
    title: 'Global Moderation',
    tintId: 'global',
    path: '/app/tints/global',
  },
};
