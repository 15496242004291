import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import './customizable-radio-button.sass';

export const CustomizableRadioButtonDisplayType = {
  ROW: 'row',
  COLUMN: 'column',
  BORDER: 'border',
};

const CustomizableRadioButton = (
  {
    id,
    name,
    value,
    isSelected,
    label,
    onSelect,
    onRemoveSelect,
    disabled,
    children,
    childrenProps,
    displayTypeClass = [CustomizableRadioButtonDisplayType.ROW, CustomizableRadioButtonDisplayType.BORDER],
  },
  ref
) => {
  const onChange = () => {
    onSelect(value);
  };

  const renderChildrenContent = () => (
    <div
      className='customizable-radio-button__content'
      onClick={e => {
        e.stopPropagation();
      }}>
      {typeof children === 'object'
        ? React.Children.map(children, (child, i) => (
            <div key={i}>
              {React.cloneElement(child, {
                ...childrenProps,
              })}
            </div>
          ))
        : children && <span>{children}</span>}
    </div>
  );

  const getDisplayTypeClass = () => {
    return displayTypeClass
      .map(type => {
        return `customizable-radio-button--${type}`;
      })
      .join(' ');
  };

  return (
    <div className={`customizable-radio-button ${getDisplayTypeClass()} ${isSelected ? 'active' : ''}`}>
      <div className='customizable-radio-button__wrapper'>
        <label htmlFor={id}>
          <input
            ref={ref}
            id={id}
            name={name}
            value={value}
            onChange={onChange}
            type='radio'
            checked={isSelected}
            disabled={disabled}
          />
          <span className='customizable-radio-button__label'>{label}</span>
        </label>
      </div>
      {renderChildrenContent()}
      {onRemoveSelect && isSelected && (
        <span className={'customizable-radio-button__unselect'} onClick={onRemoveSelect}>
          x
        </span>
      )}
    </div>
  );
};

const CustomizableRadioButtonWithForwardedRef = forwardRef(CustomizableRadioButton);

CustomizableRadioButtonWithForwardedRef.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  isSelected: PropTypes.bool,
  label: PropTypes.string,
  onSelect: PropTypes.func,
  children: PropTypes.any,
  displayTypeClass: PropTypes.array,
};

export default CustomizableRadioButtonWithForwardedRef;
