import * as Yup from 'yup';

export const validationSchemaFacebookPage = () => {
  return Yup.object({
    page: Yup.object().required('Page is required.'),
    account: Yup.object().required('Account is required.'),
  });
};

export const validationSchemaFacebookTaggedPosts = () => {
  return Yup.object({
    page: Yup.object().required('Page is required.'),
    account: Yup.object().required('Account is required.'),
  });
};

export const validationSchemaFacebookRatings = () => {
  return Yup.object({
    page: Yup.object().required('Page is required.'),
    account: Yup.object().required('Account is required.'),
  });
};
