import React, { forwardRef, useImperativeHandle } from 'react';
import './checkout-form.sass';
import PropTypes from 'prop-types';
import { useStripe, useElements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import '../stripe-checkout-form.sass';
import { toast } from 'react-toastify';

const CheckoutForm = ({ handleCheckout, onChange }, ref) => {
  const stripe = useStripe();
  const elements = useElements();

  const options = {
    style: {
      base: {
        fontSize: '14px',
        color: '#1b242d',
        fontWeight: '400',
        borderColor: 'red',

        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#DFDFDF',
        },
      },
      invalid: {
        color: '#990000',
        borderColor: '#990000',
      },
    },
  };

  useImperativeHandle(ref, () => ({
    handleSubmit() {
      if (!stripe || !elements) {
        // Stripe.js has not loaded yet. Make sure to disable
        // form submission until Stripe.js has loaded.
        return;
      }

      const cardElement = elements.getElement(CardNumberElement);

      stripe.createToken(cardElement).then(function(result) {
        if (result.error) {
          toast.error(result.error.message || 'Something went wrong. Please try again.');
        } else {
          handleCheckout(result.token.id);
        }
      });
    },
  }));

  return (
    <>
      <p className='stripe-checkout-form__title'>Payment Information</p>
      <>
        <CardNumberElement
          id='cardNumber'
          options={options}
          onChange={e => onChange({ creditCardValid: e.complete })}
        />
        <div className='stripe-checkout-form__container'>
          <div className='stripe-checkout-form__card-details stripe-checkout-form__card-details--expiry'>
            <p className='stripe-checkout-form__title'>Expire date</p>
            <CardExpiryElement
              id='expiry'
              options={options}
              onChange={e => onChange({ expiryDateValid: e.complete })}
            />
          </div>
          <div className='stripe-checkout-form__card-details stripe-checkout-form__card-details--cvc'>
            <p className='stripe-checkout-form__title'>CVC</p>
            <CardCvcElement id='cvc' options={options} onChange={e => onChange({ cvcCodeValid: e.complete })} />
          </div>
        </div>
      </>
    </>
  );
};

const CheckoutFormWithForwardedRef = forwardRef(CheckoutForm);

CheckoutFormWithForwardedRef.propTypes = {
  handleCheckout: PropTypes.func,
  onChange: PropTypes.func,
};

export default CheckoutFormWithForwardedRef;
