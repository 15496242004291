import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { renderEditColorInput } from '../../page-section-utils';
import { EditUploadDesign } from '../../../';
import AccordionTab from '../../../../../../../components/interface/accordion/accordion-tab/accordion-tab';
import Accordion from '../../../../../../../components/interface/accordion/accordion';

const colorInputs = [
  { property: 'formFieldTextColor', label: 'Form field text color' },
  { property: 'inputBorderColor', label: 'Input border color' },
  { property: 'buttonBackgroundColor', label: 'Button color' },
  { property: 'buttonTextColor', label: 'Button text color' },
  { property: 'termsConditionsTextColor', label: 'Terms & Conditions color' },
  { property: 'contestRulesTextColor', label: 'Contest Rules color' },
  { property: 'privacyPolicyTextColor', label: 'Privacy Policy color' },
  { property: 'optTextColor', label: 'Opt color' },
  { property: 'requiredColor', label: 'Required label color' },
  { property: 'errorColor', label: 'Error color', menuEvents: true },
  {
    property: 'uploadButtonBorderColor',
    label: 'Upload button border color',
    isUploadEnabled: true,
  },
  {
    property: 'uploadButtonTextColor',
    label: 'Upload button text color',
    isUploadEnabled: true,
  },
  {
    property: 'uploadButtonBackgroundColor',
    label: 'Upload button background color',
    isUploadEnabled: true,
  },
];

export class EditFormStyles extends Component {
  renderBackgroundInputs() {
    return (
      <EditUploadDesign
        dataUpdate={this.props.dataUpdate}
        styleDataUpdate={this.props.styleDataUpdate}
        data={this.props.data}
      />
    );
  }

  renderColorInputs() {
    return colorInputs.map((e, i) => {
      if (e.isUploadEnabled && e.isUploadEnabled !== this.props.isUploadEnabled) {
        return null;
      }
      return renderEditColorInput(
        this.props.data.styles[e.property],
        v => this.props.styleDataUpdate({ [e.property]: v }),
        e.label,
        i,
        {},
        e.menuEvents ? this.props.forceErrorVisibility : '',
        e.menuEvents ? this.props.disableForceErrorVisibility : ''
      );
    });
  }

  render() {
    return (
      <EditModalBody title='Select a color preset for your form or customize to match your own color palette.'>
        <Accordion>
          <AccordionTab title='Form styles'>
            <div>{this.renderColorInputs()}</div>
          </AccordionTab>
          <AccordionTab title='Background Image'>
            <div>{this.renderBackgroundInputs()}</div>
          </AccordionTab>
        </Accordion>
      </EditModalBody>
    );
  }
}
