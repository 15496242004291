import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../../components/interface/button/button';

const FilePicker = ({ onClick, value, onChange, imageUrl }) => {
  const styles = {
    padding: ' 3em',
    margin: '0 0 10px',
  };

  const updatedStyles = imageUrl ? { ...styles, background: `center / cover no-repeat url(${imageUrl}` } : styles;

  return (
    <>
      <input type='text' onChange={onChange} name='file' value={value} style={{ display: 'none' }} />
      <Button
        onClick={onClick}
        type='gray'
        size='full-width'
        text='Add files'
        icon='fas fa-plus-circle'
        styles={updatedStyles}
      />
    </>
  );
};

FilePicker.propTypes = {
  onClick: PropTypes.func,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  imageUrl: PropTypes.string,
};

export default FilePicker;
