import { Config } from '../../utils/config';

const ColorParser = {
  defaultColor(color) {
    return this.getRgbaColorString(color);
  },
  getRgbaColorString(color) {
    return typeof color === 'object' ? `rgba(${color.r},${color.g},${color.b},${color.a})` : color;
  },
  rgbaStringToObject(rgbaColor) {
    if (typeof rgbaColor === 'string') {
      const rgbaArr = rgbaColor.match(/\d+/g)?.map(Number);
      const alpha = rgbaArr.length === 5 ? Number(rgbaArr.slice(-2).join('.')) : rgbaArr[3];
      return {
        r: rgbaArr[0],
        g: rgbaArr[1],
        b: rgbaArr[2],
        a: alpha,
      };
    }
    return rgbaColor;
  },
  RGBAToHex(colorObj) {
    if (typeof colorObj !== 'object') {
      return ColorParser.rgbaStringToHex(colorObj);
    }
    let { r, g, b } = colorObj;
    r = r.toString(16);
    g = g.toString(16);
    b = b.toString(16);

    if (r.length === 1) r = '0' + r;
    if (g.length === 1) g = '0' + g;
    if (b.length === 1) b = '0' + b;

    return '#' + r + g + b;
  },
  hexToRGBAString(hex, alpha) {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);

    if (alpha) {
      return `rgba(${r}, ${g},${b}, ${alpha})`;
    } else {
      return `rgba(${r}, ${g},${b}, ${0})`;
    }
  },
  rgbaStringToHex(color) {
    if (typeof color !== 'string' || color?.indexOf('#') !== -1) {
      return color;
    }

    let a,
      rgb = color.replace(/\s/g, '').match(/^rgba?\((\d+),(\d+),(\d+),?([^,\s)]+)?/i),
      alpha = ((rgb && rgb[4]) || '').trim(),
      hex = rgb
        ? (rgb[1] | (1 << 8)).toString(16).slice(1) +
          (rgb[2] | (1 << 8)).toString(16).slice(1) +
          (rgb[3] | (1 << 8)).toString(16).slice(1)
        : color;

    if (alpha !== '') {
      a = alpha;
    } else {
      a = 0o1;
    }
    // multiply before convert to HEX
    a = ((a * 255) | (1 << 8)).toString(16).slice(1);
    hex = hex + a;

    return `#${hex}`;
  },
  colorStringToObject(initialColor) {
    const isRgbColor =
      Config.regExp.rgbaColorPattern.test(initialColor) || Config.regExp.rgbColorPattern.test(initialColor);
    const parsedColor = isRgbColor
      ? ColorParser.rgbaStringToObject(initialColor)
      : ColorParser.rgbaStringToObject(ColorParser.hexToRGBAString(initialColor, 1));

    return { rgba: parsedColor };
  },
};

export default ColorParser;
