export const sidebarUpgradeSchema = {
  default: {
    header: 'You discovered a premium feature!',
    description: 'But don’t fear, you can upgrade your plan today to unlock this feature.',
    imageUrl: '/public/js/react/assets/tint-editor/update-default.svg',
  },
  rightsManagement: {
    header: 'Rights Management',
    description:
      'Easily request permission and obtain legal rights to content with a documented audit trail of the content creator’s acceptance.',
    imageUrl: '/public/js/react/assets/tint-editor/update-rights.svg',
  },
  tagProducts: {
    header: 'Tag Products',
    description:
      'Create shoppable feeds by tagging products from your catalog. Showcase the gallery on your online storefront and watch your conversions skyrocket.',
    imageUrl: '/public/js/react/assets/tint-editor/update-ecommerce.svg',
  },
  visualSearch: {
    header: 'Visual Search',
    description:
      'Finding that perfect image is important. It’s also difficult. Frustrating. Time consuming. Our Visual Search will help you find the perfect image everytime.',
    imageUrl: '/public/js/react/assets/tint-editor/update-visual-search.svg',
  },
  vesta: {
    header: 'Harness the power of your audience.',
    description:
      'Use TINT to mobilize your consumer community to create authentic UGC and reviews, boost sales & conversions, and foster loyalty.',
    imageUrl: '/public/js/react/assets/vesta/vesta-upgrade-image.png',
    label: 'CONTACT US FOR A DEMO',
    title: 'Create a Community',
  },
};
