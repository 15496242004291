import { useEffect, useState } from 'react';

const useInfiniteScroll = (callback, elementRef) => {
  const [isFetching, setIsFetching] = useState(false);
  const offsetMargin = 200;

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop - offsetMargin <=
      elementRef.current.offsetTop + elementRef.current.clientHeight
    ) {
      return;
    }
    setIsFetching(true);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    if (!isFetching) {
      return;
    }
    callback();
  }, [callback, isFetching]);
  return { setIsFetching };
};

export default useInfiniteScroll;
