import { createPrefixedActionName } from '../../../../services/functions/redux-routines/redux-routines';
import { PRODUCT_FEEDS } from '../../consts';

export const ACTIONS = {
  OPEN_REUPLOAD_SIDEBAR: createPrefixedActionName(PRODUCT_FEEDS, 'OPEN_REUPLOAD_SIDEBAR'),
  UPDATE_REUPLOAD_SIDEBAR: createPrefixedActionName(PRODUCT_FEEDS, 'UPDATE_REUPLOAD_SIDEBAR'),
  CLOSE_REUPLOAD_SIDEBAR: createPrefixedActionName(PRODUCT_FEEDS, 'CLOSE_REUPLOAD_SIDEBAR'),
};

export const openSidebar = data => ({
  type: ACTIONS.OPEN_REUPLOAD_SIDEBAR,
  payload: data,
});

export const updateSidebar = data => ({
  type: ACTIONS.UPDATE_REUPLOAD_SIDEBAR,
  payload: data,
});

export const closeSidebar = () => ({
  type: ACTIONS.CLOSE_REUPLOAD_SIDEBAR,
});
