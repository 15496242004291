import { Config } from '../../../../utils/config';

const filtersParam = filterValue =>
  filterValue !== undefined && filterValue !== '' ? `&filter[q]=${filterValue}` : '';

const statusParam = status => (status !== undefined && status !== '' ? `&filter[status]=${status}` : '');
const privateParam = isPrivate => (isPrivate !== undefined && isPrivate !== '' ? `&filter[private]=${isPrivate}` : '');

const includeParam = value => (value ? `&include=${value}` : '');

export const getTintsUrlWithParams = ({
  url = '/tints',
  include = undefined,
  sort = 'status,-id',
  pageSize = Config.defaultTintsPageSize,
  filterValue = undefined,
  status = undefined,
  isPrivate = undefined,
} = {}) => {
  return `${url}?${includeParam(include)}&sort=${sort}&page[size]=${pageSize}${filtersParam(filterValue)}${statusParam(
    status
  )}${privateParam(isPrivate)}`;
};

export const sortTintsByStatus = (a, b) => {
  const statusA = a.attributes.status?.toUpperCase() || 'INACTIVE';
  const statusB = b.attributes.status?.toUpperCase() || 'INACTIVE';

  if (statusA > statusB) {
    return 1;
  } else if (statusA < statusB) {
    return -1;
  }
  return 0;
};
