import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { Button } from '../../../../../../../components/interface/button/button';
import { FileStack } from '../../../../../../../services/filestack/file-stack';
import './edit-media-content.sass';
import { pageSectionType } from '../../../../../../../model/page-section-type.model';
import { EditInput } from '../../../index';

export class EditMediaContent extends Component {
  onUploadClick = () => {
    const mediaType = this.props.data.content.mediaType;

    new FileStack().openFilePicker({
      maxSize: this.props.maxVideoFileSize,
      accept: [this.isImageType(mediaType) ? 'image/*' : 'video/*'],
      onUploadDone: res => {
        if (res.filesUploaded.length > 0) {
          this.props.onContentUpdate({
            mediaUrl: res.filesUploaded[0].url,
            mediaName: res.filesUploaded[0].filename,
          });
        }
      },
    });
  };

  renderViewWithImage = () => {
    return (
      <div className='tint-edit-header-content__center'>
        <div
          className='tint-edit-upload-design__image'
          style={{
            backgroundImage: `url(${this.props.data.content.mediaUrl})`,
          }}
        />
      </div>
    );
  };

  renderVideoEdit = () => {
    return (
      <>
        <EditInput
          onChange={event => {
            this.props.onContentUpdate({
              mediaUrl: event.target.value,
              mediaName: '',
            });
          }}
          label={'or paste Video url'}
          placeholder='Video url'
          value={this.props.data.content.mediaUrl}
        />
        <span className='tint-edit-media__input-description'>Supported: Facebook, YouTube, Vimeo, Twitch, TikTok</span>
      </>
    );
  };

  determineMediaType = () => {
    const mediaUrl = this.props.data.content.mediaUrl;
    const mediaType = this.props.data.content.mediaType;
    return mediaUrl === undefined
      ? null
      : this.isImageType(mediaType)
      ? this.renderViewWithImage()
      : this.renderVideoEdit();
  };

  isImageType = mediaType => mediaType === pageSectionType.IMAGE || mediaType === pageSectionType.IMAGE.toUpperCase();

  render() {
    return (
      <EditModalBody title='Edit Media' className='edit-media'>
        <form
          noValidate
          onSubmit={e => {
            e.preventDefault();
          }}>
          <Button
            onClick={this.onUploadClick}
            type='gray'
            size='full-width'
            text='Upload media'
            icon='far fa-arrow-to-top'
            className='tint-edit-answer__upload-button'
          />
          {this.determineMediaType()}

          <button style={{ display: 'none' }} type='submit'>
            Submit
          </button>
        </form>
      </EditModalBody>
    );
  }
}
