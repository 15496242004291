import React from 'react';
import './edit-input-container.sass';
import PropTypes from 'prop-types';

const EditInputContainer = ({ title, children }) => {
  return (
    <div className='unique-tint-edit-input-container'>
      <p className='unique-tint-edit-input-container__title'>{title}</p>
      {children}
    </div>
  );
};

EditInputContainer.propTypes = {
  title: PropTypes.string,
};

export default EditInputContainer;
