import { BackendKeys } from '../consts';

export const selectDefaultLocale = experience =>
  experience?.data?.attributes?.data?.locales?.published?.find(lang => lang?.isDefault)?.value;
export const selectExperienceSection = (experience, sectionType) =>
  experience?.data?.attributes?.data?.published?.find(e => sectionType?.includes(e.sectionType));
export const getTranslation = (experience, key, lang) => {
  if (
    experience?.data?.attributes?.data?.translations?.published &&
    experience?.data?.attributes?.data?.translations?.published[lang][key] !== undefined
  ) {
    return experience?.data?.attributes?.data?.translations?.published[lang][key];
  }
  return key;
};

export const selectFormSubmissionKeys = socialFeed =>
  socialFeed?.included?.filter(i => i.type === BackendKeys.FORM_SUBMISSION_KEY) || [];

export const selectFormSubmissionMediaKeys = keys => keys?.filter(e => e?.attributes?.type === BackendKeys.FILE) || [];

export const selectFormSubmissionNonMediaKeys = keys =>
  keys?.filter(e => e?.attributes?.type !== BackendKeys.FILE) || [];

export const findSocialFeed = (state, source, type) =>
  state.socialFeeds?.tintEditor?.data?.find(e => e.attributes.source === source && e.attributes.type === type);

export const getSocialFeedLength = state => state.socialFeeds?.tintEditor?.data?.length;
