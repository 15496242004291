import React, { useState } from 'react';
import './pagination.sass';
import PropTypes from 'prop-types';
import { useHistory, useLocation } from 'react-router';

export const Pagination = ({
  isFetching,
  pageSize,
  customClass,
  nextUrl,
  previousUrl,
  request,
  onPageChange,
  fromPage,
  updateUrl = false,
}) => {
  const [fromCount, setFromCount] = useState(() => (fromPage ? (fromPage - 1) * pageSize + 1 : 1));
  const location = useLocation();
  const history = useHistory();

  const updateQueryParams = ({ pageNumber, pageSize }) => {
    if (!updateUrl) {
      return;
    }
    const globalParams = new URLSearchParams(window.location.search);
    const params = new URLSearchParams({ pageSize: pageSize || globalParams.get('pageSize'), pageNumber: pageNumber });
    history.replace({ pathname: location.pathname, search: params.toString() });
  };

  const onPrevious = () => {
    if (previousUrl && !isFetching) {
      setFromCount(fromCount - pageSize);
      onPageChange && onPageChange(fromCount - pageSize);
      request && request(previousUrl);
      updateQueryParams({ pageNumber: Number(fromPage) - 1 });
    }
  };

  const onNext = () => {
    if (nextUrl && !isFetching) {
      setFromCount(fromCount + pageSize);
      onPageChange && onPageChange(fromCount + pageSize);
      request && request(nextUrl);
      updateQueryParams({ pageNumber: Number(fromPage) + 1 });
    }
  };

  return (
    <div data-testid='tint-pagination' className={`tint-pagination ${customClass && customClass}`}>
      <div className='tint-pagination__action tint-pagination__action--left'>
        {previousUrl && (
          <>
            <span
              data-testid='onPrevious'
              onClick={onPrevious}
              className={`fas fa-chevron-left ${previousUrl ? '' : 'fas--disabled'}`}
            />
            <span onClick={onPrevious} className='previous-text'>
              Previous
            </span>
          </>
        )}
      </div>
      {fromPage && (nextUrl || previousUrl) && (
        <div className='tint-pagination__main'>
          <span>Page {fromPage}</span>
        </div>
      )}
      <div className='tint-pagination__action'>
        {nextUrl && (
          <>
            <span onClick={onNext} className='next-text'>
              Next
            </span>
            <span
              data-testid='onNext'
              onClick={onNext}
              className={`fas fa-chevron-right ${nextUrl ? '' : 'fas--disabled'}`}
            />
          </>
        )}
      </div>
    </div>
  );
};

Pagination.propTypes = {
  title: PropTypes.string,
  total: PropTypes.number,
  isFetching: PropTypes.bool,
  pageSize: PropTypes.number,
  customClass: PropTypes.string,
  nextUrl: PropTypes.string,
  previousUrl: PropTypes.string,
  request: PropTypes.func,
  onPageChange: PropTypes.func,
};
