import React from 'react';
import './tooltip.sass';

const Tooltip = ({ text, component, style }) => {
  return (
    <div className='tint-tooltip-container'>
      {component}
      <div style={style} className='tint-tooltip-container__tint-tooltip'>
        <span>{text}</span>
      </div>
    </div>
  );
};

export default Tooltip;
