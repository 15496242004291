import { ACTIONS } from '../../wizards/actions/wizards.actions';

export const initialState = {
  socialAccount: undefined,
};

export const wizards = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_SOCIAL_ACCOUNT:
      return {
        ...state,
        socialAccount: action.payload,
      };

    default:
      return state;
  }
};
