import React from 'react';
import './tag-list.sass';
import { Tag } from '../tag/tag';

export const TagList = ({ list = [], selectedTag, onRemove, onTagClick }) => {
  return (
    <ul className='tint-tag-list'>
      {list?.map((tag, i) => (
        <li key={tag}>
          <Tag
            tag={tag}
            isSelected={selectedTag}
            onClick={() => onTagClick?.(tag)}
            onRemove={() => {
              onRemove?.(i, tag);
            }}
          />
        </li>
      ))}
    </ul>
  );
};
