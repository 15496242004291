import React from 'react';
import './counter.sass';
import * as PropTypes from 'prop-types';
import ColorParser from '../../../../../../../../services/color-parser/color-parser';
import PreloaderComponent from '../../../../../../../../components/interface/preloaders/preloader/preloader';

const Counter = ({ totalVotes, styles, isLoading, baseClass }) => {
  const defaultValue = '000000';
  function setStringCountNumber(totalVotes) {
    return !totalVotes
      ? defaultValue
      : `${defaultValue.slice(0, defaultValue.length - totalVotes.toString().length)}${totalVotes}`;
  }

  return (
    <div
      data-testid='survey-counter-section'
      className={`survey-counter-section base-class--${baseClass}`}
      style={{
        backgroundColor: ColorParser.defaultColor(styles.counterBackgroundColor),
        fontSize: styles.fontSize ? `${styles.fontSize}px` : '84px',
      }}>
      {isLoading ? (
        <p
          className='survey-counter-section__feed-counter'
          style={{
            color: ColorParser.defaultColor(styles.counterTextColor),
            fontFamily: styles.fontFamily ? JSON.stringify(styles.fontFamily.value) : 'Lato',
          }}>
          {setStringCountNumber(totalVotes)}
        </p>
      ) : (
        <PreloaderComponent
          style={{ minHeight: '100px', color: styles.counterTextColor }}
          data-testid='survey-counter-preloader-id'
        />
      )}
    </div>
  );
};

Counter.propTypes = {
  baseClass: PropTypes.string,
  totalVotes: PropTypes.number,
  counterHeader: PropTypes.string,
  styles: PropTypes.object,
};

export default Counter;
