import { ExperienceConfig } from '../../../feature/experience/utils/experience.config';

const _addWidthResize = (url, width) => {
  return url + `resize=width:${width},fit:max/`;
};

const getBackgroundStyles = ({ content, parentSectionIndex, index, pageWidth }) => {
  const fileStackTransformUrl = 'https://cdn.filestackcontent.com/';
  const fileStackCdnUrl = 'https://cdn.filestackcontent.com/';
  const transformations = 'auto_image/compress/';
  const { backgroundImageUrl, backgroundImageUrlMedium, backgroundImageUrlSmall } = content;
  let styles = '';
  let mobileImage = backgroundImageUrlSmall || backgroundImageUrlMedium || backgroundImageUrl;
  let tabletImage = backgroundImageUrlMedium || backgroundImageUrlSmall || backgroundImageUrl;
  let desktopImage = backgroundImageUrl || backgroundImageUrlMedium || backgroundImageUrlSmall;

  if (!mobileImage && !tabletImage && !desktopImage) {
    //if there's no images we can stop rendering background styles
    return undefined;
  }

  desktopImage =
    _addWidthResize(fileStackTransformUrl, 2000) + transformations + desktopImage.replace(fileStackCdnUrl, '');
  mobileImage =
    _addWidthResize(fileStackTransformUrl, 1170) + transformations + mobileImage.replace(fileStackCdnUrl, '');
  tabletImage =
    _addWidthResize(fileStackTransformUrl, 768) + transformations + tabletImage.replace(fileStackCdnUrl, '');

  const getSectionIndex = typeof parentSectionIndex !== 'number' ? index : `${parentSectionIndex}-${index}`;
  switch (pageWidth) {
    case ExperienceConfig.pageSize.mobile:
      styles = `.tint-page-section-index-${getSectionIndex} { background-image: url(${mobileImage}); }`;
      break;
    case ExperienceConfig.pageSize.tablet:
      styles = `.tint-page-section-index-${getSectionIndex} { background-image: url(${tabletImage}); }`;
      break;
    default:
      styles = `
          .tint-page-section-index-${getSectionIndex} { background-image: url(${mobileImage}); }
          @media (min-width: 768px) {
            .tint-page-section-index-${getSectionIndex} { background-image: url(${tabletImage}); }
          }
          @media (min-width: 1170px) {
            .tint-page-section-index-${getSectionIndex} { background-image: url(${desktopImage}); }
          }
          `;
      break;
  }
  return styles;
};

export const BackgroundStyleService = {
  getBackgroundStyles,
};
