import React, { Component } from 'react';
import './custom-font-family.sass';
import { getFonts } from '../../../../services/functions/url-config/url-config';
import { addCustomFont } from '../../../../feature/experience/actions/experience/experience.actions';
import { AddFontLinkHoc } from '../../../HOC/add-font-link/add-font-link.hoc';
import PreloaderComponent, { PreloaderSizeType } from '../../preloaders/preloader/preloader';
import EditInputDropdown from '../../../../feature/experience/components/experience/edit-input-dropdown/edit-input-dropdown';
import { Config } from '../../../../utils/config';
import { connect } from 'react-redux';
import experiencesGuards from '../../../../feature/experience/guards/experience.guards';
import { getPermissions } from '../../../../reducers/session';

class CustomFontFamilyDropdown extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      env: this.isDevEnv ? Config.googleAccountApiKey.devEnv : Config.googleAccountApiKey.prodEnv,
      fontList: this.mapFontFamily(),
      customFonts: [],
      permissions: getPermissions(this.props.session),
    };
  }

  canActiveFontsRead() {
    return experiencesGuards.fontsReadGuard({ permissions: this.state.permissions });
  }

  componentDidMount() {
    if (this.canActiveFontsRead()) {
      getFonts()
        .then(res => {
          this.setState({ customFonts: res.data.data.map });
          const mapCustomFonts = res.data.data.map(font => {
            return {
              value: font.attributes.name,
              name: font.attributes.name,
              id: font.id,
            };
          });

          const newList = [...this.state.fontList, ...mapCustomFonts];
          this.setState({ fontList: newList });
        })
        .finally(() => {
          this.setState({ isLoading: false });
        });
    } else {
      this.setState({ isLoading: false });
      this.setState({ fontList: this.state.fontList });
    }
  }

  mapFontFamily() {
    return Config.fontFamilyList.map(font => {
      return {
        value: font,
        name: font,
      };
    });
  }

  isGoogleFont(fontName) {
    return Config.fontFamilyList.includes(fontName);
  }

  addCustomFont = (font, isGoogleFont) => {
    if (this.canActiveFontsRead() && !isGoogleFont) {
      this.props.addCustomFont(font);
    }
  };

  onChangeValue(font, isGoogleFont) {
    this.props.loadFont(font);
    this.props.onChange(font, isGoogleFont);

    if (this.canActiveFontsRead() && !isGoogleFont) {
      this.addCustomFont(font, isGoogleFont);
    }

    this.props.styleUpdate({
      fontFamily: font,
    });
  }

  render() {
    return (
      <div
        className='tint-custom-font-family-dropdown'
        style={{
          fontFamily: JSON.stringify(this.props.fontFamily),
        }}>
        {!this.state.isLoading ? (
          <EditInputDropdown
            isControlled={true}
            value={this.props.fontFamily}
            onChange={e => {
              this.onChangeValue(
                this.state.fontList[e.target.options.selectedIndex],
                this.isGoogleFont(e.target.value)
              );
            }}
            options={this.state.fontList}
          />
        ) : (
          <PreloaderComponent
            size={PreloaderSizeType.SMALL}
            style={{
              minHeight: '30px',
              minWidth: '200px',
            }}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  customFonts:
    state.experience.data &&
    state.experience.data.attributes.data.customFonts &&
    state.experience.data.attributes.data.customFonts.draft,
  dropdownItem: state.dropdown.value,
  session: state.session,
});

const mapDispatchToProps = dispatch => ({
  addCustomFont: font => dispatch(addCustomFont(font)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddFontLinkHoc(CustomFontFamilyDropdown));
