import React from 'react';
import './dropdown-date-range-header.sass';
import { Config } from '../../../../../../../utils/config';

export const DropdownDateRangeHeader = ({ title, currentItem }) => {
  const formatedTitle =
    currentItem &&
    `${Config.monthList[new Date(currentItem.startDate).getMonth()]} ${new Date(
      currentItem.startDate
    ).getDate()}, ${new Date(currentItem.startDate).getFullYear()} - ${
      Config.monthList[new Date(currentItem.endDate).getMonth()]
    } ${new Date(currentItem.endDate).getDate()}, ${new Date(currentItem.endDate).getFullYear()}`;

  return (
    <div className='dropdown-date-range-header'>
      {currentItem ? <span>{formatedTitle}</span> : <span>{title}</span>}
      <span className='icon fa fa-caret-down' />

      <span className='dropdown-date-list-header__icon'>
        <span className='icon fas fa-calendar-alt' />
      </span>
    </div>
  );
};
