import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import CustomDatePicker from '../../components/interface/inputs/custom-date-picker/custom-date-picker';
import { EditInputDropdown } from '../../feature/experience/components/experience/';
import { Config } from '../../utils/config';
import './timer.sass';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import * as Yup from 'yup';
import InputPrimary from '../interface/inputs/input-primary/input-primary';
import debounce from 'lodash.debounce';

const timeTypeOptions = [{ value: 'AM', name: 'AM' }, { value: 'PM', name: 'PM' }];

const twelveHours = 12;

const TimePart = {
  HOURS: 'hours',
  MINUTES: 'minutes',
  TYPE: 'timeType',
};

export class Timer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      timeType: new Date(this.props.currentDate).getHours() > 11 ? 'PM' : 'AM',
      timeParts: {
        hours: this.getHoursByTimeType(),
        minutes: new Date(this.props.currentDate).getMinutes(),
      },
      errors: {},
    };

    this.dataChange = debounce(date => {
      this.props.dataUpdate({
        currentDate: new Date(date),
      });
    }, 600);
  }

  getHoursByTimeType() {
    return (new Date(this.props.currentDate).getHours() > 11 ? 'PM' : 'AM') === 'AM'
      ? new Date(this.props.currentDate).getHours()
      : (new Date(this.props.currentDate).getHours() - twelveHours).toString();
  }

  onDateChanges = date => {
    this.dataChange(date);
  };

  onHourChange = value => {
    let additionalHours = this.state.timeType === 'PM' ? twelveHours : 0;
    const add = additionalHours + parseInt(value, 10);
    const endDate = new Date(this.props.currentDate);
    endDate.setHours(add);

    this.onDateChanges(endDate);
  };

  onDateChange = (value, timeParts) => {
    switch (timeParts) {
      case TimePart.MINUTES:
        this.onDateChanges(new Date(this.props.currentDate).setMinutes(this.state.timeParts.minutes));
        break;

      case TimePart.TYPE:
      case TimePart.HOURS:
        this.onHourChange(value);
        break;

      default:
        return;
    }
  };

  getValidationSchema() {
    return Yup.object().shape({
      hours: Yup.string()
        .required('Can not be empty')
        .matches(Config.regExp.timer.hours, 'Has to be between 0-11'),
      minutes: Yup.string()
        .required('Can not be empty')
        .matches(Config.regExp.timer.minutes, 'Has to be between 0-59'),
      timeType: Yup.string().required(),
    });
  }

  handleHoursChange(e) {
    const isValid = Config.regExp.timer.hours.test(e.target.value);

    if (isValid) {
      this.setState({ timeParts: { ...this.state.timeParts, hours: e.target.value } }, () =>
        this.onDateChange(e.target.value, TimePart.HOURS)
      );
    }
  }

  handleMinutesChange(e) {
    const isValid = Config.regExp.timer.minutes.test(e.target.value);

    if (isValid) {
      this.setState(
        {
          timeParts: {
            ...this.state.timeParts,
            minutes: e.target.value,
          },
        },
        () => this.onDateChange(e.target.value, TimePart.MINUTES)
      );
    }
  }

  render() {
    return (
      <div className='tint-timer'>
        <Formik
          validateOnChange={true}
          initialValues={{
            hours: this.state.timeParts.hours,
            minutes: this.state.timeParts.minutes,
            timeType: this.state.timeType,
          }}
          validationSchema={this.getValidationSchema()}
          render={({ errors, values, handleChange, handleSubmit, dirty }) => (
            <form
              id='formId'
              noValidate
              onSubmit={e => {
                e.preventDefault();
                handleSubmit();
              }}>
              <DatePicker
                customInput={<CustomDatePicker />}
                onChange={this.onDateChanges}
                selected={new Date(this.props.currentDate)}
              />
              <div className='tint-timer__container'>
                <div>
                  <InputPrimary
                    label={'Hours'}
                    name={'hours'}
                    type={'number'}
                    value={values.hours}
                    placeholder={'HH'}
                    error={errors.hours}
                    touched={errors.hours}
                    handleChange={e => {
                      this.handleHoursChange(e);
                      return handleChange(e);
                    }}
                    errorMsg={'Has to be between 0-11'}
                  />
                </div>
                <div>
                  <InputPrimary
                    label={'Minutes'}
                    name={'minutes'}
                    type={'number'}
                    value={values.minutes}
                    placeholder={'MM'}
                    dirty={dirty}
                    error={errors.minutes}
                    touched={errors.minutes}
                    handleChange={e => {
                      this.handleMinutesChange(e);
                      return handleChange(e);
                    }}
                    errorMsg={'Has to be between 0-59'}
                  />
                </div>
                <div>
                  <EditInputDropdown
                    onChange={e => {
                      this.setState({ timeType: e.target.value }, () => {
                        this.onDateChange(this.state.timeParts.hours, TimePart.TYPE);
                      });
                      return handleChange(e);
                    }}
                    defaultValue={this.state.timeType}
                    value={values.timeType}
                    options={timeTypeOptions}
                    label='Time'
                  />
                </div>
              </div>
            </form>
          )}
        />
      </div>
    );
  }
}

Timer.propTypes = {
  currentDate: PropTypes.string,
  dataUpdate: PropTypes.func,
};
