import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './experience-settings.sass';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';
import { EditModalBody } from '../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { Button } from '../../../../../../components/interface/button/button';
import { EXPERIENCE_ANALYTICS_TYPE } from '../../../../../../model/experience-analytics-type.model';
import copyToClipboard from '../../../../../../services/functions/copy-to-clipboard/copy-to-clipboard';
import { getCustomDomains } from '../../../../actions/custom-domains/custom-domains.fat-actions';
import { handleApiError } from '../../../../../../services/functions/error-handler/error-handler';
import PreloaderComponent, {
  PreloaderColorType,
} from '../../../../../../components/interface/preloaders/preloader/preloader';
import { FileStack } from '../../../../../../services/filestack/file-stack';
import { ExperienceCaptchaSettings } from '../experience-captcha-settings/experience-captcha-settings';
import InputPrimary from '../../../../../../components/interface/inputs/input-primary/input-primary';
import DropdownButton, { ButtonType } from '../../../../../../components/interface/dropdown/button/dropdown-button';
import { DropdownHeader } from '../../../../../../components/interface/dropdown/button/header/dropdown-header';
import DropdownSortList from '../../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list';
import axios from 'axios';
import TextareaPrimary from '../../../../../../components/interface/inputs/textarea-primary/textarea-primary';
import FeatureGuard from '../../../../../../guards/feature-guard';
import { GuardNames } from '../../../../../../guards/guards-list';
import useFeatureAccessibility from '../../../../../../components/hooks/use-feature-accessibility/use-feature-accessibility';

const optionTypes = {
  YES: 'yes',
  NO: 'no',
  NONE: 'none',
};

const options = [
  { value: true, name: 'Yes' },
  { value: false, name: 'No' },
];

const analyticsOptions = [
  { value: null, name: 'None' },
  { value: EXPERIENCE_ANALYTICS_TYPE.google_analytics, name: 'Google Analytics' },
  { value: EXPERIENCE_ANALYTICS_TYPE.google_tag_manager, name: 'Google Tag Manager' },
];

export const ExperienceSettings = ({
  experience,
  draftExperience,
  experienceUrl,
  dataUpdate,
  cookieConsent,
  onAddCookieBanner,
}) => {
  const [name, setName] = useState(draftExperience.attributes.name || '');
  const [title, setTitle] = useState(draftExperience.attributes.title || '');
  const favIconUrl = draftExperience.attributes.favicon_url;
  const analyticsId = draftExperience.attributes.analytics_id;
  const analyticsType = draftExperience.attributes.analytics_type;
  const description = draftExperience.attributes.description;
  const noindex = draftExperience.attributes.noindex;

  const [slug, setSlug] = useState(draftExperience.attributes.slug || '');

  const dispatch = useDispatch();
  const session = useSelector(state => state.session.data);
  const customDomains = useSelector(state => state.customDomains.data) || [];
  const isDomainFetching = useSelector(state => state.customDomains.isPending);

  const [domainId, setDomainId] = useState();
  const [cookieBannerEnabled, setCookieBannerEnabled] = useState(false);
  const [noIndex, setNoIndex] = useState(false);

  const customDomainList = [
    {
      id: optionTypes.NONE,
      name: 'None',
    },
    ...customDomains,
  ].map(domain => ({
    value: domain.id,
    name: domain.name,
  }));

  const [descriptionError, setDescriptionError] = useState('');
  const canActiveDomainsRead = useFeatureAccessibility(GuardNames.DOMAINS_READ);

  useEffect(() => {
    if (cookieConsent) setCookieBannerEnabled(true);
    if (noindex) setNoIndex(true);

    const id =
      draftExperience.attributes.domain_id ||
      (draftExperience.relationships.domain.data && draftExperience.relationships.domain.data.id) ||
      optionTypes.NONE;
    setDomainId(id);

    if (canActiveDomainsRead && customDomains.length === 0) {
      dispatch(getCustomDomains(session.id)).catch(err => {
        handleApiError(err, toast.error);
      });
    }
  }, []);

  const onDownloadQRCodeClick = () => {
    const svg = document.getElementById('QRCode');
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL('image/png');
      const downloadLink = document.createElement('a');
      downloadLink.download = `${slug}_QR_Code`;
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  const onCopyToClipboardClick = urlAddress => {
    if (slug) {
      copyToClipboard(urlAddress);
      toast.success('Link copied to your clipboard!');
    } else {
      toast.warn('You need to generate your url first!');
    }
  };

  const onUploadFavIcon = () => {
    const fileStack = new FileStack();
    const maxFavIconDimensions = 40;

    fileStack.openFilePicker({
      maxSize: experience?.attributes?.maximum_image_size,
      onUploadDone: res => {
        dataUpdate({
          favicon_url: `https://cdn.filestackcontent.com/resize=height:${maxFavIconDimensions},width:${maxFavIconDimensions},fit:max/${res.filesUploaded[0].url}`,
        });
      },
      accept: ['image/png'],
    });
  };

  const renderForm = () => {
    return (
      <EditModalBody title='Customize your experience’s URL Slug and copy the public URL for sharing.'>
        <form className='tint-experience-settings' noValidate>
          <InputPrimary
            label='Project Name'
            name='project_name'
            type='text'
            placeholder='Project Name'
            value={name}
            handleChange={e => {
              setName(e.target.value);
              if (e.target.value !== '') {
                dataUpdate({ name: e.target.value });
              } else {
                dataUpdate({ name: null });
              }
            }}
          />

          <InputPrimary
            label='Page Title'
            name='page_title'
            type='text'
            placeholder='Page Name'
            value={title}
            handleChange={e => {
              setTitle(e.target.value);
              if (e.target.value !== '') {
                dataUpdate({ title: e.target.value });
              } else {
                dataUpdate({ title: null });
              }
            }}
          />

          <InputPrimary
            label='Slug / Friendly URL'
            name='slug'
            type='text'
            placeholder='summersweepstakes'
            value={slug}
            handleChange={e => {
              setSlug(e.target.value);
              if (e.target.value !== '') {
                dataUpdate({ slug: e.target.value });
              } else {
                dataUpdate({ slug: null });
              }
            }}
          />

          {!isDomainFetching ? (
            <span className='tint-experience-settings__description'>
              {slug
                ? experienceUrl
                : 'Publish your site to get generated slug or add it yourself writing it into the input'}
            </span>
          ) : (
            <PreloaderComponent
              color={PreloaderColorType.LIGHT}
              style={{ minHeight: '50px' }}
              data-testid='preloaderId'
            />
          )}

          <div className='tint-experience-settings__button-group'>
            <button style={{ display: 'none' }} type='submit'>
              Submit
            </button>
            <div
              onClick={() => onCopyToClipboardClick(experienceUrl)}
              className='tint-experience-settings__copy-button'>
              <span>Copy URL</span>
            </div>

            <Button
              onClick={() => {
                if (slug) {
                  onDownloadQRCodeClick();
                } else {
                  toast.warn('You need to generate your url first!');
                }
              }}
              type='gray'
              size='full-width'
              text='Download QR Code'
            />
            {slug && <QRCode style={{ display: 'none' }} id='QRCode' value={experienceUrl} />}

            <FeatureGuard featureName={GuardNames.EXPERIENCES_ADVANCED_OPTIONS}>
              <div className='tint-experience-settings__upload-favicon'>
                {favIconUrl ? (
                  <>
                    <img src={favIconUrl} />
                    <Button
                      onClick={() => {
                        dataUpdate({ favicon_url: '' });
                      }}
                      type='gray'
                      size='full-width'
                      text='Remove Favicon'
                    />
                  </>
                ) : (
                  <Button
                    onClick={onUploadFavIcon}
                    type='gray'
                    size='full-width'
                    text='Upload Favicon'
                    icon='far fa-arrow-to-top'
                  />
                )}
              </div>
            </FeatureGuard>
          </div>

          <div className='tint-experience-settings__google-analytics'>
            <span className='tint-experience-settings__description'>
              Connect your page to Google Analytics or Google Tag Manager for visitor tracking and more
            </span>
            <DropdownButton
              dropdownHeader={props => <DropdownHeader {...props} />}
              dropdownList={props => <DropdownSortList {...props} />}
              currentItem={analyticsOptions.find(option => option.value === analyticsType) || analyticsOptions[0]}
              list={analyticsOptions}
              iconRight='fa fa-caret-down'
              buttonType={ButtonType.BUTTON_GRAY_BORDER}
              onChangeValue={e => {
                const updateObject = {
                  analytics_type: e.value,
                };
                if (e.value === null) {
                  updateObject.analytics_id = null;
                }
                dataUpdate(updateObject);
              }}
            />
            {!!analyticsType && (
              <>
                <span className='tint-experience-settings__description'></span>
                <InputPrimary
                  name='analytics_id'
                  type='text'
                  label={analyticsType === 'google_analytics' ? 'Google Analytics' : 'Google Tag Manager'}
                  placeholder={analyticsType === 'google_analytics' ? 'G-XXXXXX' : 'GTM-XXXXXX'}
                  value={analyticsId || ''}
                  handleChange={e => {
                    if (e.target.value !== '') {
                      dataUpdate({
                        analytics_id: e.target.value,
                      });
                    } else {
                      dataUpdate({
                        analytics_id: null,
                      });
                    }
                  }}
                />
              </>
            )}
          </div>

          <FeatureGuard featureName={GuardNames.EXPERIENCE_SEO}>
            <div className='tint-experience-settings__meta-description'>
              <TextareaPrimary
                name='description'
                type='text'
                label='Description'
                placeholder='Description'
                error={descriptionError}
                value={description || ''}
                touched={true}
                handleChange={e => {
                  if (e.target.value !== '') {
                    // FIXME we dont have other validation here so please god forgive me
                    if (e.target.value.length > 155) {
                      setDescriptionError("You've exceeded the limit of 155 characters");
                      return;
                    }
                    setDescriptionError('');
                    dataUpdate({
                      description: e.target.value,
                    });
                  } else {
                    dataUpdate({
                      description: null,
                    });
                  }
                }}
              />
            </div>
          </FeatureGuard>
          {!isDomainFetching && canActiveDomainsRead && (
            <div className='tint-experience-settings__multiple-domains'>
              <label>Select Custom Domain</label>
              <DropdownButton
                dropdownHeader={props => <DropdownHeader {...props} />}
                dropdownList={props => <DropdownSortList {...props} />}
                currentItem={customDomainList.find(option => option.value === domainId)}
                list={customDomainList}
                iconRight='fa fa-caret-down'
                buttonType={ButtonType.BUTTON_GRAY_BORDER}
                onChangeValue={e => {
                  setDomainId(e.value);
                  dataUpdate({
                    domain_id: e.value !== optionTypes.NONE ? e.value : null,
                  });
                }}
              />
            </div>
          )}

          <FeatureGuard featureName={GuardNames.EXPERIENCES_ADVANCED_OPTIONS}>
            <div className='tint-experience-settings__search-indexing'>
              <label>Block search indexing</label>
              <DropdownButton
                dropdownHeader={props => <DropdownHeader {...props} />}
                dropdownList={props => <DropdownSortList {...props} />}
                currentItem={options.find(option => option.value === noIndex)}
                list={options}
                iconRight='fa fa-caret-down'
                buttonType={ButtonType.BUTTON_GRAY_BORDER}
                onChangeValue={e => {
                  setNoIndex(e.value);
                  dataUpdate({
                    noindex: e.value,
                  });
                }}
              />
            </div>
          </FeatureGuard>

          <FeatureGuard featureName={GuardNames.GDPR_COMPLIANCE}>
            <div className='tint-experience-settings__cookie-banner'>
              <label>Enable cookie banner</label>
              <DropdownButton
                dropdownHeader={props => <DropdownHeader {...props} />}
                dropdownList={props => <DropdownSortList {...props} />}
                currentItem={options.find(option => option.value === cookieBannerEnabled)}
                list={options}
                iconRight='fa fa-caret-down'
                buttonType={ButtonType.BUTTON_GRAY_BORDER}
                onChangeValue={e => {
                  setCookieBannerEnabled(e.value);
                  onAddCookieBanner(e.value);
                }}
              />
            </div>
          </FeatureGuard>
          <ExperienceCaptchaSettings
            experience={experience}
            draftExperience={draftExperience}
            dataUpdate={dataUpdate}
          />
        </form>
      </EditModalBody>
    );
  };

  return renderForm();
};
