export const mapDropdownListItem = item => {
  return (
    item && {
      slugName: item.attributes.slug,
      name: item.attributes.name || item.attributes.slug,
      value: item.id,
    }
  );
};

export const mapDropdownList = (list = [], mapDropdownItem = mapDropdownListItem) => {
  return list.map(item => mapDropdownItem(item));
};
