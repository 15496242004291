import { ACTIONS } from '../actions/posts.actions';
import { produce } from 'immer';
import { FilterGroupTypes } from '../components/tint-editor/top-bar-filter/filter-modal/tint-editor-top-bar-filter-modal';
import {
  ActionFilterKeyProps,
  actionFilterProps,
  ActionFilterValues,
} from '../components/tint-editor/top-bar-filter/filter-modal/tint-editor-top-bar-filter-modal-filters.data';
import { MAX_STAR_AMOUNT } from '../components/tint-editor/star-rating/star-rating';
import { SocialAccountType } from '../../../model/social-account-type.model';
import { SortBy } from '../components/tint-editor/top-bar/tint-editor-top-bar';

export const PostRenderType = {
  GRID: 'grid',
  LIST: 'list',
};

export const initialState = {
  data: [],
  filters: [],
  selectedFilters: undefined,
  sortPostsBy: { sortBy: SortBy.TIME_POSTED },
  languages: {},
  links: null,
  selectedPosts: [],
  pageSize: 20,
  currentPageStart: 1,
  currentPageEnd: 20,
  isFetching: false,
  isLastPage: false,
  isLoading: false,
  fetchingFeedId: undefined,
  error: undefined,
  pinnedId: undefined,
  renderType: PostRenderType.GRID,
};

export const tintEditorPosts = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_RENDER_TYPE:
      return {
        ...state,
        renderType: action.payload,
      };

    case ACTIONS.CLEAR_STATE:
      return initialState;

    case ACTIONS.RECOVER_POST.REQUEST:
    case ACTIONS.DELETE_POST.REQUEST:
    case ACTIONS.UPDATE_POST.REQUEST:
      return {
        ...state,
        isFetching: false,
        isError: false,
        fetchingFeedId: action.payload,
      };

    case ACTIONS.GET_POST_REQUEST_RIGHTS.REQUEST:
    case ACTIONS.GET_LAST_PAGE.REQUEST:
      return {
        ...state,
        ...{
          isError: false,
        },
      };

    case ACTIONS.DELETE_POSTS.REQUEST:
    case ACTIONS.POST_SIMILAR_IMAGE.REQUEST:
    case ACTIONS.REMOVE_SAVED_FILTERS.REQUEST:
    case ACTIONS.UPDATE_SAVED_FILTERS.REQUEST:
    case ACTIONS.POST_SAVED_FILTERS.REQUEST:
    case ACTIONS.GET_SAVED_FILTERS.REQUEST:
    case ACTIONS.GET_GLOBAL_POSTS.REQUEST:
    case ACTIONS.GET_POSTS.REQUEST:
    case ACTIONS.UPDATE_POSTS.REQUEST:
      return {
        ...state,
        ...{
          isFetching: true,
          isError: false,
        },
      };

    case ACTIONS.GET_POST_AGGREGATIONS.SUCCESS:
      return produce(state, draft => {
        const languageObj = action.payload.data.find(e => e.id === 'language');
        const imageObjectObj = action.payload.data.find(e => e.id === 'image_objects');
        draft.languages = languageObj?.attributes?.buckets || {};
        draft.imageObjects = imageObjectObj?.attributes?.buckets || {};
      });

    case ACTIONS.GET_POST_REQUEST_RIGHTS.SUCCESS:
      return produce(state, draft => {
        const el = draft.data.find(post => post.id === action.payload.id);
        el.rightRequests = action.payload.data;
      });

    case ACTIONS.GET_LAST_PAGE.SUCCESS:
      return produce(state, draft => {
        draft.isLastPage = action.payload;
      });

    case ACTIONS.OPEN_POST_TAG_LIST:
      return produce(state, draftState => {
        draftState.tagListModalPostId = action.payload;
      });

    case ACTIONS.GET_GLOBAL_POSTS.SUCCESS:
    case ACTIONS.POST_SIMILAR_IMAGE.SUCCESS:
    case ACTIONS.GET_POSTS.SUCCESS:
      return produce(state, draft => {
        const includedCTA = action.payload?.included?.filter(el => el.type === 'cta_association');
        const socialFeeds = action.payload?.included?.filter(data => data.type === 'social_feed');
        const uniquePosts = removePostDuplicates(action.payload.data, socialFeeds);
        draft.data = uniquePosts.map(post => ({
          ...post,
          cta: {
            ...post?.relationships?.cta_associations?.data?.map(
              ctaAssociations =>
                includedCTA?.find(includedCTA => includedCTA.id === ctaAssociations.id)?.relationships?.cta?.data
            ),
          },
          siblings: post?.relationships?.siblings?.data?.map(sibling =>
            action.payload?.included?.find(e => e.id === sibling.id)
          ),
          replies: post?.relationships?.replies?.data?.map(reply => {
            const foundReply = action.payload?.included?.filter(e => e.type === 'reply').find(e => e.id === reply.id);
            const user = action.payload?.included?.find(
              e => e.type === 'user' && e.id === foundReply?.relationships?.user?.data?.id
            );
            return {
              ...foundReply,
              user: user,
            };
          }),
        }));
        draft.tints = action.payload?.included
          ?.filter(el => el.type === 'tint')
          ?.map(tint => ({ id: tint?.id, slugName: tint?.attributes?.name }));
        draft.links = action.payload.links;
        draft.currentPageStart = action.payload.pageStart;
        draft.currentPageEnd = action.payload.pageEnd;
        draft.selectedPosts = [];
        draft.isFetching = false;
        draft.isError = false;
        draft.products = action.payload?.included?.filter(data => data.type === 'product');
        draft.productTags = action.payload?.included?.filter(data => data.type === 'product_tag');
        draft.socialFeeds = socialFeeds;
      });

    case ACTIONS.DELETE_REPLY:
      return produce(state, draft => {
        const post = draft.data.find(post => post.id === action.payload.postId);
        post.replies = post.replies.filter(reply => reply.id !== action.payload.replyId);
        return draft;
      });

    case ACTIONS.ADD_POST_PRODUCT_TAG:
      return produce(state, draft => {
        const currentPost = draft.data.find(
          post =>
            post.attributes.external_id === action.payload.externalId &&
            post.attributes.attachment_id === action.payload.data.attachmentId
        );
        currentPost?.relationships?.product_tags?.data.push(action.payload.data);
      });

    case ACTIONS.DELETE_POST_PRODUCT_TAG:
      return produce(state, draft => {
        const currentPost = draft.data.find(
          post =>
            post.attributes.external_id === action.payload.externalId &&
            post.attributes.attachment_id === action.payload.attachmentId
        );
        currentPost.relationships.product_tags.data = currentPost?.relationships?.product_tags?.data.filter(
          productTag => productTag.id !== action.payload.rectId
        );
      });
    case ACTIONS.RECOVER_POST.FAILURE:
    case ACTIONS.DELETE_POSTS.FAILURE:
    case ACTIONS.DELETE_POST.FAILURE:
    case ACTIONS.UPDATE_POST.FAILURE:
      return {
        ...state,
        ...{
          isError: action.payload,
          isFetching: false,
          fetchingFeedId: undefined,
        },
      };

    case ACTIONS.GET_POST_REQUEST_RIGHTS.FAILURE:
    case ACTIONS.POST_SIMILAR_IMAGE.FAILURE:
    case ACTIONS.POST_SAVED_FILTERS.FAILURE:
    case ACTIONS.GET_SAVED_FILTERS.FAILURE:
    case ACTIONS.REMOVE_SAVED_FILTERS.FAILURE:
    case ACTIONS.GET_GLOBAL_POSTS.FAILURE:
    case ACTIONS.GET_POSTS.FAILURE:
    case ACTIONS.UPDATE_POSTS.FAILURE:
    case ACTIONS.GET_LAST_PAGE.FAILURE:
      return {
        ...state,
        ...{
          isError: action.payload,
          isFetching: false,
        },
      };

    case ACTIONS.UNPIN_TO_TOP:
      return produce(state, draft => {
        draft.fetchingFeedId = undefined;
        draft.pinnedId = undefined;
      });

    case ACTIONS.PIN_TO_TOP:
      return produce(state, draft => {
        draft.data = draft.data.filter(post => post.id !== action.payload.id);
        draft.data = [action.payload, ...draft.data];
        draft.fetchingFeedId = undefined;
        draft.pinnedId = action.payload.id;
      });

    case ACTIONS.RECOVER_POST.SUCCESS:
      return produce(state, draft => {
        draft.data = draft.data.filter(post => post.id !== action.payload);
        draft.isFetching = false;
        draft.fetchingFeedId = undefined;
      });

    case ACTIONS.DELETE_POST.SUCCESS:
      return produce(state, draft => {
        draft.data = draft.data.filter(post => post.id !== action.payload);
        draft.isFetching = false;
        draft.fetchingFeedId = undefined;
      });

    case ACTIONS.DELETE_POSTS.SUCCESS:
      return produce(state, draft => {
        draft.data = draft.data.filter(post => action.payload.every(el => el.id !== post.id));
        draft.isFetching = false;
        draft.fetchingFeedId = undefined;
      });

    case ACTIONS.UPDATE_POST.SUCCESS:
      return produce(state, draft => {
        const index = draft.data.findIndex(post => post.id === action.payload.id);
        draft.data[index] = action.payload;
        draft.data.forEach(post => {
          if (post.siblings) {
            post.siblings = post.siblings.map(sibling => {
              if (sibling.id === action.payload.id) {
                return action.payload;
              }
              return sibling;
            });
          }
        });
        draft.isFetching = false;
        draft.fetchingFeedId = undefined;
      });

    case ACTIONS.UPDATE_POSTS.SUCCESS:
      return produce(state, draft => {
        draft.data = draft.data.map(post => {
          const updatedPost = action.payload.find(updatePost => updatePost.id === post.id);
          return updatedPost ? updatedPost : post;
        });
        draft.data = draft.data.filter(e => e.attributes.status !== 'deleted');
        draft.selectedPosts = action.payload;
        draft.isFetching = false;
        draft.fetchingFeedId = undefined;
      });

    case ACTIONS.UPDATE_SELECTED_POSTS:
      return {
        ...state,
        selectedPosts: action.payload,
      };

    case ACTIONS.UPDATE_PAGE_SIZE:
      return {
        ...state,
        pageSize: action.payload,
      };

    case ACTIONS.SET_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };

    case ACTIONS.SELECT_FILTER:
      return produce(state, draft => {
        draft.selectedFilters = {
          ...draft.selectedFilters,
          [action.payload.type]:
            action.payload.type !== FilterGroupTypes.DATE
              ? action.payload.data.sort((a, b) => (a > b ? 1 : -1))
              : action.payload.data,
        };
      });

    case ACTIONS.CLEAR_SAVED_FILTERS:
      return produce(state, draft => {
        draft.filters = undefined;
      });

    case ACTIONS.SORT_POSTS_BY:
      return produce(state, draft => {
        draft.sortPostsBy = {
          ...draft?.sortPostsBy,
          [action.payload.type]: action.payload.data,
        };
        draft.sortPostsByVisualSearch = undefined;
      });

    case ACTIONS.SORT_POSTS_BY_VISUAL_SEARCH:
      return produce(state, draft => {
        draft.sortPostsByVisualSearch = action.payload;
      });

    case ACTIONS.CHOOSE_SAVED_FILTER:
      return produce(state, draft => {
        const selectedFilters = mapSelectedFilters(action.payload.filters);
        draft.selectedFilters = selectedFilters;
        draft.filterId = action.payload.id;
      });

    case ACTIONS.RESET_FILTERS:
      return produce(state, draft => {
        draft.selectedFilters = undefined;
        draft.sortPostsBy = {
          ...initialState?.sortPostsBy,
        };
        draft.filterId = undefined;
      });

    case ACTIONS.GET_SAVED_FILTERS.SUCCESS:
      return {
        ...state,
        filters: action.payload,
        isFetching: false,
      };

    case ACTIONS.REMOVE_SAVED_FILTERS.SUCCESS:
      return produce(state, draft => {
        draft.filters = draft.filters.filter(filter => filter.id !== action.payload);
        draft.isFetching = false;
      });

    case ACTIONS.UPDATE_SAVED_FILTERS.SUCCESS:
      return produce(state, draft => {
        draft.filters = draft.filters.map(filter => (filter.id === action.payload.id ? action.payload : filter));
        draft.isFetching = false;
      });

    case ACTIONS.POST_SAVED_FILTERS.SUCCESS:
      return {
        ...state,
        filters: [...state.filters, action.payload],
        isFetching: false,
      };

    default:
      return state;
  }
};

const removePostDuplicates = (data, socialFeeds = []) => {
  const existObject = {};
  const socialFeedsObj = {};

  socialFeeds?.map(e => {
    socialFeedsObj[e.id] = e;
  });

  return data.filter(e => {
    const post = e.attributes;
    const socialFeedId = e.relationships.social_feed.data.id;
    const source = socialFeedsObj[socialFeedId].attributes.source;
    let key = post?.external_id + post?.attachment_id + post?.source;

    switch (source) {
      case SocialAccountType.FORM_SUBMISSION:
      case SocialAccountType.TUMBLR:
      case SocialAccountType.LINKED_IN:
        key += post?.image_url;
        break;

      case SocialAccountType.INSTAGRAM:
      case SocialAccountType.INSTAGRAM_BUSINESS:
        key += post?.url;
        break;
    }

    if (existObject[key]) {
      return false;
    }
    existObject[key] = true;
    return true;
  });
};

const mapSelectedFilters = selectedFilters => {
  const mapActionsFilters = Object.keys(selectedFilters)
    .map(key => {
      if (actionFilterProps.includes(key)) {
        if (key === ActionFilterKeyProps.RIGHT_REQUEST_STATUS) {
          return selectedFilters[key] !== undefined ? selectedFilters[key] : undefined;
        } else if (
          key === ActionFilterValues.PINNED ||
          key === ActionFilterValues.NOT_PINNED ||
          key === ActionFilterValues.HIGHLIGHTED ||
          key === ActionFilterValues.NOT_HIGHLIGHTED ||
          key === ActionFilterValues.INCENTIVIZED ||
          key === ActionFilterValues.NOT_INCENTIVIZED
        ) {
          return selectedFilters[key] !== undefined ? (selectedFilters[key] ? key : `not-${key}`) : undefined;
        }
      } else if (key === ActionFilterKeyProps.RATING) {
        return selectedFilters[key] !== undefined
          ? selectedFilters[key]['$range']
            ? selectedFilters[key]['$range'].split(',')
            : undefined
          : undefined;
      }
    })
    .filter(Boolean)
    .flat()
    .sort((a, b) => (a > b ? 1 : -1));

  return [
    ...Object.keys(selectedFilters).map(key => {
      if (!actionFilterProps.includes(key)) {
        return {
          [key]:
            Object.keys(selectedFilters[key]).length > 0 || selectedFilters[key].length > 0
              ? selectedFilters[key]
              : undefined,
        };
      }
    }),
    { [FilterGroupTypes.ACTIONS]: mapActionsFilters },
  ]
    .filter(Boolean)
    .reduce((a, b) => ({ ...a, ...b }));
};

export const checkFilterChanges = state => {
  const currentFilter = state?.filters?.find(filter => filter.id === state.filterId);

  return (
    currentFilter &&
    JSON.stringify(mapSelectedFilters(currentFilter.attributes.filter)) !== JSON.stringify(state.selectedFilters)
  );
};

export const getCurrentSelectedFilter = state => {
  return state?.filters?.find(filter => filter.id === state.filterId);
};

export const getMappedSavedFilters = state => {
  return (
    state?.tintEditorPosts?.filters?.map(filter => ({
      id: filter.id,
      name: filter.attributes.name,
      filters: filter.attributes.filter,
    })) || []
  );
};

export const isSocialFeedPostFiltered = state => {
  return state && state.sortPostsBy?.social_feed_id;
};

export const isVisualSearchUsed = state => {
  return state && state?.tintEditorPosts?.sortPostsByVisualSearch;
};

export const getPost = (state, id) => {
  if (!state || !id) return;
  let currentPost = state.data.find(post => post.id === id);

  if (!currentPost) {
    const sibling = state.data.reduce((foundSibling, parent) => {
      const sibling = parent.siblings.find(s => s.id === id);
      if (sibling) {
        return sibling;
      }
      return foundSibling;
    }, undefined);

    currentPost = sibling;
  }

  return currentPost;
};

export const getProducts = (state, id) => {
  if (!state || !id) return;

  let currentPost = state?.data.find(post => post.id === id);

  if (!currentPost) {
    const sibling = state.data.reduce((foundSibling, parent) => {
      const sibling = parent.siblings.find(s => s.id === id);
      if (sibling) {
        return sibling;
      }
      return foundSibling;
    }, undefined);

    currentPost = sibling;
  }

  const mappedProductsTags =
    (state.productTags &&
      state.productTags.map(productTag => ({
        id: productTag.id,
        product: state.products.find(product => product.id === productTag.relationships.product.data.id),
      }))) ||
    [];

  const currentPostProducts = currentPost?.relationships?.product_tags?.data
    ?.map(productTag => {
      return mappedProductsTags.find(product => product.id === productTag.id);
    })
    .filter(el => el?.product.id)
    .filter(Boolean);

  const products =
    currentPostProducts &&
    currentPostProducts.map(el => ({
      ...el.product,
    }));

  return products;
};

export const getSocialFeed = (state, id) => {
  if (!state || !id) return;

  let currentPost = state?.data.find(post => post.id === id);

  if (!currentPost) {
    currentPost = state.data.reduce((foundSibling, parent) => {
      const sibling = parent.siblings.find(s => s.id === id);
      if (sibling) {
        return sibling;
      }
      return foundSibling;
    }, undefined);
  }

  const currentPostSocialFeed = state?.socialFeeds?.find(
    socialFeed => socialFeed.id === currentPost?.relationships?.social_feed?.data?.id
  );

  return currentPostSocialFeed;
};

export const getNextPost = (state, id) => {
  if (!state && !state.data) return;
  const indexOfElement = state.data.findIndex(post => post.id === id);

  if (
    state.data[indexOfElement] &&
    state.data[indexOfElement].siblings &&
    state.data[indexOfElement].siblings.length > 0
  ) {
    return state.data[indexOfElement].siblings[0];
  }

  if (indexOfElement === -1) {
    for (let i = 0; i < state.data.length; i++) {
      if (state.data[i].siblings && !(state.data[i].siblings.length > 0)) {
        continue;
      }
      const indexOfSibling = state.data[i].siblings.findIndex(sibling => sibling.id === id);
      if (indexOfSibling !== -1) {
        if (state.data[i].siblings.length > indexOfSibling + 1) {
          return state.data[i].siblings[indexOfSibling + 1];
        }

        if (state.data.length > i + 1) {
          return state.data[i + 1];
        }

        return state.data[0];
      }
    }
  }

  return state.data.length > indexOfElement + 1 ? state.data[indexOfElement + 1] : state.data[0];
};

export const getPrevPost = (state, id) => {
  if (!state && !state.data) return;
  const indexOfElement = state.data.findIndex(post => post.id === id);

  if (
    state.data[indexOfElement] &&
    state.data[indexOfElement].siblings &&
    state.data[indexOfElement].siblings.length > 0
  ) {
    return state.data[indexOfElement].siblings[state.data[indexOfElement].siblings.length - 1];
  }

  if (indexOfElement === -1) {
    for (let i = 0; i < state.data.length; i++) {
      if (state.data[i].siblings && !(state.data[i].siblings.length > 0)) {
        continue;
      }
      const indexOfSibling = state.data[i].siblings.findIndex(sibling => sibling.id === id);
      if (indexOfSibling !== -1) {
        if (indexOfSibling > 0) {
          return state.data[i].siblings[indexOfSibling - 1];
        }

        if (i > 0) {
          return state.data[i - 1];
        }

        return state.data[state.data.length - 1];
      }
    }
  }

  return indexOfElement > 0 ? state.data[indexOfElement - 1] : state.data[state.data.length - 1];
};

export const getActiveStars = value => {
  if (!value) return 0;

  return Math.ceil((parseInt(value) * MAX_STAR_AMOUNT) / 100);
};

export const getTintName = (state, tintId) =>
  state && state?.tintEditorPosts?.tints?.find(tint => tint.id === tintId)?.slugName;

export const getVisualSearchImage = state => state && state?.tintEditorPosts?.selectedFilters?.visually_similar_to?.[0];
export const isTagsListModalPostOpen = state => state && state?.tintEditorPosts?.tagListModalPostId !== undefined;
