import React, { useRef } from 'react';
import './dropdown-link-list.sass';
import { Link } from 'react-router-dom';
import useDropdownListPosition from '../../../../hooks/use-dropdown-list-position/use-dropdown-list-position';
import PropTypes from 'prop-types';
import TintButtonIcon, { TintButtonIconTypes } from '../../../button-icon/button-icon';

const DropdownLinkList = ({ onChange, parentRef, currentItems, children, topComponent }) => {
  const elementRef = useRef();
  const { positionHorizontal, positionVertical } = useDropdownListPosition(elementRef, parentRef);

  const renderLi = item => {
    return (
      <li
        className={`dropdown-list__item ${item.isActive ? 'active' : ''}`}
        onClick={() => {
          onChange && onChange(item);
        }}>
        {item.icon && typeof item.icon === 'string' ? (
          <span className={`icon ${item.icon}`} />
        ) : (
          item.icon && <TintButtonIcon type={TintButtonIconTypes.NONE} icon={item.icon} />
        )}
        <span>{item.name}</span>
        <span className='dropdown-list__description'>{item.description}</span>
      </li>
    );
  };

  const renderLink = (item, i) => {
    if (item.absoluteUrl) {
      return (
        <a href={item.href} key={i}>
          {' '}
          {renderLi(item)}{' '}
        </a>
      );
    }

    return item.href ? (
      <Link to={item.href} key={i}>
        {' '}
        {renderLi(item)}{' '}
      </Link>
    ) : (
      <a key={i}>{renderLi(item)}</a>
    );
  };
  return (
    <div
      ref={elementRef}
      data-testid='dropdown-click-list'
      className={`dropdown-list dropdown-list--${positionHorizontal} dropdown-list--${positionVertical} dropdown-list--click dropdown-list--wide dropdown-list--with-description`}>
      {topComponent && topComponent()}
      {currentItems && <ul>{currentItems.map(renderLink)}</ul>}
      {children}
    </div>
  );
};

export default DropdownLinkList;

DropdownLinkList.propTypes = {
  onChange: PropTypes.func,
  currentItems: PropTypes.array,
  parentRef: PropTypes.any,
  children: PropTypes.object,
};
