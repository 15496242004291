import React, { useEffect, useState } from 'react';
import './edit-form-builder-content.sass';
import { useHistory } from 'react-router-dom';
import '../../../../../../../components/sidebar/sidebar.sass';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { ApiService } from '../../../../../../../services/api-service/api-service';
import { DropdownHeader } from '../../../../../../../components/interface/dropdown/button/header/dropdown-header';
import DropdownButton, { ButtonType } from '../../../../../../../components/interface/dropdown/button/dropdown-button';
import { InputGroup } from '../../../../../../../components/interface/inputs/input-group/input-group';
import { formBuilderRoutePaths } from '../../../../../../form-builder/utils/form-builder.route-paths';
import DropdownSearchList from '../../../../../../../components/interface/dropdown/lists/search-list/dropdown-search-list';

const addFormBuilderElement = { name: '+ Add Form Builder', value: 'ADD_FORM_BUILDER' };
export const EditFormBuilderContent = ({ dataUpdate, data }) => {
  //TODO fetch form builder data and render it in the dropdown

  const history = useHistory();
  const [forms, setForms] = useState([]);
  const [currentItem, setCurrentItem] = useState(null);

  useEffect(() => {
    fetchFormBuilderData();
  }, []);

  const fetchFormBuilderData = () => {
    ApiService.get('/forms?page[size]=500&filter[published]=true')
      .then(e => {
        setForms(e.data.data.map(form => ({ name: form.attributes.name, value: form.id })));
        const id = data.content.formBuilderId;
        if (id) {
          let form = e.data.data.find(form => form.id === id);
          form = { name: form.attributes.name, value: form.id };
          setCurrentItem(form);
        }
      })
      .finally(() => {});
  };

  const onFormSelect = form => {
    if (form.value === addFormBuilderElement.value) {
      history.push(formBuilderRoutePaths.new.path);
      return;
    }
    const teamId = ApiService.getTeamId();
    dataUpdate({
      teamId,
      formBuilderId: form.value,
    });
  };

  return (
    <EditModalBody title='Connect your Form Builder by selecting a specific form from the list below.'>
      <InputGroup label='Form'>
        <DropdownButton
          placeholder={'Select Form'}
          currentItem={currentItem}
          dropdownHeader={props => <DropdownHeader {...props} />}
          dropdownList={props => <DropdownSearchList isDefaultOption={false} {...props} />}
          list={[...forms, addFormBuilderElement]}
          iconRight='fa fa-caret-down'
          buttonType={ButtonType.BUTTON_SORT}
          onChangeValue={onFormSelect}
        />
      </InputGroup>
    </EditModalBody>
  );
};
