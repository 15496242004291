import React from 'react';
import PropTypes from 'prop-types';
import './range-list.sass';
import usePercent from '../../../../../../../../components/hooks/use-percent/use-percent';
import useColumnSetter from '../../../../../../../../components/hooks/use-column-setter/use-column-setter';
import RangeColumn from './range-col/range-col';

const RangeList = function({ leftSideWidth, rightSideWidth, socialFeedLeft, socialFeedRight, isPreviewMode, styles }) {
  const { percentValue } = usePercent(leftSideWidth, rightSideWidth);

  const { columnClass } = useColumnSetter(percentValue.firstValue, percentValue.secondValue);

  const mapStyles = {
    leftSide: {
      backgroundColor: styles.backgroundColorPostLeft,
      backgroundColorRange: styles.backgroundRangeLeft,
      textColor: styles.textColorPostLeft,
    },
    rightSide: {
      backgroundColor: styles.backgroundColorPostRight,
      backgroundColorRange: styles.backgroundRangeRight,
      textColor: styles.textColorPostRight,
    },
  };

  const leftWidth = percentValue.firstValue ? `${percentValue.firstValue}%` : '50%';

  const rightWidth = percentValue.secondValue ? `${percentValue.secondValue}%` : '50%';

  return (
    <>
      <div className={`tint-range-list tint-range-list--${columnClass}`}>
        <div className='tint-range-list__left' style={{ width: leftWidth }}>
          <RangeColumn isPreviewMode={isPreviewMode} socialFeedPosts={socialFeedLeft} styles={mapStyles.leftSide} />
        </div>

        <div className='tint-range-list__right' style={{ width: rightWidth }}>
          <RangeColumn isPreviewMode={isPreviewMode} socialFeedPosts={socialFeedRight} styles={mapStyles.rightSide} />
        </div>
      </div>
    </>
  );
};

export default RangeList;

RangeList.propTypes = {
  leftSideWidth: PropTypes.number,
  rightSideWidth: PropTypes.number,
  socialFeedLeft: PropTypes.array,
  socialFeedRight: PropTypes.array,
  styles: PropTypes.any,
  isPreviewMode: PropTypes.bool,
};
