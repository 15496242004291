import { ACTIONS } from '../actions/media-composer.actions';
import { produce } from 'immer';
import { MediaComposerAddNetworkModalData } from '../components/modal/add-network/media-composer-add-network-modal.data';
import { SubmitType } from '../components/footer/overview-footer';

const defaultAddNetworkModalData = {
  step: MediaComposerAddNetworkModalData.MODAL_STEP.STEP_ONE,
  title: 'Add Network',
  type: '',
  isOpened: false,
};

const initialState = {
  accounts: [],
  form: {},
  addNetworkModal: { ...defaultAddNetworkModalData },
  submitType: SubmitType.NOW,
  selectedAccounts: [],
};

const mediaComposer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_DATA:
      return produce(state, draftState => {
        draftState.form = action.payload.form;
        draftState.isDuplicate = action.payload.isDuplicate;
        draftState.selectedAccounts = action.payload.selectedAccounts;
      });

    case ACTIONS.REMOVE_PUBLISHED_AT_FORM_DATA:
      return produce(state, draftState => {
        delete draftState.form.published_at;
        return draftState;
      });

    case ACTIONS.SET_SUBMIT_TYPE:
      return { ...state, submitType: action.payload };

    case ACTIONS.GET_ACCOUNTS.SUCCESS:
      return { ...state, accounts: action.payload };

    case ACTIONS.GET_ASSET.SUCCESS:
      return {
        ...state,
        asset: action.payload.data.data,
      };

    case ACTIONS.CLEAR_STATE:
      return initialState;

    case ACTIONS.UPDATE_FORM:
      return produce(state, draftState => {
        draftState.form[action.payload.field] = action.payload.value;
        return draftState;
      });

    case ACTIONS.OPEN_ADD_NETWORK_MODAL:
      return { ...state, addNetworkModal: { ...state.addNetworkModal, isOpened: true } };

    case ACTIONS.CLOSE_ADD_NETWORK_MODAL:
      return { ...state, addNetworkModal: { ...defaultAddNetworkModalData } };

    case ACTIONS.SET_ADD_NETWORK_MODAL_DATA:
      return { ...state, addNetworkModal: { ...state.addNetworkModal, ...action.payload } };

    case ACTIONS.SET_ADD_NETWORK_MODAL_DATA_OPTIONS:
      return produce(state, draftState => {
        draftState.addNetworkModal.options = { ...draftState.addNetworkModal.options, ...action.payload };
        return draftState;
      });

    case ACTIONS.REMOVE_SELECTED_ACCOUNT:
      return produce(state, draftState => {
        draftState.selectedAccounts.splice(action.payload, 1);
        return draftState;
      });

    case ACTIONS.ADD_SELECTED_ACCOUNT:
      return produce(state, draftState => {
        draftState.selectedAccounts.push(action.payload);
        return draftState;
      });

    default:
      return state;
  }
};

export default mediaComposer;
