import { ACTIONS } from '../../actions/teams/teams.actions';

export const initialState = {
  data: undefined,
  error: undefined,
  isPending: false,
  selectedTeamIndex: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_TEAMS.FAILURE:
    case ACTIONS.PATCH_TEAM.FAILURE:
      return {
        ...state,
        error: action.payload,
        isPending: false,
      };

    case ACTIONS.GET_TEAMS.REQUEST:
    case ACTIONS.PATCH_TEAM.REQUEST:
      return {
        ...state,
        isPending: true,
      };

    case ACTIONS.GET_TEAMS.SUCCESS:
      return {
        ...state,
        data: action.payload,
        isPending: false,
        error: undefined,
      };

    case ACTIONS.PATCH_TEAM.SUCCESS:
      return {
        ...state,
        data: {
          data: state.data.data.map(e => (e.id === action.payload.data.id ? action.payload.data : e)),
        },
        isPending: false,
        error: undefined,
      };
    default:
      return state;
  }
};

export const getSelectedTeam = state => {
  return state.data ? state.data.data[state.selectedTeamIndex] : undefined;
};
