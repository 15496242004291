import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { EditInput } from '../../../../';
import PropTypes from 'prop-types';

export class EditCustomButtonSection extends Component {
  render() {
    return (
      <EditModalBody title='Customize the content of your button'>
        <EditInput
          onChange={e => {
            this.props.onTranslationUpdate(this.props.data.buttonText, e.target.value);
          }}
          label={'Button text'}
          placeholder='Submit'
          defaultValue={this.props.getTranslation(this.props.data.buttonText)}
        />

        <EditInput
          onChange={e => {
            this.props.onTranslationUpdate(this.props.data.buttonUrl, e.target.value);
          }}
          label={'Button url'}
          placeholder='Enter URL'
          defaultValue={this.props.getTranslation(this.props.data.buttonUrl)}
        />

        <EditInput
          onChange={e => {
            this.props.dataUpdate({ sectionHeight: e.target.value });
          }}
          label={'Section height'}
          placeholder='230'
          value={this.props.data.sectionHeight}
        />
      </EditModalBody>
    );
  }
}

EditCustomButtonSection.propTypes = {
  onTranslationUpdate: PropTypes.func,
  getTranslation: PropTypes.func,
  dataUpdate: PropTypes.func,
  onStylesUpdate: PropTypes.func,
  closeModal: PropTypes.func,
  onDataSave: PropTypes.func,
  dismissModal: PropTypes.func,
  data: PropTypes.shape({
    buttonUrl: PropTypes.string,
    buttonText: PropTypes.string,
  }),
};
