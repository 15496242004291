import { COMMON_SETTINGS } from './common-settings';
import { languageList } from '../configs/personalization-languages';

export const MOSAIC = {
  name: 'Mosaic',
  key: 'hypeTemplate_mosaic',
  premiumDesign: true,
  default_settings: {
    ...COMMON_SETTINGS,
    themeName: 'hypeTemplate_mosaic',
    postTransitionSpeed: '8000',
    postHighlightDuration: '8000',
  },
  guards: {
    CTA: true,
    waterfall: false,
    popup: true,
    shareIcons: true,
    removeDuplicateImages: true,
    genericAvatarBackgroundColor: true,
    genericAvatarTextColor: true,
    customCSS: true,
    backgroundImage: true,
    banner: true,
  },
  themes_settings: [
    {
      key: 'postTransitionSpeed',
      label: 'Highlight Transition Speed',
      type: 'dropdown',
      options: [
        {
          value: '4000',
          name: '4 seconds',
        },
        {
          value: '6000',
          name: '6 seconds',
        },
        {
          value: '8000',
          name: '8 seconds',
        },
        {
          value: '10000',
          name: '10 seconds',
        },
        {
          value: '15000',
          name: '15 seconds',
        },
        {
          value: '30000',
          name: '30 seconds',
        },
        {
          value: '60000',
          name: '60 seconds',
        },
      ],
    },
    {
      key: 'postHighlightDuration',
      label: 'Post Highlighted Duration',
      type: 'dropdown',
      options: [
        {
          value: '4000',
          name: '4 seconds',
        },
        {
          value: '6000',
          name: '6 seconds',
        },
        {
          value: '8000',
          name: '8 seconds',
        },
        {
          value: '10000',
          name: '10 seconds',
        },
        {
          value: '15000',
          name: '15 seconds',
        },
        {
          value: '30000',
          name: '30 seconds',
        },
        {
          value: '60000',
          name: '60 seconds',
        },
      ],
    },
  ],
  font_settings: [
    {
      key: 'fontcolor_post',
      label: 'Font Color',
      type: 'colorPicker',
    },
    {
      key: 'fontsize_secondary',
      type: 'slider',
      label: 'Font size',
      min: '8',
      max: '50',
    },
    {
      key: 'color_namebar_buttons',
      label: 'Accent Color',
      type: 'colorPicker',
    },
  ],
  extra_settings: [
    {
      key: 'time_language',
      type: 'dropdown',
      label: 'Language for post timestamp',
      options: languageList,
    },
    {
      key: 'highlightVideoPlayback',
      label: 'Highlight Video playback',
      type: 'dropdown',
      options: [
        {
          name: "Don't Auto Play Video",
          value: 'dont-autoplay',
        },
        {
          name: 'Auto Play Entire Video',
          value: 'play-entire-video',
        },
        {
          name: 'Auto Play Video to transition',
          value: 'play-to-transition',
        },
      ],
    },
  ],
  extra_popup_settings: [
    // {
    //   key: 'popup_products_visibility',
    //   label: 'Hide Product Hotspots',
    //   control_type: 'Checkbox',
    //   target: '#personalize-popup-controls',
    //   add_class: 'control-divide-above',
    //   required_flags: ['ecommerce'],
    // },
  ],
};
