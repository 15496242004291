import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './overview-footer.sass';
import { useParams } from 'react-router-dom';
import { Button } from '../../../../components/interface/button/button';
import { DropdownHeader } from '../../../../components/interface/dropdown/button/header/dropdown-header';
import DropdownButton, { ButtonType } from '../../../../components/interface/dropdown/button/dropdown-button';
import DropdownClickList from '../../../../components/interface/dropdown/lists/click-list/dropdown-click-list';
import { removePublishedAtFormData, setSubmitType, updateFormData } from '../../actions/media-composer.actions';
import { MediaComposerValidatorService } from '../../services/media-composer-validator.service';
import FeatureGuard from '../../../../guards/feature-guard';
import { GuardNames } from '../../../../guards/guards-list';
import PreloaderComponent, {
  PreloaderBackdropType,
  PreloaderPositionType,
} from '../../../../components/interface/preloaders/preloader/preloader';
import { NETWORK_TYPE } from '../modal/add-network/media-composer-add-network-modal.data';

export const SubmitType = {
  SCHEDULE: 'SCHEDULE',
  NOW: 'NOW',
};

export const OverviewFooter = ({ onSubmit, onClose, isMediaComposer, isEditMode, isValid }) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const { assetId } = useParams();
  const dispatch = useDispatch();
  const mediaComposerData = useSelector(state => state.mediaComposer);
  const isFetching = useSelector(state => state.socialPublishingCalendar.isPartialFetching);
  const form = mediaComposerData.form;
  const submitType = mediaComposerData.submitType;
  const isThreadsNetwork = mediaComposerData?.selectedAccounts?.[0]?.type === NETWORK_TYPE.THREADS;
  const isValidNetworkLimit =
    mediaComposerData?.selectedAccounts.length > 0 && mediaComposerData?.selectedAccounts.length <= 3;
  const isValidMedia = form?.media?.filter(Boolean)?.length > 0;
  const isValidThreadPost = isValidNetworkLimit && (form?.text || isValidMedia);

  useEffect(() => {
    setIsButtonDisabled(isButtonDisabledFn());
  }, [mediaComposerData.form]);

  const _onSubmit = () => {
    const timeNow = new Date(Date.now());
    const currentDate = timeNow.setSeconds(timeNow.getSeconds() + 10);

    const data = mediaComposerData.selectedAccounts.map(e => {
      const obj = {
        account_id: e.account_id,
        ...form,
        asset_id: assetId,
        media: mediaComposerData?.form?.media?.filter(Boolean) || [],
        campaign_id: mediaComposerData.form.campaignId,
        campaignId: undefined,
        published_at: mediaComposerData.submitType === SubmitType.NOW ? new Date(currentDate) : form.published_at,
      };
      if (e.subaccount_id) {
        obj.subaccount_id = e.subaccount_id;
      }

      //---tiktok
      if (mediaComposerData?.form?.privacy) {
        obj.privacy = mediaComposerData?.form?.privacy;
      }
      if (mediaComposerData?.form?.options) {
        obj.options = mediaComposerData?.form?.options;
      }
      //---tiktok
      return obj;
    });

    onSubmit(isMediaComposer ? data : data[0], isEditMode, submitType);
  };

  const renderSubmitButton = () => {
    let text = '';
    switch (submitType) {
      case SubmitType.SCHEDULE:
        text = 'Schedule For Later';
        break;
      case SubmitType.NOW:
      default:
        text = 'Post now';
    }

    return <Button isDisabled={isButtonDisabled} text={text} type='info' onClick={_onSubmit} />;
  };

  const setSubmitButton = ({ value }) => {
    switch (value) {
      case SubmitType.SCHEDULE:
        dispatch(updateFormData({ value: new Date().toISOString(), field: 'publish_at' }));
        break;
      case SubmitType.NOW:
      default:
        dispatch(removePublishedAtFormData());
    }
    dispatch(setSubmitType(value));
  };

  const isButtonDisabledFn = () => {
    return !MediaComposerValidatorService.isScheduleBetweenLimit(form.date);
  };

  const isFormValid = isThreadsNetwork
    ? isValidThreadPost
    : form?.text && isValid && isValidMedia && isValidNetworkLimit;

  return (
    <div className='tint-overview-footer'>
      <Button text='Cancel' type='transparent' onClick={onClose} />

      <FeatureGuard featureName={GuardNames.ASSET_MARKETING_INTEGRATION}>
        {isMediaComposer ? (
          <div className='tint-overview-footer__right'>
            {renderSubmitButton()}
            <DropdownButton
              disabled={isButtonDisabled}
              dropdownHeader={props => <DropdownHeader {...props} dropdownHeaderStyle={{ justifyContent: 'center' }} />}
              dropdownList={props => <DropdownClickList expandToTop={true} {...props} />}
              list={[
                { value: SubmitType.SCHEDULE, name: 'Schedule For Later' },
                { value: SubmitType.NOW, name: 'Post now' },
              ]}
              iconCenter='fa fa-caret-down'
              buttonType={ButtonType.BUTTON_ACTIVE}
              onChangeValue={setSubmitButton}
            />
          </div>
        ) : (
          <Button className='edit-mode' isDisabled={!isFormValid || isFetching} type='info' onClick={_onSubmit}>
            {isFetching && (
              <PreloaderComponent
                positionType={PreloaderPositionType.ABSOLUTE}
                backdropType={PreloaderBackdropType.TRANSPARENT}
              />
            )}
            {isEditMode ? 'Update' : 'Post'}
          </Button>
        )}
      </FeatureGuard>
    </div>
  );
};
