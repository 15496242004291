import React from 'react';
import './sidebar-header.sass';

export const SidebarHeader = ({ title, onIconCLick }) => {
  return (
    <header className='tint-sidebar-header'>
      {title && <h3>{title}</h3>}
      {onIconCLick && <span className='icon fas fa-times' onClick={onIconCLick} />}
    </header>
  );
};
