import React, { Component } from 'react';
import './edit-area.sass';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const renderTitle = title => {
  return (
    <div className='tint-edit-box__title-container'>
      <span>{title}</span>
    </div>
  );
};

class EditArea extends Component {
  delete = () => {
    if (!this.props.isDeletable) {
      return;
    }
    this.props.deleteSection();
  };

  renderDelete = () => {
    return (
      <div
        data-testid='deleteAreaButton'
        onClick={this.delete}
        className={`tint-edit-box__item ${!this.props.isDeletable ? 'tint-edit-box__item--disabled' : ''}`}>
        {this.props.isDeletable ? renderTitle('Delete') : renderTitle("Can't Remove")}
        <span data-testid='deleteAreaIcon' className={`fas fa-${this.props.isDeletable ? 'trash' : 'ban'}`} />
      </div>
    );
  };

  renderReorderButton = () => {
    return (
      this.props.isReorderAble && (
        <div data-testid='reorderAreaButton' onClick={this.props.openOrderModal} className={'tint-edit-box__item'}>
          {renderTitle('Reorder')}
          <span className='icon fas fa-exchange-alt' />
        </div>
      )
    );
  };

  render() {
    return (
      <div className='tint-edit-box'>
        <div className='tint-edit-box__nav'>
          <div className='tint-edit-box__title'>
            <span data-testid='editBoxTitle'>{this.props.title.replace(/_/g, ' ')}</span>
          </div>
          <div className='tint-edit-box__flex-container'>
            <div onClick={this.props.openModal} className='tint-edit-box__item' data-testid='editAreaButton'>
              {renderTitle('Edit')}
              <span className='fas fa-pen' />
            </div>
            {this.renderReorderButton()}
            {this.renderDelete()}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  session: state.session.data,
});

export default connect(mapStateToProps)(EditArea);

EditArea.propTypes = {
  openOrderModal: PropTypes.func,
  openModal: PropTypes.func,
  title: PropTypes.string,
  deleteSection: PropTypes.func,
  isDeletable: PropTypes.bool,
  isReorderAble: PropTypes.bool,
};
