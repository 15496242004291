import { languageList } from '../configs/personalization-languages';

export const ARCHIVED_GEM = {
  name: 'Archived Gem',
  key: 'gem',
  premiumDesign: true,
  default_settings: {
    themeName: 'gem',
    header: '#HASHTAG',
    header_position: 'left',
    strip_position: 'bottom',
    connected_hashtag_highlight_color: '#cc44ee',
    postwidth: '300',
    postpadding: '10',
    fontcolor_post: '#000000',
    wavedirection: 'up',
    sliderpadding: 2,
    sliderwidth: 150,
    shape: 'rounded',
    gemamount: 'little',
    gem_highlight_duration: '0',
    colors: ['#ff564b', '#ff6c54', '#2fc2c7', '#50d3d8', '#d74743'],
    show_author: 1,
    remove_duplicate_images: true,
    time_language: 'en',
    generic_avatar_text_color: '#ffffff',
    generic_avatar_background_color: '#517fa4',
  },
  guards: {
    CTA: false,
    waterfall: false,
    popup: false,
    shareIcons: false,
    removeDuplicateImages: true,
    genericAvatarBackgroundColor: true,
    genericAvatarTextColor: true,
    customCSS: true,
    backgroundImage: true,
    banner: true,
  },
  themes_settings: [
    {
      key: 'wavedirection',
      label: 'Wave Direction',
      type: 'switcher',
      values: [
        {
          value: 'up',
          label: 'Up',
        },
        {
          value: 'down',
          label: 'Down',
        },
        {
          value: 'right',
          label: 'Right',
        },
        {
          value: 'left',
          label: 'Left',
        },
      ],
    },
    {
      key: 'gem_highlight_duration',
      label: 'Post Highlight Duration',
      type: 'dropdown',
      options: [
        {
          value: '0',
          name: 'No Highlights',
        },
        {
          value: '4000',
          name: '4 seconds',
        },
        {
          value: '6000',
          name: '6 seconds',
        },
        {
          value: '8000',
          name: '8 seconds',
        },
        {
          value: '10000',
          name: '10 seconds',
        },
        {
          value: '15000',
          name: '15 seconds',
        },
        {
          value: '30000',
          name: '30 seconds',
        },
        {
          value: '60000',
          name: '60 seconds',
        },
      ],
    },
    {
      key: 'shape',
      label: 'Post Shape',
      type: 'switcher',
      values: [
        {
          value: 'circle',
          label: 'Circle',
        },
        {
          value: 'rounded',
          label: 'Rounded',
        },
        {
          value: 'square',
          label: 'Square',
        },
      ],
    },
    {
      key: 'gemamount',
      label: 'Gem Amount',
      type: 'switcher',
      values: [
        {
          value: 'none',
          label: 'None',
        },
        {
          value: 'little',
          label: 'A little',
        },
        {
          value: 'lots',
          label: 'A lot',
        },
      ],
    },
    {
      key: 'colors',
      type: 'dropdownColorPalette',
      label: 'Color Palette',
      options: [
        ['#ff564b', '#ff6c54', '#2fc2c7', '#50d3d8', '#d74743'],
        ['#d74743', '#fdb35c', '#5bc36f', '#4598ba', '#a36ec9'],
        ['#000000', '#3a3a3a', '#6e6e6e', '#a0a0a0', '#bbbbbb'],
        ['#244c21', '#397f31', '#78aa5d', '#c9de8e', '#b2ca69'],
        ['#345b62', '#55797f', '#a9daaa', '#7bbc9b', '#3e8685'],
        ['#c52733', '#e13441', '#f3644f', '#fd9c61', '#facc84'],
        ['#19999e', '#54cdc3', '#b5ea61', '#fd6c6e', '#c24e5a'],
        ['#34132f', '#424253', '#65908a', '#e7caa6', '#ca2c44'],
        ['#6dd2e6', '#a9dbd8', '#c8e2d3', '#f1863c', '#f86920'],
      ],
    },
  ],
  font_settings: [],
  extra_settings: [
    {
      key: 'time_language',
      type: 'dropdown',
      label: 'Language for post timestamp',
      options: languageList,
    },
    {
      key: 'playvideo',
      type: 'checkbox',
      label: 'Play Videos when Highlighted',
    },
    {
      key: 'display_text_on_text_posts',
      type: 'checkbox',
      label: 'Display text from Text Only posts',
    },
    {
      key: 'sliderwidth',
      type: 'slider',
      label: 'Minimum Post Width',
      min: '100',
      max: '500',
    },
    {
      key: 'sliderpadding',
      type: 'slider',
      label: 'Padding',
      min: '0',
      max: '50',
    },
  ],
  extra_popup_settings: [],
};
