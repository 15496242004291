import React, { useRef } from 'react';
import './dropdown-simple-list.sass';
import useDropdownListPosition from '../../../../hooks/use-dropdown-list-position/use-dropdown-list-position';
import PropTypes from 'prop-types';
import { DropdownHeader } from '../../button/header/dropdown-header';
import TintButtonIcon, { TintButtonIconTypes } from '../../../button-icon/button-icon';
import useSelectListElement from '../../../autocomplete-list/useSelectListElement';

const DropdownSimpleList = ({
  onChange,
  parentRef,
  onChangeTextComponent,
  iconLeft,
  iconRight,
  iconCenter,
  currentItems,
  title,
  isDefaultOption = true,
  defaultOptionTitle = 'All',
  dropdownHeaderStyle,
  rerenderHeader,
}) => {
  const elementRef = useRef();
  const { positionHorizontal, positionVertical } = useDropdownListPosition(elementRef, parentRef);

  const { currentElement, isSelected } = useSelectListElement({
    listLength: currentItems?.length,
    onEnterClick: i => {
      currentItems?.[i] ? onSelect?.(currentItems[i]) : i === -1 && onDefaultOptionSelect();
    },
    isDefaultOption,
  });

  const DropdownTextElement = title => {
    return (
      <DropdownHeader
        iconLeft={iconLeft}
        iconRight={iconRight}
        iconCenter={iconCenter}
        title={title}
        style={dropdownHeaderStyle}
      />
    );
  };

  const onSelect = item => {
    onChange(item);
    rerenderHeader && onChangeTextComponent(DropdownTextElement(item.name));
  };

  const onDefaultOptionSelect = () => {
    onChange('');
    rerenderHeader && onChangeTextComponent(DropdownTextElement(title));
  };

  return (
    <>
      <ul
        className={`dropdown-list dropdown-list--${positionHorizontal} dropdown-list--${positionVertical}`}
        ref={elementRef}
        data-testid='dropdown-simple-list'>
        {isDefaultOption && (
          <li
            className={`dropdown-list__item ${currentElement === 0 ? 'dropdown-list__item--selected' : ''}`}
            onClick={onDefaultOptionSelect}>
            <span>{defaultOptionTitle}</span>
          </li>
        )}
        {currentItems &&
          currentItems.map((item, i) => (
            <li
              className={`dropdown-list__item ${item.isActive ? 'active' : ''} ${
                isSelected(i) ? 'dropdown-list__item--selected' : ''
              } ${item?.customClassName ? item.customClassName : ''} 
            ${i}`}
              key={i}
              onClick={() => onSelect(item)}>
              {item.icon && typeof item.icon === 'string' ? (
                <span className={`icon ${item.icon}`} />
              ) : (
                item.icon && <TintButtonIcon type={TintButtonIconTypes.NONE} icon={item.icon} />
              )}
              <span>{item.name}</span>
            </li>
          ))}
      </ul>
    </>
  );
};

export default DropdownSimpleList;

DropdownSimpleList.propTypes = {
  onChange: PropTypes.func,
  currentItems: PropTypes.array,
  parentRef: PropTypes.any,
  onChangeTextComponent: PropTypes.func,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconCenter: PropTypes.string,
};
