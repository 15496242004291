import { ACTIONS } from '../actions/tint-editor.actions';

export const initialState = {
  id: undefined,
  isOpen: false,
  data: undefined,
  name: '',
  slug: '',
  private: '',
  analytics: '',
  actionName: '',
  personalizations: '',
  defaultPersonalizationId: '',
};

export const tintEditorSidebar = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_SIDEBAR:
      return {
        ...state,
        ...{
          id: action.data.id,
          data: action.data.data,
          actionName: action.data.actionName,
          isOpen: true,
          name: action.data.name,
          slug: action.data.slug,
          private: action.data.private,
          analytics: action.data.analytics,
          personalizations: action.data.personalizations,
          defaultPersonalizationId: action.data.defaultPersonalizationId,
        },
      };
    case ACTIONS.UPDATE_SIDEBAR_INPUT:
      return {
        ...state,
        [action.data.field]: action.data.value,
      };
    case ACTIONS.CLOSE_SIDEBAR:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};
