import { Config } from '../../utils/config';
import * as filestack from 'filestack-js';
/* eslint-disable */

export class FileStack {
  constructor(apiKey = Config.fileStackApiKey.general()) {
    this.client = filestack.init(apiKey);
  }

  getClient = () => this.client;
  openFilePicker = options =>
    this.client
      .picker({
        onFileSelected: file => {
          const maxFileSize = options?.maxSize || Config.maxFileSize;
          const maxVideoFileSize = options?.maxSize || Config.maxVideoFileSize;

          if (file.mimetype.includes('image') && file.size > maxFileSize) {
            const fileMbSize = this.convertBytesToMegabytes(maxFileSize);

            throw new Error(`File too big, select an image smaller than ${fileMbSize}MB`);
          }

          if (file.mimetype.includes('video') && file.size > maxVideoFileSize) {
            const videoFileMbSize = this.convertBytesToMegabytes(maxVideoFileSize);

            throw new Error(`File too big, select a video smaller than ${videoFileMbSize}MB`);
          }
        },
        ...options,
      })
      .open();
  metadata = (handle, options) => this.client.metadata(handle, options);

  convertBytesToMegabytes = value => {
    if (value && typeof value === 'number') {
      return value / 1024 / 1024;
    }
  };
}
