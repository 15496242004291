import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { EditUploadDesign } from '../../../../index';
import { RangeInput } from '../../../../../../../../components/interface/inputs/range-input/range-input';

export class VideoStyles extends Component {
  render() {
    return (
      <EditModalBody title='Customize this section.'>
        <RangeInput
          value={this.props.data.styles.videoWidth}
          onChange={e => {
            this.props.styleDataUpdate({ videoWidth: e });
          }}
          step={'1'}
          minRange={'1'}
          maxRange={'100'}
          label={'Video width'}
          unit={'%'}
        />
        <EditUploadDesign
          dataUpdate={this.props.dataUpdate}
          styleDataUpdate={this.props.styleDataUpdate}
          data={this.props.data}
        />
      </EditModalBody>
    );
  }
}
