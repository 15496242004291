import { createPrefixedActionName, createRoutine } from '../../../services/functions/redux-routines/redux-routines';

export const ACTIONS = {
  SELECT_CATEGORY: createPrefixedActionName('DISCOVER', 'SELECT_CATEGORY'),
  GET_RECENT_TINT_CONTENT: createRoutine(createPrefixedActionName('DISCOVER', 'GET_RECENT_TINT_CONTENT')),
  GET_CONTENT_COLLECTED: createRoutine(createPrefixedActionName('DISCOVER', 'GET_CONTENT_COLLECTED')),
  GET_RIGHTS_REQUEST_METRICS: createRoutine(createPrefixedActionName('DISCOVER', 'GET_RIGHTS_REQUEST_METRICS')),
  GET_RIGHTS_APPROVED_METRICS: createRoutine(createPrefixedActionName('DISCOVER', 'GET_RIGHTS_APPROVED_METRICS')),

  GET_COMMUNITY_GENERATED_CONTENT: createRoutine(
    createPrefixedActionName('DISCOVER', 'GET_COMMUNITY_GENERATED_CONTENT')
  ),
  GET_COMMUNITY_GENERATED_PREV_CONTENT: createRoutine(
    createPrefixedActionName('DISCOVER', 'GET_COMMUNITY_GENERATED_PREV_CONTENT')
  ),
  GET_COMMUNITY_GENERATED_CONTENT_BREAKDOWN: createRoutine(
    createPrefixedActionName('DISCOVER', 'GET_COMMUNITY_GENERATED_CONTENT_BREAKDOWN')
  ),
  GET_TOTAL_COMMUNITY_MEMBERS_CURRENT: createRoutine(
    createPrefixedActionName('DISCOVER', 'GET_TOTAL_COMMUNITY_MEMBERS_CURRENT')
  ),
  GET_TOTAL_COMMUNITY_MEMBERS_PREV_COUNT: createRoutine(
    createPrefixedActionName('DISCOVER', 'GET_TOTAL_COMMUNITY_MEMBERS_PREV_COUNT')
  ),
  POST_MISSION: createRoutine(createPrefixedActionName('DISCOVER', 'POST_MISSION')),
  GET_MISSION: createRoutine(createPrefixedActionName('DISCOVER', 'GET_MISSION')),
};

export const selectCategory = payload => ({
  type: ACTIONS.SELECT_CATEGORY,
  payload,
});

export const getContentCollectedStart = () => ({
  type: ACTIONS.GET_CONTENT_COLLECTED.REQUEST,
});

export const getContentCollectedSuccess = data => ({
  type: ACTIONS.GET_CONTENT_COLLECTED.SUCCESS,
  payload: data,
});

export const getContentCollectedError = err => ({
  type: ACTIONS.GET_CONTENT_COLLECTED.FAILURE,
  payload: err,
});

export const getRecentTintContentStart = () => ({
  type: ACTIONS.GET_RECENT_TINT_CONTENT.REQUEST,
});

export const getRecentTintContentSuccess = data => ({
  type: ACTIONS.GET_RECENT_TINT_CONTENT.SUCCESS,
  payload: data,
});

export const getRecentTintContentError = err => ({
  type: ACTIONS.GET_RECENT_TINT_CONTENT.FAILURE,
  payload: err,
});

export const getRightsRequestMetricsStart = () => ({
  type: ACTIONS.GET_RIGHTS_REQUEST_METRICS.REQUEST,
});

export const getRightsRequestMetricsSuccess = data => ({
  type: ACTIONS.GET_RIGHTS_REQUEST_METRICS.SUCCESS,
  payload: data,
});

export const getRightsRequestMetricsError = err => ({
  type: ACTIONS.GET_RIGHTS_REQUEST_METRICS.FAILURE,
  payload: err,
});

export const getRightsApprovedMetricsStart = () => ({
  type: ACTIONS.GET_RIGHTS_APPROVED_METRICS.REQUEST,
});

export const getRightsApprovedMetricsSuccess = data => ({
  type: ACTIONS.GET_RIGHTS_APPROVED_METRICS.SUCCESS,
  payload: data,
});

export const getRightsApprovedMetricsError = err => ({
  type: ACTIONS.GET_RIGHTS_APPROVED_METRICS.FAILURE,
  payload: err,
});

export const getCommunityGeneratedContentCountStart = () => ({
  type: ACTIONS.GET_COMMUNITY_GENERATED_CONTENT.REQUEST,
});

export const getCommunityGeneratedContentCountSuccess = data => ({
  type: ACTIONS.GET_COMMUNITY_GENERATED_CONTENT.SUCCESS,
  payload: data,
});

export const getCommunityGeneratedContentCountError = err => ({
  type: ACTIONS.GET_COMMUNITY_GENERATED_CONTENT.FAILURE,
  payload: err,
});

export const getCommunityGeneratedContentPrevCountStart = () => ({
  type: ACTIONS.GET_COMMUNITY_GENERATED_PREV_CONTENT.REQUEST,
});

export const getCommunityGeneratedContentPrevCountSuccess = data => ({
  type: ACTIONS.GET_COMMUNITY_GENERATED_PREV_CONTENT.SUCCESS,
  payload: data,
});

export const getCommunityGeneratedContentPrevCountError = err => ({
  type: ACTIONS.GET_COMMUNITY_GENERATED_PREV_CONTENT.FAILURE,
  payload: err,
});

export const getCommunityGeneratedContentBreakdownStart = () => ({
  type: ACTIONS.GET_COMMUNITY_GENERATED_CONTENT_BREAKDOWN.REQUEST,
});

export const getCommunityGeneratedContentBreakdownSuccess = data => ({
  type: ACTIONS.GET_COMMUNITY_GENERATED_CONTENT_BREAKDOWN.SUCCESS,
  payload: data,
});

export const getCommunityGeneratedContentBreakdownError = err => ({
  type: ACTIONS.GET_COMMUNITY_GENERATED_CONTENT_BREAKDOWN.FAILURE,
  payload: err,
});

export const getTotalCommunityMembersPrevCountStart = () => ({
  type: ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_PREV_COUNT.REQUEST,
});

export const getTotalCommunityMembersPrevCountSuccess = data => ({
  type: ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_PREV_COUNT.SUCCESS,
  payload: data,
});

export const getTotalCommunityMembersPrevCountError = err => ({
  type: ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_PREV_COUNT.FAILURE,
  payload: err,
});

export const getTotalCommunityMembersStart = () => ({
  type: ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_CURRENT.REQUEST,
});

export const getTotalCommunityMembersSuccess = data => ({
  type: ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_CURRENT.SUCCESS,
  payload: data,
});

export const getTotalCommunityMembersError = err => ({
  type: ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_CURRENT.FAILURE,
  payload: err,
});

export const postMissionStart = () => ({
  type: ACTIONS.POST_MISSION.REQUEST,
});

export const postMissionSuccess = data => ({
  type: ACTIONS.POST_MISSION.SUCCESS,
  payload: data,
});

export const postMissionError = err => ({
  type: ACTIONS.POST_MISSION.FAILURE,
  payload: err,
});

export const getMissionStart = () => ({
  type: ACTIONS.GET_MISSION.REQUEST,
});

export const getMissionSuccess = data => ({
  type: ACTIONS.GET_MISSION.SUCCESS,
  payload: data,
});

export const getMissionError = err => ({
  type: ACTIONS.GET_MISSION.FAILURE,
  payload: err,
});
