import { SidebarIcons, SidebarItemTypes } from '../utils/meta/consts';
import { SocialFeedsFooterLabels } from './helpers';
import { validationSchemaGoogleNewsSearchTerm } from '../utils/validation-schema/validation-google';
import { languagesDropdown } from '../utils/common/common';

const mapListForDropdown = list => Object.values(list).map(list => list);

export const googleNews = [
  {
    header: 'Add Google News',
    accountSourceType: 'google_news',
    selectedOption: { id: 'keyword', title: 'Add Google News' },
    description: 'Enter search term and select a language to ingest Google News content.',
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext,
    },
    zendeskUrl: undefined,
    isAlert: true,
    formSchema: {
      keyword: {
        id: 'keyword',
        title: 'Search Term',
        submitMapper: values => ({
          search_term: values.keyword,
          options: {
            lang: values.language.value,
          },
        }),
        validationSchema: validationSchemaGoogleNewsSearchTerm(),
        inputs: [
          {
            id: 'keyword',
            backendId: 'search_term',
            label: 'ENTER SEARCH TERM',
            placeholder: 'Term',
            icon: SidebarIcons.SEARCH,
            type: SidebarItemTypes.TEXT,
            lineBreak: true,
          },
          {
            id: 'language',
            backendId: 'lang',
            label: 'SELECT LANGUAGE',
            placeholder: 'Select Language...',
            icon: SidebarIcons.GLOBE,
            type: SidebarItemTypes.SELECT,
            defaultList: mapListForDropdown(languagesDropdown),
          },
        ],
      },
    },
  },
];
