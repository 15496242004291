import { getFooter, getSocial, getHeader, getTagOWar } from '../shared.data';
import colors from '../colors';

const currentDate = new Date();
const yesterday = new Date(currentDate.setDate(currentDate.getDate() - 1));
const tomorrow = new Date(currentDate.setDate(currentDate.getDate() + 2));

export const tagOWarContentLeftSide = {
  socialFeedLeft: {
    label: '#hashtag1',
    value: '',
    name: '',
  },
  leftSidePosts: [],
  percentLeft: 0,
};

export const tagOWarContentRightSide = {
  socialFeedRight: {
    label: '#hashtag2',
    value: '',
    name: '',
  },
  rightSidePosts: [],
  percentRight: 0,
};

export const tagOWarContentData = {
  ...{ ...tagOWarContentLeftSide },
  ...{ ...tagOWarContentRightSide },
  slugNameLeft: '',
  slugIdLeft: '',
  slugNameRight: '',
  slugIdRight: '',
  leftSideImage: '',
  rightSideImage: '',
  isCompetitionEnd: false,
  competitionEndDate: '',
  startDate: yesterday,
  endDate: tomorrow,
  timeZone: '',
  fixedTitle: '',
};

export const tagOWarStylesData = {
  textColorLeft: colors.white,
  textColorPostLeft: colors.white,
  backgroundRangeLeft: colors.blueDark,
  backgroundColorLeft: colors.blueLight,
  backgroundColorPostLeft: colors.blueLight,
  textColorRight: colors.white,
  textColorPostRight: colors.white,
  backgroundRangeRight: colors.red,
  backgroundColorRight: colors.redLight,
  backgroundColorPostRight: colors.redLight,
};

export const tagOWarData = {
  published: [],
  draft: [getHeader(colors.white), getTagOWar(tagOWarContentData, tagOWarStylesData), getSocial(), getFooter()],
};
