import {
  initialState as SocialPublishingInitialState,
  socialPublishingFilters,
} from './social-publishing.flters.reducer';
import { socialPublishingCalendar } from './social-publishing.reducer';
import { filterable } from '../../../reducers/enchancers/filterable';
import { ACTIONS as SOCIAL_PUBLISHING_FILTERS } from '../actions/social-publishing.filters.actions';

const socialPublishingReducer = {
  socialPublishingCalendar,
  socialPublishingFilters: filterable(
    socialPublishingFilters,
    {
      SET_FILTERS: SOCIAL_PUBLISHING_FILTERS.SET_FILTERS,
      CLEAR_FILTERS: SOCIAL_PUBLISHING_FILTERS.CLEAR_FILTERS,
    },
    SocialPublishingInitialState
  ),
};

export default socialPublishingReducer;
