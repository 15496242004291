import React from 'react';
import PropTypes from 'prop-types';
import './tag-o-war-section-winner.sass';

export const drawTranslate = 'Draw!';

const TagOWarSectionWinner = ({ socialFeedLeft, socialFeedRight, percentLeft, percentRight }) => {
  let winner = '';

  if (percentLeft > percentRight) {
    winner = socialFeedLeft.label;
  }
  if (percentLeft < percentRight) {
    winner = socialFeedRight.label;
  }
  if (percentRight === percentLeft) {
    winner = drawTranslate;
  }

  return (
    <>
      <div className='tint-tag-o-war-winner' data-testid='tagOWarWinner'>
        {winner === drawTranslate ? (
          <span data-testid='winner'>
            <strong>{winner}</strong>
          </span>
        ) : (
          <span data-testid='winner'>
            <strong>{winner} </strong>won!
          </span>
        )}
      </div>
    </>
  );
};

export default TagOWarSectionWinner;

TagOWarSectionWinner.propTypes = {
  winner: PropTypes.string,
};
