import React, { Fragment } from 'react';
import './form-builder.form.interface.thumb-scale.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';

const FormBuilderFormInterfaceThumbScale = ({ styles, item, values }) => {
  if (!item) return null;

  const { t } = useTranslation();

  const elements = item.elements;
  const legend = elements?.find(el => el?.nodeName === 'legend');
  const input = elements?.find(el => el?.nodeName === 'input');
  const defaultValues = values?.getAll(input.name);

  const onFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
  };

  return (
    <div className={item.class} style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS]}>
      <fieldset style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.THUMB_SCALE_FIELDSET] }}>
        <legend style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.LABEL] }}>
          {`${t(legend?.nodeValue)}${input?.required ? '*' : ''}`}
        </legend>
        {elements
          .filter(el => el?.type === 'radio')
          .map((input, index) => {
            const labelNodeValue = elements.find(label => label?.for === input?.id)?.nodeValue;

            return (
              <Fragment key={index}>
                <label htmlFor={input?.id} style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.THUMB_SCALE_LABEL] }}>
                  {t(labelNodeValue)}
                  <input
                    type={input?.type}
                    name={input?.name}
                    onFocus={onFocus}
                    id={input?.id}
                    defaultChecked={defaultValues?.indexOf(input?.value) >= 0}
                    value={input.value}
                    required={input?.required}
                    style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.THUMB_SCALE_INPUT] }}
                  />
                </label>
              </Fragment>
            );
          })}
      </fieldset>
    </div>
  );
};

FormBuilderFormInterfaceThumbScale.displayName = 'FormBuilderFormInterfaceThumbScale';
export default React.memo(FormBuilderFormInterfaceThumbScale);
