import React, { Component } from 'react';
import './button.sass';
import PropTypes from 'prop-types';
import TintIcon from '../icon/icon';

const defaultClasses = {
  type:
    'primary' |
    'secondary' |
    'info' |
    'stroked' |
    'white' |
    'black' |
    'transparent' |
    'transparent-gray' |
    'dark-gray' |
    'gray' |
    'light-gray' |
    'gray-no-hover' |
    'link' |
    'stripe' |
    'stroked-grey' |
    'navigation-item' |
    'no-style' |
    'orange' |
    'navigation-arrow' |
    'dark-border' |
    'light-border' |
    'new-gray' |
    'new-primary-icon' |
    'dashed' |
    'error',
  size: '',
};

const TargetType = {
  BLANK: '_blank',
};

export class Button extends Component {
  renderIcon() {
    if (this.props.icon) {
      if (typeof this.props.icon === 'string') {
        return <i className={`${this.props.icon} button-icon`} />;
      } else {
        return (
          <div className='tint-btn__icon'>
            <TintIcon icon={this.props.icon} svgIconSrc={this.props.svgIconSrc} color={this.props.iconColor} />
          </div>
        );
      }
    } else if (this.props.svgIconSrc) {
      return (
        <div className='tint-btn__icon'>
          <TintIcon svgIconSrc={this.props.svgIconSrc} />
        </div>
      );
    }
  }

  getClassNames() {
    const buttonDisabledClass = this.props.isDisabled ? 'tint-btn--disabled' : '';
    return `tint-btn tint-btn--${this.props.type || 'primary'} tint-btn--${this.props.size ||
      defaultClasses.size} ${buttonDisabledClass} tint-btn--${
      this.props.icon ? (this.props.iconRight ? 'icon-right' : 'icon-left') : ''
    }`;
  }

  renderLink() {
    return (
      <a
        className={`${this.getClassNames()} ${this.props.className}`}
        data-testid={this.props.id}
        href={this.props.openUrl}
        onClick={this.props.onClick}
        rel='noopener noreferrer'
        style={{
          width: this.props.width,
          paddingLeft: this.props.width ? 0 : null,
          paddingRight: this.props.width ? 0 : null,
          color: this.props.textColor,
          ...this.props.styles,
        }}
        target={this.props.openUrlTarget !== undefined ? this.props.openUrlTarget : '_blank'}>
        {!this.props.iconRight && this.renderIcon()}
        {this.props.text}
        {this.props.children}
        {this.props.iconRight && this.renderIcon()}
      </a>
    );
  }

  renderButton() {
    return (
      <button
        data-testid={this.props.id}
        disabled={this.props.isDisabled}
        type={this.props.action}
        form={this.props.formId}
        onClick={this.props.onClick}
        readOnly={this.props.readonly}
        style={{
          height: this.props.height,
          width: this.props.width,
          paddingLeft: this.props.width ? 0 : null,
          paddingRight: this.props.width ? 0 : null,
          color: this.props.textColor,
          ...this.props.styles,
        }}
        className={`${this.getClassNames()} ${this.props.className}`}>
        {!this.props.iconRight && this.renderIcon()}
        {this.props.text}
        {this.props.children}
        {this.props.iconRight && this.renderIcon()}
      </button>
    );
  }

  render() {
    return this.props.openUrl !== undefined ? this.renderLink() : this.renderButton();
  }
}

Button.propTypes = {
  action: PropTypes.string,
  formId: PropTypes.string,
  type: PropTypes.string,
  readonly: PropTypes.bool,
  size: PropTypes.string,
  text: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  onClick: PropTypes.func,
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  buttonColor: PropTypes.string,
  textColor: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  openUrl: PropTypes.string,
  openUrlTarget: PropTypes.string,
  id: PropTypes.string,
  form: PropTypes.string,
  svgIconSrc: PropTypes.string,
};

Button.defaultProps = {
  action: 'button',
  isDisabled: false,
  openUrlTarget: TargetType.BLANK,
};
