import { ExperienceConfig } from '../../feature/experience/utils/experience.config';

export const getDefaultExperienceModel = (data, type, title) => {
  return {
    experience: {
      type: 'experience',
      attributes: {
        data: {
          translations: {
            draft: { 'en-US': {} },
            published: { 'en-US': {} },
          },
          locales: {
            draft: [
              {
                value: ExperienceConfig.languages.defaultLanguage.value,
                name: ExperienceConfig.languages.defaultLanguage.name,
                isDefault: true,
              },
            ],
            published: [
              {
                value: ExperienceConfig.languages.defaultLanguage.value,
                name: ExperienceConfig.languages.defaultLanguage.name,
                isDefault: true,
              },
            ],
          },
          customFonts: {
            draft: [],
            published: [],
          },
          ...data,
        },
        name: title ? `${title}_copy` : 'Untitled Project',
        type: type,
      },
    },
  };
};

export const addCustomizableHeader = (section, headerOptions) => {
  const defaultCustomizableHeader = {
    text: 'ENTRY TEXT',
    textDecoration: '',
    urlLink: '',
    fontStyle: '',
    textAlign: 'center',
    fontFamily: {
      value: 'Lato',
      name: 'Lato',
      url: 'Lato',
    },
    fontWeight: 500,
    topPosition: 50,
    fontSize: 36,
    lineHeight: 38,
    width: 566,
    color: '#ffffff',
    backgroundColor: { r: 255, g: 255, b: 255, a: 0 },
  };

  if (section.data.content.customizableHeaders === undefined) {
    section.data.content.customizableHeaders = [];
  }

  section.data.content.customizableHeaders.push({
    ...defaultCustomizableHeader,
    ...headerOptions,
  });

  return section;
};
