export const colors = {
  primary: '#26e0bd',
  info: '#3b99fc',
  starRating: '#f5a623',
  black: '#000000',
  waveGrey6: '#1B242D',
  textPrimary: '#1b242d',
  veryDarkGrey: '#2c2d30',
  shadow: '#00000008',
  buttonSecondary: '#4b5056',
  placeholder: '#70757e',
  semiDarkGrey: '#797E86',
  textSecondary: '#838e94',
  placeholderGrey: '#aab7c4',
  grey: '#b6b8bb',
  lightGray: '#DFDFDF',
  lines: '#e9edf3',
  semiLightGray: '#EDEFF1',
  veryLightGrey: '#f6f7f8',
  white: '#ffffff',
};
