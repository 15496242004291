import React from 'react';
import './modal-header.sass';

const ModalHeader = ({ title, rightIcon = 'fal fa-times', onCloseClick }) => {
  return (
    <header className='tint-modal-header'>
      {title && <h3>{title}</h3>}
      <span className={`icon close-icon ${rightIcon}`} onClick={onCloseClick} />
    </header>
  );
};
export default ModalHeader;
