import React from 'react';
import './tint-mix-grid-template.sass';

export const TintMixGridTemplates = {
  FULL_SCREEN: "'a'",
  HORIZONTAL: "'a' 50% 'b' 50%",
  VERTICAL: "'a b' / 50% 50%",
  TWO_ZONES_HORIZONTAL2: "'a' 25% 'b' 75%",
  TWO_ZONES_HORIZONTAL_REVERS2: "'a' 75% 'b' 25%",
  TWO_ZONES_HORIZONTAL: "'a b' / 25% 75%",
  TWO_ZONES_HORIZONTAL_REVERS: "'a b' / 75% 25%",
  LOWER_LEFT: "'a b' 75% 'c c' 25% / 25% 75%",
  LOWER_RIGHT: "'a b' 75% 'c c' 25% / 75% 25%",
  UPPER_LEFT: "'a a' 34% 'b c' 66% / 25% 75%",
  UPPER_RIGHT: "'a a' 34% 'b c' 66% / 75% 25%",
};

export const TintMixGridTemplate = React.memo(({ template, templateElements, onTemplateClick }) => {
  return (
    <>
      <div
        className='tint-mix-grid-template'
        style={{ gridTemplate: template }}
        onClick={() => {
          onTemplateClick && onTemplateClick(template);
        }}>
        {templateElements.map((el, i) => (
          <div
            key={el}
            className={`tint-mix-grid-template__cell tint-mix-grid-template__cell--${i + 1}`}
            style={{ gridArea: el }}
          />
        ))}
      </div>
    </>
  );
});
TintMixGridTemplate.displayName = 'TintMixGridTemplate';
