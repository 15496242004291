import React, { Component, Fragment } from 'react';

export const ClickOutsideHOC = PassedComponent => {
  class ClickOutside extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isMenuElementVisible: false,
      };
      this.mouseDownFunction = this.handleClick.bind(this);
      this.menuContainerNode = {};
    }

    componentWillMount() {
      document.addEventListener('mousedown', this.mouseDownFunction);
    }

    componentWillUnmount() {
      document.removeEventListener('mousedown', this.mouseDownFunction);
    }

    handleClick(e) {
      if (this.menuContainerNode.contains(e.target)) {
        return;
      }
      this.props.onMenuClose && this.props.onMenuClose();
      this.setState({ isMenuElementVisible: false });
    }

    displayMenu() {
      this.props.onMenuOpen && this.props.onMenuOpen();
      this.setState({ isMenuElementVisible: true });
    }

    hideMenu = () => {
      this.props.onMenuClose && this.props.onMenuClose();
      this.setState({ isMenuElementVisible: false });
    };

    onSetMenuNode(menuNode) {
      if (menuNode) {
        this.menuContainerNode = menuNode;
      }
    }

    render() {
      return (
        <Fragment>
          <PassedComponent
            {...this.props}
            isMenuElementVisible={this.state.isMenuElementVisible}
            setMenuNode={this.onSetMenuNode.bind(this)}
            displayMenu={this.displayMenu.bind(this)}
            hideMenu={this.hideMenu}
          />
        </Fragment>
      );
    }
  }

  return ClickOutside;
};
