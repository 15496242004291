import { createPrefixedActionName } from '../../../../services/functions/redux-routines/redux-routines';

export const ACTIONS = {
  OPEN_MARKETING_INTEGRATION_SIDEBAR: createPrefixedActionName('ASSET_MANAGER', 'OPEN_MARKETING_INTEGRATION_SIDEBAR'),
  CLOSE_MARKETING_INTEGRATION_SIDEBAR: createPrefixedActionName('ASSET_MANAGER', 'CLOSE_MARKETING_INTEGRATION_SIDEBAR'),
};

export const openMarketingIntegrationsSidebar = () => ({
  type: ACTIONS.OPEN_MARKETING_INTEGRATION_SIDEBAR,
});

export const closeMarketingIntegrationsSidebar = () => ({
  type: ACTIONS.CLOSE_MARKETING_INTEGRATION_SIDEBAR,
});
