import React, { Component } from 'react';
import { toast } from 'react-toastify';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { EditInput, EditForm } from '../../../';
import { Button } from '../../../../../../../components/interface/button/button';
import { TintSelector } from '../../form/edit/tint-selector/tint-selector';

export class PostEditContent extends Component {
  constructor(props) {
    super(props);
    this.handleCustomizePopupClick = this.handleCustomizePopupClick.bind(this);
  }

  handleCustomizePopupClick() {
    if (this.props.data.tintSlug) {
      this.props.nextStep();
      this.props.openPostModal();
    } else {
      toast.warn('You need to select tint!');
    }
  }

  onHandleClick({ tintSlug, socialFeedId, formBuilderId }) {
    this.props.dataUpdate({ socialFeedId, tintSlug, formBuilderId });
  }

  render() {
    return (
      <EditModalBody
        title='Select a Board from the list below to allow users to post directly to your TINT.
'>
        <EditForm>
          <TintSelector
            onChange={({ formBuilderId }) => {
              this.setState({ formBuilderId });
            }}
            defaultTint={this.props.data.tintSlug}
            dataUpdate={({ tintSlug, socialFeedId, formBuilderId }) => {
              this.setState({ formBuilderId });
              this.onHandleClick({ socialFeedId, tintSlug, formBuilderId });
            }}
          />
          <EditInput
            defaultValue={this.props.getTranslation(this.props.data.buttonText)}
            label='button text'
            onChange={e => {
              this.props.onTranslationUpdate(this.props.data.buttonText, e.target.value);
            }}
          />

          {!this.state?.formBuilderId && (
            <Button
              onClick={this.handleCustomizePopupClick}
              type='gray'
              width='100%'
              text='Customize popup'
              styles={{ color: '#000000' }}
            />
          )}
        </EditForm>
      </EditModalBody>
    );
  }
}
