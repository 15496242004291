import { SidebarIcons, SidebarItemTypes } from '../utils/meta/consts';
import { formsDisabledButton, postMediaAltTextDisabledButton, SocialFeedsFooterLabels } from './helpers';
import { SocialFeedsAccountTypes, BackendKeys, OptionsKeys, FormMediaKeys } from '../consts';
import {
  initializeFormId,
  initializeAuthorNameValue,
  initializeAuthorEmailValue,
  initializeAuthorAvatarValue,
  initializePostTextValue,
  initializeTagsValue,
  initializePostAlternativeTextValue,
  initializeMediaValue,
  validationSchemaPublicPosting,
} from '../utils/validation-schema/public-posting-schema';

export const publicPostingUpgrade = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext,
    },
    editFooter: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepSave,
    },
    accountSourceType: 'public_post',
    selectedOption: { id: 'post', title: 'Add Public Posting' },
    header: 'Add Public Posting',
    description:
      'Public posting adds a button to the top left of your display, allowing users to post content directly to your board.',
    formSchema: {
      post: {
        validationSchema: validationSchemaPublicPosting(),
        submitMapper: values => {
          return {
            form_id: values.form.value,
            options: {
              [OptionsKeys.AUTHOR_NAME_FIELD]: values.name?.value || '',
              [OptionsKeys.AUTHOR_EMAIL_FIELD]: values.email?.value || '',
              [OptionsKeys.AUTHOR_AVATAR_FIELD]: values.avatar?.value || '',
              [OptionsKeys.POST_TEXT_FIELD]: values.text?.value || '',
              [OptionsKeys.POST_ALT_TEXT_FIELD]:
                values.media?.value && values.alternative_text?.value ? values.alternative_text?.value : '',
              [OptionsKeys.MEDIA]: values.media?.value || '',
              [OptionsKeys.TAGS]: values.tags?.value || '',
            },
          };
        },
        inputs: [
          {
            id: SocialFeedsAccountTypes.FORM,
            backendId: BackendKeys.EXTERNAL_ID,
            label: 'SELECT FORM',
            placeholder: 'Select Form...',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
          },
          {
            id: 'name',
            backendId: OptionsKeys.AUTHOR_NAME_FIELD,
            label: 'SELECT AUTHOR NAME',
            placeholder: 'Select...',
            description: 'Choose the field that you want to map to the post’s author name.',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
          },
          {
            id: 'email',
            backendId: OptionsKeys.AUTHOR_EMAIL_FIELD,
            label: 'SELECT AUTHOR EMAIL',
            placeholder: 'Select...',
            description: 'Choose the field that you want to map to the post’s author email.',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
          },
          {
            id: FormMediaKeys.AVATAR,
            backendId: OptionsKeys.AUTHOR_AVATAR_FIELD,
            label: 'SELECT AUTHOR AVATAR',
            placeholder: 'Select...',
            description: 'Choose the author avatar field',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
          },
          {
            id: 'text',
            backendId: OptionsKeys.POST_TEXT_FIELD,
            label: 'POST TEXT',
            placeholder: 'Select...',
            description: 'Choose the field that you want to map to the post’s body content section.',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
          },
          {
            id: 'tags',
            backendId: OptionsKeys.TAGS,
            label: 'POST TAGS',
            placeholder: 'Select...',
            description: 'Choose the field that you want to map to the post’s tags.',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            defaultValue: initializeTagsValue,
          },
          {
            id: FormMediaKeys.MEDIA,
            backendId: OptionsKeys.MEDIA,
            label: 'SELECT MEDIA',
            placeholder: 'Select...',
            description: 'Choose the media field',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
          },
          {
            id: FormMediaKeys.POST_MEDIA_ALT_TEXT,
            backendId: OptionsKeys.POST_ALT_TEXT_FIELD,
            label: 'POST MEDIA ALTERNATIVE TEXT',
            placeholder: 'Select...',
            description: 'Choose the field that you want to map to the post’s alternative text.',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: postMediaAltTextDisabledButton,
          },
        ],
        editInputs: [
          {
            id: SocialFeedsAccountTypes.FORM,
            backendId: BackendKeys.EXTERNAL_ID,
            label: 'SELECT FORM',
            placeholder: 'Select Form...',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.ACCOUNT,
            defaultValue: initializeFormId,
            lineBreak: true,
          },
          {
            id: 'name',
            backendId: OptionsKeys.AUTHOR_NAME_FIELD,
            label: 'SELECT AUTHOR NAME',
            placeholder: 'Select...',
            description: 'Choose the field that you want to map to the post’s author name.',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            defaultValue: initializeAuthorNameValue,
            disabled: formsDisabledButton,
          },
          {
            id: 'email',
            backendId: OptionsKeys.AUTHOR_EMAIL_FIELD,
            label: 'SELECT AUTHOR EMAIL',
            placeholder: 'Select...',
            description: 'Choose the field that you want to map to the post’s author email.',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            defaultValue: initializeAuthorEmailValue,
            disabled: formsDisabledButton,
          },
          {
            id: FormMediaKeys.AVATAR,
            backendId: OptionsKeys.AUTHOR_AVATAR_FIELD,
            label: 'SELECT AUTHOR AVATAR',
            placeholder: 'Select...',
            description: 'Choose the author avatar field',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            defaultValue: initializeAuthorAvatarValue,
            disabled: formsDisabledButton,
          },
          {
            id: 'text',
            backendId: OptionsKeys.POST_TEXT_FIELD,
            label: 'POST TEXT',
            placeholder: 'Select...',
            description: 'Choose the field that you want to map to the post’s body content section.',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            defaultValue: initializePostTextValue,
            disabled: formsDisabledButton,
          },
          {
            id: 'tags',
            backendId: OptionsKeys.TAGS,
            label: 'POST TAGS',
            placeholder: 'Select...',
            description: 'Choose the field that you want to map to the post’s tags.',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
            defaultValue: initializeTagsValue,
          },
          {
            id: FormMediaKeys.MEDIA,
            backendId: OptionsKeys.MEDIA,
            label: 'SELECT MEDIA',
            placeholder: 'Select...',
            description: 'Choose the media field',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            defaultValue: initializeMediaValue,
            disabled: formsDisabledButton,
          },
          {
            id: FormMediaKeys.POST_MEDIA_ALT_TEXT,
            backendId: OptionsKeys.POST_ALT_TEXT_FIELD,
            label: 'POST MEDIA ALTERNATIVE TEXT',
            placeholder: 'Select...',
            description: 'Choose the field that you want to map to the post’s alternative text.',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            defaultValue: initializePostAlternativeTextValue,
            disabled: postMediaAltTextDisabledButton,
          },
        ],
      },
    },
  },
];
