import { toast } from 'react-toastify';
import Pusher from 'pusher-js';
import { Config } from '../../../utils/config';
import { ApiService } from '../../api-service/api-service';
import { getTeamId } from '../../../feature/experience/utils/experience-helper';

export const getMetrics = (ids, startDate, endDate) => {
  let url = `/v2/teams/${getTeamId()}/posts/metrics/ingested?filter[social_feed_id]=${ids.join(
    ','
  )}&filter[created_at][$gte]=${startDate}&filter[created_at][$lte]=${endDate}`;
  return ApiService.pureInstance()
    .get(url)
    .catch(() => toast.error('Something went wrong, try again later'));
};

export const subscribeOnWebsocket = function(tintId, callback) {
  const socket = new Pusher(Config.isDevEnv() ? Config.webSocketId.devEnv : Config.webSocketId.prodEnv);
  const channel = socket.subscribe(tintId);
  channel.bind('push_notification', res => {
    callback(res);
  });
};

export const getSocialFeeds = function() {
  return ApiService.get('/tints?include=social_feeds&page[size]=500').catch(() =>
    toast.error('Something went wrong, try again later')
  );
};

export const getSocialFeedsPosts = (slugName, socialFeedId, startDateString, endDateString) => {
  const filterUrl = `posts?filter[social_feed_id]=${socialFeedId}&filter[status]=public&filter[published_at][$gte]=${startDateString}&filter[published_at][$lte]=${endDateString}`;
  const tintPagesUrl = `/v2/tints/${slugName}/${filterUrl}`;
  return ApiService.pureInstance()
    .get(tintPagesUrl)
    .catch(() => toast.error('Something went wrong, try again later'));
};

export const getFonts = () => {
  return ApiService.get('/fonts?page[size]=500').catch(() => {
    toast.error('Something went wrong, try again letter');
  });
};
