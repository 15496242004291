import { ACTIONS } from '../actions/discover.actions';
// import { categoryList } from '../components/omni-channel/category-container/category-container';

export const initialState = {
  data: {},
  isFetching: false,
  error: undefined,

  // selectedCategory: categoryList[1].value,
  ingestedMetricsData: {},
  rightRequestsMetricData: {},
  rightApprovedMetricData: {},
  communityInsights: {},
};

export const discover = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SELECT_CATEGORY:
      return {
        ...state,
        selectedCategory: action.payload,
      };

    case ACTIONS.GET_CONTENT_COLLECTED.SUCCESS:
      return {
        ...state,
        ingestedMetricsData: action.payload,
        isFetching: false,
        isError: false,
      };

    case ACTIONS.GET_RIGHTS_APPROVED_METRICS.SUCCESS:
      return {
        ...state,
        rightApprovedMetricData: action.payload,
        isFetching: false,
        isError: false,
      };

    case ACTIONS.GET_RIGHTS_REQUEST_METRICS.SUCCESS:
      return {
        ...state,
        rightRequestsMetricData: action.payload,
        isFetching: false,
        isError: false,
      };

    case ACTIONS.GET_RECENT_TINT_CONTENT.SUCCESS:
      return {
        ...state,
        data: action.payload,
        isFetching: false,
        isError: false,
      };

    case ACTIONS.GET_COMMUNITY_GENERATED_CONTENT_BREAKDOWN.SUCCESS:
      return {
        ...state,
        communityInsights: {
          ...state.communityInsights,
          communityGeneratedContentBreakdown: action.payload.data,
        },
      };

    case ACTIONS.GET_COMMUNITY_GENERATED_PREV_CONTENT.SUCCESS:
      return {
        ...state,
        communityInsights: {
          ...state.communityInsights,
          communityMembersPrevCount: action.payload.data,
        },
      };

    case ACTIONS.GET_COMMUNITY_GENERATED_CONTENT.SUCCESS:
      return {
        ...state,
        communityInsights: {
          ...state.communityInsights,
          communityMembersCurrentCount: action.payload.data,
        },
      };

    case ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_PREV_COUNT.SUCCESS:
      return {
        ...state,
        communityInsights: {
          ...state.communityInsights,
          totalCommunityMembersPrevCount: action.payload.data,
        },
      };

    case ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_CURRENT.SUCCESS:
      return {
        ...state,
        communityInsights: {
          ...state.communityInsights,
          totalCommunityMembersCurrentCount: action.payload.data,
        },
      };

    case ACTIONS.GET_COMMUNITY_GENERATED_PREV_CONTENT.REQUEST:
    case ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_CURRENT.REQUEST:
    case ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_PREV_COUNT.REQUEST:
    case ACTIONS.GET_COMMUNITY_GENERATED_CONTENT_BREAKDOWN.REQUEST:
    case ACTIONS.GET_COMMUNITY_GENERATED_CONTENT.REQUEST:
    case ACTIONS.GET_RECENT_TINT_CONTENT.REQUEST:
      return {
        ...state,
        isFetching: true,
        isError: false,
      };

    case ACTIONS.GET_COMMUNITY_GENERATED_PREV_CONTENT.FAILURE:
    case ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_CURRENT.FAILURE:
    case ACTIONS.GET_TOTAL_COMMUNITY_MEMBERS_PREV_COUNT.FAILURE:
    case ACTIONS.GET_COMMUNITY_GENERATED_CONTENT_BREAKDOWN.FAILURE:
    case ACTIONS.GET_COMMUNITY_GENERATED_CONTENT.FAILURE:
    case ACTIONS.GET_RECENT_TINT_CONTENT.FAILURE:
      return {
        ...state,
        isFetching: false,
        isError: action.payload,
      };

    default:
      return state;
  }
};
