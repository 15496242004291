import { ACTIONS } from '../../actions/asset-marketing-integrations/asset-marketing-integrations.actions';

export const initialState = {
  isOpen: false,
};

export const assetMarketingIntagrations = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_MARKETING_INTEGRATION_SIDEBAR:
      return {
        ...state,
        isOpen: true,
      };

    case ACTIONS.CLOSE_MARKETING_INTEGRATION_SIDEBAR:
      return {
        ...state,
        isOpen: false,
      };

    default:
      return state;
  }
};
