import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import EditUploadDesign from '../../../edit-upload-design/edit-upload-design';
import { EditColorInput } from '../../../index';
import PropTypes from 'prop-types';

export class EditSocialFeedCounterStyles extends Component {
  render() {
    return (
      <>
        <EditModalBody title='Edit your header background color'>
          <EditColorInput
            color={this.props.data.styles.counterTextColor}
            label='Counter text color'
            onChange={value => {
              this.props.styleDataUpdate({ counterTextColor: value });
            }}
          />

          <EditColorInput
            color={this.props.data.styles.counterLabelTextColor}
            label='Label text color'
            onChange={value => {
              this.props.styleDataUpdate({ counterLabelTextColor: value });
            }}
          />

          <EditColorInput
            color={this.props.data.styles.counterBackgroundColor}
            label='Counter background color'
            onChange={value => {
              this.props.styleDataUpdate({ counterBackgroundColor: value });
            }}
          />
          <EditUploadDesign
            dataUpdate={this.props.dataUpdate}
            styleDataUpdate={this.props.styleDataUpdate}
            data={this.props.data}
          />
        </EditModalBody>
      </>
    );
  }
}

EditSocialFeedCounterStyles.propTypes = {
  data: PropTypes.shape({
    styles: PropTypes.shape({
      counterTextColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      counterLabelTextColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      counterBackgroundColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
  }),
  styleDataUpdate: PropTypes.func,
  dataUpdate: PropTypes.func,
};
