import React, { forwardRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './checkbox-button.sass';

export const CheckboxButtonType = {
  ROUND: 'round',
  PRIMARY: 'primary',
};

const CheckboxButton = (
  {
    defaultChecked,
    id,
    onStatusChange,
    name,
    color,
    borderColor,
    markColor,
    type = CheckboxButtonType.PRIMARY,
    backgroundColor = 'transparent',
    textColor,
    details,
    ...rest
  },
  ref
) => {
  const [isSelected, setIsSelected] = useState(defaultChecked);

  const onChange = event => {
    setIsSelected(!isSelected);
    if (onStatusChange !== undefined) {
      onStatusChange(id, event, !isSelected, details);
    }
  };

  useEffect(() => {
    setIsSelected(defaultChecked);
  }, [defaultChecked]);

  const markStyles = `
  .checkbox-button.${id} label::after {
    border-color: ${markColor};
    opacity: ${isSelected ? 1 : 0};
  }`;

  return (
    <div className={`checkbox-button ${id}`}>
      <style dangerouslySetInnerHTML={{ __html: markStyles }} />
      <input type='checkbox' id={id} name={name} value={isSelected} onChange={onChange} ref={ref} {...rest} />
      <label
        data-after={markColor}
        className={`checkbox-button__${type}`}
        style={{
          backgroundColor: isSelected ? color : backgroundColor,
          borderColor: isSelected ? color : borderColor,
          color: textColor,
        }}
        htmlFor={id}
      />
    </div>
  );
};

const CheckboxButtonWithForwardedRef = forwardRef(CheckboxButton);

CheckboxButtonWithForwardedRef.propTypes = {
  defaultChecked: PropTypes.bool,
  id: PropTypes.string,
  onStatusChange: PropTypes.func,
  color: PropTypes.string,
  borderColor: PropTypes.string,
  markColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  type: PropTypes.string,
};

export default CheckboxButtonWithForwardedRef;
