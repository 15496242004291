import { pageSectionType } from '../../model/page-section-type.model';
import { formUpload } from '../../model/form-upload.model';
import { Config } from '../../utils/config';

import colors from './colors';
import { addCustomizableHeader } from './index';
import { dropDownType, pollImageType } from '../../model/poll-image-type.model';
import { FIELD_TYPE } from '../../feature/experience/components/experience/page-sections/form/form-helper';
import { ExperienceConfig } from '../../feature/experience/utils/experience.config';
import { ExperienceType } from '../../model/experience.type';

const sharedContent = {
  backgroundImageUrl: '',
  backgroundImageUrlMedium: '',
  backgroundImageUrlSmall: '',
};

const sharedStyles = {
  sectionPadding: '',
};

export function getHeader(backgroundColor) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        logoUrl: 'https://cdn.filestackcontent.com/0gI2MYPTdaLrR8xVzuSA',
        logoRedirectUrl: '',
        ...sharedContent,
      },
      styles: {
        ...sharedStyles,
        backgroundColor: backgroundColor,
        logoHeight: '50',
      },
    },
    sectionType: pageSectionType.HEADER,
  };
}

export function getBanner(bannerUrl, styleOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        backgroundImageUrl: bannerUrl,
      },
      styles: {
        ...{ backgroundColor: colors.white },
        ...styleOptions,
      },
    },
    sectionType: pageSectionType.BANNER,
  };
}

export function getCookieBanner(styleOptions) {
  const textStyle = {
    fontWeight: 500,
    fontSize: 16,
    lineHeight: 24,
    color: colors.white,
  };

  const buttonStyle = {
    fontWeight: 500,
    fontSize: 14,
    color: colors.cookieBannerBlue,
    backgroundColor: colors.white,
  };

  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        cookieText:
          'By clicking “Allow” you agree to the storing of cookies on your device to enhance site navigation, analyze site usage, and assist in our marketing efforts.',
        cookieAllowButton: 'Allow',
        cookieDismissButton: 'Decline Optional Cookies',
        cookiePolicyText: 'Read Cookie Policy',
        cookiePolicyHref: null,
        privacyPolicyText: 'Read Privacy Policy',
        privacyPolicyHref: null,
      },
      styles: {
        ...{
          backgroundColor: colors.cookieBannerBlue,
        },
        bannerTextColor: textStyle.color,
        bannerTextFontSize: textStyle.fontSize,
        buttonSettingsStyleBackground: buttonStyle.backgroundColor,
        buttonSettingsStyleTextColor: buttonStyle.color,
        buttonAllowStyleBackground: buttonStyle.backgroundColor,
        buttonAllowStyleTextColor: buttonStyle.color,
        buttonAllowStyleOutlineColor: buttonStyle.color,
        buttonDismissStyleBackground: buttonStyle.backgroundColor,
        buttonDismissStyleTextColor: buttonStyle.color,
        buttonDismissStyleOutlineColor: buttonStyle.color,
        sectionPadding: 64,
        ...styleOptions,
      },
    },
    sectionType: pageSectionType.COOKIE_BANNER,
  };
}

export function getForm(isPhotoUploadEnabled, contentOptions) {
  const currentDate = new Date();
  const tomorrow = new Date(currentDate.setDate(currentDate.getDate() + 1));
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        isEndTimeEnabled: false,
        endDate: tomorrow,
        isPhotoUploadEnabled: isPhotoUploadEnabled,
        isPhotoUploadRequired: false,
        uploadPhotoType: formUpload.MANY,
        fields: [
          { type: FIELD_TYPE.INPUT, placeholder: 'Email', key: 'email', required: true },
          { type: FIELD_TYPE.INPUT, placeholder: 'First Name', key: 'first_name', required: true },
          { type: FIELD_TYPE.INPUT, placeholder: 'Last Name', key: 'last_name', required: true },
        ],
        buttonText: 'Submit Entry',
        uploadButtonText: 'Upload photo',
        uploadErrorText: 'File upload is required in order to submit',

        requiredFieldText: 'Required',

        termsConditionsUrl: '',
        termsConditions: '',
        termsConditionsAgree: 'I agree to',
        termsConditionsLinkTitle: 'Terms & Conditions',

        privacyPolicyUrl: '',
        privacyPolicy: '',
        privacyPolicyAgree: 'I agree to',
        privacyPolicyLinkTitle: 'Privacy Policy',

        contestRulesUrl: '',
        contestRules: '',
        contestRulesAgree: 'I agree to',
        contestRulesLinkTitle: 'Contest Rules',

        gdprCheckboxLabelText: '',
        gdprCheckboxDescriptionText: '',
        successMessageTitle: 'Thank you!',
        successMessageDescription: 'The form was submitted successfully',
        hasAdvancedSettings: false,
        successMessageLinks: [],
        ...contentOptions,
      },
      styles: {
        backgroundColor: colors.blackLight,
        formStyle: '',
        formFieldTextColor: colors.white,
        inputBorderColor: colors.white,
        buttonBackgroundColor: colors.whiteLight,
        buttonTextColor: colors.blackLight,
        termsConditionsTextColor: colors.white,
        contestRulesTextColor: colors.white,
        privacyPolicyTextColor: colors.white,
        optTextColor: colors.white,
        requiredColor: colors.white,
        errorColor: colors.red,
        uploadButtonBorderColor: colors.white,
        uploadButtonTextColor: colors.white,
        uploadButtonBackgroundColor: '#3e3e3e',
      },
    },
    sectionType: pageSectionType.FORM,
  };
}

export function getFormBuilder(stylesOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        formBuilderId: '',
        teamId: '',
        height: '500',
      },
      styles: {
        ...stylesOptions,
      },
    },
    sectionType: pageSectionType.FORM_BUILDER,
  };
}

export function getTimer(contentOptions, styleOptions) {
  const currentDate = new Date();
  const tomorrow = new Date(currentDate.setDate(currentDate.getDate() + 1));
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        endDate: tomorrow,
        timeZone: '',
        fixedTitle: '',
        backgroundImageUrl: '',
        labelDays: 'DAYS',
        labelHours: 'HOURS',
        labelMinutes: 'MINUTES',
        labelSeconds: 'SECONDS',
        endTitle: 'SWEEPSTAKE ENDS AT',
        dateType: dropDownType.US,
        ...contentOptions,
      },
      styles: {
        ...{
          headingTextColor: colors.blackLight,
          timerTextColor: colors.blackLight,
          labelTextColor: colors.blackLight,
          dividerColor: colors.blackLight,
          backgroundColor: colors.white,
        },
        ...styleOptions,
      },
    },
    sectionType: pageSectionType.TIMER,
  };
}

export function getTint(stylesOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        tintName: '',
        personalizationId: '',
        height: '500',
        heightType: 'Unlimited',
        displayType: '{"data-clickformore":"true"}',
        ofColumns: '3',
      },
      styles: {
        ...stylesOptions,
      },
    },
    sectionType: pageSectionType.TINT,
  };
}

export function getMedia(
  mediaType,
  backgroundColor,
  mediaUrl = mediaType === pageSectionType.IMAGE ? Config.assets.customImage : ''
) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        mediaUrl: mediaUrl,
        mediaType: mediaType,
        mediaName: '',
      },
      styles: {
        backgroundColor: backgroundColor,
        mediaHeight: '124',
        objectFit: 'fill',
      },
    },
    sectionType: pageSectionType.MEDIA,
  };
}

export function getSocial(styleOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        socialLinks: [
          { type: 'instagram', url: 'instagram.com/example' },
          { type: 'twitter', url: 'twitter.com/example' },
          { type: 'facebook', url: 'facebook.com/example' },
          { type: 'tiktok', url: 'tiktok.com/example' },
          { type: 'linkedin', url: '' },
          { type: 'youtube', url: '' },
          { type: 'pinterest', url: '' },
          { type: 'tumblr', url: '' },
          { type: 'vimeo', url: 'vimeo.com/example' },
        ],
      },
      styles: {
        ...{
          iconBackgroundColor: colors.black,
          backgroundColor: colors.white,
          iconColor: colors.white,
        },
        ...styleOptions,
      },
    },
    sectionType: pageSectionType.SOCIAL,
  };
}

export function getFooter(styleOptions, contentOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        logoUrl: 'https://cdn.filestackcontent.com/7xaOAYd9QAmmTYbiJjqt',
        logoRedirectUrl: '',
        footerCopyright: `${new Date().getFullYear()} COMPANY`,
        manageCookiesLabel: 'Manage Cookies',
        footerLinks: [
          { url: '', text: 'Terms & Conditions' },
          { url: '', text: 'Contest Rules' },
        ],
        ...sharedContent,
        ...contentOptions,
      },
      styles: {
        ...{
          textColor: colors.white,
          backgroundColor: colors.blackLighter,
          logoHeight: '60',
        },
        ...styleOptions,
      },
    },
    sectionType: pageSectionType.FOOTER,
  };
}

export function getBackToTop(styleOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {},
      styles: {
        ...sharedContent,
        backgroundColor: colors.white,
        buttonTextColor: colors.blackLighter,
        buttonBackgroundColor: colors.white,
        buttonBorderColor: colors.blackLighter,
        ...styleOptions,
      },
    },
    sectionType: pageSectionType.BACK_TO_TOP,
  };
}

export function getPost(contentOptions, styleOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        buttonText: 'SUBMIT PHOTO',
        tintSlug: null,
        socialFeedId: '',
        popupTitle: 'Add a post',
        popupButtonText: 'Submit Post',
        customFieldPlaceholder: '',
        ...contentOptions,
      },
      styles: {
        backgroundColor: colors.white,
        buttonTextColor: colors.white,
        buttonBackgroundColor: colors.blackLighter,
        overlayTextColor: colors.white,
        overlayBackgroundColor: colors.blackLighter,
        ...styleOptions,
      },
    },
    sectionType: pageSectionType.POST,
  };
}

export function getPoll(contentOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        pollSize: ExperienceConfig[ExperienceType.POLL].defaultPostCount,
        amountOfVotes: 2,
        imageType: pollImageType.FILL,
        tintSlug: '',
        buttonText: 'Vote Now!',
        buttonVotedText: 'VOTED',
        ...contentOptions,
      },
      styles: {
        backgroundColor: colors.violet,
        buttonTextColor: colors.black,
        buttonBackgroundColor: colors.white,
        overlayTextColor: colors.white,
        overlayBackgroundColor: colors.black,
      },
    },
    sectionType: pageSectionType.POLL,
  };
}

export function getSurvey(contentOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        imageType: pollImageType.FIT,
        buttonText: 'Submit',
        displayCount: 1,
        successMessageTitle: 'Thank you!',
        successMessageDescription: 'The survey was submitted successfully',
        successMessageLinks: [],
        answers: [],
        hasAdvancedSettings: false,
        ...contentOptions,
      },
      styles: {
        backgroundColor: colors.solarBlue,
        buttonTextColor: colors.white,
        buttonBackgroundColor: colors.black,
        answerTextColor: colors.black,
        answerBackgroundColor: colors.white,
      },
    },
    sectionType: pageSectionType.SURVEY,
  };
}

export function getSurveyCounter(contentOption) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        countLabelText: 'Total Votes',
        ...contentOption,
      },
      styles: {
        backgroundColor: colors.solarBlue,
        counterTextColor: colors.white,
        counterBackgroundColor: colors.oceanBlue,
        fontSize: '84',
        fontFamily: {
          value: 'Lato',
          name: 'Lato',
        },
      },
    },
    sectionType: pageSectionType.SURVEY_COUNTER,
  };
}

export function getCounter(contentOptions, styleOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        counterDescription: 'Posts & Counting',
        tintSlug: '',
        ...contentOptions,
      },
      styles: {
        containerBackgroundColor: colors.blueLight,
        counterBackgroundColor: colors.white,
        counterTextColor: colors.blueLight,
        counterDescriptionColor: colors.blackLighter,
        ...styleOptions,
      },
    },
    sectionType: pageSectionType.COUNTER,
  };
}

export function getHeadline(contentOptions, styleOptions, headerText) {
  return addCustomizableHeader(
    {
      data: {
        _id: new Date().getTime(),
        content: {
          ...sharedContent,
          ...contentOptions,
        },
        styles: {
          backgroundColor: colors.white,
          ...styleOptions,
        },
      },
      sectionType: pageSectionType.CUSTOM_HEADLINE,
    },
    { text: headerText || 'SHARE YOUR STYLE WITH US', color: colors.black }
  );
}

export function getParagraph(contentOptions, styleOptions, paragraphText) {
  return addCustomizableHeader(
    {
      data: {
        _id: new Date().getTime(),
        content: {
          ...sharedContent,
          ...contentOptions,
        },
        styles: {
          backgroundColor: colors.white,
          ...styleOptions,
        },
      },
      sectionType: pageSectionType.CUSTOM_PARAGRAPH,
    },
    {
      text: paragraphText,
      color: colors.black,
      fontSize: 16,
      topPosition: 176,
      lineHeight: 22,
      width: 460,
    }
  );
}

export function getCustomButton(contentOptions, styleOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        buttonUrl: '',
        buttonText: 'Submit',
        sectionHeight: '230',
        ...contentOptions,
      },
      styles: {
        buttonTextColor: colors.white,
        buttonColor: colors.black,
        buttonOutline: 'none',
        buttonOutlineBorderColor: colors.black,
        backgroundColor: colors.white,
        ...styleOptions,
      },
    },
    sectionType: pageSectionType.CUSTOM_BUTTON,
  };
}

export function getMultiColumn(contentOptions, styleOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        amountOfColumns: 3,
        columnsSpacing: 24,
        sections: [
          { data: null, column: 0 },
          { data: null, column: 1 },
          { data: null, column: 2 },
        ],
        justifyContent: 'center',
        ...contentOptions,
      },
      styles: {
        backgroundColor: colors.whiteLight,
        firstColumnBackgroundColor: colors.white,
        secondColumnBackgroundColor: colors.white,
        thirdColumnBackgroundColor: colors.white,
        columnPadding: '0',
        ...styleOptions,
      },
    },
    sectionType: pageSectionType.MULTI_COLUMN,
  };
}

export function getCustomSpacer(contentOptions, styleOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        sectionHeight: '58',
        ...contentOptions,
      },
      styles: {
        backgroundColor: colors.white,
        ...styleOptions,
      },
    },
    sectionType: pageSectionType.CUSTOM_SPACER,
  };
}

export function getCustomImage(contentOptions, styleOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        mediaUrl: Config.assets.customImage,
        ...contentOptions,
      },
      styles: {
        imageWidth: 100,
        sectionPadding: 64,
        backgroundColor: colors.white,
        ...styleOptions,
      },
    },
    sectionType: pageSectionType.IMAGE,
  };
}

export function getCustomVideo(contentOptions, styleOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...sharedContent,
        ...contentOptions,
        hasAutoplay: false,
      },
      styles: {
        videoWidth: 100,
        sectionPadding: 64,
        backgroundColor: colors.white,
        ...styleOptions,
      },
    },
    sectionType: pageSectionType.VIDEO,
  };
}

export function getTagOWar(contentOptions, styleOptions) {
  return {
    data: {
      _id: new Date().getTime(),
      content: {
        ...contentOptions,
        ...sharedContent,
      },
      styles: {
        ...styleOptions,
      },
    },
    sectionType: pageSectionType.TAG_O_WAR,
  };
}

export function getSocialFeedCounter(contentOptions, styleOptions) {
  const currentDate = new Date();
  const yesterday = new Date(currentDate.setDate(currentDate.getDate()));
  const tomorrow = new Date(currentDate.setDate(currentDate.getDate() + 2));

  return {
    data: {
      _id: new Date().getTime(),
      content: {
        counterLabelText: 'POSTS & COUNTING',
        ...sharedContent,
        ...contentOptions,
        isCompetitionEnd: false,
        startDate: yesterday,
        endDate: tomorrow,
        count: 0,
        slugList: [
          {
            slugId: undefined,
            socialFeedId: undefined,
          },
        ],
        isCountingEnd: false,
      },
      styles: {
        headerTextColor: colors.white,
        counterBackgroundColor: colors.white,
        counterTextColor: colors.blueLight,
        backgroundColor: colors.coldDarkBlue,
        counterLabelTextColor: colors.blueDark,
        ...styleOptions,
      },
    },
    sectionType: pageSectionType.SOCIAL_FEED_COUNTER,
  };
}
