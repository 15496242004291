import React from 'react';
import './button-content-icon.sass';
import TintIcon from '../icon/icon';
import getIconProperties from '../../../feature/tint-editor/configs/social-icon';
import useSvgIconCustomSize from '../../../components/hooks/use-svg-icon-custom-size/use-svg-icon-custom-size';

export const DefaultButtonIconSizes = {
  FONT: '22px',
  CIRCLE: '48px',
  SVG_ICON: '26px',
};

const ButtonContentIcon = ({
  source,
  onClick,
  fontSize = DefaultButtonIconSizes.FONT,
  circleSize = DefaultButtonIconSizes.CIRCLE,
  svgIconSize,
  isDisabled = false,
  styles,
  positionRight = -8,
  positionBottom = -8,
}) => {
  const currentIcon = getIconProperties(source);
  const { iconWidth, iconHeight } = useSvgIconCustomSize({
    source,
    currentWidth: currentIcon?.svgIconStyles?.width,
    currentHeight: currentIcon?.svgIconStyles?.height,
    resizeValue: svgIconSize,
  });

  if (styles) {
    currentIcon.styles = { ...currentIcon.styles, ...styles };
  }

  return (
    <button
      onClick={onClick}
      className='button-content-icon'
      style={{
        bottom: positionBottom,
        right: positionRight,
        fontSize,
        height: circleSize,
        width: circleSize,
        ...currentIcon?.styles,
        ...(isDisabled ? { background: '#838E94' } : {}),
      }}>
      {currentIcon?.icon ? (
        <TintIcon icon={currentIcon.icon} />
      ) : (
        <TintIcon
          svgIconSrc={currentIcon.svgIconSrc}
          style={{
            ...(iconWidth && iconHeight
              ? {
                  width: iconWidth,
                  height: iconHeight,
                  maxWidth: 'initial',
                }
              : {}),
          }}
        />
      )}
    </button>
  );
};

export default ButtonContentIcon;
