import React, { useState } from 'react';
import './omnichannel-solutions-sidebar.sass';
import { faTv } from '@fortawesome/free-solid-svg-icons';
import { faObjectsColumn } from '@fortawesome/pro-solid-svg-icons';

import { AddContentOption } from '../../../../../../../../components/interface/add-content-option/add-content-option';
import {
  AddContentOptionIcon,
  AddContentOptionIconTypes,
} from '../../../../../../../../components/interface/add-content-option/add-content-option-icon/add-content-option-icon';
import { DisplaySettingsSwitcherSteps } from '../../display-settings-switcher/display-settings-switcher';

export const OmniChannelSolutionsType = {
  WEBSITE_EMBED: 'embed',
  SIGNAGE_DISPLAY: 'screen',
};

const OmniChannelSolutionsTypeList = [
  {
    value: OmniChannelSolutionsType.WEBSITE_EMBED,
    title: 'Website Embed',
    description: 'Create a gallery embed for your website.',
    icon: faObjectsColumn,
  },
  {
    value: OmniChannelSolutionsType.SIGNAGE_DISPLAY,
    title: 'Signage Display',
    description: 'Create realtime dynamic displays for your audience.',
    icon: faTv,
  },
];

const OmnichannelSolutionsSidebar = ({ data, switcherData, onChangeData }) => {
  const [omnichannel, setOmnichannel] = useState(data?.type);

  return (
    <div className='tint-sidebar-omnichannel-solutions'>
      <p className='tint-sidebar-omnichannel-solutions__header'>Choose a type below:</p>

      {OmniChannelSolutionsTypeList.map((type, i) => (
        <AddContentOption
          key={i}
          id={type.value}
          isSelected={omnichannel === type.value}
          icon={
            <AddContentOptionIcon
              type={AddContentOptionIconTypes.FONTAWESOME}
              faIcon={type.icon}
              container={{
                background: '#373740',
              }}
              icon={{
                color: '#fff',
                fontSize: 20,
              }}
            />
          }
          content={[
            {
              title: type.title,
              description: type.description,
            },
          ]}
          onChange={type => {
            setOmnichannel(type);
            onChangeData({
              ...switcherData[DisplaySettingsSwitcherSteps.DISPLAY_OPTIONS],
              type: type,
              isValid: type || omnichannel !== undefined,
            });
          }}
        />
      ))}
    </div>
  );
};

export default OmnichannelSolutionsSidebar;
