import React, { Component } from 'react';
import CustomButton from './button';
import { PageSectionHoc } from '../../../../../../../components/HOC/page-section/page-section.hoc';
import './button-section.sass';
import { EditCustomButtonSection } from './edit/edit-button-content';
import { EditModal } from '../../../../../../../components/edit-modal/edit-modal/edit-modal';
import { EditModalStep } from '../../../../../../../components/edit-modal/edit-modal-step/edit-modal-step';
import { EditModalSwitcher } from '../../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher';
import PropTypes from 'prop-types';
import ColorParser from '../../../../../../../services/color-parser/color-parser';
import { EditCustomButtonStyles } from './edit/edit-button-styles';

class CustomButtonSection extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      backgroundColor,
      buttonColor,
      buttonOutline,
      buttonOutlineBorderColor,
      buttonTextColor,
    } = this.props.data.styles;
    const { sectionHeight } = this.props.data.content;

    const buttonStyles = {
      backgroundColor: ColorParser.defaultColor(buttonColor),
      color: ColorParser.defaultColor(buttonTextColor),
      border: `1px ${buttonOutline} ${ColorParser.defaultColor(buttonOutlineBorderColor)}`,
    };

    return (
      <>
        <div
          className={`tint-page-section tint-page-section--button tint-custom-section tint-custom-section--button ${this.props.getSectionClass()}`}
          style={{
            backgroundColor: ColorParser.defaultColor(backgroundColor),
            minHeight: `${sectionHeight}px`,
            paddingTop: `${this.props.data.styles.sectionPadding}px`,
            paddingBottom: `${this.props.data.styles.sectionPadding}px`,
          }}>
          {this.props.renderGenericComponents()}
          {this.props.isModalOpen && (
            <EditModal
              nextStep={this.props.nextStep}
              isVisible={this.props.isModalOpen}
              saveData={this.props.onDataSave}
              dismissModal={this.props.dismissModal}
              closeModal={this.props.closeModal}>
              <EditModalStep title='Edit Button'>
                <EditModalSwitcher>
                  <EditCustomButtonSection
                    getTranslation={this.props.getTranslation}
                    onTranslationUpdate={this.props.onTranslationUpdate}
                    data={this.props.data.content}
                    saveData={this.props.onDataSave}
                    dataUpdate={this.props.onContentUpdate}
                  />
                  <EditCustomButtonStyles
                    data={this.props.data}
                    saveData={this.props.onDataSave}
                    dataUpdate={this.props.onContentUpdate}
                    styleDataUpdate={this.props.onStylesUpdate}
                  />
                </EditModalSwitcher>
              </EditModalStep>
            </EditModal>
          )}

          <CustomButton
            buttonUrl={this.props.getTranslation(this.props.data.content.buttonUrl)}
            styles={buttonStyles}
            buttonTitle={this.props.getTranslation(this.props.data.content.buttonText)}
          />
        </div>
      </>
    );
  }
}

CustomButtonSection.propTypes = {
  onContentUpdate: PropTypes.func,
  onTranslationUpdate: PropTypes.func,
  getTranslation: PropTypes.func,
  onStylesUpdate: PropTypes.func,
  closeModal: PropTypes.func,
  onDataSave: PropTypes.func,
  dismissModal: PropTypes.func,
  data: PropTypes.shape({
    content: PropTypes.shape({
      buttonUrl: PropTypes.string,
      buttonText: PropTypes.string,
    }),
    styles: PropTypes.shape({
      buttonTextColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      buttonColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      buttonOutlineType: PropTypes.number,
      buttonOutline: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
  }),
};

export default PageSectionHoc(CustomButtonSection);
