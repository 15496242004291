import { SidebarIcons, SidebarItemTypes } from '../utils/meta/consts';
import { hootsuiteDisabledButton, SocialFeedsFooterLabels } from './helpers';
import { validationSchemaHootsuite } from '../utils/validation-schema/hootsuite-schema';

const dropdownProfileDefaultValue = {
  value: 'profile',
  name: 'Select profile',
};

const clearSubAccountsDropdown = formik => formik.setFieldValue('profile', dropdownProfileDefaultValue);

export const hootsuite = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext,
    },
    accountSourceType: 'hootsuite',
    selectedOption: { id: 'scheduled_posts', title: 'Add Hootsuite Scheduled Posts' },
    header: 'Add Hootsuite Scheduled Posts',
    description: 'To ingest Scheduled Posts from Hootsuite, select your Hootsuite account and a specific profile:',
    formSchema: {
      scheduled_posts: {
        validationSchema: validationSchemaHootsuite(),
        submitMapper: values => {
          return {
            account_id: values.account.value,
            external_id: values.profile.value,
            type: 'scheduled_posts',
          };
        },
        inputs: [
          {
            id: 'account',
            backendId: 'account_id',
            label: 'SELECT ACCOUNT',
            placeholder: 'Select account...',
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
            clearSubAccountsDropdown,
          },
          {
            id: 'profile',
            backendId: 'external_id',
            label: 'SELECT PROFILE',
            placeholder: 'Select Profile...',
            icon: SidebarIcons.PROFILE,
            type: SidebarItemTypes.SELECT,
            disabled: hootsuiteDisabledButton,
          },
        ],
      },
    },
  },
];
