import { ApiService } from '../../../services/api-service/api-service';
import {
  getPostsError,
  getPostsStart,
  getPostsSuccess,
  getSavedFiltersError,
  getSavedFiltersStart,
  getSavedFiltersSuccess,
  postSavedFiltersError,
  postSavedFiltersStart,
  postSavedFiltersSuccess,
  removeSavedFiltersError,
  removeSavedFiltersStart,
  removeSavedFiltersSuccess,
  updateSavedFiltersError,
  updateSavedFiltersStart,
  updateSavedFiltersSuccess,
  addCustomPostError,
  addCustomPostStart,
  addCustomPostSuccess,
  updatePostStart,
  updatePostSuccess,
  updatePostError,
  deletePostStart,
  deletePostSuccess,
  deletePostError,
  recoverPostStart,
  recoverPostSuccess,
  recoverPostError,
  postSimilarImageStart,
  postSimilarImageSuccess,
  postSimilarImageError,
  getPostRightsRequestStart,
  getPostRightsRequestSuccess,
  getPostRightsRequestError,
  getGlobalPostsStart,
  getGlobalPostsSuccess,
  getGlobalPostsError,
  getPostAggregationsStart,
  getPostAggregationsSuccess,
  getPostAggregationsError,
  updatePostsStart,
  updatePostsSuccess,
  updatePostsError,
  deletePostsStart,
  deletePostsSuccess,
  deletePostsError,
  updateCustomPostSuccess,
  updateCustomPostError,
  updateCustomPostStart,
  getLastPageStart,
  getLastPageSuccess,
  getLastPageError,
  setIsLoading,
} from './posts.actions';
import { FilterGroupTypes } from '../components/tint-editor/top-bar-filter/filter-modal/tint-editor-top-bar-filter-modal';
import { SortBy } from '../components/tint-editor/top-bar/tint-editor-top-bar';
import {
  actionRightsList,
  ActionFilterValues,
} from '../components/tint-editor/top-bar-filter/filter-modal/tint-editor-top-bar-filter-modal-filters.data';
import { PostRenderType } from '../reducers/posts.reducer';
import { Config } from '../../../utils/config';

const parseToActionFilter = (selectedFilters, currentKey) => {
  if (selectedFilters[currentKey] && selectedFilters[currentKey].length > 0) {
    const actionRightsFilterList = selectedFilters[currentKey].filter(el => actionRightsList.includes(el));
    const isSelectedRightsApprovalExpired = actionRightsFilterList?.find(
      el => el === ActionFilterValues.APPROVAL_EXPIRED
    );

    const rightsStatusList = actionRightsFilterList.reduce((prev, current) => {
      const curr = current === ActionFilterValues.APPROVAL_EXPIRED ? ActionFilterValues.APPROVED : current;
      return prev?.find(e => e === curr) ? prev : [...prev, curr];
    }, []);

    const actionOtherFilterList = selectedFilters[currentKey]
      .filter(val => actionRightsFilterList.indexOf(val.toString()) === -1)
      .reduce((prev, current) => {
        if (current === ActionFilterValues.NOT_PINNED) {
          return `${prev}` + '&filter[pinned]=false';
        }
        if (current === ActionFilterValues.NOT_HIGHLIGHTED) {
          return `${prev}` + '&filter[highlighted]=false';
        }
        if (current === ActionFilterValues.NOT_INCENTIVIZED) {
          return `${prev}` + '&filter[incentivized]=false';
        }

        return `${prev}` + `&filter[${current}]=true`;
      }, '');

    const rightsApprovalExpiredFilter = isSelectedRightsApprovalExpired
      ? `&filter[right_request_status_expires_at][$lte]=${new Date().toISOString()}`
      : '';
    const rightsStatusFilters =
      rightsStatusList?.length > 0 ? `&filter[right_request_status]=${rightsStatusList.map(filter => filter)}` : '';

    return `${actionOtherFilterList}${rightsStatusFilters}${rightsApprovalExpiredFilter}`;
  }
};

const parseToDateFilter = (selectedFilters, currentKey) => {
  return selectedFilters[currentKey] && Object.keys(selectedFilters[currentKey]).length > 0
    ? `&filter[${currentKey}][$gte]=${selectedFilters[currentKey].$gte}&filter[${currentKey}][$lte]=${selectedFilters[currentKey].$lte}`
    : undefined;
};

const parseToRatingFilter = (selectedFilters, currentKey) => {
  return selectedFilters[currentKey] && selectedFilters[currentKey].length > 0
    ? `&filter[${currentKey}][$range]=${selectedFilters[currentKey].join(',')}`
    : undefined;
};

const parseFilterToQueryUrl = selectedFilters =>
  selectedFilters &&
  Object.keys(selectedFilters)
    .map(currentKey => {
      if (currentKey === FilterGroupTypes.PUBLISHED_AT) {
        return parseToDateFilter(selectedFilters, currentKey);
      }
      if (currentKey === FilterGroupTypes.ACTIONS) {
        return parseToActionFilter(selectedFilters, currentKey);
      }
      if (currentKey === FilterGroupTypes.RATING) {
        return parseToRatingFilter(selectedFilters, currentKey);
      }
      return selectedFilters[currentKey] && selectedFilters[currentKey].length > 0
        ? `&filter[${currentKey}]=${encodeURIComponent(selectedFilters[currentKey])}`
        : undefined;
    })
    .filter(Boolean)
    .reduce((prev, current) => prev + current, '')
    .substring(1);

const parseSortFiltersToQueryUrl = sortPostsBy =>
  sortPostsBy &&
  Object.keys(sortPostsBy)
    .map(currentKey => {
      const sortByValue =
        sortPostsBy[currentKey] === SortBy.TIME_POSTED
          ? 'sort=-published_at'
          : sortPostsBy[currentKey] === SortBy.LAST_MODERATED
          ? 'sort=-last_moderated_at'
          : sortPostsBy[currentKey] === SortBy.PINNED
          ? 'sort=-pinned'
          : sortPostsBy[currentKey] === SortBy.RELEVANCE
          ? 'sort=-score'
          : '';

      if (
        sortPostsBy[currentKey] === SortBy.TIME_POSTED ||
        sortPostsBy[currentKey] === SortBy.LAST_MODERATED ||
        sortPostsBy[currentKey] === SortBy.PINNED ||
        sortPostsBy[currentKey] === SortBy.RELEVANCE
      ) {
        return sortByValue;
      } else {
        return sortPostsBy[currentKey] && sortPostsBy[currentKey].length > 0
          ? `filter[${currentKey}]=${encodeURIComponent(sortPostsBy[currentKey])}`
          : undefined;
      }
    })
    .filter(Boolean)
    .reduce((prev, current) => prev + current + '&', '')
    .slice(0, -1);

export const getPostsFromURL = (url, pageStart) => {
  return dispatch => {
    dispatch(getPostsStart());
    return ApiService.get(url)
      .then(res => {
        const start = res.data.data.length === 0 ? 0 : pageStart;
        const end = res.data.data.length === 0 ? 0 : pageStart + res.data.data.length - 1;

        dispatch(getPostsSuccess({ ...res.data, pageStart: start, pageEnd: end }));
      })
      .catch(err => dispatch(getPostsError(err)));
  };
};

export const getLastPage = nextLink => {
  return dispatch => {
    dispatch(getLastPageStart());
    return ApiService.get(nextLink || '/tints')
      .then(res => {
        const lastPage = res.data.data.length <= 0;
        dispatch(getLastPageSuccess(lastPage));
        dispatch(setIsLoading(false));
      })
      .catch(err => {
        dispatch(getLastPageError(err));
        dispatch(setIsLoading(false));
      });
  };
};

export const getSavedFilters = tintId => {
  return dispatch => {
    dispatch(getSavedFiltersStart());
    return ApiService.get(`/tints/${tintId}/saved_filters`)
      .then(res => {
        dispatch(getSavedFiltersSuccess(res.data.data));
      })
      .catch(err => dispatch(getSavedFiltersError(err)));
  };
};

export const postSavedFilters = ({ tintId, filterName, filters }) => {
  const data = {
    data: {
      type: 'saved_filter',
      attributes: {
        name: filterName,
        filter: filters,
      },
    },
  };

  return dispatch => {
    dispatch(postSavedFiltersStart());
    return ApiService.post(`/tints/${tintId}/saved_filters`, data)
      .then(res => {
        dispatch(postSavedFiltersSuccess(res.data.data));
      })
      .catch(err => dispatch(postSavedFiltersError(err)));
  };
};

export const removeSavedFilters = ({ tintId, savedFilterId }) => {
  return dispatch => {
    dispatch(removeSavedFiltersStart());
    return ApiService.delete(`/tints/${tintId}/saved_filters/${savedFilterId}`)
      .then(() => {
        dispatch(removeSavedFiltersSuccess(savedFilterId));
      })
      .catch(err => dispatch(removeSavedFiltersError(err)));
  };
};

export const updateSavedFilters = ({ tintId, savedFilterId, filterName, filters }) => {
  const data = {
    data: {
      type: 'saved_filter',
      id: savedFilterId,
      attributes: {
        name: filterName,
        filter: filters,
      },
    },
  };

  return dispatch => {
    dispatch(updateSavedFiltersStart());
    return ApiService.patch(`/tints/${tintId}/saved_filters/${savedFilterId}`, data)
      .then(res => {
        dispatch(updateSavedFiltersSuccess(res.data.data));
      })
      .catch(err => {
        dispatch(updateSavedFiltersError(err));
      });
  };
};

export const updatePosts = ({ updatedPosts }) => {
  const data = {
    data: updatedPosts,
  };

  return dispatch => {
    dispatch(updatePostsStart(updatedPosts));
    return ApiService.patch('/posts?include=product_tags.product,social_feed', data, {
      headers: ApiService.getBulkHeaders(),
    })
      .then(res => {
        dispatch(updatePostsSuccess(res.data.data));

        return res.data.data;
      })
      .catch(err => {
        dispatch(updatePostsError(err));
      });
  };
};

export const updatePost = ({ postId, postData }) => {
  const data = {
    data: {
      type: 'post',
      id: postId,
      attributes: {
        ...postData,
      },
    },
  };

  return dispatch => {
    dispatch(updatePostStart(postId));
    return ApiService.patch(`/posts/${postId}?include=product_tags.product,social_feed`, data)
      .then(res => {
        dispatch(updatePostSuccess(res.data.data));

        return res.data.data;
      })
      .catch(err => {
        dispatch(updatePostError(err));
      });
  };
};

export const recoverPost = ({ postId, status }) => {
  const data = {
    data: {
      type: 'post',
      id: postId,
      attributes: {
        status,
      },
    },
  };

  return dispatch => {
    dispatch(recoverPostStart(postId));
    return ApiService.patch(`/posts/${postId}`, data)
      .then(res => {
        dispatch(recoverPostSuccess(res.data.data.id));
      })
      .catch(err => {
        dispatch(recoverPostError(err));
      });
  };
};

export const deletePost = postId => {
  const data = {
    data: {
      type: 'post',
      id: postId,
      attributes: {
        status: 'deleted',
      },
    },
  };

  return dispatch => {
    dispatch(deletePostStart(postId));
    return ApiService.patch(`/posts/${postId}`, data)
      .then(res => {
        dispatch(deletePostSuccess(postId));

        return res.data.data;
      })
      .catch(err => {
        dispatch(deletePostError(err));
      });
  };
};

export const deletePosts = ({ data, teamId }) => {
  return dispatch => {
    dispatch(deletePostsStart());
    return ApiService.delete(
      `/teams/${teamId}/posts/`,
      {
        headers: ApiService.getBulkHeaders(),
      },
      { data }
    )
      .then(() => {
        dispatch(deletePostsSuccess(data));
      })
      .catch(err => {
        dispatch(deletePostsError(err));
        throw err;
      });
  };
};

export const addCustomPost = ({ tintId, postData }) => {
  const data = {
    data: {
      type: 'post',
      attributes: {
        tags: postData.tags,
        title: postData.title,
        text: postData.description,
        url: postData.url ? postData.url : undefined,
        media: postData.media?.map(m => m?.id),
        author: {
          image_id: postData.authorPhoto ? postData.authorPhoto : undefined,
          url: undefined,
          name: postData.authorName,
          username: undefined,
          email: undefined,
          phone: undefined,
        },
        highlighted: postData.highlighted,
        pinned: postData.pinned,
      },
    },
  };

  return dispatch => {
    dispatch(addCustomPostStart());
    return ApiService.post(`/tints/${tintId}/posts`, data)
      .then(res => {
        dispatch(addCustomPostSuccess(res.data.data));
      })
      .catch(err => {
        dispatch(addCustomPostError(err));
        throw err;
      });
  };
};

export const updateCustomPost = ({ tintId, postData }) => {
  const data = {
    data: {
      type: 'post',
      attributes: {
        tags: postData.tags,
        title: postData.title,
        text: postData.description,
        url: postData.url ? postData.url : undefined,
        author: {
          image_id: postData.authorPhoto ? postData.authorPhoto : undefined,
          url: undefined,
          name: postData.authorName,
          username: undefined,
          email: undefined,
          phone: undefined,
        },
        highlighted: postData.highlighted,
        pinned: postData.pinned,
      },
    },
  };

  return dispatch => {
    dispatch(updateCustomPostStart());
    return ApiService.patch(`/tints/${tintId}/posts`, data)
      .then(res => {
        dispatch(updateCustomPostSuccess(res.data.data));
      })
      .catch(err => {
        dispatch(updateCustomPostError(err));
        throw err;
      });
  };
};

export const getPostsAggregations = ({ tintId, pageSize = Config.defaultPostsPageSize, filters, sortBy }) => {
  const filterUrl = parseFilterToQueryUrl(filters);
  const sortPostByFilters = parseSortFiltersToQueryUrl(sortBy);

  const currentUrl = filterUrl
    ? `/tints/${tintId}/posts/aggregations?${filterUrl}${filterUrl ? '&' : ''}page[size]=${pageSize}`
    : `/tints/${tintId}/posts/aggregations?page[size]=${pageSize}`;

  return dispatch => {
    dispatch(getPostAggregationsStart());

    return ApiService.get(`${currentUrl}${sortPostByFilters ? '&' + sortPostByFilters : ''}`)
      .then(res => {
        dispatch(getPostAggregationsSuccess(res.data));
      })
      .catch(err => dispatch(getPostAggregationsError(err)));
  };
};

export const getPosts = ({ tintId, pageSize = 20, pageStart = 1, filters, sortBy, renderType }) => {
  const filterUrl = parseFilterToQueryUrl(filters);
  const sortPostByFilters = parseSortFiltersToQueryUrl(sortBy);

  let currentUrl = filterUrl
    ? `/tints/${tintId}/posts?${filterUrl}${
        filterUrl ? '&' : ''
      }page[size]=${pageSize}&include=product_tags.product,social_feed,cta_associations.cta,asset`
    : `/tints/${tintId}/posts?page[size]=${pageSize}&include=product_tags.product,social_feed,cta_associations.cta,asset`;

  if (renderType === PostRenderType.LIST) {
    currentUrl += ',replies,replies.user&collapse=source,external_id';
  }

  return dispatch => {
    dispatch(getPostsStart());

    return ApiService.get(`${currentUrl}${sortPostByFilters ? '&' + sortPostByFilters : ''}`)
      .then(res => {
        dispatch(
          getPostsSuccess({
            ...res.data,
            pageStart: pageStart,
            pageEnd: pageStart + res.data.data.length - 1,
          })
        );
      })
      .catch(err => dispatch(getPostsError(err)));
  };
};

export const getGlobalPosts = ({ pageSize = 20, pageStart = 1, filters, sortBy }) => {
  const filterUrl = parseFilterToQueryUrl(filters);
  const sortPostByFilters = parseSortFiltersToQueryUrl(sortBy);

  const currentUrl = filterUrl
    ? `/posts?${filterUrl}${filterUrl ? '&' : ''}page[size]=${pageSize}&include=product_tags.product,tint,social_feed`
    : `/posts?page[size]=${pageSize}&include=product_tags.product,tint,social_feed`;

  return dispatch => {
    dispatch(getGlobalPostsStart());

    return ApiService.get(`${currentUrl}${sortPostByFilters ? '&' + sortPostByFilters : ''}`)
      .then(res => {
        dispatch(
          getGlobalPostsSuccess({
            ...res.data,
            pageStart: pageStart,
            pageEnd: pageStart + res.data.data.length - 1,
          })
        );
      })
      .catch(err => dispatch(getGlobalPostsError(err)));
  };
};

export const getPostRightRequests = ({ tintId, id }) => {
  const currentUrl = `/tints/${tintId}/posts?include=right_requests&filter[id]=${id}`;

  return dispatch => {
    dispatch(getPostRightsRequestStart());

    return ApiService.get(currentUrl)
      .then(res => {
        dispatch(getPostRightsRequestSuccess({ data: res.data?.included?.[0], id }));
        return res.data?.included?.[0];
      })
      .catch(err => dispatch(getPostRightsRequestError(err)));
  };
};

export const postSimilarImage = ({ tintId, pageSize = 20, pageStart = 1, filters, src, sortBy, postId }) => {
  return dispatch => {
    dispatch(postSimilarImageStart());
    const filterUrl = parseFilterToQueryUrl(filters);
    const sortPostByFilters = parseSortFiltersToQueryUrl(sortBy);
    const postIdentifier = postId ? postId : src;

    const currentUrl = filterUrl
      ? `/tints/${tintId}/posts?${filterUrl}${
          filterUrl ? '&' : ''
        }page[size]=${pageSize}&filter[visually_similar_to]=${postIdentifier}&include=product_tags.product,social_feed,cta_associations.cta`
      : `/tints/${tintId}/posts?page[size]=${pageSize}&filter[visually_similar_to]=${postIdentifier}&include=product_tags.product,social_feed,cta_associations.cta`;

    return ApiService.get(`${currentUrl}${sortPostByFilters ? '&' + sortPostByFilters : ''}`)
      .then(res => {
        dispatch(
          postSimilarImageSuccess({ ...res.data, pageStart: pageStart, pageEnd: pageStart + res.data.data.length - 1 })
        );
      })
      .catch(err => {
        dispatch(postSimilarImageError(err));
        throw err;
      });
  };
};
