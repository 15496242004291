import React from 'react';
import './dropdown-search-input.sass';

export const DropdownSearchInput = ({ searchTerm = '', onChange, onFocus }) => {
  return (
    <div className='dropdown-search-input'>
      <input
        placeholder='Search...'
        value={searchTerm}
        onChange={onChange}
        onFocus={onFocus}
        data-testid='dropdown-search-input'
      />
    </div>
  );
};
