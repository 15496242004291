import React, { useState, useEffect } from 'react';
import './experience-captcha-settings.sass';
import DropdownButton, { ButtonType } from '../../../../../../components/interface/dropdown/button/dropdown-button';
import { DropdownHeader } from '../../../../../../components/interface/dropdown/button/header/dropdown-header';
import DropdownSortList from '../../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list';
import InputPrimary from '../../../../../../components/interface/inputs/input-primary/input-primary';
import FeatureGuard from '../../../../../../guards/feature-guard';
import { GuardNames } from '../../../../../../guards/guards-list';

const CaptchaTypes = {
  H_CAPTCHA: 'h_captcha',
  GOOGLE_RECAPTCHA: 'google_recaptcha',
  NONE: 'none',
};

const captchaOptions = [
  { value: CaptchaTypes.NONE, name: 'None' },
  { value: CaptchaTypes.GOOGLE_RECAPTCHA, name: 'reCAPTCHA' },
  { value: CaptchaTypes.H_CAPTCHA, name: 'hCaptcha' },
];

export const ExperienceCaptchaSettings = ({ dataUpdate, experience, draftExperience }) => {
  const draftCaptcha = draftExperience.attributes.captcha_type;
  const draftCaptchaPublicKey = draftExperience.attributes.captcha_public_key;
  const savedCaptcha = experience.attributes.captcha_type;

  const [selectedCaptcha, setSelectedCaptcha] = useState();
  const [isPrivateKeyVisible, setIsPrivateKeyVisible] = useState(false);

  const isCaptchaSelected = captchaType =>
    [CaptchaTypes.H_CAPTCHA, CaptchaTypes.GOOGLE_RECAPTCHA].includes(captchaType);

  const showPrivateKeyInput = () => setIsPrivateKeyVisible(true);

  useEffect(() => {
    const value = draftCaptcha ? draftCaptcha : CaptchaTypes.NONE;
    const captcha = captchaOptions.find(option => option.value === value);

    setSelectedCaptcha(captcha);
  }, [draftCaptcha]);

  const onCaptchaSelect = ({ value }) => {
    switch (value) {
      case CaptchaTypes.GOOGLE_RECAPTCHA:
      case CaptchaTypes.H_CAPTCHA:
        dataUpdate({ captcha_type: value });
        break;

      case CaptchaTypes.NONE:
      default:
        dataUpdate({
          captcha_type: null,
          captcha_public_key: null,
          captcha_private_key: null,
        });
    }
  };

  const renderCaptchaSelectDropdown = () => {
    return (
      <div className='input-primary'>
        <label>Form Verification</label>
        <DropdownButton
          dropdownHeader={props => <DropdownHeader {...props} />}
          dropdownList={props => <DropdownSortList {...props} />}
          currentItem={selectedCaptcha}
          list={captchaOptions}
          iconRight='fa fa-caret-down'
          buttonType={ButtonType.BUTTON_GRAY_BORDER}
          onChangeValue={onCaptchaSelect}
        />
      </div>
    );
  };

  const renderCaptchaPublicKeyInput = () => {
    return (
      <InputPrimary
        label='Public Key'
        id='captcha-public-key'
        name='captcha_public_key'
        type='text'
        placeholder='Enter Public Key'
        value={draftCaptchaPublicKey || ''}
        handleChange={e => {
          dataUpdate({ captcha_public_key: e.target.value });
        }}
      />
    );
  };

  const renderCaptchaPrivateKeyInput = () => {
    return (
      <>
        <InputPrimary
          label='Private Key'
          id='captcha-private-key'
          name='captcha_private_key'
          type='text'
          placeholder='Enter Private Key'
          handleChange={e => {
            dataUpdate({ captcha_private_key: e.target.value });
          }}
        />
        <span className='tint-experience-settings__description'>
          Enter the private key {selectedCaptcha?.name || ''} provided during registration.
        </span>
      </>
    );
  };

  const renderCaptchaInputs = () => {
    return (
      <>
        {isCaptchaSelected(draftCaptcha) ? (
          <>
            {renderCaptchaPublicKeyInput()}
            <>
              {savedCaptcha && !isPrivateKeyVisible ? (
                <div className='tint-experience-settings__private-key'>
                  <label>Private Key</label>
                  <span className='tint-experience-settings__description'>
                    Already set,{' '}
                    <span className='tint-experience-settings__link' onClick={showPrivateKeyInput}>
                      change
                    </span>
                  </span>
                </div>
              ) : (
                renderCaptchaPrivateKeyInput()
              )}
            </>
          </>
        ) : null}
      </>
    );
  };

  return (
    <FeatureGuard featureName={GuardNames.CAPTCHA}>
      <div className='tint-experience-captcha-settings'>
        {renderCaptchaSelectDropdown()}
        {renderCaptchaInputs()}
      </div>
    </FeatureGuard>
  );
};
