import React from 'react';
import './social-feed-counter.sass';
import ColorParser from '../../../../../../../services/color-parser/color-parser';
import PropTypes from 'prop-types';

const SocialFeedCounter = ({ title, count, style, baseClass, isCountingEnd }) => {
  const defaultValue = '000000';

  function setStringCountNumber() {
    return !count ? defaultValue : `${defaultValue.slice(0, defaultValue.length - count.toString().length)}${count}`;
  }

  function renderFinishPopup() {
    return (
      isCountingEnd && (
        <div className='social-feed-counter__finish-popup' data-testid='socialFeedCounterFinishedPopupId'>
          <span>
            <strong>Counting finished!</strong>
          </span>
        </div>
      )
    );
  }

  return (
    <div
      data-testid='socialFeedCounterSection'
      className={`social-feed-counter base-class--${baseClass}`}
      style={{
        backgroundColor: ColorParser.defaultColor(style.counterBackgroundColor),
      }}>
      <p
        data-testid='socialFeedCounter'
        className='social-feed-counter__count'
        style={{ color: ColorParser.defaultColor(style.counterTextColor) }}>
        {setStringCountNumber()}
      </p>

      <p
        className='social-feed-counter__text'
        style={{
          color: ColorParser.defaultColor(style.counterLabelTextColor),
        }}>
        {title}
      </p>
      {renderFinishPopup()}
    </div>
  );
};

export default SocialFeedCounter;

SocialFeedCounter.propTypes = {
  isCountingEnd: PropTypes.bool,
  title: PropTypes.string,
  baseClass: PropTypes.string,
  count: PropTypes.number,
  styles: PropTypes.shape({
    counterBackgroundColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    counterTextColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    counterLabelTextColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};
