import { ACTIONS } from '../actions/tint-editor.actions';

export const initialState = {
  data: [],
  isFetching: false,
  error: undefined,
};

export const exportPosts = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.EXPORT_POSTS.REQUEST:
    case ACTIONS.EXPORT_POSTS_STATUS.REQUEST:
      return {
        ...state,
        ...{
          isFetching: true,
          isError: false,
        },
      };

    case ACTIONS.EXPORT_POSTS.SUCCESS:
      return {
        ...state,
        ...{
          data: state.data
            .filter(item => {
              return item.tintId !== action.payload.tintId;
            })
            .concat(action.payload),
          isFetching: false,
          isError: false,
        },
      };

    case ACTIONS.EXPORT_POSTS_STATUS.SUCCESS:
      return {
        ...state,
        ...{
          data: state.data.map(exportPost => {
            if (exportPost.id === action.payload.id) exportPost.attributes.status = action.payload.status;
            return exportPost;
          }),
          isFetching: false,
          isError: false,
        },
      };

    case ACTIONS.EXPORT_POSTS.FAILURE:
    case ACTIONS.EXPORT_POSTS_STATUS.FAILURE:
      return {
        ...state,
        ...{
          isError: action.payload,
          isFetching: false,
        },
      };
    default:
      return state;
  }
};
