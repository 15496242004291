import { useState } from 'react';
import * as OAuth from 'oauth4webapi';
import { Config } from '../../../utils/config';
import { ApiService } from '../../../services/api-service/api-service';
import {
  BROADCAST_MESSAGE_TYPE,
  BroadcastChannelService,
} from '../../../services/broadcast-channel/broadcast-channel.service';
import { TabSharingService } from '../../../services/tab-sharing/tab-sharing.service';

export const oauthConfig = {
  clientId: Config.getEnvironmentVariable('CLIENT_ID'),
  authorizationEndpoint: Config.getApiUrl() + '/oauth/authorize',
  tokenEndpoint: Config.getApiUrl() + '/oauth/token',
  redirectUri: `${Config.baseUrl()}/app/auth-verifier`,
  scopes: ['all'],
};

export const ACCESS_TOKEN_KEY = 'access_token';
export const REFRESH_TOKEN_KEY = 'refresh_token';

export const getAccessToken = () => {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
};

export const setAccessToken = token => {
  localStorage.setItem(ACCESS_TOKEN_KEY, token);
};

export const removeAccessToken = () => {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
};

export const setRefreshToken = token => {
  sessionStorage.setItem(REFRESH_TOKEN_KEY, token);
};

export const getRefreshToken = () => {
  return sessionStorage.getItem(REFRESH_TOKEN_KEY);
};

export const useOAuth = () => {
  const [authUrl, setAuthUrl] = useState('');
  const [token, setToken] = useState(null);
  const [error, setError] = useState(null);

  const redirectToLogin = () => {
    generatePKCE().then(authUrl => {
      window.location.href = authUrl;
    });
  };

  const clearStorage = () => {
    removeAccessToken();
    sessionStorage.removeItem(REFRESH_TOKEN_KEY);
    sessionStorage.removeItem('code_verifier');
    ApiService.setAuth({ token: null });
  };

  const onAppLoadingInit = () => {
    const token = getAccessToken();

    if (!token) {
      generatePKCE().then(authUrl => {
        window.location.href = authUrl;
      });
      return;
    }

    ApiService.setAuth({ token, requestRefreshToken, redirectToLogin });
  };

  const generatePKCE = async () => {
    const code_verifier = OAuth.generateRandomCodeVerifier();
    const code_challenge = await OAuth.calculatePKCECodeChallenge(code_verifier);
    sessionStorage.setItem('code_verifier', code_verifier);
    sessionStorage.setItem('redirect_url', window.location.href);

    const url = new URL(oauthConfig.authorizationEndpoint);
    url.searchParams.append('client_id', oauthConfig.clientId);
    url.searchParams.append('response_type', 'code');
    url.searchParams.append('redirect_uri', oauthConfig.redirectUri);
    url.searchParams.append('scope', oauthConfig.scopes.join(' '));
    url.searchParams.append('code_challenge', code_challenge);
    url.searchParams.append('code_challenge_method', 'S256');
    setAuthUrl(url.toString());
    return url.toString();
  };

  const requestRefreshToken = async () => {
    const refreshToken = sessionStorage.getItem(REFRESH_TOKEN_KEY);
    if (!refreshToken) {
      throw new Error('No refresh token found');
    }
    const params = new URLSearchParams();
    params.append('client_id', oauthConfig.clientId);
    params.append('grant_type', 'refresh_token');
    params.append('refresh_token', refreshToken);
    const response = await fetch(oauthConfig.tokenEndpoint, {
      method: 'POST',
      body: params,
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    });
    const data = await response.json();
    if (response.ok) {
      setAccessToken(data.access_token);
      setRefreshToken(data.refresh_token);
      sessionStorage.removeItem('code_verifier');
      ApiService.setAuth({ token: data.access_token });
      setToken(data);
    } else {
      clearStorage();
      redirectToLogin();
      return 'error';
    }
  };
  const handleCallback = async code => {
    try {
      const code_verifier = sessionStorage.getItem('code_verifier');
      if (!code_verifier) {
        throw new Error('Code verifier not found in session storage');
      }

      const params = new URLSearchParams();
      params.append('grant_type', 'authorization_code');
      params.append('code', code);
      params.append('redirect_uri', oauthConfig.redirectUri);
      params.append('client_id', oauthConfig.clientId);
      params.append('code_verifier', code_verifier);

      const response = await fetch(oauthConfig.tokenEndpoint, {
        method: 'POST',
        body: params,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      const data = await response.json();
      if (response.ok) {
        setAccessToken(data.access_token);
        setRefreshToken(data.refresh_token);
        sessionStorage.removeItem('code_verifier');
        ApiService.setAuth({ token: data.access_token });
        setToken(data);
      } else {
        setError(data?.error_description || 'Token request failed');
      }
    } catch (err) {
      setError(err);
    }
  };

  const logout = async () => {
    try {
      const accessToken = getAccessToken();
      const params = new URLSearchParams();
      params.append('client_id', oauthConfig.clientId);
      params.append('token', accessToken);

      const response = await ApiService.pureInstance().post(Config.getApiUrl() + '/oauth/revoke', params, {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      });

      if (response.status === 200) {
        clearStorage();
        window.location.href = `${Config.getApiUrl()}/credentials/sign_out`;
      } else {
        setError(response.data?.error_description || 'Token revocation failed');
      }
    } catch (err) {
      setError(err.message || 'An error occurred');
    }
  };

  return {
    authUrl,
    token,
    error,
    handleCallback,
    generatePKCE,
    logout,
    requestRefreshToken,
    onAppLoadingInit,
    redirectToLogin,
  };
};
