import React, { forwardRef, useState } from 'react';
import { useSelector } from 'react-redux';
import './input-file.sass';
import ValidationAlert from '../validation-alert/validation-alert';
import PropTypes from 'prop-types';
import { FileStack } from '../../../../services/filestack/file-stack';
import { getMaxImageSizePlanLimit } from '../../../../reducers/session';

const InputFile = forwardRef(
  (
    {
      name = 'file',
      id = 'file',
      accept,
      value,
      label,
      placeholder,
      error,
      touched,
      handleBlur,
      dirty,
      handleChange,
      readonly,
      disabled,
      style,
      useFileStack = false,
      isCropped = true,
      maxSize,
      defaultCropImageDimensions = {
        width: 64,
        height: 64,
      },
      children,
    },
    ref
  ) => {
    const isError = error && touched;
    const errorClassName = isError ? 'tint-input-file--error' : '';
    const validClassName = !error && touched !== undefined && dirty ? 'tint-input-file--valid' : '';
    const [buttonLabel, setButtonLAbel] = useState(label);
    const [file, setFile] = useState();

    const maxImageSizePlanLimit = useSelector(getMaxImageSizePlanLimit);

    const clearCurrentFile = () => {
      setFile(undefined);
      setButtonLAbel(label);
      handleChange(undefined);
    };

    const openFilePicker = () => {
      if (file) {
        clearCurrentFile();
        return;
      }

      const fileStack = new FileStack();
      fileStack.openFilePicker({
        accept: accept || 'image/*',
        maxSize: maxSize || maxImageSizePlanLimit,
        transformations: {
          circle: false,
          ...(isCropped && {
            crop: {
              force: true,
              aspectRatio: defaultCropImageDimensions.width / defaultCropImageDimensions.height,
            },
          }),
        },
        onFileUploadStarted: () => {},
        onUploadDone: res => {
          setButtonLAbel(`Clean ${res.filesUploaded[0]?.filename}`);
          setFile(res.filesUploaded[0]?.url);
          handleChange(res.filesUploaded[0]?.url);
        },
      });
    };

    return useFileStack ? (
      <div className={`tint-input-file ${errorClassName} ${validClassName}`} onClick={openFilePicker}>
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            text: buttonLabel,
          })
        )}
      </div>
    ) : (
      <div className={`tint-input-file ${errorClassName} ${validClassName}`} htmlFor={id}>
        <input
          accept={accept}
          ref={ref}
          placeholder={placeholder}
          onChange={handleChange}
          onClick={e => (e.target.value = null)}
          onBlur={handleBlur}
          name={name}
          type='file'
          id={id}
          value={value}
          readOnly={readonly}
          multiple
          disabled={disabled}
          style={{ ...style }}
        />

        {!useFileStack && children ? (
          React.Children.map(children, child =>
            React.cloneElement(child, {
              text: buttonLabel,
            })
          )
        ) : (
          <label htmlFor={id}>{label}</label>
        )}
        <ValidationAlert errorMsg={error} isVisible={isError} />
      </div>
    );
  }
);

InputFile.displayName = 'InputFile';
export default InputFile;

InputFile.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  ref: PropTypes.any,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
  value: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.any,
};
