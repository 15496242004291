import React from 'react';
import './survey-edit-answer.sass';
import * as PropTypes from 'prop-types';
import { FileStack } from '../../../../../../../../services/filestack/file-stack';
import { Button } from '../../../../../../../../components/interface/button/button';
import EditInput from '../../../../edit-input/edit-input';
import { EditTextArea } from '../../../../index';
import { Config } from '../../../../../../../../utils/config';

const SurveyEditAnswer = ({
  answer,
  updateAnswerData,
  answers,
  getTranslation,
  onTranslationUpdate,
  hasAdvancedSettings,
  keyInputValidation,
  index,
  setState,
  setApplyButtonDisabled,
  maxVideoFileSize,
}) => {
  const onUploadClick = () => {
    new FileStack().openFilePicker({
      maxSize: maxVideoFileSize,
      onUploadDone: res => {
        if (res.filesUploaded.length > 0) {
          updateAnswerData({
            answers: updateAnswer('imageUrl', res.filesUploaded[0].url),
          });
        }
      },
    });
  };

  const renderViewWithImage = () => {
    return (
      <div className='tint-edit-header-content__center'>
        <div className='tint-edit-upload-design__image' style={{ backgroundImage: `url(${answer.imageUrl})` }} />
        <Button
          onClick={() => {
            updateAnswerData({
              answers: updateAnswer('imageUrl', undefined),
            });
          }}
          type='gray'
          size='full-width'
          text='Remove Image'
        />
      </div>
    );
  };

  const updateAnswer = (propertyName, newValue) => {
    return answers.map(e => {
      if (e.id === answer.id) {
        return {
          ...e,
          [propertyName]: newValue,
        };
      }

      return e;
    });
  };

  const removeAnswer = () => {
    updateAnswerData({
      answers: (answers = answers.filter(currentAnswer => {
        return currentAnswer.id !== answer.id;
      })),
    });
  };

  const keyInputUpdate = (value, i) => {
    let _keyInputValidation;
    if (Config.regExp.formIds.test(value)) {
      _keyInputValidation = {
        keyInputValidation: { ...keyInputValidation, ...{ [i]: '' } },
      };
      updateAnswerData({
        answers: updateAnswer('key', value),
      });
    } else {
      _keyInputValidation = {
        keyInputValidation: {
          ...keyInputValidation,
          ...{
            [i]: 'Invalid format, input can contain only _, numbers and letters',
          },
        },
      };
    }
    setState({ keyInputValidation: _keyInputValidation.keyInputValidation }, () =>
      updateValidation(_keyInputValidation.keyInputValidation)
    );
  };

  const updateValidation = _keyInputValidation => {
    const isFormInvalid = Object.values(_keyInputValidation).every(e => e !== '');
    setApplyButtonDisabled(isFormInvalid);
  };

  return (
    <div className='tint-edit-answer'>
      <EditInput
        defaultValue={getTranslation(answer.title) || 'Title'}
        onChange={e => {
          onTranslationUpdate(answer.title, e.target.value);
        }}
        rightIcon='far fa-trash-alt'
        rightIconAction={removeAnswer}
        label={`Answer ${answers.indexOf(answer) + 1}`}
        placeholder='Title'
      />

      <EditTextArea
        defaultValue={getTranslation(answer.description)}
        onChange={e => {
          onTranslationUpdate(answer.description, e.target.value);
        }}
        placeholder='Description'
      />

      {hasAdvancedSettings ? (
        <EditInput
          touched={true}
          error={keyInputValidation[index] !== ''}
          errorMsg={keyInputValidation[index]}
          onChange={event => {
            keyInputUpdate(event.target.value, index);
          }}
          placeholder='Form Field ID'
          defaultValue={answer.key}
        />
      ) : null}

      {answer.imageUrl === undefined ? (
        <Button
          onClick={onUploadClick}
          type='gray'
          size='full-width'
          text='Upload image'
          icon='far fa-arrow-to-top'
          className='tint-edit-answer__upload-button'
        />
      ) : (
        renderViewWithImage(answer)
      )}
    </div>
  );
};

SurveyEditAnswer.propTypes = {
  totalVotes: PropTypes.number,
  updateAnswerData: PropTypes.func,
  answers: PropTypes.array,
};

export default SurveyEditAnswer;
