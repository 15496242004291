import { ACTIONS } from '../../actions/marketing-integrations/marketing-integrations-folders.actions';
import { ExpirationStatuses } from '../../components/marketing-integrations/table/row/marketing-integrations-table-row';
import { marketingIntegrationsSources } from '../../utils/marketing-integrations-sources/marketing-integrations-sources';

export const initialState = {
  data: [],
  isFetching: false,
  error: undefined,
};

export const marketingIntegrationsFolders = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_MARKETING_INTEGRATIONS_FOLDERS.SUCCESS:
      return {
        ...state,
        data: state.data
          .filter(item => {
            return item.id !== action.payload.data.id;
          })
          .concat(action.payload.data),
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.GET_MARKETING_INTEGRATIONS_FOLDERS.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };

    case ACTIONS.GET_MARKETING_INTEGRATIONS_FOLDERS.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

const getSourceName = source => {
  const item = marketingIntegrationsSources.find(e => e.source === source);
  return item ? `(${item.title})` : '';
};

export const mapMarketingIntegrationsAccountsForDropdown = list =>
  list.reduce((acc, item) => {
    if (item.attributes.status === ExpirationStatuses.READY) {
      if (item.attributes.source === 'twitter') return acc;

      acc.push({
        value: item.id,
        name: `${item.attributes.name || 'Unknown'} ${getSourceName(item.attributes.source)}`,
      });
    }
    return acc;
  }, []);

export const mapMarketingIntegrationsFoldersForDropdown = list =>
  list.map(e => ({ value: e.data.id, name: e.data.attributes.name || 'Unknown' }));
