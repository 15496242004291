import React from 'react';
import { DropdownHeader } from '../../../../components/interface/dropdown/button/header/dropdown-header';
import DropdownSortList from '../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list';
import DropdownButton, { ButtonType } from '../../../../components/interface/dropdown/button/dropdown-button';
import { useCurrentAssetSettingList } from '../../hooks/use-current-asset-setting-list';

export const AssetStatus = {
  ARCHIVED: 'archived',
  ACTIVE: 'active',
  DELETED: 'deleted',
};

export const AssetSettingsDropdown = ({ selectedAssetLength, selectedAssets, selectedVariantIds, variantUrl }) => {
  const { currentSettingsList, onSettingChange: onSettingDropdownChange } = useCurrentAssetSettingList({
    selectedAssets,
    selectedAssetLength,
    selectedVariantIds,
    variantUrl,
  });

  return (
    <DropdownButton
      dropdownHeader={props => <DropdownHeader {...props} />}
      dropdownList={props => <DropdownSortList {...props} />}
      list={currentSettingsList}
      iconCenter='fas fa-ellipsis-h'
      buttonType={ButtonType.BUTTON_PRIMARY}
      onChangeValue={onSettingDropdownChange}
    />
  );
};

export default AssetSettingsDropdown;
