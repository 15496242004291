import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './edit-modal-switcher.sass';

export class EditModalSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isContentVisible: true,
    };
  }

  setContentVisibility(isContentVisible) {
    if (this.state.isContentVisible === isContentVisible) {
      return undefined;
    }
    this.setState({ isContentVisible });
    this.props.modalSwitch && this.props.modalSwitch();
  }

  render() {
    return (
      <div className='tint-edit-switcher'>
        <div className='tint-edit-switcher__button-container'>
          <button
            className={`${this.state.isContentVisible ? 'active' : ''}`}
            onClick={() => {
              this.setContentVisibility(true);
            }}>
            <span>{this.props.leftButtonText || 'Content'}</span>
          </button>
          <button
            className={`${!this.state.isContentVisible ? 'active' : ''}`}
            onClick={() => {
              this.setContentVisibility(false);
            }}>
            <span>{this.props.rightButtonText || 'Design'}</span>
          </button>
        </div>
        <div
          className={`${
            this.state.isContentVisible ? '' : 'tint-edit-switcher__invisible'
          } tint-edit-switcher__content`}>
          {React.cloneElement(this.props.children[0], { ...this.props })}
        </div>
        <div
          className={`${
            !this.state.isContentVisible ? '' : 'tint-edit-switcher__invisible'
          } tint-edit-switcher__design`}>
          {React.cloneElement(this.props.children[1], { ...this.props })}
        </div>
      </div>
    );
  }
}

EditModalSwitcher.propTypes = {
  modalSwitch: PropTypes.func,
  leftButtonText: PropTypes.string,
  rightButtonText: PropTypes.string,
};
