import { SocialFeedsFooterLabels } from './helpers';
import { SidebarIcons, SidebarItemTypes } from '../utils/meta/consts';
import { validationSchemaInfluencerContent } from '../utils/validation-schema/influencer-schema';
import { GuardNames } from '../../../../../../../guards/guards-list';

const additionalContentInputs = [
  {
    id: 'name',
    backendId: 'name',
    label: 'INFLUENCER NAME',
    placeholder: 'Full Name',
    type: SidebarItemTypes.TEXT,
  },
  {
    id: 'email',
    backendId: 'email',
    label: 'EMAIL',
    required: true,
    placeholder: 'Email Address',
    type: SidebarItemTypes.TEXT,
  },
  {
    id: 'message',
    backendId: 'message',
    label: 'MESSAGE',
    required: true,
    placeholder: 'Enter Message',
    lineBreak: true,
    type: SidebarItemTypes.TEXTAREA,
    styles: {
      rows: '6',
    },
  },
  {
    id: 'right_request_term_id',
    backendId: 'right_request_term_id',
    type: SidebarItemTypes.TERMS_CONDITIONS,
    lineBreak: true,
  },
  {
    id: 'date',
    type: SidebarItemTypes.DATE_TEXT,
    placeholder: 'MM/DD/YYYY',
    description: 'Enter dates below to control when or what content is ingested from influencers.',
    lineBreak: true,
  },
  {
    id: 'hashtags',
    label: 'REQUIRED HASHTAGS',
    placeholder: 'Enter Hashtags',
    type: SidebarItemTypes.TEXT,
    description:
      'Add hashtag(s) below to only ingest content where they are included in the post descriptions. To add multiple hashtags, separate each with commas.',
  },
];

export const influencer = [
  {
    header: 'Add Influencer Content',
    description: 'Choose a Social Feed type below:',
    typeList: [
      {
        id: 'instagram_business',
        title: 'Instagram',
        description: 'This social feed type allows you to ingest content from an influencer’s Instagram.',
        svgType: 'instagram',
      },
      {
        id: 'facebook',
        title: 'Facebook',
        description: 'This social feed type allows you to ingest content from an influencer’s Facebook.',
        svgType: 'facebook',
      },
      {
        id: 'twitter',
        title: 'X',
        description: 'This social feed type allows you to ingest content from an influencer’s X.',
        svgType: 'twitter',
      },
      {
        id: 'threads',
        title: 'Threads',
        description: 'This social feed type allows you to ingest content from an influencer’s Threads.',
        svgType: 'threads',
        featureName: GuardNames.SOCIAL_FEEDS_THREADS,
      },
    ],
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepNext,
    },
    zendeskUrl: undefined,
    isAlert: false,
    formSchema: undefined,
  },
  {
    header: undefined,
    description: undefined,
    zendeskUrl: undefined,
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.finalStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext,
    },
    accountSourceType: 'influencer',
    formSchema: {
      instagram_business: {
        validationSchema: validationSchemaInfluencerContent(),
        submitMapper: values => ({
          external_id: values.username,
          name: values.name,
          contact: {
            email: values.email,
            name: values.name,
            message: values.message,
          },
          message: values.message,
          pre_processing_rules: [
            {
              conditions: {
                published_at: {
                  $gte: values.start_at ? new Date(values.start_at).toISOString() : undefined,
                  $lt: values.end_at ? new Date(values.end_at).toISOString() : undefined,
                },
                text: values.hashtags ? values.hashtags : undefined,
              },
            },
          ],
          right_request_term_id: values.right_request_term_id,
          type: 'influencer',
          source: 'instagram_business',
        }),
        inputs: [
          {
            id: 'username',
            label: 'INSTAGRAM USERNAME',
            placeholder: 'Username',
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.TEXT,
          },
          ...additionalContentInputs,
        ],
      },
      facebook: {
        validationSchema: validationSchemaInfluencerContent(),
        submitMapper: values => ({
          external_id: values.username,
          name: values.name,
          contact: {
            email: values.email,
            name: values.name,
            message: values.message,
          },
          message: values.message,
          pre_processing_rules: [
            {
              conditions: {
                published_at: {
                  $gte: values.start_at ? new Date(values.start_at).toISOString() : undefined,
                  $lt: values.end_at ? new Date(values.end_at).toISOString() : undefined,
                },
                text: values.hashtags ? values.hashtags : undefined,
              },
            },
          ],
          right_request_term_id: values.right_request_term_id,
          type: 'influencer',
          source: 'facebook',
        }),
        inputs: [
          {
            id: 'username',
            label: 'FACEBOOK USERNAME',
            placeholder: 'Username',
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.TEXT,
          },
          ...additionalContentInputs,
        ],
      },
      twitter: {
        validationSchema: validationSchemaInfluencerContent(),
        submitMapper: values => ({
          external_id: values.username,
          name: values.name,
          contact: {
            email: values.email,
            name: values.name,
            message: values.message,
          },
          message: values.message,
          pre_processing_rules: [
            {
              conditions: {
                published_at: {
                  $gte: values.start_at ? new Date(values.start_at).toISOString() : undefined,
                  $lt: values.end_at ? new Date(values.end_at).toISOString() : undefined,
                },
                text: values.hashtags ? values.hashtags : undefined,
              },
            },
          ],
          right_request_term_id: values.right_request_term_id,
          type: 'influencer',
          source: 'twitter',
        }),
        inputs: [
          {
            id: 'username',
            label: 'X USERNAME',
            placeholder: 'Username',
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.TEXT,
          },
          ...additionalContentInputs,
        ],
      },
      threads: {
        validationSchema: validationSchemaInfluencerContent(),
        submitMapper: values => ({
          external_id: values.username,
          name: values.name,
          contact: {
            email: values.email,
            name: values.name,
            message: values.message,
          },
          message: values.message,
          pre_processing_rules: [
            {
              conditions: {
                published_at: {
                  $gte: values.start_at ? new Date(values.start_at).toISOString() : undefined,
                  $lt: values.end_at ? new Date(values.end_at).toISOString() : undefined,
                },
                text: values.hashtags ? values.hashtags : undefined,
              },
            },
          ],
          right_request_term_id: values.right_request_term_id,
          type: 'influencer',
          source: 'threads',
        }),
        inputs: [
          {
            id: 'username',
            label: 'THREADS USERNAME',
            placeholder: 'Username',
            icon: SidebarIcons.MAIL,
            type: SidebarItemTypes.TEXT,
          },
          ...additionalContentInputs,
        ],
      },
    },
  },
];
