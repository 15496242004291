import { Config } from '../../../../utils/config';
import { MODAL_TYPE } from '../../reducers/product-feeds/add-product-feeds-modal.reducer';
import { GuardNames } from '../../../../guards/guards-list';
import { ProductAccounts } from '../../../../types/product-accounts';
import { iconTypes } from '../../../../utils/icon-types/icon-types';
import { ProductAccountsTranslations } from '../../../../utils/translations/product-accounts';

export const ProductAccount = {
  [ProductAccounts.FACEBOOK]: {
    id: MODAL_TYPE.FACEBOOK,
    backendMapping: 'facebook',
    frontendMapping: 'Facebook',
    title: 'Facebook',
    description: 'Connect your Facebook Business account in order to import product catalog feeds.',
    icon: 'facebook',
    productView: true,
    feedView: true,
    filter: true,
  },
  [ProductAccounts.SHOPIFY]: {
    id: MODAL_TYPE.SHOPIFY,
    backendMapping: 'shopify',
    frontendMapping: 'Shopify',
    title: 'Shopify',
    description: 'Connect your Shopify account in order to import product catalog feeds.',
    icon: 'shopify',
    link: Config.url.shopify,
    linkDescription: 'Install via Shopify',
    productView: true,
    feedView: true,
    filter: true,
  },
  [ProductAccounts.SALESFORCE_COMMERCE_CLOUD]: {
    id: MODAL_TYPE.SALESFORCE_COMMERCE_CLOUD,
    backendMapping: 'salesforce_commerce_cloud',
    frontendMapping: 'Salesforce',
    title: 'Salesforce Commerce Cloud',
    description: 'Connect your Salesforce Commerce Cloud account in order to import product catalog feeds.',
    icon: 'salesforce_commerce_cloud',
    featureNames: GuardNames.PRODUCT_ACCOUNT_SALESFORCE,
    productView: true,
    feedView: true,
    filter: true,
    isBeta: true,
  },
  [ProductAccounts.WOOCOMMERCE]: {
    id: MODAL_TYPE.WOOCOMMERCE,
    backendMapping: 'woocommerce',
    frontendMapping: 'WooCommerce',
    title: 'WooCommerce',
    description: 'Connect your WooCommerce account in order to import product catalog feeds.',
    icon: 'woocommerce',
    productView: true,
    feedView: true,
    filter: true,
  },
  [ProductAccounts.MAGENTO]: {
    id: MODAL_TYPE.MAGENTO,
    backendMapping: 'magento',
    frontendMapping: 'Magento',
    title: 'Magento',
    description: 'Connect your Magento account in order to import product catalog feeds.',
    icon: 'magento',
    productView: true,
    feedView: true,
    filter: true,
  },
  [ProductAccounts.FTP]: {
    id: MODAL_TYPE.FTP,
    backendMapping: 'ftp',
    frontendMapping: 'FTP',
    title: 'FTP',
    description: 'Sync your products from services that serve files via FTP such as Feedonomics.',
    icon: 'ftp',
    productView: true,
    feedView: true,
    filter: true,
  },
  [ProductAccounts.SFTP]: {
    id: MODAL_TYPE.SFTP,
    backendMapping: 'sftp',
    frontendMapping: 'SFTP',
    title: 'SFTP',
    description: 'Sync your products from services that serve files via SFTP.',
    icon: 'ftp',
    productView: true,
    feedView: true,
    filter: true,
  },
  [ProductAccounts.BIGCOMMERCE]: {
    id: MODAL_TYPE.BIGCOMMERCE,
    backendMapping: 'bigcommerce',
    frontendMapping: 'BigCommerce',
    title: 'BigCommerce',
    description: 'Connect your BigCommerce account in order to import product catalog feeds.',
    icon: 'bigcommerce',
    productView: true,
    feedView: true,
    filter: true,
  },
  lookingForMore: {
    id: 'lookingForMore',
    backendMapping: null,
    title: 'Looking for more?',
    description: 'Let us know if you are interested a custom integration.',
    link: Config.url.productAccountsHelp,
    linkDescription: 'Contact us.',
    icon: 'request',
    productView: false,
    feedView: false,
  },
  [ProductAccounts.CSV]: {
    id: MODAL_TYPE.CSV,
    frontendMapping: 'CSV',
    backendMapping: 'csv',
    title: 'CSV',
    icon: 'csv',
    productView: false,
    feedView: true,
    filter: true,
  },
  [ProductAccounts.CUSTOM]: {
    id: MODAL_TYPE.CUSTOM,
    frontendMapping: 'Custom',
    backendMapping: 'manual',
    title: 'Custom',
    icon: 'manual',
    productView: false,
    feedView: true,
    filter: true,
  },
  [ProductAccounts.HTTP]: {
    id: MODAL_TYPE.HTTP,
    frontendMapping: ProductAccountsTranslations[ProductAccounts.HTTP],
    backendMapping: ProductAccounts.HTTP,
    description: 'Sync your products from services that serve files via HTTP or HTTPS.',
    title: ProductAccountsTranslations[ProductAccounts.HTTP],
    icon: 'ftp',
    productView: true,
    feedView: true,
    filter: true,
  },
  [ProductAccounts.S3]: {
    id: ProductAccounts.S3,
    frontendMapping: ProductAccountsTranslations[ProductAccounts.S3],
    backendMapping: ProductAccounts.S3,
    description: "Sync your products from files served via Amazon Web Service's S3.",
    title: ProductAccountsTranslations[ProductAccounts.S3],
    icon: iconTypes.s3,
    productView: true,
    feedView: true,
    filter: true,
  },
  [ProductAccounts.BAZAARVOICE]: {
    id: MODAL_TYPE.BAZAARVOICE,
    backendMapping: 'bazaarvoice',
    frontendMapping: 'Bazaarvoice',
    title: 'Bazaarvoice',
    description: 'Connect your Bazaarvoice account in order to import product.',
    icon: 'bazaarvoice',
    productView: true,
    feedView: true,
    filter: true,
  },
};
