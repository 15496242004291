import { INPUT_EDIT_MODAL_STEP } from '../../components/experience/page-sections/form/field-edit-modal/field-edit-modal';
import { ACTIONS } from '../../actions/form-section-field-modal/form-section-field-modal.actions';

export const initialState = {
  step: INPUT_EDIT_MODAL_STEP.PRIMARY,
  data: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_FORM_SECTION_MODAL_STEP:
      return {
        ...state,
        ...action.payload,
      };

    case ACTIONS.CLOSE_FORM_SECTION_INPUT_MODAL:
      return initialState;

    default:
      return state;
  }
};
