import {
  faSmile,
  faFrown,
  faMeh,
  faCommentAlt,
  faVideo,
  faImage,
  faClone,
  faSyncAlt,
  faHourglassEnd,
  faTriangleExclamation,
  faThumbtack,
  faStar,
  faShoppingCart,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';
import { faShieldCheck } from '@fortawesome/pro-solid-svg-icons';

export const starRatingTagList = [
  {
    icon: faStar,
    name: '1 Star',
    value: '1-20',
  },
  {
    icon: faStar,
    name: '2 Stars',
    value: '21-40',
  },
  {
    icon: faStar,
    name: '3 Stars',
    value: '41-60',
  },
  {
    icon: faStar,
    name: '4 Stars',
    value: '61-80',
  },
  {
    icon: faStar,
    name: '5 Stars',
    value: '81-100',
  },
];

export const sentimentTagList = [
  {
    icon: faSmile,
    name: 'Positive-Text',
    value: 'positive',
  },
  {
    icon: faMeh,
    name: 'Neutral-Text',
    value: 'neutral',
  },
  {
    icon: faFrown,
    name: 'Negative-Text',
    value: 'negative',
  },
];

export const mediaTagList = [
  {
    icon: faCommentAlt,
    name: 'Text Only',
    value: 'text',
  },
  {
    icon: faVideo,
    name: 'Video',
    value: 'video',
  },
  {
    icon: faImage,
    name: 'Image',
    value: 'image',
  },
  {
    icon: faClone,
    name: 'Carousel',
    value: 'carousel',
  },
];

export const ActionFilterKeyProps = {
  RIGHT_REQUEST_STATUS: 'right_request_status',
  PINNED: 'pinned',
  HIGHLIGHTED: 'highlighted',
  INCENTIVIZED: 'incentivized',
  PRODUCT_TAGS: 'product_tags',
  RATING: 'rating',
};

export const ActionFilterValues = {
  REQUESTED: 'requested',
  APPROVED: 'approved',
  APPROVAL_EXPIRED: 'approval_expired',
  SOFT_APPROVED: 'soft_approved',
  EXPIRED: 'expired',
  ERROR: 'error',
  PINNED: 'pinned',
  NOT_PINNED: 'not-pinned',
  HIGHLIGHTED: 'highlighted',
  NOT_HIGHLIGHTED: 'not-highlighted',
  PRODUCT_TAGS: 'product_id',
  RATING: 'rating',
  INCENTIVIZED: 'incentivized',
  NOT_INCENTIVIZED: 'not-incentivized',
  DRAFT: 'draft',
  SCHEDULED: 'scheduled',
  PENDING_REVIEW: 'pending_review',
  POSTED: 'posted',
};

export const actionsTagList = [
  {
    icon: faSyncAlt,
    name: 'Rights Requested',
    value: ActionFilterValues.REQUESTED,
    queryParamValue: ActionFilterValues.REQUESTED,
  },
  {
    icon: faShieldCheck,
    name: 'Rights Approved',
    value: ActionFilterValues.APPROVED,
    queryParamValue: ActionFilterValues.APPROVED,
  },
  {
    icon: faShieldCheck,
    name: 'Rights Soft Approved',
    value: ActionFilterValues.SOFT_APPROVED,
    queryParamValue: ActionFilterValues.SOFT_APPROVED,
  },
  {
    icon: faHourglassEnd,
    name: 'Rights Request Expired',
    value: ActionFilterValues.EXPIRED,
    queryParamValue: ActionFilterValues.EXPIRED,
  },
  {
    icon: faHourglassEnd,
    name: 'Rights Approval Expired',
    value: ActionFilterValues.APPROVAL_EXPIRED,
    queryParamValue: ActionFilterValues.APPROVAL_EXPIRED,
  },
  {
    icon: faTriangleExclamation,
    name: 'Rights Error',
    value: ActionFilterValues.ERROR,
    queryParamValue: ActionFilterValues.ERROR,
  },
  {
    icon: faThumbtack,
    name: 'Pinned',
    value: true,
    queryParamValue: ActionFilterValues.PINNED,
  },
  {
    icon: faThumbtack,
    name: 'Not Pinned',
    value: false,
    queryParamValue: ActionFilterValues.NOT_PINNED,
  },
  {
    icon: faCheck,
    name: 'Incentivized',
    value: true,
    queryParamValue: ActionFilterValues.INCENTIVIZED,
  },
  {
    icon: faCheck,
    name: 'Not Incentivized',
    value: false,
    queryParamValue: ActionFilterValues.NOT_INCENTIVIZED,
  },
  {
    icon: faStar,
    name: 'Highlighted',
    value: true,
    queryParamValue: ActionFilterValues.HIGHLIGHTED,
  },
  {
    icon: faStar,
    name: 'Not Highlighted',
    value: false,
    queryParamValue: ActionFilterValues.NOT_HIGHLIGHTED,
  },
  {
    icon: faShoppingCart,
    name: 'Tagged Products',
    value: ActionFilterValues.PRODUCT_TAGS,
    queryParamValue: ActionFilterValues.PRODUCT_TAGS,
  },
];

export const actionRightsList = [
  ActionFilterValues.REQUESTED,
  ActionFilterValues.ERROR,
  ActionFilterValues.EXPIRED,
  ActionFilterValues.SOFT_APPROVED,
  ActionFilterValues.APPROVED,
  ActionFilterValues.APPROVAL_EXPIRED,
];

export const actionFilterProps = [
  ActionFilterKeyProps.PINNED,
  ActionFilterKeyProps.INCENTIVIZED,
  ActionFilterKeyProps.RIGHT_REQUEST_STATUS,
  ActionFilterKeyProps.HIGHLIGHTED,
  ActionFilterKeyProps.PRODUCT_TAGS,
];
