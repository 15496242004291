export const commandIdList = {
  LINK: 'createLink',
  UN_LINK: 'unlink',
  FONT_FAMILY: 'fontName',
  FONT_SIZE: 'fontSize',
  COLOR: 'foreColor',
  BACKGROUND_COLOR: 'backColor',
  UNDERLINE: 'underline',
  FONT_STYLE: 'italic',
  FONT_LIST: 'insertUnorderedList',
  FONT_LIST_ORDERED: 'insertOrderedList',
  FONT_WEIGHT: 'bold',
  TEXT_ALIGN_LEFT: 'justifyLeft',
  TEXT_ALIGN_RIGHT: 'justifyRight',
  TEXT_ALIGN_CENTER: 'justifyCenter',
  SELECT_ALL: 'selectAll',
};

export const wysiwygNavigationItemList = [
  {
    commandId: commandIdList.BACKGROUND_COLOR,
    value: 'backgroundColor',
  },
  {
    commandId: commandIdList.COLOR,
    value: 'color',
  },
  {
    commandId: commandIdList.FONT_FAMILY,
    value: 'fontFamily',
  },
  {
    commandId: commandIdList.FONT_SIZE,
    value: 'fontSize',
  },
  {
    commandId: commandIdList.LINK,
    value: 'urlLink',
  },
  {
    commandId: commandIdList.FONT_WEIGHT,
    value: 'fontWeight',
  },
  {
    commandId: commandIdList.UNDERLINE,
    value: 'textDecoration',
  },
  {
    commandId: commandIdList.FONT_STYLE,
    value: 'fontStyle',
  },
  {
    commandId: commandIdList.FONT_LIST,
    value: 'listStyle',
  },
  {
    commandId: commandIdList.FONT_LIST_ORDERED,
    value: 'listStyleOrdered',
  },
  {
    commandId: commandIdList.TEXT_ALIGN_LEFT,
    value: 'justifyLeft',
  },
  {
    commandId: commandIdList.TEXT_ALIGN_CENTER,
    value: 'justifyCenter',
  },
  {
    commandId: commandIdList.TEXT_ALIGN_RIGHT,
    value: 'justifyRight',
  },
];

export const wysiwygNavigationTextStyleItems = [
  {
    commandId: commandIdList.FONT_WEIGHT,
    iconName: 'fa fa-bold',
    value: 'fontWeight',
  },
  {
    commandId: commandIdList.UNDERLINE,
    iconName: 'fa fa-underline',
    value: 'textDecoration',
  },
  {
    commandId: commandIdList.FONT_STYLE,
    iconName: 'fa fa-italic',
    value: 'fontStyle',
  },
];

export const wysiwygNavigationColorStyleItems = [
  {
    commandId: commandIdList.COLOR,
    iconName: 'fa fa-font',
    value: 'color',
  },
  {
    commandId: commandIdList.BACKGROUND_COLOR,
    iconName: 'fa fa-marker',
    value: 'backgroundColor',
  },
];

export const wysiwygNavigationLinkItem = [
  {
    commandId: commandIdList.LINK,
    iconName: 'fa fa-link',
    value: 'link',
  },
];

export const wysiwygNavigationTextAlignItems = [
  {
    commandId: commandIdList.TEXT_ALIGN_LEFT,
    iconName: 'fa fa-align-left',
  },
  {
    commandId: commandIdList.TEXT_ALIGN_CENTER,
    iconName: 'fa fa-align-center',
  },
  {
    commandId: commandIdList.TEXT_ALIGN_RIGHT,
    iconName: 'fas fa-align-right',
  },
];
