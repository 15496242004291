import React from 'react';
import './engagement-per-device.sass';
import { useSelector } from 'react-redux';
import { PieChart, Pie, Cell, ResponsiveContainer, Tooltip } from 'recharts';
import { InsightsSection } from '../../../section/insights-section';
import { useInsightsPerformanceData } from '../../../../hooks/use-insights-performance-data';
import { ACTIONS } from '../../../../actions/performance/insights-performance.actions';
import { getEngagementPerDevice } from '../../../../reducers/insights-performance.reducer';
import { CustomTooltip } from '../../../custom-tooltip/custom-tooltip';
import { numberWithCommas } from '../../../../../../services/functions/number-format/number-format';
import { InsightsNoData } from '../../../no-data/insights-no-data';

export const DEVICE_TYPE = {
  DESKTOP: 'desktop',
  TABLET: 'tablet',
  MOBILE: 'mobile',
};

const COLORS = {
  [DEVICE_TYPE.DESKTOP.toUpperCase()]: '#3B99FC',
  [DEVICE_TYPE.TABLET.toUpperCase()]: '#FBB923',
  [DEVICE_TYPE.MOBILE.toUpperCase()]: '#1CC666',
};

const deviceTypeToReadableString = {
  [DEVICE_TYPE.DESKTOP]: 'Desktop',
  [DEVICE_TYPE.TABLET]: 'Tablet',
  [DEVICE_TYPE.MOBILE]: 'Mobile',
};

export const EngagementPerDevice = () => {
  const insights = useSelector(state => state.insightsPerformance);
  const [, targetRef] = useInsightsPerformanceData([ACTIONS.GET_ENGAGEMENT_PER_DEVICE], false);
  const data = getEngagementPerDevice(insights);

  const getName = name => {
    const _name = deviceTypeToReadableString[name];
    return _name ? _name : name;
  };

  const getSumPosts = _data => {
    return _data.reduce((a, b) => a + b.value, 0);
  };

  return (
    <InsightsSection
      isFetching={insights.engagementPerDevice.isFetching}
      childRef={targetRef}
      title='Engagement per Device'
      description='What devices are my audience using? '>
      {data.length > 0 ? (
        <div className='tint-engagement-per-device'>
          <ResponsiveContainer width={256} height='100%'>
            <PieChart>
              <Tooltip content={<CustomTooltip getName={getName} dotColor={COLORS} />} />
              <Pie data={data} innerRadius={'75%'} outerRadius={'90%'} fill='#8884d8' dataKey='value'>
                {data.map((e, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[e.name?.toUpperCase()]} />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
          <div className='tint-engagement-per-device__total-container'>
            <span className='total-container__number'>{numberWithCommas(getSumPosts(data))}</span>
            <span className='total-container__posts'>Clicks</span>
          </div>

          <div className='tint-engagement-per-device__description'>
            {data.map((e, i) => {
              return (
                <div className='tint-engagement-per-device__description-item' key={i}>
                  <div
                    className={`tint-engagement-per-device tint-engagement-per-device__circle tint-engagement-per-device--${e.name}`}
                  />
                  <span className='tint-engagement-per-device__name'>{deviceTypeToReadableString[e.name]}</span>
                  <span className='tint-engagement-per-device__percentage'>
                    {e.percentage > 0 ? e.percentage : '<1'}%
                  </span>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <InsightsNoData />
      )}
    </InsightsSection>
  );
};
