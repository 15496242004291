import React from 'react';
import './form-builder.form.interface.header.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';

const FormBuilderFormInterfaceHeader = ({ item, styles }) => {
  if (!item) return null;

  const { t } = useTranslation();

  const h1 = item.elements.find(element => element.nodeName === 'h1');
  const p = item.elements.find(element => element.nodeName === 'p');

  return (
    <hgroup className={`${item.class}`}>
      <h1 style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.HEADER_TITLE]}>{t(h1.nodeValue)}</h1>
      {p && <p style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.HEADER_CAPTION]}>{t(p.nodeValue)}</p>}
    </hgroup>
  );
};

FormBuilderFormInterfaceHeader.displayName = 'FormBuilderFormInterfaceHeader';
export default React.memo(FormBuilderFormInterfaceHeader);
