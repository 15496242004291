import { clone } from '../../../../../../../../services/functions/clone/clone';
import { FIELD_TYPE, transformToSendAble } from '../../form-helper';
import { Config } from '../../../../../../../../utils/config';
import { createTranslationKey } from '../../../../../../containers/experience/translation-creator';

const defaultTintObligatoryFields = ['First Name', 'Last Name'];

const formErrors = {
  //TOO_LONG: 'Cannot be more than 60 signs',
  EMPTY: 'Cannot be empty',
  DUPLICATED: 'Cannot be duplicated',
  REGEX: 'Invalid format, input can contain only _, numbers and letters',
};

const defaultValidators = [
  //{ fn: val => val.length > 60, error: formErrors.TOO_LONG },
  { fn: val => val.length === 0, error: formErrors.EMPTY },
];

const _addMissingField = ({ property, getTranslation, addTranslation, experienceType, fields, tmpFields }) => {
  const exist = fields.some(
    e =>
      getTranslation(e.placeholder).toLowerCase() === property.toLowerCase() || e.key === transformToSendAble(property)
  );
  if (!exist) {
    const translateKey = createTranslationKey(experienceType, 'form', 'placeholder', fields.length);
    addTranslation(translateKey, property);
    tmpFields.push({
      type: FIELD_TYPE.INPUT,
      placeholder: translateKey,
      required: true,
      readOnly: true,
      key: transformToSendAble(property),
    });
  } else {
    const _elem = tmpFields.find(
      e =>
        getTranslation(e.placeholder).toLowerCase() === property.toLowerCase() ||
        e.key === transformToSendAble(property)
    );
    _elem.readOnly = true;
    _elem.required = true;
  }
};

const addMissingInputFields = ({ fields = [], options, getTranslation, addTranslation, experienceType }) => {
  let tmpFields = clone(fields).map(e => {
    e.readOnly = false;
    return e;
  });
  defaultTintObligatoryFields.map(e =>
    _addMissingField({
      property: e,
      getTranslation,
      fields,
      tmpFields,
      addTranslation,
      experienceType,
    })
  );
  if (options && options.email) {
    _addMissingField({
      property: 'Email',
      getTranslation,
      fields,
      tmpFields,
      addTranslation,
      experienceType,
    });
  }
  if (options && options.custom_field) {
    _addMissingField({
      property: 'Custom Field',
      getTranslation,
      fields,
      tmpFields,
      addTranslation,
      experienceType,
    });
  }

  return tmpFields;
};

const validateInputs = ({ values, getTranslation }) => {
  const keyValidators = [
    ...defaultValidators,
    {
      fn: val => !Config.regExp.formIds.test(val),
      error: EditFormHelper.formErrors.REGEX,
    },
  ];
  const errors = _validate({
    property: 'fields',
    validators: defaultValidators,
    getTranslation,
    values,
    errorKeys: ['placeholder'],
  });
  const keyErrors = _validate({
    property: 'fields',
    validators: keyValidators,
    getTranslation,
    values,
    errorKeys: ['key'],
  });
  errors.fields.map((e, i) => {
    const key = keyErrors.fields[i].key;
    if (key) {
      e.key = key;
    }
  });

  return errors;
};

const validateTextarea = ({ getTranslation, values }) => {
  const keyValidators = [
    ...defaultValidators,
    {
      fn: val => !Config.regExp.formIds.test(val),
      error: EditFormHelper.formErrors.REGEX,
    },
  ];
  const errors = _validate({
    property: 'textareaFields',
    validators: defaultValidators,
    getTranslation,
    values,
    errorKeys: ['placeholder'],
  });
  const keyErrors = _validate({
    property: 'textareaFields',
    validators: keyValidators,
    getTranslation,
    values,
    errorKeys: ['key'],
  });
  errors.textareaFields.map((e, i) => {
    e.key = keyErrors.textareaFields[i].key;
  });

  return errors;
};

const _validate = ({ property, values, getTranslation, validators, errorKeys }) => {
  const returnError = {};
  const inputFieldsErrors = [];
  values[property].forEach(e => {
    let errors = {};
    validators.map(validator => {
      errorKeys.map(errorKey => {
        if (validator.fn(getTranslation(e[errorKey]))) {
          errors[errorKey] = validator.error;
        }
      });
    });
    inputFieldsErrors.push(errors);
  });
  returnError[property] = inputFieldsErrors;
  return returnError;
};

const isDuplicated = ({ arr, currentItem, getTranslation, minOccurrence = 1 }) => {
  return (
    arr.filter(e => getTranslation(e).toLowerCase() === getTranslation(currentItem).toLowerCase()).length >
    minOccurrence
  );
};

const validateDuplicates = ({ values, properties, keys, getTranslation, allValues, errors }) => {
  properties.map(e => {
    values[e].map((value, i) => {
      keys.map(key => {
        let minOccurrence = 1;
        if (getTranslation(value.key).toLowerCase() === getTranslation(value.placeholder).toLowerCase()) {
          minOccurrence = 2;
        }
        if (isDuplicated({ currentItem: value[key], arr: allValues, getTranslation, minOccurrence })) {
          if (!errors[e]) {
            errors[e] = [];
          }
          if (errors[e][i] === undefined) {
            errors[e][i] = {};
          }
          errors[e][i][key] = EditFormHelper.formErrors.DUPLICATED;
        }
      });
    });
  });
};

export const EditFormHelper = {
  // addMissingInputFields is not used anywhere now and can be safety deleted, that's used only for test
  addMissingInputFields,
  validateInputs,
  validateTextarea,
  isDuplicated,
  validateDuplicates,
  formErrors,
};
