import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { EditForm, EditInputDropdown } from '../../../';
import EditInput from '../../../edit-input/edit-input';
import { pollImageType } from '../../../../../../../model/poll-image-type.model';
import { Button } from '../../../../../../../components/interface/button/button';
import SurveyEditAnswer from './components/survey-edit-answer';
import { Config } from '../../../../../../../utils/config';
import { createTranslationKey } from '../../../../../containers/experience/translation-creator';

const voteCountOptions = [
  { value: 1, name: '1 Column' },
  { value: 2, name: '2 Column' },
  { value: 3, name: '3 Column' },
];

const advancedSettings = [
  {
    name: 'OFF',
    value: 'off',
  },
  {
    name: 'ON',
    value: 'on',
  },
];

export class SurveyEditContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      keyInputValidation: {},
    };
  }

  renderAdvancedSettings = () => {
    return (
      <EditInputDropdown
        label='display advanced settings'
        onChange={e => {
          this.props.dataUpdate({
            hasAdvancedSettings: e.target.value === 'on',
          });
        }}
        options={advancedSettings}
        defaultValue={this.props.data.content.hasAdvancedSettings ? 'on' : 'off'}
      />
    );
  };

  addNewAnswer = () => {
    const titleTrKey = createTranslationKey(
      this.props.experienceType,
      'SURVEY',
      'TITLE',
      this.props.data.content.answers.length
    );

    const descriptionTrKey = createTranslationKey(
      this.props.experienceType,
      'SURVEY',
      'DESCRIPTION',
      this.props.data.content.answers.length
    );
    this.props.dataUpdate({
      answers: [
        ...this.props.data.content.answers,
        {
          title: titleTrKey,
          description: descriptionTrKey,
          id: Config.getUniqueId(),
        },
      ],
    });

    this.props.onNewTranslationUpdate(titleTrKey, 'Title');
    this.props.onNewTranslationUpdate(descriptionTrKey, '');
  };

  render() {
    return (
      <EditModalBody>
        <EditForm>
          <button style={{ display: 'none' }} type='submit'>
            Submit
          </button>
          {this.renderAdvancedSettings()}
          {this.props.data.content.answers.map((answer, index) => {
            return (
              <SurveyEditAnswer
                onTranslationUpdate={this.props.onTranslationUpdate}
                getTranslation={this.props.getTranslation}
                answer={answer}
                key={answer.id}
                updateAnswerData={this.props.dataUpdate}
                answers={this.props.data.content.answers}
                index={index}
                keyInputValidation={this.state.keyInputValidation}
                hasAdvancedSettings={this.props.data.content.hasAdvancedSettings}
                setState={(value, fn) => this.setState(value, fn)}
                setApplyButtonDisabled={this.props.setApplyButtonDisabled}
                maxVideoFileSize={this.props.getMaxVideoSizePlanLimit()}
              />
            );
          })}

          <Button
            onClick={this.addNewAnswer}
            type='gray'
            size='full-width'
            text='Add Answer'
            icon='far fa-plus'
            styles={{ margin: '0 0 56px 0' }}
          />

          <EditInputDropdown
            defaultValue={this.props.data.content.displayCount}
            onChange={e => {
              this.props.dataUpdate({ displayCount: e.target.value });
            }}
            options={voteCountOptions}
            label='Display vote count'
          />

          <EditInput
            label='Button text'
            placeholder='Submit'
            defaultValue={this.props.getTranslation(this.props.data.content.buttonText)}
            onChange={event => {
              this.props.onTranslationUpdate(this.props.data.content.buttonText, event.target.value);
            }}
          />

          <EditInputDropdown
            styles={{ margin: '56px 0 0 0' }}
            defaultValue={this.props.data.content.imageType}
            onChange={e => {
              this.props.dataUpdate({ imageType: e.target.value });
            }}
            options={[
              { value: pollImageType.FILL, name: pollImageType.FILL },
              { value: pollImageType.FIT, name: pollImageType.FIT },
            ]}
            label='Image type'
          />
          <div
            className='tint-edit-form-content__line-adder'
            onClick={() => {
              this.props.editSuccessMessage();
              this.props.selectStep(1);
            }}>
            <div className='tint-edit-form-content__line-adder-element'>
              <span>Edit Success Message</span>
            </div>
          </div>
        </EditForm>
      </EditModalBody>
    );
  }
}
