import React from 'react';
import PropTypes from 'prop-types';
import ColorParser from '../../../../../../../../services/color-parser/color-parser';
import usePercent from '../../../../../../../../components/hooks/use-percent/use-percent';
import './tag-o-war-section-ring.sass';

const TagOWarSectionRing = function({ content, styles, percentLeft, percentRight }) {
  const data = {
    leftSide: {
      hashTag: content.socialFeedLeft.label,
      percentResult: percentLeft,
      backgroundRange: styles.backgroundRangeLeft,
      background: styles.backgroundColorLeft,
      textColor: styles.textColorLeft,
      thumb: content.leftSideImage,
    },
    rightSide: {
      hashTag: content.socialFeedRight.label,
      percentResult: percentRight,
      backgroundRange: styles.backgroundRangeRight,
      background: styles.backgroundColorRight,
      textColor: styles.textColorRight,
      thumb: content.rightSideImage,
    },
  };

  const { percentValue } = usePercent(data.leftSide.percentResult, data.rightSide.percentResult);

  function renderRingSide({ hashTag, background, textColor, thumb }) {
    return (
      <>
        <div className='tint-tag-o-war-ring__thumb' style={{ backgroundImage: `url(${thumb})` }}>
          {!thumb ? <span className='icon fas fa-image'></span> : null}
          <span
            className='triangle'
            ref={input => {
              input && input.style.setProperty('border-top-color', ColorParser.defaultColor(background));
            }}
          />
        </div>
        <div className='tint-tag-o-war-ring__content' style={{ backgroundColor: ColorParser.defaultColor(background) }}>
          <h2 style={{ color: ColorParser.defaultColor(textColor) }}>{hashTag}</h2>
          <span className='tint-tag-o-war-ring__counter'></span>
        </div>
      </>
    );
  }

  function getPercentValue(percentValue) {
    const fiftyPercentCount = 50;
    return isNaN(percentValue) ? fiftyPercentCount : percentValue;
  }

  return (
    <>
      <div className='tint-tag-o-war-ring' data-testid='tagOWarRing'>
        <div className='tint-tag-o-war-ring__left-side'>{renderRingSide(data.leftSide)}</div>
        <div className='tint-tag-o-war-ring__right-side'>{renderRingSide(data.rightSide)}</div>
        <span className='versus'>vs</span>
      </div>
      <div className='tint-tag-o-war-ring-result'>
        <span
          data-testid='leftCountBar'
          className='tint-tag-o-war-ring-result__left range-bar'
          style={{
            backgroundColor: ColorParser.defaultColor(data.leftSide.backgroundRange),
            width: `${getPercentValue(percentValue.firstValue)}%`,
          }}>
          {`${getPercentValue(percentValue.firstValue)}%`}
        </span>
        <span
          data-testid='rightCountBar'
          className='tint-tag-o-war-ring-result__right range-bar'
          style={{
            backgroundColor: ColorParser.defaultColor(data.rightSide.backgroundRange),
            width: `${getPercentValue(percentValue.secondValue)}%`,
          }}>
          {`${getPercentValue(percentValue.secondValue)}%`}
        </span>
      </div>
    </>
  );
};

export default TagOWarSectionRing;

TagOWarSectionRing.propTypes = {
  content: PropTypes.shape({
    socialFeedLeft: PropTypes.object,
    leftSideImage: PropTypes.string,
    percentLeft: PropTypes.number,
    socialFeedRight: PropTypes.object,
    rightSideImage: PropTypes.string,
    percentRight: PropTypes.number,
  }),
  styles: PropTypes.any,
};
