import React, { Component } from 'react';
import { EXPERIENCE_ANALYTICS_TYPE } from '../public/js/react/model/experience-analytics-type.model';

export const TintPagesScriptsHOC = PassedComponent => {
  class TintPagesScripts extends Component {
    constructor(props) {
      super(props);
    }

    createAnalyticsScript = (analyticsType, analyticsId) => {
      switch (analyticsType) {
        case EXPERIENCE_ANALYTICS_TYPE.google_analytics:
          this.addGoogleAnalyticsScript(analyticsId);
          break;
        case EXPERIENCE_ANALYTICS_TYPE.google_tag_manager:
          this.addGoogleTagManagerScript(analyticsId);
          break;
        default:
          return;
      }
    };

    addGoogleTagManagerScript(googleAccountId) {
      const googleTagManagerScript = document.createElement('script');
      googleTagManagerScript.async = true;
      googleTagManagerScript.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${googleAccountId}')`;
      document.head.prepend(googleTagManagerScript);

      const googleTagManagerNoScript = document.createElement('noscript');
      const iframe = document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${googleAccountId}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.style = 'display:none;visibility:hidden';
      googleTagManagerNoScript.appendChild(iframe);
      document.body.prepend(googleTagManagerNoScript);
    }

    addGoogleAnalyticsScript(googleAccountId) {
      const googleTagManagerScript = document.createElement('script');
      googleTagManagerScript.async = true;
      googleTagManagerScript.src = `https://www.googletagmanager.com/gtag/js?id=${googleAccountId}`;

      const googleAnalyticsScript = document.createElement('script');
      googleAnalyticsScript.text = `window.dataLayer = window.dataLayer || [];
                                    function gtag(){dataLayer.push(arguments);}
                                    gtag('js', new Date());
                                    gtag('config', '${googleAccountId}');`;

      document.body.appendChild(googleTagManagerScript);
      document.body.appendChild(googleAnalyticsScript);
    }

    createFavIconLink = url => {
      const favIconLink = document.createElement('link');
      favIconLink.href = url;
      favIconLink.rel = 'icon';
      favIconLink.type = 'image/png';
      document.head.appendChild(favIconLink);
    };

    createNoIndexMeta = () => {
      const favIconLink = document.createElement('meta');
      favIconLink.name = 'robots';
      favIconLink.content = 'noindex';
      document.head.appendChild(favIconLink);
    };

    createCanonicalLink = url => {
      const canonicalLink = document.createElement('link');
      canonicalLink.href = url;
      document.head.appendChild(canonicalLink);
    };

    render() {
      return (
        <>
          <PassedComponent
            {...this.props}
            createAnalyticsScript={this.createAnalyticsScript}
            createFavIconLink={this.createFavIconLink}
            createNoIndexMeta={this.createNoIndexMeta}
            createCanonicalLink={this.createCanonicalLink}
          />
        </>
      );
    }
  }

  return TintPagesScripts;
};
