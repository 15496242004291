import { createPrefixedActionName, createRoutine } from '../../../services/functions/redux-routines/redux-routines';

const prefix = 'TINT_EDITOR';

export const ACTIONS = {
  CLEAR_STATE: createPrefixedActionName(prefix, 'CLEAR_STATE'),
  GET_TINTS: createRoutine(createPrefixedActionName(prefix, 'GET_TINTS')),
  GET_SEARCH_TINTS: createRoutine(createPrefixedActionName(prefix, 'GET_SEARCH_TINTS')),
  GET_TINT: createRoutine(createPrefixedActionName(prefix, 'GET_TINT')),
  UPDATE_TINT_STATUS: createRoutine(createPrefixedActionName(prefix, 'UPDATE_TINT_STATUS')),
  BULK_UPDATE_TINTS_STATUS: createRoutine(createPrefixedActionName(prefix, 'BULK_UPDATE_TINT_STATUS')),
  UPDATE_TINT_PRIVACY: createRoutine(createPrefixedActionName(prefix, 'UPDATE_TINT_PRIVACY')),
  DELETE_TINT: createRoutine(createPrefixedActionName(prefix, 'DELETE_TINT')),
  BULK_DELETE_TINTS: createRoutine(createPrefixedActionName(prefix, 'BULK_DELETE_TINTS')),
  DUPLICATE_TINT: createRoutine(createPrefixedActionName(prefix, 'DUPLICATE_TINT')),
  EXPORT_POSTS: createRoutine(createPrefixedActionName(prefix, 'EXPORT_POSTS')),
  EXPORT_POSTS_STATUS: createRoutine(createPrefixedActionName(prefix, 'EXPORT_POSTS_STATUS')),
  RENAME_TINT: createRoutine(createPrefixedActionName(prefix, 'RENAME_TINT')),
  PATCH_TINT: createRoutine(createPrefixedActionName(prefix, 'PATCH_TINT')),
  CREATE_TINT: createRoutine(createPrefixedActionName(prefix, 'CREATE_TINT')),
  PATCH_MODERATION_RULES: createRoutine(createPrefixedActionName(prefix, 'PATCH_MODERATION_RULES')),
  GET_INSTINT_UPDATES: createRoutine(createPrefixedActionName(prefix, 'GET_INSTINT_UPDATES')),
  POST_INSTINT_UPDATES: createRoutine(createPrefixedActionName(prefix, 'POST_INSTINT_UPDATES')),
  OPEN_SIDEBAR: createPrefixedActionName(prefix, 'OPEN_SIDEBAR'),
  CLOSE_SIDEBAR: createPrefixedActionName(prefix, 'CLOSE_SIDEBAR'),
  UPDATE_SIDEBAR_INPUT: createPrefixedActionName(prefix, 'UPDATE_SIDEBAR_INPUT'),

  GET_CTAS: createRoutine(createPrefixedActionName(prefix, 'GET_CTAS')),
  POST_CTA: createRoutine(createPrefixedActionName(prefix, 'POST_CTA')),
  PATCH_CTA: createRoutine(createPrefixedActionName(prefix, 'PATCH_CTA')),
  DELETE_CTA: createRoutine(createPrefixedActionName(prefix, 'DELETE_CTA')),
  POST_CTA_ASSOCIATIONS: createRoutine(createPrefixedActionName(prefix, 'POST_CTA_ASSOCIATIONS')),
  APPLY_CTA_ASSOCIATIONS: createRoutine(createPrefixedActionName(prefix, 'APPLY_CTA_ASSOCIATIONS')),
  DELETE_CTA_ASSOCIATIONS: createRoutine(createPrefixedActionName(prefix, 'DELETE_CTA_ASSOCIATIONS')),
  UPDATE_TINT_TAGS: createPrefixedActionName(prefix, 'UPDATE_TINT_TAGS'),

  OPEN_MODAL: createPrefixedActionName(prefix, 'OPEN_MODAL'),
  OPEN_VISUAL_SEARCH_MODAL: createPrefixedActionName(prefix, 'OPEN_VISUAL_SEARCH_MODAL'),
  CLOSE_VISUAL_SEARCH_MODAL: createPrefixedActionName(prefix, 'CLOSE_VISUAL_SEARCH_MODAL'),
  OPEN_ECOMMERCE_MODAL: createPrefixedActionName(prefix, 'OPEN_ECOMMERCE_MODAL'),
  CLOSE_ECOMMERCE_MODAL: createPrefixedActionName(prefix, 'CLOSE_ECOMMERCE_MODAL'),
  CHANGE_MODAL_POST: createPrefixedActionName(prefix, 'CHANGE_MODAL_POST'),
  CLOSE_MODAL: createPrefixedActionName(prefix, 'CLOSE_MODAL'),
  CHANGE_MODAL_ORIENTATION: createPrefixedActionName(prefix, 'CHANGE_MODAL_ORIENTATION'),
  MARK_PREVIEW_MODE: createPrefixedActionName(prefix, 'MARK_PREVIEW_MODE'),
  TOGGLE_CTA_SIDEBAR: createPrefixedActionName(prefix, 'TOGGLE_CTA_SIDEBAR'),
  INCREASE_VISUAL_SEARCH_COUNT: createPrefixedActionName(prefix, 'INCREASE_VISUAL_SEARCH_COUNT'),
  TOGGLE_SIDEBAR: createPrefixedActionName(prefix, 'TOGGLE_SIDEBAR'),
  SELECT_TINT: createPrefixedActionName(prefix, 'SELECT_TINT'),
  UNSELECT_TINT: createPrefixedActionName(prefix, 'UNSELECT_TINT'),
  DESELECT_ALL_TINTS: createPrefixedActionName(prefix, 'DESELECT_ALL_TINTS'),
};

export const clearTintEditorState = () => ({
  type: ACTIONS.CLEAR_STATE,
});

export const getTintsStart = () => ({
  type: ACTIONS.GET_TINTS.REQUEST,
});

export const getTintsSuccess = data => ({
  type: ACTIONS.GET_TINTS.SUCCESS,
  payload: data,
});

export const getTintsError = err => ({
  type: ACTIONS.GET_TINTS.FAILURE,
  payload: err,
});

export const postCTAStart = () => ({
  type: ACTIONS.POST_CTA.REQUEST,
});

export const postCTASuccess = data => ({
  type: ACTIONS.POST_CTA.SUCCESS,
  payload: data,
});

export const postCTAError = err => ({
  type: ACTIONS.POST_CTA.FAILURE,
  payload: err,
});

export const openModal = data => ({
  type: ACTIONS.OPEN_MODAL,
  payload: data,
});

export const markPreviewMode = isPreviewMode => ({
  type: ACTIONS.MARK_PREVIEW_MODE,
  payload: isPreviewMode,
});

export const toggleTrialSidebar = (isVisible, sidebarName) => ({
  type: ACTIONS.TOGGLE_SIDEBAR,
  payload: {
    isVisible,
    sidebarName,
  },
});
export const toggleCTASidebar = (isVisible, isAssignMode, postId) => ({
  type: ACTIONS.TOGGLE_CTA_SIDEBAR,
  payload: {
    isVisible,
    isAssignMode,
    postId,
  },
});

export const openVisualSearchModal = () => ({
  type: ACTIONS.OPEN_VISUAL_SEARCH_MODAL,
});

export const closeVisualSearchModal = () => ({
  type: ACTIONS.CLOSE_VISUAL_SEARCH_MODAL,
});

export const openEcommerceModal = data => ({
  type: ACTIONS.OPEN_ECOMMERCE_MODAL,
  payload: data,
});

export const closeEcommerceModal = () => ({
  type: ACTIONS.CLOSE_ECOMMERCE_MODAL,
});

export const changeModalPost = data => ({
  type: ACTIONS.CHANGE_MODAL_POST,
  payload: data,
});

export const closeModal = () => ({
  type: ACTIONS.CLOSE_MODAL,
});

export const changeModalOrientation = type => ({
  type: ACTIONS.CHANGE_MODAL_ORIENTATION,
  payload: type,
});

export const patchCTAStart = () => ({
  type: ACTIONS.PATCH_CTA.REQUEST,
});

export const patchCTASuccess = (data, id) => ({
  type: ACTIONS.PATCH_CTA.SUCCESS,
  payload: {
    data,
    id,
  },
});

export const patchCTAError = err => ({
  type: ACTIONS.PATCH_CTA.FAILURE,
  payload: err,
});

export const deleteCTAStart = () => ({
  type: ACTIONS.DELETE_CTA.REQUEST,
});

export const deleteCTASuccess = id => ({
  type: ACTIONS.DELETE_CTA.SUCCESS,
  payload: id,
});

export const deleteCTAError = err => ({
  type: ACTIONS.DELETE_CTA.FAILURE,
  payload: err,
});

export const postCTAAssociationsStart = () => ({
  type: ACTIONS.POST_CTA_ASSOCIATIONS.REQUEST,
});

export const postCTAAssociationsSuccess = data => ({
  type: ACTIONS.POST_CTA_ASSOCIATIONS.SUCCESS,
  payload: data,
});

export const postCTAAssociationsError = err => ({
  type: ACTIONS.POST_CTA_ASSOCIATIONS.FAILURE,
  payload: err,
});

export const applyCTAAssociationsStart = () => ({
  type: ACTIONS.APPLY_CTA_ASSOCIATIONS.REQUEST,
});

export const applyCTAAssociationsSuccess = data => ({
  type: ACTIONS.APPLY_CTA_ASSOCIATIONS.SUCCESS,
  payload: data,
});

export const applyCTAAssociationsError = err => ({
  type: ACTIONS.APPLY_CTA_ASSOCIATIONS.FAILURE,
  payload: err,
});

export const deleteCTAAssociationsStart = () => ({
  type: ACTIONS.DELETE_CTA_ASSOCIATIONS.REQUEST,
});

export const deleteCTAAssociationsSuccess = (ctaId, ctaAssociationId) => ({
  type: ACTIONS.DELETE_CTA_ASSOCIATIONS.SUCCESS,
  payload: {
    ctaId,
    ctaAssociationId,
  },
});

export const deleteCTAAssociationsError = err => ({
  type: ACTIONS.DELETE_CTA_ASSOCIATIONS.FAILURE,
  payload: err,
});

export const getCATSStart = () => ({
  type: ACTIONS.GET_CTAS.REQUEST,
});

export const getCATSSuccess = data => ({
  type: ACTIONS.GET_CTAS.SUCCESS,
  payload: data,
});

export const getCATSError = err => ({
  type: ACTIONS.GET_CTAS.FAILURE,
  payload: err,
});

export const getSearchTintsStart = () => ({
  type: ACTIONS.GET_SEARCH_TINTS.REQUEST,
});

export const getSearchTintsSuccess = data => ({
  type: ACTIONS.GET_SEARCH_TINTS.SUCCESS,
  payload: data,
});

export const getSearchTintsError = err => ({
  type: ACTIONS.GET_SEARCH_TINTS.FAILURE,
  payload: err,
});

export const getTintStart = () => ({
  type: ACTIONS.GET_TINT.REQUEST,
});

export const getTintSuccess = data => ({
  type: ACTIONS.GET_TINT.SUCCESS,
  payload: data,
});

export const getTintError = err => ({
  type: ACTIONS.GET_TINT.FAILURE,
  payload: err,
});

export const deleteTintStart = () => ({
  type: ACTIONS.DELETE_TINT.REQUEST,
});

export const deleteTintSuccess = id => ({
  type: ACTIONS.DELETE_TINT.SUCCESS,
  payload: id,
});

export const deleteTintError = err => ({
  type: ACTIONS.DELETE_TINT.FAILURE,
  payload: err,
});

export const bulkDeleteTintsStart = () => ({
  type: ACTIONS.BULK_DELETE_TINTS.REQUEST,
});

export const bulkDeleteTintsSuccess = id => ({
  type: ACTIONS.BULK_DELETE_TINTS.SUCCESS,
  payload: id,
});

export const bulkDeleteTintsError = err => ({
  type: ACTIONS.BULK_DELETE_TINTS.FAILURE,
  payload: err,
});

export const updateTintStatusStart = () => ({
  type: ACTIONS.UPDATE_TINT_STATUS.REQUEST,
});

export const updateTintStatusSuccess = () => ({
  type: ACTIONS.UPDATE_TINT_STATUS.SUCCESS,
});

export const updateTintStatusError = err => ({
  type: ACTIONS.UPDATE_TINT_STATUS.FAILURE,
  payload: err,
});

export const bulkUpdateTintsStatusStart = () => ({
  type: ACTIONS.BULK_UPDATE_TINTS_STATUS.REQUEST,
});

export const bulkUpdateTintsStatusSuccess = () => ({
  type: ACTIONS.BULK_UPDATE_TINTS_STATUS.SUCCESS,
});

export const bulkUpdateTintsStatusError = err => ({
  type: ACTIONS.BULK_UPDATE_TINTS_STATUS.FAILURE,
  payload: err,
});

export const duplicateTintStart = () => ({
  type: ACTIONS.DUPLICATE_TINT.REQUEST,
});

export const duplicateTintSuccess = () => ({
  type: ACTIONS.DUPLICATE_TINT.SUCCESS,
});

export const duplicateTintError = err => ({
  type: ACTIONS.DUPLICATE_TINT.FAILURE,
  payload: err,
});

export const exportPostsStart = () => ({
  type: ACTIONS.EXPORT_POSTS.REQUEST,
});

export const exportPostsSuccess = data => ({
  type: ACTIONS.EXPORT_POSTS.SUCCESS,
  payload: data,
});

export const exportPostsError = err => ({
  type: ACTIONS.EXPORT_POSTS.FAILURE,
  payload: err,
});

export const getExportPostsStatusStart = () => ({
  type: ACTIONS.EXPORT_POSTS_STATUS.REQUEST,
});

export const getExportPostsStatusSuccess = data => ({
  type: ACTIONS.EXPORT_POSTS_STATUS.SUCCESS,
  payload: data,
});

export const getExportPostsStatusError = err => ({
  type: ACTIONS.EXPORT_POSTS_STATUS.FAILURE,
  payload: err,
});

export const patchTintStart = () => ({
  type: ACTIONS.PATCH_TINT.REQUEST,
});

export const patchTintSuccess = data => ({
  type: ACTIONS.PATCH_TINT.SUCCESS,
  payload: data,
});

export const patchTintError = err => ({
  type: ACTIONS.PATCH_TINT.FAILURE,
  payload: err,
});

export const createTintStart = () => ({
  type: ACTIONS.CREATE_TINT.REQUEST,
});

export const createTintSuccess = data => ({
  type: ACTIONS.CREATE_TINT.SUCCESS,
  payload: data,
});

export const createTintError = err => ({
  type: ACTIONS.CREATE_TINT.FAILURE,
  payload: err,
});

export const openSidebar = data => ({
  type: ACTIONS.OPEN_SIDEBAR,
  data,
});

export const closeSidebar = () => ({
  type: ACTIONS.CLOSE_SIDEBAR,
});

export const updateSidebarInput = data => ({
  type: ACTIONS.UPDATE_SIDEBAR_INPUT,
  data,
});

export const patchModerationRulesStart = () => ({
  type: ACTIONS.PATCH_MODERATION_RULES.REQUEST,
});

export const patchModerationRulesSuccess = autoModerationRules => ({
  type: ACTIONS.PATCH_MODERATION_RULES.SUCCESS,
  payload: autoModerationRules,
});

export const patchModerationRulesError = err => ({
  type: ACTIONS.PATCH_MODERATION_RULES.FAILURE,
  payload: err,
});

export const getInstintUpdatesStart = () => ({
  type: ACTIONS.GET_INSTINT_UPDATES.REQUEST,
});

export const getInstintUpdatesSuccess = data => ({
  type: ACTIONS.GET_INSTINT_UPDATES.SUCCESS,
  payload: data,
});

export const getInstintUpdatesError = err => ({
  type: ACTIONS.GET_INSTINT_UPDATES.FAILURE,
  payload: err,
});

export const postInstintUpdatesStart = () => ({
  type: ACTIONS.POST_INSTINT_UPDATES.REQUEST,
});

export const postInstintUpdatesSuccess = data => ({
  type: ACTIONS.POST_INSTINT_UPDATES.SUCCESS,
  payload: data,
});

export const postInstintUpdatesError = err => ({
  type: ACTIONS.POST_INSTINT_UPDATES.FAILURE,
  payload: err,
});

export const updateSocialFeedSidebarStatus = status => ({
  type: ACTIONS.UPDATE_SOCIAL_FEED_SIDEBAR,
  payload: status,
});

export const updateTintTags = tag => ({
  type: ACTIONS.UPDATE_TINT_TAGS,
  payload: tag,
});

export const selectTint = tint => ({
  type: ACTIONS.SELECT_TINT,
  payload: tint,
});

export const unselectTint = id => ({
  type: ACTIONS.UNSELECT_TINT,
  payload: id,
});

export const deselectAllTints = () => ({
  type: ACTIONS.DESELECT_ALL_TINTS,
});
