import { SocialFeedsFooterLabels } from './helpers';
import { SidebarIcons, SidebarItemTypes } from '../utils/meta/consts';
import * as Yup from 'yup';

export const validationSchemaRSSUrl = () => {
  return Yup.object({});
};

export const rss = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext,
    },
    accountSourceType: 'rss',
    header: 'Add RSS Feed',
    selectedOption: { id: 'url', title: 'Add RSS Feed' },
    description: 'Enter a RSS URL to ingest posts from a feed.',
    zendeskUrl: 'https://support.tintup.com/hc/en-us/articles/13082794744851-RSS',
    formSchema: {
      url: {
        submitMapper: values => ({
          external_id: values.url,
        }),
        validationSchema: validationSchemaRSSUrl(),
        inputs: [
          {
            id: 'url',
            backendId: 'external_id',
            label: 'ENTER URL',
            placeholder: 'RSS URL',
            icon: SidebarIcons.RSS,
            type: SidebarItemTypes.TEXT,
            lineBreak: true,
          },
        ],
      },
    },
  },
];
