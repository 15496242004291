import { BROADCAST_MESSAGE_TYPE, BroadcastChannelService } from '../broadcast-channel/broadcast-channel.service';

let globalChannel = BroadcastChannelService.init();
let eventListeners = {};

const _getChannel = () => {
  if (!globalChannel) {
    globalChannel = BroadcastChannelService.init();
  }
  return globalChannel;
};

const _dispatchEvent = event => {
  const { type, data } = event.data;
  if (eventListeners[type]) {
    eventListeners[type].forEach(fn => fn(data));
  }
};

const init = ({ logout }) => {
  const channel = _getChannel();
  if (!channel.onmessage) {
    channel.onmessage = e => {
      _dispatchEvent(e);
    };
  }
  if (logout) {
    on({ type: BROADCAST_MESSAGE_TYPE.LOGOUT, fn: logout });
  }
  return channel;
};

const on = ({ type, fn }) => {
  const channel = _getChannel();
  if (!channel.onmessage) {
    channel.onmessage = e => {
      _dispatchEvent(e);
    };
  }
  if (!eventListeners[type]) {
    eventListeners[type] = [];
  }
  eventListeners[type].push(fn);
};

const postMessage = ({ type, data }) => {
  _getChannel().postMessage({ type, data });
};

export const TabSharingService = {
  init,
  postMessage,
  on,
};
