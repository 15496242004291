import createRoutine, { createPrefixedActionName } from '../../../services/functions/redux-routines/redux-routines';

const prefix = 'SOCIAL_PUBLISHING_CAMPAIGNS';

export const ACTIONS = {
  GET_CAMPAIGNS: createRoutine(createPrefixedActionName(prefix, 'GET_CAMPAIGNS')),
  POST_CAMPAIGN: createRoutine(createPrefixedActionName(prefix, 'POST_CAMPAIGN')),
  PATCH_CAMPAIGN: createRoutine(createPrefixedActionName(prefix, 'PATCH_CAMPAIGN')),
  DELETE_CAMPAIGN: createRoutine(createPrefixedActionName(prefix, 'DELETE_CAMPAIGN')),
};

export const getCampaignsStart = () => ({
  type: ACTIONS.GET_CAMPAIGNS.REQUEST,
});

export const getCampaignsSuccess = data => ({
  type: ACTIONS.GET_CAMPAIGNS.SUCCESS,
  payload: data,
});

export const getCampaignsError = err => ({
  type: ACTIONS.GET_CAMPAIGNS.FAILURE,
  payload: err,
});

export const postCampaignStart = () => ({
  type: ACTIONS.POST_CAMPAIGN.REQUEST,
});

export const postCampaignSuccess = data => ({
  type: ACTIONS.POST_CAMPAIGN.SUCCESS,
  payload: data,
});

export const postCampaignError = err => ({
  type: ACTIONS.POST_CAMPAIGN.FAILURE,
  payload: err,
});

export const deleteCampaignStart = () => ({
  type: ACTIONS.DELETE_CAMPAIGN.REQUEST,
});

export const deleteCampaignSuccess = id => ({
  type: ACTIONS.DELETE_CAMPAIGN.SUCCESS,
  payload: id,
});

export const deleteCampaignError = err => ({
  type: ACTIONS.DELETE_CAMPAIGN.FAILURE,
  payload: err,
});

export const patchCampaignStart = () => ({
  type: ACTIONS.PATCH_CAMPAIGN.REQUEST,
});

export const patchCampaignSuccess = (data, id) => ({
  type: ACTIONS.PATCH_CAMPAIGN.SUCCESS,
  payload: { data, id },
});

export const patchCampaignError = err => ({
  type: ACTIONS.PATCH_CAMPAIGN.FAILURE,
  payload: err,
});
