import { ACTIONS } from '../actions/social/insights-social.actions';
import { produce } from 'immer';
import { SocialFeedsSource } from '../../../types/social-feeds';

const initialState = {
  filters: {
    account: undefined,
  },
  socialOverview: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  profileViews: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  websiteClicks: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  impressions: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  newImpressions: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  reach: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  engagements: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  postEngagement: {
    data: undefined,
    growth: undefined,
    isFetching: false,
    isError: false,
  },
  audienceGrowth: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  mostEngagedFollowers: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  mostEngagedType: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  audienceGender: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  audienceByAge: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  topCountries: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  topCities: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  storiesEngagement: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  newPostEngagement: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  dailyEngagement: {
    data: undefined,
    isFetching: false,
    isError: false,
  },
  clusterPostEngagement: {
    data: undefined,
    avgFollowersRate: undefined,
    bestPerformingContent: undefined,
    lowerPerformingContent: undefined,
    isFetching: false,
    isError: false,
  },
  videoViews: {
    data: [],
    isFetching: false,
  },
  totalImpressions: {
    data: [],
    isFetching: false,
  },
  totalEngagement: {
    data: [],
    isFetching: false,
  },
  publishedPosts: {
    data: [],
    isFetching: false,
  },
  totalPublishedPosts: {
    data: [],
    isFetching: false,
  },
  subAccounts: [],
  isFetching: false,
  isError: false,
  currentAccount: {},
};

export const insightsSocial = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_OVERVIEW.FAILURE:
      return produce(state, draftState => {
        draftState.socialOverview.isFetching = false;
        draftState.socialOverview.isError = true;
      });

    case ACTIONS.GET_OVERVIEW.REQUEST:
      return produce(state, draftState => {
        draftState.socialOverview.isFetching = true;
      });

    case ACTIONS.GET_OVERVIEW.SUCCESS:
      return produce(state, draftState => {
        draftState.socialOverview.isFetching = false;
        draftState.socialOverview.data = action.payload;
      });

    case ACTIONS.GET_SUB_ACCOUNTS.FAILURE:
      return produce(state, draftState => {
        draftState.isFetching = false;
        draftState.isError = true;
      });

    case ACTIONS.GET_SUB_ACCOUNTS.REQUEST:
      return produce(state, draftState => {
        draftState.isFetching = true;
      });

    case ACTIONS.GET_SUB_ACCOUNTS.SUCCESS:
      return produce(state, draftState => {
        const accounts = [...action.payload?.included, ...action.payload?.data].filter(
          acc =>
            acc.attributes.type === SocialFeedsSource.INSTAGRAM || acc.attributes.type === SocialFeedsSource.TIK_TOK
        );

        draftState.isFetching = false;
        draftState.subAccounts = accounts.map(acc => {
          return {
            ...acc,
            account:
              acc?.type === 'subaccount'
                ? action.payload.data.find(data => data.relationships.subaccounts.data.find(sub => sub.id === acc.id))
                : action.payload.data.find(data => data.id === acc.id),
          };
        });
      });

    case ACTIONS.GET_PROFILE_VIEWS.FAILURE:
      return produce(state, draftState => {
        draftState.profileViews.isFetching = false;
        draftState.profileViews.isError = true;
      });

    case ACTIONS.GET_PROFILE_VIEWS.REQUEST:
      return produce(state, draftState => {
        draftState.profileViews.isFetching = true;
      });

    case ACTIONS.GET_PROFILE_VIEWS.SUCCESS:
      return produce(state, draftState => {
        draftState.profileViews.isFetching = false;
        draftState.profileViews.data = action.payload;
      });

    case ACTIONS.GET_WEBSITE_CLICKS.FAILURE:
      return produce(state, draftState => {
        draftState.websiteClicks.isFetching = false;
        draftState.websiteClicks.isError = false;
      });

    case ACTIONS.GET_WEBSITE_CLICKS.REQUEST:
      return produce(state, draftState => {
        draftState.websiteClicks.isFetching = true;
      });

    case ACTIONS.GET_WEBSITE_CLICKS.SUCCESS:
      return produce(state, draftState => {
        draftState.websiteClicks.isFetching = false;
        draftState.websiteClicks.data = action.payload;
      });

    case ACTIONS.GET_IMPRESSIONS.FAILURE:
      return produce(state, draftState => {
        draftState.impressions.isFetching = false;
        draftState.impressions.isError = false;
      });

    case ACTIONS.GET_IMPRESSIONS.REQUEST:
      return produce(state, draftState => {
        draftState.impressions.isFetching = true;
      });

    case ACTIONS.GET_IMPRESSIONS.SUCCESS:
      return produce(state, draftState => {
        draftState.impressions.isFetching = false;
        draftState.impressions.data = action.payload;
      });

    case ACTIONS.GET_NEW_IMPRESSIONS.FAILURE:
      return produce(state, draftState => {
        draftState.newImpressions.isFetching = false;
        draftState.newImpressions.isError = false;
      });

    case ACTIONS.GET_NEW_IMPRESSIONS.REQUEST:
      return produce(state, draftState => {
        draftState.newImpressions.isFetching = true;
      });

    case ACTIONS.GET_NEW_IMPRESSIONS.SUCCESS:
      return produce(state, draftState => {
        draftState.newImpressions.isFetching = false;
        draftState.newImpressions.data = action.payload;
      });

    case ACTIONS.GET_REACH.FAILURE:
      return produce(state, draftState => {
        draftState.reach.isFetching = false;
        draftState.reach.isError = false;
      });

    case ACTIONS.GET_REACH.REQUEST:
      return produce(state, draftState => {
        draftState.reach.isFetching = true;
      });

    case ACTIONS.GET_REACH.SUCCESS:
      return produce(state, draftState => {
        draftState.reach.isFetching = false;
        draftState.reach.data = action.payload;
      });

    case ACTIONS.GET_POST_ENGAGEMENT.FAILURE:
      return produce(state, draftState => {
        draftState.postEngagement.isFetching = false;
        draftState.postEngagement.isError = true;
      });

    case ACTIONS.GET_POST_ENGAGEMENT.REQUEST:
      return produce(state, draftState => {
        draftState.postEngagement.isFetching = true;
      });

    case ACTIONS.GET_POST_ENGAGEMENT.SUCCESS:
      return produce(state, draftState => {
        draftState.postEngagement.isFetching = false;
        draftState.postEngagement.data = action.payload.data;
        draftState.postEngagement.growth = action.payload.growth;
      });

    case ACTIONS.GET_AUDIENCE_GROWTH.FAILURE:
      return produce(state, draftState => {
        draftState.audienceGrowth.isFetching = false;
        draftState.audienceGrowth.isError = true;
      });

    case ACTIONS.GET_AUDIENCE_GROWTH.REQUEST:
      return produce(state, draftState => {
        draftState.audienceGrowth.isFetching = true;
      });

    case ACTIONS.GET_AUDIENCE_GROWTH.SUCCESS:
      return produce(state, draftState => {
        draftState.audienceGrowth.isFetching = false;
        draftState.audienceGrowth.data = action.payload;
      });

    case ACTIONS.GET_MOST_ENGAGED_FOLLOWERS.FAILURE:
      return produce(state, draftState => {
        draftState.mostEngagedFollowers.isFetching = false;
        draftState.mostEngagedFollowers.isError = true;
      });

    case ACTIONS.GET_MOST_ENGAGED_FOLLOWERS.REQUEST:
      return produce(state, draftState => {
        draftState.mostEngagedFollowers.isFetching = true;
      });

    case ACTIONS.GET_MOST_ENGAGED_FOLLOWERS.SUCCESS:
      return produce(state, draftState => {
        draftState.mostEngagedFollowers.isFetching = false;
        draftState.mostEngagedFollowers.data = action.payload;
      });

    case ACTIONS.GET_MOST_ENGAGED_TYPE.FAILURE:
      return produce(state, draftState => {
        draftState.mostEngagedType.isFetching = false;
        draftState.mostEngagedType.isError = true;
      });

    case ACTIONS.GET_MOST_ENGAGED_TYPE.REQUEST:
      return produce(state, draftState => {
        draftState.mostEngagedType.isFetching = true;
      });

    case ACTIONS.GET_MOST_ENGAGED_TYPE.SUCCESS:
      return produce(state, draftState => {
        draftState.mostEngagedType.isFetching = false;
        draftState.mostEngagedType.data = action.payload;
      });

    case ACTIONS.GET_AUDIENCE_GENDER.FAILURE:
      return produce(state, draftState => {
        draftState.audienceGender.isFetching = false;
        draftState.audienceGender.isError = true;
      });

    case ACTIONS.GET_AUDIENCE_GENDER.REQUEST:
      return produce(state, draftState => {
        draftState.audienceGender.isFetching = true;
      });

    case ACTIONS.GET_AUDIENCE_GENDER.SUCCESS:
      return produce(state, draftState => {
        draftState.audienceGender.isFetching = false;
        draftState.audienceGender.data = action.payload;
      });

    case ACTIONS.GET_AUDIENCE_BY_AGE.FAILURE:
      return produce(state, draftState => {
        draftState.audienceByAge.isFetching = false;
        draftState.audienceByAge.isError = true;
      });

    case ACTIONS.GET_AUDIENCE_BY_AGE.REQUEST:
      return produce(state, draftState => {
        draftState.audienceByAge.isFetching = true;
      });

    case ACTIONS.GET_AUDIENCE_BY_AGE.SUCCESS:
      return produce(state, draftState => {
        draftState.audienceByAge.isFetching = false;
        draftState.audienceByAge.data = action.payload;
      });

    case ACTIONS.GET_TOP_COUNTRIES.FAILURE:
      return produce(state, draftState => {
        draftState.topCountries.isFetching = false;
        draftState.topCountries.isError = true;
      });

    case ACTIONS.GET_TOP_COUNTRIES.REQUEST:
      return produce(state, draftState => {
        draftState.topCountries.isFetching = true;
      });

    case ACTIONS.GET_TOP_COUNTRIES.SUCCESS:
      return produce(state, draftState => {
        draftState.topCountries.isFetching = false;
        draftState.topCountries.data = action.payload;
      });

    case ACTIONS.GET_TOP_CITIES.FAILURE:
      return produce(state, draftState => {
        draftState.topCities.isFetching = false;
        draftState.topCities.isError = true;
      });

    case ACTIONS.GET_TOP_CITIES.REQUEST:
      return produce(state, draftState => {
        draftState.topCities.isFetching = true;
      });

    case ACTIONS.GET_TOP_CITIES.SUCCESS:
      return produce(state, draftState => {
        draftState.topCities.isFetching = false;
        draftState.topCities.data = action.payload;
      });

    case ACTIONS.GET_STORIES_ENGAGEMENT.FAILURE:
      return produce(state, draftState => {
        draftState.storiesEngagement.isFetching = false;
        draftState.storiesEngagement.isError = true;
      });

    case ACTIONS.GET_STORIES_ENGAGEMENT.REQUEST:
      return produce(state, draftState => {
        draftState.storiesEngagement.isFetching = true;
      });

    case ACTIONS.GET_STORIES_ENGAGEMENT.SUCCESS:
      return produce(state, draftState => {
        draftState.storiesEngagement.isFetching = false;
        draftState.storiesEngagement.data = action.payload;
      });

    case ACTIONS.GET_NEW_POST_ENGAGEMENT.FAILURE:
      return produce(state, draftState => {
        draftState.newPostEngagement.isFetching = false;
        draftState.newPostEngagement.isError = true;
      });

    case ACTIONS.GET_NEW_POST_ENGAGEMENT.REQUEST:
      return produce(state, draftState => {
        draftState.newPostEngagement.isFetching = true;
      });

    case ACTIONS.GET_NEW_POST_ENGAGEMENT.SUCCESS:
      return produce(state, draftState => {
        draftState.newPostEngagement.isFetching = false;
        draftState.newPostEngagement.data = action.payload;
      });

    case ACTIONS.GET_DAILY_ENGAGEMENTS.FAILURE:
      return produce(state, draftState => {
        draftState.dailyEngagement.isFetching = false;
        draftState.dailyEngagement.isError = true;
      });

    case ACTIONS.GET_DAILY_ENGAGEMENTS.REQUEST:
      return produce(state, draftState => {
        draftState.dailyEngagement.isFetching = true;
      });

    case ACTIONS.GET_DAILY_ENGAGEMENTS.SUCCESS:
      return produce(state, draftState => {
        draftState.dailyEngagement.isFetching = false;
        draftState.dailyEngagement.data = action.payload;
      });

    case ACTIONS.GET_CLUSTER_POST_ENGAGEMENT.FAILURE:
      return produce(state, draftState => {
        draftState.clusterPostEngagement.isFetching = false;
        draftState.clusterPostEngagement.isError = true;
      });

    case ACTIONS.GET_CLUSTER_POST_ENGAGEMENT.REQUEST:
      return produce(state, draftState => {
        draftState.clusterPostEngagement.isFetching = true;
      });

    case ACTIONS.GET_CLUSTER_POST_ENGAGEMENT.SUCCESS:
      return produce(state, draftState => {
        draftState.clusterPostEngagement.isFetching = false;
        draftState.clusterPostEngagement.data = action.payload;
        draftState.clusterPostEngagement.avgFollowersRate =
          Math.round(
            (action.payload.reduce((acc, currentValue) => {
              return acc + currentValue.attributes.engagement_count;
            }, 0) /
              action.payload.length) *
              10
          ) / 10;
        const maxCluster = action.payload.reduce((acc, current) => {
          return acc > current.attributes.cluster ? acc : current.attributes.cluster;
        }, 0);

        const aggregateClusters = [...new Array(maxCluster + 1)].map(() => ({ avg: null, data: [] }));
        action.payload.forEach(p => {
          aggregateClusters[p.attributes.cluster].data.push(p);
        });

        aggregateClusters.forEach(cluster => {
          cluster.avg =
            Math.round(
              (cluster.data.reduce((acc, currentValue) => {
                return acc + currentValue.attributes.engagement_count;
              }, 0) /
                cluster.data.length) *
                10
            ) / 10;
        });

        draftState.clusterPostEngagement.bestPerformingContent = aggregateClusters.filter(cluster => {
          return cluster.avg > draftState.clusterPostEngagement.avgFollowersRate;
        });

        draftState.clusterPostEngagement.lowerPerformingContent = aggregateClusters.filter(cluster => {
          return cluster.avg <= draftState.clusterPostEngagement.avgFollowersRate;
        });
      });

    case ACTIONS.SET_CURRENT_ACCOUNT:
      return produce(state, draftState => {
        if (!action.payload.isAccount) {
          draftState.currentAccount = {
            ...state?.subAccounts?.find(acc => acc.id === action.payload.id),
            subAccountId: action.payload.id,
            source: action.payload.source,
          };
        } else {
          const account = state?.subAccounts?.find(acc => acc.id === action.payload.id);
          draftState.currentAccount = {
            account,
            ...account,
            subAccountId: -1,
            accountId: action.payload.id,
            source: action.payload?.source,
          };
        }
      });

    case ACTIONS.GET_VIDEO_VIEWS.REQUEST:
      return produce(state, draftState => {
        draftState.videoViews.isFetching = true;
      });

    case ACTIONS.GET_VIDEO_VIEWS.SUCCESS:
      return produce(state, draftState => {
        draftState.videoViews.isFetching = false;
        draftState.videoViews.data = action.payload;
      });

    case ACTIONS.GET_VIDEO_VIEWS.FAILURE:
      return produce(state, draftState => {
        draftState.videoViews.isFetching = false;
      });

    case ACTIONS.GET_TOTAL_IMPRESSION.REQUEST:
      return produce(state, draftState => {
        draftState.totalImpressions.isFetching = true;
      });

    case ACTIONS.GET_TOTAL_IMPRESSION.SUCCESS:
      return produce(state, draftState => {
        draftState.totalImpressions.isFetching = false;
        draftState.totalImpressions.data = action.payload;
      });

    case ACTIONS.GET_TOTAL_IMPRESSION.FAILURE:
      return produce(state, draftState => {
        draftState.totalImpressions.isFetching = false;
      });

    case ACTIONS.GET_TOTAL_ENGAGEMENT.REQUEST:
      return produce(state, draftState => {
        draftState.totalEngagement.isFetching = true;
      });

    case ACTIONS.GET_TOTAL_ENGAGEMENT.SUCCESS:
      return produce(state, draftState => {
        draftState.totalEngagement.isFetching = false;
        draftState.totalEngagement.data = action.payload;
      });

    case ACTIONS.GET_TOTAL_ENGAGEMENT.FAILURE:
      return produce(state, draftState => {
        draftState.totalEngagement.isFetching = false;
      });

    case ACTIONS.GET_PUBLISHED_POSTS.REQUEST:
      return produce(state, draftState => {
        draftState.publishedPosts.isFetching = true;
      });

    case ACTIONS.GET_PUBLISHED_POSTS.SUCCESS:
      return produce(state, draftState => {
        draftState.publishedPosts.isFetching = false;
        draftState.publishedPosts.data = action.payload;
      });

    case ACTIONS.GET_PUBLISHED_POSTS.FAILURE:
      return produce(state, draftState => {
        draftState.publishedPosts.isFetching = false;
      });

    case ACTIONS.GET_TOTAL_PUBLISHED_POSTS.REQUEST:
      return produce(state, draftState => {
        draftState.totalPublishedPosts.isFetching = true;
      });

    case ACTIONS.GET_TOTAL_PUBLISHED_POSTS.SUCCESS:
      return produce(state, draftState => {
        draftState.totalPublishedPosts.isFetching = false;
        draftState.totalPublishedPosts.data = action.payload;
      });

    case ACTIONS.GET_TOTAL_PUBLISHED_POSTS.FAILURE:
      return produce(state, draftState => {
        draftState.totalPublishedPosts.isFetching = false;
      });

    case ACTIONS.GET_ENGAGEMENT.REQUEST:
      return produce(state, draftState => {
        draftState.engagements.isFetching = true;
      });

    case ACTIONS.GET_ENGAGEMENT.SUCCESS:
      return produce(state, draftState => {
        draftState.engagements.isFetching = false;
        draftState.engagements.data = action.payload;
      });

    case ACTIONS.GET_ENGAGEMENT.FAILURE:
      return produce(state, draftState => {
        draftState.engagements.isFetching = false;
      });

    default:
      return state;
  }
};
