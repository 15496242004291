import React from 'react';
import PropTypes from 'prop-types';
import './marketing-integrations-table-row.sass';
import { TableListPrimaryRow } from '../../../../../../components/interface/lists/table-list-primary/components/table-list-row/table-list-primary-row';
import { MarketingIntegrationsSettingsList } from '../settings-list/marketing-integrations-settings-list';
import { Button } from '../../../../../../components/interface/button/button';
import ButtonContentIcon from '../../../../../../components/interface/button-content-icon/button-content-icon';

export const ExpirationStatuses = {
  READY: 'ready',
  ERROR: 'error',
};

export const MarketingIntegrationsTableRow = ({ marketingIntegration, onRemove, onResolve }) => (
  <TableListPrimaryRow>
    <div data-flex={0} data-width={'56px'}>
      <ButtonContentIcon
        source={marketingIntegration.attributes.source}
        circleSize={30}
        fontSize={15}
        svgIconSize={20}
      />
    </div>
    <div data-flex={3} className='tint-social-connection-row__name'>
      {displayName(marketingIntegration)}
    </div>
    <div data-flex={2}>
      <div className='tint-marketing-integrations-table-row__settings-button'>
        {marketingIntegration.attributes.status === ExpirationStatuses.ERROR && (
          <Button
            type='orange'
            size='medium'
            onClick={() => onResolve(marketingIntegration.attributes.source, marketingIntegration.id)}
            icon='fas fa-exclamation-circle'>
            Resolve
          </Button>
        )}
        <MarketingIntegrationsSettingsList marketingIntegration={marketingIntegration} onRemove={onRemove} />
      </div>
    </div>
  </TableListPrimaryRow>
);

MarketingIntegrationsTableRow.propTypes = {
  marketingIntegration: PropTypes.object,
};

const displayName = marketingIntegration => {
  if (marketingIntegration.attributes.source === 'twitter') {
    return (marketingIntegration.attributes.external_id || '').split('|').join(' ');
  }
  return marketingIntegration.attributes.name || marketingIntegration.attributes.username;
};
