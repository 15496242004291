import Worker from './hashWorker.worker.js';

export const fileChecksum = async (file, onprogress) => {
  return await md5FromFile(file, onprogress);
};

const md5FromFile = (file, onprogress) => {
  return new Promise((resolve, reject) => {
    const worker = new Worker();

    worker.postMessage(file);
    worker.onmessage = event => {
      if (event.data.type === 'progress') {
        onprogress?.(event.data);
      }
      if (event.data.type === 'result') {
        resolve(event.data.hashBase64);
      }
    };
    worker.onerror = reject;
  });
};
