import React from 'react';
import PropTypes from 'prop-types';

const CustomButton = ({ styles, buttonUrl, buttonTitle }) => {
  return (
    <div className='tint-custom-button'>
      <a href={buttonUrl} target='_blank' rel='noopener noreferrer' style={styles}>
        <span>{buttonTitle}</span>
      </a>
    </div>
  );
};

export default CustomButton;

CustomButton.propTypes = {
  styles: PropTypes.shape({
    buttonTextColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    buttonColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    buttonOutlineType: PropTypes.number,
    buttonOutline: PropTypes.string,
    buttonOutlineBorderColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    backgroundColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  buttonUrl: PropTypes.string,
  buttonTitle: PropTypes.string,
};
