import { ACTIONS } from '../actions/transform-ui-canvas.actions';

export const initialState = {
  canvas: {},
};

export const assetManagerTransformUICanvas = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SAVE_CANVAS_STATE:
      return {
        ...state,
        ...{
          canvas: action.payload,
        },
      };
    case ACTIONS.SAVE_DEFAULT_CANVAS_STATE:
      return {
        ...state,
        defaultCanvas: action.payload,
      };

    default:
      return state;
  }
};
