import { ProductFeeds } from '../../types/product-feeds';

export const ProductFeedsTranslations = {
  [ProductFeeds.FTP]: 'FTP',
  [ProductFeeds.SALESFORCE_COMMERCE_CLOUD]: 'Salesforce Commerce Cloud',
  [ProductFeeds.BIGCOMMERCE]: 'BigCommerce',
  [ProductFeeds.WOOCOMMERCE]: 'WooCommerce',
  [ProductFeeds.SHOPIFY]: 'Shopify',
  [ProductFeeds.MAGENTO]: 'Magento',
  [ProductFeeds.FACEBOOK]: 'Facebook',
  [ProductFeeds.CSV]: 'CSV',
  [ProductFeeds.CUSTOM]: 'Custom',
  [ProductFeeds.BAZAARVOICE]: 'Bazaarvoice',
};
