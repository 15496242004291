import React, { useEffect, useRef } from 'react';
import './image-draggable-rect.sass';
import useDrag from '../../hooks/useDrag';
import useResizeElement, { UseDragResizeType } from '../../hooks/useResizeElement';
import { getPercentValue } from '../../hooks/helpers';
import { useDispatch } from 'react-redux';
import { updateRectUI } from '../../actions/ui-ecommerce.actions';

export const ImageDraggableRectStatus = {
  DRAW_START: 'draw-start',
  DRAW_END: 'draw-end',
  DRAG_START: 'drag-start',
  DRAG_END: 'drag-end',
  RESIZE_START: 'resize-start',
  RESIZE_END: 'resize-end',

  COMPLETE: 'complete',
  EDITABLE: 'editable',
  UNASSIGNED: 'unassigned',
  ASSIGN: 'assign',
};

export const imageDraggableSizeElement = 'icon-size';

const ImageDraggableRect = ({
  dragElement,
  dragAreaDimensions,
  id,
  status = ImageDraggableRectStatus.EDITABLE,
  defaultStyle,
  drawStatus,
  onRectClick,
  onEditStartClick,
  onEditEndClick,
  isHover,
  isAssigned,
  updateProductTag,
}) => {
  const dispatch = useDispatch();
  const sizeElementRef = useRef();
  const rightResizeElement = useRef();
  const bottomResizeElement = useRef();
  const leftResizeElement = useRef();
  const topResizeElement = useRef();
  const bottomRightResizeElement = useRef();
  const topRightResizeElement = useRef();
  const topLeftResizeElement = useRef();
  const bottomLeftResizeElement = useRef();

  const isComplete = status === ImageDraggableRectStatus.COMPLETE;
  const { dragStatus } = useDrag(dragElement, dragAreaDimensions);

  const resizeElementList = [
    { type: UseDragResizeType.RIGHT, element: rightResizeElement },
    { type: UseDragResizeType.LEFT, element: leftResizeElement },
    { type: UseDragResizeType.BOTTOM, element: bottomResizeElement },
    { type: UseDragResizeType.TOP, element: topResizeElement },
    { type: UseDragResizeType.BOTTOM_RIGHT, element: bottomRightResizeElement },
    { type: UseDragResizeType.TOP_RIGHT, element: topRightResizeElement },
    { type: UseDragResizeType.BOTTOM_LEFT, element: bottomLeftResizeElement },
    { type: UseDragResizeType.TOP_LEFT, element: topLeftResizeElement },
  ];

  const { resizeStatus } = useResizeElement(dragElement, resizeElementList, sizeElementRef, dragAreaDimensions);

  useEffect(() => {
    handleRectStatus(dragStatus);
  }, [dragStatus]);

  useEffect(() => {
    handleRectStatus(resizeStatus);
  }, [resizeStatus]);

  const handleRectStatus = status => {
    let productTagDetails = mapUpdatedRectUIDetails();

    switch (status) {
      case ImageDraggableRectStatus.RESIZE_END:
      case ImageDraggableRectStatus.DRAG_END:
        dispatch(updateRectUI(productTagDetails));
        if (updateProductTag) {
          updateProductTag(productTagDetails);
        }
        break;

      case ImageDraggableRectStatus.RESIZE_START:
      case ImageDraggableRectStatus.DRAG_START:
      default:
        return;
    }
  };

  const mapUpdatedRectUIDetails = () => {
    return {
      rectId: id,
      width: getPercentValue(dragElement.current.clientWidth, dragAreaDimensions.width),
      height: getPercentValue(dragElement.current.clientHeight, dragAreaDimensions.height),
      x: getPercentValue(dragElement.current.offsetLeft, dragAreaDimensions.width),
      y: getPercentValue(dragElement.current.offsetTop, dragAreaDimensions.height),
    };
  };

  const rectangleActionClass =
    drawStatus === ImageDraggableRectStatus.DRAW_START
      ? 'tint-draggable-rect--draw-start'
      : 'tint-draggable-rect--draw-end';

  const isCompleteClass = isComplete && 'draggable-rect-resize--complete';

  const renderDraggableElements = () => (
    <>
      <span
        className={`draggable-rect-resize draggable-rect-resize--left ${isCompleteClass}`}
        ref={leftResizeElement}
      />
      <span
        className={`draggable-rect-resize draggable-rect-resize--right ${isCompleteClass}`}
        ref={rightResizeElement}
      />
      <span
        className={`draggable-rect-resize draggable-rect-resize--bottom ${isCompleteClass}`}
        ref={bottomResizeElement}
      />
      <span className={`draggable-rect-resize draggable-rect-resize--top ${isCompleteClass}`} ref={topResizeElement} />

      <span
        className={`draggable-rect-resize draggable-rect-resize--bottom-right ${isCompleteClass}`}
        ref={bottomRightResizeElement}
      />
      <span
        className={`draggable-rect-resize draggable-rect-resize--bottom-left ${isCompleteClass}`}
        ref={bottomLeftResizeElement}
      />
      <span
        className={`draggable-rect-resize draggable-rect-resize--top-right ${isCompleteClass}`}
        ref={topRightResizeElement}
      />
      <span
        className={`draggable-rect-resize draggable-rect-resize--top-left ${isCompleteClass}`}
        ref={topLeftResizeElement}
      />
    </>
  );

  const isRectExist = dragElement && dragElement.current;

  return (
    <div
      ref={dragElement}
      onClick={e => {
        e.stopPropagation();
        onRectClick && !isComplete && onRectClick(id);
      }}
      className={`tint-draggable-rect tint-draggable-rect--status-${status} ${rectangleActionClass}`}
      style={{ ...defaultStyle }}>
      {renderDraggableElements()}
      {status === ImageDraggableRectStatus.COMPLETE && <span className='inactive-overflow' />}
      {status === ImageDraggableRectStatus.COMPLETE && (
        <span
          className={`icon fas fa-tag ${isHover && 'icon--active'}`}
          onClick={e => {
            e.stopPropagation();
            onEditStartClick(id);
          }}
        />
      )}

      {(status !== ImageDraggableRectStatus.COMPLETE || resizeStatus === ImageDraggableRectStatus.RESIZE_START) &&
        sizeElementRef &&
        isRectExist &&
        dragElement.current.clientWidth > 0 && (
          <span
            ref={sizeElementRef}
            className={`icon ${imageDraggableSizeElement}`}
            onClick={e => {
              e.stopPropagation();
              isAssigned && onEditEndClick(id);
            }}>
            {`${dragElement.current.clientWidth}px x ${dragElement.current.clientHeight}px`}
          </span>
        )}
    </div>
  );
};

export default ImageDraggableRect;
