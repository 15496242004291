import { ACTIONS } from '../../actions/builder/form-builder.builder.form.actions';

export const initialState = {
  language: '',
};

//used only for actions which will be publicly available
export const formBuilderFormReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };

    case ACTIONS.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
