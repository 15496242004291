import React from 'react';
import './single-answer.sass';
import * as PropTypes from 'prop-types';
import CheckboxButton, {
  CheckboxButtonType,
} from '../../../../../../../../components/interface/inputs/checkbox-button/checkbox-button';
import ColorParser from '../../../../../../../../services/color-parser/color-parser';
import { pollImageType } from '../../../../../../../../model/poll-image-type.model';
const SingleAnswer = ({ answer, styles, imageType, onUpdateAnswerStatus, getTranslation }) => {
  return (
    <div
      className='survey-single-answer-container'
      style={{
        background: ColorParser.defaultColor(styles.answerBackgroundColor),
      }}>
      <div className='survey-single-answer-container__top'>
        <CheckboxButton
          color={ColorParser.defaultColor(styles.answerTextColor)}
          borderColor={ColorParser.defaultColor(styles.answerTextColor)}
          markColor={ColorParser.defaultColor(styles.answerBackgroundColor)}
          id={answer.id}
          onStatusChange={onUpdateAnswerStatus}
          type={CheckboxButtonType.ROUND}
        />
        <h5 style={{ color: ColorParser.defaultColor(styles.answerTextColor) }}>
          {getTranslation(answer.title) || 'Title'}
        </h5>
      </div>
      {getTranslation(answer.description) ? (
        <p style={{ color: ColorParser.defaultColor(styles.answerTextColor) }}>{getTranslation(answer.description)}</p>
      ) : null}
      {getTranslation(answer.imageUrl) ? (
        <div className='survey-single-answer-container__image-wrapper'>
          <div
            className={`survey-single-answer-container__thumb ${
              imageType !== undefined && imageType === pollImageType.FIT
                ? 'survey-single-answer-container__thumb--fit'
                : ''
            }`}
            style={{
              backgroundImage: `url("${getTranslation(answer.imageUrl)}")`,
            }}
          />{' '}
        </div>
      ) : null}
    </div>
  );
};

SingleAnswer.propTypes = {
  answer: PropTypes.object,
  styles: PropTypes.object,
  imageType: PropTypes.string,
  onUpdateAnswerStatus: PropTypes.func,
};

export default SingleAnswer;
