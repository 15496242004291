import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from '../../../../../components/interface/button/button';
import './settings-bar.sass';
import { Config } from '../../../../../utils/config';
import { track } from '../../../../../services/functions/analytics-wrapper/analytics-wrapper';
import { withRouter } from 'react-router-dom';
import { ExperienceSettings } from './experience-settings/experience-settings';
import { toast } from 'react-toastify';
import { ModalHoc } from '../../../../../components/HOC/modal/modal.hoc';
import { EditModal } from '../../../../../components/edit-modal/edit-modal/edit-modal';
import { setExperienceBuilderWidth } from '../../../actions/experience-builder';
import {
  addSection,
  deleteSection,
  publishExperience,
  saveDataAttributesDraft,
} from '../../../actions/experience/experience.fat-actions';
import {
  addCustomLanguage,
  deleteCustomLanguage,
  discardDraftData,
  setDefaultLanguage,
} from '../../../actions/experience/experience.actions';
import { isDataPublished } from '../../../../../model/experience-status.model';
import { EditModalStep } from '../../../../../components/edit-modal/edit-modal-step/edit-modal-step';
import { handleApiError } from '../../../../../services/functions/error-handler/error-handler';
import { ButtonType } from '../../../../../components/interface/dropdown/button/dropdown-button';
import DropdownButton from '../../../../../components/interface/dropdown/button/dropdown-button';
import { DropdownHeader } from '../../../../../components/interface/dropdown/button/header/dropdown-header';
import LanguageList from '../../../../../components/interface/dropdown/lists/language/language-list';
import { getLanguageDirection, isoLanguageList } from '../../../../../services/functions/language/language';
import { ExperienceLanguageSettings } from './experience-language-settings/experience-language-settings';
import { setCurrentLanguage } from '../../../actions/languages/languages.actions';
import { getCustomLanguageList } from '../../../reducers/experience/experience';
import { pageSectionType } from '../../../../../model/page-section-type.model';
import { ExperienceConfig } from '../../../utils/experience.config';
import { openSidebar as openGlobalSidebar, SIDEBAR_TYPE } from '../../../../../actions/sidebar';
import { sidebarUpgradeSchema } from '../../../../../components/sidebar-upgrade/sidebar-upgrade.schema';

const displayModeArray = [
  { class: 'fas fa-laptop', width: ExperienceConfig.pageSize.desktop },
  { class: 'fas fa-tablet-alt', width: ExperienceConfig.pageSize.tablet },
  //{class: 'fas fa-mobile-alt contest-settings-bar__center__rotate '},
  { class: 'fas fa-mobile-alt', width: ExperienceConfig.pageSize.mobile },
];

class SettingsBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      displayModeSelected: 0,
      languageList: Object.entries(isoLanguageList).map(lang => {
        return { value: lang[0], name: lang[1].name };
      }),
      isLanguageModalOpen: false,
      isLanguageMetaEnabled: this.props.session.features['experiences_advanced_templates'],
      cookieConsent: this.props.draftExperience.attributes.cookie_consent && this.hasCookieBannerSection(),
    };
  }

  hasCookieBannerSection = () => {
    return (
      this.props.draftExperience.attributes.data.draft.findIndex(
        section => section.sectionType === pageSectionType.COOKIE_BANNER
      ) !== -1
    );
  };

  closeModal() {
    this.setState({ isLanguageModalOpen: false });
    this.props.discardDraftData();
    this.props.closeModal();
  }

  getAddressUrl() {
    if (this.props.draftExperience?.links?.public) {
      return this.props.draftExperience.links.public;
    } else {
      return Config.getTintPageUrl(
        this.props.draftExperience.relationships.user.data.id,
        this.props.draftExperience.attributes.slug
      );
    }
  }

  checkSettingsChanges = () => {
    this.setState({
      cookieConsent: this.props.draftExperience.attributes.cookie_consent && this.hasCookieBannerSection(),
    });

    return this.props.saveDraftData().catch(err => handleApiError(err, toast.error));
  };

  renderModal() {
    return (
      this.props.isModalOpen && (
        <EditModal
          isVisible={this.props.isModalOpen}
          saveData={this.checkSettingsChanges}
          closeModal={this.closeModal.bind(this)}
          dismissModal={this.closeModal.bind(this)}>
          <EditModalStep
            title='Experience Settings'
            closeModal={this.closeModal.bind(this)}
            onCancelClick={() => this.addCookieBannerSection(this.state.cookieConsent)}>
            <ExperienceSettings
              experience={this.props.experience}
              draftExperience={this.props.draftExperience}
              experienceUrl={this.getAddressUrl()}
              dataUpdate={this.props.saveDataAttributesDraft}
              cookieConsent={this.state.cookieConsent}
              onAddCookieBanner={this.addCookieBannerSection}
            />
          </EditModalStep>
        </EditModal>
      )
    );
  }

  renderLanguageModal() {
    return (
      this.state.isLanguageModalOpen && (
        <EditModal
          isVisible={this.props.state}
          saveData={this.props.saveDraftData}
          closeModal={this.closeModal.bind(this)}
          dismissModal={this.closeModal.bind(this)}>
          <EditModalStep title='Language Settings' closeModal={this.closeModal.bind(this)}>
            <ExperienceLanguageSettings
              description={'Set the default page for your experience.'}
              languages={this.state.languageList}
              selectedLanguages={this.props.languages}
              currentLanguage={this.props.currentLanguage}
              defaultLanguage={this.props.defaultLanguage}
              onDefaultLanguageChange={lang => {
                this.props.setDefaultLanguage(lang);
                this.props.setCurrentLanguage({
                  value: lang.value.replace('_', '-'),
                  direction: getLanguageDirection(lang.value),
                });
              }}
              onAddLanguage={this.props.addCustomLanguages}
              onDeleteLanguage={this.props.deleteCustomLanguage}
              dataUpdate={this.props.saveDataAttributesDraft}
              saveData={this.props.saveDraftData}
            />
          </EditModalStep>
        </EditModal>
      )
    );
  }

  componentDidMount() {
    this.props.setCurrentLanguage({
      value: this.props.defaultLanguage,
      direction: getLanguageDirection(this.props.defaultLanguage),
    });
  }

  goToDashboard = () => {
    this.props.history.push(Config.routePaths.experienceBuilder.path);
  };

  renderSizeMode() {
    return displayModeArray.map((e, i) => {
      return (
        <i
          key={i}
          className={`${e.class} ${i === this.state.displayModeSelected ? 'selected' : ''}`}
          onClick={() => {
            this.setState({ displayModeSelected: i });
            this.props.setPageBuilderWidth(e.width);
          }}
        />
      );
    });
  }

  publishExperience() {
    if (isDataPublished(this.props.draftExperience, this.props.experience)) {
      return;
    }

    if (!this.props.session.features.experiences) {
      toast.warn('You need to upgrade your plan type to publish experience.');
      return;
    }
    this.props.publishExperience(this.props.draftExperience).then(
      () => {
        toast.success("We've published your page!");
      },
      err => {
        handleApiError(err, toast.error);
      }
    );

    track('Click on Publish', {
      type: this.props.draftExperience.attributes.type,
      experience_id: this.props.draftExperience.id,
      link: this.props.draftExperience?.links?.public,
      device_type: navigator.userAgent,
    });
  }

  onLanguageChange = currentItem => {
    this.props.setCurrentLanguage({
      value: currentItem.value,
      direction: getLanguageDirection(currentItem.value),
    });
  };

  onAddLanguage = () => {
    this.setState({
      isLanguageModalOpen: true,
    });
  };

  getLanguage = () => {
    return this.props.languages.find(language => language.isDefault);
  };

  addCookieBannerSection = isEnabled => {
    this.props.saveDataAttributesDraft({ cookie_consent: isEnabled });

    if (isEnabled) {
      if (!this.hasCookieBannerSection()) {
        this.props.addCookieBanner(pageSectionType.COOKIE_BANNER, -1, { cookie_consent: isEnabled });
      }
    } else {
      const cookieBannerIndex = this.props.draftExperience.attributes.data.draft.findIndex(
        section => section.sectionType === pageSectionType.COOKIE_BANNER
      );
      this.props.deleteSection(cookieBannerIndex, { cookie_consent: isEnabled });
    }
  };

  render() {
    return this.props.draftExperience !== undefined && this.props.languages[0] ? (
      <div className='tint-experience-settings-bar'>
        <div className='tint-experience-settings-bar__left'>
          <span className='far fa-angle-left tint-experience-settings-bar__back-button' onClick={this.goToDashboard} />
          <input
            onBlur={e => {
              this.props.saveDataAttributesDraft({ name: e.target.value });
              this.props.saveDraftData();
            }}
            key={this.props.draftExperience.attributes.name}
            placeholder='Project Name'
            defaultValue={this.props.draftExperience.attributes.name}
          />
        </div>
        <div className='tint-experience-settings-bar__center'>{this.renderSizeMode()}</div>
        <div className='tint-experience-settings-bar__right'>
          {this.state.isLanguageMetaEnabled ? (
            <DropdownButton
              dropdownHeader={props => <DropdownHeader {...props} />}
              dropdownList={props => <LanguageList {...props} currentLanguage={this.props.currentLanguage} />}
              list={this.props.languages}
              placeholder={this.getLanguage().name}
              iconRight='fa fa-caret-down'
              buttonType={ButtonType.BUTTON_PRIMARY}
              onChangeValue={this.onLanguageChange}
              onAction={this.onAddLanguage}
            />
          ) : null}
          <a
            href={Config.routePaths.experienceBuilder.preview.createUrl(this.props.draftExperience.id)}
            target='_blank'
            rel='noopener noreferrer'
            onClick={() => {
              track('Click on Preview', {
                type: this.props.draftExperience.attributes.type,
                experience_id: this.props.draftExperience.id,
              });
            }}>
            <Button type='secondary' text='Preview' />
          </a>
          <a onClick={this.publishExperience.bind(this)}>
            <Button
              type={isDataPublished(this.props.draftExperience, this.props.experience) ? 'disabled' : 'primary'}
              text='Publish'
            />
          </a>
          <div
            className='tint-experience-settings-bar__right__settings'
            onClick={e => {
              if (this.props.session.features.experiences) {
                this.props.openModal(e);
              } else {
                this.props.openGlobalSidebar();
              }
            }}>
            <i className='fas fa-cog' />
          </div>
        </div>
        {this.renderModal()}
        {this.renderLanguageModal()}
      </div>
    ) : null;
  }
}

const mapStateToProps = state => ({
  currentLanguage: state.languages.currentLanguage,
  draftExperience: state.experience.dataDraft,
  experience: state.experience.data,
  session: state.session.data,
  languages: getCustomLanguageList(state.experience.data, 'draft'),
});

const mapDispatchToProps = dispatch => ({
  addCookieBanner: (pageSectionType, pageSectionIndex, cookieConsent) =>
    dispatch(addSection(pageSectionType, pageSectionIndex, cookieConsent)),
  deleteSection: (pageSectionIndex, cookieConsent) => dispatch(deleteSection(pageSectionIndex, cookieConsent)),
  setCurrentLanguage: lang => dispatch(setCurrentLanguage(lang)),
  setDefaultLanguage: lang => dispatch(setDefaultLanguage(lang)),
  addCustomLanguages: lang => dispatch(addCustomLanguage(lang)),
  deleteCustomLanguage: lang => dispatch(deleteCustomLanguage(lang)),
  setPageBuilderWidth: width => dispatch(setExperienceBuilderWidth(width)),
  publishExperience: experience => dispatch(publishExperience(experience)),
  discardDraftData: () => dispatch(discardDraftData()),
  saveDataAttributesDraft: attributes => dispatch(saveDataAttributesDraft(attributes)),
  openGlobalSidebar: () =>
    dispatch(
      openGlobalSidebar({
        modalType: SIDEBAR_TYPE.UPGRADE_SIDEBAR,
        data: {
          sidebarUpgradeData: sidebarUpgradeSchema.default,
        },
      })
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ModalHoc(SettingsBar)));
