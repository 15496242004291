import React, { useRef } from 'react';
import './dropdown-date-range-calendar.sass';
import useDropdownListPosition from '../../../../hooks/use-dropdown-list-position/use-dropdown-list-position';
import { Config } from '../../../../../utils/config';
import PropTypes from 'prop-types';
import { TintDatePicker } from '../../../date-picker/date-picker';
import { DropdownDateRangeHeader } from '../date-list/header/dropdown-date-range-header/dropdown-date-range-header';

const DropdownDateRangeCalendar = ({
  onChange,
  onClose,
  parentRef,
  onChangeTextComponent,
  currentItem,
  minDate,
  maxDate,
  noAllTime = false,
  numberOfDaysAllow,
}) => {
  const listRef = useRef();
  const { positionHorizontal } = useDropdownListPosition(listRef, parentRef);

  const getDateFrom = dateTimestamp => {
    return `${Config.monthList[new Date(dateTimestamp).getMonth()]} ${new Date(dateTimestamp).getDate()}, ${new Date(
      dateTimestamp
    ).getFullYear()}`;
  };

  const DropdownTextElement = title => {
    return <DropdownDateRangeHeader title={title} />;
  };

  const onApply = date => {
    onChange({
      value: {
        startDate: new Date(date.startDate).getTime(),
        endDate: new Date(date.endDate).getTime(),
      },
      name: `${getDateFrom(date.startDate)} - ${getDateFrom(date.endDate)}`,
    });
    onChangeTextComponent(DropdownTextElement(`${getDateFrom(date.startDate)} - ${getDateFrom(date.endDate)}`));
  };

  return (
    <div
      className={`tint-dropdown-date-range-calendar tint-dropdown-date-range-calendar--${positionHorizontal}`}
      ref={listRef}
      data-testid='dropdown-date-range-calendar'>
      <TintDatePicker
        currentDate={currentItem}
        onApply={onApply}
        onCancel={onClose}
        maxDate={maxDate}
        minDate={minDate}
        noAllTime={noAllTime}
        numberOfDaysAllow={numberOfDaysAllow}
      />
    </div>
  );
};

export default DropdownDateRangeCalendar;

DropdownDateRangeCalendar.propTypes = {
  onChange: PropTypes.func,
  onChangeTextComponent: PropTypes.func,
};
