import React, { Component } from 'react';
import './footer-section.sass';
import { EditFooterContent } from './edit/edit-footer-content';
import { EditFooterStyles } from './edit/edit-footer-styles';
import { PageSectionHoc } from '../../../../../../components/HOC/page-section/page-section.hoc';
import { EditModalSwitcher } from '../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher';
import { EditModal } from '../../../../../../components/edit-modal/edit-modal/edit-modal';
import { EditModalStep } from '../../../../../../components/edit-modal/edit-modal-step/edit-modal-step';
import { Config } from '../../../../../../utils/config';
import ColorParser from '../../../../../../services/color-parser/color-parser';
import { SectionLogo } from '../../logo/section-logo';
import { pageSectionType } from '../../../../../../model/page-section-type.model';
import Logger from '../../../../../../services/logger/logger';

class FooterSection extends Component {
  openCookieBanner = () => {
    try {
      const GDPRComplianceLocalStorageKey = `${Config.localStorage.keys.GDPRComplianceLocalStorageName}_${window.location.origin}`;
      const GDPRComplianceLocalStorageValue = JSON.parse(window.localStorage.getItem(GDPRComplianceLocalStorageKey));
      localStorage.setItem(
        GDPRComplianceLocalStorageKey,
        JSON.stringify({
          ...GDPRComplianceLocalStorageValue,
          seen: null,
        })
      );
      window.scrollTo(0, 0);
      this.props.onOpenCookieBanner(pageSectionType.COOKIE_BANNER);
    } catch (e) {
      Logger.info(e);
    }
  };

  render() {
    return (
      <div
        className={`tint-page-section tint-page-section--footer ${this.props.getSectionClass()}`}
        style={{
          color: ColorParser.defaultColor(this.props.data.styles.textColor),
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`,
        }}>
        {this.props.renderGenericComponents()}

        {this.props.isModalOpen && (
          <EditModal
            nextStep={this.props.nextStep}
            isVisible={this.props.isModalOpen}
            saveData={this.props.onDataSave}
            dismissModal={this.props.dismissModal}
            closeModal={this.props.closeModal}>
            <EditModalStep closeModal={this.props.dismissModal} title='Edit Footer'>
              <EditModalSwitcher>
                <EditFooterContent
                  experienceType={this.props.experience.attributes.type}
                  maxFileSize={this.props.getMaxImageSizePlanLimit()}
                  data={this.props.data.content}
                  saveData={this.props.onDataSave}
                  dataUpdate={this.props.onContentUpdate}
                  getTranslation={this.props.getTranslation}
                  onTranslationUpdate={this.props.onTranslationUpdate}
                  onNewTranslationUpdate={this.props.onNewTranslationUpdate}
                  cookieEnabled={this.props.cookieEnabled}
                />
                <EditFooterStyles
                  data={this.props.data}
                  saveData={this.props.onDataSave}
                  dataUpdate={this.props.onContentUpdate}
                  styleDataUpdate={this.props.onStylesUpdate}
                />
              </EditModalSwitcher>
            </EditModalStep>
          </EditModal>
        )}
        <SectionLogo
          logoHeight={this.props.data.styles.logoHeight}
          logoRedirectUrl={this.props.data.content.logoRedirectUrl}
          logoUrl={this.props.data.content.logoUrl}
        />
        <div className='tint-page-section__footer-information'>
          {this.props.getTranslation(this.props.data.content.footerCopyright) ? (
            <p className='tint-page-section__footer-information--copyrights'>
              {this.props.getTranslation(this.props.data.content.footerCopyright)}
            </p>
          ) : null}

          <ul className='footer-section-nav'>
            {this.props.data.content.footerLinks.map((e, i) =>
              e.text ? (
                <li key={i}>
                  <a
                    style={{
                      color: ColorParser.defaultColor(this.props.data.styles.textColor),
                    }}
                    rel='noopener noreferrer'
                    href={Config.getValidRedirectUrl(this.props.getTranslation(e.url))}
                    target='_blank'>
                    {this.props.getTranslation(e.text)}
                  </a>
                </li>
              ) : null
            )}
            {this.props.cookieEnabled ? (
              <li key='menage-key'>
                <a
                  style={{
                    color: ColorParser.defaultColor(this.props.data.styles.textColor),
                  }}
                  onClick={this.openCookieBanner}
                  rel='noopener noreferrer'>
                  {this.props.getTranslation(this.props.data.content.manageCookiesLabel)
                    ? this.props.getTranslation(this.props.data.content.manageCookiesLabel)
                    : 'Manage Cookies'}
                </a>
              </li>
            ) : null}
          </ul>
        </div>
      </div>
    );
  }
}

export default PageSectionHoc(FooterSection);
