import { ACTIONS } from '../actions/dropdown';

const initialState = {};

const dropdown = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.ON_SELECT_MODAL:
      return {
        ...state,
        value: action.payload.value,
      };

    default:
      return state;
  }
};

export default dropdown;
