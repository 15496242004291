import { SidebarIcons, SidebarItemTypes } from '../utils/meta/consts';
import { formsDisabledButton, SocialFeedsFooterLabels } from './helpers';
import { validationSchemaForm } from '../utils/validation-schema/form-schema';
import { SocialFeedsAccountTypes, BackendKeys, FormMediaKeys } from '../consts';

export const forms = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext,
    },
    accountSourceType: 'form_submission',
    selectedOption: { id: 'forms', title: 'Add Form' },
    header: 'Add Form',
    description: 'To display Form submissions in your board, fill out the sections below:',
    formSchema: {
      forms: {
        validationSchema: validationSchemaForm(),
        submitMapper: values => {
          return {
            external_id: values.form.value,
            options: {
              author_name_field: values.name.value,
              post_body_field: values.body.value,
              post_media_url_key: values.media.value,
            },
            type: 'form',
          };
        },
        inputs: [
          {
            id: SocialFeedsAccountTypes.FORM,
            backendId: BackendKeys.EXTERNAL_ID,
            label: 'SELECT FORM',
            placeholder: 'Select Form...',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
          },
          {
            id: FormMediaKeys.MEDIA,
            backendId: BackendKeys.POST_MEDIA_URL_KEY,
            label: 'SELECT MEDIA',
            placeholder: 'Select...',
            description: 'Choose the media field',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
          },
          {
            id: 'name',
            backendId: BackendKeys.AUTHOR_NAME_FIELD,
            label: 'SELECT AUTHOR NAME',
            placeholder: 'Select...',
            description: 'Choose the field that you want to map to the post’s author name section.',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
          },
          {
            id: 'body',
            backendId: BackendKeys.POST_BODY_FIELD,
            label: 'POST BODY',
            placeholder: 'Select...',
            description: 'Choose the field that you want to map to the post’s body content section.',
            icon: SidebarIcons.LIST,
            type: SidebarItemTypes.FORM_SELECT,
            disabled: formsDisabledButton,
          },
        ],
      },
    },
  },
];
