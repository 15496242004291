import { produce } from 'immer';
import { ACTIONS } from '../../actions/enchancers/filterable.actions';
import { FilterGroupTypes } from '../../feature/tint-editor/components/tint-editor/top-bar-filter/filter-modal/tint-editor-top-bar-filter-modal';

export const initialState = {
  selectedFilters: {},
};

export function filterable(reducer, config, initialState) {
  const _config = { ...ACTIONS, ...config };

  return (state = initialState, action) => {
    switch (action.type) {
      case _config.SET_FILTERS:
        return produce(state, draft => {
          draft.selectedFilters = {
            ...draft.selectedFilters,
            [action.payload.type]:
              action.payload.type !== FilterGroupTypes.DATE
                ? action.payload.data.sort((a, b) => (a > b ? 1 : -1))
                : action.payload.data.length !== 0
                ? action.payload.data
                : undefined,
          };
        });

      case _config.CLEAR_FILTERS:
        return produce(state, draft => {
          draft.selectedFilters = {};
        });

      default:
        return reducer(state, action);
    }
  };
}
