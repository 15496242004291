import { createPrefixedActionName, createRoutine } from '../../../services/functions/redux-routines/redux-routines';

export const ACTIONS = {
  GET_MIX: createRoutine(createPrefixedActionName('TINT_MIX_PREVIEW', 'GET_MIX')),
  GET_TINT_PERSONALIZATION: createRoutine(createPrefixedActionName('TINT_MIX_PREVIEW', 'GET_TINT_PERSONALIZATION')),
  SET_CURRENT_SCENE_ID: createPrefixedActionName('TINT_MIX_PREVIEW', 'SET_CURRENT_SCENE_ID'),
  GET_DEFAULT_PERSONALIZATION: createRoutine(
    createPrefixedActionName('TINT_MIX_PREVIEW', 'GET_DEFAULT_PERSONALIZATION')
  ),
};

export const getTintMixStart = () => ({
  type: ACTIONS.GET_MIX.REQUEST,
});

export const getTintMixSuccess = data => ({
  type: ACTIONS.GET_MIX.SUCCESS,
  payload: data,
});

export const getTintMixError = err => ({
  type: ACTIONS.GET_MIX.FAILURE,
  payload: err,
});

export const getDefaultPersonalizationStart = () => ({
  type: ACTIONS.GET_DEFAULT_PERSONALIZATION.REQUEST,
});

export const getDefaultPersonalizationSuccess = defaultPersonalization => ({
  type: ACTIONS.GET_DEFAULT_PERSONALIZATION.SUCCESS,
  payload: defaultPersonalization,
});

export const getDefaultPersonalizationError = err => ({
  type: ACTIONS.GET_DEFAULT_PERSONALIZATION.FAILURE,
  payload: err,
});

export const getTintPersonalizationStart = () => ({
  type: ACTIONS.GET_TINT_PERSONALIZATION.REQUEST,
});

export const getTintPersonalizationSuccess = data => ({
  type: ACTIONS.GET_TINT_PERSONALIZATION.SUCCESS,
  payload: data,
});

export const getTintPersonalizationError = err => ({
  type: ACTIONS.GET_TINT_PERSONALIZATION.FAILURE,
  payload: err,
});

export const setCurrentSceneID = index => ({
  type: ACTIONS.SET_CURRENT_SCENE_ID,
  payload: index,
});
