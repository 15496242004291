import React from 'react';
import PropTypes from 'prop-types';
import TintModal from '../../../../../../../components/modal/modal';
import ModalHeader from '../../../../../../../components/modal/components/modal-header/modal-header';
import ModalFooter from '../../../../../../../components/modal/components/footers/modal-footer/modal-footer';

export const FormContestRulesModal = ({ title, body, show, onHide }) => {
  return (
    <TintModal
      className='post-section-modal'
      headerTitle={title}
      modalHeader={props => <ModalHeader {...props} />}
      modalFooter={props => <ModalFooter {...props} />}
      isOpen={show}
      onClose={onHide}>
      <p>{body}</p>
    </TintModal>
  );
};

FormContestRulesModal.propTypes = {
  title: PropTypes.string,
  body: PropTypes.any,
};
