import { SidebarIcons, SidebarItemTypes } from '../utils/meta/consts';
import { SocialFeedsFooterLabels } from './helpers';
import { validationSchemaYoutubeBrandContent } from '../utils/validation-schema/youtube-validation';

export const youtube = [
  {
    header: 'Add YouTube',
    description: 'Import content from your authorized YouTube channel.',
    zendeskUrl: undefined,
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext,
    },
    accountSourceType: 'youtube',
    selectedOption: { id: 'posted', title: 'Add YouTube' },
    formSchema: {
      posted: {
        id: 'posted',
        title: 'Brand Content',
        validationSchema: validationSchemaYoutubeBrandContent(),
        submitMapper: values => ({
          account_id: values.account.value,
          type: 'posted',
        }),
        inputs: [
          {
            id: 'account',
            backendId: 'account_id',
            label: 'SELECT ACCOUNT',
            placeholder: 'Select account...',
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
          },
        ],
      },
    },
  },
];
