import React, { Component } from 'react';
import './poll-section.sass';
import { PageSectionHoc } from '../../../../../../components/HOC/page-section/page-section.hoc';
import { EditModal } from '../../../../../../components/edit-modal/edit-modal/edit-modal';
import { fetchPosts } from '../../../../actions/posts';
import { selectIsLoading, selectPosts, selectPostsWithImages } from '../../../../reducers/post/posts';
import { connect } from 'react-redux';
import { EditModalStep } from '../../../../../../components/edit-modal/edit-modal-step/edit-modal-step';
import { EditModalSwitcher } from '../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher';
import { EditPollContent } from './edit/poll-edit-content';
import PostList from './components/post-list/post-list';
import PollEditStyles from './edit/poll-edit-styles';
import VotePostItemContainer from './components/vote-post-item/vote-post-item-container';
import PreloaderComponent, {
  PreloaderColorType,
} from '../../../../../../components/interface/preloaders/preloader/preloader';
import ColorParser from '../../../../../../services/color-parser/color-parser';

const postIdKey = 'tint-votePostIdList';

class PollSection extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      isVoteAllowed: false,
      currentPollSize: this.props.data.content.pollSize,
      postIdKey: this.props.experienceId ? `${postIdKey}__${this.props.experienceId}` : postIdKey,
      hasGetPosts: this.props.filteredPosts.length > 0,
    };
  }

  componentDidMount() {
    this.amountOfVotes = this.props.data.content.amountOfVotes || 2;
    if (this.isDropdownOptionsChecked()) {
      this.getTintPosts();
    }
    this.isVoteAble();
  }

  componentDidUpdate(prevProps) {
    this.amountOfVotes = this.props.data.content.amountOfVotes || 2;
    this.getTints(prevProps);
  }

  getTints(prevProps) {
    if (this.areEnoughPosts(prevProps)) {
      this.setState({
        currentPollSize: Number(this.props.data.content.pollSize),
        hasGetPosts: true,
      });
    } else if (this.arePostsUpdated(prevProps)) {
      this.getMoreTints();
      this.setState({ hasGetPosts: true });
    }

    if (this.isDropdownOptionsChecked() && this.isPropertyChanged(prevProps)) {
      this.setState({
        currentPollSize: Number(this.props.data.content.pollSize),
        hasGetPosts: false,
      });
      this.getTintPosts();
      if (!this.areEnoughPosts(prevProps)) {
        this.setState({
          hasGetPosts: true,
        });
      }
    }
  }

  sortById(arr) {
    return arr.sort((a, b) => {
      return Number(a.id) - Number(b.id);
    });
  }

  arePostsUpdated(prevProps) {
    return (
      (this.props.filteredPosts.length !== prevProps.posts.length || this.props.filteredPosts.length === 0) &&
      this.props.posts.length !== 0 &&
      prevProps.posts.length !== this.props.posts.length
    );
  }

  getMoreTints() {
    const pollSizeDifferentCount = this.getPollSizeDifference();
    this.setState({
      currentPollSize: pollSizeDifferentCount,
    });
    this.getTintPosts(pollSizeDifferentCount);
  }

  areEnoughPosts(prevProps) {
    return (
      this.props.posts.length !== prevProps.posts.length &&
      this.props.filteredPosts.length === Number(this.props.data.content.pollSize)
    );
  }

  getPollSizeDifference() {
    return (
      Number(this.props.data.content.pollSize) + Number(this.state.currentPollSize) - this.props.filteredPosts.length
    );
  }

  isPropertyChanged(prevProps) {
    const { pollSize, tintSlug } = this.props.data.content;

    return tintSlug !== prevProps.data.content.tintSlug || pollSize !== prevProps.data.content.pollSize;
  }

  isDropdownOptionsChecked() {
    const { pollSize, tintSlug } = this.props.data.content;

    return pollSize && pollSize !== 'null' && tintSlug !== 'null' && tintSlug && tintSlug.length > 0;
  }

  getTintPosts = (pollSize = this.props.data.content.pollSize) => {
    const { tintSlug } = this.props.data.content;

    if (this.isDropdownOptionsChecked()) {
      this.props.fetchPosts(pollSize, tintSlug);
    }
  };

  isVoteAble = () => {
    const votedPostList = JSON.parse(localStorage.getItem(this.state.postIdKey)) || [];

    const visibleVotedPostList = this.props.filteredPosts.filter(item => {
      return votedPostList.some(votedPostListItem => {
        return votedPostListItem.id === item.id;
      });
    });

    this.updateVotedPosts(visibleVotedPostList, votedPostList);
    this.setState({
      isVoteAllowed: votedPostList.length < Number(this.amountOfVotes),
    });

    return votedPostList.length < this.amountOfVotes;
  };

  updateVotedPosts(visibleVotedPostList, votedPostList) {
    const isVotedPostInvisible = visibleVotedPostList.length <= this.amountOfVotes && votedPostList.length > 0;

    if (isVotedPostInvisible && this.props.isPreviewMode && this.props.filteredPosts.length > 0) {
      if (visibleVotedPostList.length === 1 && votedPostList.length === this.amountOfVotes) {
        localStorage.setItem(this.state.postIdKey, JSON.stringify(visibleVotedPostList));
      } else if (visibleVotedPostList.length === 0 && votedPostList.length === this.amountOfVotes) {
        localStorage.removeItem(this.state.postIdKey);
      }
    }
  }

  renderTintPosts() {
    const styles = this.props.data.styles;
    const { experienceId, isPreviewMode } = this.props;
    const { buttonText, buttonVotedText } = this.props.data.content;

    return (
      <>
        {this.props.isLoading ? (
          <PreloaderComponent color={PreloaderColorType.WHITE} style={{ minHeight: '50px' }} />
        ) : null}

        {this.isDropdownOptionsChecked() && !this.props.isLoading && this.state.hasGetPosts ? (
          <PostList posts={this.sortById(this.props.filteredPosts)}>
            {post => (
              <VotePostItemContainer
                post={post}
                imageType={this.props.data.content.imageType}
                postIdKey={this.state.postIdKey}
                isPreviewMode={isPreviewMode}
                isVoteAllowed={this.state.isVoteAllowed}
                checkIsVoteAllow={this.isVoteAble}
                buttonText={this.props.getTranslation(buttonText)}
                buttonVotedText={this.props.getTranslation(buttonVotedText)}
                style={styles}
                experienceId={experienceId}
              />
            )}
          </PostList>
        ) : null}
      </>
    );
  }

  render() {
    return (
      <div
        className={`tint-page-section tint-page-section--poll ${this.props.getSectionClass()}`}
        style={{
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`,
        }}>
        {this.props.renderGenericComponents()}

        {this.renderTintPosts()}

        {this.props.isModalOpen && (
          <EditModal
            nextStep={this.props.nextStep}
            isVisible={this.props.isModalOpen}
            saveData={this.props.onDataSave}
            dismissModal={this.props.dismissModal}
            closeModal={this.props.closeModal}>
            <EditModalStep title='Edit Poll'>
              <EditModalSwitcher>
                <EditPollContent
                  getTranslation={this.props.getTranslation}
                  onTranslationUpdate={this.props.onTranslationUpdate}
                  data={this.props.data}
                  dataUpdate={this.props.onContentUpdate}
                />
                <PollEditStyles
                  data={this.props.data}
                  dataUpdate={this.props.onContentUpdate}
                  styleDataUpdate={this.props.onStylesUpdate}
                />
              </EditModalSwitcher>
            </EditModalStep>
          </EditModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  posts: selectPosts(state.posts),
  isLoading: selectIsLoading(state.posts),
  filteredPosts: selectPostsWithImages(state.posts),
  experienceId: state.experience.data && state.experience.data.id,
});

export default connect(mapStateToProps, { fetchPosts })(PageSectionHoc(PollSection));
