import React from 'react';
import PropTypes from 'prop-types';
import './post-list.sass';

const PostList = ({ posts, children }) => {
  return (
    <ul className='post-list' data-testid='postList'>
      {posts.map(post => (
        <li key={post.id}>{children(post)}</li>
      ))}
    </ul>
  );
};

PostList.propTypes = {
  posts: PropTypes.array,
  children: PropTypes.func,
};

export default PostList;
