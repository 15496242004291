import React, { useState } from 'react';
import './assign-products-sidebar.sass';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import DropdownButton, { ButtonType } from '../../../../components/interface/dropdown/button/dropdown-button';
import { DropdownHeader } from '../../../../components/interface/dropdown/button/header/dropdown-header';
import ProductItem from '../products/product-item/product-item';
import TintSidebar, { TintSidebarHeightType } from '../../../../components/sidebar/sidebar';
import SearchInput from '../../../../components/search-input/search-input';
import {
  assignProduct,
  dismissDrawRectUI,
  selectProduct,
  editRectUIStatus,
  unselectProduct,
  cancelDrawRectUI,
} from '../../actions/ui-ecommerce.actions';
import {
  SidebarFooter,
  SidebarFooterClickButtonAction,
} from '../../../../components/sidebar/components/footer/sidebar-footer';
import { postProductTag, deleteProductTag } from '../../actions/ui-ecommerce.fat-actions';
import { getTaggedProducts } from '../../reducer/ui-ecommerce.reducer';
import { ImageDraggableRectStatus } from '../image-draggable-rect/image-draggable-rect';
import { toast } from 'react-toastify';
import DropdownSimpleList from '../../../../components/interface/dropdown/lists/simple-list/dropdown-simple-list';
import DropdownInfiniteList from '../../../../components/interface/dropdown/lists/infinite-list/dropdown-infinite-list';
import PreloaderComponent from '../../../../components/interface/preloaders/preloader/preloader';
import { setDefaultFilteredProducts } from '../../../account-settings/actions/products/products.actions';
import { handleApiError } from '../../../../services/functions/error-handler/error-handler';
import { addPostProductTag, deletePostProductTag } from '../../../tint-editor/actions/posts.actions';
import { getSocialFeed } from '../../../tint-editor/reducers/posts.reducer';
import { TintEditorConfig } from '../../../tint-editor/utils/tint-editor.config';

export const AssignProductsSidebarStepType = {
  STEP_ONE: 'step-one',
  STEP_TWO: 'step-two',
  STEP_THREE: 'step-three',
};

const AssignProductsSidebar = ({
  isSidebarVisible,
  onInputChange,
  onProductFeedChange,
  onProductCategoryChange,
  onProductBrandChange,
  onAssignProduct,
  onSidebarClose,
  filteredProductList,
  filteredProductListLinks,
  fetchNextProducts,
  fetchNextProductFeeds,
  onSearchProductFeeds,
  productsError,
  productFeeds,
  productCategories,
  productBrand,
  onAddProduct,
  onProductOver,
  onProductLeave,
  post,
  tintId,
  isPending,
  productFilters,
  isAbleToLoadProductFeeds,
}) => {
  const dispatch = useDispatch();
  const uiEcommerce = useSelector(state => state.UIEcommerce);
  const filteredAndInfiniteLoadedProductList = useSelector(state => state.products.infiniteLoadedData);
  const taggedProducts = uiEcommerce.taggedProducts;
  const hasTaggedProducts = uiEcommerce?.data?.included?.length === 0;
  const currentRect = uiEcommerce.currentRect;
  const currentProduct = uiEcommerce.selectedProduct;
  const [searchInputValue, setSearchInputValue] = useState('');
  const currentTaggedProduct = getTaggedProducts(uiEcommerce);
  const sidebarStatus = uiEcommerce.sidebarStatus;
  const socialFeed = useSelector(state => getSocialFeed(state.tintEditorPosts, post?.id));

  const onProductRemove = (productId, rectId) => {
    dispatch(deleteProductTag({ id: rectId, tintId }))
      .then(() => {
        dispatch(deletePostProductTag(post.attributes.external_id, rectId, post.attributes.attachment_id));
        toast.success('Product tag has been removed successfully');
      })
      .catch(err => handleApiError(err, toast.error));
  };

  const onSelectProduct = product => {
    dispatch(selectProduct(product));
    currentTaggedProduct?.rectId &&
      dispatch(editRectUIStatus(ImageDraggableRectStatus.ASSIGN, currentTaggedProduct.rectId));
  };

  const mapProductTag = productTag => {
    return {
      id: productTag.productId,
      name: productTag.name,
      sku: productTag.sku,
      imageUrl: productTag.image_url ? productTag.image_url : productTag.imageUrl,
    };
  };

  const selectedProductFeed =
    productFilters && productFilters.product_feed_ids
      ? productFeeds.find(e => e.key === productFilters.product_feed_ids)
      : undefined;
  const selectedCategory =
    productFilters && productFilters.categories
      ? productCategories.find(e => e.key === productFilters.categories)
      : undefined;
  const selectedBrand =
    productFilters && productFilters.brand ? productBrand.find(e => e.key === productFilters.brand) : undefined;

  const renderProductListView = () => {
    return (
      <>
        <div className='tint-assign-products-sidebar__filters'>
          <div className='tint-assign-products-sidebar__search'>
            <SearchInput
              onInputChange={e => {
                onInputChange(e);
                setSearchInputValue(e);
              }}
              inputValue={searchInputValue}
            />
          </div>
          <div className='tint-assign-products-sidebar__search'>
            <DropdownButton
              dropdownHeader={props => <DropdownHeader {...props} />}
              dropdownList={props => (
                <DropdownInfiniteList
                  {...props}
                  onLoadData={fetchNextProductFeeds}
                  isAbleToLoad={productFeeds.length >= 25 && isAbleToLoadProductFeeds}
                  onInputChange={onSearchProductFeeds}
                />
              )}
              buttonType={ButtonType.BUTTON_GRAY_BORDER}
              list={productFeeds}
              placeholder='Product Feed'
              iconRight='fa fa-caret-down'
              currentItem={selectedProductFeed}
              onChangeValue={onProductFeedChange}
            />
          </div>
          <div className='tint-assign-products-sidebar__wrapper'>
            <DropdownButton
              dropdownHeader={props => <DropdownHeader {...props} />}
              dropdownList={props => <DropdownSimpleList {...props} />}
              buttonType={ButtonType.BUTTON_GRAY_BORDER}
              list={productBrand}
              placeholder={'Brand'}
              iconRight='fa fa-caret-down'
              currentItem={selectedBrand}
              onChangeValue={onProductBrandChange}
            />
            <DropdownButton
              dropdownHeader={props => <DropdownHeader {...props} />}
              dropdownList={props => <DropdownSimpleList {...props} />}
              buttonType={ButtonType.BUTTON_GRAY_BORDER}
              list={productCategories}
              placeholder={'Category'}
              iconRight='fa fa-caret-down'
              currentItem={selectedCategory}
              onChangeValue={onProductCategoryChange}
            />
          </div>
        </div>
        <ul className='tint-assign-products-sidebar__product-list'>
          {filteredProductList.length > 0 ? (
            <InfiniteScroll
              dataLength={productsError ? 0 : filteredProductList.length}
              next={fetchNextProducts}
              hasMore={filteredProductListLinks && filteredProductListLinks.next && !productsError}
              loader={<PreloaderComponent />}
              scrollableTarget='scrollableTarget'>
              {filteredProductList.map(product => (
                <li key={product.id}>
                  <ProductItem
                    isDisabled={!currentRect && post.attributes.type === 'image'}
                    isActive={currentProduct && currentProduct.id === product.id}
                    productDetails={product}
                    onProductClick={onSelectProduct}
                  />
                </li>
              ))}
            </InfiniteScroll>
          ) : (
            <div className='tint-assign-products-sidebar__no-products-msg'>
              <h4>No Products Found</h4>
            </div>
          )}
        </ul>
      </>
    );
  };

  const renderSelectedProductsList = () => {
    return (
      <ul className='tint-assign-products-sidebar__product-list'>
        {taggedProducts.length > 0 ? (
          taggedProducts.map(
            product =>
              product.productId && (
                <li key={product.rectId}>
                  <ProductItem
                    isActive={
                      currentTaggedProduct &&
                      currentTaggedProduct.productId === product.productId &&
                      currentTaggedProduct.rectId === product.rectId
                    }
                    key={product.productId}
                    productDetails={mapProductTag(product)}
                    onProductClick={onAddProduct}
                    onProductOver={onProductOver}
                    onProductLeave={onProductLeave}
                    onProductRemove={() => onProductRemove(product.productId, product.rectId)}
                  />
                </li>
              )
          )
        ) : (
          <div className='tint-assign-products-sidebar__no-products-msg'>
            <h4>No Products Assigned</h4>
            <p>To assign a product, you first need to add a product tag to the post.</p>
          </div>
        )}
      </ul>
    );
  };

  const sidebarViewSwitcher = type => {
    switch (type) {
      case AssignProductsSidebarStepType.STEP_TWO:
        return renderProductListView();

      case AssignProductsSidebarStepType.STEP_ONE:
      case AssignProductsSidebarStepType.STEP_THREE:
        return renderSelectedProductsList();

      default:
        return;
    }
  };

  const getCurrentProduct = productId => {
    return filteredAndInfiniteLoadedProductList.find(product => product.id === productId);
  };

  const onProductListClose = () => {
    dispatch(cancelDrawRectUI());
    dispatch(setDefaultFilteredProducts());
  };

  const createNewProductTag = (productId, rectId) => {
    const taggedProduct = taggedProducts.find(e => e.status === ImageDraggableRectStatus.ASSIGN);
    const productAttributes =
      taggedProduct.x && taggedProduct.y
        ? {
            x: taggedProduct.x.toString(),
            y: taggedProduct.y.toString(),
            height: taggedProduct.height.toString(),
            width: taggedProduct.width.toString(),
            product_id: productId,
            external_id: post.social_post_id || post.attributes.external_id,
            source: socialFeed?.attributes?.source,
            ...(post.attributes.attachment_id || post.attributes.attachment_id === 0
              ? { attachment_id: String(post.attributes.attachment_id) }
              : {}),
          }
        : {
            product_id: productId,
            external_id: post.social_post_id || post.attributes.external_id,
            source: socialFeed?.attributes?.source,
            ...(post.attributes.attachment_id || post.attributes.attachment_id === 0
              ? { attachment_id: String(post.attributes.attachment_id) }
              : {}),
          };

    return dispatch(
      postProductTag({
        tintId: tintId !== TintEditorConfig.routePaths.global.tintId ? tintId : post?.relationships?.tint?.data?.id,
        rectId,
        productId,
        attributes: productAttributes,
      })
    );
  };

  const handleProductsListFooterButtonAction = buttonType => {
    switch (buttonType) {
      case SidebarFooterClickButtonAction.LEFT_BUTTON:
        onProductListClose();
        break;

      case SidebarFooterClickButtonAction.RIGHT_BUTTON:
        createNewProductTag(currentProduct.id, currentRect.rectId)
          .then(res => {
            toast.success('Product tag has been created successfully');
            dispatch(assignProduct(getCurrentProduct(currentProduct.id), res.id));
            dispatch(dismissDrawRectUI());
            dispatch(unselectProduct());
            onAssignProduct && onAssignProduct();
            dispatch(
              addPostProductTag(post.attributes.external_id, {
                type: 'product_tag',
                id: res.id,
                attachmentId: post.attributes.attachment_id,
              })
            );
          })
          .catch(() => toast.error('Cannot create a product tag, please try again'));
        break;

      default:
        return;
    }
  };

  const renderSidebarFooter = props => {
    switch (sidebarStatus) {
      case AssignProductsSidebarStepType.STEP_TWO:
        return (
          <SidebarFooter
            {...props}
            oneButton={filteredProductList.length === 0}
            onClickButton={handleProductsListFooterButtonAction}
            isSubmitting={false}
            isRightButtonDisabled={!currentProduct || !getCurrentProduct(currentProduct.id)}
            rightButtonText='Assign Product'
          />
        );

      case AssignProductsSidebarStepType.STEP_ONE:
      case AssignProductsSidebarStepType.STEP_THREE:
        return (
          <SidebarFooter
            {...props}
            oneButton={true}
            onClickButton={onSidebarClose}
            isSubmitting={false}
            leftButtonText={taggedProducts.length === 0 && hasTaggedProducts ? 'Cancel' : 'Done'}
          />
        );

      default:
        return <SidebarFooter {...props} onClickButton={handleProductsListFooterButtonAction} oneButton={true} />;
    }
  };

  return (
    <TintSidebar
      isOpen={isSidebarVisible}
      onClose={onSidebarClose}
      type={TintSidebarHeightType.BELOW_NAVBAR}
      title={sidebarStatus === AssignProductsSidebarStepType.STEP_TWO ? 'Assign Products' : 'Assigned Products'}
      defaultFooterStyle={false}
      footerComponent={props => renderSidebarFooter(props)}
      sidebarClassName={'tint-assign-products-sidebar'}>
      {isPending ? <PreloaderComponent /> : sidebarViewSwitcher(sidebarStatus)}
    </TintSidebar>
  );
};
export default AssignProductsSidebar;
