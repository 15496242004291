import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ValidationAlert from '../../../../../components/interface/inputs/validation-alert/validation-alert';
import './edit-textarea.sass';

export default class EditTextArea extends Component {
  render() {
    const isError = this.props.errorMsg && this.props.touched;
    const errorClasses = isError ? 'tint-edit-input-container--error' : '';

    return this.props.isInvisible ? null : (
      <div className={`tint-edit-input-container ${errorClasses}`} style={{ marginBottom: this.props.margin + 'px' }}>
        {this.props.label && (
          <span className='tint-edit-input-container__label'>
            {this.props.label}
            {this.props.required ? '*' : null}
          </span>
        )}
        <textarea
          onChange={this.props.onChange}
          onBlur={this.props.onBlur}
          onFocus={this.props.onFocus}
          defaultValue={this.props.defaultValue}
          value={this.props.value}
          name={this.props.name}
          readOnly={this.props.readOnly}
          placeholder={this.props.placeholder}
          disabled={this.props.disabled}
          rows={this.props.styles?.rows}
          required
        />
        {this.props.children}
        <ValidationAlert errorMsg={this.props.errorMsg} isVisible={isError} />
      </div>
    );
  }
}

EditTextArea.defaultProps = {
  isInvisible: false,
  defaultValue: '',
};

EditTextArea.propTypes = {
  isInvisible: PropTypes.bool,
  margin: PropTypes.number,
  label: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  defaultValue: PropTypes.string,
  value: PropTypes.string,
  name: PropTypes.string,
  readOnly: PropTypes.bool,
  placeholder: PropTypes.string,
  children: PropTypes.object,
  errorMsg: PropTypes.string,
};
