import { createPrefixedActionName, createRoutine } from '../../../../services/functions/redux-routines/redux-routines';
import { ROLES } from '../../consts';

export const ACTIONS = {
  GET_ROLES: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLES')),
  GET_ROLE: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE')),
  POST_ROLE: createRoutine(createPrefixedActionName(ROLES, 'POST_ROLE')),
  PATCH_ROLE: createRoutine(createPrefixedActionName(ROLES, 'PATCH_ROLE')),
  DELETE_ROLE: createRoutine(createPrefixedActionName(ROLES, 'DELETE_ROLE')),
  GET_ROLE_ACCOUNTS: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE_ACCOUNTS')),
  GET_ROLE_TINTS: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE_TINTS')),

  SET_ROLE_QUERY_SEARCH_VALUE: createPrefixedActionName(ROLES, 'SET_ROLE_QUERY_SEARCH_VALUE'),
  CLEAR_ROLE_QUERY_SEARCH_VALUE: createPrefixedActionName(ROLES, 'CLEAR_ROLE_QUERY_SEARCH_VALUE'),
};

export const getRolesStart = () => ({
  type: ACTIONS.GET_ROLES.REQUEST,
});

export const getRolesSuccess = data => ({
  type: ACTIONS.GET_ROLES.SUCCESS,
  payload: data,
});

export const getRolesError = err => ({
  type: ACTIONS.GET_ROLES.FAILURE,
  payload: err,
});

export const getRoleStart = () => ({
  type: ACTIONS.GET_ROLE.REQUEST,
});

export const getRoleSuccess = data => ({
  type: ACTIONS.GET_ROLE.SUCCESS,
  payload: data,
});

export const getRoleError = err => ({
  type: ACTIONS.GET_ROLE.FAILURE,
  payload: err,
});

export const postRoleStart = () => ({
  type: ACTIONS.POST_ROLE.REQUEST,
});

export const postRoleSuccess = data => ({
  type: ACTIONS.POST_ROLE.SUCCESS,
  payload: data,
});

export const postRoleError = err => ({
  type: ACTIONS.POST_ROLE.FAILURE,
  payload: err,
});

export const patchRoleStart = () => ({
  type: ACTIONS.PATCH_ROLE.REQUEST,
});

export const patchRoleSuccess = data => ({
  type: ACTIONS.PATCH_ROLE.SUCCESS,
  payload: data,
});

export const patchRoleError = err => ({
  type: ACTIONS.PATCH_ROLE.FAILURE,
  payload: err,
});

export const deleteRoleStart = () => ({
  type: ACTIONS.DELETE_ROLE.REQUEST,
});

export const deleteRoleSuccess = id => ({
  type: ACTIONS.DELETE_ROLE.SUCCESS,
  payload: id,
});

export const deleteRoleError = err => ({
  type: ACTIONS.DELETE_ROLE.FAILURE,
  payload: err,
});

export const setRoleQuerySearchValue = data => ({
  type: ACTIONS.SET_ROLE_QUERY_SEARCH_VALUE,
  payload: data,
});

export const clearRoleQuerySearchValue = () => ({
  type: ACTIONS.CLEAR_ROLE_QUERY_SEARCH_VALUE,
});
