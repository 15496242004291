import { useState, useRef, useMemo } from 'react';
import { fileChecksum } from '../../../services/upload/fileChecksum';

export const errorTypes = {
  invalid: 'invalid',
  failed: 'failed',
};

export const useFormBuilderUpload = ({ onUploadSuccess, onPending, onUploadError, url, multiple }) => {
  const [isPending, setPending] = useState(false);
  const [files, setFiles] = useState([]);
  const xhrRequests = useRef({});

  const saveXhrRequest = (id, xhrRequest) => {
    xhrRequests.current[id] = xhrRequest;
  };

  const cancelXhrRequest = id => {
    if (xhrRequests.current[id]) {
      xhrRequests.current[id].abort();
      delete xhrRequests.current[id];
    }
  };

  const postFile = async file => {
    const checksum = await fileChecksum(file, e => {
      setFiles(s =>
        s.map(f => {
          return f.id === file.id
            ? {
                ...f,
                progress: e.progress * 0.2,
              }
            : f;
        })
      );
    });

    const payload = {
      data: {
        type: 'upload',
        attributes: {
          filename: file?.name,
          byte_size: file.size,
          content_type: file.type,
          checksum,
        },
      },
    };

    let xhrRequest;
    try {
      xhrRequest = new XMLHttpRequest();
      saveXhrRequest(file.name, xhrRequest);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
        },
        body: JSON.stringify(payload),
      });

      if (!response.ok) {
        throw new Error('Something went wrong');
      }

      const data = await response.json();

      multiple
        ? setFiles(s => s.map(item => (item.name === file.name ? { ...item, id: data.data.id } : item)))
        : setFiles(s => [{ ...s[0], id: data.data.id }]);

      await putFile({
        url: data.data.attributes.url,
        id: data.data.id,
        body: file,
        headers: {
          ...data.data.attributes.headers,
        },
        onError: id => {
          setFiles(s =>
            s.map(file => {
              return file.id === id
                ? {
                    ...file,
                    error: errorTypes.failed,
                    progress: 100,
                  }
                : file;
            })
          );

          throw new Error('The upload failed');
        },
        onUploadProgress: progressEvent => {
          setFiles(s =>
            s.map(file => {
              return file.id === progressEvent.id
                ? {
                    ...file,
                    progress: (progressEvent.loaded / progressEvent.total) * 100 * 0.8 + 20,
                  }
                : file;
            })
          );
        },
      });
    } catch (err) {
      setFiles(s =>
        s.map(el =>
          el.name === file.name
            ? {
                ...el,
                error: errorTypes.invalid,
                progress: 100,
              }
            : el
        )
      );
      onUploadError?.(err);
      throw err;
    } finally {
      delete xhrRequests.current[file.name];
    }
  };

  const putFile = async ({ url, headers, body, id, onError, onUploadProgress }) => {
    return new Promise((resolve, reject) => {
      let xhrRequest = new XMLHttpRequest();

      xhrRequest.open('PUT', url);

      if (headers) {
        Object.entries(headers).forEach(([key, value]) => {
          xhrRequest.setRequestHeader(key, value);
        });
      }

      xhrRequest.upload.addEventListener('progress', ({ loaded, total }) => {
        onUploadProgress({ id, loaded, total });
      });

      xhrRequest.onload = () => {
        if (xhrRequest.status === 200) {
          resolve(xhrRequest.response);
        } else {
          reject(xhrRequest.statusText);
        }
      };

      xhrRequest.onerror = () => {
        onError(id);
        reject('Network error occurred while uploading file');
      };

      xhrRequest.send(body);
      saveXhrRequest(id, xhrRequest);
    });
  };

  const onUpload = async fileList => {
    if (fileList?.length == 0) return;

    setPending(fileList.length > 0);
    onPending?.(fileList.length > 0);

    const filteredFiles = [...fileList].filter(file => !files.find(_file => _file.name === file.name));
    const promises = [...filteredFiles].map(file => postFile(file));

    multiple
      ? setFiles(s =>
          removeDuplicates([
            ...s,
            ...[...filteredFiles].map(file => ({
              name: file.name,
              progress: 0,
            })),
          ])
        )
      : setFiles([{ name: filteredFiles?.[0]?.name, progress: 0 }]);

    await Promise.all(promises)
      .then(results => {
        onUploadSuccess(results);
      })
      .catch(err => {
        onUploadError?.(err);
      })
      .finally(() => {
        setPending(false);
        onPending?.(false);
      });
  };

  const onRemoveFile = id => {
    cancelXhrRequest(id);
    setFiles(s => s.filter(file => file.id !== id));
  };

  const removeDuplicates = data => {
    return data.filter((obj, index, self) => index === self.findIndex(t => t.name === obj.name));
  };

  const isAnyComponentsPending = useMemo(() => {
    return files.filter(file => file.progress !== 100).length !== 0;
  }, [files]);

  return {
    onUploadFiles: onUpload,
    isPending,
    isAnyComponentsPending,
    files,
    onRemoveFile,
  };
};
