import { createPrefixedActionName, createRoutine } from '../../services/functions/redux-routines/redux-routines';

export const ACTIONS = {
  OPEN_REQUEST_RIGHTS: createPrefixedActionName('TINT', 'OPEN_REQUEST_RIGHTS'),
  SET_REQUEST_RIGHT_STEP: createPrefixedActionName('TINT', 'SET_REQUEST_RIGHT_STEP'),
  CLOSE_REQUEST_RIGHTS: createPrefixedActionName('TINT', 'CLOSE_REQUEST_RIGHTS'),
  SET_MODAL_PROGRESS: createPrefixedActionName('TINT', 'SET_MODAL_PROGRESS'),
  POST_REQUEST_RIGHTS: createRoutine(createPrefixedActionName('TINT', 'POST_REQUEST_RIGHTS')),

  GET_REQUEST_RIGHTS_TERMS_AND_CONDITIONS: createRoutine(
    createPrefixedActionName('TINT', 'GET_REQUEST_RIGHTS_TERMS_AND_CONDITIONS')
  ),
  UPDATE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS: createRoutine(
    createPrefixedActionName('TINT', 'UPDATE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS')
  ),
  DELETE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS: createRoutine(
    createPrefixedActionName('TINT', 'DELETE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS')
  ),
  POST_REQUEST_RIGHTS_TERMS_AND_CONDITIONS: createRoutine(
    createPrefixedActionName('TINT', 'POST_REQUEST_RIGHTS_TERMS_AND_CONDITIONS')
  ),

  GET_REQUEST_RIGHTS_TEMPLATES: createRoutine(createPrefixedActionName('TINT', 'GET_REQUEST_RIGHTS_TEMPLATES')),
  DELETE_REQUEST_RIGHTS_TEMPLATE: createRoutine(createPrefixedActionName('TINT', 'DELETE_REQUEST_RIGHTS_TEMPLATE')),
  GET_REQUEST_RIGHTS_TERMS: createRoutine(createPrefixedActionName('TINT', 'GET_REQUEST_RIGHTS_TERMS')),
  DELETE_REQUEST_RIGHTS_TERMS: createRoutine(createPrefixedActionName('TINT', 'DELETE_REQUEST_RIGHTS_TERMS')),
  PATCH_REQUEST_RIGHTS_TEMPLATES: createRoutine(createPrefixedActionName('TINT', 'PATCH_REQUEST_RIGHTS_TEMPLATES')),
  POST_REQUEST_RIGHTS_TEMPLATE: createRoutine(createPrefixedActionName('TINT', 'POST_REQUEST_RIGHTS_TEMPLATE')),
};

export const postRequestRightsTemplateStart = () => ({
  type: ACTIONS.POST_REQUEST_RIGHTS_TEMPLATE.REQUEST,
});

export const postRequestRightsTemplateSuccess = data => ({
  type: ACTIONS.POST_REQUEST_RIGHTS_TEMPLATE.SUCCESS,
  payload: data,
});

export const postRequestRightsTemplateError = err => ({
  type: ACTIONS.POST_REQUEST_RIGHTS_TEMPLATE.FAILURE,
  payload: err,
});

export const deleteRequestRightsTemplateStart = () => ({
  type: ACTIONS.DELETE_REQUEST_RIGHTS_TEMPLATE.REQUEST,
});

export const deleteRequestRightsTemplateSuccess = id => ({
  type: ACTIONS.DELETE_REQUEST_RIGHTS_TEMPLATE.SUCCESS,
  payload: id,
});

export const deleteRequestRightsTemplateError = err => ({
  type: ACTIONS.DELETE_REQUEST_RIGHTS_TEMPLATE.FAILURE,
  payload: err,
});

export const setRequestRightsModalProgress = payload => ({
  type: ACTIONS.SET_MODAL_PROGRESS,
  payload,
});

export const openRequestRights = payload => ({
  type: ACTIONS.OPEN_REQUEST_RIGHTS,
  payload,
});

export const closeRequestRights = () => ({
  type: ACTIONS.CLOSE_REQUEST_RIGHTS,
});

export const setRequestRightStep = payload => ({
  type: ACTIONS.SET_REQUEST_RIGHT_STEP,
  payload,
});

export const postRequestRightsStart = () => ({
  type: ACTIONS.POST_REQUEST_RIGHTS.REQUEST,
});

export const postRequestRightsSuccess = data => ({
  type: ACTIONS.POST_REQUEST_RIGHTS.SUCCESS,
  payload: data,
});

export const postRequestRightsError = err => ({
  type: ACTIONS.POST_REQUEST_RIGHTS.FAILURE,
  payload: err,
});

export const getRequestRightsTemplatesStart = () => ({
  type: ACTIONS.GET_REQUEST_RIGHTS_TEMPLATES.REQUEST,
});

export const getRequestRightsTemplatesSuccess = data => ({
  type: ACTIONS.GET_REQUEST_RIGHTS_TEMPLATES.SUCCESS,
  payload: data,
});

export const getRequestRightsTemplatesError = err => ({
  type: ACTIONS.GET_REQUEST_RIGHTS_TEMPLATES.FAILURE,
  payload: err,
});

export const getRequestRightsTermsAndConditionsStart = () => ({
  type: ACTIONS.GET_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.REQUEST,
});

export const getRequestRightsTermsAndConditionsSuccess = data => ({
  type: ACTIONS.GET_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.SUCCESS,
  payload: data,
});

export const getRequestRightsTermsAndConditionsError = err => ({
  type: ACTIONS.UPDATE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.FAILURE,
  payload: err,
});

export const updateRequestRightsTermsAndConditionsError = err => ({
  type: ACTIONS.GET_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.FAILURE,
  payload: err,
});

export const updateRequestRightsTermsAndConditionsStart = () => ({
  type: ACTIONS.UPDATE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.REQUEST,
});

export const updateRequestRightsTermsAndConditionsSuccess = data => ({
  type: ACTIONS.UPDATE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.SUCCESS,
  payload: data,
});

export const createRequestRightsTermsAndConditionsError = err => ({
  type: ACTIONS.POST_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.FAILURE,
  payload: err,
});

export const createRequestRightsTermsAndConditionsStart = () => ({
  type: ACTIONS.POST_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.REQUEST,
});

export const createRequestRightsTermsAndConditionsSuccess = data => ({
  type: ACTIONS.POST_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.SUCCESS,
  payload: data,
});

export const deleteRequestRightsTermsAndConditionsError = err => ({
  type: ACTIONS.DELETE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.FAILURE,
  payload: err,
});

export const deleteRequestRightsTermsAndConditionsStart = () => ({
  type: ACTIONS.DELETE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.REQUEST,
});

export const deleteRequestRightsTermsAndConditionsSuccess = id => ({
  type: ACTIONS.DELETE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.SUCCESS,
  payload: id,
});

export const getRequestRightsTermsStart = () => ({
  type: ACTIONS.GET_REQUEST_RIGHTS_TERMS.REQUEST,
});

export const getRequestRightsTermsSuccess = data => ({
  type: ACTIONS.GET_REQUEST_RIGHTS_TERMS.SUCCESS,
  payload: data,
});

export const getRequestRightsTermsError = err => ({
  type: ACTIONS.GET_REQUEST_RIGHTS_TERMS.FAILURE,
  payload: err,
});

export const patchRequestRightsTemplatesStart = () => ({
  type: ACTIONS.PATCH_REQUEST_RIGHTS_TEMPLATES.REQUEST,
});

export const patchRequestRightsTemplatesSuccess = data => ({
  type: ACTIONS.PATCH_REQUEST_RIGHTS_TEMPLATES.SUCCESS,
  payload: data,
});

export const patchRequestRightsTemplatesError = err => ({
  type: ACTIONS.PATCH_REQUEST_RIGHTS_TEMPLATES.FAILURE,
  payload: err,
});
