import { createPrefixedActionName } from '../../../services/functions/redux-routines/redux-routines';

export const ACTIONS = {
  SET_SOCIAL_ACCOUNT: createPrefixedActionName('WIZARDS', 'SET_SOCIAL_ACCOUNT'),
};

export const setSocialAccount = data => ({
  type: ACTIONS.SET_SOCIAL_ACCOUNT,
  payload: data,
});
