import { useState } from 'react';
import { ApiService } from '../../../services/api-service/api-service';
import { handleApiError } from '../../../services/functions/error-handler/error-handler';

export const useFormBuilderCountries = ({ item, formId, locale, onPending } = {}) => {
  const elements = item?.elements;
  const divs = elements?.filter(el => el?.nodeName === 'div');
  const addressElements = divs?.map(div => div?.elements);
  const inputs = addressElements?.map(arr => arr?.find(el => el?.nodeName === 'input' || el?.nodeName === 'select'));
  const countrySelect = inputs?.find(el => el?.id?.includes('country'));

  const [countryDetails, setCountryDetails] = useState({});
  const [countriesList, setCountriesList] = useState(countrySelect?.elements);

  const getCountryDetails = ({ countryId }) => {
    onPending(true, countrySelect.name);
    return ApiService.get(`countries/${countryId}?include=subdivisions${locale ? `&locale=${locale}` : ''}`, {
      forceNoTeamPrefix: true,
    })
      .then(res => {
        return res.data;
      })
      .catch(err => {
        throw new Error(`Cannot fetch country details ${err}`);
      })
      .finally(() => onPending(false, countrySelect.name));
  };

  const getCountriesLocales = ({ countriesIds }) => {
    onPending(true, countrySelect.name);
    return ApiService.get(`countries?filter[id]=${countriesIds}&locale=${locale}`, { forceNoTeamPrefix: true })
      .then(res => {
        return res.data.data;
      })
      .catch(err => {
        throw new Error(`Cannot fetch countries locales ${err}`);
      })
      .finally(() => onPending(false, countrySelect.name));
  };

  const getCountriesList = () => {
    return ApiService.get('countries', { forceNoTeamPrefix: true })
      .then(res => {
        return res.data.data;
      })
      .catch(err => {
        handleApiError(err);
      });
  };

  const updateCountryDetails = async (countryId = 'en_US') => {
    const details = await getCountryDetails({ countryId: countryId });
    const subdivisions =
      details?.included
        ?.filter(el => el?.type === 'subdivision')
        ?.map(subdivision => {
          return {
            value: subdivision?.id,
            name: subdivision?.attributes?.name,
          };
        }) || [];
    setCountryDetails({ ...details, subdivisionsList: [...subdivisions] });
  };

  const updateCountriesNames = async ({ countriesIds }) => {
    const localizedCountries = await getCountriesLocales({ countriesIds });
    setCountriesList(cl =>
      cl.map(country => {
        const translatedName = localizedCountries?.find(localizedCountry => localizedCountry?.id === country.value)
          ?.attributes?.name;
        return { ...country, name: translatedName };
      })
    );
  };

  const onCountryChange = ({ e, localizeCountryNames }) => {
    const formDiv = document.querySelector(`[data-form-id="${formId}"] div`);
    if (formDiv) {
      const selectedCountry = e?.target?.selectedOptions?.[0];
      const countryInputHTML = formDiv?.shadowRoot?.getElementById(countrySelect.id);

      if (localizeCountryNames) {
        const countriesIds = [...countrySelect.elements].map(country => country.value);
        updateCountriesNames({ countriesIds });
      }

      updateCountryDetails(selectedCountry ? selectedCountry.value : countryInputHTML.selectedOptions[0].value);
    }
  };

  return {
    localizedCountriesList: countriesList,
    countryDetails,
    onCountryChange,
    getCountryDetails,
    getCountriesList,
    updateCountryDetails,
  };
};
