import { createPrefixedActionName } from '../../../../services/functions/redux-routines/redux-routines';
import { PRODUCT_FEEDS } from '../../consts';

export const ACTIONS = {
  OPEN_MODAL: createPrefixedActionName(PRODUCT_FEEDS, 'OPEN_MODAL'),
  UPDATE_MODAL: createPrefixedActionName(PRODUCT_FEEDS, 'UPDATE_MODAL'),
  CLOSE_MODAL: createPrefixedActionName(PRODUCT_FEEDS, 'CLOSE_MODAL'),
};

export const openModal = () => ({
  type: ACTIONS.OPEN_MODAL,
});

export const updateModal = data => ({
  type: ACTIONS.UPDATE_MODAL,
  payload: data,
});

export const closeModal = () => ({
  type: ACTIONS.CLOSE_MODAL,
});
