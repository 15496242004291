import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { EditUploadDesign } from '../../../';
import { EditColorInput } from '../../../';

export class EditSocialStyles extends Component {
  render() {
    return (
      <EditModalBody title='Display icons for your social accounts. Insert URLs to add social icons to the layout.'>
        <EditColorInput
          color={this.props.data.styles.iconBackgroundColor}
          label='background icon color'
          onChange={value => {
            this.props.styleDataUpdate({ iconBackgroundColor: value });
          }}
        />
        <EditColorInput
          color={this.props.data.styles.iconColor}
          label='Icon color'
          onChange={value => {
            this.props.styleDataUpdate({ iconColor: value });
          }}
        />
        <EditUploadDesign
          dataUpdate={this.props.dataUpdate}
          styleDataUpdate={this.props.styleDataUpdate}
          data={this.props.data}
        />
      </EditModalBody>
    );
  }
}
