import React from 'react';
import './edit-spacer-content.sass';
import { EditModalBody } from '../../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { EditInput } from '../../../../';
import PropTypes from 'prop-types';

const EditCustomSpacerSection = ({ sectionHeight, dataUpdate }) => {
  return (
    <EditModalBody>
      <EditInput
        type='number'
        onChange={e => {
          dataUpdate({ sectionHeight: e.target.value });
        }}
        label={'Section height'}
        placeholder='58'
        value={sectionHeight}
      />
    </EditModalBody>
  );
};

export default EditCustomSpacerSection;

EditCustomSpacerSection.propTypes = {
  dataUpdate: PropTypes.func,
  sectionHeight: PropTypes.string,
};
