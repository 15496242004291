import EditColorInput from '../edit-color-input/edit-color-input';
import React from 'react';
import { EditInput } from '../';

export const renderEditColorInput = (color, onChange, label, key, styles = {}, onMenuOpen, onMenuClose) => {
  return (
    <EditColorInput
      color={color}
      label={label}
      onChange={onChange}
      key={key}
      styles={styles}
      onMenuOpen={onMenuOpen}
      onMenuClose={onMenuClose}
    />
  );
};

export const renderEditInput = (defaultValue, onChange, label, key, placeholder) => {
  return (
    <EditInput defaultValue={defaultValue} label={label} onChange={onChange} key={key} placeholder={placeholder} />
  );
};
