import React from 'react';
import './form-builder.form.interface.input.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../utils/form-builder.types';
import { FormBuilderFormHint } from './common/hint/form-builder-form.hint';

export const FormBuilderFormInterfaceInput = ({ styles, item, styleElementInput = {}, values }) => {
  if (!item) return null;

  const { t } = useTranslation();

  const elements = item.elements;
  const input = elements.find(e => e.nodeName === 'input') || {};
  const label = elements.find(e => e.nodeName === 'label') || {};
  const hint = elements.find(e => e.id?.includes('hint')) || {};

  const onFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
  };

  return (
    <div
      className={item.class}
      style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS], ...styles[`div:has(input[id=${input.id}])`] }}>
      <label style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.LABEL]} htmlFor={label.for}>
        {t(label.nodeValue)}
      </label>
      <input
        style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT], ...styles[`#${input.id}`], ...styleElementInput }}
        onFocus={onFocus}
        id={input.id}
        name={input.name}
        type={input.type}
        defaultValue={values?.getAll(input.name)}
        autoComplete={input.autocomplete}
        pattern={input.pattern}
        placeholder={t(input.placeholder)}
        required={input.required}
        aria-describedby={input['aria-describedby']}
      />
      {hint && (
        <FormBuilderFormHint
          item={t(hint?.nodeValue)}
          id={hint?.id}
          style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.HINT]}
        />
      )}
    </div>
  );
};
