import { ACTIONS } from '../../actions/product-feeds/product-feeds.actions';

export const initialState = {
  data: [],
  links: undefined,
  isFetching: false,
  error: undefined,
};

export const productFeeds = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_PRODUCT_FEEDS.REQUEST:
    case ACTIONS.GET_PRODUCT_FEED.REQUEST:
    case ACTIONS.DELETE_PRODUCT_FEED.REQUEST:
    case ACTIONS.POST_PRODUCT_FEED.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case ACTIONS.GET_PRODUCT_FEEDS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case ACTIONS.GET_PRODUCT_FEED.SUCCESS:
      return {
        ...state,
        data: state.data.length
          ? state.data.map(e =>
              e.id === action.payload.id
                ? {
                    ...action.payload,
                  }
                : e
            )
          : [{ ...action.payload }],
        isFetching: false,
        error: undefined,
      };
    case ACTIONS.DELETE_PRODUCT_FEED.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        data: state.data.filter(e => e.id !== action.payload),
      };
    case ACTIONS.POST_PRODUCT_FEED.SUCCESS:
      return {
        ...state,
        data: [action.payload, ...state.data],
        isFetching: false,
        error: undefined,
      };
    case ACTIONS.GET_PRODUCT_FEEDS.FAILURE:
    case ACTIONS.GET_PRODUCT_FEED.FAILURE:
    case ACTIONS.DELETE_PRODUCT_FEED.FAILURE:
    case ACTIONS.POST_PRODUCT_FEED.FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const filterProductFeeds = products =>
  products?.included?.length > 0 ? products.included.filter(e => e.type === 'product_feed') : [];
