import React, { useRef, useState, useEffect } from 'react';
import './accordion-tab.sass';

const AccordionTabAnimationState = {
  COLLAPSE_END: 'tint-accordion-tab--collapsing-end',
  COLLAPSE_PENDING: 'tint-accordion-tab--collapsing',
  EXPAND_END: 'tint-accordion-tab--expanding-end',
  EXPAND_PENDING: 'tint-accordion-tab--expanding',
};
const AccordionTab = ({
  children,
  animationTimeDuration,
  isActive,
  title,
  selectedItemsCount,
  onTabClick,
  onClick,
  index,
  icon,
  maxHeight,
}) => {
  const collapseActiveClass = isActive ? 'tint-accordion-tab--expanded' : 'tint-accordion-tab--collapsed';
  const elementRef = useRef();

  const [animationState, setAnimationState] = useState();
  const [timeoutFunc, setTimeoutFunc] = useState();
  const [clickTime, setClickTime] = useState(animationTimeDuration * 1000);
  const [isAnimationFinished, setAnimationFinished] = useState(true);
  const [currentHeight, setCurrentHeight] = useState(true);

  const onAccordionTabClick = () => {
    onTabClick(index);
    onClick && onClick(isActive);
  };

  useEffect(() => {
    manageAnimation();
  }, [isActive]);

  const manageAnimation = () => {
    setAnimationState(
      isActive ? AccordionTabAnimationState.EXPAND_PENDING : AccordionTabAnimationState.COLLAPSE_PENDING
    );
    setAnimationFinished(false);

    if (isAnimationFinished) {
      startAnimation(animationTimeDuration * 1000);
      setClickTime(new Date().getTime());
    } else {
      const differentTime = new Date().getTime() - clickTime;
      clearTimeout(timeoutFunc);
      startAnimation(differentTime);
    }
  };

  const onAnimationFinish = () => {
    setAnimationFinished(true);
    setAnimationState(isActive ? AccordionTabAnimationState.EXPAND_END : AccordionTabAnimationState.COLLAPSE_END);
  };

  const startAnimation = delay => {
    setTimeoutFunc(setTimeout(onAnimationFinish, delay));
  };

  useEffect(() => {
    if (
      elementRef.current &&
      currentHeight !== elementRef.current.clientHeight &&
      elementRef.current.clientHeight !== 0 &&
      typeof maxHeight === 'undefined'
    ) {
      setCurrentHeight(elementRef.current.clientHeight);
    }
  });

  return (
    <div className={`tint-accordion-tab ${collapseActiveClass} ${animationState}`} data-testid='tint-accordion-tab'>
      <div
        className='tint-accordion-tab__header'
        onClick={e => {
          e.stopPropagation();
          onAccordionTabClick(index);
        }}>
        <span className='tint-accordion-tab__title'>{title}</span>
        <span className='tint-accordion-tab__actions'>
          {selectedItemsCount && selectedItemsCount !== 0 ? (
            <span className='tint-accordion-tab__counter'>{selectedItemsCount}</span>
          ) : null}
          {icon ? icon : null}
          {!icon ? (
            isActive ? (
              <span className='icon fas fa-chevron-up' />
            ) : (
              <span className='icon fas fa-chevron-down' />
            )
          ) : null}
        </span>
      </div>
      <div
        className='tint-accordion-tab__content'
        style={{
          minHeight: isActive ? currentHeight : 0,
          transition: `min-height ${animationTimeDuration}s, max-height ${
            !maxHeight ? animationTimeDuration : animationTimeDuration * 1.5
          }s`,
          maxHeight: isActive ? (maxHeight ? maxHeight : currentHeight) : 0,
        }}>
        {React.Children.map(children, child =>
          React.cloneElement(child, {
            ref: elementRef,
          })
        )}
      </div>
    </div>
  );
};

export default React.memo(AccordionTab);
