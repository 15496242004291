import React from 'react';
import PropTypes from 'prop-types';

const CustomSpacer = ({ sectionHeight }) => {
  return (
    <div className='tint-custom-spacer'>
      <div className='tint-custom-spacer__wrapper'>
        <span className='counter'>{`${sectionHeight}px`}</span>
      </div>
    </div>
  );
};

export default CustomSpacer;

CustomSpacer.propTypes = {
  sectionHeight: PropTypes.string,
};
