import { ACTIONS } from '../../actions/product-accounts/add-product-accounts-modal.actions';

export const MODAL_TYPES = {
  FACEBOOK: 'facebook',
  MAGENTO: 'magento',
  SHOPIFY: 'shopify',
  WOOCOMMERCE: 'woocommerce',
  BIGCOMMERCE: 'bigcommerce',
  FTP: 'ftp',
  SFTP: 'sftp',
  SALESFORCE_COMMERCE_CLOUD: 'salesforce_commerce_cloud',
  BAZAARVOICE: 'bazaarvoice',
};

export const MODAL_STEPS = {
  FIRST: 'first',
  SECOND: 'second',
};

export const MODAL_TYPE = {
  EDIT: 'edit',
  ADD: 'add',
};

export const initialState = {
  modalType: MODAL_TYPE.ADD,
  opened: false,
  data: {},
  step: undefined,
  type: '',
  title: 'Add Product Account',
  saveButtonTitle: '',
};

export const addProductAccountsModal = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_EDIT_MODAL:
      return {
        title: 'Edit Product Account',
        modalType: MODAL_TYPE.EDIT,
        data: action.payload.data,
        type: action.payload.type,
        opened: true,
        saveButtonTitle: 'Edit',
        step: MODAL_STEPS.SECOND,
      };

    case ACTIONS.OPEN_REAUTHENTICATE_MODAL:
      return {
        title: 'Reauthenticate Product Account',
        modalType: MODAL_TYPE.EDIT,
        data: action.payload.data,
        type: action.payload.type,
        opened: true,
        saveButtonTitle: 'Reauthenticate',
        step: MODAL_STEPS.SECOND,
      };

    case ACTIONS.OPEN_MODAL:
      return {
        ...state,
        opened: true,
        step: MODAL_STEPS.FIRST,
      };

    case ACTIONS.UPDATE_MODAL:
      return {
        ...state,
        ...action.payload,
      };

    case ACTIONS.CLOSE_MODAL:
      return initialState;
    default:
      return state;
  }
};

export const selectCurrentActiveProductAccountType = state => state.type;
export const selectCurrentActiveProductAccountStep = state => state.step;
