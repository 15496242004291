import React from 'react';
import './video-renderer.sass';
import { VideoRendererService } from './video-renderer.service';

export const VideoRenderer = ({ mediaUrl, width, autoPlay, parentLocation }) => {
  const determineVideoSource = () => {
    if (VideoRendererService.isTwitch(mediaUrl)) {
      return (
        <div style={{ width: `${width}%` }} className='video-wrapper'>
          <div className='fluid-width-video-wrapper'>
            <iframe
              src={VideoRendererService.getTwitchUrl(mediaUrl, autoPlay, parentLocation)}
              frameBorder='0'
              allowFullScreen
              scrolling='no'
            />
          </div>
        </div>
      );
    }

    if (VideoRendererService.isTikTok(mediaUrl)) {
      return (
        <div style={{ width: `${width}%` }} className='video-wrapper'>
          <div className='fluid-width-video-wrapper fluid-width-video-wrapper__tiktok'>
            <iframe
              src={VideoRendererService.getTikTokUrl(mediaUrl, autoPlay)}
              frameBorder='0'
              allowFullScreen
              scrolling='no'
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            />
          </div>
        </div>
      );
    }

    if (VideoRendererService.isYouTube(mediaUrl)) {
      return (
        <div style={{ width: `${width}%` }} className='video-wrapper'>
          <div className='fluid-width-video-wrapper'>
            <iframe
              src={VideoRendererService.getYouTubeUrl(mediaUrl, autoPlay)}
              allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
              allowFullScreen
            />
          </div>
        </div>
      );
    }

    if (VideoRendererService.isFacebook(mediaUrl)) {
      return (
        <div style={{ width: `${width}%` }} className='video-wrapper'>
          <div className='fluid-width-video-wrapper'>
            <iframe
              src={VideoRendererService.getFacebookUrl(mediaUrl, autoPlay)}
              style={{ border: 'none', overflow: 'hidden' }}
              scrolling='no'
              allowFullScreen
            />
          </div>
        </div>
      );
    }

    if (VideoRendererService.isVimeo(mediaUrl)) {
      return (
        <div style={{ width: `${width}%` }} className='video-wrapper'>
          <div className='fluid-width-video-wrapper'>
            <iframe title='vimeo-player' src={VideoRendererService.getVimeoUrl(mediaUrl, autoPlay)} allowFullScreen />
          </div>
        </div>
      );
    }
    return (
      <span
        className='tint-exb-video-container'
        style={{
          width: `${width}%`,
        }}
        key={mediaUrl}
        dangerouslySetInnerHTML={{
          __html: `
        <video
          controls
          class='tint-exb-video-container__video'
          muted
          ${autoPlay ? 'autoPlay' : ''}
          playsinline
        >
         <source src=${mediaUrl}#t=0.1 />
        </video>
      `,
        }}
      />
    );
  };

  return determineVideoSource();
};

export default VideoRenderer;
