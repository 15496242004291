import React, { Component } from 'react';
import { PageSectionHoc } from '../../../../../../components/HOC/page-section/page-section.hoc';
import { connect } from 'react-redux';
import ColorParser from '../../../../../../services/color-parser/color-parser';
import { EditModal } from '../../../../../../components/edit-modal/edit-modal/edit-modal';
import { EditModalStep } from '../../../../../../components/edit-modal/edit-modal-step/edit-modal-step';
import { EditModalSwitcher } from '../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher';
import './multi-column-section.sass';
import MultiColumnEditStyles from './edit/multi-column-edit-style';
import { MultiColumnEditContent } from './edit/multi-column-edit-content';
import BlankColumnBlock from './components/blank-column-block/blank-column-block';
import { ExperienceConfig } from '../../../../utils/experience.config';

class MultiColumnSection extends Component {
  constructor(...args) {
    super(...args);
    this.state = {
      chunkSize: this.props.data.content.amountOfColumns,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.content.amountOfColumns !== this.props.data.content.amountOfColumns) {
      this.setState({ chunkSize: this.props.data.content.amountOfColumns });
    }
    if (prevProps.pageWidth !== this.props.pageWidth) {
      this.updateColumns(this.props.pageWidth);
    }
  }

  updateColumns = changedWidth => {
    const chunkSize = this.adjustChunkSizeToContainerSize(changedWidth, this.props.data.content.amountOfColumns);
    this.setState({ chunkSize: chunkSize });
  };

  adjustChunkSizeToContainerSize(currentSize, userChunkSize) {
    if (currentSize < ExperienceConfig.pageSize.tablet) {
      return 1;
    }
    return userChunkSize;
  }

  renderColumnsPagination(elements, amountOfColumn) {
    let chunkElements = [
      elements.filter((e, i) => (e === null && i === 0) || (e && e.column === 0)),
      elements.filter((e, i) => (e === null && i === 1) || (e && e.column === 1)),
      elements.filter((e, i) => (e === null && i === 2) || (e && e.column === 2)),
    ];

    if (Number(amountOfColumn) === 2) {
      chunkElements.pop();
    }

    if (Number(amountOfColumn) === 1) {
      chunkElements.pop();
      chunkElements.pop();
    }
    return chunkElements.map((chunkElement, i) => (
      <div key={i} className='tint-page-section__multi-column-single-column-space-wrapper'>
        <div
          key={i}
          className={`tint-page-section__multi-column-single-column tint-page-section__multi-column--${i}`}
          data-testid='multiColumnColumnId'
          style={{
            justifyContent: this.props.data.styles.justifyContent ? this.props.data.styles.justifyContent : 'center',
            background: ColorParser.defaultColor(this.determinateBackgroundColor(i)),
          }}>
          {chunkElement.map((element, elementIndex) => (
            <BlankColumnBlock
              key={elementIndex}
              index={elements.indexOf(element)}
              column={i}
              element={element}
              isPreview={this.props.isPreviewMode}
              experienceType={this.props.experienceType}
              parentSectionIndex={this.props.index}
              translations={this.props.translations}
              addSection={this.addSection}
              paddingBottom={chunkElement.length - 1 === elementIndex ? this.props.data.styles.columnPadding : 0}
              paddingTop={elementIndex === 0 ? this.props.data.styles.columnPadding : 0}
            />
          ))}
        </div>
      </div>
    ));
  }

  determinateBackgroundColor = column => {
    switch (Number(column)) {
      case 0:
        return ColorParser.defaultColor(this.props.data.styles.firstColumnBackgroundColor);
      case 1:
        return ColorParser.defaultColor(this.props.data.styles.secondColumnBackgroundColor);
      case 2:
        return ColorParser.defaultColor(this.props.data.styles.thirdColumnBackgroundColor);
      default:
        return '';
    }
  };

  addSection = (updatedSection, index, column) => {
    updatedSection.data.styles.backgroundColor = this.determinateBackgroundColor(column);
    const updatedSectionList =
      this.props.data.content.sections[index].data === null
        ? this.props.data.content.sections.map((section, i) => {
            return index !== i ? section : { ...updatedSection, ...{ column } };
          })
        : [
            ...this.props.data.content.sections.slice(0, index + 1),
            { ...updatedSection, ...{ column } },
            ...this.props.data.content.sections.slice(index + 1, this.props.data.content.sections.length + 1),
          ];

    this.props.addMultiColumnSection(updatedSection, this.props.index, updatedSectionList);
  };

  updateBackgroundColor = (color, i) => {
    this.props.data.content.sections.map(section =>
      section.column !== i || section.data === null
        ? section
        : (section.data.styles.backgroundColor = ColorParser.defaultColor(color))
    );
  };

  render() {
    return (
      <div
        dir='auto'
        className={`tint-page-section tint-page-section--multi-column ${this.props.getSectionClass()}`}
        style={{
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`,
        }}>
        {this.props.renderGenericComponents()}
        <div
          className={`tint-page-section__multi-column-wrapper tint-page-section__multi-column-wrapper--${this.state.chunkSize}`}>
          {this.renderColumnsPagination(this.props.data.content.sections, this.props.data.content.amountOfColumns)}
        </div>
        {this.props.isModalOpen && (
          <EditModal
            nextStep={this.props.nextStep}
            stepIndex={this.props.stepIndex}
            isVisible={this.props.isModalOpen}
            saveData={this.props.onDataSave}
            dismissModal={this.props.dismissModal}
            closeModal={this.props.closeModal}>
            <EditModalStep title='Edit Multi Column'>
              <EditModalSwitcher>
                <MultiColumnEditContent
                  data={this.props.data}
                  selectStep={this.props.selectStep}
                  dataUpdate={this.props.onContentUpdate}
                />
                <MultiColumnEditStyles
                  data={this.props.data}
                  dataUpdate={this.props.onContentUpdate}
                  updateBackgroundColor={this.updateBackgroundColor}
                  styleDataUpdate={this.props.onStylesUpdate}
                />
              </EditModalSwitcher>
            </EditModalStep>
          </EditModal>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  experienceId: state.experience.data && state.experience.data.id,
});

export default connect(mapStateToProps)(PageSectionHoc(MultiColumnSection));
