import React from 'react';
import PropTypes from 'prop-types';
import './search-input.sass';

export const SearchInputType = {
  SHORT: 'short',
  DEFAULT: 'default',
  ROUND: 'round',
  SQUARE: 'square',
};
export const SearchInput = ({
  onInputChange,
  inputValue = '',
  placeholder = 'Search...',
  styles = {},
  types = [SearchInputType.DEFAULT, SearchInputType.ROUND],
}) => {
  const isInputValue = inputValue && inputValue.length > 0;
  const handleValueChange = e => {
    onInputChange(e);
  };

  const getTypesClassNames = () => {
    return types
      .map(type => {
        return `search-input__${type}`;
      })
      .join(' ');
  };

  return (
    <div className={`search-input ${getTypesClassNames()}`} style={{ ...styles }}>
      {!isInputValue && <span className='icon far fa-search' />}
      <input
        value={inputValue}
        placeholder={placeholder}
        onChange={e => handleValueChange(e.target.value)}
        style={{ ...(isInputValue && { padding: '0 42px 0 20px' }) }}
      />
      {isInputValue && <span className='icon icon-clear fal fa-times' onClick={() => handleValueChange('')} />}
    </div>
  );
};
export default SearchInput;

SearchInput.propTypes = {
  onInputChange: PropTypes.func,
  styles: PropTypes.object,
  placeholder: PropTypes.string,
  types: PropTypes.array,
};
