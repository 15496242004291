import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import './asset-marketing-integrations-sidebar.sass';
import PropTypes from 'prop-types';
import TintSidebar from '../../../../components/sidebar/sidebar';
import { Config } from '../../../../utils/config';
import { closeMarketingIntegrationsSidebar } from '../../actions/asset-marketing-integrations/asset-marketing-integrations.actions';
import {
  postMarketingIntegrationsAssetRequest,
  getMarketingIntegrationsAssetRequest,
} from '../../actions/asset-manager.fat-actions.js';
import { getSelectedAssets } from '../../reducers/asset-manager.reducer';
import { getSelectedCollections } from '../../reducers/asset-collections/asset-collections.reducer';
import { getMarketingIntegrationsFolders } from '../../../account-settings/actions/marketing-integrations/marketing-integrations-folders.fat-actions';
import { getMarketingIntegrations } from '../../../account-settings/actions/marketing-integrations/marketing-integrations.fat-actions';
import {
  mapMarketingIntegrationsAccountsForDropdown,
  mapMarketingIntegrationsFoldersForDropdown,
} from '../../../account-settings/reducers/marketing-integrations/marketing-integrations-folders.reducer';
import DropdownButton, { ButtonType } from '../../../../components/interface/dropdown/button/dropdown-button';
import { DropdownHeader } from '../../../../components/interface/dropdown/button/header/dropdown-header';
import DropdownSortList from '../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list';
import PreloaderComponent, {
  PreloaderPositionType,
  PreloaderBackdropType,
} from '../../../../components/interface/preloaders/preloader/preloader';
import { toast } from 'react-toastify';
import { LongPollingService } from '../../../../services/long-polling/long-polling.service';
import { clearUploadDialog, setUploadDialog } from '../../../../actions/upload-dialog/upload-dialog';
import { UPLOAD_DIALOG_TYPE } from '../../../../components/uploading-dialog/uploading-dialog';
import { handleApiError } from '../../../../services/functions/error-handler/error-handler';

const ADD_ACCOUNT_DROPDOWN_OPTION = {
  name: 'Add Account',
  value: 'addAccount',
};

export const AssetPostKey = {
  ASSET: 'asset_id',
  COLLECTION: 'collection_id',
};

export const PublishAssetsRequestStatus = {
  SUCCESS: 'success',
  PENDING: 'pending',
  FAILURE: 'failure',
};

export const AssetMarketingIntagrationsSidebar = ({ isOpen, postKey }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const assetManager = useSelector(state => state.assetManager);
  const assetCollections = useSelector(state => state.assetCollections);
  const marketingIntegrations = useSelector(state => state.marketingIntegrations);
  const marketingIntegrationsFolders = useSelector(state => state.marketingIntegrationsFolders);
  const isFetchingFolders = marketingIntegrationsFolders.isFetching;

  const [selectedAccountId, setSelectedAccountId] = useState();
  const [selectedFolderId, setSelectedFolderId] = useState();

  const lightBoxAsset = assetManager.selectedLightBoxAsset ? [assetManager.selectedLightBoxAsset.id] : undefined;

  const selectedAssets = getSelectedAssets(assetManager);
  const variantAssets = selectedAssets ? selectedAssets.map(e => e.id) : undefined;
  const selectedVariantIds = lightBoxAsset || variantAssets;

  const selectedCollections = getSelectedCollections(assetCollections);
  const collectionIds = selectedCollections.map(e => e.id);

  const postValues = postKey === AssetPostKey.ASSET ? selectedVariantIds : collectionIds;

  const filterFolders = marketingIntegrationsFolders.data.filter(e => e.id === selectedAccountId);
  const folders = mapMarketingIntegrationsFoldersForDropdown(filterFolders);
  const isFoldersAvailable = folders && folders.length > 0;
  const isRightButtonDisabled = !selectedAccountId || (isFoldersAvailable && !selectedFolderId);
  const accounts = mapMarketingIntegrationsAccountsForDropdown(marketingIntegrations.data);

  useEffect(() => {
    dispatch(getMarketingIntegrations()).catch(err => handleApiError(err, toast.error));
  }, [dispatch]);

  useEffect(() => {
    if (selectedAccountId === ADD_ACCOUNT_DROPDOWN_OPTION.value) return;
    if (selectedAccountId && filterFolders.length === 0) {
      dispatch(getMarketingIntegrationsFolders(selectedAccountId)).catch(err => handleApiError(err, toast.error));
    }
  }, [dispatch, selectedAccountId]);

  const resetAccountsAndFolders = () => {
    setSelectedAccountId(undefined);
    setSelectedFolderId(undefined);
  };

  const closeSidebar = () => {
    resetAccountsAndFolders();
    dispatch(closeMarketingIntegrationsSidebar());
  };

  const onSelectAccount = selectedAccount => {
    if (selectedAccount.value === ADD_ACCOUNT_DROPDOWN_OPTION.value) {
      history.push(Config.routePaths.teamSettings.marketingIntegrations.path);
      closeSidebar();
    } else {
      setSelectedAccountId(selectedAccount.value);
      setSelectedFolderId(undefined);
    }
  };

  const onSelectFolder = selectedFolder => {
    setSelectedFolderId(selectedFolder.value);
  };

  const startPollingPublishAssets = () => {
    let validator = { isCancelled: false };
    const validate = result => {
      if (validator.isCancelled) {
        return true;
      }
      switch (result && result.payload.status) {
        case PublishAssetsRequestStatus.FAILURE:
        case PublishAssetsRequestStatus.SUCCESS:
          return true;
        default:
          return false;
      }
    };

    dispatch(
      postMarketingIntegrationsAssetRequest({
        id: selectedAccountId,
        postKey: postKey,
        postValues: postValues,
        folderId: selectedFolderId,
      })
    ).then(res => {
      closeSidebar();
      dispatch(
        setUploadDialog({
          type: UPLOAD_DIALOG_TYPE.UPLOAD,
          isPending: true,
          title: 'Publishing assets',
          onCloseClick: () => {
            validator.isCancelled = true;
          },
          refreshFn: () => dispatch(getMarketingIntegrationsAssetRequest(res.payload[0].id)),
        })
      );
      LongPollingService.Poll({
        request: () => dispatch(getMarketingIntegrationsAssetRequest(res.payload[0].id)),
        validate,
        interval: 2000,
      }).then(data => {
        if (data.payload) {
          switch (data.payload.status) {
            case PublishAssetsRequestStatus.FAILURE:
              toast.error('We could not publish assets, please contact support.');
              dispatch(clearUploadDialog());
              break;
            case PublishAssetsRequestStatus.SUCCESS:
              dispatch(
                setUploadDialog({
                  isFinished: true,
                  successMsg: 'Completed.',
                })
              );
              return true;
          }
        }
      });
    });
  };

  const getCurrentFolderItem = () => {
    return folders.find(folder => folder.value === selectedFolderId);
  };

  const getCurrentAccountItem = () => {
    return accounts.find(account => account.value === selectedAccountId);
  };

  const renderFolders = () => {
    return (
      <div className='tint-asset-marketing-integrations-sidebar__content'>
        {isFetchingFolders ? (
          <PreloaderComponent
            positionType={PreloaderPositionType.ABSOLUTE}
            backdropType={PreloaderBackdropType.SEMI_COVER}
          />
        ) : null}
        {isFoldersAvailable ? (
          <>
            <h5>STEP 2: Select Folder</h5>
            <DropdownButton
              dropdownHeader={props => <DropdownHeader {...props} />}
              dropdownList={props => <DropdownSortList {...props} />}
              list={folders}
              currentItem={getCurrentFolderItem()}
              placeholder='Select Folder...'
              iconRight='fa fa-caret-down'
              buttonType={ButtonType.BUTTON_BORDER_FULL_WIDTH}
              onChangeValue={onSelectFolder}
            />
          </>
        ) : null}
      </div>
    );
  };

  const renderAccounts = () => {
    return (
      <div className='tint-asset-marketing-integrations-sidebar__content'>
        <h5>STEP 1: Select Account</h5>
        <DropdownButton
          dropdownHeader={props => <DropdownHeader {...props} />}
          dropdownList={props => <DropdownSortList {...props} />}
          list={[...accounts, ADD_ACCOUNT_DROPDOWN_OPTION]}
          placeholder='Select Account...'
          currentItem={getCurrentAccountItem()}
          iconRight='fa fa-caret-down'
          buttonType={ButtonType.BUTTON_BORDER_FULL_WIDTH}
          onChangeValue={onSelectAccount}
        />
      </div>
    );
  };

  return (
    <>
      {selectedVariantIds && (
        <TintSidebar
          title='Publish Assets'
          onClose={closeSidebar}
          isOpen={isOpen}
          defaultFooterStyle={false}
          onRightButtonClick={startPollingPublishAssets}
          isRightButtonDisabled={isRightButtonDisabled}
          rightButtonText='Publish'>
          <div className='tint-asset-marketing-integrations-sidebar'>
            {renderAccounts()}
            {renderFolders()}
          </div>
        </TintSidebar>
      )}
    </>
  );
};

AssetMarketingIntagrationsSidebar.propTypes = {
  isOpen: PropTypes.bool,
  postKey: PropTypes.string,
};
