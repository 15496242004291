import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button } from '../../../../../../../components/interface/button/button';
import { FileStack } from '../../../../../../../services/filestack/file-stack';
import { EditInputDropdown } from '../../../';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import 'react-datepicker/dist/react-datepicker.css';
import { Timer } from '../../../../../../../components/timer/timer';
import './tag-o-war-content.sass';
import InputFile from '../../../../../../../components/interface/inputs/input-file/input-file';
import PreloaderComponent from '../../../../../../../components/interface/preloaders/preloader/preloader';

export const defaultTagOWarDropdownOption = 'Select HashTag';
const imageDimensions = {
  width: 1040,
  height: 640,
};

const defaultCropImageDimensions = {
  width: 260,
  height: 160,
};

export class TagOWarContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      leftSideImagePending: false,
      rightSideImagePending: false,
      leftConnections: [],
      rightConnections: [],
      leftSlugName: this.props.data.content.slugNameLeft,
      rightSlugName: this.props.data.content.slugNameRight,
    };
  }

  componentDidMount() {
    this.setState({
      leftConnections: this.findSocialFeedOption(this.props.data.content.slugIdLeft),
      rightConnections: this.findSocialFeedOption(this.props.data.content.slugIdRight),
    });
  }

  onButtonClick = (event, thumbnail) => {
    const fileStack = new FileStack();
    const client = fileStack.getClient();
    const files = event.target.files;
    const pickOptions = {
      maxSize: this.props.maxFileSize,
      accept: ['image/*'],
      imageDim: [imageDimensions.width, imageDimensions.height],
      transformations: {
        crop: {
          force: true,
          aspectRatio: defaultCropImageDimensions.width / defaultCropImageDimensions.height,
        },
        circle: false,
      },
      onFileUploadStarted: () => {
        this.setState({
          [`${thumbnail}Pending`]: true,
        });
      },
      onUploadDone: res => {
        this.setState({
          [`${thumbnail}Pending`]: false,
        });
        this.props.dataUpdate({ [thumbnail]: res.filesUploaded[0].url });
      },
    };
    const picker = client.picker(pickOptions);
    picker.crop(files);
  };

  renderUploadButton(thumbnail) {
    return (
      <>
        <InputFile label='Upload Image' accept='image/*' handleChange={e => this.onButtonClick(e, thumbnail)} />
        <span className='recommended-dimensions'>Recommended Size: 260x160</span>
      </>
    );
  }

  onLeftTintSlugChange(id) {
    this.onLeftConnectionSelected(undefined);

    if (!this.isDefaultOptionSelected(id)) {
      this.setState({ leftConnections: this.findSocialFeedOption(id) });
    } else {
      this.setState({
        leftConnections: [],
      });
    }
  }

  onLeftConnectionSelected = (slugName, socialFeedValue) => {
    this.props.onLeftSideSelect(slugName, socialFeedValue);
  };

  onRightTintSlugChange(id) {
    this.onRightConnectionSelected(undefined);
    if (!this.isDefaultOptionSelected(id)) {
      this.setState({ rightConnections: this.findSocialFeedOption(id) });
    } else {
      this.setState({
        rightConnections: [],
      });
    }
  }

  onRightConnectionSelected = (slugName, socialFeedValue) => {
    this.props.onRightSideSelect(slugName, socialFeedValue);
  };

  isDefaultOptionSelected(id) {
    return isNaN(id);
  }

  findSocialFeedOption(id) {
    return this.props.socialFeedsDropdownOptions.filter(socialFeed => {
      return socialFeed.tint.id === id;
    });
  }

  getSlugName(id) {
    const slugName = this.props.tintDropdownOptions.find(slug => {
      return slug.value === id;
    });

    return slugName !== undefined ? slugName.name : undefined;
  }

  renderLeftSideUploadButton() {
    return (
      <>
        <p className='tint-edit-modal-body__header'>Left side</p>
        <EditInputDropdown
          defaultValue={this.props.data.content.slugIdLeft}
          placeholder={'Select TINT'}
          onChange={e => {
            this.onLeftTintSlugChange(e.target.value);
            this.props.dataUpdate({
              slugIdLeft: e.target.value,
              slugNameLeft: this.getSlugName(e.target.value),
            });
            this.setState({ leftSlugName: this.getSlugName(e.target.value) });
          }}
          options={this.props.tintDropdownOptions}
          label='Select TINT (left)'
        />

        <EditInputDropdown
          placeholder={defaultTagOWarDropdownOption}
          isControlled={true}
          value={this.props.data.content.socialFeedLeft.value}
          defaultValue={this.props.data.content.socialFeedLeft.value}
          onChange={e => {
            this.onLeftConnectionSelected(this.state.leftSlugName, e.target.value);
          }}
          options={this.state.leftConnections}
          label='Select social feed (left)'
        />

        <span className='tint-edit-modal-body__label'>THUMBNAIL (LEFT)</span>
        {this.props.data.content.leftSideImage ? (
          <>
            <img src={this.props.data.content.leftSideImage} />
            <Button
              onClick={() => {
                this.props.dataUpdate({ leftSideImage: '' });
                return this.props.saveData;
              }}
              type='upload'
              size='full-width'
              text='Remove Image'
            />
          </>
        ) : (
          <>
            {this.state.leftSideImagePending ? (
              <PreloaderComponent style={{ minHeight: '52px' }} />
            ) : (
              this.renderUploadButton('leftSideImage')
            )}
          </>
        )}
      </>
    );
  }

  renderRightSideUploadButton() {
    return (
      <>
        <p className='tint-edit-modal-body__header'>Right side</p>

        <EditInputDropdown
          defaultValue={this.props.data.content.slugIdRight}
          placeholder={'Select TINT'}
          onChange={e => {
            this.onRightTintSlugChange(e.target.value);
            this.props.dataUpdate({
              slugIdRight: e.target.value,
              slugNameRight: this.getSlugName(e.target.value),
            });
            this.setState({ rightSlugName: this.getSlugName(e.target.value) });
          }}
          options={this.props.tintDropdownOptions}
          label='Select TINT (right)'
        />

        <EditInputDropdown
          placeholder={defaultTagOWarDropdownOption}
          isControlled={true}
          value={this.props.data.content.socialFeedRight.value}
          defaultValue={this.props.data.content.socialFeedRight.value}
          onChange={e => {
            this.onRightConnectionSelected(this.state.rightSlugName, e.target.value);
          }}
          options={this.state.rightConnections}
          label='Select social feed (right)'
        />

        <span className='tint-edit-modal-body__label'>THUMBNAIL (RIGHT)</span>

        {this.props.data.content.rightSideImage ? (
          <>
            <img src={this.props.data.content.rightSideImage} />
            <Button
              onClick={() => {
                this.props.dataUpdate({ rightSideImage: '' });
                return this.props.saveData;
              }}
              type='upload'
              size='full-width'
              text='Remove Image'
            />
          </>
        ) : (
          <>
            {this.state.rightSideImagePending ? (
              <PreloaderComponent style={{ minHeight: '52px' }} />
            ) : (
              this.renderUploadButton('rightSideImage')
            )}
          </>
        )}
      </>
    );
  }

  onChangeStartDate = date => {
    this.props.onSetStartDate(new Date(date.currentDate));
    return this.props.dataUpdate({ startDate: new Date(date.currentDate) });
  };

  onChangeEndDate = date => {
    this.props.onSetEndDate(new Date(date.currentDate));
    return this.props.dataUpdate({ endDate: new Date(date.currentDate) });
  };

  render() {
    const competitionFinishedClass = this.props.isCompetitionEnd ? 'competition-finished' : '';

    return (
      <EditModalBody
        title='Create a Tag-o-War by selecting two different social feeds to display
          against each other.'>
        <div className='tint-tag-o-war-content'>
          <div className={competitionFinishedClass}>
            <div className='tint-edit-modal-body__group'>{this.renderLeftSideUploadButton()}</div>

            <div className='tint-edit-modal-body__group'>{this.renderRightSideUploadButton()}</div>

            <div className='tint-edit-modal-body__group tint-edit-modal-body__group--competition'>
              <span className='tint-edit-modal-body__header'>Start Tag-o-War</span>
              <Timer dataUpdate={this.onChangeStartDate} currentDate={this.props.data.content.startDate} />
              <span className='tint-edit-modal-body__header'>End Tag-o-War</span>
              <Timer dataUpdate={this.onChangeEndDate} currentDate={this.props.data.content.endDate} />
              <span className='tint-edit-modal-body__header'>End Tag-o-War</span>

              {!this.props.isCompetitionEnd ? (
                <Button
                  onClick={this.props.onEndCompetitionClick}
                  type='upload'
                  size='full-width'
                  text='End Competition'
                />
              ) : (
                <Button
                  className='competition-finished'
                  isDisabled={true}
                  type='disabled'
                  size='full-width'
                  text='Competition Finished'
                />
              )}
              <p className='tint-edit-modal-body__title'>
                Please Note - you cannot undo this action. When you end a Tag-o-War the total score is finalized.
              </p>
            </div>
          </div>
        </div>
      </EditModalBody>
    );
  }
}

TagOWarContent.propTypes = {
  slugIdLeft: PropTypes.string,
  slugIdRight: PropTypes.string,
  onRightSideSelect: PropTypes.func,
  onLeftSideSelect: PropTypes.func,
  onEndCompetitionClick: PropTypes.func,
  onSetStartDate: PropTypes.func,
  onSetEndDate: PropTypes.func,
  dataUpdate: PropTypes.func,
  startDate: PropTypes.any,
  endDate: PropTypes.any,
  saveData: PropTypes.func,
  tintDropdownOptions: PropTypes.array,
  socialFeedsDropdownOptions: PropTypes.array,
  isCompetitionEnd: PropTypes.bool,
  data: PropTypes.shape({
    content: PropTypes.shape({
      socialFeedLeft: PropTypes.object,
      leftSideImage: PropTypes.string,
      socialFeedRight: PropTypes.object,
      rightSideImage: PropTypes.string,
    }),
  }),
};
