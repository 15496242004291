import { ACTIONS } from '../../actions/product-accounts/product-accounts.actions';
import { ProductAccountsTranslations } from '../../../../utils/translations/product-accounts';

import produce from 'immer';

export const initialState = {
  data: [],
  links: {},
  filteredProductAccounts: [],
  isFetching: false,
  isFilteringProductAccounts: false,
  error: undefined,
};

export const productAccounts = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_PRODUCT_ACCOUNTS.REQUEST:
    case ACTIONS.GET_PRODUCT_ACCOUNT.REQUEST:
    case ACTIONS.DELETE_PRODUCT_ACCOUNT.REQUEST:
    case ACTIONS.REFRESH_PRODUCT_ACCOUNT.REQUEST:
    case ACTIONS.PATCH_PRODUCT_ACCOUNT.REQUEST:
    case ACTIONS.POST_PRODUCT_ACCOUNT.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case ACTIONS.FILTER_PRODUCT_ACCOUNTS.REQUEST:
      return {
        ...state,
        error: undefined,
        isFilteringProductAccounts: true,
      };
    case ACTIONS.GET_PRODUCT_ACCOUNTS.SUCCESS:
      return {
        ...state,
        ...action.payload,
        isFetching: false,
        error: undefined,
      };
    case ACTIONS.GET_PRODUCT_ACCOUNT.SUCCESS:
      return {
        ...state,
        data: state.data.length
          ? state.data.map(e =>
              e.id === action.payload.data.id
                ? {
                    ...action.payload.data,
                    included: action.payload.included,
                  }
                : e
            )
          : [{ ...action.payload.data, included: action.payload.included }],
        isFetching: false,
        error: undefined,
      };
    case ACTIONS.DELETE_PRODUCT_ACCOUNT.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        data: state.data.filter(e => e.id !== action.payload),
      };
    case ACTIONS.POST_PRODUCT_ACCOUNT_BY_OAUTH.SUCCESS:
      return {
        ...state,
        data: [action.payload, ...state.data],
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.PATCH_PRODUCT_ACCOUNT.SUCCESS:
      return produce(state, draftState => {
        const index = draftState.data.findIndex(e => e.id === action.payload.id);
        draftState.data[index] = action.payload;
        draftState.isFetching = false;
        draftState.error = undefined;
      });

    case ACTIONS.REFRESH_PRODUCT_ACCOUNT.SUCCESS:
      return {
        ...state,
        data: state.data.map(e => (e.id === action.payload.id ? action.payload : e)),
        isFetching: false,
        error: undefined,
      };
    case ACTIONS.POST_PRODUCT_ACCOUNT.SUCCESS:
      return {
        ...state,
        data: [action.payload, ...state.data],
        isFetching: false,
        error: undefined,
      };
    case ACTIONS.FILTER_PRODUCT_ACCOUNTS.SUCCESS:
      return {
        ...state,
        filteredProductAccounts: action.payload,
        isFilteringProductAccounts: false,
        error: undefined,
      };
    case ACTIONS.GET_PRODUCT_ACCOUNTS.FAILURE:
    case ACTIONS.GET_PRODUCT_ACCOUNT.FAILURE:
    case ACTIONS.DELETE_PRODUCT_ACCOUNT.FAILURE:
    case ACTIONS.POST_PRODUCT_ACCOUNT_BY_OAUTH.FAILURE:
    case ACTIONS.REFRESH_PRODUCT_ACCOUNT.FAILURE:
    case ACTIONS.PATCH_PRODUCT_ACCOUNT.FAILURE:
    case ACTIONS.POST_PRODUCT_ACCOUNT.FAILURE:
    case ACTIONS.FILTER_PRODUCT_ACCOUNTS.FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        isFilteringProductAccounts: false,
      };
    default:
      return state;
  }
};

export const mapProductAccountsForDropdown = list =>
  list.map(e => ({
    name:
      e.attributes.name || e.attributes.username || e.attributes.site || ProductAccountsTranslations[e.attributes.type],
    value: e.id,
  }));

export const mapCatalogsForDropdown = (list, productAccount) => {
  const _productAccount = productAccount ? list.find(e => e.id === productAccount.value) : [];
  return _productAccount && _productAccount.included
    ? _productAccount.included.map(e => ({ name: e.attributes.name, value: e.id }))
    : [];
};
