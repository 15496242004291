import { Config } from '../../utils/config';
import store from '../../store';
import axios from 'axios';

const _bulkHeaders = {
  'Content-Type': 'application/vnd.api+json; ext=bulk',
  Accept: 'application/vnd.api+json; ext=bulk',
};

let _headers = { 'Content-Type': 'application/vnd.api+json' };
let teamId;

let _authInterceptor;
//TODO I've added /metrics as tmp fix for the issue on the dashboard, however we should consider getting rid of this array
//TODO cuz it causes a lot of issues with the team prefix and a lot of unexpected behavior
const excludeTeamArray = ['/me?', 'authentication_factors', '/teams', 'notifications', '/plans'];
const teamIdKey = 'TINT_TEAM_ID';
const axiosInstance = axios.create({
  baseURL: Config.getApiUrl() + Config.apiVersion,
  headers: _headers,
});

let isRefreshing = false;
let requestRefreshTokenFn = null;
let redirectToLoginFn = null;

const addErrorInterceptor = ({ errorCode, fn }) => {
  axiosInstance.interceptors.response.use(
    response => response,
    error => {
      if (errorCode === error.response.status) {
        return fn(error);
      }
      return Promise.reject(error);
    }
  );
};

const getTeamPrefix = ({ path = '', config }) => {
  if (config && config.forceNoTeamPrefix) return '';
  if (teamId) {
    return excludeTeamArray.some(e => path.includes(e)) ? '' : `teams/${teamId}`;
  }

  return '';
};

const setTeamId = (id, force = false) => {
  const localStorageTeamId = window.localStorage.getItem(teamIdKey);
  if (!localStorageTeamId) {
    teamId = id;
  } else if (localStorageTeamId && force) {
    teamId = id;
  } else {
    teamId = localStorageTeamId;
  }
  window.localStorage.setItem(teamIdKey, teamId);
};

const getTeamId = () => {
  const localStorageTeamId = window.localStorage.getItem(teamIdKey);
  if (localStorageTeamId) {
    teamId = localStorageTeamId;
  }
  return teamId;
};

const get = (path, config) => {
  return axiosInstance.get(getTeamPrefix({ path, config }) + path, config);
};

const post = (path, data, config) => {
  return axiosInstance.post(getTeamPrefix({ path, config }) + path, data, config);
};

const put = (path, data, config) => {
  return axiosInstance.put(getTeamPrefix({ path }) + path, data, config);
};

const patch = (path, data, config) => {
  let _path;
  if (path === '/me') {
    _path = '/me';
  } else {
    _path = getTeamPrefix({ path }) + path;
  }
  return axiosInstance.patch(_path, data, config);
};

const deleteRequest = (path, config, data = {}) => {
  return axiosInstance.delete(getTeamPrefix({ path, config }) + path, { ...config, data });
};

const setAuth = ({ token, requestRefreshToken, redirectToLogin }) => {
  const session = store.getState().session.data;
  const auth = token || (store.getState().session.data && session.access_token);
  if (requestRefreshToken) {
    requestRefreshTokenFn = requestRefreshToken;
  }
  if (redirectToLogin) {
    redirectToLoginFn = redirectToLogin;
  }

  removeAuth();
  _authInterceptor = axiosInstance.interceptors.request.use(request => {
    request.headers['Authorization'] = `Bearer ${auth}`;
    return request;
  });
};

const handle401Error = async error => {
  if (isRefreshing) {
    return axiosInstance(error.config);
  }

  isRefreshing = true;

  try {
    const data = await requestRefreshTokenFn();
    if (data !== 'error') {
      isRefreshing = false;
    }
    //return axiosInstance(error.config);
  } catch (refreshError) {
    redirectToLoginFn();
    return Promise.reject(refreshError);
  }
};

const removeAuth = () => {
  axiosInstance.interceptors.request.eject(_authInterceptor);
};

const getBulkHeaders = () => _bulkHeaders;

const pureInstance = () =>
  axios.create({
    baseURL: Config.getApiUrl(),
    headers: _headers,
  });

export const ApiService = {
  get,
  post,
  put,
  patch,
  delete: deleteRequest,
  getBulkHeaders,
  setAuth,
  removeAuth,
  pureInstance,
  setTeamId,
  getTeamId,
  clearLocalStorageTeamId: () => window.localStorage.removeItem(teamIdKey),
  getTeamPrefix,
  addErrorInterceptor,
  handle401Error,
};
