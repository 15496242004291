import { ACTIONS } from '../actions/content/insights-content.actions';
import { produce } from 'immer';
import { InsightsUtil } from '../utils/insights.util';
import { parseISO, format, differenceInDays, addDays } from 'date-fns';

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const initialState = {
  topAccounts: {
    data: undefined,
    isFetching: false,
  },
  topClickedPosts: {
    data: undefined,
    isFetching: false,
  },
  postsIngested: {
    data: undefined,
    isFetching: false,
  },
  postsIngestedPerDay: {
    data: undefined,
    isFetching: false,
  },
  topSources: {
    data: undefined,
    isFetching: false,
  },
  topFeeds: {
    data: undefined,
    isFetching: false,
  },
  totalPosts: {
    data: undefined,
    isFetching: false,
  },
  moderationActivity: {
    data: undefined,
    isFetching: false,
  },
  dailyModeration: {
    data: undefined,
    isFetching: false,
  },
  topModerators: {
    data: undefined,
    isFetching: false,
  },
  rightsRequested: {
    data: undefined,
    isFetching: false,
  },
  rightsApproved: {
    data: undefined,
    isFetching: false,
  },
  totalContentCollected: {
    data: undefined,
    isFetching: false,
  },
  topRightsRequesters: {
    data: undefined,
    isFetching: false,
  },
};

export const insightsContent = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.CLEAR_ALL_INSIGHTS:
      return initialState;

    case ACTIONS.GET_POSTS_INGESTED_PER_DAY.FAILURE:
      return produce(state, draftState => {
        draftState.postsIngestedPerDay.isFetching = false;
      });

    case ACTIONS.GET_POSTS_INGESTED_PER_DAY.REQUEST:
      return produce(state, draftState => {
        draftState.postsIngestedPerDay.isFetching = true;
      });

    case ACTIONS.GET_POSTS_INGESTED_PER_DAY.SUCCESS:
      return produce(state, draftState => {
        draftState.postsIngestedPerDay.isFetching = false;
        draftState.postsIngestedPerDay.data = action.payload;
      });

    case ACTIONS.GET_POSTS_INGESTED.FAILURE:
      return produce(state, draftState => {
        draftState.postsIngested.isFetching = false;
      });

    case ACTIONS.GET_POSTS_INGESTED.REQUEST:
      return produce(state, draftState => {
        draftState.postsIngested.isFetching = true;
      });

    case ACTIONS.GET_POSTS_INGESTED.SUCCESS:
      return produce(state, draftState => {
        draftState.postsIngested.isFetching = false;
        draftState.postsIngested.data = action.payload;
      });

    case ACTIONS.GET_TOP_ACCOUNTS.FAILURE:
      return produce(state, draftState => {
        draftState.topAccounts.isFetching = false;
      });

    case ACTIONS.GET_TOP_ACCOUNTS.REQUEST:
      return produce(state, draftState => {
        draftState.topAccounts.isFetching = true;
      });

    case ACTIONS.GET_TOP_ACCOUNTS.SUCCESS:
      return produce(state, draftState => {
        draftState.topAccounts.isFetching = false;
        draftState.topAccounts.data = action.payload;
      });

    case ACTIONS.GET_TOP_RIGHTS_REQUESTERS.FAILURE:
      return produce(state, draftState => {
        draftState.topRightsRequesters.isFetching = false;
      });

    case ACTIONS.GET_TOP_RIGHTS_REQUESTERS.REQUEST:
      return produce(state, draftState => {
        draftState.topRightsRequesters.isFetching = true;
      });

    case ACTIONS.GET_TOP_RIGHTS_REQUESTERS.SUCCESS:
      return produce(state, draftState => {
        draftState.topRightsRequesters.isFetching = false;
        draftState.topRightsRequesters.data = action.payload;
      });

    case ACTIONS.GET_TOTAL_CONTENT_COLLECTED.FAILURE:
      return produce(state, draftState => {
        draftState.totalContentCollected.isFetching = false;
      });

    case ACTIONS.GET_TOTAL_CONTENT_COLLECTED.REQUEST:
      return produce(state, draftState => {
        draftState.totalContentCollected.isFetching = true;
      });

    case ACTIONS.GET_TOTAL_CONTENT_COLLECTED.SUCCESS:
      return produce(state, draftState => {
        draftState.totalContentCollected.isFetching = false;
        draftState.totalContentCollected.data = action.payload;
      });

    case ACTIONS.GET_RIGHTS_APPROVED.FAILURE:
      return produce(state, draftState => {
        draftState.rightsApproved.isFetching = false;
      });

    case ACTIONS.GET_RIGHTS_APPROVED.REQUEST:
      return produce(state, draftState => {
        draftState.rightsApproved.isFetching = true;
      });

    case ACTIONS.GET_RIGHTS_APPROVED.SUCCESS:
      return produce(state, draftState => {
        draftState.rightsApproved.isFetching = false;
        draftState.rightsApproved.data = action.payload;
      });

    case ACTIONS.GET_TOP_CLICKED_POSTS.FAILURE:
      return produce(state, draftState => {
        draftState.topClickedPosts.isFetching = false;
      });

    case ACTIONS.GET_TOP_CLICKED_POSTS.REQUEST:
      return produce(state, draftState => {
        draftState.topClickedPosts.isFetching = true;
      });

    case ACTIONS.GET_TOP_CLICKED_POSTS.SUCCESS:
      return produce(state, draftState => {
        draftState.topClickedPosts.isFetching = false;
        draftState.topClickedPosts.data = action.payload;
      });

    case ACTIONS.GET_RIGHTS_REQUESTED.FAILURE:
      return produce(state, draftState => {
        draftState.rightsRequested.isFetching = false;
      });

    case ACTIONS.GET_RIGHTS_REQUESTED.REQUEST:
      return produce(state, draftState => {
        draftState.rightsRequested.isFetching = true;
      });

    case ACTIONS.GET_RIGHTS_REQUESTED.SUCCESS:
      return produce(state, draftState => {
        draftState.rightsRequested.isFetching = false;
        draftState.rightsRequested.data = action.payload;
      });

    case ACTIONS.GET_TOP_MODERATORS.FAILURE:
      return produce(state, draftState => {
        draftState.topModerators.isFetching = false;
      });

    case ACTIONS.GET_TOP_MODERATORS.REQUEST:
      return produce(state, draftState => {
        draftState.topModerators.isFetching = true;
      });

    case ACTIONS.GET_TOP_MODERATORS.SUCCESS:
      return produce(state, draftState => {
        draftState.topModerators.isFetching = false;
        draftState.topModerators.data = action.payload;
      });

    case ACTIONS.GET_DAILY_MODERATION.FAILURE:
      return produce(state, draftState => {
        draftState.dailyModeration.isFetching = false;
      });

    case ACTIONS.GET_DAILY_MODERATION.REQUEST:
      return produce(state, draftState => {
        draftState.dailyModeration.isFetching = true;
      });

    case ACTIONS.GET_DAILY_MODERATION.SUCCESS:
      return produce(state, draftState => {
        draftState.dailyModeration.isFetching = false;
        draftState.dailyModeration.data = action.payload;
      });

    case ACTIONS.GET_MODERATION_ACTIVITY.FAILURE:
      return produce(state, draftState => {
        draftState.moderationActivity.isFetching = false;
      });

    case ACTIONS.GET_MODERATION_ACTIVITY.REQUEST:
      return produce(state, draftState => {
        draftState.moderationActivity.isFetching = true;
      });

    case ACTIONS.GET_MODERATION_ACTIVITY.SUCCESS:
      return produce(state, draftState => {
        draftState.moderationActivity.isFetching = false;
        draftState.moderationActivity.data = action.payload;
      });

    case ACTIONS.GET_TOTAL_POSTS_INGESTED.FAILURE:
      return produce(state, draftState => {
        draftState.totalPosts.isFetching = false;
      });

    case ACTIONS.GET_TOTAL_POSTS_INGESTED.REQUEST:
      return produce(state, draftState => {
        draftState.totalPosts.isFetching = true;
      });

    case ACTIONS.GET_TOTAL_POSTS_INGESTED.SUCCESS:
      return produce(state, draftState => {
        draftState.totalPosts.data = action.payload;
        draftState.totalPosts.isFetching = false;
      });

    case ACTIONS.GET_TOP_SOCIAL_FEEDS.FAILURE:
      return produce(state, draftState => {
        draftState.topFeeds.isFetching = false;
      });

    case ACTIONS.GET_TOP_SOCIAL_FEEDS.REQUEST:
      return produce(state, draftState => {
        draftState.topFeeds.isFetching = true;
      });

    case ACTIONS.GET_TOP_SOCIAL_FEEDS.SUCCESS:
      return produce(state, draftState => {
        draftState.topFeeds.data = action.payload;
        draftState.topFeeds.isFetching = false;
      });

    case ACTIONS.GET_TOP_SOURCES.FAILURE:
      return produce(state, draftState => {
        draftState.topSources.isFetching = false;
      });

    case ACTIONS.GET_TOP_SOURCES.REQUEST:
      return produce(state, draftState => {
        draftState.topSources.isFetching = true;
      });

    case ACTIONS.GET_TOP_SOURCES.SUCCESS:
      return produce(state, draftState => {
        draftState.topSources.data = action.payload;
        draftState.topSources.isFetching = false;
      });

    default:
      return state;
  }
};

export const getTopSocialFeedsData = state => {
  if (!state || !state.topFeeds.data || state.topFeeds.data.data.length < 1) {
    return [];
  }

  const { data, included } = state.topFeeds.data;

  let arr = data
    .map(e => ({
      id: e.id,
      postAmount: Number(e.attributes.count),
      socialFeedId: e.attributes.dimensions.social_feed_id,
    }))
    .sort((a, b) => b.postAmount - a.postAmount);
  const maxAmount = arr[0].postAmount;

  arr = arr.map(e => {
    let title;
    const socialFeed = included?.find(i => Number(i.id) === Number(e.socialFeedId));
    const source = InsightsUtil.socialFeedSource(socialFeed);

    title = InsightsUtil.socialFeedToReadableString(socialFeed);

    return {
      ...e,
      width: Math.round((100 * e.postAmount) / maxAmount),
      title,
      status: socialFeed?.attributes?.status || 'deleted',
      source: socialFeed ? source?.toUpperCase?.() : undefined,
    };
  });
  return arr;
};

export const getPostsIngestedData = state => {
  if (!state || !state.postsIngested.data) {
    return [];
  }

  return state.postsIngested.data.data.map(e => ({
    day: e.attributes.dimensions.day_of_week,
    hour: e.attributes.dimensions.hour_of_day,
    count: e.attributes.count,
  }));
};

export const getPostsIngestedPerDayData = state => {
  if (!state || !state.postsIngestedPerDay.data) {
    return [];
  }

  return state.postsIngestedPerDay.data.data
    .map(e => ({
      day: e.attributes.dimensions.day_of_week,
      count: e.attributes.count,
    }))
    .sort((a, b) => a.count - b.count);
};

export const getTopClickedPostsData = state => {
  if (!state || !state.topClickedPosts.data) {
    return [];
  }
  const { data, included } = state.topClickedPosts.data;
  const _arr = [];

  data.map(e => {
    const post = included?.find(i => i.id === e.attributes.dimensions.post_id);
    let socialFeed;
    if (post) {
      const socialFeedId = post?.relationships?.social_feed?.data?.id;
      socialFeed = included?.filter(e => e.type === 'social_feed')?.find(e => e.id === socialFeedId);
      _arr.push({ count: e.attributes.count, ...post.attributes, socialFeed });
    }
  });
  return _arr;
};

export const getTopAccountsData = state => {
  if (!state || !state.topAccounts.data) {
    return { arr: [] };
  }

  const { data, included } = state.topAccounts.data;
  const _data = { arr: [] };
  _data.postSum = data.reduce((a, b) => a + Number(b.attributes.count), 0);

  data.map(e => {
    if (!e.relationships) {
      return;
    }
    const count = Number(e.attributes.count);
    const account = included?.find(i => i.id === e.relationships.account.data?.id);
    if (!account) {
      return;
    }
    _data.arr.push({
      amount: count,
      percentage: Math.round((count * 100) / _data.postSum),
      name: account.attributes.name || account.attributes.username,
      type: account.attributes.type.toUpperCase(),
    });
  });
  _data.arr = _data.arr.sort((a, b) => b.amount - a.amount);
  return _data;
};

export const getTopSourcesData = state => {
  if (!state || !state.topSources.data) {
    return [];
  }
  const { data } = state.topSources.data;
  const sum = data.reduce((a, b) => a + Number(b.attributes.count), 0);

  return data
    .map(e => {
      const source = e.attributes?.dimensions?.social_feed_source;
      let name = source ? source[0].toUpperCase() + source.slice(1) : 'Deleted';

      return {
        name: name,
        value: Number(e.attributes.count),
        percentage: Math.round((Number(e.attributes.count) * 100) / sum),
        type: 'pie',
      };
    })
    .sort((a, b) => b.value - a.value);
};

export const getTotalPostsData = (state, insightsState) => {
  if (!state || !state.totalPosts?.data) {
    return [];
  }

  const { data } = state.totalPosts?.data;

  const _helperArr = data.map(e => ({
    time: parseISO(e.attributes.dimensions.time),
    name: `${monthNames[parseISO(e.attributes.dimensions.time).getMonth()]?.substring(0, 3)} ${parseISO(
      e.attributes.dimensions.time
    ).getDate()}`,
    count: Number(e.attributes.count),
  }));

  let { startDate, endDate } = insightsState.filter.date;

  const ensureISOString = date => {
    if (typeof date === 'string') {
      return date;
    } else if (typeof date === 'number') {
      return new Date(date).toISOString();
    } else if (date instanceof Date) {
      return date.toISOString();
    } else {
      throw new Error(`Unsupported date format: ${date}`);
    }
  };

  try {
    startDate = ensureISOString(startDate);
    endDate = ensureISOString(endDate);
  } catch (error) {
    console.error('Invalid date format:', { startDate, endDate });
    return [];
  }

  const start = parseISO(startDate);
  const end = parseISO(endDate);

  const days = differenceInDays(end, start);
  const dayArray = [];

  for (let i = 0; i <= days; i++) {
    dayArray.push(format(addDays(start, i), 'yyyy-MM-dd'));
  }

  const _arr = dayArray.map(e => {
    const time = parseISO(e);
    const element = _helperArr.find(f => format(f.time, 'yyyy-MM-dd') === e);
    return {
      time: time,
      name: `${monthNames[time.getMonth()]?.substring(0, 3)} ${time.getDate()}`,
      count: element ? Number(element.count) : 0,
    };
  });

  const sum = _helperArr.reduce((a, b) => a + Number(b.count), 0);
  return { data: _arr, totalPosts: sum };
};

export const getModerationActivityData = state => {
  if (!state || !state.moderationActivity.data) {
    return {};
  }
  const data = {};
  state.moderationActivity.data.data.map(e => {
    if (!e.attributes.dimensions) {
      return;
    }

    switch (e.attributes.dimensions.status) {
      case 'deleted':
        data.deleted = Number(e.attributes.count);
        break;
      case 'private':
        data.private = Number(e.attributes.count);
        break;
      case 'public':
        data.public = Number(e.attributes.count);
        break;
    }
  });

  return data;
};

export const getDailyModerationData = state => {
  if (!state || !state.dailyModeration.data) {
    return [];
  }

  const data = {};
  let arr = [];

  state.dailyModeration.data.data.map(e => {
    let { status, time } = e.attributes.dimensions;
    time = new Date(time).getTime();

    if (!data[time]) {
      data[time] = {};
    }
    switch (status) {
      case 'deleted':
        data[time].deleted = Number(e.attributes.count);
        break;
      case 'private':
        data[time].private = Number(e.attributes.count);
        break;
      case 'public':
        data[time].public = Number(e.attributes.count);
        break;
    }
  });

  for (const [key, value] of Object.entries(data)) {
    arr.push({ date: key, ...value });
  }

  return arr
    .sort((a, b) => a.date - b.date)
    .map(e => {
      const time = new Date(Number(e.date));
      return { ...e, name: `${monthNames[time.getMonth()]} ${time.getDate()}` };
    });
};

export const getTopModeratorsData = state => {
  if (!state || !state.topModerators.data) {
    return [];
  }

  const data = {};
  let arr = [];

  state.topModerators.data.data.map(e => {
    let { status, user_id } = e.attributes.dimensions;

    if (!data[user_id]) {
      data[user_id] = { deleted: 0, private: 0, public: 0 };
    }

    switch (status) {
      case 'deleted':
        data[user_id].deleted = Number(e.attributes.count);
        break;
      case 'private':
        data[user_id].private = Number(e.attributes.count);
        break;
      case 'public':
        data[user_id].public = Number(e.attributes.count);
        break;
    }
  });

  for (const [key, value] of Object.entries(data)) {
    arr.push({ id: key, ...value });
  }

  arr = arr
    .sort((a, b) => {
      return a.private + a.public + a.deleted - b.private + b.public + b.deleted;
    })
    .map(e => {
      const user = state.topModerators.data.included?.find(i => i.id === e.id);
      if (user) {
        return {
          ...e,
          name: user.attributes.first_name,
          avatar: user.attributes.image_url,
          email: user.attributes.email,
        };
      }
    })
    .filter(e => !!e);

  return arr;
};

export const getSuggestedHashtagsData = () => {
  return [
    {
      name: 'airjordan',
      size: 40302,
    },
    {
      name: 'adidas',
      size: 40302,
    },
    {
      name: 'jordan',
      size: 40302,
    },
    {
      name: 'sneaker',
      size: 40302,
    },
    {
      name: 'sneaker',
      size: 30302,
    },
    {
      name: 'fashion',
      size: 30302,
    },
    {
      name: 'shoe',
      size: 30302,
    },
    {
      name: 'air',
      size: 30302,
    },
    {
      name: 'size',
      size: 30302,
    },
  ];
};

export const getPopularKeywordsData = () => {
  return [
    {
      name: 'airjordan',
      size: 40302,
    },
    {
      name: 'adidas',
      size: 40302,
    },
    {
      name: 'jordan',
      size: 40302,
    },
    {
      name: 'sneaker',
      size: 40302,
    },
    {
      name: 'sneaker',
      size: 30302,
    },
    {
      name: 'fashion',
      size: 30302,
    },
    {
      name: 'shoe',
      size: 30302,
    },
    {
      name: 'air',
      size: 30302,
    },
    {
      name: 'size',
      size: 30302,
    },
  ];
};

export const getContentRoiData = state => {
  if (!state || !state.moderationActivity.data || !state.totalContentCollected.data) {
    return [];
  }
  const rightsManagement = getRightsManagementData(state);
  const _data = {};
  _data.postsCollected = state.totalContentCollected.data.data[0]?.attributes.count || 0;
  _data.postsApproved = state.moderationActivity.data.data.find(
    e => e.attributes.dimensions?.status === 'public'
  )?.attributes.count;

  return { ..._data, ...rightsManagement };
};

export const getTopRightsRequestersData = state => {
  if (!state || !state.topRightsRequesters.data) {
    return [];
  }
  let arr = [];
  const { included, data } = state.topRightsRequesters.data;
  //requests, name, avatar
  data.map(e => {
    if (!included) {
      return;
    }

    const _obj = {};
    const _userId = e.attributes.dimensions.user_id;
    const user = included.find(u => u.id === _userId);
    if (user) {
      _obj.requests = e.attributes.count;
      _obj.name =
        user.attributes?.first_name && user.attributes?.last_name
          ? `${user.attributes.first_name} ${user.attributes.last_name}`
          : null;
      _obj.email = user.attributes?.email;
      _obj.avatar = user?.attributes.image_url;
      arr.push(_obj);
    }
  });

  return arr;
};

export const getRightsManagementData = state => {
  if (!state || !state.rightsRequested.data || !state.rightsApproved.data) {
    return {};
  }

  const _data = {};
  _data.rightsRequested = state.rightsRequested.data.data.reduce((a, b) => a + Number(b.attributes.count), 0);
  _data.rightsApproved = state.rightsApproved.data.data.reduce((a, b) => a + Number(b.attributes.count), 0);
  return _data;
};

export const hasAllInsightsContentData = state => {
  return (
    !!state?.rightsApproved?.data &&
    !!state?.topClickedPosts?.data &&
    !!state?.rightsRequested?.data &&
    !!state?.topRightsRequesters?.data &&
    !!state?.totalContentCollected?.data &&
    !!state?.topModerators?.data &&
    !!state?.dailyModeration?.data &&
    !!state?.moderationActivity?.data &&
    !!state?.topSources?.data &&
    !!state?.topAccounts?.data &&
    !!state?.topFeeds?.data &&
    !!state?.postsIngested?.data &&
    !!state?.postsIngestedPerDay?.data &&
    !!state?.totalPosts?.data
  );
};
