import React from 'react';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { renderEditColorInput } from '../../page-section-utils';
import { RangeInput } from '../../../../../../../components/interface/inputs/range-input/range-input';
import { EditUploadDesign } from '../../../index';

const colorInputs = [
  { property: 'answerTextColor', label: 'Answer text color' },
  { property: 'answerBackgroundColor', label: 'Answer background color' },
  { property: 'buttonBackgroundColor', label: 'Button color' },
  { property: 'buttonTextColor', label: 'Button text color' },
];

const halfDivider = 2;
const styles = index => (index % halfDivider === 0 ? { margin: '42px 0 0 0' } : {});

const SurveyEditStyles = ({ data, dataUpdate, styleDataUpdate }) => {
  return (
    <EditModalBody title='Customize this section with the color fields below.'>
      <RangeInput
        value={data.styles.sectionPadding}
        onChange={e => {
          styleDataUpdate({ sectionPadding: e });
        }}
        label={'Section Padding'}
      />
      {colorInputs.map((e, i) =>
        renderEditColorInput(
          data.styles[e.property],
          color => styleDataUpdate({ [e.property]: color }),
          e.label,
          i,
          styles(i)
        )
      )}
      <EditUploadDesign
        dataUpdate={dataUpdate}
        styleDataUpdate={styleDataUpdate}
        data={data}
        isPaddingDisabled={true}
      />
    </EditModalBody>
  );
};

export default SurveyEditStyles;
