import { EditInput } from '../../../index';
import React from 'react';
import { FIELD_TYPE } from '../form-helper';
import { INPUT_EDIT_MODAL_STEP } from '../field-edit-modal/field-edit-modal';
import { setFormSectionFieldModalStep } from '../../../../../actions/form-section-field-modal/form-section-field-modal.actions';

const renderInput = ({
  errors,
  data,
  onTranslationUpdate,
  getTranslation,
  removeField,
  handleChange,
  e,
  i,
  dismissEditModal,
  dispatch,
}) => {
  return (
    <EditInput
      label={`form ${e.type} field ${i + 1}`}
      labelIcon={e.type === FIELD_TYPE.DROPDOWN || e.type === FIELD_TYPE.CHECKBOX ? 'far fa-edit' : ''}
      labelIconAction={() => {
        dismissEditModal();
        switch (e.type) {
          case FIELD_TYPE.DROPDOWN:
            dispatch(setFormSectionFieldModalStep({ step: INPUT_EDIT_MODAL_STEP.DROPDOWN, data: { i } }));
            break;

          case FIELD_TYPE.CHECKBOX:
            dispatch(setFormSectionFieldModalStep({ step: INPUT_EDIT_MODAL_STEP.CHECKBOX, data: { i } }));
            break;
        }
      }}
      placeholder='Enter value'
      onChange={event => {
        onTranslationUpdate(data.fields[i].placeholder, event.target.value);
        handleChange(event);
      }}
      name={`fields[${i}].placeholder`}
      touched={true}
      error={errors.fields && errors.fields[i] ? errors.fields[i].placeholder !== '' : false}
      errorMsg={errors.fields && errors.fields[i] ? errors.fields[i].placeholder : ''}
      defaultValue={getTranslation(e.placeholder)}
      rightIcon={'fas fa-trash'}
      rightIconAction={() => removeField({ index: i })}
    />
  );
};

const render = ({
  errors,
  data,
  onTranslationUpdate,
  getTranslation,
  removeField,
  handleChange,
  e,
  i,
  dispatch,
  dismissEditModal,
}) => {
  return renderInput({
    getTranslation,
    e,
    i,
    data,
    onTranslationUpdate,
    handleChange,
    errors,
    removeField,
    dismissEditModal,
    dispatch,
  });
  //switch (e.type) {
  //  case FIELD_TYPE.INPUT:
  //  case FIELD_TYPE.TEXTAREA:
  //  case FIELD_TYPE.DROPDOWN:
  //  case FIELD_TYPE.DROPDOWN:
  //}
};
export const EditFormFieldRenderer = {
  render,
};
