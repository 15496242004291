import React from 'react';
import './form-builder.form.interface.acceptance.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';

const FormBuilderFormInterfaceAcceptance = ({ item, styles }) => {
  if (!item) return null;

  const { t } = useTranslation();

  const checkbox = item.elements.find(element => element.nodeName === 'input');
  const label = item.elements.find(element => element.nodeName === 'label');
  const a = label.elements.find(element => element.nodeName === 'a');
  const text = label.elements.find(element => element.nodeName === '#text');

  const onFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
  };

  const link = `<a href=${t(a.href)} target="_blank">${t(a?.nodeValue)}</a>`;

  const textHtml = t(text.nodeValue, {
    link: link,
  });

  return (
    <div
      className={`${item.class}`}
      style={{
        ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS],
        ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.ACCEPTANCE],
      }}>
      <input
        type={checkbox?.type}
        name={checkbox?.name}
        onFocus={onFocus}
        id={checkbox?.id}
        required={checkbox?.required}
        value={checkbox.value}
        style={{
          ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.ACCEPTANCE_INPUT],
        }}
      />
      <label
        htmlFor={label.for}
        style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.ACCEPTANCE_LABEL] }}
        dangerouslySetInnerHTML={{ __html: textHtml }}
      />
    </div>
  );
};

FormBuilderFormInterfaceAcceptance.displayName = 'FormBuilderFormInterfaceAcceptance';
export default React.memo(FormBuilderFormInterfaceAcceptance);
