import React, { useCallback, useEffect, useRef, useState } from 'react';
import './dropdown-infinite-list.sass';
import PropTypes from 'prop-types';
import { DropdownHeader } from '../../button/header/dropdown-header';
import { DropdownSearchInput } from '../../seach-input/dropdown-search-input';
import PreloaderComponent, {
  PreloaderBackdropType,
  PreloaderPositionType,
  PreloaderSizeType,
} from '../../../preloaders/preloader/preloader';
import InfiniteScroll from 'react-infinite-scroll-component';
import useDropdownListPosition from '../../../../hooks/use-dropdown-list-position/use-dropdown-list-position';
import debounce from 'lodash.debounce';
import Tooltip from '../../../../../feature/transform-ui/components/tooltip/tooltip';
import TintButtonIcon, { TintButtonIconTypes } from '../../../button-icon/button-icon';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { AccountStatus } from '../../../../../feature/tint-editor/components/tint-editor/sidebars/social-feed-sidebar/components/account-dropdown/account-dropdown';
import { Typography } from '../../../typography/typography';

const debounceDelay = 300;

const DropdownInfiniteList = ({
  onChange,
  parentRef,
  onChangeTextComponent,
  iconLeft,
  iconRight,
  iconCenter,
  currentItems = [],
  dropdownHeaderStyle,
  onLoadData,
  onInputChange,
  isAbleToLoad,
  inputValue,
  setInputListValue,
  isDefaultOption = false,
  defaultOptionTitle = 'None',
  placeholder,
  lastChildColor,
  refreshSocialConnection,
  isLoading,
  fixedOption = false,
}) => {
  const elementRef = useRef();
  const { positionHorizontal, positionVertical } = useDropdownListPosition(elementRef, parentRef);
  const updateInputField = useCallback(debounce(onInputChange, debounceDelay), []);
  const defaultValue = { value: '', name: defaultOptionTitle };

  const DropdownTextElement = title => {
    return (
      <DropdownHeader
        iconLeft={iconLeft}
        iconRight={iconRight}
        iconCenter={iconCenter}
        title={title}
        style={dropdownHeaderStyle}
      />
    );
  };

  useEffect(() => {
    setSearchTerm(inputValue);
  }, []);

  const [searchTerm, setSearchTerm] = useState('');

  const onInputSearchChange = event => {
    updateInputField(event.target.value);
    setInputListValue(event.target.value);
    setSearchTerm(event.target.value);
  };

  return (
    <>
      <InfiniteScroll
        dataLength={currentItems.length}
        next={onLoadData}
        hasMore={isAbleToLoad}
        scrollableTarget='dropdown-infinite-list-scroll'>
        <ul
          className={`dropdown-infinite-list dropdown-infinite-list--${positionHorizontal} dropdown-infinite-list--${positionVertical}`}
          ref={elementRef}
          data-testid='dropdown-infinite-list'
          id='dropdown-infinite-list-scroll'>
          {(currentItems.length === 0 || (currentItems.length === 0 && searchTerm.length > 0)) && (
            <li className='dropdown-infinite-list__item dropdown-infinite-list__item--no-results'>
              <Typography>No results</Typography>
            </li>
          )}
          {onInputChange && (
            <li className='dropdown-infinite-list__item dropdown-infinite-list__item--search'>
              <DropdownSearchInput onChange={onInputSearchChange} searchTerm={searchTerm} />
              {isLoading && (
                <PreloaderComponent
                  positionType={PreloaderPositionType.ABSOLUTE}
                  backdropType={PreloaderBackdropType.TRANSPARENT}
                  size={PreloaderSizeType.SMALL}
                />
              )}
            </li>
          )}
          {isDefaultOption && (
            <li
              className='dropdown-infinite-list__item'
              onClick={() => {
                onChange(defaultValue.value);
                onChangeTextComponent(DropdownTextElement(placeholder));
              }}>
              <span>{defaultValue.name}</span>
            </li>
          )}
          {currentItems.map((item, i) => (
            <li
              className={`dropdown-infinite-list__item ${
                i === currentItems.length - 1 && fixedOption ? 'dropdown-infinite-list__item--additional' : ''
              } ${item?.isActive && fixedOption ? 'active' : ''}`}
              key={i}
              onClick={() => {
                if (item?.status !== AccountStatus.EXPIRED) {
                  onChange(item);
                  onChangeTextComponent(DropdownTextElement(item.name));
                } else {
                  refreshSocialConnection(item);
                }
              }}>
              {item?.header && <header>{item.header}</header>}
              {item?.icon ? <span className={`icon ${item.icon}`} /> : null}
              <span
                style={
                  i === currentItems.length - 1 && fixedOption && lastChildColor ? { color: lastChildColor } : null
                }>
                {item?.name}
              </span>
              {item?.status === AccountStatus.EXPIRED && (
                <Tooltip
                  text='Reconnect'
                  component={
                    <TintButtonIcon
                      type={TintButtonIconTypes.NONE}
                      icon={faCircleExclamation}
                      color={'#FBAA22'}
                      iconSize={14}
                      onClick={e => {
                        e.preventDefault();
                      }}
                    />
                  }
                />
              )}
            </li>
          ))}
        </ul>
      </InfiniteScroll>
    </>
  );
};

export default DropdownInfiniteList;

DropdownInfiniteList.propTypes = {
  onChange: PropTypes.func,
  currentItems: PropTypes.array,
  parentRef: PropTypes.any,
  onChangeTextComponent: PropTypes.func,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconCenter: PropTypes.string,
};
