import { Config } from '../../../utils/config';
import { hasOneOfThePermission } from '../../../services/functions/permissions-checker/permissions-checker';

const formSubmissionsDeleteGuard = ({ permissions }) =>
  hasOneOfThePermission(permissions, [Config.permissions.formSubmissionsDelete]);

const fontsReadGuard = ({ permissions }) => hasOneOfThePermission(permissions, [Config.permissions.fontsRead]);

const experiencesGuards = {
  formSubmissionsDeleteGuard,
  fontsReadGuard,
};

export default experiencesGuards;
