import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js/pure';
import './stripe-checkout-form.sass';
import { Config } from '../../utils/config';
import CheckoutFormWithForwardedRef from './checkout-form/checkout-form';

const StripeCheckoutForm = ({ handleCheckout, onChange }, ref) => {
  const stripePromise = loadStripe(Config.stripePublishableKey());

  return (
    <div className='stripe-checkout-form'>
      <div className='stripe-checkout-form__elements'>
        <Elements stripe={stripePromise}>
          <CheckoutFormWithForwardedRef ref={ref} handleCheckout={handleCheckout} onChange={onChange} />
        </Elements>
      </div>
    </div>
  );
};

const StripeCheckoutFormWithForwardedRef = forwardRef(StripeCheckoutForm);

StripeCheckoutFormWithForwardedRef.propTypes = {
  onChange: PropTypes.func,
  handleCheckout: PropTypes.func,
};

export default StripeCheckoutFormWithForwardedRef;
