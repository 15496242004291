import { ACTIONS } from '../actions/experience-builder';

const experienceBuilder = (
  state = {
    width: undefined,
    isPreviewMode: false,
    selectedTextarea: undefined,
  },
  action
) => {
  switch (action.type) {
    case ACTIONS.SET_PAGE_BUILDER_WIDTH:
      return { ...state, ...{ width: action.payload } };
    case ACTIONS.SET_PREVIEW_MODE:
      return { ...state, ...{ isPreviewMode: action.payload } };
    case ACTIONS.SET_SELECTED_TEXTAREA:
      return { ...state, ...{ selectedTextarea: action.payload } };
    default:
      return state;
  }
};

export default experienceBuilder;
