import * as ReactDOM from 'react-dom';
import React, { useEffect, useState } from 'react';
import './sidebar.sass';
import PropTypes from 'prop-types';
import useKeyDown from '../hooks/use-key-down/use-key-down';
import { keyCodes } from '../hooks/use-key-down/keyCodes';
import { SidebarFooter, SidebarFooterClickButtonAction } from './components/footer/sidebar-footer';
import { SidebarHeader } from './components/haeder/sidebar-header';

export const TintSidebarHeightType = {
  FULL_SCREEN: 'screen-height',
  BELOW_NAVBAR: 'below-navbar-height',
  BELOW_ACTIONBAR: 'below-actionbar-height', // dark navbar
};

export const TintSidebarSize = {
  BIG: 'big',
  NORMAL: 'normal',
};

export const TintSidebar = ({
  sidebarClassName,
  children,
  animationTime = 0.3,
  onClose,
  isOpen = false,
  styles = {},
  title,
  isFooterVisible = true,
  alertComponent,
  footerComponent,
  onRightButtonClick,
  onLeftButtonClick,
  rightButtonText,
  leftButtonText,
  isSubmitting,
  isRightButtonDisabled = false,
  defaultFooterStyle = true,
  type = TintSidebarHeightType.BELOW_NAVBAR,
  isBackDropVisible = true,
  id,
  oneButton = false,
  size = TintSidebarSize.NORMAL,
}) => {
  const keyDown = useKeyDown();
  const [isSidebarOpen, setSidebarVisibility] = useState(isOpen);
  const [animationState, setAnimationState] = useState(isOpen);

  useEffect(() => {
    if (isOpen) {
      setSidebarVisibility(true);
      setAnimationState(true);
    }

    if (!isOpen && isSidebarOpen) {
      onCloseClick();
    }
  }, [isOpen]);

  useEffect(() => {
    if (onClose && keyDown && keyDown.keyDown === keyCodes.escape) {
      onCloseClick();
    }
  }, [keyDown]);

  const onAnimationEnd = () => {
    if (!isSidebarOpen) {
      setAnimationState(false);
    }
  };

  const onCloseClick = () => {
    setSidebarVisibility(false);

    setTimeout(() => {
      onClose && onClose();
    }, animationTime * 1000 - 50);
  };

  const renderFooterComponent = () => {
    return footerComponent ? (
      footerComponent({
        onClickButton,
      })
    ) : (
      <SidebarFooter
        onClickButton={onClickButton}
        leftButtonText={leftButtonText}
        rightButtonText={rightButtonText}
        isSubmitting={isSubmitting}
        isRightButtonDisabled={isRightButtonDisabled}
        oneButton={oneButton}
      />
    );
  };

  const getCloseAnimation = () => {
    return styles.animation && styles.animation.close
      ? styles.animation.close
      : size === TintSidebarSize.BIG
      ? 'moveOutBig'
      : 'moveOut';
  };

  const getOpenAnimation = () => {
    return styles.animation && styles.animation.open
      ? styles.animation.open
      : size === TintSidebarSize.BIG
      ? 'moveInBig'
      : 'moveIn';
  };

  const onClickButton = type => {
    switch (type) {
      case SidebarFooterClickButtonAction.LEFT_BUTTON:
        onLeftButtonClick ? onLeftButtonClick() : onCloseClick();
        break;

      case SidebarFooterClickButtonAction.RIGHT_BUTTON:
        onRightButtonClick && onRightButtonClick();
        break;

      default:
        return;
    }
  };

  return (
    animationState &&
    ReactDOM.createPortal(
      <>
        {isBackDropVisible && <div className='tint-sidebar-backdrop' />}
        <div
          data-testid={id}
          className={`tint-sidebar tint-sidebar--${type} tint-sidebar--size-${size} ${sidebarClassName}`}>
          <div
            className='tint-sidebar__content'
            style={{
              animation: `${isSidebarOpen ? getOpenAnimation() : getCloseAnimation()} ${animationTime}s`,
            }}
            onAnimationEnd={onAnimationEnd}>
            <div className='tint-sidebar__container'>
              {title && (
                <div className='tint-sidebar__header'>
                  <SidebarHeader title={title} onIconCLick={onCloseClick} />
                </div>
              )}
              <div className='tint-sidebar__children-wrapper' id='scrollableTarget'>
                {children}
              </div>
              {alertComponent}
              {isFooterVisible && (
                <div className={`tint-sidebar__footer ${defaultFooterStyle ? 'tint-sidebar__footer--default' : ''}`}>
                  {renderFooterComponent()}
                </div>
              )}
            </div>
          </div>
        </div>
      </>,
      document.body
    )
  );
};

export default TintSidebar;
TintSidebar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  styles: PropTypes.shape({
    backgroundColor: PropTypes.string,
    animation: PropTypes.object,
  }),
};
