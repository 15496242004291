import React, { Component } from 'react';
import './edit-social-feed-counter-content.sass';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import EditInputDropdown from '../../../edit-input-dropdown/edit-input-dropdown';
import { Timer } from '../../../../../../../components/timer/timer';
import { Button } from '../../../../../../../components/interface/button/button';
import EditInput from '../../../edit-input/edit-input';
import PropTypes from 'prop-types';

const dropdownFieldsLimit = 3;
const dropdownSocialFeedPlaceholder = 'Select Social Feed';
const dropdownTintSlugPlaceholder = 'Select TINT';

export class EditSocialFeedCounterContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customList: this.mapDropdownItem(),
      dropdownFieldsLength: this.props.data.content.slugList.length,
      isCountingFinished: false,
    };
  }

  mapDropdownItem = () => {
    return this.props.data.content.slugList.map(item => {
      return {
        value: item.slugId,
        slugId: item.slugId,
        socialFeedId: item.socialFeedId,
        name: item.slugId,
        socialFeeds: this.isSelectDefault(item.slugId) ? this.getSocialFeedList(item.slugId) : [],
      };
    });
  };

  onTintSocialFeedChange(socialFeedId, index) {
    const selectedItem = this.state.customList.find((item, i) => i === index);
    const currentItem = { ...selectedItem, ...{ socialFeedId: socialFeedId } };
    const currentList = [
      ...this.state.customList.slice(0, index),
      currentItem,
      ...this.state.customList.slice(index + 1),
    ];

    this.setState({ customList: currentList }, () => {
      if (socialFeedId === dropdownSocialFeedPlaceholder) return;
      this.updateData();
    });
  }

  isSelectDefault = value => {
    return value !== undefined && value !== dropdownSocialFeedPlaceholder && value !== dropdownTintSlugPlaceholder;
  };

  onTintSlugChange(value, index) {
    const selectedItem = this.getSelectedItem(value, index);
    const currentList = [
      ...this.state.customList.slice(0, index),
      selectedItem,
      ...this.state.customList.slice(index + 1),
    ];

    this.setState(
      {
        customList: this.state.customList.length <= 1 ? [selectedItem] : currentList,
      },
      () => {
        this.updateData();
      }
    );
  }

  getSelectedItem = (value, index) => {
    const currentItem = this.state.customList.find((item, i) => i === index);
    return {
      ...currentItem,
      ...{
        value: value,
        slugId: value,
        socialFeedId: undefined,
        socialFeeds: this.isSelectDefault(value) ? this.getSocialFeedList(value) : [],
      },
    };
  };

  mapEmptyFieldsObject = () => {
    return {
      value: undefined,
      name: undefined,
      slugId: undefined,
      socialFeedId: undefined,
      socialFeeds: [],
    };
  };

  updateData = () => {
    const mapToUpdate = this.state.customList.map(item => {
      return {
        slugId: item.value,
        socialFeedId: item.socialFeedId,
      };
    });

    this.props.dataUpdate({ slugList: mapToUpdate });
    // this.props.onChangeData(mapToUpdate);
  };

  getSocialFeedList = slugId => {
    return this.props.tintSlugOptions.find(tint => tint.value === slugId).socialFeeds;
  };

  onChangeStartDate = date => {
    const currentDate = new Date(date.currentDate);
    const formatDate = new Date(currentDate).toJSON();
    return this.props.dataUpdate({ startDate: formatDate });
  };

  onChangeEndDate = date => {
    const currentDate = new Date(date.currentDate);
    const formatDate = new Date(currentDate).toJSON();

    return this.props.dataUpdate({ endDate: formatDate });
  };

  addAnotherFeed = () => {
    this.setState({
      dropdownFieldsLength: this.state.customList.length + 1,
      customList: [...this.state.customList, this.mapEmptyFieldsObject()],
    });
  };

  removeFeed = index => {
    this.setState(
      {
        dropdownFieldsLength: this.state.customList.length - 1,
        customList: [...this.state.customList.slice(0, index), ...this.state.customList.slice(index + 1)],
      },
      () => {
        this.updateData();
      }
    );
  };

  renderDropdownList = () => {
    return (
      this.state.customList.length > 0 &&
      this.state.customList.map((el, i) => (
        <div
          data-testid='socialFeedDropdownGroup'
          className='edit-social-feed-counter-content__select-section'
          key={`tint-slug-${i}`}>
          <div>
            <EditInputDropdown
              id={`tintSlugDropdown_${i}`}
              value={el.slugId}
              isControlled={true}
              placeholder={dropdownTintSlugPlaceholder}
              onChange={e => {
                this.onTintSlugChange(e.target.value, i);
              }}
              options={this.props.tintSlugOptions}
              label={dropdownTintSlugPlaceholder}
            />

            <EditInputDropdown
              id={`socialFeedDropdown_${i}`}
              value={el.socialFeedId}
              isControlled={true}
              placeholder={dropdownSocialFeedPlaceholder}
              onChange={e => {
                this.onTintSocialFeedChange(e.target.value, i);
              }}
              options={el.socialFeeds}
              label='Select Social Feed'
            />
          </div>
          <span
            data-testid='socialFeedDropdownRemoveButton'
            className='fas fa-trash'
            onClick={() => this.removeFeed(i)}
          />
        </div>
      ))
    );
  };

  render() {
    const inactiveClass =
      this.props.data.content.isCountingEnd || this.state.isCountingFinished
        ? 'edit-social-feed-counter-content--inactive'
        : '';

    return (
      <EditModalBody>
        <div data-testid='editSocialFeedCounter' className={`edit-social-feed-counter-content ${inactiveClass}`}>
          <div className='tint-edit-modal-body__group'>
            {this.renderDropdownList()}

            {this.state.dropdownFieldsLength < dropdownFieldsLimit ? (
              <Button
                id='addAnotherFeedButton'
                onClick={this.addAnotherFeed}
                type='upload'
                size='full-width'
                text='+ Add Another Feed'
              />
            ) : null}

            <span className='tint-edit-modal-body__description'>Limited to 3 Social Feeds</span>
          </div>

          <div className='tint-edit-modal-body__group' data-testid='socialFeedLabelText'>
            <EditInput
              onChange={e => {
                this.props.onTranslationUpdate(this.props.data.content.counterLabelText, e.target.value);
              }}
              label='Label text'
              type='text'
              placeholder='Posts & Counting'
              defaultValue={this.props.getTranslation(this.props.data.content.counterLabelText)}
            />
          </div>

          <div className='tint-edit-modal-body__group'>
            <span className='tint-edit-modal-body__header'>COUNTER START DATE</span>
            <Timer dataUpdate={this.onChangeStartDate} currentDate={this.props.data.content.startDate} />
          </div>

          <div className='tint-edit-modal-body__group'>
            <span className='tint-edit-modal-body__header'>COUNTER END DATE</span>
            <Timer dataUpdate={this.onChangeEndDate} currentDate={this.props.data.content.endDate} />
          </div>

          <div className='tint-edit-modal-body__group'>
            <span className='tint-edit-modal-body__header'>END DATE</span>
            <Button
              id='stopCounterButton'
              onClick={() => {
                this.setState({ isCountingFinished: true });
                this.props.dataUpdate({ count: this.props.count });
                this.props.onStopCounter();
              }}
              type='upload'
              size='full-width'
              text='Stop Counter'
            />

            <span className='tint-edit-modal-body__description'>
              Please note: you cannot undo this action. When you stop the counter, the total count will be finalized.
            </span>
          </div>
          {this.props.data.content.isCountingEnd ? (
            <>
              <span className='tint-edit-modal-body__description'>Counting is finished</span>
              {this.state.isCountingFinished ? <p className='confirm-alert'>Click Apply for accept</p> : null}
            </>
          ) : null}
        </div>
      </EditModalBody>
    );
  }
}

EditSocialFeedCounterContent.propTypes = {
  count: PropTypes.number,
  tintSlugOptions: PropTypes.array,
  data: PropTypes.shape({
    content: PropTypes.shape({
      slugList: PropTypes.array,
      counterLabelText: PropTypes.string,
      startDate: PropTypes.any,
      endDate: PropTypes.any,
      isCountingEnd: PropTypes.bool,
    }),
  }),
  onChangeData: PropTypes.func,
  dataUpdate: PropTypes.func,
  onStopCounter: PropTypes.func,
  getTranslation: PropTypes.func,
  onTranslationUpdate: PropTypes.func,
};
