import { createPrefixedActionName, createRoutine } from '../../../../services/functions/redux-routines/redux-routines';
import { ROLES } from '../../consts';

export const ACTIONS = {
  OPEN_SIDEBAR: createPrefixedActionName(ROLES, 'OPEN_SIDEBAR'),
  CLOSE_SIDEBAR: createPrefixedActionName(ROLES, 'CLOSE_SIDEBAR'),
  SET_PENDING_TINTS: createPrefixedActionName(ROLES, 'SET_PENDING_TINTS'),
  SET_PENDING_PERSONALIZATIONS: createPrefixedActionName(ROLES, 'SET_PENDING_PERSONALIZATIONS'),
  SET_PENDING_COLLECTIONS: createPrefixedActionName(ROLES, 'SET_PENDING_COLLECTIONS'),
  SET_PENDING_SOCIAL_ACCOUNTS: createPrefixedActionName(ROLES, 'SET_PENDING_SOCIAL_ACCOUNTS'),
  SET_PENDING_EXPERIENCES: createPrefixedActionName(ROLES, 'SET_PENDING_EXPERIENCES'),
  SET_PENDING_FONTS: createPrefixedActionName(ROLES, 'SET_PENDING_FONTS'),
  SET_PENDING_DOMAINS: createPrefixedActionName(ROLES, 'SET_PENDING_DOMAINS'),
  SET_PENDING_PRODUCT_ACCOUNTS: createPrefixedActionName(ROLES, 'SET_PENDING_PRODUCT_ACCOUNTS'),
  SET_PENDING_PRODUCT_FEEDS: createPrefixedActionName(ROLES, 'SET_PENDING_PRODUCT_FEEDS'),
  SET_PENDING_WEBHOOKS: createPrefixedActionName(ROLES, 'SET_PENDING_WEBHOOKS'),
  SET_PENDING_OAUTH_APPLICATIONS: createPrefixedActionName(ROLES, 'SET_PENDING_OAUTH_APPLICATIONS'),
  SET_PENDING_FORMS: createPrefixedActionName(ROLES, 'SET_PENDING_FORMS'),
  SET_PENDING_MIXES: createPrefixedActionName(ROLES, 'SET_PENDING_MIXES'),
  CLEAR_PENDING_DATA: createPrefixedActionName(ROLES, 'CLEAR_PENDING_DATA'),
  GET_ROLE_ACCOUNTS: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE_ACCOUNTS')),
  GET_ROLE_TINTS: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE_TINTS')),
  GET_ROLE_PERSONALIZATIONS: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE_PERSONALIZATIONS')),
  GET_ROLE_COLLECTIONS: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE_COLLECTIONS')),
  GET_ROLE_EXPERIENCES: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE_EXPERIENCES')),
  GET_ROLE_FONTS: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE_FONTS')),
  GET_ROLE_DOMAINS: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE_DOMAINS')),
  GET_ROLE_PRODUCT_ACCOUNTS: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE_PRODUCT_ACCOUNTS')),
  GET_ROLE_PRODUCT_FEEDS: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE_PRODUCT_FEEDS')),
  GET_ROLE_WEBHOOKS: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE_WEBHOOKS')),
  GET_ROLE_OAUTH_APPLICATIONS: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE_OAUTH_APPLICATIONS')),
  GET_ROLE_FORMS: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE_FORMS')),
  GET_ROLE_MIXES: createRoutine(createPrefixedActionName(ROLES, 'GET_ROLE_MIXES')),
};

export const openSidebar = data => ({
  type: ACTIONS.OPEN_SIDEBAR,
  payload: data,
});

export const closeSidebar = () => ({
  type: ACTIONS.CLOSE_SIDEBAR,
});

export const setPendingTints = data => ({
  type: ACTIONS.SET_PENDING_TINTS,
  payload: data,
});

export const setPendingPersonalizations = data => ({
  type: ACTIONS.SET_PENDING_PERSONALIZATIONS,
  payload: data,
});

export const setPendingCollections = data => ({
  type: ACTIONS.SET_PENDING_COLLECTIONS,
  payload: data,
});

export const setPendingSocialAccounts = data => ({
  type: ACTIONS.SET_PENDING_SOCIAL_ACCOUNTS,
  payload: data,
});

export const setPendingExperiences = data => ({
  type: ACTIONS.SET_PENDING_EXPERIENCES,
  payload: data,
});

export const setPendingFonts = data => ({
  type: ACTIONS.SET_PENDING_FONTS,
  payload: data,
});

export const setPendingDomains = data => ({
  type: ACTIONS.SET_PENDING_DOMAINS,
  payload: data,
});

export const setPendingProductAccounts = data => ({
  type: ACTIONS.SET_PENDING_PRODUCT_ACCOUNTS,
  payload: data,
});

export const setPendingProductFeeds = data => ({
  type: ACTIONS.SET_PENDING_PRODUCT_FEEDS,
  payload: data,
});

export const setPendingWebhooks = data => ({
  type: ACTIONS.SET_PENDING_WEBHOOKS,
  payload: data,
});

export const setPendingOauthApplications = data => ({
  type: ACTIONS.SET_PENDING_OAUTH_APPLICATIONS,
  payload: data,
});

export const setPendingForms = data => ({
  type: ACTIONS.SET_PENDING_FORMS,
  payload: data,
});

export const setPendingMixes = data => ({
  type: ACTIONS.SET_PENDING_MIXES,
  payload: data,
});

export const clearPendingData = () => ({
  type: ACTIONS.CLEAR_PENDING_DATA,
});

export const getRoleTintsStart = () => ({
  type: ACTIONS.GET_ROLE_TINTS.REQUEST,
});

export const getRoleTintsSuccess = data => ({
  type: ACTIONS.GET_ROLE_TINTS.SUCCESS,
  payload: data,
});

export const getRoleTintsError = err => ({
  type: ACTIONS.GET_ROLE_TINTS.FAILURE,
  payload: err,
});

export const getRolePersonalizationsStart = () => ({
  type: ACTIONS.GET_ROLE_PERSONALIZATIONS.REQUEST,
});

export const getRolePersonalizationsSuccess = data => ({
  type: ACTIONS.GET_ROLE_PERSONALIZATIONS.SUCCESS,
  payload: data,
});

export const getRolePersonalizationsError = err => ({
  type: ACTIONS.GET_ROLE_PERSONALIZATIONS.FAILURE,
  payload: err,
});

export const getRoleAccountsStart = () => ({
  type: ACTIONS.GET_ROLE_ACCOUNTS.REQUEST,
});

export const getRoleAccountsSuccess = data => ({
  type: ACTIONS.GET_ROLE_ACCOUNTS.SUCCESS,
  payload: data,
});

export const getRoleAccountsError = err => ({
  type: ACTIONS.GET_ROLE_ACCOUNTS.FAILURE,
  payload: err,
});

export const getRoleCollectionsStart = () => ({
  type: ACTIONS.GET_ROLE_COLLECTIONS.REQUEST,
});

export const getRoleCollectionsSuccess = data => ({
  type: ACTIONS.GET_ROLE_COLLECTIONS.SUCCESS,
  payload: data,
});

export const getRoleCollectionsError = err => ({
  type: ACTIONS.GET_ROLE_COLLECTIONS.FAILURE,
  payload: err,
});

export const getRoleExperiencesStart = () => ({
  type: ACTIONS.GET_ROLE_EXPERIENCES.REQUEST,
});

export const getRoleExperiencesSuccess = data => ({
  type: ACTIONS.GET_ROLE_EXPERIENCES.SUCCESS,
  payload: data,
});

export const getRoleExperiencesError = err => ({
  type: ACTIONS.GET_ROLE_EXPERIENCES.FAILURE,
  payload: err,
});

export const getRoleFontsStart = () => ({
  type: ACTIONS.GET_ROLE_FONTS.REQUEST,
});

export const getRoleFontsSuccess = data => ({
  type: ACTIONS.GET_ROLE_FONTS.SUCCESS,
  payload: data,
});

export const getRoleFontsError = err => ({
  type: ACTIONS.GET_ROLE_FONTS.FAILURE,
  payload: err,
});

export const getRoleDomainsStart = () => ({
  type: ACTIONS.GET_ROLE_DOMAINS.REQUEST,
});

export const getRoleDomainsSuccess = data => ({
  type: ACTIONS.GET_ROLE_DOMAINS.SUCCESS,
  payload: data,
});

export const getRoleDomainsError = err => ({
  type: ACTIONS.GET_ROLE_DOMAINS.FAILURE,
  payload: err,
});

export const getRoleProductAccountsStart = () => ({
  type: ACTIONS.GET_ROLE_PRODUCT_ACCOUNTS.REQUEST,
});

export const getRoleProductAccountsSuccess = data => ({
  type: ACTIONS.GET_ROLE_PRODUCT_ACCOUNTS.SUCCESS,
  payload: data,
});

export const getRoleProductAccountsError = err => ({
  type: ACTIONS.GET_ROLE_PRODUCT_ACCOUNTS.FAILURE,
  payload: err,
});

export const getRoleProductFeedsStart = () => ({
  type: ACTIONS.GET_ROLE_PRODUCT_FEEDS.REQUEST,
});

export const getRoleProductFeedsSuccess = data => ({
  type: ACTIONS.GET_ROLE_PRODUCT_FEEDS.SUCCESS,
  payload: data,
});

export const getRoleProductFeedsError = err => ({
  type: ACTIONS.GET_ROLE_PRODUCT_FEEDS.FAILURE,
  payload: err,
});

export const getRoleWebhooksStart = () => ({
  type: ACTIONS.GET_ROLE_WEBHOOKS.REQUEST,
});

export const getRoleWebhooksSuccess = data => ({
  type: ACTIONS.GET_ROLE_WEBHOOKS.SUCCESS,
  payload: data,
});

export const getRoleWebhooksError = err => ({
  type: ACTIONS.GET_ROLE_WEBHOOKS.FAILURE,
  payload: err,
});

export const getRoleOauthApplicationsStart = () => ({
  type: ACTIONS.GET_ROLE_OAUTH_APPLICATIONS.REQUEST,
});

export const getRoleOauthApplicationsSuccess = data => ({
  type: ACTIONS.GET_ROLE_OAUTH_APPLICATIONS.SUCCESS,
  payload: data,
});

export const getRoleOauthApplicationsError = err => ({
  type: ACTIONS.GET_ROLE_OAUTH_APPLICATIONS.FAILURE,
  payload: err,
});

export const getRoleFormsStart = () => ({
  type: ACTIONS.GET_ROLE_FORMS.REQUEST,
});

export const getRoleFormsSuccess = data => ({
  type: ACTIONS.GET_ROLE_FORMS.SUCCESS,
  payload: data,
});

export const getRoleFormsError = err => ({
  type: ACTIONS.GET_ROLE_FORMS.FAILURE,
  payload: err,
});

export const getRoleMixesStart = () => ({
  type: ACTIONS.GET_ROLE_MIXES.REQUEST,
});

export const getRoleMixesSuccess = data => ({
  type: ACTIONS.GET_ROLE_MIXES.SUCCESS,
  payload: data,
});

export const getRoleMixesError = err => ({
  type: ACTIONS.GET_ROLE_MIXES.FAILURE,
  payload: err,
});
