import React, { useRef } from 'react';
import './typography.sass';

/*
  DEFAULT:  fontSize: 14px, fontWeight: 500, color: #70757E (placeholder)
  DESCRIPTION:  fontSize: 16px, fontWeight: 500, color: #1b242d (primary)
  TITLE:  fontSize: 14px, fontWeight: 500, color: #1b242d (primary)
  SUBTITLE:  fontSize: 14px, fontWeight: 400, color: #838e94 (secondary)
  LABEL:  fontSize: 12px, fontWeight: 500, color: #282828 (deep dark grey)
  LIST:  fontSize: 12px, fontWeight: 400, color: #797E86 (semi dark grey)
  SMALL:  fontSize: 11px, fontWeight: 400, color: #70757E (dark grey)
  H2:  fontSize: 30px, fontWeight: 500, color: #797E86 (dark grey)
  H3:  fontSize: 24px, fontWeight: 500, color: #797E86 (dark grey)
  H4:  fontSize: 18px, fontWeight: 500, color: #797E86 (dark grey)
  H5:  fontSize: 17px, fontWeight: 400, color: #797E86 (dark grey)
* */

export const TypographyVariants = {
  DEFAULT: 'default',
  DESCRIPTION: 'description',
  TITLE: 'title',
  SUBTITLE: 'subtitle',
  LABEL: 'label',
  LIST: 'list',
  SMALL: 'small',
  H2: 'h2',
  H3: 'h3',
  H4: 'h4',
  H5: 'h5',
};

export const TypographyColors = {
  PRIMARY: '#1b242d',
  SECONDARY: '#838e94',
  BLUE: '#3B99FC',
  WHITE: '#fff',
  RED: '#FF5055',
};

export const TypographyFontWeights = {
  NORMAL: 400,
  MEDIUM: 500,
};

export const Typography = ({
  className,
  children,
  component = 'span',
  color,
  fontWeight,
  variant = TypographyVariants.DEFAULT,
  styleProps,
  ...props
}) => {
  const elementRef = useRef(null);
  const Tag = props => React.createElement(component, { ref: elementRef, ...props });

  return (
    <Tag
      className={`tint-typography tint-typography--${variant} ${className}`}
      style={{ color, fontWeight, ...styleProps }}
      {...props}>
      {children}
    </Tag>
  );
};
