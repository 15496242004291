import { createPrefixedActionName, createRoutine } from '../../../../services/functions/redux-routines/redux-routines';

export const ACTIONS = {
  OPEN_COLLECTION_MODAL: createPrefixedActionName('ASSET_MANAGER', 'OPEN_COLLECTION_MODAL'),
  CLOSE_COLLECTION_MODAL: createPrefixedActionName('ASSET_MANAGER', 'CLOSE_COLLECTION_MODAL'),
  OPEN_COLLECTION_SETTINGS_MODAL: createPrefixedActionName('ASSET_MANAGER', 'OPEN_COLLECTION_SETTINGS_MODAL'),
  CLOSE_COLLECTION_SETTINGS_MODAL: createPrefixedActionName('ASSET_MANAGER', 'CLOSE_COLLECTION_SETTINGS_MODAL'),
  SET_COLLECTION_DISPLAY_TYPE: createPrefixedActionName('ASSET_MANAGER', 'SET_COLLECTION_DISPLAY_TYPE'),
  SELECT_COLLECTION: createPrefixedActionName('ASSET_MANAGER', 'SELECT_COLLECTION'),
  UNSELECT_COLLECTION: createPrefixedActionName('ASSET_MANAGER', 'UNSELECT_COLLECTION'),
  SELECT_ALL_COLLECTIONS: createPrefixedActionName('ASSET_MANAGER', 'SELECT_ALL_COLLECTIONS'),
  UNSELECT_ALL_COLLECTIONS: createPrefixedActionName('ASSET_MANAGER', 'UNSELECT_ALL_COLLECTIONS'),
  CLEAR_COLLECTIONS: createPrefixedActionName('ASSET_MANAGER', 'CLEAR_COLLECTIONS'),
  POST_ASSETS_TO_COLLECTIONS: createRoutine(createPrefixedActionName('ASSET_MANAGER', 'POST_ASSETS_TO_COLLECTIONS')),
  POST_NEW_COLLECTION: createRoutine(createPrefixedActionName('ASSET_MANAGER', 'POST_NEW_COLLECTION')),
  GET_COLLECTIONS: createRoutine(createPrefixedActionName('ASSET_MANAGER', 'GET_COLLECTIONS')),
  PATCH_COLLECTIONS: createRoutine(createPrefixedActionName('ASSET_MANAGER', 'PATCH_COLLECTIONS')),
  PATCH_COLLECTION: createRoutine(createPrefixedActionName('ASSET_MANAGER', 'PATCH_COLLECTION')),
  DELETE_COLLECTIONS: createRoutine(createPrefixedActionName('ASSET_MANAGER', 'DELETE_COLLECTIONS')),
  GET_COLLECTION: createRoutine(createPrefixedActionName('ASSET_MANAGER', 'GET_COLLECTION')),
  GET_COLLECTION_ASSET_DOWNLOAD_STATUS: createRoutine(
    createPrefixedActionName('ASSET_MANAGER', 'GET_COLLECTION_ASSET_DOWNLOAD_STATUS')
  ),
  DOWNLOAD_COLLECTION_ASSETS: createRoutine(createPrefixedActionName('ASSET_MANAGER', 'DOWNLOAD_COLLECTION_ASSETS')),
};

export const downloadCollectionAssetsStart = () => ({
  type: ACTIONS.DOWNLOAD_COLLECTION_ASSETS.REQUEST,
});

export const downloadCollectionAssetsSuccess = data => ({
  type: ACTIONS.DOWNLOAD_COLLECTION_ASSETS.SUCCESS,
  payload: data,
});

export const downloadCollectionAssetsError = err => ({
  type: ACTIONS.DOWNLOAD_COLLECTION_ASSETS.FAILURE,
  payload: err,
});

export const getCollectionAssetDownloadStatusStart = () => ({
  type: ACTIONS.GET_COLLECTION_ASSET_DOWNLOAD_STATUS.REQUEST,
});

export const getCollectionAssetDownloadStatusSuccess = data => ({
  type: ACTIONS.GET_COLLECTION_ASSET_DOWNLOAD_STATUS.SUCCESS,
  payload: data,
});

export const getCollectionAssetDownloadStatusError = err => ({
  type: ACTIONS.GET_COLLECTION_ASSET_DOWNLOAD_STATUS.FAILURE,
  payload: err,
});

export const openCollectionSettingsModal = () => ({ type: ACTIONS.OPEN_COLLECTION_SETTINGS_MODAL });

export const closeCollectionSettingsModal = () => ({ type: ACTIONS.CLOSE_COLLECTION_SETTINGS_MODAL });

export const clearCollections = () => ({ type: ACTIONS.CLEAR_COLLECTIONS });

export const patchCollectionStart = () => ({
  type: ACTIONS.PATCH_COLLECTION.REQUEST,
});

export const patchCollectionSuccess = data => ({
  type: ACTIONS.PATCH_COLLECTION.SUCCESS,
  payload: data,
});

export const patchCollectionError = err => ({
  type: ACTIONS.PATCH_COLLECTION.FAILURE,
  payload: err,
});

export const patchCollectionsStart = () => ({
  type: ACTIONS.PATCH_COLLECTIONS.REQUEST,
});

export const patchCollectionsSuccess = () => ({
  type: ACTIONS.PATCH_COLLECTIONS.SUCCESS,
});

export const patchCollectionsError = err => ({
  type: ACTIONS.PATCH_COLLECTIONS.FAILURE,
  payload: err,
});

export const deleteCollectionsStart = () => ({
  type: ACTIONS.DELETE_COLLECTIONS.REQUEST,
});

export const deleteCollectionsSuccess = data => ({
  type: ACTIONS.DELETE_COLLECTIONS.SUCCESS,
  payload: data,
});

export const deleteCollectionsError = err => ({
  type: ACTIONS.DELETE_COLLECTIONS.FAILURE,
  payload: err,
});

export const getCollectionsStart = () => ({
  type: ACTIONS.GET_COLLECTIONS.REQUEST,
});

export const getCollectionsSuccess = payload => ({
  type: ACTIONS.GET_COLLECTIONS.SUCCESS,
  payload,
});

export const getCollectionsError = err => ({
  type: ACTIONS.GET_COLLECTIONS.FAILURE,
  payload: err,
});

export const getCollectionStart = () => ({
  type: ACTIONS.GET_COLLECTION.REQUEST,
});

export const getCollectionSuccess = payload => ({
  type: ACTIONS.GET_COLLECTION.SUCCESS,
  payload,
});

export const getCollectionError = err => ({
  type: ACTIONS.GET_COLLECTION.FAILURE,
  payload: err,
});

export const postNewCollectionStart = () => ({
  type: ACTIONS.POST_NEW_COLLECTION.REQUEST,
});

export const postNewCollectionSuccess = () => ({
  type: ACTIONS.POST_NEW_COLLECTION.SUCCESS,
});

export const postNewCollectionError = err => ({
  type: ACTIONS.POST_NEW_COLLECTION.FAILURE,
  payload: err,
});

export const postAssetsToCollectionsStart = () => ({
  type: ACTIONS.POST_ASSETS_TO_COLLECTIONS.REQUEST,
});

export const postAssetsToCollectionsSuccess = () => ({
  type: ACTIONS.POST_ASSETS_TO_COLLECTIONS.SUCCESS,
});

export const postAssetsToCollectionsError = err => ({
  type: ACTIONS.POST_ASSETS_TO_COLLECTIONS.FAILURE,
  payload: err,
});

export const openCollectionModal = () => {
  return { type: ACTIONS.OPEN_COLLECTION_MODAL };
};

export const closeCollectionModal = () => {
  return { type: ACTIONS.CLOSE_COLLECTION_MODAL };
};

export const setCollectionDisplayType = displayType => {
  return {
    type: ACTIONS.SET_COLLECTION_DISPLAY_TYPE,
    payload: displayType,
  };
};

export const selectAllCollections = () => {
  return {
    type: ACTIONS.SELECT_ALL_COLLECTIONS,
  };
};

export const unselectAllCollections = () => {
  return {
    type: ACTIONS.UNSELECT_ALL_COLLECTIONS,
  };
};

export const selectCollection = id => {
  return {
    type: ACTIONS.SELECT_COLLECTION,
    payload: id,
  };
};

export const unSelectCollection = id => {
  return {
    type: ACTIONS.UNSELECT_COLLECTION,
    payload: id,
  };
};
