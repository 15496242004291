import React from 'react';
import PropTypes from 'prop-types';
import './edit-checkbox.sass';

const EditCheckbox = ({
  id,
  name,
  onChange,
  onBlur,
  onFocus,
  value,
  placeholder,
  label,
  labelUrl,
  errorMsg,
  touched,
  disabled,
  onClickTerms,
  ...props
}) => {
  const errorClasses = errorMsg && touched ? 'tint-edit-checkbox-container--error' : '';

  const isClickable = labelUrl || onClickTerms;
  return (
    <div
      className={`tint-edit-checkbox-container ${errorClasses} ${
        disabled ? 'tint-edit-checkbox-container--disabled' : ''
      }`}>
      <label htmlFor={id}>
        <input
          id={id}
          name={name}
          onChange={e => !disabled && onChange(e)}
          onBlur={onBlur}
          onFocus={onFocus}
          value={value}
          checked={value}
          placeholder={placeholder}
          type='checkbox'
          required
          {...props}
        />
        <span className='tint-edit-checkbox-container__label'>
          <span className={`checkbox-label-text ${isClickable ? 'checkbox-label-text--clickable' : ''}`}>
            {labelUrl ? (
              <a rel='noopener noreferrer' target='_blank' href={labelUrl}>
                {label}
              </a>
            ) : onClickTerms ? (
              <a
                onClick={e => {
                  e.preventDefault();
                  onClickTerms();
                }}>
                {label}
              </a>
            ) : (
              <>{label}</>
            )}
          </span>
        </span>
      </label>
    </div>
  );
};

EditCheckbox.propTypes = {
  label: PropTypes.string,
  labelUrl: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  onChange: PropTypes.func,
  onClickTerms: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  errorMsg: PropTypes.string,
};

export default EditCheckbox;
