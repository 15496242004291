import React, { Component } from 'react';
import './timer-section.sass';
import { CountDown } from './count-down/count-down';
import { EditTimerContent } from './edit/edit-timer-content';
import { EditTimerStyles } from './edit/edit-timer-styles';
import { EditModalSwitcher } from '../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher';
import { EditModal } from '../../../../../../components/edit-modal/edit-modal/edit-modal';
import { PageSectionHoc } from '../../../../../../components/HOC/page-section/page-section.hoc';
import { EditModalStep } from '../../../../../../components/edit-modal/edit-modal-step/edit-modal-step';
import ColorParser from '../../../../../../services/color-parser/color-parser';
import { dropDownType } from '../../../../../../model/poll-image-type.model';

const currentDate = new Date();
const today = new Date(currentDate.setDate(currentDate.getDate()));

class TimerSection extends Component {
  getClassBasedOnWidth() {
    if (this.props.isPreviewMode) {
      return '';
    }
    return `tint-page-section--timer--${this.props.baseClass}`;
  }

  isCountDownFinished = () => {
    return new Date(this.props.data.content.endDate).getTime() - Date.now() <= 0;
  };

  getDateFormat(date) {
    const dateObject = new Date(date);
    return dateObject.toLocaleString(
      this.props.data.content.dateType === undefined || this.props.data.content.dateType === dropDownType.US
        ? 'en-US'
        : 'en-GB',
      {
        hour: 'numeric',
        minute: 'numeric',
        month: 'numeric',
        day: 'numeric',
        year: 'numeric',
      }
    );
  }

  render() {
    return (
      <div
        className={`tint-page-section tint-page-section--timer ${this.props.getSectionClass()} ${this.getClassBasedOnWidth()}`}
        style={{
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`,
        }}>
        {this.props.renderGenericComponents()}
        {this.props.data.content.fixedTitle ? (
          <span
            className='tint-page-section--timer__time-left'
            style={{
              color: ColorParser.defaultColor(this.props.data.styles.headingTextColor),
            }}>
            {this.props.data.content.fixedTitle}
          </span>
        ) : null}
        <div className='timer-count-down-container'>
          <CountDown
            textDirection={this.props.textDirection}
            endDate={this.props.data.content.endDate}
            styles={this.props.data.styles}
            content={this.props.data.content}
            isCountDownFinished={this.isCountDownFinished}
            getTranslation={this.props.getTranslation}
          />
          {this.isCountDownFinished() || !this.props.getTranslation(this.props.data.content.endTitle) ? null : (
            <span
              className='timer-count-down-container__end-title'
              style={{
                color: ColorParser.defaultColor(this.props.data.styles.labelTextColor),
              }}>
              {this.props.getTranslation(this.props.data.content.endTitle)}{' '}
              {this.getDateFormat(this.props.data.content.endDate)}
            </span>
          )}
        </div>
        {this.props.isModalOpen && (
          <EditModal
            isVisible={this.props.isModalOpen}
            saveData={this.props.onDataSave}
            dismissModal={this.props.dismissModal}
            closeModal={this.props.closeModal}>
            <EditModalStep title='Edit Timer'>
              <EditModalSwitcher>
                <EditTimerContent
                  data={this.props.data.content}
                  dataUpdate={this.props.onContentUpdate}
                  minDate={today}
                  getTranslation={this.props.getTranslation}
                  onTranslationUpdate={this.props.onTranslationUpdate}
                />
                <EditTimerStyles
                  data={this.props.data}
                  saveData={this.props.onDataSave}
                  dataUpdate={this.props.onContentUpdate}
                  styleDataUpdate={this.props.onStylesUpdate}
                />
              </EditModalSwitcher>
            </EditModalStep>
          </EditModal>
        )}
      </div>
    );
  }
}

export default PageSectionHoc(TimerSection);
