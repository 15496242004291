import React, { Fragment, useEffect } from 'react';
import './form-builder.form.interface.choice.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE, FORM_BUILDER_INPUT_CLASSES } from '../../../../utils/form-builder.types';
import { Config } from '../../../../../../utils/config';

export const FormBuilderFormInterfaceChoice = ({
  styles,
  item,
  formId,
  styleElementFieldset,
  styleElementLegend,
  styleElementInput,
  styleElementLabel,
  values,
}) => {
  if (!item) return null;

  const { t } = useTranslation();
  const elements = item.elements;

  const legend = elements?.find(el => el?.nodeName === 'legend');
  const input = elements?.find(el => el?.nodeName === 'input');

  const isSingleChoice = item.class.includes(FORM_BUILDER_INPUT_CLASSES.SINGLE_CHOICE);
  const isDevEnv = Config.isDevEnv();
  const defaultValues = values?.getAll(input.name);

  const onFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
  };

  useEffect(() => {
    if (!isSingleChoice && input?.required) {
      const formDiv = document.querySelector(`[data-form-id="${formId}"] div`);
      const root = isDevEnv ? document : formDiv?.shadowRoot;
      const firstInvalidInputHTML = root?.getElementById(input.id);
      firstInvalidInputHTML?.setCustomValidity('Please tick any box if you want to proceed.');
    }
  }, [input?.id, input?.required, isSingleChoice]);

  const onChange = () => {
    const formDiv = document.querySelector(`[data-form-id="${formId}"] div`);
    const root = isDevEnv ? document : formDiv?.shadowRoot;

    const inputs = elements.filter(el => el?.nodeName === 'input');
    const inputsArr = [];
    inputs.forEach(el => {
      const inputHTML = root?.getElementById(el.id);
      inputsArr.push(inputHTML);
    });

    const firstInvalidInputHTML = root?.getElementById(input.id);
    const isAnyInputSelected = inputsArr?.some(el => el?.checked === true);
    if (isAnyInputSelected) {
      firstInvalidInputHTML?.setCustomValidity('');
    } else {
      firstInvalidInputHTML?.setCustomValidity('Please tick any box if you want to proceed.');
    }
  };

  const currentOnChangeHandler = (() => {
    if (!isSingleChoice && input?.required) {
      return onChange;
    }
    return () => {};
  })();

  return (
    <div className={item.class} style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS]}>
      <fieldset style={{ ...styleElementFieldset }} className={input?.required ? `${item.class}--required` : ''}>
        <legend style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.LABEL], ...styleElementLegend }}>
          {`${t(legend?.nodeValue)}${input?.required ? '*' : ''}`}
        </legend>
        {elements
          .filter(el => (el?.nodeName?.includes('input') && el?.type === 'radio') || el?.type === 'checkbox')
          .map((el, index) => {
            const labelNodeValue = elements.find(label => label?.for === el?.id)?.nodeValue;

            return (
              <Fragment key={index}>
                <input
                  type={input?.type}
                  name={el?.name}
                  id={el?.id}
                  defaultChecked={defaultValues?.indexOf(el?.value) >= 0}
                  onFocus={onFocus}
                  onChange={currentOnChangeHandler}
                  value={el?.value}
                  required={isSingleChoice ? input?.required : false}
                  style={{ ...styleElementInput }}
                />
                <label htmlFor={el?.id} style={{ ...styleElementLabel }}>
                  {t(labelNodeValue)}
                </label>
              </Fragment>
            );
          })}
      </fieldset>
    </div>
  );
};
