import React, { useEffect, useRef, useState } from 'react';
import * as PropTypes from 'prop-types';
import ChromePicker from 'react-color/lib/Chrome';
import './color-picker.sass';
import Color from '../../../../services/color-parser/color-parser';
import { Config } from '../../../../utils/config';

// DEPRECATED Please use the color-picker-new component instead

const ColorPicker = ({ color, onColorChange, isAlphaShow = true, noInputs = false }) => {
  const elementRef = useRef();
  const [currentColorHex, setCurrentColorHex] = useState('');
  const [currentAlpha, setCurrentAlpha] = useState('');

  const defaultAlphaValue = 1;

  useEffect(() => {
    setCurrentColorHex(Color.RGBAToHex(color));
    setAlpha();
  }, []);

  const setAlpha = () => {
    if (!color) return;

    if (Config.regExp.hexColorPattern.test(color)) {
      setCurrentAlpha(defaultAlphaValue);
    } else {
      const rgba = color.replace(Config.regExp.rgbaColorPattern, '').split(',');
      return rgba && rgba[3] ? setCurrentAlpha(rgba[3]) : setCurrentAlpha(defaultAlphaValue);
    }
  };

  const onChange = color => {
    setCurrentColorHex(Color.RGBAToHex(color));
    if (Config.regExp.hexColorPattern.test(color) && color && isAlphaShow) {
      onColorChange(Color.hexToRGBAString(color, currentAlpha));
    } else {
      onColorChange(color);
      setCurrentColorHex(color);
      setCurrentAlpha(currentAlpha);
    }
  };

  return (
    <div ref={elementRef} className='tint-color-picker'>
      <div onMouseDown={e => e.preventDefault()}>
        <ChromePicker
          color={color}
          disableAlpha={!isAlphaShow}
          onChange={color => {
            onColorChange(color.rgb);
            setCurrentColorHex(color.hex);
            setCurrentAlpha(color.rgb.a);
          }}
        />
      </div>
      {!noInputs ? (
        <div className='tint-color-picker__input-wrapper'>
          <input
            className='input-color-picker input-color-picker--hex'
            type='text'
            onChange={e => onChange(e.target.value)}
            id={'inputId'}
            value={currentColorHex}
          />
          {isAlphaShow && (
            <input
              className='input-color-picker input-color-picker--alpha'
              type='text'
              id={'inputAlpha'}
              defaultValue={currentAlpha}
              readOnly={true}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};

export default ColorPicker;

ColorPicker.propTypes = {
  onColorChange: PropTypes.func,
  color: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};
