import { formBuilderFormReducer } from './builder/form-builder.builder.form.reducer';
import { formBuilderReducer } from './builder/form-builder.builder.reducer';
import { formBuilderListReducer } from './list/form-builder-list.reducer';
import { formBuilderSubmissionBuilderReducer } from './builder/form-builder-submission.builder.reducer';

const formBuilderReducers = {
  formBuilderForm: formBuilderFormReducer,
  formBuilder: formBuilderReducer,
  formBuilderList: formBuilderListReducer,
  formBuilderSubmission: formBuilderSubmissionBuilderReducer,
};

export default formBuilderReducers;
