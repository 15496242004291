import React, { Component } from 'react';
import './form-builder-section.sass';
import { ConnectFormBuilderBox } from './connect-form-builder-box/connect-form-builder-box';
import { EditFormBuilderContent } from './edit/edit-form-builder-content';
import { EditFormBuilderStyles } from './edit/edit-form-builder-styles';
import { PageSectionHoc } from '../../../../../../components/HOC/page-section/page-section.hoc';
import { EditModalSwitcher } from '../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher';
import { EditModal } from '../../../../../../components/edit-modal/edit-modal/edit-modal';
import { EditModalStep } from '../../../../../../components/edit-modal/edit-modal-step/edit-modal-step';
import ColorParser from '../../../../../../services/color-parser/color-parser';
import { connect } from 'react-redux';
import { Config } from '../../../../../../utils/config';

class FormBuilderSection extends Component {
  constructor(props, context) {
    super(props, context);
  }

  initFormBuilderScript() {
    let src = `${Config.getEnvironmentVariable('BASE_URL')}app/dist/tint.js`;

    // Check if the script already exists
    const existingScripts = document.getElementsByTagName('script');
    let scriptExists = false;
    for (let i = 0; i < existingScripts.length; i++) {
      if (existingScripts[i].src === src) {
        scriptExists = true;
        break;
      }
    }

    if (!scriptExists) {
      // If the script does not exist, create and append it
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        window?.Tint?.FormBuilder?.renderForms?.();
      };
      document.body.appendChild(script);
    } else {
      window?.Tint?.FormBuilder?.renderForms?.();
    }
  }

  reRenderFormBuilder() {
    if (document.querySelector("script[src*='/app/dist/tint.js']")) {
      document.querySelector("script[src*='/app/dist/tint.js']").remove();
      this.initFormBuilderScript();
    }
  }

  componentDidMount() {
    this.initFormBuilderScript();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data.content.formBuilderId !== this.props.data.content.formBuilderId) {
      this.reRenderFormBuilder();
    }
  }

  getRectangleBox(isSmallFirst) {
    return (
      <div className='rectangle-container__rectangle-box'>
        <div className={`rectangle ${isSmallFirst ? 'rectangle--small' : ''}`} />
        <div className={`rectangle ${!isSmallFirst ? 'rectangle--small' : ''}`} />
      </div>
    );
  }

  renderContent() {
    return (
      <div className='rectangle-container'>
        <ConnectFormBuilderBox onClick={this.props.openModal.bind(this)} text='+ Connect Form Builder' />
        {this.getRectangleBox(true)}
        {this.getRectangleBox(false)}
        {this.getRectangleBox(true)}
        {this.getRectangleBox(false)}
        {this.getRectangleBox(true)}
      </div>
    );
  }

  renderFormBuilder = () => {
    return (
      <div
        id='tint-form-builder'
        data-team-id={this.props.data.content.teamId}
        data-form-id={this.props.data.content.formBuilderId}></div>
    );
  };

  render() {
    return (
      <div
        dir='auto'
        className={`tint-page-section tint-page-section--form-builder ${this.props.getSectionClass()}`}
        style={{
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`,
          display: !this.props.data.content.formBuilderId && this.props.isPreviewMode ? 'none' : '',
        }}>
        {this.props.renderGenericComponents()}
        {this.props.isModalOpen && (
          <EditModal
            isVisible={this.props.isModalOpen}
            saveData={this.props.onDataSave}
            closeModal={this.props.closeModal}
            dismissModal={this.props.dismissModal}>
            <EditModalStep title='Edit Form'>
              <EditModalSwitcher>
                <EditFormBuilderContent
                  updateForm={this.onDataSave}
                  data={this.props.data}
                  dataUpdate={this.props.onContentUpdate}
                />
                <EditFormBuilderStyles
                  data={this.props.data}
                  saveData={this.props.onDataSave}
                  dataUpdate={this.props.onContentUpdate}
                  styleDataUpdate={this.props.onStylesUpdate}
                />
              </EditModalSwitcher>
            </EditModalStep>
          </EditModal>
        )}
        {this.props.data.content.formBuilderId ? this.renderFormBuilder() : this.renderContent()}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  session: state.session.data,
});

export default connect(mapStateToProps)(PageSectionHoc(FormBuilderSection));
