import { COMMON_SETTINGS } from './common-settings';
import { languageList } from '../configs/personalization-languages';

export const ATMOSPHERE = {
  name: 'Atmosphere',
  key: 'new_atmosphere',
  premiumDesign: true,
  default_settings: {
    ...COMMON_SETTINGS,
    themeName: 'new_atmosphere',
    post_duration: '10000',
    atmosphere_blip_colors: ['#ff564b', '#ff6c54', '#2fc2c7', '#50d3d8', '#d74743'],
  },
  guards: {
    CTA: false,
    waterfall: false,
    popup: false,
    shareIcons: false,
    removeDuplicateImages: true,
    genericAvatarBackgroundColor: true,
    genericAvatarTextColor: true,
    customCSS: true,
    backgroundImage: true,
    banner: true,
  },
  font_settings: [
    {
      key: 'fontcolor_post',
      label: 'Font Color',
      type: 'colorPicker',
    },
  ],
  themes_settings: [
    {
      key: 'time_language',
      type: 'dropdown',
      label: 'Language for post timestamp',
      options: languageList,
    },
    {
      key: 'atmosphere_blip_colors',
      type: 'dropdownColorPalette',
      label: 'Atmosphere colors',
      options: [
        ['#ff564b', '#ff6c54', '#2fc2c7', '#50d3d8', '#d74743'],
        ['#d74743', '#fdb35c', '#5bc36f', '#4598ba', '#a36ec9'],
        ['#000000', '#3a3a3a', '#6e6e6e', '#a0a0a0', '#bbbbbb'],
        ['#244c21', '#397f31', '#78aa5d', '#c9de8e', '#b2ca69'],
        ['#345b62', '#55797f', '#a9daaa', '#7bbc9b', '#3e8685'],
        ['#c52733', '#e13441', '#f3644f', '#fd9c61', '#facc84'],
        ['#19999e', '#54cdc3', '#b5ea61', '#fd6c6e', '#c24e5a'],
        ['#34132f', '#424253', '#65908a', '#e7caa6', '#ca2c44'],
        ['#6dd2e6', '#a9dbd8', '#c8e2d3', '#f1863c', '#f86920'],
      ],
    },
    {
      key: 'post_duration',
      label: 'Post Duration',
      type: 'dropdown',
      options: [
        {
          value: '4000',
          name: '4 seconds',
        },
        {
          value: '6000',
          name: '6 seconds',
        },
        {
          value: '8000',
          name: '8 seconds',
        },
        {
          value: '10000',
          name: '10 seconds',
        },
        {
          value: '15000',
          name: '15 seconds',
        },
        {
          value: '30000',
          name: '30 seconds',
        },
        {
          value: '60000',
          name: '60 seconds',
        },
      ],
    },
  ],
  extra_settings: [],
  extra_popup_settings: [],
};
