export const BROADCAST_MESSAGE_TYPE = {
  LOGOUT: 'LOGOUT',
  UPDATE_SESSION_STORAGE: 'UPDATE_SESSION_STORAGE',
  NEW_TAB: 'NEW_TAB',
};

const init = () => {
  if (BroadcastChannel) {
    return new BroadcastChannel('tint');
  }
  return { init: () => {}, on: () => {}, postMessage() {} };
};

export const BroadcastChannelService = {
  init,
};
