import { ACTIONS } from '../actions/tint-mix.actions';
import produce from 'immer';
import { defaultTintMixName } from '../components/tint-mix-scene-navigation-bar/tint-mix-scene-navigation-bar';
import { TintMixGridTemplates } from '../components/tint-mix-grid-template/tint-mix-grid-template';
import mapGridTemplateToElement from '../components/tint-mix-grid-template/tint-mix-grid-template.mapper';

const initialState = {
  data: undefined,
  links: undefined,
  currentTintMix: undefined,
  currentTintMixUI: undefined,
  isPending: false,
  scenes: undefined,
  selectedMixes: [],
};

export const tintMix = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.POST_MIX.REQUEST:
    case ACTIONS.GET_MIX.REQUEST:
    case ACTIONS.GET_MIXES.REQUEST:
    case ACTIONS.BULK_DELETE_MIXES.REQUEST:
      return {
        ...state,
        isPending: true,
      };

    case ACTIONS.PATCH_MIX.FAILURE:
    case ACTIONS.DELETE_MIX.FAILURE:
    case ACTIONS.POST_MIX.FAILURE:
    case ACTIONS.GET_MIX.FAILURE:
    case ACTIONS.GET_MIXES.FAILURE:
    case ACTIONS.BULK_DELETE_MIXES.FAILURE:
      return {
        ...state,
        isPending: false,
      };

    case ACTIONS.SET_NEW_MIX:
      return produce(state, draft => {
        draft.currentTintMixUI = {
          attributes: {
            name: defaultTintMixName,
            scenes: [
              {
                areas: {
                  a: [],
                },
                template: TintMixGridTemplates.FULL_SCREEN,
              },
            ],
          },
        };
      });

    case ACTIONS.SET_CURRENT_MIX_UI:
      return produce(state, draft => {
        draft.currentTintMixUI = {
          ...action.payload,
          attributes: {
            ...action.payload.attributes,
            scenes: action.payload.attributes.scenes.map(scene => {
              return {
                ...scene,
                areas: Object.keys(scene.areas)
                  .map(key => {
                    return {
                      [key]: [
                        ...scene.areas[key].map(area => {
                          return {
                            ...area,
                            defaultAreaKey: key,
                          };
                        }),
                      ],
                    };
                  })
                  .reduce((a, i) => {
                    return { ...a, ...i };
                  }, {}),
              };
            }),
          },
        };
      });

    case ACTIONS.CLEAR_CURRENT_MIX_UI:
      return produce(state, draft => {
        draft.currentTintMixUI = undefined;
        draft.currentTintMix = undefined;
      });

    case ACTIONS.GET_MIXES.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        links: action.payload.links,
        isPending: false,
      };

    case ACTIONS.PATCH_MIX.SUCCESS:
      return produce(state, draft => {
        const updatedMixId = action.payload.id;
        draft.data = draft.data
          ? draft.data.map(mix => (mix.id === updatedMixId ? action.payload : mix))
          : (draft.data = [action.payload]);
        draft.isPending = false;
      });

    case ACTIONS.RENAME_TINT_MIX:
      return produce(state, draft => {
        let currentTintMix = draft.data && draft.data.find(tintMix => tintMix.id === action.payload.id);
        currentTintMix && currentTintMix.attributes.name === action.payload.name;
        draft.currentTintMixUI.attributes.name = action.payload.name;
      });

    case ACTIONS.POST_MIX.SUCCESS:
      return produce(state, draft => {
        draft.data = [...draft.data, action.payload];
        draft.currentTintMix = action.payload;
        draft.currentTintMixUI = action.payload;
        draft.isPending = false;
      });

    case ACTIONS.DELETE_MIX.SUCCESS:
      return produce(state, draft => {
        draft.data = draft.data.filter(mix => mix.id !== action.payload);
        draft.isPending = false;
      });

    case ACTIONS.GET_MIX.SUCCESS:
      return {
        ...state,
        isPending: false,
        currentTintMixUI: {
          ...action.payload,
        },
        currentTintMix: {
          ...action.payload,
        },
      };

    case ACTIONS.CHANGE_SCENE_LAYOUT:
      return produce(state, draft => {
        draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].layout = undefined;
        draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].template = action.payload.template;

        const itemList = Object.values(
          draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas
        ).flat();
        const sections = mapGridTemplateToElement(action.payload.template);
        const currentAreas = Object.keys(draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas);
        const inactiveAreas = currentAreas.filter(item => !sections.includes(item));
        const unAssignItems = inactiveAreas
          .map(key => {
            return itemList.filter(areaItem => areaItem.defaultAreaKey === key);
          })
          .flat();

        draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas = sections
          .map((key, i) => {
            if (i === 0) {
              return {
                [key]: [...itemList.filter(areaItem => areaItem.defaultAreaKey === key), ...unAssignItems],
              };
            } else {
              return {
                [key]: itemList.filter(areaItem => areaItem.defaultAreaKey === key),
              };
            }
          })
          .reduce((a, b) => ({ ...a, ...b }));
      });

    case ACTIONS.ADD_NEW_SCENE:
      return produce(state, draft => {
        draft.currentTintMixUI.attributes.scenes.splice(action.payload + 1, 0, {
          template: TintMixGridTemplates.FULL_SCREEN,
          areas: { a: [] },
        });
      });

    case ACTIONS.DELETE_SCENE:
      return produce(state, draft => {
        draft.currentTintMixUI.attributes.scenes = draft.currentTintMixUI.attributes.scenes.filter(
          (scene, i) => i !== action.payload
        );
      });

    case ACTIONS.ADD_NEW_AREA_ITEM:
      return produce(state, draft => {
        const currentArea =
          draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas[action.payload.area];

        const areaContent = {
          type: action.payload.contentType,
          ...action.payload.data,
          tint_slug: action.payload.data.tintSlug,
          tint_id: action.payload.data.tintId,
          personalization_id: action.payload.data.personalizationId,
          defaultAreaKey: action.payload.area,
        };

        const area = {
          [action.payload.area]: [areaContent],
        };

        currentArea
          ? currentArea.push(areaContent)
          : (draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas = {
              ...draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas,
              ...area,
            });
      });

    case ACTIONS.UPDATE_AREA_ITEM:
      return produce(state, draft => {
        const currentItem =
          draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas[action.payload.area][
            action.payload.areaIndex
          ];

        draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas[action.payload.area][
          action.payload.areaIndex
        ] = {
          ...currentItem,
          ...action.payload.data,
          tintId: undefined,
          personalizationId: undefined,
          tintSlug: undefined,
          tint_slug: action.payload.data?.tintSlug || currentItem.tint_slug,
          tint_id: action.payload.data?.tintId || currentItem.tint_id,
          personalization_id: action.payload.data?.personalizationId || currentItem.personalization_id,
        };
      });

    case ACTIONS.REMOVE_AREA_ITEM:
      return produce(state, draft => {
        draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas[
          action.payload.area
        ] = draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas[action.payload.area].filter(
          (item, index) => index !== action.payload.index
        );
      });

    case ACTIONS.REORDER_SCENE:
      return produce(state, draft => {
        draft.currentTintMixUI.attributes.scenes = action.payload;
      });

    case ACTIONS.REORDER_AREA_ITEM:
      return produce(state, draft => {
        draft.currentTintMixUI.attributes.scenes[action.payload.sceneIndex].areas = action.payload.areas;
      });

    case ACTIONS.UPDATE_TINT_MIX_UI:
      return produce(state, draft => {
        draft.currentTintMix = {
          ...draft.currentTintMixUI,
          attributes: {
            ...draft.currentTintMixUI.attributes,
            scenes: draft.currentTintMixUI.attributes.scenes.map(scene => ({
              ...scene,
              areas: Object.keys(scene.areas)
                .map(key => {
                  return {
                    [key]: [
                      ...scene.areas[key].map(area => {
                        return {
                          ...area,
                          index: undefined,
                          defaultAreaKey: undefined,
                          sceneIndex: undefined,
                          column: undefined,
                          elementId: undefined,
                          isValid: undefined,
                        };
                      }),
                    ],
                  };
                })
                .reduce((a, i) => {
                  return { ...a, ...i };
                }, {}),
            })),
          },
        };
      });

    case ACTIONS.BULK_DELETE_MIXES.SUCCESS:
      return produce(state, draftState => {
        draftState.data = state.data.filter(mix => {
          return !action.payload.find(m => {
            return m.id === mix.id;
          });
        });
        draftState.isPending = false;
        draftState.isError = false;
        draftState.selectedMixes = [];
      });

    case ACTIONS.SELECT_MIX:
      return produce(state, draft => {
        draft.selectedMixes = [...draft.selectedMixes, action.payload];
      });

    case ACTIONS.UNSELECT_MIX:
      return produce(state, draft => {
        draft.selectedMixes = draft.selectedMixes.filter(({ id }) => {
          return action.payload !== id;
        });
      });

    case ACTIONS.DESELECT_ALL_MIXES:
      return produce(state, draft => {
        draft.selectedMixes = [];
      });

    default:
      return state;
  }
};

export const getCurrentTintMix = state => {
  return state?.tintMix?.currentTintMix;
};

export const getAllTintMixes = state => {
  return state?.tintMix?.data;
};

export const getAreaDuration = areas => {
  if (!areas) return {};
  return areas.reduce(function(prev, current) {
    return prev + +current.duration;
  }, 0);
};

export const getSumAreasDuration = areas => {
  if (!areas) return {};

  const getAreaItemsDurationTime = Object.keys(areas).map(key => {
    return areas[key].reduce((prev, current) => {
      return prev + +current.duration;
    }, 0);
  });

  const getTheBiggestValue = Math.max(...getAreaItemsDurationTime);

  return getTheBiggestValue;
};

export const getScenes = state => {
  return (
    (state.tintMix.currentTintMixUI && state.tintMix.currentTintMixUI.attributes.scenes) || [
      {
        template: "'a'",
        areas: {
          a: [],
          b: [
            {
              fit: 'contain',
              url: 'https://cdn.filestackcontent.com/xb32BsyRVKwaNtiln1gg',
              name: 'file',
              type: 'video',
              duration: 15,
            },
          ],
        },
      },
    ]
  );
};

export const getUniqueTemplatesSelector = state => {
  const usedTemplates = (state.tintMix.currentTintMix && state.tintMix.currentTintMix.attributes.scenes)
    .map(scene => scene.template)
    .filter(Boolean);

  return [...new Set([...Object.values(TintMixGridTemplates), ...usedTemplates])];
};
