import { ACTIONS } from '../../actions/product-feeds/product-details-modal.actions';

export const initialState = {
  opened: false,
  productIndex: undefined,
};

export const productDetailsModal = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_MODAL:
      return {
        ...state,
        opened: true,
        productIndex: action.payload,
      };

    case ACTIONS.UPDATE_MODAL:
      return {
        ...state,
        productIndex: action.payload,
      };

    case ACTIONS.CLOSE_MODAL:
      return {
        opened: false,
        productIndex: undefined,
      };
    default:
      return state;
  }
};
