import { SocialFeedsFooterLabels } from './helpers';
import { SidebarIcons, SidebarItemTypes } from '../utils/meta/consts';
import { validationSchemaThreadsBrandContent } from '../utils/validation-schema/threads-schema';
import { GuardNames } from '../../../../../../../guards/guards-list';

export const threads = [
  {
    header: 'Add Threads',
    description: 'Choose a Social Feed type below:',
    typeList: [
      {
        id: 'posted',
        title: 'Branded Feed',
        description: 'This social feed type allows you to access your authorized Threads posts.',
        featureName: GuardNames.SOCIAL_FEEDS_THREADS,
      },
    ],
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepNext,
    },
    zendeskUrl: undefined,
    isAlert: false,
    formSchema: undefined,
  },
  {
    header: undefined,
    description: undefined,
    zendeskUrl: undefined,
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.finalStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext,
    },
    accountSourceType: 'threads',
    formSchema: {
      posted: {
        validationSchema: validationSchemaThreadsBrandContent(),
        footerCopy: '',
        submitMapper: values => ({
          account_id: values.account?.value?.accountId || values.account.value,
          external_id: values.account?.value?.externalId || values.account.externalId,
        }),
        inputs: [
          {
            id: 'account',
            backendId: 'account_id',
            label: 'SELECT ACCOUNT',
            placeholder: 'Select Account...',
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
          },
        ],
      },
    },
  },
];
