import React from 'react';
import './form-builder.form.interface.submit-button.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';

const FormBuilderFormInterfaceSubmitButton = ({ styles, item, disabled }) => {
  if (!item) return null;

  const { t } = useTranslation();
  const submitButtonData = item?.elements;

  const removeUnnecessaryStyles = () => {
    if (styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SUBMIT_BUTTON]?.boxShadow !== 'none') {
      const newStyles = { ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SUBMIT_BUTTON] };
      delete newStyles.backgroundColor;
      return newStyles;
    }
    return styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SUBMIT_BUTTON];
  };

  const onFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
  };

  return (
    <div className={item.class}>
      <button style={removeUnnecessaryStyles()} type='submit' onFocus={onFocus} disabled={disabled}>
        {t(submitButtonData?.find(el => el.nodeName === 'button').nodeValue)}
      </button>
    </div>
  );
};

FormBuilderFormInterfaceSubmitButton.displayName = 'FormBuilderFormInterfaceSubmitButton';
export default React.memo(FormBuilderFormInterfaceSubmitButton);
