const EMAIL_DOMAINS = 'EMAIL_DOMAINS';
const ACCOUNT_SETTINGS = 'ACCOUNT_SETTINGS';
const COLLABORATORS = 'COLLABORATORS';
const PRODUCT_ACCOUNTS = 'PRODUCT_ACCOUNTS';
const PRODUCT_FEEDS = 'PRODUCT_FEEDS';
const PRODUCTS = 'PRODUCTS';
const OAUTH_APPLICATIONS = 'OAUTH_APPLICATIONS';
const WEBHOOKS = 'WEBHOOKS';
const MARKETING_INTEGRATIONS = 'MARKETING_INTEGRATIONS';
const ROLES = 'ROLES';
const CUSTOM_FONTS = 'CUSTOM_FONTS';

const names = {
  EMAIL_DOMAINS,
  ACCOUNT_SETTINGS,
  COLLABORATORS,
  PRODUCT_ACCOUNTS,
  PRODUCT_FEEDS,
  PRODUCTS,
  OAUTH_APPLICATIONS,
  MARKETING_INTEGRATIONS,
  ROLES,
  WEBHOOKS,
  CUSTOM_FONTS,
};

export {
  names as default,
  ACCOUNT_SETTINGS,
  COLLABORATORS,
  PRODUCT_ACCOUNTS,
  PRODUCT_FEEDS,
  PRODUCTS,
  OAUTH_APPLICATIONS,
  MARKETING_INTEGRATIONS,
  ROLES,
  WEBHOOKS,
  EMAIL_DOMAINS,
  CUSTOM_FONTS,
};
