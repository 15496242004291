import { createPrefixedActionName, createRoutine } from '../../services/functions/redux-routines/redux-routines';
import ACCOUNT from '../consts';
import { ApiService } from '../../services/api-service/api-service';
import {
  getPersonalizationError,
  getPersonalizationsError,
  getPersonalizationsStart,
  getPersonalizationsSuccess,
  getPersonalizationStart,
  getPersonalizationSuccess,
  getTintError,
  getTintsError,
  getTintsStart,
  getTintsSuccess,
  getTintStart,
  getTintSuccess,
} from './account.actions';

export const ACTIONS = {
  GET_TINTS: createRoutine(createPrefixedActionName(ACCOUNT, 'GET_TINTS')),
  GET_PERSONALIZATION: createRoutine(createPrefixedActionName(ACCOUNT, 'GET_PERSONALIZATION')),
};

export const getTints = tintsUrl => {
  return dispatch => {
    dispatch(getTintsStart());
    return ApiService.get(tintsUrl || '/tints')
      .then(res => {
        const tints = res.data.data.map(tint => {
          return {
            ...tint,
            ...{
              socialFeedsCount: tint.relationships?.social_feeds?.meta?.total,
            },
          };
        });
        dispatch(
          getTintsSuccess({
            data: tints,
            links: res.data.links,
            included: res.data.included,
          })
        );
      })
      .catch(err => dispatch(getTintsError(err)));
  };
};

export const getTint = (tintId, includedParams = '', forceNoTeamPrefix = false) => {
  return dispatch => {
    dispatch(getTintStart());
    return ApiService.get(`/tints/${tintId}${includedParams}`, { forceNoTeamPrefix })
      .then(res => {
        dispatch(getTintSuccess(res.data.data));
        return res;
      })
      .catch(err => dispatch(getTintError(err)));
  };
};

export const getPersonalizations = ({ teamId, status, filterValue, type, tags, personalizationsUrl }) => {
  return dispatch => {
    dispatch(getPersonalizationsStart());
    return ApiService.get(
      personalizationsUrl ||
        `/teams/${teamId}/personalizations?sort=status,-id&${
          status !== undefined && status !== '' ? `filter[status]=${status}&` : ''
        }${filterValue !== undefined && filterValue !== '' ? `filter[q]=${filterValue}&` : ''}${
          type !== undefined && type !== '' ? `filter[type]=${type}&` : ''
        }${tags !== undefined && tags !== '' ? `filter[tags]=${tags}&` : ''}`
    )
      .then(res => {
        dispatch(getPersonalizationsSuccess(res.data));
      })
      .catch(err => {
        dispatch(getPersonalizationsError(err));
      });
  };
};

export const getPersonalization = ({ id }) => {
  return dispatch => {
    dispatch(getPersonalizationStart());
    return ApiService.get(`/personalizations/${id}`)

      .then(res => {
        dispatch(getPersonalizationSuccess(res.data.data));

        return res.data.data;
      })
      .catch(err => {
        dispatch(getPersonalizationError(err));
      });
  };
};
