import { Config } from '../../../../../../../../utils/config';

const getYouTubeUrl = (mediaUrl, hasAutoplay) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = mediaUrl.match(regExp);
  const id = match && match[2].length === 11 ? match[2] : null;
  return `https://www.youtube.com/embed/${id}${hasAutoplay ? '?autoplay=1&mute=1' : ''}`;
};

const getVimeoUrl = (mediaUrl, hasAutoplay) => {
  let vimeoRegex = /(?:vimeo)\.com.*(?:videos|video|channels|)\/([\d]+)/i;
  let parsed = mediaUrl.match(vimeoRegex);
  const id = parsed[1];
  return `https://player.vimeo.com/video/${id}${hasAutoplay ? '?autoplay=1&mute=1' : ''}`;
};

const getFacebookUrl = (mediaUrl, hasAutoplay) => {
  let url = /^http(?:s?):\/\/(?:www\.|web\.|m\.)?facebook\.com\/([A-z0-9.]+)\/videos(?:\/[0-9A-z].+)?\/(\d+)(?:.+)?$/;
  return url.test(mediaUrl)
    ? `https://www.facebook.com/plugins/video.php?href=${mediaUrl}&width=1600&height=900${
        hasAutoplay ? '&autoplay=1' : ''
      }`
    : mediaUrl + `&width=1600&height=900${hasAutoplay ? '&autoplay=1&mute=1' : ''}`;
};

const getTwitchUrl = (mediaUrl, hasAutoplay, parentLocation = Config.twitchParentUrl()) => {
  const twitchRegex = mediaUrl.match(Config.regExp.video.twitch);

  if (twitchRegex && twitchRegex.length >= 3) {
    return twitchRegex[1] === undefined
      ? `https://player.twitch.tv/?autoplay=${hasAutoplay}&channel=${twitchRegex[2]}&parent=${parentLocation}`
      : `https://player.twitch.tv/?autoplay=${hasAutoplay}&video=${twitchRegex[2]}&parent=${parentLocation}`;
  }
};

const getTikTokUrl = (mediaUrl, hasAutoplay) => {
  const tikTokRegex = mediaUrl.match(Config.regExp.video.tikTok);

  if (tikTokRegex && tikTokRegex[1]) {
    return `https://www.tiktok.com/embed/${tikTokRegex[1]}${hasAutoplay ? '?autoplay=1' : ''}`;
  }
};

const isYouTube = url => Config.regExp.video.youtube.test(url);

const isFacebook = url => Config.regExp.video.facebook.test(url);

const isVimeo = url => Config.regExp.video.vimeo.test(url);

const isTwitch = url => Config.regExp.video.twitch.test(url);

const isTikTok = url => Config.regExp.video.tikTok.test(url);

export const VideoRendererService = {
  getFacebookUrl,
  getVimeoUrl,
  getYouTubeUrl,
  getTwitchUrl,
  getTikTokUrl,
  isYouTube,
  isFacebook,
  isVimeo,
  isTwitch,
  isTikTok,
};
