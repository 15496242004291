import React from 'react';
import './submission-success.sass';

export const SubmissionSuccess = ({ title, description, successMessageLinks, getTranslation }) => {
  const renderSuccessMessageLinks = () => {
    return successMessageLinks.map((e, i) => {
      return (
        <a
          rel='noopener noreferrer'
          key={i}
          href={getTranslation(e.buttonUrl)}
          target='_blank'
          className='tint-submission-success'>
          <span>{getTranslation(e.buttonText) || 'Button text'}</span>
        </a>
      );
    });
  };

  const renderStyledText = text => {
    if (text) {
      return (
        <span
          dangerouslySetInnerHTML={{
            __html: text.replace(/(?:\r\n|\r|\n)/g, '<br>'),
          }}
        />
      );
    }
    return undefined;
  };

  return (
    <div className='tint-page-section--form__submission-container'>
      <i className='fas fa-check-circle' />
      <p className='tint-page-section--form__submission-container__header'>{title || 'Thank you!'}</p>
      <p className='tint-page-section--form__submission-container__description'>
        {renderStyledText(description) || 'The form was submitted successfully'}
      </p>
      {successMessageLinks && successMessageLinks.length > 0 ? (
        <div className='tint-submission-success-container'> {renderSuccessMessageLinks()}</div>
      ) : null}
    </div>
  );
};
