import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DropdownInfiniteList from '../../interface/dropdown/lists/infinite-list/dropdown-infinite-list';
import { DropdownHeader } from '../../interface/dropdown/button/header/dropdown-header';
import { InputGroup } from '../../../components/interface/inputs/input-group/input-group';
import DropdownButton, { ButtonType, DropdownButtonType } from '../../interface/dropdown/button/dropdown-button';
import { toast } from 'react-toastify';
import { mapDropdownList, mapDropdownListItem } from '../utils/utils';
import { getTintsUrlWithParams } from '../../../feature/tint-editor/utils/filter-helper/filter-helper';
import { getTint, getTints } from '../../../actions/account/account.fat-actions';

const SelectTintWrapper = ({
  currentItemId,
  currentItemSlug,
  onChange,
  includedParams,
  onLoadIncludedItems,
  withInputGroup = true,
  isDefaultOption = false,
  defaultOptionTitle = 'None',
  ...props
}) => {
  const dispatch = useDispatch();
  const [filterValue, setFilterValue] = useState();
  const [contentList, setContentList] = useState([]);
  const [currentItem, setCurrentItem] = useState();

  const currentTints = useSelector(state => state.account.tints?.data);
  const currentTintsIncludedItems = useSelector(state => state.account.tints.included);
  const isAbleToLoad = useSelector(state => state.account.tints.links?.next);
  const currentTint = useSelector(state => state.account?.tint);

  const setIncludedItems = res => {
    if (includedParams)
      onLoadIncludedItems &&
        onLoadIncludedItems({
          tintList: [...currentTints, res.data.data],
          ...(currentTintsIncludedItems
            ? {
                includedItems: res.data?.included
                  ? [...currentTintsIncludedItems, res.data?.included]
                  : currentTintsIncludedItems,
              }
            : { includedItems: [] }),
        });
  };

  const getTintBySlug = () => {
    getTintById(currentItemSlug, true);
  };

  const getTintById = (slugName, forceNoTeamPrefix) => {
    dispatch(getTint(slugName ? slugName : currentItemId, includedParams, forceNoTeamPrefix))
      .then(res => {
        setCurrentItem(mapDropdownListItem(res.data.data));
        setIncludedItems(res);
        onChange?.({
          value: res?.data?.data?.id,
          slugName: res?.data?.data?.attributes?.slug,
          name: res?.data?.data?.attributes?.name,
        });
      })
      .catch(() => {
        toast('Cannot fetch TINT');
      });
  };

  const findCurrentItem = () => {
    if (currentItemSlug) {
      return currentTints.find(item => item?.attributes?.slug === currentItemSlug);
    }

    if (currentItemId) {
      return currentTints.find(item => item?.id === currentItemId);
    }
  };

  const getCurrentItem = () => {
    if (currentTints) {
      const item = findCurrentItem();
      if (item) {
        setCurrentItem(mapDropdownListItem(item));
        onLoadIncludedItems &&
          onLoadIncludedItems({
            tintList: currentTints,
            includedItems: currentTintsIncludedItems,
          });
      } else if (currentItemSlug && !currentItemId) {
        getTintBySlug();
      } else if (currentItemId) {
        getTintById();
      }
    }
  };

  useEffect(() => {
    (!currentTint || currentTint.id !== currentItemId) && currentItemId && getTintById(currentItemId);
  }, []);

  useEffect(() => {
    if (filterValue !== undefined) {
      setContentList([]);
      dispatch(
        getTints(
          getTintsUrlWithParams({
            ...(includedParams ? { include: includedParams } : {}),
            ...(filterValue ? { filterValue } : {}),
            ...{ isPrivate: false },
          })
        )
      ).catch(() => toast('Cannot fetch TINTs'));
    }
  }, [filterValue]);

  useEffect(() => {
    dispatch(
      getTints(
        getTintsUrlWithParams({
          ...(includedParams ? { include: includedParams, isPrivate: false } : { isPrivate: false }),
        })
      )
    );
  }, []);

  useEffect(() => {
    if (currentTints && !contentList?.find(cl => cl?.id === currentTints[0]?.id))
      if (!Array.isArray(currentTints)) {
        //TODO Refactor pls as above
        setContentList([...contentList, currentTints]);
      } else {
        setContentList([...contentList, ...currentTints]);
      }

    getCurrentItem();
  }, [currentTints]);

  const onChangeTint = data => {
    onChange?.(data);
    if (data) {
      getTintById(data.value);
    }
  };

  const onLoadData = () => dispatch(getTints(isAbleToLoad));
  const onInputChange = query => setFilterValue(query);

  const renderDropdown = () => {
    return (
      <DropdownButton
        currentItem={currentItem}
        dropdownType={DropdownButtonType.SEARCH_INPUT}
        buttonType={ButtonType.BUTTON_SORT}
        dropdownHeader={props => <DropdownHeader {...props} />}
        dropdownList={props => (
          <DropdownInfiniteList
            {...props}
            onLoadData={onLoadData}
            isAbleToLoad={contentList.length >= 25 && isAbleToLoad}
            onInputChange={e => onInputChange(e)}
            isDefaultOption={isDefaultOption}
            defaultOptionTitle={defaultOptionTitle}
            placeholder='Select TINT'
          />
        )}
        list={mapDropdownList(contentList, mapDropdownListItem)}
        placeholder='Select TINT'
        iconRight='fa fa-caret-down'
        onChangeValue={onChangeTint}
        {...props}
      />
    );
  };
  return withInputGroup ? (
    <InputGroup inputGroupIcon='fas fa-portrait' label='Select TINT'>
      {renderDropdown()}
    </InputGroup>
  ) : (
    renderDropdown()
  );
};

export default SelectTintWrapper;
