import React from 'react';
import './tag-o-war-section.sass';
import RangeList from './components/range-list/range-list';
import TagOWarSectionRing from './components/ring/tag-o-war-section-ring';

const TagOWarSection = function({ props, leftPosts, rightPosts, percentLeft, percentRight }) {
  return (
    <>
      <TagOWarSectionRing
        percentLeft={percentLeft}
        percentRight={percentRight}
        content={props.data.content}
        styles={props.data.styles}
      />

      <RangeList
        isPreviewMode={props.isPreviewMode}
        styles={props.data.styles}
        leftSideWidth={percentLeft}
        rightSideWidth={percentRight}
        socialFeedLeft={leftPosts}
        socialFeedRight={rightPosts}
      />
    </>
  );
};

export default TagOWarSection;

TagOWarSection.propTypes = {};
