import { useEffect, useState } from 'react';
import { keyCodes } from './keyCodes';

const useKeyDown = () => {
  const [keyDown, setKeyDown] = useState();

  const handleKeyDown = e => {
    switch (e.keyCode) {
      case keyCodes.arrowTop:
      case keyCodes.arrowBottom:
        // to avoid scroll when use dropdown
        e.preventDefault();
        break;

      default:
        break;
    }
    setKeyDown({ keyDown: e.keyCode });
  };

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return keyDown;
};

export default useKeyDown;
