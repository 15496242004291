import React, { useRef, useState, useEffect } from 'react';
import './wysiwyg-navigation-container.sass';
import WysiwygNavigation from './wysiwyg-navigation';
import { commandIdList } from './wysiwyg-navigation-options';
import CustomFontFamilyDropdown from '../../../../../../../components/interface/inputs/custom-font-family/custom-font-family';
import FontSizeDropdown from '../../../../../../../components/interface/inputs/font-size/font-size';
import * as PropTypes from 'prop-types';
import useClickOutside from '../../../../../../../components/hooks/use-click-outside/use-click-outside';
import Color from '../../../../../../../services/color-parser/color-parser';
import ColorPicker from '../../../../../../../components/interface/inputs/color-picker/color-picker';
import AddLink from '../../../../../../../components/interface/inputs/add-link/add-link';

const WysiwygNavigationContainer = ({ onNavigationClick, valueProperties, onSaveSelection, getPrevSelection }) => {
  const [isLinkInputVisible, setLinkInputVisibility] = useState();
  const [isColorPickerVisible, setColorPickerVisibility] = useState();
  const [isBackgroundColorPickerVisible, setBackgroundColorPickerVisibility] = useState();

  const isSelectText = () => {
    let ranges = [];
    let sel = window.getSelection();

    for (let i = 0; i < sel.rangeCount; i++) {
      ranges[i] = sel.getRangeAt(i);
    }
    return ranges[0] && ranges[0].toString().length === 0;
  };
  const isNavDisabled = isSelectText();

  const node = useRef();
  const [isActive, setStatus] = useClickOutside(node);

  const onItemClick = (commandId, value) => {
    switch (commandId) {
      case commandIdList.LINK:
        onSaveSelection();
        setLinkInputVisibility(true);
        break;

      case commandIdList.BACKGROUND_COLOR:
        onSaveSelection();
        setBackgroundColorPickerVisibility(true);
        setColorPickerVisibility(false);
        setStatus(true);
        break;

      case commandIdList.COLOR:
        onSaveSelection();
        setColorPickerVisibility(true);
        setBackgroundColorPickerVisibility(false);
        setStatus(true);
        break;

      case commandIdList.FONT_SIZE:
      case commandIdList.FONT_FAMILY:
        if (isNavDisabled) return;
        onNavigationClick(commandId, value);
        break;

      case commandIdList.DEFAULT:
      default:
        onNavigationClick(commandId);
        return;
    }
  };

  const onLinkChange = link => {
    onNavigationClick(commandIdList.LINK, link.urlLink);
  };

  const onColorChange = (color, commandId) => {
    onNavigationClick(commandId, color);
  };

  const onNavClick = e => {
    if (
      e.target.nodeName !== 'BUTTON' &&
      e.target.nodeName !== 'SELECT' &&
      e.target.nodeName !== 'SPAN' &&
      e.target.nodeName !== 'INPUT'
    ) {
      getPrevSelection();
    }
  };

  // ------ Firefox issue
  const [currentBackgroundColors, setCurrentBackgroundColor] = useState(valueProperties);

  useEffect(() => {
    setCurrentBackgroundColor(valueProperties.backgroundColor);
  }, [valueProperties.backgroundColor]);
  // -------

  return (
    <div className='tint-wysiwyg-navigation-container' onClick={onNavClick}>
      <div className='tint-wysiwyg-navigation-container__font-family'>
        <CustomFontFamilyDropdown
          fontFamily={valueProperties.fontFamily}
          styleUpdate={font => {
            onItemClick('fontName', font.fontFamily.value);
          }}
          onChange={() => {
            if (isNavDisabled) return;
          }}
        />
      </div>
      <div className='tint-wysiwyg-navigation-container__font-size'>
        <FontSizeDropdown
          fontSize={valueProperties.fontSize}
          styleUpdate={fontSize => {
            onItemClick('fontSize', fontSize);
          }}
        />
      </div>
      <div ref={node} className='tint-wysiwyg-navigation-container__color-picker'>
        {isColorPickerVisible && isActive && (
          <ColorPicker
            color={valueProperties.color}
            onColorChange={color => {
              onColorChange(Color.defaultColor(color), commandIdList.COLOR);
            }}
          />
        )}
        {isBackgroundColorPickerVisible && isActive && (
          <ColorPicker
            color={currentBackgroundColors}
            onColorChange={color => {
              setCurrentBackgroundColor(color);
              onColorChange(Color.defaultColor(color), commandIdList.BACKGROUND_COLOR);
            }}
          />
        )}
      </div>

      <div className='tint-wysiwyg-navigation-container__font-style-general'>
        <WysiwygNavigation onNavElementClick={onItemClick} style={valueProperties} />
      </div>

      {isLinkInputVisible && (
        <div className='tint-wysiwyg-navigation-container__link'>
          <AddLink urlLink={valueProperties.link} onClose={setLinkInputVisibility} dataUpdate={onLinkChange} />
        </div>
      )}
    </div>
  );
};

export default WysiwygNavigationContainer;

WysiwygNavigationContainer.propTypes = {
  onNavigationClick: PropTypes.func,
  valueProperties: PropTypes.object,
  onSaveSelection: PropTypes.func,
};
