import React, { Component } from 'react';
import { EditUploadDesign } from '../../../';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';

export class EditFormBuilderStyles extends Component {
  render() {
    return (
      <EditModalBody title='Set styles for your Form Builder section'>
        <EditUploadDesign
          dataUpdate={this.props.dataUpdate}
          styleDataUpdate={this.props.styleDataUpdate}
          data={this.props.data}
        />
      </EditModalBody>
    );
  }
}
