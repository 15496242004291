import React, { useState, useEffect } from 'react';
import './tint-mix-scene-navigation-bar.sass';
import InputPrimary from '../../../../components/interface/inputs/input-primary/input-primary';
import { Config } from '../../../../utils/config';
import { useHistory } from 'react-router';
import { Button } from '../../../../components/interface/button/button';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useDebounce from '../../../../components/hooks/use-debounce/use-debounce';
import DropdownButton, { ButtonType } from '../../../../components/interface/dropdown/button/dropdown-button';
import { DropdownHeader } from '../../../../components/interface/dropdown/button/header/dropdown-header';
import DropdownCurrentUsageList, {
  isLimitReached,
} from '../../../../components/interface/dropdown/lists/current-usage-list/dropdown-current-usage-list';
import useUsagePlanLimits from '../../../../components/hooks/use-plan/use-usage-plan-limits';
import { useSelector } from 'react-redux';
import { getCurrentTintMix } from '../../reducers/tint-mix.reducer';

export const defaultTintMixName = 'Untitled Mix';

export const TintMixSceneNavigationBar = ({ title = defaultTintMixName, onChangeName, onAddScene }) => {
  const history = useHistory();
  const [inputValue, setInputValue] = useState(title);
  const tintMix = useSelector(getCurrentTintMix);

  const formik = useFormik({
    validateOnChange: true,
    validateOnBlur: true,
    initialValues: {
      tintMixSceneName: inputValue,
    },
    validationSchema: Yup.object().shape({
      tintMixSceneName: Yup.string(),
    }),
  });

  const debounceInputValue = useDebounce(inputValue, 1000);

  useEffect(() => {
    inputValue !== title && onChangeName(inputValue);
  }, [debounceInputValue]);

  const planList = ['tint_limit', 'image_limit', 'media_limit'];
  const plans = useUsagePlanLimits(planList, 'tint_mix_config');

  const currentUsageList = [
    {
      name: 'Images',
      activeCount: plans?.image_limit?.count,
      activeOnPlan: plans?.image_limit?.plan,
    },
    {
      name: 'Media',
      activeCount: plans?.media_limit?.count,
      activeOnPlan: plans?.media_limit?.plan,
    },
    {
      name: 'Tints',
      activeCount: plans?.tint_limit?.count,
      activeOnPlan: plans?.tint_limit?.plan,
    },
  ].filter(plan => plan.activeOnPlan);

  const onPreview = () => {
    window.open(Config.routePaths.newTintMix.mix.preview.createUrl(tintMix.id), '_blank', 'noopener,noreferrer');
  };

  return (
    <div className='tint-mix-scene-navigation-bar'>
      <div className='tint-mix-scene-navigation-bar__wrapper'>
        <span
          className='tint-mix-scene-navigation-bar__back-button far fa-angle-left '
          onClick={() => history.push(Config.routePaths.newTintMix.path)}
        />

        <div className='tint-mix-scene-navigation-bar__title'>
          <InputPrimary
            name={'tintMixSceneName'}
            type={'text'}
            value={formik.values.tintMixSceneName}
            placeholder={formik.values.tintMixSceneName}
            error={formik.errors.tintMixSceneName}
            handleBlur={formik.handleBlur}
            touched={formik.touched.tintMixSceneName}
            handleChange={e => {
              setInputValue(e.target.value);
              return formik.handleChange(e);
            }}
          />
        </div>
      </div>
      <div className='tint-mix-scene-navigation-bar__actions'>
        {currentUsageList.length !== 0 && (
          <DropdownButton
            dropdownHeader={props => <DropdownHeader {...props} />}
            dropdownList={props => (
              <DropdownCurrentUsageList {...props} headerTitle='Media Limits' hasUpgradeButton={false} />
            )}
            list={currentUsageList}
            iconCenter='fas fa-chart-pie-alt'
            iconOverlap={isLimitReached(currentUsageList) ? 'fas fa-exclamation-circle' : undefined}
            buttonType={ButtonType.BUTTON_ICON_DARK}
          />
        )}
        <Button type={'primary'} text={'+Add Scene'} onClick={onAddScene} />
        <Button type={'secondary'} text={'View Mix'} onClick={onPreview} />
      </div>
    </div>
  );
};
