import { COMMON_SETTINGS } from './common-settings';
import { languageList } from '../configs/personalization-languages';

export const SQUARE_PHOTO = {
  name: 'Square Photo',
  key: 'new_square_photo',
  premiumDesign: false,
  default_settings: {
    ...COMMON_SETTINGS,
    inline_video_playback: 'none',
    text_on_hover: true,
    themeName: 'new_square_photo',
  },
  themes_settings: [],
  guards: {
    CTA: true,
    waterfall: true,
    popup: true,
    shareIcons: true,
    removeDuplicateImages: true,
    genericAvatarBackgroundColor: true,
    genericAvatarTextColor: true,
    customCSS: true,
    backgroundImage: true,
    banner: true,
  },
  font_settings: [
    {
      key: 'fontcolor_post',
      label: 'Font Color',
      type: 'colorPicker',
    },
    {
      key: 'fontsize_secondary',
      type: 'slider',
      label: 'Font size',
      min: '8',
      max: '50',
    },
    {
      key: 'color_namebar_buttons',
      label: 'Accent Color',
      type: 'colorPicker',
    },
  ],
  extra_settings: [
    {
      key: 'time_language',
      type: 'dropdown',
      label: 'Language for post timestamp',
      options: languageList,
    },
    {
      key: 'postwidth',
      type: 'slider',
      label: 'Minimum Post Width',
      min: '150',
      max: '500',
    },
    {
      key: 'postpadding',
      type: 'slider',
      label: 'Post Spacing',
      min: '0',
      max: '50',
    },
    {
      key: 'highlightVideoPlayback',
      label: 'Highlight Video playback',
      type: 'dropdown',
      options: [
        {
          name: "Don't Auto Play Video",
          value: 'dont-autoplay',
        },
        {
          name: 'Auto Play Entire Video',
          value: 'play-entire-video',
        },
        {
          name: 'Auto Play Video to transition',
          value: 'play-to-transition',
        },
      ],
    },
  ],
  extra_popup_settings: [
    // {
    //   key: 'popup_products_visibility',
    //   label: 'Hide Product Hotspots',
    //   control_type: 'Checkbox',
    //   target: '#personalize-popup-controls',
    //   add_class: 'control-divide-above',
    //   required_flags: ['ecommerce'],
    // },
  ],
};
