import { hasNullValue } from '../../../utils/helpers/helpers';

export const useDataTranslationsValue = ({ key, element }) => {
  const keyValue = element?.[key];
  if (!keyValue || hasNullValue(keyValue)) return {};
  const parse = keyValue && JSON.parse(keyValue);

  const value = Object.entries(parse)
    .map(([key, value]) => {
      return {
        [key]: value,
      };
    })
    .reduce((acc, obj) => {
      const key = Object.keys(obj)[0];
      acc[key] = obj[key];
      return acc;
    }, {});

  return value || {};
};
