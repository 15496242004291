import { ACTIONS } from '../../actions/custom-fonts/custom-fonts.actions';

export const initialState = {
  fontList: [],
  isPending: false,
  error: undefined,
  links: undefined,
};

export const fontList = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_CUSTOM_FONTS.REQUEST:
    case ACTIONS.DELETE_CUSTOM_FONT.REQUEST:
    case ACTIONS.POST_CUSTOM_FONT.REQUEST:
      return {
        ...state,
        isPending: true,
        error: undefined,
      };

    case ACTIONS.DELETE_CUSTOM_FONT.SUCCESS:
      return {
        ...state,
        isPending: false,
        fontList: [...state.fontList?.filter(font => font.id !== action.payload)],
        error: undefined,
      };

    case ACTIONS.POST_CUSTOM_FONT.SUCCESS:
      return {
        ...state,
        fontList: [action.payload.data, ...state.fontList],
        isPending: false,
        error: undefined,
      };

    case ACTIONS.GET_CUSTOM_FONTS.SUCCESS:
      return {
        ...state,
        fontList: action.payload.data,
        isPending: false,
        error: undefined,
        links: action.payload.links,
      };

    case ACTIONS.GET_CUSTOM_FONTS.FAILURE:
    case ACTIONS.DELETE_CUSTOM_FONT.FAILURE:
    case ACTIONS.POST_CUSTOM_FONT.FAILURE:
      return {
        ...state,
        error: action.payload,
        isPending: false,
      };

    default:
      return state;
  }
};
export default fontList;

export const getCustomFontList = state => {
  return state.fontList ? state.fontList : [];
};

export const isCustomFontsPending = state => state.isPending;
