import React, { Component } from 'react';
import './banner-section.sass';
import { EditBannerContent } from './edit/edit-banner-content';
import { PageSectionHoc } from '../../../../../../components/HOC/page-section/page-section.hoc';
import { EditModal } from '../../../../../../components/edit-modal/edit-modal/edit-modal';
import { EditModalStep } from '../../../../../../components/edit-modal/edit-modal-step/edit-modal-step';
import ColorParser from '../../../../../../services/color-parser/color-parser';

class BannerSection extends Component {
  render() {
    return (
      <div
        className={`tint-page-section tint-page-section--banner ${this.props.getSectionClass()}`}
        style={{
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`,
        }}>
        {this.props.renderGenericComponents()}
        {this.props.isModalOpen && (
          <EditModal
            nextStep={this.props.nextStep}
            isVisible={this.props.isModalOpen}
            saveData={this.props.onDataSave}
            dismissModal={this.props.dismissModal}
            closeModal={this.props.closeModal}>
            <EditModalStep title='Edit Banner'>
              <EditBannerContent
                data={this.props.data}
                saveData={this.props.onDataSave}
                styleDataUpdate={this.props.onStylesUpdate}
                dataUpdate={this.props.onContentUpdate}
              />
            </EditModalStep>
          </EditModal>
        )}
      </div>
    );
  }
}

export default PageSectionHoc(BannerSection);
