import { ACTIONS } from '../../actions/marketing-integrations/marketing-integrations-sidebar.actions';

export const initialState = {
  isOpen: false,
};

export const marketingIntegrationsSidebar = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_SIDEBAR:
      return {
        ...state,
        isOpen: true,
      };

    case ACTIONS.CLOSE_SIDEBAR:
      return {
        ...state,
        isOpen: false,
      };
    default:
      return state;
  }
};
