import { createPrefixedActionName, createRoutine } from '../../../../services/functions/redux-routines/redux-routines';
import { MISSION_HUBS } from '../../../../actions/consts';

export const ACTIONS = {
  SELECT_MISSION: createPrefixedActionName(MISSION_HUBS, 'SELECT_MISSION'),
  GET_MISSION: createRoutine(createPrefixedActionName(MISSION_HUBS, 'GET_MISSION')),
};

export const getMissionStart = () => ({
  type: ACTIONS.GET_MISSION.REQUEST,
});

export const getMissionSuccess = data => ({
  type: ACTIONS.GET_MISSION.SUCCESS,
  payload: data,
});

export const getMissionError = err => ({
  type: ACTIONS.GET_MISSION.FAILURE,
  payload: err,
});
