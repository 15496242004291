import { useState, useEffect } from 'react';

export const DropdownListPosition = {
  LEFT: 'position-left',
  RIGHT: 'position-right',
  TOP: 'position-top',
  BOTTOM: 'position-bottom',
};

const useDropdownListPosition = (ref, parentRef) => {
  const [positionHorizontal, setPositionHorizontalClass] = useState('left');
  const [positionVertical, setPositionVerticalClass] = useState('top');

  const checkElementPositionHorizontal = element => {
    const offsetLeft = parentRef?.current?.getBoundingClientRect();

    if (window.innerWidth - offsetLeft.left > element?.current?.clientWidth) {
      setPositionHorizontalClass(DropdownListPosition.LEFT);
    } else {
      setPositionHorizontalClass(DropdownListPosition.RIGHT);
    }
  };

  const checkElementPositionVertical = element => {
    if (
      window.innerHeight -
        (parentRef?.current?.offsetTop +
          (parentRef?.current?.offsetParent ? parentRef?.current?.offsetParent?.offsetTop : 0)) >
      element?.current?.clientHeight
    ) {
      setPositionVerticalClass(DropdownListPosition.BOTTOM);
    } else {
      setPositionVerticalClass(DropdownListPosition.TOP);
    }
  };

  useEffect(() => {
    checkElementPositionHorizontal(ref);
    checkElementPositionVertical(ref);
  }, []);

  return { positionHorizontal, positionVertical };
};

export default useDropdownListPosition;
