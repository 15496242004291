import React, { Component } from 'react';
import './custom-section-component-modal.sass';
import { CustomComponent } from '../custom-component/custom-component';
import PropTypes from 'prop-types';
import { pageSectionType } from '../../../../../../model/page-section-type.model';
import { isAllowed } from '../../../../../../services/functions/features-checker/features-checker';

export class CustomSectionComponentModal extends Component {
  constructor(props) {
    super(props);
    this.customBlocks = [
      {
        title: 'Headline',
        icon: 'fa-font',
        action: () => this.props.addSection(pageSectionType.CUSTOM_HEADLINE),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['all'],
      },
      {
        title: 'Paragraph',
        icon: 'fa-align-left',
        action: () => this.props.addSection(pageSectionType.CUSTOM_PARAGRAPH),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['all'],
      },
      {
        title: 'TINT',
        icon: 'fa-th-large',
        action: () => this.props.addSection(pageSectionType.TINT),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['all'],
      },
      {
        title: 'Button',
        icon: 'fa-rectangle-wide',
        action: () => this.props.addSection(pageSectionType.CUSTOM_BUTTON),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['all'],
      },
      {
        title: 'Counter',
        icon: 'fa-poll',
        action: () => this.props.addSection(pageSectionType.SOCIAL_FEED_COUNTER),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['experiences_advanced_templates'],
      },
      {
        title: 'Timer',
        icon: 'fa-clock',
        action: () => this.props.addSection(pageSectionType.TIMER),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['all'],
      },
      {
        title: 'Spacer',
        icon: 'fa-arrows-alt-v',
        action: () => this.props.addSection(pageSectionType.CUSTOM_SPACER),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['all'],
      },
      {
        title: 'Social',
        icon: 'fa-share-alt',
        action: () => this.props.addSection(pageSectionType.SOCIAL),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['all'],
      },
      //{
      //  title: 'Form',
      //  icon: 'fa-list-alt',
      //  action: () => this.props.addSection(pageSectionType.FORM),
      //  onClick: () => this.props.hideMenu(),
      //  enabledFeature: ['all'],
      //  isAddable: experience => {
      //    const pageSections = experience.attributes.data.draft;
      //    return (
      //      pageSections.filter(e => e.sectionType === pageSectionType.FORM).length === 0 &&
      //      !pageSections.some(e => e.sectionType === pageSectionType.POLL)
      //    );
      //  },
      //},
      {
        title: 'Form Builder',
        icon: 'fa-list-alt',
        action: () => this.props.addSection(pageSectionType.FORM_BUILDER),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['all'],
        isAddable: experience => {
          const pageSections = experience.attributes.data.draft;
          return !pageSections.filter(e => e.sectionType === pageSectionType.FORM_BUILDER).length;
        },
      },
      {
        title: 'Multi-Column',
        icon: 'fa-columns',
        action: () => this.props.addSection(pageSectionType.MULTI_COLUMN),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['experiences_advanced_templates'],
      },
      {
        title: 'Image',
        icon: 'fa-columns',
        action: () => this.props.addSection(pageSectionType.IMAGE),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['experiences_advanced_templates'],
      },
      {
        title: 'Video',
        icon: 'fa-columns',
        action: () => this.props.addSection(pageSectionType.VIDEO),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['experiences_advanced_templates'],
      },
    ];

    this.multiColumnBlocks = [
      {
        title: 'Headline',
        icon: 'fa-font',
        action: () => this.props.addSection(pageSectionType.CUSTOM_HEADLINE),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['all'],
      },
      {
        title: 'Paragraph',
        icon: 'fa-align-left',
        action: () => this.props.addSection(pageSectionType.CUSTOM_PARAGRAPH),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['all'],
      },
      {
        title: 'TINT',
        icon: 'fa-th-large',
        action: () => this.props.addSection(pageSectionType.TINT),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['all'],
      },
      {
        title: 'Image',
        icon: 'fas fa-image',
        action: () => this.props.addSection(pageSectionType.IMAGE),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['all'],
      },
      {
        title: 'Video',
        icon: 'fas fa-video',
        action: () => this.props.addSection(pageSectionType.VIDEO),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['all'],
      },
      {
        title: 'Button',
        icon: 'fa-rectangle-wide',
        action: () => this.props.addSection(pageSectionType.CUSTOM_BUTTON),
        onClick: () => this.props.hideMenu(),
        enabledFeature: ['all'],
      },
    ];
  }

  render() {
    return (
      <div className='tint-custom-section-component-modal' style={this.props.styles}>
        <span className='tint-custom-section-component-modal__title'>Add Block</span>
        <div className='tint-custom-section-component-modal__blocks-container'>
          {(!this.props.isForMultiColumn ? this.customBlocks : this.multiColumnBlocks).map((e, i) => (
            <CustomComponent
              key={i}
              {...e}
              isBlocked={
                !isAllowed(this.props.enabled, e.enabledFeature) ||
                (e.isAddable !== undefined ? !e.isAddable(this.props.experience) : false)
              }
            />
          ))}
          {this.props.isBlocked && !this.props.isForMultiColumn ? (
            <div className='upgrade-information'>
              <span>Upgrade Plan to Add</span>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

CustomSectionComponentModal.propTypes = {
  pageSectionIndex: PropTypes.number,
  addSection: PropTypes.func,
  hideMenu: PropTypes.func,
  isForMultiColumn: PropTypes.bool,
  styles: PropTypes.object,
  enabled: PropTypes.object,
  experience: PropTypes.object,
};
