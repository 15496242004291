import { createPrefixedActionName, createRoutine } from '../../../services/functions/redux-routines/redux-routines';

export const ACTIONS = {
  GET_POST_TAGGED_PRODUCTS: createRoutine(createPrefixedActionName('UI_ECOMMERCE', 'GET_POST_TAGGED_PRODUCTS')),
  PATCH_PRODUCT_TAG: createRoutine(createPrefixedActionName('UI_ECOMMERCE', 'PATCH_PRODUCT_TAG')),
  POST_PRODUCT_TAG: createRoutine(createPrefixedActionName('UI_ECOMMERCE', 'POST_PRODUCT_TAG')),
  DELETE_PRODUCT_TAG: createRoutine(createPrefixedActionName('UI_ECOMMERCE', 'DELETE_PRODUCT_TAG')),
  RESTORE_DEFAULT_TAGGED_PRODUCTS: createPrefixedActionName('UI_ECOMMERCE', 'RESTORE_DEFAULT_TAGGED_PRODUCTS'),

  DRAW_RECT: createPrefixedActionName('UI_ECOMMERCE', 'DRAW_RECT'),
  UPDATE_RECT_UI: createPrefixedActionName('UI_ECOMMERCE', 'UPDATE_RECT_UI'),
  EDIT_RECT_UI_STATUS: createPrefixedActionName('UI_ECOMMERCE', 'EDIT_RECT_UI_STATUS'),
  CANCEL_DRAW_RECT: createPrefixedActionName('UI_ECOMMERCE', 'CANCEL_DRAW_RECT'),
  DISMISS_DRAW_RECT: createPrefixedActionName('UI_ECOMMERCE', 'DISMISS_DRAW_RECT'),
  ADD_NO_DRAWABLE_PRODUCT: createPrefixedActionName('UI_ECOMMERCE', 'ADD_NO_DRAWABLE_PRODUCT'),

  ASSIGN_PRODUCT: createPrefixedActionName('UI_ECOMMERCE', 'ASSIGN_PRODUCT'),
  SELECT_PRODUCT: createPrefixedActionName('UI_ECOMMERCE', 'SELECT_PRODUCT'),
  UNSELECT_PRODUCT: createPrefixedActionName('UI_ECOMMERCE', 'UNSELECT_PRODUCT'),
  HOVER_PRODUCT: createPrefixedActionName('UI_ECOMMERCE', 'HOVER_PRODUCT'),
  UN_HOVER_PRODUCT: createPrefixedActionName('UI_ECOMMERCE', 'UN_HOVER_PRODUCT'),
  SET_SIDEBAR_STATUS: createPrefixedActionName('UI_ECOMMERCE', 'SET_SIDEBAR_STATUS'),
};

export const setSidebarStatus = () => ({
  type: ACTIONS.SET_SIDEBAR_STATUS,
});

export const addNoDrawableProduct = rect => ({
  type: ACTIONS.ADD_NO_DRAWABLE_PRODUCT,
  payload: rect,
});

export const getPostTaggedProductsStart = () => ({
  type: ACTIONS.GET_POST_TAGGED_PRODUCTS.REQUEST,
});

export const getPostTaggedProductsSuccess = data => ({
  type: ACTIONS.GET_POST_TAGGED_PRODUCTS.SUCCESS,
  payload: data,
});

export const getPostTaggedProductsError = err => ({
  type: ACTIONS.GET_POST_TAGGED_PRODUCTS.FAILURE,
  payload: err,
});

export const patchProductTagStart = (postId, teamId, taggedProducts) => ({
  type: ACTIONS.PATCH_PRODUCT_TAG.REQUEST,
  payload: {
    postId,
    teamId,
    taggedProducts,
  },
});

export const patchProductTagSuccess = (data, rectId, productId) => ({
  type: ACTIONS.PATCH_PRODUCT_TAG.SUCCESS,
  payload: {
    data,
    rectId,
    productId,
  },
});

export const patchProductTagError = err => ({
  type: ACTIONS.PATCH_PRODUCT_TAG.FAILURE,
  payload: err,
});

export const postProductTagStart = (tintId, teamId, taggedProducts) => ({
  type: ACTIONS.POST_PRODUCT_TAG.REQUEST,
  payload: {
    tintId,
    teamId,
    taggedProducts,
  },
});

export const postProductTagSuccess = (data, rectId, productId) => ({
  type: ACTIONS.POST_PRODUCT_TAG.SUCCESS,
  payload: {
    data,
    rectId,
    productId,
  },
});

export const postProductTagError = err => ({
  type: ACTIONS.POST_PRODUCT_TAG.FAILURE,
  payload: err,
});

export const deleteProductTagStart = (tintId, teamId, taggedProducts) => ({
  type: ACTIONS.DELETE_PRODUCT_TAG.REQUEST,
  payload: {
    tintId,
    teamId,
    taggedProducts,
  },
});

export const deleteProductTagSuccess = rectId => ({
  type: ACTIONS.DELETE_PRODUCT_TAG.SUCCESS,
  payload: {
    rectId,
  },
});

export const deleteProductTagError = err => ({
  type: ACTIONS.DELETE_PRODUCT_TAG.FAILURE,
  payload: err,
});

export const hoverProduct = (productId, rectId) => ({
  type: ACTIONS.HOVER_PRODUCT,
  payload: {
    productId,
    rectId,
  },
});

export const unHoverProduct = () => ({
  type: ACTIONS.UN_HOVER_PRODUCT,
});

export const selectProduct = selectedProduct => ({
  type: ACTIONS.SELECT_PRODUCT,
  payload: selectedProduct,
});

export const unselectProduct = () => ({
  type: ACTIONS.UNSELECT_PRODUCT,
});

export const assignProduct = (productDetails, rectId) => ({
  type: ACTIONS.ASSIGN_PRODUCT,
  payload: {
    productDetails,
    rectId,
  },
});

export const drawRectUI = rect => {
  return {
    type: ACTIONS.DRAW_RECT,
    payload: rect,
  };
};

export const editRectUIStatus = (status, rectId) => {
  return {
    type: ACTIONS.EDIT_RECT_UI_STATUS,
    payload: {
      rectId,
      status,
    },
  };
};

export const cancelDrawRectUI = () => {
  return {
    type: ACTIONS.CANCEL_DRAW_RECT,
  };
};

export const dismissDrawRectUI = () => {
  return {
    type: ACTIONS.DISMISS_DRAW_RECT,
  };
};

export const updateRectUI = taggedProduct => {
  return {
    type: ACTIONS.UPDATE_RECT_UI,
    payload: taggedProduct,
  };
};

export const restoreDefaultTaggedProducts = () => ({
  type: ACTIONS.RESTORE_DEFAULT_TAGGED_PRODUCTS,
});
