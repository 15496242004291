import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import './custom-date-picker.sass';

const CustomDatePicker = (
  { disabled, onClear, onClick, value, calendarIconLeft = false, clearButton = false },
  ref
) => {
  return (
    <div
      className={`tint-custom-data-picker${disabled ? ' tint-custom-data-picker--disabled' : ''} ${
        calendarIconLeft ? ' tint-custom-data-picker--icon-left' : ''
      }`}
      onClick={onClick}
      ref={ref}>
      <span className='fas fa-calendar-alt tint-custom-data-picker__calendar-icon' />
      {value}
      {value && clearButton && <span className='fal fa-times tint-custom-data-picker__clear-icon' onClick={onClear} />}
    </div>
  );
};

const CustomDatePickerWithForwardedRef = forwardRef(CustomDatePicker);

CustomDatePickerWithForwardedRef.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

export default CustomDatePickerWithForwardedRef;
