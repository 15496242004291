import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { SentryReduxMiddleware } from './middlewares/sentry.redux.middleware';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const preloadedState = window.__PRELOADED_STATE__;
// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

export default createStore(
  rootReducer,
  preloadedState || {},
  composeEnhancers(applyMiddleware(thunk, SentryReduxMiddleware))
);
