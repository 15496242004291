import React from 'react';
import { Button } from '../../../interface/button/button';
import './edit-modal-button.sass';

const EditModalButton = ({ text, saveData, onClick, type, ...props }) => {
  return (
    <div className='edit-modal-button'>
      <Button
        onClick={() => {
          saveData && saveData();
          onClick && onClick();
        }}
        type={type}
        text={text}
        width='100%'
        {...props}
      />
      ;
    </div>
  );
};

export default EditModalButton;
