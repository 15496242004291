import { useState, useEffect } from 'react';
import { Config } from '../../../utils/config';

const useImageValidation = (imageUrl, alternativeImgUrl) => {
  const [newImage, setImageUrl] = useState('');
  const [isImageValid, setImageValid] = useState(true);
  const [isPending, setPending] = useState(true);

  const onLoad = url => {
    setImageUrl(url.target.currentSrc);
    setImageValid(true);
    setPending(false);
  };

  const reLoadImage = img => {
    if (alternativeImgUrl && img.src !== alternativeImgUrl) {
      loadImage(alternativeImgUrl);
      img.removeEventListener('load', onLoad);
    } else {
      setImageAsBroken();
    }
  };

  const loadImage = url => {
    const img = new Image();
    setPending(true);

    img.src = url;

    img.addEventListener('load', onLoad);
    img.addEventListener('error', () => reLoadImage(img));
  };

  function setImageAsBroken() {
    setImageUrl(Config.assets.tintLogoSVG);
    setImageValid(false);
    setPending(false);
  }

  useEffect(() => {
    if (imageUrl || alternativeImgUrl) {
      loadImage(imageUrl);
    } else {
      setImageAsBroken();
    }

    return () => {};
  }, [imageUrl, alternativeImgUrl]);

  return {
    newImageUrl: newImage,
    isImageValid: isImageValid,
    isPending,
  };
};

export default useImageValidation;
