import React, { Component } from 'react';

import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { EditUploadDesign } from '../../../';
import { EditColorInput } from '../../../';
import { RangeInput } from '../../../../../../../components/interface/inputs/range-input/range-input';

export class EditFooterStyles extends Component {
  render() {
    return (
      <EditModalBody title='Display icons for your social accounts. Insert URLs to add social icons to the layout.'>
        <RangeInput
          value={this.props.data.styles.logoHeight}
          onChange={e => {
            this.props.styleDataUpdate({ logoHeight: e });
          }}
          label={'Logo size'}
          maxRange={'150'}
          minRange={'1'}
          step={'1'}
        />
        <EditColorInput
          color={this.props.data.styles.textColor}
          label='text color'
          onChange={value => {
            this.props.styleDataUpdate({ textColor: value });
          }}
        />
        <EditUploadDesign
          dataUpdate={this.props.dataUpdate}
          styleDataUpdate={this.props.styleDataUpdate}
          data={this.props.data}
        />
      </EditModalBody>
    );
  }
}
