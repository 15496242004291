import { ACTIONS } from '../actions/new-team.actions';
import { produce } from 'immer';

const initialState = {
  planDetails: {},
};

export const newTeamReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.PLAN_DETAILS.SUCCESS:
      return produce(state, draftState => {
        draftState.planDetails = action.payload;
        return draftState;
      });

    default:
      return state;
  }
};
