import { ACTIONS } from '../../actions/product-feeds/reupload-feeds-sidebar.actions';

export const initialState = {
  opened: false,
  productFeedId: undefined,
};

export const reuploadFeedsSidebar = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_REUPLOAD_SIDEBAR:
      return {
        ...state,
        opened: true,
        productFeedId: action.payload,
      };

    case ACTIONS.UPDATE_REUPLOAD_SIDEBAR:
      return {
        ...state,
        ...action.payload,
      };

    case ACTIONS.CLOSE_REUPLOAD_SIDEBAR:
      return {
        ...state,
        opened: false,
      };
    default:
      return state;
  }
};
