export const regexp = {
  ip: /^(([0-9]{1,3}\.){3}[0-9]{1,3})$/,
  tintName: /(.+?\/)/,
  formIds: /^[0-9A-Za-z-_]*$/,
  sitePattern: /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi,
  httpPattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,99}(:[0-9]{1,5})?(\/.*)?$/,
  simpleHttpPattern: /^(https?:\/\/)/,
  domainPattern: /^(www\.|www\.|)?[a-z0-9]+([-.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/,
  strictHttp: /^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/,
  sftp: /^(sftp:\/\/)([a-zA-Z0-9.-]+)(:[0-9]+)?(\/[a-zA-Z0-9\-._~%!$&'()*+,;=:@/]*)?$/,
  pricePattern: /^\d+(\.\d{1,2})?$/,
  formSection: {
    hours: /^([0-9]|1[011])$/,
    minutes: /^[0-5]?[0-9]$/,
  },
  timer: {
    hours: /^([0-9]|1[011])$/,
    minutes: /^([0-9]|[1-5][0-9])$/,
  },
  jsonPathID: /@\.id="([^"]+)"/,
  video: {
    facebook: /^https:\/\/www.facebook.com/,
    vimeo: /^https?:\/\/(www.)?(player.)?vimeo.com(\/video)?\/([0-9])+\/?(([\d]|[a-z])+)$/,
    youtube: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube\.com|youtu.be))(\/(?:[\w-]+\?v=|embed\/|v\/)?)([\w-]+)(\S+)?$/,
    twitch: /https?:\/\/(?:www.)?twitch\.tv\/(?:(videos)\/)?([a-zA-Z0-9][\w]{3,24})/i,
    tikTok: /^https?:\/\/(?:www\.)?tiktok\.com\/@.+?\/video\/(\d+)/i,
  },
  isTikTokEmbed: url => /tiktok-embed/.test(url),
  isYouTubeEmbed: url => /(?:youtube\.com\/(?:watch\?.*v=|embed)|youtu\.be\/)/.test(url),
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,6}))$/,
  numberOfDays: /^[1-9][0-9]*$/,
  slugNamePattern: /^[a-z][a-z0-9\-_]*(?:_[a-z0-9]+)*$/i,
  slugNameExcludeChars: /[^a-zA-Z0-9\-_]/g,
  htmlTag: /<\/?[a-z][\s\S]*>/i,
  lettersOnlyPattern: /[^a-zA-Z]/g,
  hexColorPattern: /^#([A-Fa-f0-9]{6})$/,
  rgbColorPattern: /rgb\(\s*(\d+(\.\d+)?%?)\s*,\s*(\d+(\.\d+)?%?)\s*,\s*(\d+(\.\d+)?%?)\s*\)/,
  rgbaColorPattern: /rgba\(\s*(\d+(\.\d+)?%?)\s*,\s*(\d+(\.\d+)?%?)\s*,\s*(\d+(\.\d+)?%?)\s*,\s*(1|0(\.\d+)?)\s*\)/,
  numbersAndDotsPattern: /[0-9.]+/gi,
  passwordStrength: {
    upperCase: /(?=.*[A-Z])/,
    lowerCase: /(?=.*[a-z])/,
    oldMinLength: /.{8,}/,
    minLength: /.{12,}/,
    notIdenticalChar: /(.+)\1\1/,
    specialChar: /[^A-Za-z0-9]/,
    digit: /(?=.*\d)/,
  },
  fontFamilyStyle: /(font-family)[:][\s]*[^;'">]*/g,
  tagsRegex: /[ ,\n]+/,
  chromeCastRegex: /(Chrome|CriOS)/g,
  gridTemplate: /(["'])(?:(?=(\\?))\2.)*?\1/g,
  phoneNumber: /^\+[1-9]\d{6,14}$/,
  numberWithCommasRegex: /\B(?=(\d{3})+(?!\d))/g,
  redirectUriRegex: /(^(urn:ietf:wg:oauth:2.0:oob)$)|(^(ftp|http|https):\/\/[^ "]+$)/,
  tintMix: {
    youtube: /(?:(?:v|vi|be|videos|embed)\/(?!videoseries)|(?:v|ci)=)([\w-]{11})/i,
    facebookVideo: /(?:https?:\/\/)?(?:www.|web.|m.)?(facebook|fb).(com|watch)\/(?:video.php\?v=\d+|(\S+)|photo.php\?v=\d+|\?v=\d+)|\S+\/videos\/((\S+)\/(\d+)|(\d+))\/?/,
    twitch: /(clips\.)?twitch\.tv\/(?:(?:videos\/(\d+))|(\w+)(?:\/clip\/(\S+))?)/i,
    vimeo: /(?:\/(?:channels\/[\w]+|(?:(?:album\/\d+|groups\/[\w]+)\/)?videos?))?\/(\d+)/i,
  },
  formBuilder: {
    youtubeURL: /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(?:-nocookie)?\.com|youtu.be))(\/(?:[\w]+\?v=|embed\/|live\/|v\/)?)([\w]+)(\S+)?$/,
    youtubeID: /^[a-zA-Z0-9_-]{11}$/,
  },
  hashtag: /^#[^#\s]+(?: #[^#\s]+)*$/,
};
