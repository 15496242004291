import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import * as PropTypes from 'prop-types';
import { EditUploadDesign } from '../../../../';

export class EditParagraphStyles extends Component {
  render() {
    return (
      <EditModalBody title='Customize this section with the color fields below.'>
        <EditUploadDesign
          dataUpdate={this.props.dataUpdate}
          styleDataUpdate={this.props.styleDataUpdate}
          data={this.props.data}
        />
      </EditModalBody>
    );
  }
}

EditParagraphStyles.propTypes = {
  dataUpdate: PropTypes.func,
  data: PropTypes.shape({
    backgroundColor: PropTypes.string,
  }),
};
