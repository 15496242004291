import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import './edit-modal.sass';

export class EditModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      animationType: null,
      isClosed: false,
    };

    this.timer = null;
  }

  saveData() {
    this.props.saveData().then(() => {
      this.props.dismissModal && this.props.dismissModal();
    });
    this.setState({ animationType: 'out' });
  }

  renderStep() {
    const childrenArray = React.Children.toArray(this.props.children);
    if (childrenArray[this.props.stepIndex] === undefined) {
      return React.cloneElement(childrenArray[0], {
        saveData: this.saveData.bind(this),
        closeModalMain: this.closeClick,
        dismissModal: this.dismissClick,
      });
    }
    return React.cloneElement(childrenArray[this.props.stepIndex], {
      saveData: this.saveData.bind(this),
      closeModalMain: this.closeClick,
      dismissModal: this.dismissClick,
    });
  }

  transitionEnd = e => {
    if (e.propertyName !== 'opacity' || this.state.animationType === 'in') return;

    if (this.state.animationType === 'out') {
      if (this.state.isClosed) {
        this.props.closeModal();
      } else {
        this.props.dismissModal && this.props.dismissModal();
      }
    }
  };

  dismissClick = () => {
    this.setState({ animationType: 'out', isClosed: false });
  };

  closeClick = () => {
    this.setState({ animationType: 'out', isClosed: true });
  };

  renderAnimationWrapper() {
    return (
      <div className={`edit-modal edit-modal--move-${this.state.animationType}`} data-test-id='edit-modal'>
        <div
          className={`edit-modal__animation-wrapper edit-modal__animation-wrapper--move-${this.state.animationType}`}
          onTransitionEnd={this.transitionEnd}>
          <div className='tint-edit-modal'>{this.renderStep()}</div>
        </div>
      </div>
    );
  }

  componentDidMount() {
    this.timer = setTimeout(() => this.setState({ animationType: 'in' }), 0);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  componentDidUpdate(prevProps) {
    if (!this.props.isVisible && prevProps.isVisible) {
      this.setState({ animationType: 'out' });
    }
  }

  render() {
    return ReactDOM.createPortal(this.renderAnimationWrapper(), document.querySelector('#tintup-edit-modal'));
  }
}

EditModal.propTypes = {
  isVisible: PropTypes.bool,
  dismissModal: PropTypes.func,
  saveData: PropTypes.func,
  closeModal: PropTypes.func,
  footer: PropTypes.object,
  id: PropTypes.string,
};
