import React, { Component } from 'react';
import './image-section.sass';
import { PageSectionHoc } from '../../../../../../../components/HOC/page-section/page-section.hoc';
import { EditModal } from '../../../../../../../components/edit-modal/edit-modal/edit-modal';
import { EditModalStep } from '../../../../../../../components/edit-modal/edit-modal-step/edit-modal-step';
import ColorParser from '../../../../../../../services/color-parser/color-parser';
import { ImageContent } from './edit/image-content';
import { EditModalSwitcher } from '../../../../../../../components/edit-modal/edit-modal-switcher/edit-modal-switcher';
import { ImageStyles } from './edit/image-styles';

class ImageSection extends Component {
  render() {
    return (
      <div
        className={`tint-page-section tint-page-section--image tint-custom-section tint-custom-section--image ${this.props.getSectionClass()}`}
        style={{
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`,
        }}>
        {this.props.renderGenericComponents()}
        <img
          className='tint-page-section-media__thumb'
          src={this.props.data.content.mediaUrl}
          alt={this.props.data.content.mediaUrl}
          style={{
            width: `${this.props.data.styles.imageWidth}%`,
          }}
        />
        {this.props.isModalOpen && (
          <EditModal
            nextStep={this.props.nextStep}
            isVisible={this.props.isModalOpen}
            saveData={this.props.onDataSave}
            dismissModal={this.props.dismissModal}
            closeModal={this.props.closeModal}>
            <EditModalStep title='Edit Image'>
              <EditModalSwitcher>
                <ImageContent
                  data={this.props.data}
                  saveData={this.props.onDataSave}
                  styleDataUpdate={this.props.onStylesUpdate}
                  onContentUpdate={this.props.onContentUpdate}
                  maxFileSize={this.props.getMaxImageSizePlanLimit()}
                />
                <ImageStyles
                  data={this.props.data}
                  saveData={this.props.onDataSave}
                  dataUpdate={this.props.onContentUpdate}
                  styleDataUpdate={this.props.onStylesUpdate}
                />
              </EditModalSwitcher>
            </EditModalStep>
          </EditModal>
        )}
      </div>
    );
  }
}

export default PageSectionHoc(ImageSection);
