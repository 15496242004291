import React, { useEffect, useState } from 'react';
import './filter-tags.sass';
import TintIcon from '../icon/icon';
import PropTypes from 'prop-types';
import { faArrowsFromDottedLine, faArrowsToDottedLine } from '@fortawesome/pro-solid-svg-icons';

const TintFilterTags = ({ list = [], onTagSelect }) => {
  const tagListLimit = 10;
  const [selectedList, setSelectedList] = useState(list);
  const [isExpended, setExpended] = useState(false);
  const isLimitReached = selectedList.length > tagListLimit;

  useEffect(() => {
    setSelectedList(list);
  }, [list]);

  const onTagClick = selectedTag => {
    const mapSelectedList = selectedList.map(tag => ({
      ...tag,
      selected: tag.value === selectedTag.value && tag.name === selectedTag.name ? !tag.selected : tag.selected,
    }));

    setSelectedList(mapSelectedList);
    onTagSelect && onTagSelect(selectedTag);
  };

  const renderToggleButtons = () => {
    return isExpended ? (
      <li className='tint-filter-tags__tag tint-filter-tags__tag--show-more' onClick={() => setExpended(!isExpended)}>
        <TintIcon icon={faArrowsToDottedLine} />
        <span>Show Less</span>
      </li>
    ) : (
      <li className='tint-filter-tags__tag tint-filter-tags__tag--show-more' onClick={() => setExpended(!isExpended)}>
        <TintIcon icon={faArrowsFromDottedLine} />
        <span>Show More</span>
      </li>
    );
  };

  const renderElement = (tag, i) => (
    <li
      className={`tint-filter-tags__tag ${tag.selected ? 'tint-filter-tags__tag--selected' : ''}`}
      key={i}
      onClick={() => onTagClick(tag)}>
      {tag.icon ? (
        <TintIcon color={tag?.style?.color} icon={tag.icon} />
      ) : tag.svgIconSrc ? (
        <TintIcon
          style={{ filter: tag.selected ? 'grayscale(1)' : 'grayscale(1) invert(1)' }}
          svgIconSrc={tag.svgIconSrc}
        />
      ) : null}
      <span>{tag.name}</span>
    </li>
  );

  return (
    <div className='tint-filter-tags'>
      <ul className='tint-filter-tags__list'>
        {selectedList.map((tag, i) =>
          isLimitReached && !isExpended ? (i < tagListLimit ? renderElement(tag, i) : null) : renderElement(tag, i)
        )}
        {isLimitReached && renderToggleButtons()}
      </ul>
    </div>
  );
};

export default TintFilterTags;

TintFilterTags.propTypes = {
  list: PropTypes.arrayOf(PropTypes.object),
  onTagSelect: PropTypes.func,
};
