import { ExperienceType } from '../../../model/experience.type';

export const ExperienceConfig = {
  [ExperienceType.POLL]: {
    maximumVoteAmount: 10,
    defaultPostCount: 9,
  },
  languages: {
    defaultLanguage: {
      value: 'en-US',
      name: 'English (US)',
      direction: 'ltr',
    },
  },
  pageSize: {
    mobile: 480,
    tablet: 768,
    desktop: 1170,
  },
  defaultExperiencePageSize: 25,
};
