import React from 'react';
import './tag.sass';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';
import TintButtonIcon, { TintButtonIconTypes } from '../button-icon/button-icon';

export const Tag = ({ tag, isSelected, onClick, onRemove }) => {
  return (
    <span className={`tint-tag ${isSelected ? 'tint-tag--selected' : ''}`} onClick={onClick}>
      {tag}
      <TintButtonIcon
        type={TintButtonIconTypes.NONE}
        icon={faTimes}
        fontSize='16px'
        onClick={e => {
          e.stopPropagation();
          onRemove();
        }}
      />
    </span>
  );
};
