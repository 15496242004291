import React from 'react';
import './form-builder.form-empty.sass';
import { Typography, TypographyVariants } from '../../../../../components/interface/typography/typography';

const FormBuilderFormEmpty = () => {
  return (
    <div className='form-builder-form-empty'>
      <Typography className='form-builder-form-empty__title' variant={TypographyVariants.H2}>
        Blank Form
      </Typography>
      <Typography className='form-builder-form-empty__caption' variant={TypographyVariants.DESCRIPTION}>
        Add at least 1 field to the form to get started.
      </Typography>
    </div>
  );
};
FormBuilderFormEmpty.displayName = 'FormBuilderFormEmpty';
export default React.memo(FormBuilderFormEmpty);
