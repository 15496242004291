import { createPrefixedActionName, createRoutine } from '../../services/functions/redux-routines/redux-routines';

export const ACTIONS = {
  GET_TEAMS: createRoutine(createPrefixedActionName('TINT', 'GET_TEAMS')),
  PATCH_TEAM: createRoutine(createPrefixedActionName('TINT', 'PATCH_TEAM')),
};

export const getTeamsStart = () => ({
  type: ACTIONS.GET_TEAMS.REQUEST,
});

export const getTeamsSuccess = data => ({
  type: ACTIONS.GET_TEAMS.SUCCESS,
  payload: data,
});

export const getTeamsError = err => ({
  type: ACTIONS.GET_TEAMS.FAILURE,
  payload: err,
});

export const patchTeamStart = () => ({
  type: ACTIONS.PATCH_TEAM.REQUEST,
});

export const patchTeamSuccess = data => ({
  type: ACTIONS.PATCH_TEAM.SUCCESS,
  payload: data,
});

export const patchTeamError = err => ({
  type: ACTIONS.PATCH_TEAM.FAILURE,
  payload: err,
});
