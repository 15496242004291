import { personalization } from './personalization.reducer';
import { exportPosts } from './export-posts.reducer';
import { instintUpdates } from './instint-updates.reducer';
import { tintEditor } from './tint-editor.reducer';
import { tintEditorSidebar } from './tint-editor-sidebar.reducer';
import { tintEditorPosts } from './posts.reducer';
import { comments } from './comments.reducer';

const tintEditorReducer = {
  personalization,
  exportPosts,
  instintUpdates,
  tintEditor,
  tintEditorSidebar,
  tintEditorPosts,
  comments,
};

export default tintEditorReducer;
