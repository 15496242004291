import { ACTIONS } from '../../actions/profile/profile.actions';
import produce from 'immer';

export const initialState = {
  data: undefined,
  error: undefined,
  isPending: false,
  twoFactorAuth: {
    data: [],
    isPending: false,
    error: undefined,
  },
  confirmPasswordModal: {
    isVisible: false,
    data: undefined,
  },
};

export const personalSettings = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.PATCH_USER_PROFILE.REQUEST:
      return {
        ...state,
        error: undefined,
        isPending: true,
      };

    case ACTIONS.POST_TWO_FACTOR_AUTH.REQUEST:
    case ACTIONS.PATCH_TWO_FACTOR_AUTH.REQUEST:
    case ACTIONS.GET_AUTHENTICATION_FACTORS.REQUEST:
      return {
        ...state,
        twoFactorAuth: {
          ...state.twoFactorAuth,
          error: undefined,
          isPending: true,
        },
      };

    case ACTIONS.POST_TWO_FACTOR_AUTH.FAILURE:
    case ACTIONS.GET_AUTHENTICATION_FACTORS.FAILURE:
      return {
        ...state,
        twoFactorAuth: {
          ...state.twoFactorAuth,
          error: action.payload,
          isPending: false,
        },
      };

    case ACTIONS.PATCH_USER_PROFILE.FAILURE:
      return {
        ...state,
        error: action.payload,
        isPending: false,
      };

    case ACTIONS.PATCH_USER_PROFILE.SUCCESS:
      return {
        ...state,
        data: action.payload,
        error: undefined,
        isPending: false,
      };

    case ACTIONS.GET_AUTHENTICATION_FACTORS.SUCCESS:
      return {
        ...state,
        twoFactorAuth: {
          error: undefined,
          isPending: false,
          data: action.payload,
        },
      };

    case ACTIONS.PATCH_TWO_FACTOR_AUTH.FAILURE:
      return {
        ...state,
        twoFactorAuth: {
          ...state.twoFactorAuth,
          error: action.payload,
          isPending: false,
        },
      };

    case ACTIONS.DELETE_TWO_FACTOR_AUTH.SUCCESS:
      return produce(state, draft => {
        draft.twoFactorAuth.data = draft.twoFactorAuth.data.filter(factorType => factorType.id !== action.payload);
        draft.twoFactorAuth.isPending = false;
      });

    case ACTIONS.PATCH_TWO_FACTOR_AUTH.SUCCESS:
      return produce(state, draft => {
        const filterFactors = draft.twoFactorAuth.data.filter(factor => factor.id !== action.payload.id);
        draft.twoFactorAuth.data = [...filterFactors, action.payload];
        draft.twoFactorAuth.isPending = false;
      });

    case ACTIONS.POST_TWO_FACTOR_AUTH.SUCCESS:
      return produce(state, draft => {
        draft.twoFactorAuth.isPending = false;
        draft.twoFactorAuth.data.push(action.payload);
      });

    case ACTIONS.CLOSE_CONFIRM_MODAL:
      return produce(state, draft => {
        draft.confirmPasswordModal = initialState.confirmPasswordModal;
      });

    case ACTIONS.OPEN_CONFIRM_MODAL:
      return produce(state, draft => {
        draft.confirmPasswordModal = action.payload;
      });

    default:
      return state;
  }
};
