import React, { useState } from 'react';
import './edit-tint-content-new.sass';
import '../../../../../../../components/sidebar/sidebar.sass';
import { useSelector } from 'react-redux';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import PreloaderComponent, {
  PreloaderPositionType,
} from '../../../../../../../components/interface/preloaders/preloader/preloader';
import DropdownSortList from '../../../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list';
import DropdownButton, { ButtonType } from '../../../../../../../components/interface/dropdown/button/dropdown-button';
import { DropdownHeader } from '../../../../../../../components/interface/dropdown/button/header/dropdown-header';
import { InputGroup } from '../../../../../../../components/interface/inputs/input-group/input-group';
import InputPrimary from '../../../../../../../components/interface/inputs/input-primary/input-primary';
import SelectPersonalizationWrapper from '../../../../../../../components/wrappers/select-personalization-wrapper/select-personalization-wrapper';
import SelectTintWrapper from '../../../../../../../components/wrappers/select-tint-wrapper/select-tint-wrapper';
import useSavedFilters from '../../../../../../../components/hooks/use-saved-filters/use-saved-filters';

const clickForMore = {
  name: 'Click for More Button',
  value: '{"data-clickformore":"true"}',
  description: 'More posts will load on your TINT when a button is clicked.',
};

const pageByPage = {
  name: 'Page by Page',
  value: '{"data-paginate":"true"}',
  description: 'A fixed number of posts is shown, with buttons to navigate from page to page.',
};

const infinityScroll = {
  name: 'Infinite Scroll',
  value: '{"data-infinitescroll":"true" }',
  description: 'More posts will load automatically on your TINT as you scroll down.',
};

const filters = {
  name: 'Select Filter',
  value: '{}',
};

const tintHeightType = [
  {
    name: 'Fixed',
    value: 'Fixed',
    isHeightInputDisplayed: true,
  },
  {
    name: 'Unlimited',
    value: 'Unlimited',
    isHeightInputDisplayed: false,
  },
];

export const EditTintContentNew = ({ dataUpdate, data }) => {
  const selectedTintSlug = data.content.tintName;
  const selectedPersonalization = data.content.personalizationId;
  const heightType = tintHeightType.find(element => element.value === data.content.heightType);
  const selectedTintId = useSelector(state => state.account?.tint?.id);
  const savedFilters = useSelector(state => state.tintEditorPosts.filters);
  const savedFiltersList = useSavedFilters({ tintId: selectedTintId });

  const isFetchingTints = useSelector(state => state.tintEditor.isFetching);
  const isFetchingPersonalizations = useSelector(state => state.personalization.isPending);

  const [tints, setTints] = useState([]);
  const [isHeightInputDisplayed, setIsHeightInputDisplayed] = useState(
    heightType ? heightType.isHeightInputDisplayed : false
  );

  const tintDisplayTypes = isHeightInputDisplayed
    ? [clickForMore, pageByPage, infinityScroll]
    : [clickForMore, infinityScroll];

  const currentDisplayType = data.content.displayType
    ? tintDisplayTypes.find(e => e.value === data.content.displayType)
    : clickForMore;

  const mapSavedFilters = data => {
    return data?.id ? { name: data?.attributes?.name, value: data?.id } : filters;
  };

  const currentSavedFilter =
    data.content.savedFilterId && savedFilters
      ? mapSavedFilters(savedFilters?.find(e => e.id === data.content.savedFilterId))
      : filters;

  const currentDescription = data.content.displayType
    ? tintDisplayTypes.find(e => e.value === data.content.displayType)?.description
    : '';

  const onTintChange = e => {
    return dataUpdate({
      tintName: e.slugName,
      savedFilterId: e.slugName !== data.content.tintName ? null : data.content.savedFilterId,
    });
  };

  const onLoadTints = ({ tintList = [] } = {}) => {
    const loadedTints = [...tints, ...tintList];
    setTints(loadedTints);
  };

  const renderDropdownTints = () => {
    return (
      <SelectTintWrapper
        currentItemSlug={!selectedTintId && selectedTintSlug}
        currentItemId={selectedTintId}
        onLoadIncludedItems={onLoadTints}
        onChange={onTintChange}
      />
    );
  };

  const renderDropdownPersonalizations = () => {
    return (
      <SelectPersonalizationWrapper
        currentItemId={selectedPersonalization}
        onChange={e => dataUpdate({ personalizationId: e.value })}
      />
    );
  };

  const renderDropdownHeightType = () => {
    return (
      <>
        <InputGroup label='Height Type'>
          <DropdownButton
            currentItem={heightType}
            dropdownHeader={props => <DropdownHeader {...props} />}
            dropdownList={props => <DropdownSortList {...props} />}
            list={tintHeightType}
            placeholder='Select Personalization'
            iconRight='fa fa-caret-down'
            buttonType={ButtonType.BUTTON_SORT}
            onChangeValue={e => {
              const value = e.value;
              const isHeightInputDisplayed = tintHeightType.find(type => type.value === value)?.isHeightInputDisplayed;
              setIsHeightInputDisplayed(isHeightInputDisplayed);
              dataUpdate({ heightType: value });
            }}
          />
        </InputGroup>
        {isHeightInputDisplayed ? (
          <InputGroup label='Height'>
            <InputPrimary
              name='height'
              type='text'
              value={data.content.height}
              placeholder='500'
              handleChange={e => dataUpdate({ height: e.target.value })}
            />
          </InputGroup>
        ) : null}
      </>
    );
  };

  const renderDropdownDisplayType = () => {
    return (
      <>
        <InputGroup label='Display Type'>
          <DropdownButton
            currentItem={currentDisplayType}
            dropdownHeader={props => <DropdownHeader {...props} />}
            dropdownList={props => <DropdownSortList {...props} />}
            list={tintDisplayTypes}
            placeholder='Select Display Type'
            iconRight='fa fa-caret-down'
            buttonType={ButtonType.BUTTON_SORT}
            onChangeValue={e => dataUpdate({ displayType: e.value })}
          />
        </InputGroup>
        <span className='tint-edit-input-dropdown-container__description'>{currentDescription}</span>
      </>
    );
  };

  const renderDropdownNoColumns = () => {
    return (
      <InputGroup label='# of columns'>
        <InputPrimary
          name='height'
          type='text'
          value={data.content.ofColumns}
          placeholder='example: 6'
          handleChange={e => {
            dataUpdate({ ofColumns: e.target.value });
          }}
        />
      </InputGroup>
    );
  };

  const renderDropdownSavedFilters = () => {
    return (
      <InputGroup label='Saved Filters'>
        <DropdownButton
          currentItem={currentSavedFilter}
          dropdownHeader={props => <DropdownHeader {...props} />}
          dropdownList={props => <DropdownSortList {...props} />}
          list={savedFiltersList}
          placeholder='Select Filter'
          iconRight='fa fa-caret-down'
          buttonType={ButtonType.BUTTON_SORT}
          onChangeValue={e => dataUpdate({ savedFilterId: e.id })}
        />
      </InputGroup>
    );
  };

  return (
    <EditModalBody title='Connect your TINT board by selecting a TINT board and a personalization from the lists below.'>
      {(isFetchingTints || isFetchingPersonalizations) && (
        <PreloaderComponent positionType={PreloaderPositionType.ABSOLUTE} />
      )}
      {renderDropdownTints()}
      {renderDropdownPersonalizations()}

      <p className='tint-edit-modal-body__label'>Advanced Settings</p>
      {renderDropdownHeightType()}
      {renderDropdownDisplayType()}
      {renderDropdownNoColumns()}
      {renderDropdownSavedFilters()}
    </EditModalBody>
  );
};
