import * as ReactDOM from 'react-dom';
import React, { useRef, useEffect, useState } from 'react';
import './modal.sass';
import PropTypes from 'prop-types';
import { keyCodes } from '../hooks/use-key-down/keyCodes';

const TintModalZIndex = 1050;
export const TintModal = ({
  className = '',
  children,
  onClose,
  onConfirm,
  isOpen,
  styles = {},
  animationTime = 0.3,
  isBackDropVisible = true,
  headerTitle,
  modalHeader,
  modalFooter,
  closeFooterButtonText,
  confirmFooterButtonText,
  isButtonDisabled,
  modalDOMDestination = document.body,
  zIndex,
  blockESCKey = false,
  fullScreen = false,
}) => {
  const bodyElement = document.querySelector('body');
  const [isModalOpen, setModalVisibility] = useState(isOpen);
  const [animationState, setAnimationState] = useState(isOpen);
  const modalRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setModalVisibility(true);
      setAnimationState(true);
    }

    if (!isOpen && isModalOpen) {
      onCloseClick();
    }
  }, [isOpen]);

  const onCloseClick = () => {
    if (blockESCKey) return;
    setModalVisibility(false);

    setTimeout(() => {
      onClose && onClose();
    }, animationTime * 1000);
  };

  useEffect(() => {
    isOpen ? bodyElement.classList.add('body-hidden-scroll') : bodyElement.classList.remove('body-hidden-scroll');
  }, [isOpen, bodyElement.classList]);

  useEffect(() => {
    return () => {
      setAnimationState(false);
      bodyElement.classList.remove('body-hidden-scroll');
    };
  }, [modalRef]);

  useEffect(() => {
    if (!modalRef?.current) return;
    document?.addEventListener('keydown', onKeyDown);

    return () => {
      document?.removeEventListener('keydown', onKeyDown);
    };
  }, [modalRef, blockESCKey]);

  const onKeyDown = key => {
    switch (key.which) {
      case keyCodes.escape:
        onCloseClick();
        break;

      default:
        return;
    }
  };

  const onAnimationEnd = () => {
    if (!isModalOpen) {
      setAnimationState(false);
    }
  };

  const getAnimationClose = () => {
    return styles.animation && styles.animation.close ? styles.animation.close : 'defaultModalAnimationOut';
  };

  const getAnimationOpen = () => {
    return styles.animation && styles.animation.open ? styles.animation.open : 'defaultModalAnimationIn';
  };

  const renderHeader = () => {
    return (
      modalHeader &&
      modalHeader({
        title: headerTitle,
        onCloseClick: onCloseClick,
      })
    );
  };

  const onConfirmClick = () => {
    onConfirm && onConfirm();
    onCloseClick();
  };

  const renderFooter = () => {
    return (
      modalFooter &&
      modalFooter({
        onCloseClick: onCloseClick,
        onConfirm: onConfirmClick,
        isDisabled: isButtonDisabled,
        closeButtonText: closeFooterButtonText,
        confirmButtonText: confirmFooterButtonText,
      })
    );
  };

  const noComponentModalClass = !modalFooter && !modalHeader ? 'tint-modal--no-component' : '';

  const onOutsideClick = () => {
    onCloseClick();
  };

  return (
    animationState &&
    ReactDOM.createPortal(
      <div
        ref={modalRef}
        style={{ zIndex: zIndex ? TintModalZIndex + zIndex : TintModalZIndex }}
        className={`tint-modal ${className} ${noComponentModalClass} ${fullScreen ? 'tint-modal--full-screen' : ''}`}>
        {isBackDropVisible && <div onClick={onOutsideClick} className='tint-modal-backdrop' />}
        <div
          className='tint-modal__dialog'
          style={{
            animation: `${isModalOpen ? getAnimationOpen() : getAnimationClose()} ${animationTime}s`,
          }}
          onAnimationEnd={onAnimationEnd}>
          <div className='tint-modal__content'>
            {renderHeader()}
            <div className='tint-modal__body' style={{ minHeight: styles.minHeight, width: styles.width }}>
              {children}
            </div>
            {renderFooter()}
          </div>
        </div>
      </div>,
      modalDOMDestination
    )
  );
};

export default TintModal;

TintModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.arrayOf(PropTypes.node)]),
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
  styles: PropTypes.shape({
    backgroundColor: PropTypes.string,
    animation: PropTypes.object,
    width: PropTypes.number,
    minHeight: PropTypes.number,
  }),
  modalDOMDestination: PropTypes.any,
};
