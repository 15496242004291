import { createPrefixedActionName, createRoutine } from '../../../services/functions/redux-routines/redux-routines';

export const ACTIONS = {
  GET_AUDIENCES: createRoutine(createPrefixedActionName('AUDIENCE_BUILDER', 'GET_AUDIENCES')),
  GET_MEMBERS: createRoutine(createPrefixedActionName('AUDIENCE_BUILDER', 'GET_MEMBERS')),
  DELETE_MEMBER: createRoutine(createPrefixedActionName('AUDIENCE_BUILDER', 'DELETE_MEMBER')),
  BULK_DELETE_MEMBERS: createRoutine(createPrefixedActionName('AUDIENCE_BUILDER', 'BULK_DELETE_MEMBERS')),
  SELECT_MEMBER: createPrefixedActionName('AUDIENCE_BUILDER', 'SELECT_MEMBER'),
  UNSELECT_MEMBER: createPrefixedActionName('AUDIENCE_BUILDER', 'UNSELECT_MEMBER'),
  DESELECT_ALL_MEMBERS: createPrefixedActionName('AUDIENCE_BUILDER', 'DESELECT_ALL_MEMBERS'),
  OPEN_MEMBERS_FILTER: createPrefixedActionName('AUDIENCE_BUILDER', 'OPEN_MEMBERS_FILTER'),
  CLOSE_MEMBERS_FILTER: createPrefixedActionName('AUDIENCE_BUILDER', 'CLOSE_MEMBERS_FILTER'),
  UPDATE_MEMBERS_FILTER: createPrefixedActionName('AUDIENCE_BUILDER', 'UPDATE_MEMBERS_FILTER'),
  CLEAR_MEMBERS_FILTER: createPrefixedActionName('AUDIENCE_BUILDER', 'CLEAR_MEMBERS_FILTER'),
  GET_EXPORT_MEMBERS: createRoutine(createPrefixedActionName('AUDIENCE_BUILDER', 'GET_EXPORT_MEMBERS')),
  GET_EXPORT_MEMBERS_STATUS: createRoutine(createPrefixedActionName('AUDIENCE_BUILDER', 'GET_EXPORT_MEMBERS_STATUS')),
  GET_IMPORT_MEMBERS: createRoutine(createPrefixedActionName('AUDIENCE_BUILDER', 'GET_IMPORT_MEMBERS')),
  GET_IMPORT_MEMBERS_STATUS: createRoutine(createPrefixedActionName('AUDIENCE_BUILDER', 'GET_IMPORT_MEMBERS_STATUS')),

  DELETE_AUDIENCE: createRoutine(createPrefixedActionName('AUDIENCE_BUILDER', 'DELETE_AUDIENCE')),
  BULK_DELETE_AUDIENCES: createRoutine(createPrefixedActionName('AUDIENCE_BUILDER', 'BULK_DELETE_AUDIENCES')),
  SELECT_AUDIENCE: createPrefixedActionName('AUDIENCE_BUILDER', 'SELECT_AUDIENCE'),
  UNSELECT_AUDIENCE: createPrefixedActionName('AUDIENCE_BUILDER', 'UNSELECT_AUDIENCE'),
  DESELECT_ALL_AUDIENCES: createPrefixedActionName('AUDIENCE_BUILDER', 'DESELECT_ALL_AUDIENCES'),
  ADD_AUDIENCE: createRoutine(createPrefixedActionName('AUDIENCE_BUILDER', 'ADD_AUDIENCE')),
  UPDATE_AUDIENCE: createRoutine(createPrefixedActionName('AUDIENCE_BUILDER', 'UPDATE_AUDIENCE')),
  ADD_PERSON: createRoutine(createPrefixedActionName('AUDIENCE_BUILDER', 'ADD_PERSON')),
  GET_PERSON: createRoutine(createPrefixedActionName('AUDIENCE_BUILDER', 'GET_PERSON')),
};

export const getAudiencesStart = () => ({
  type: ACTIONS.GET_AUDIENCES.REQUEST,
});

export const getAudiencesSuccess = data => ({
  type: ACTIONS.GET_AUDIENCES.SUCCESS,
  payload: data,
});

export const getAudiencesError = err => ({
  type: ACTIONS.GET_AUDIENCES.FAILURE,
  payload: err,
});

export const getMembersStart = () => ({
  type: ACTIONS.GET_MEMBERS.REQUEST,
});

export const getMembersSuccess = data => ({
  type: ACTIONS.GET_MEMBERS.SUCCESS,
  payload: data,
});

export const getMembersError = err => ({
  type: ACTIONS.GET_MEMBERS.FAILURE,
  payload: err,
});

export const selectMember = member => ({
  type: ACTIONS.SELECT_MEMBER,
  payload: member,
});

export const unselectMember = id => ({
  type: ACTIONS.UNSELECT_MEMBER,
  payload: id,
});

export const deselectAllMembers = () => ({
  type: ACTIONS.DESELECT_ALL_MEMBERS,
});

export const bulkDeleteMembersStart = () => ({
  type: ACTIONS.BULK_DELETE_MEMBERS.REQUEST,
});

export const bulkDeleteMembersSuccess = ids => ({
  type: ACTIONS.BULK_DELETE_MEMBERS.SUCCESS,
  payload: ids,
});

export const bulkDeleteMembersError = err => ({
  type: ACTIONS.BULK_DELETE_MEMBERS.FAILURE,
  payload: err,
});

export const deleteMemberStart = () => ({
  type: ACTIONS.DELETE_MEMBER.REQUEST,
});

export const deleteMemberSuccess = id => ({
  type: ACTIONS.DELETE_MEMBER.SUCCESS,
  payload: id,
});

export const deleteMemberError = err => ({
  type: ACTIONS.DELETE_MEMBER.FAILURE,
  payload: err,
});

export const selectAudience = audience => ({
  type: ACTIONS.SELECT_AUDIENCE,
  payload: audience,
});

export const unselectAudience = id => ({
  type: ACTIONS.UNSELECT_AUDIENCE,
  payload: id,
});

export const deselectAllAudiences = () => ({
  type: ACTIONS.DESELECT_ALL_AUDIENCES,
});

export const bulkDeleteAudiencesStart = () => ({
  type: ACTIONS.BULK_DELETE_AUDIENCES.REQUEST,
});

export const bulkDeleteAudiencesSuccess = ids => ({
  type: ACTIONS.BULK_DELETE_AUDIENCES.SUCCESS,
  payload: ids,
});

export const bulkDeleteAudiencesError = err => ({
  type: ACTIONS.BULK_DELETE_AUDIENCES.FAILURE,
  payload: err,
});

export const deleteAudienceStart = () => ({
  type: ACTIONS.DELETE_AUDIENCE.REQUEST,
});

export const deleteAudienceSuccess = id => ({
  type: ACTIONS.DELETE_AUDIENCE.SUCCESS,
  payload: id,
});

export const deleteAudienceError = err => ({
  type: ACTIONS.DELETE_AUDIENCE.FAILURE,
  payload: err,
});

export const addAudienceStart = () => ({
  type: ACTIONS.ADD_AUDIENCE.REQUEST,
});

export const addAudienceSuccess = a => ({
  type: ACTIONS.ADD_AUDIENCE.SUCCESS,
  payload: a,
});

export const addAudienceError = err => ({
  type: ACTIONS.ADD_AUDIENCE.FAILURE,
  payload: err,
});

export const addPersonStart = () => ({
  type: ACTIONS.ADD_PERSON.REQUEST,
});

export const addPersonSuccess = p => ({
  type: ACTIONS.ADD_PERSON.SUCCESS,
  payload: p,
});

export const addPersonError = err => ({
  type: ACTIONS.ADD_PERSON.FAILURE,
  payload: err,
});

export const getPersonStart = () => ({
  type: ACTIONS.GET_PERSON.REQUEST,
});

export const getPersonSuccess = p => ({
  type: ACTIONS.GET_PERSON.SUCCESS,
  payload: p,
});

export const getPersonError = err => ({
  type: ACTIONS.GET_PERSON.FAILURE,
  payload: err,
});

export const updateAudienceStart = () => ({
  type: ACTIONS.UPDATE_AUDIENCE.REQUEST,
});

export const updateAudienceSuccess = p => ({
  type: ACTIONS.UPDATE_AUDIENCE.SUCCESS,
  payload: p,
});

export const updateAudienceError = err => ({
  type: ACTIONS.UPDATE_AUDIENCE.FAILURE,
  payload: err,
});

export const openFilters = () => ({
  type: ACTIONS.OPEN_MEMBERS_FILTER,
});

export const closeFilters = () => ({
  type: ACTIONS.CLOSE_MEMBERS_FILTER,
});

export const updateMembersFilter = payload => ({
  type: ACTIONS.UPDATE_MEMBERS_FILTER,
  payload,
});

export const clearMembersFilter = () => ({
  type: ACTIONS.CLEAR_MEMBERS_FILTER,
});

export const getExportMembersStart = () => ({
  type: ACTIONS.GET_EXPORT_MEMBERS.REQUEST,
});

export const getExportMembersSuccess = data => ({
  type: ACTIONS.GET_EXPORT_MEMBERS.SUCCESS,
  payload: data,
});

export const getExportMembersError = err => ({
  type: ACTIONS.GET_EXPORT_MEMBERS.FAILURE,
  payload: err,
});

export const getExportMembersStatusStart = () => ({
  type: ACTIONS.GET_EXPORT_MEMBERS_STATUS.REQUEST,
});

export const getExportMembersStatusSuccess = data => ({
  type: ACTIONS.GET_EXPORT_MEMBERS_STATUS.SUCCESS,
  payload: data,
});

export const getExportMembersStatusError = err => ({
  type: ACTIONS.GET_EXPORT_MEMBERS_STATUS.FAILURE,
  payload: err,
});

export const getImportMembersStart = () => ({
  type: ACTIONS.GET_IMPORT_MEMBERS.REQUEST,
});

export const getImportMembersSuccess = data => ({
  type: ACTIONS.GET_IMPORT_MEMBERS.SUCCESS,
  payload: data,
});

export const getImportMembersError = err => ({
  type: ACTIONS.GET_IMPORT_MEMBERS.FAILURE,
  payload: err,
});

export const getImportMembersStatusStart = () => ({
  type: ACTIONS.GET_IMPORT_MEMBERS_STATUS.REQUEST,
});

export const getImportMembersStatusSuccess = data => ({
  type: ACTIONS.GET_IMPORT_MEMBERS_STATUS.SUCCESS,
  payload: data,
});

export const getImportMembersStatusError = err => ({
  type: ACTIONS.GET_IMPORT_MEMBERS_STATUS.FAILURE,
  payload: err,
});
