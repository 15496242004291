import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  closeEditModal,
  closeOrderModal,
  openOrderModal,
  openEditModal,
  openOrderModalForMultiColumn,
} from '../../../actions/edit-modal';

//TODO PLS REFACTOR ME
export const ModalHoc = PassedComponent => {
  class Modal extends Component {
    constructor(props) {
      super(props);
      // since there are a lot of modals and no time to figure this out without hacks I've made local isModalOpen state
      this.state = {
        stepIndex: 0,
        isModalOpen: false,
        isOrderModalOpen: false,
      };
    }

    selectStep(stepIndex) {
      this.setState({ stepIndex: stepIndex });
    }

    nextStep() {
      this.setState({ stepIndex: this.state.stepIndex + 1 });
    }

    previousStep() {
      this.setState({ stepIndex: this.state.stepIndex - 1 });
    }

    onCloseModal() {
      this.setState({ isModalOpen: false, stepIndex: 0 });
      this.props.closeEditModal();
    }

    onDismissModal() {
      this.setState({ isModalOpen: false, stepIndex: 0 });
      this.props.closeEditModal();
    }

    componentWillUnmount() {
      if (this.state.isModalOpen) {
        this.props.closeEditModal();
      }
    }

    onOpenModal() {
      if (!this.state.isModalOpen && this.props.isEditModalOpened) {
        this.onCloseModal();
      }

      setTimeout(() => {
        this.props.openEditModal();
        this.setState({ isModalOpen: true });
      });
    }

    onOrderCloseModal = () => {
      this.setState({ isOrderModalOpen: false });
      this.props.closeOrderModal();
    };

    onOrderOpenModal = () => {
      if (!this.state.isOrderModalOpen && this.props.isOrderModalOpen) {
        this.props.closeOrderModal();
      }
      setTimeout(this.props.openOrderModal.bind(this));
      this.setState({ isOrderModalOpen: true });
    };

    componentWillReceiveProps(nextProps) {
      if (!nextProps.isEditModalOpened && this.state.isModalOpen) {
        this.setState({ isModalOpen: false, stepIndex: 0 });
      }
      if (!nextProps.isOrderModalOpen && this.state.isOrderModalOpen) {
        this.setState({ isOrderModalOpen: false });
      }
    }

    render() {
      return (
        <PassedComponent
          {...this.props}
          stepIndex={this.state.stepIndex}
          isModalOpen={this.state.isModalOpen}
          selectStep={this.selectStep.bind(this)}
          nextStep={this.nextStep.bind(this)}
          previousStep={this.previousStep.bind(this)}
          closeModal={this.onCloseModal.bind(this)}
          dismissModal={this.onDismissModal.bind(this)}
          openModal={this.onOpenModal.bind(this)}
          closeOrderModal={this.onOrderCloseModal}
          openOrderModal={this.onOrderOpenModal}
        />
      );
    }
  }

  const mapStateToProps = state => ({
    isEditModalOpened: state.editModal.isOpened,
    isOrderModalOpen: state.editModal.isOrderModal,
  });

  const mapDispatchToProps = dispatch => ({
    openEditModal: () => dispatch(openEditModal()),
    closeEditModal: () => dispatch(closeEditModal()),
    openOrderModal: () => dispatch(openOrderModal()),
    openOrderModalForMultiColumn: multiColumnData => dispatch(openOrderModalForMultiColumn(multiColumnData)),
    closeOrderModal: () => dispatch(closeOrderModal()),
  });

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(Modal);
};
