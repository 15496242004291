import React from 'react';
import { Config } from '../../../../../utils/config';

export const SectionLogo = ({ logoRedirectUrl, logoUrl, logoHeight }) => {
  const _logoUrl = logoUrl
    ? 'https://cdn.filestackcontent.com/auto_image/compress/' +
      logoUrl.replace('https://cdn.filestackcontent.com/', '')
    : '';
  const getImg = () => <img src={_logoUrl} style={{ height: `${logoHeight}px` }} />;

  return logoRedirectUrl ? (
    <a rel='noopener noreferrer' href={Config.getValidRedirectUrl(logoRedirectUrl)} target='_blank'>
      {getImg()}
    </a>
  ) : (
    getImg()
  );
};
