const { ApiService } = require('../../../../services/api-service/api-service');

import {
  getFilteredProductsStart,
  getFilteredProductsSuccess,
  getFilteredProductsError,
  getInfiniteLoadedProductsStart,
  getInfiniteLoadedProductsSuccess,
  getInfiniteLoadedProductsError,
  deleteProductStart,
  deleteProductSuccess,
  deleteProductError,
  postProductStart,
  postProductSuccess,
  postProductError,
  getProductsStart,
  getProductsSuccess,
  getProductsError,
  patchProductSuccess,
  patchProductError,
  patchProductStart,
  exportToCSVStart,
  exportToCSVSuccess,
  exportToCSVError,
  syncFeedStart,
  syncFeedSuccess,
  syncFeedError,
  getAssignedProductsStart,
  getAssignedProductsSuccess,
  getAssignedProductsError,
} from './products.actions';

export const getProducts = ({ url = '/products' } = {}) => {
  return dispatch => {
    dispatch(getProductsStart());

    return ApiService.get(url)
      .then(res => {
        dispatch(getProductsSuccess(res.data));
      })
      .catch(err => {
        dispatch(getProductsError(err));
        throw err;
      });
  };
};

export const getAssignedProducts = ({ url = '/products' } = {}) => {
  return dispatch => {
    dispatch(getAssignedProductsStart());

    return ApiService.get(url)
      .then(res => {
        dispatch(getAssignedProductsSuccess(res.data));
        return res.data;
      })
      .catch(err => {
        dispatch(getAssignedProductsError(err));
        throw err;
      });
  };
};

export const getInfiniteLoadedPosts = ({ url = '/products' } = { url: '/products' }) => {
  return dispatch => {
    dispatch(getInfiniteLoadedProductsStart());

    return ApiService.get(url)
      .then(res => {
        dispatch(getInfiniteLoadedProductsSuccess(res.data));
        return res.data;
      })
      .catch(err => {
        dispatch(getInfiniteLoadedProductsError(err));
        throw err;
      });
  };
};

export const getFilteredProducts = ({ url = '/products' } = { url: '/products' }) => {
  return dispatch => {
    dispatch(getFilteredProductsStart());

    return ApiService.get(url)
      .then(res => {
        dispatch(getFilteredProductsSuccess(res.data));
        return res.data;
      })
      .catch(err => {
        dispatch(getFilteredProductsError(err));
        throw err;
      });
  };
};

export const deleteProductAccount = ({ productId }) => {
  return dispatch => {
    const url = `/products/${productId}`;
    dispatch(deleteProductStart());

    return ApiService.delete(url)
      .then(() => {
        dispatch(deleteProductSuccess(productId));
      })
      .catch(err => {
        dispatch(deleteProductError(err));
        throw err;
      });
  };
};

export const addProduct = ({ url = '/products' } = {}) => {
  const data = {
    type: 'product',
    attributes: {},
  };
  return dispatch => {
    dispatch(postProductStart());

    return ApiService.post(url, { data })
      .then(res => {
        dispatch(postProductSuccess(res.data));
      })
      .catch(err => {
        dispatch(postProductError(err));
        throw err;
      });
  };
};

export const exportToCSV = ({ productFeedId }) => {
  const url = `/products.csv?filter[product_feed_ids]=${productFeedId}`;

  return dispatch => {
    dispatch(exportToCSVStart());

    return ApiService.get(url)
      .then(res => {
        dispatch(exportToCSVSuccess(res.data));
        return res.data;
      })
      .catch(err => {
        dispatch(exportToCSVError(err));
        throw err;
      });
  };
};

export const syncFeed = ({ productFeedId, csvUrl }) => {
  const url = `/product_feeds/${productFeedId}/product_feed_refresh_requests`;

  const data = {
    type: 'product_feed_refresh_request',
    attributes: {
      product_feed_id: productFeedId,
      url: csvUrl,
    },
  };

  return dispatch => {
    dispatch(syncFeedStart());

    return ApiService.post(url, { data })
      .then(res => {
        dispatch(syncFeedSuccess(res.data));
      })
      .catch(err => {
        dispatch(syncFeedError(err));
        throw err;
      });
  };
};

export const updateProduct = ({ productId, status }) => {
  const data = {
    attributes: {
      status,
      id: productId,
    },
    type: 'product',
  };

  const url = `/products/${productId}`;

  return dispatch => {
    dispatch(patchProductStart());

    return ApiService.patch(url, { data })
      .then(res => {
        dispatch(patchProductSuccess(res.data));
      })
      .catch(err => {
        dispatch(patchProductError(err));
        throw err;
      });
  };
};
