import { ACTIONS } from '../actions/social-publishing.actions';
import { ACTIONS as ACTIONS_CAMPAIGNS } from '../actions/social-publishing-campaigns.actions';
import produce from 'immer';

const initialState = {
  data: [],
  accounts: [],
  media: [],
  subAccounts: [],
  aggregations: {
    tags: [],
    accounts: [],
    subAccounts: [],
  },
  campaigns: [],
  error: false,
  isFetching: false,
  socialConnections: {
    accounts: [],
    subAccounts: [],
  },
};

export const socialPublishingCalendar = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_LONG_POOLING_SCHEDULED_CONTENT.FAILURE:
    case ACTIONS.GET_SCHEDULED_CONTENT.FAILURE:
      return {
        ...state,
        error: action.payload,
        isCalendarFetching: false,
      };

    case ACTIONS.GET_SCHEDULED_CONTENT_SOCIAL_CONNECTIONS.FAILURE:
    case ACTIONS.GET_SCHEDULED_CONTENT_AGGREGATIONS.FAILURE:
    case ACTIONS.PATCH_SCHEDULED_CONTENT.FAILURE:
    case ACTIONS.POST_SCHEDULED_CONTENT.FAILURE:
    case ACTIONS.DELETE_SCHEDULED_CONTENT.FAILURE:
    case ACTIONS_CAMPAIGNS.POST_CAMPAIGN.FAILURE:
    case ACTIONS_CAMPAIGNS.GET_CAMPAIGNS.FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        isPartialFetching: false,
      };

    case ACTIONS_CAMPAIGNS.POST_CAMPAIGN.SUCCESS:
      return {
        ...state,
        campaigns: [action.payload, ...state.campaigns],
        isPartialFetching: false,
      };

    case ACTIONS.POST_SCHEDULED_CONTENT.SUCCESS:
      return {
        ...state,
        data: [...state.data, action.payload?.data],
        accounts: removeDuplicates([
          ...state?.accounts,
          ...action.payload.included?.filter(el => el?.type === 'account'),
        ]),
        subAccounts: removeDuplicates([
          ...state?.subAccounts,
          ...action.payload.included?.filter(el => el?.type === 'subaccount'),
        ]),
        media: [...state?.media, ...action.payload?.included?.filter(el => el?.type === 'upload')],
        aggregations: {
          ...state.aggregations,
          accounts: [
            ...new Set([
              ...state.aggregations.accounts,
              ...action.payload.included?.filter(el => el?.type === 'account').map(el => el.id),
            ]),
          ],
          subAccounts: [
            ...new Set([
              ...state.aggregations.subAccounts,
              ...action.payload.included?.filter(el => el?.type === 'subaccount').map(el => el.id),
            ]),
          ],
        },
        isPartialFetching: false,
      };

    case ACTIONS.GET_SCHEDULED_CONTENT.SUCCESS:
      return {
        ...state,
        data: action.payload?.data,
        accounts: action.payload?.included?.filter(el => el?.type === 'account') || [],
        subAccounts: action.payload?.included?.filter(el => el?.type === 'subaccount') || [],
        media: action.payload?.included?.filter(el => el?.type === 'upload') || [],
        isCalendarFetching: false,
      };

    case ACTIONS.CLEAR_DATA:
      return {
        ...state,
        data: [],
        accounts: [],
        media: [],
        subAccounts: [],
      };

    case ACTIONS.GET_LONG_POOLING_SCHEDULED_CONTENT.SUCCESS:
      return {
        ...state,
        data: [...state?.data, ...action.payload?.data],
        accounts: [...state?.accounts, ...(action?.payload?.included?.filter(el => el?.type === 'account') || [])],
        subAccounts: [
          ...state?.subAccounts,
          ...(action?.payload?.included?.filter(el => el?.type === 'subaccount') || []),
        ],
        media: [...state?.media, action?.payload?.included?.filter(el => el?.type === 'upload') || []].flat(),
        isCalendarFetching: false,
      };

    case ACTIONS.PATCH_SCHEDULED_CONTENT.SUCCESS:
      return produce(state, draft => {
        const currentIndex = draft.data.findIndex(el => el.id === action.payload.id);

        draft.data[currentIndex] = action.payload.data;
        draft.accounts = [...state.accounts, ...action.payload?.included?.filter(el => el?.type === 'account')];
        draft.subAccounts = [
          ...state.subAccounts,
          ...action.payload?.included?.filter(el => el?.type === 'subaccount'),
        ];
        draft.media = [...state.media, ...action.payload?.included?.filter(el => el?.type === 'upload')];
        draft.isPartialFetching = false;
      });

    case ACTIONS.DELETE_SCHEDULED_CONTENT.SUCCESS:
      return produce(state, draft => {
        draft.data = draft.data.filter(content => content.id !== action.payload);
        draft.isPartialFetching = false;
      });

    case ACTIONS_CAMPAIGNS.GET_CAMPAIGNS.SUCCESS:
      return {
        ...state,
        campaigns: action.payload,
        isFetching: false,
      };

    case ACTIONS_CAMPAIGNS.PATCH_CAMPAIGN.SUCCESS:
      return produce(state, draft => {
        const currentIndex = draft.campaigns.findIndex(el => el.id === action.payload.id);

        draft.campaigns[currentIndex] = action.payload.data;
      });

    case ACTIONS_CAMPAIGNS.DELETE_CAMPAIGN.SUCCESS:
      return produce(state, draft => {
        draft.campaigns = draft.campaigns.filter(campaign => campaign.id !== action.payload);
      });

    case ACTIONS_CAMPAIGNS.DELETE_CAMPAIGN.REQUEST:
    case ACTIONS_CAMPAIGNS.PATCH_CAMPAIGN.REQUEST:
    case ACTIONS_CAMPAIGNS.DELETE_CAMPAIGN.FAILURE:
    case ACTIONS_CAMPAIGNS.PATCH_CAMPAIGN.FAILURE:
      return { ...state };

    case ACTIONS_CAMPAIGNS.POST_CAMPAIGN.REQUEST:
    case ACTIONS.PATCH_SCHEDULED_CONTENT.REQUEST:
    case ACTIONS.DELETE_SCHEDULED_CONTENT.REQUEST:
    case ACTIONS.POST_SCHEDULED_CONTENT.REQUEST:
      return {
        ...state,
        isPartialFetching: true,
      };

    case ACTIONS.GET_LONG_POOLING_SCHEDULED_CONTENT.REQUEST:
    case ACTIONS.GET_SCHEDULED_CONTENT.REQUEST:
      return {
        ...state,
        isCalendarFetching: true,
      };

    case ACTIONS.GET_SCHEDULED_CONTENT_SOCIAL_CONNECTIONS.REQUEST:
    case ACTIONS.GET_SCHEDULED_CONTENT_AGGREGATIONS.REQUEST:
    case ACTIONS_CAMPAIGNS.GET_CAMPAIGNS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case ACTIONS.GET_SCHEDULED_CONTENT_AGGREGATIONS.SUCCESS:
      return produce(state, draft => {
        const tags = action.payload.find(el => el.id === 'tags');
        const accountIds = action.payload.find(el => el.id === 'account_ids')?.attributes?.buckets;
        const subaccountsIds = action.payload.find(el => el.id === 'subaccount_ids')?.attributes?.buckets;

        const accountIdKeys = Object.keys(accountIds);
        const subaccountIdKeys = Object.keys(subaccountsIds);
        const mapSubaccountIds = subaccountIdKeys
          ?.map(key => {
            const idMap = key.split('|');
            const hasAccount = idMap.length > 2;

            return !hasAccount ? idMap[1] : idMap[2];
          })
          .filter(Boolean);

        draft.isFetching = false;

        draft.aggregations = {
          tags: Object.keys(tags?.attributes?.buckets),
          accounts: accountIdKeys,
          subAccounts: mapSubaccountIds,
        };
      });

    case ACTIONS.GET_SCHEDULED_CONTENT_SOCIAL_CONNECTIONS.SUCCESS:
      return produce(state, draft => {
        draft.socialConnections = {
          accounts: action.payload.data,
          subAccounts: action.payload?.included?.filter(el => el.type === 'subaccount'),
        };
      });

    default:
      return state;
  }
};

const removeDuplicates = data => Array.from(new Set(data.map(JSON.stringify))).map(JSON.parse);

export const isScheduledContentLoading = state => state?.socialPublishingCalendar?.isCalendarFetching;

export const getMappedCalendarData = state => {
  const data = state?.socialPublishingCalendar?.data;
  const accounts = state?.socialPublishingCalendar?.accounts;
  const subAccounts = state?.socialPublishingCalendar?.subAccounts;
  const media = state?.socialPublishingCalendar?.media;
  if (!data || !media || !accounts || !subAccounts) return;

  return data.map(el => {
    const mediaList = el?.relationships?.media?.data.map(asset => media.find(_media => _media.id === asset.id));
    const accountId = el?.relationships?.account?.data?.id;
    const currentAccount = accounts.find(account => account.id === accountId);
    const currentSubAccount = getSubAccountById(subAccounts, el?.relationships?.subaccount?.data?.id);

    return {
      ...el,
      campaignId: el?.relationships?.campaign?.data?.id,
      media: mediaList,
      socialAccount: currentAccount
        ? {
            name: currentAccount?.attributes?.name,
            accountId: currentAccount?.id,
            userName: currentAccount?.attributes?.username,
            meta: currentAccount?.attributes?.meta,
            subAccountName: currentSubAccount?.attributes?.name || currentSubAccount?.attributes?.username,
            type: currentAccount?.attributes?.type,
            imageUrl: currentSubAccount?.attributes?.image_url || currentAccount?.attributes?.image_url,
          }
        : null,
    };
  });
};

export const getSocialConnectionAccounts = state => {
  return state?.socialPublishingCalendar?.socialConnections?.accounts;
};

export const getSocialConnectionSubAccounts = state => {
  return state?.socialPublishingCalendar?.socialConnections?.subAccounts;
};

export const getAccounts = state => {
  return state?.socialPublishingCalendar?.accounts;
};

export const getSubAccounts = state => {
  return state?.socialPublishingCalendar?.subAccounts;
};

export const getSubAccountById = (subAccounts, id) => {
  if (!subAccounts || subAccounts.length === 0 || !id) return;

  return subAccounts.find(subAccount => subAccount.id === id);
};

export const getCampaignsData = state => {
  return state?.socialPublishingCalendar?.campaigns;
};

export const getAssetPreview = (state, id) => {
  const currentAsset = state?.socialPublishingCalendar?.media?.find(el => el.id === id);
  return currentAsset ? [currentAsset?.attributes.url] : [];
};

export const getAssetsPreview = (state, idsList) => {
  if (!idsList) return [];

  return idsList
    .map(id => state?.socialPublishingCalendar?.media.find(asset => asset.id === id)?.attributes?.url)
    .filter(Boolean);
};

export const getActiveCampaignsDropdownList = (state, currentDate = new Date().getTime()) => {
  return state?.socialPublishingCalendar?.campaigns
    .filter(el => new Date(el?.attributes.ends_at).getTime() > new Date(currentDate).getTime())
    .map(campaign => ({
      name: campaign?.attributes?.name,
      value: campaign.id,
    }));
};

export const getMappedCampaignsData = state => {
  return state?.socialPublishingCalendar?.campaigns?.map(campaign => ({
    id: campaign.id,
    name: campaign?.attributes.name,
    startDate: campaign?.attributes.starts_at,
    endDate: campaign?.attributes.ends_at,
    labelColor: campaign?.attributes.label_color,
  }));
};

export const getAggregations = state => {
  return state?.socialPublishingCalendar?.aggregations;
};

export const getScheduledContentMedia = state => {
  return state?.socialPublishingCalendar?.media;
};

export const getCurrentTags = state => {
  const aggregationsTags = state?.socialPublishingCalendar?.aggregations?.tags;
  const tags = state?.socialPublishingCalendar?.data?.map(el => el.attributes.tags).flat() || [];

  return [...new Set([...aggregationsTags, ...tags])].map(tag => ({
    name: tag,
    value: tag,
  }));
};
