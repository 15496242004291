import { ACTIONS } from '../../actions/social-connections/social-connections.actions';

export const initialState = {
  toggled: [],
};

export const socialFeedsToTransfer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_TO_TRANSFER:
      return {
        ...state,
        toggled: state.toggled.includes(action.payload)
          ? [...state.toggled.filter(id => id != action.payload)]
          : [...state.toggled, action.payload],
      };
    case ACTIONS.SELECT_ALL_TO_TRANSFER:
      return {
        ...state,
        toggled: [...action.payload],
      };
    case ACTIONS.CLEAR_TO_TRANSFER:
      return {
        ...state,
        toggled: [],
      };
    default:
      return state;
  }
};
