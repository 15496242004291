import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './range-input.sass';
import useDebounce from '../../../hooks/use-debounce/use-debounce';
import Slider from '@mui/material/Slider';
import { MuiSliderVariants } from '../../../../theme/components/slider';

export const RangeInputUnitType = {
  NONE: 'none',
  PX: 'px',
  PERCENT: '%',
};

export function RangeInput({
  label,
  onChange,
  value = 64,
  step = '8',
  maxRange = '800',
  minRange = '0',
  unit,
  isDisabled,
  labelClassName,
  wrapperClassName,
  multiple = 1,
  marks,
}) {
  const [inputValue, setInputValue] = useState(value);

  const getUnit = () => {
    switch (unit) {
      case RangeInputUnitType.PERCENT:
        return '%';

      case RangeInputUnitType.NONE:
        return '';

      case RangeInputUnitType.PX:
      default:
        return 'px';
    }
  };

  const debounceInputValue = useDebounce(inputValue * multiple, 10);

  useEffect(() => {
    if (inputValue !== value) {
      onChange(debounceInputValue.toString());
    }
  }, [debounceInputValue]);

  const onInputChange = e => {
    setInputValue(e.target.value);
  };

  return (
    <div className={'input-range'}>
      <div className={`input-range__label ${labelClassName}`}>{label ? <label>{label}</label> : null}</div>
      <div className={`input-range__wrapper ${wrapperClassName}`}>
        <span className={'input-range-value'}>{`${(value * multiple).toFixed()} ${getUnit()}`}</span>
        <Slider
          className={'input-range-slider'}
          min={Number(minRange)}
          max={Number(maxRange)}
          step={Number(step)}
          onChange={onInputChange}
          value={Number(value)}
          disabled={isDisabled}
          variant={marks ? MuiSliderVariants.markedTrackSlider : MuiSliderVariants.coloredProgressSlider}
          marks={marks}
        />
      </div>
    </div>
  );
}

RangeInput.propTypes = {
  label: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  step: PropTypes.string,
  maxRange: PropTypes.string,
  minRange: PropTypes.string,
  unit: PropTypes.string,
  isDisabled: PropTypes.bool,
  isDebounceAble: PropTypes.bool,
};
