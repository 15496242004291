import { ACTIONS } from '../../actions/list/form-builder-list.actions';
import { produce } from 'immer';

export const initialState = {
  forms: [],
  isFetching: false,
  isError: false,
  fetchingFormId: undefined,
  selectedForms: [],
  links: undefined,
};

export const formBuilderListReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.POST_FORM.REQUEST:
    case ACTIONS.GET_FORMS.REQUEST:
    case ACTIONS.BULK_DELETE_FORMS.REQUEST:
    case ACTIONS.BULK_UPDATE_FORMS_STATUS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case ACTIONS.DUPLICATE_FORM.REQUEST:
    case ACTIONS.DELETE_FORM.REQUEST:
    case ACTIONS.UPDATE_FORM.REQUEST:
      return {
        ...state,
        fetchingFormId: action.payload,
      };

    case ACTIONS.DUPLICATE_FORM.SUCCESS:
      return {
        ...state,
        forms: [action.payload, ...state.forms],
        fetchingFormId: false,
      };

    case ACTIONS.POST_FORM.SUCCESS:
      return {
        ...state,
        forms: [action.payload, ...state.forms],
        isFetching: false,
      };

    case ACTIONS.GET_FORMS.SUCCESS:
      return {
        ...state,
        forms: action.payload.data,
        links: action.payload.links,
        isFetching: false,
      };

    case ACTIONS.UPDATE_FORM.SUCCESS:
      return produce(state, draft => {
        const index = draft.forms.findIndex(form => form.id === action.payload.id);
        draft.forms[index] = action.payload.data;
        draft.fetchingFormId = undefined;
      });

    case ACTIONS.DUPLICATE_FORM.FAILURE:
    case ACTIONS.DELETE_FORM.FAILURE:
    case ACTIONS.UPDATE_FORM.FAILURE:
      return {
        ...state,
        fetchingFormId: undefined,
        isError: action.payload,
      };

    case ACTIONS.DELETE_FORM.SUCCESS:
      return {
        ...state,
        forms: [...state.forms.filter(form => form.id !== action.payload)],
        fetchingFormId: undefined,
        isFetching: false,
      };

    case ACTIONS.POST_FORM.FAILURE:
    case ACTIONS.GET_FORMS.FAILURE:
    case ACTIONS.BULK_DELETE_FORMS.FAILURE:
    case ACTIONS.BULK_UPDATE_FORMS_STATUS.FAILURE:
      return {
        ...state,
        isFetching: false,
        fetchingFormId: undefined,
        isError: action.payload,
      };

    case ACTIONS.SELECT_FORM:
      return produce(state, draft => {
        draft.selectedForms = [...draft.selectedForms, action.payload];
      });

    case ACTIONS.UNSELECT_FORM:
      return produce(state, draft => {
        draft.selectedForms = draft.selectedForms.filter(({ id }) => {
          return action.payload !== id;
        });
      });

    case ACTIONS.DESELECT_ALL_FORMS:
      return produce(state, draft => {
        draft.selectedForms = [];
      });

    case ACTIONS.BULK_DELETE_FORMS.SUCCESS:
      return produce(state, draftState => {
        draftState.forms = draftState.forms.filter(form => {
          return !action.payload.some(f => f.id === form.id);
        });
        draftState.isFetching = false;
        draftState.isError = false;
        draftState.selectedForms = [];
      });

    case ACTIONS.BULK_UPDATE_FORMS_STATUS.SUCCESS:
      return produce(state, draftState => {
        draftState.selectedForms = draftState.selectedForms.map(form => ({
          ...form,
          attributes: {
            ...form.attributes,
            status: form.attributes.status === 'active' ? 'archived' : 'active',
          },
        }));
        (draftState.forms = draftState.forms.map(form => {
          if (draftState.selectedForms.find(selectedForm => selectedForm.id === form.id)) {
            return {
              ...form,
              attributes: {
                ...form.attributes,
                status: form.attributes.status === 'active' ? 'archived' : 'active',
              },
            };
          }
          return form;
        })),
          (draftState.isFetching = false);
      });

    case ACTIONS.RESET_STATE:
      return initialState;

    default:
      return state;
  }
};
