export const keyCodes = {
  escape: 27,
  enter: 13,
  space: 32,
  comma: 188,
  backspace: 8,
  arrowLeft: 37,
  arrowTop: 38,
  arrowRight: 39,
  arrowBottom: 40,
  command: 91,
  ctrl: 17,
  aLetter: 65,
};
