import { Config } from '../../../utils/config';
import { TintEditorPaths } from './tint-editor.paths';

export const TintEditorConfig = {
  routePaths: TintEditorPaths,
  tintIdQueryParam: 'tintId',
  urlSearchParamState: 'page',
  embedScript: `https://www.tintup.${Config.isDevEnv() ? 'dev' : 'com'}/app/dist/embedded.js`,
  formsEmbedScript: `https://www.${Config.isStagingEnv() ? 'staging.' : ''}tintup.${
    Config.isDevEnv() ? 'dev' : 'com'
  }/app/dist/tint.js`,
  defaultBoardName: 'My first TINT Board',
  defaultSlugName: 'my_first_tint_board',
  defaultPersonalizationName: 'Untitled personalization',
};
