import { ACTIONS } from '../actions/personalization.action';
import produce from 'immer';

const defaultSidebarState = {
  isOpen: false,
  actionName: undefined,
  personalization: [],
};

const defaultCustomCSSState = {
  isOpen: false,
  type: undefined,
};

const initialState = {
  data: [],
  sidebar: defaultSidebarState,
  customCSS: defaultCustomCSSState,
  isPending: false,
  filters: {
    value: undefined,
    type: undefined,
    tags: undefined,
  },
  current: undefined,
  content: undefined,
  tagsList: [],
  selectedPersonalizations: [],
};

export const personalization = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.DELETE_PERSONALIZATION.REQUEST:
    case ACTIONS.POST_PERSONALIZATION.REQUEST:
    case ACTIONS.GET_PERSONALIZATION.REQUEST:
    case ACTIONS.GET_PERSONALIZATIONS.REQUEST:
    case ACTIONS.BULK_DELETE_PERSONALIZATIONS.REQUEST:
    case ACTIONS.BULK_UPDATE_PERSONALIZATIONS_STATUS.REQUEST:
      return {
        ...state,
        isPending: true,
      };
    case ACTIONS.GET_PERSONALIZATIONS.SUCCESS:
      return {
        ...state,
        tagsList: action.payload?.meta?.aggregations?.tags,
        data: action.payload.data,
        links: action.payload.links,
        isPending: false,
      };

    case ACTIONS.GET_PERSONALIZATION.SUCCESS:
      return {
        ...state,
        current: action.payload.data,
        isPending: false,
      };

    case ACTIONS.PATCH_PERSONALIZATION.SUCCESS:
      return produce(state, draft => {
        draft.current = action.payload.data;
        draft.data = state.data
          ? state.data.map(personalization =>
              personalization.id !== action.payload.data.id ? personalization : action.payload.data
            )
          : undefined;
      });

    case ACTIONS.POST_PERSONALIZATION.SUCCESS:
      return produce(state, draft => {
        draft.data = [action.payload.data, ...draft.data];
        draft.isPending = false;
      });

    case ACTIONS.DELETE_PERSONALIZATION.SUCCESS:
      return produce(state, draft => {
        draft.data = draft.data.filter(personalization => personalization.id !== action.payload);
        draft.isPending = false;
      });

    case ACTIONS.SHOW_PERSONALIZATION_SIDEBAR:
      return {
        ...state,
        sidebar: {
          actionName: action.payload.actionName,
          isOpen: true,
          personalization: action.payload.personalization,
        },
      };
    case ACTIONS.HIDE_PERSONALIZATION_SIDEBAR:
      return {
        ...state,
        sidebar: {
          isOpen: false,
          actionName: undefined,
          personalization: undefined,
        },
      };
    case ACTIONS.SHOW_CUSTOM_CSS:
      return {
        ...state,
        customCSS: {
          type: action.payload,
          isOpen: true,
        },
      };
    case ACTIONS.HIDE_CUSTOM_CSS:
      return {
        ...state,
        customCSS: {
          isOpen: false,
          type: undefined,
        },
      };
    case ACTIONS.FILTER_PERSONALIZATION_LIST:
      return {
        ...state,
        filters: action.payload,
      };
    case ACTIONS.UPDATE_CONTENT:
      return {
        ...state,
        content: action.payload,
      };

    case ACTIONS.SELECT_PERSONALIZATION:
      return produce(state, draft => {
        draft.selectedPersonalizations = [...draft.selectedPersonalizations, action.payload];
      });

    case ACTIONS.UNSELECT_PERSONALIZATION:
      return produce(state, draft => {
        draft.selectedPersonalizations = draft.selectedPersonalizations.filter(({ id }) => {
          return action.payload !== id;
        });
      });

    case ACTIONS.DESELECT_ALL_PERSONALIZATIONS:
      return produce(state, draft => {
        draft.selectedPersonalizations = [];
      });

    case ACTIONS.BULK_DELETE_PERSONALIZATIONS.SUCCESS:
      return produce(state, draftState => {
        draftState.data = draftState.data.filter(personalization => {
          return !action.payload.some(p => p.id === personalization.id);
        });
        draftState.isPending = false;
        draftState.isError = false;
        draftState.selectedPersonalizations = [];
      });

    case ACTIONS.BULK_UPDATE_PERSONALIZATIONS_STATUS.SUCCESS:
      return produce(state, draftState => {
        draftState.selectedPersonalizations = draftState.selectedPersonalizations.map(personalization => ({
          ...personalization,
          attributes: {
            ...personalization.attributes,
            status: personalization.attributes.status === 'active' ? 'archived' : 'active',
          },
        }));
        draftState.isPending = false;
        draftState.data = draftState.data.map(personalization => {
          if (
            draftState.selectedPersonalizations.find(
              selectedPersonalization => selectedPersonalization.id === personalization.id
            )
          ) {
            return {
              ...personalization,
              attributes: {
                ...personalization.attributes,
                status: personalization.attributes.status === 'active' ? 'archived' : 'active',
              },
            };
          }
          return personalization;
        });
      });

    case ACTIONS.BULK_DELETE_PERSONALIZATIONS.FAILURE:
    case ACTIONS.BULK_UPDATE_PERSONALIZATIONS_STATUS.FAILURE:
      return {
        ...state,
        isPending: false,
      };

    default:
      return state;
  }
};
