import { useState, useEffect } from 'react';

const usePercent = (firstValue, secondValue) => {
  const [percentValue, setPercentValue] = useState('');
  const hundredPercent = 100;

  function getPercent(firstValue, secondValue) {
    const sumPercent = firstValue + secondValue;
    const leftSidePercent = Math.round((firstValue * hundredPercent) / sumPercent);
    const rightSidePercent = Math.round(hundredPercent - leftSidePercent);
    setPercentValue({
      firstValue: leftSidePercent,
      secondValue: rightSidePercent,
    });
  }

  useEffect(() => {
    getPercent(firstValue !== 0 ? firstValue : 0, secondValue !== 0 ? secondValue : 0);
  }, [firstValue, secondValue]);

  return {
    percentValue,
  };
};

export default usePercent;
