import { ACTIONS } from '../../actions/marketing-integrations/marketing-integrations.actions';

export const initialState = {
  data: [],
  links: undefined,
  isFetching: false,
  error: undefined,
};

export const marketingIntegrations = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_MARKETING_INTEGRATIONS.SUCCESS:
      return {
        data: action.payload.data,
        links: action.payload.links,
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.POST_MARKETING_INTEGRATION.SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.DELETE_MARKETING_INTEGRATION.SUCCESS:
      return {
        ...state,
        data: state.data.filter(e => e.id !== action.payload.id),
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.REFRESH_MARKETING_INTEGRATION.SUCCESS:
      return {
        ...state,
        data: state.data.map(e => (e.id === action.payload.id ? action.payload : e)),
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.GET_MARKETING_INTEGRATIONS.REQUEST:
    case ACTIONS.DELETE_MARKETING_INTEGRATION.REQUEST:
    case ACTIONS.REFRESH_MARKETING_INTEGRATION.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };

    case ACTIONS.GET_MARKETING_INTEGRATIONS.FAILURE:
    case ACTIONS.POST_MARKETING_INTEGRATION.FAILURE:
    case ACTIONS.DELETE_MARKETING_INTEGRATION.FAILURE:
    case ACTIONS.REFRESH_MARKETING_INTEGRATION.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
