export const isModalOpen = (state, id) => {
  if (!state) return false;

  return !!state?.ui?.modals[id];
};
export const getModalData = (state, id) => {
  if (!state) return false;

  return state?.ui?.modals[id];
};

export const isSidebarOpen = (state, id) => {
  if (!state) return false;

  return !!state?.ui?.sidebars[id];
};

export const getSidebarData = (state, id) => {
  if (!state) return false;

  return {
    ...state?.ui?.sidebars[id],
    isOpen: isSidebarOpen(state, id),
  };
};

export const getDropdownData = (state, id, fetchFnMapper, filterDataFn = Boolean) => {
  if (!state) return false;

  const dropdownData = state?.ui?.dropdowns?.[id];

  return {
    ...dropdownData,
    data:
      dropdownData?.query?.length > 0
        ? dropdownData?.searchResult?.filter(filterDataFn)?.map(el => ({ value: el.id, name: el.attributes.name }))
        : mapDropdownList(dropdownData?.data, fetchFnMapper, filterDataFn),
    isAbleToLoad: dropdownData?.links?.next && dropdownData?.data?.length % 25 === 0 && !dropdownData?.query,
    page: Math.round(dropdownData?.data?.length / 25),
  };
};

const mapDropdownList = (data, fetchFnMapper, filterDataFn) => data?.filter(filterDataFn)?.map(fetchFnMapper) || [];
