import { ACTIONS } from '../../actions/product-feeds/add-product-feeds-modal.actions';

export const MODAL_TYPE = {
  FACEBOOK: 'facebook',
  MAGENTO: 'magento',
  FTP: 'ftp',
  SFTP: 'sftp',
  SHOPIFY: 'shopify',
  WOOCOMMERCE: 'woocommerce',
  BIGCOMMERCE: 'bigcommerce',
  CSV: 'csv',
  CUSTOM: 'custom',
  SALESFORCE_COMMERCE_CLOUD: 'salesforce_commerce_cloud',
  HTTP: 'http',
  AWS: 'aws_s3',
  BAZAARVOICE: 'bazaarvoice',
};

export const MODAL_STEPS = {
  FIRST: 'first',
  SECOND: 'second',
  THIRD: 'third',
};

export const initialState = {
  opened: false,
  step: undefined,
  type: '',
};

export const addProductFeedsModal = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_MODAL:
      return {
        ...state,
        opened: true,
        step: MODAL_STEPS.FIRST,
        type: '',
      };

    case ACTIONS.UPDATE_MODAL:
      return {
        ...state,
        ...action.payload,
      };

    case ACTIONS.CLOSE_MODAL:
      return {
        ...state,
        opened: false,
        step: undefined,
        type: '',
      };
    default:
      return state;
  }
};

export const selectCurrentActiveProductFeedType = state => state.type;
export const selectCurrentActiveProductFeedStep = state => state.step;
