import { createRoutine, createPrefixedActionName } from '../../../../services/functions/redux-routines/redux-routines';
import { CUSTOM_DOMAINS } from '../../consts';

export const ACTIONS = {
  GET_CUSTOM_DOMAIN_EXPERIENCES: createRoutine(
    createPrefixedActionName(CUSTOM_DOMAINS, 'GET_CUSTOM_DOMAIN_EXPERIENCES')
  ),
  PATCH_CUSTOM_DOMAIN_EXPERIENCES: createRoutine(
    createPrefixedActionName(CUSTOM_DOMAINS, 'PATCH_CUSTOM_DOMAIN_EXPERIENCES')
  ),
  GET_CUSTOM_DOMAINS: createRoutine(createPrefixedActionName(CUSTOM_DOMAINS, 'GET_CUSTOM_DOMAINS')),
  DELETE_CUSTOM_DOMAIN: createRoutine(createPrefixedActionName(CUSTOM_DOMAINS, 'DELETE_CUSTOM_DOMAIN')),
  POST_CUSTOM_DOMAIN: createRoutine(createPrefixedActionName(CUSTOM_DOMAINS, 'POST_CUSTOM_DOMAIN')),
};

export const getCustomDomainExperiencesStart = () => ({
  type: ACTIONS.GET_CUSTOM_DOMAIN_EXPERIENCES.REQUEST,
});

export const getCustomDomainExperiencesSuccess = domainList => ({
  type: ACTIONS.GET_CUSTOM_DOMAIN_EXPERIENCES.SUCCESS,
  payload: domainList,
});

export const getCustomDomainExperiencesError = err => ({
  type: ACTIONS.GET_CUSTOM_DOMAIN_EXPERIENCES.FAILURE,
  payload: err,
});

export const patchCustomDomainExperiencesStart = () => ({
  type: ACTIONS.PATCH_CUSTOM_DOMAIN_EXPERIENCES.REQUEST,
});

export const patchCustomDomainExperiencesSuccess = domain => ({
  type: ACTIONS.PATCH_CUSTOM_DOMAIN_EXPERIENCES.SUCCESS,
  payload: domain,
});

export const patchCustomDomainExperiencesError = err => ({
  type: ACTIONS.PATCH_CUSTOM_DOMAIN_EXPERIENCES.FAILURE,
  payload: err,
});

export const getCustomDomainsStart = () => ({
  type: ACTIONS.GET_CUSTOM_DOMAINS.REQUEST,
});

export const getCustomDomainsSuccess = domainList => ({
  type: ACTIONS.GET_CUSTOM_DOMAINS.SUCCESS,
  payload: domainList,
});

export const getCustomDomainsError = err => ({
  type: ACTIONS.GET_CUSTOM_DOMAINS.FAILURE,
  payload: err,
});

export const deleteCustomDomainStart = () => ({
  type: ACTIONS.DELETE_CUSTOM_DOMAIN.REQUEST,
});

export const deleteCustomDomainSuccess = id => ({
  type: ACTIONS.DELETE_CUSTOM_DOMAIN.SUCCESS,
  payload: id,
});

export const deleteCustomDomainError = err => ({
  type: ACTIONS.DELETE_CUSTOM_DOMAIN.FAILURE,
  payload: err,
});

export const postCustomDomainStart = () => ({
  type: ACTIONS.POST_CUSTOM_DOMAIN.REQUEST,
});

export const postCustomDomainSuccess = domainDetails => ({
  type: ACTIONS.POST_CUSTOM_DOMAIN.SUCCESS,
  payload: domainDetails,
});

export const postCustomDomainError = err => ({
  type: ACTIONS.POST_CUSTOM_DOMAIN.FAILURE,
  payload: err,
});
