import React from 'react';
import * as PropTypes from 'prop-types';
import { Config } from '../../../../../../../utils/config';
import ColorParser from '../../../../../../../services/color-parser/color-parser';
import './wysiwyg-content-editor.sass';
import { keyCodes } from '../../../../../../../components/hooks/use-key-down/keyCodes';
import { commandIdList } from '../wysiwyg-navigation/wysiwyg-navigation-options';
import DOMPurify from 'dompurify';

const WysiwygContentEditor = ({
  style,
  value,
  onBlur,
  onChange,
  onUpdateContentProperties,
  contentEditableRef,
  onSelectAll,
  textDirection,
}) => {
  const countClickToSelectWholeText = 3;

  const camelCase = string => {
    return string.replace(/-([a-z])/gi, (all, letter) => letter.toUpperCase());
  };

  const backgroundColor = style?.backgroundColor || { a: 0, b: 255, g: 255, r: 255 };

  DOMPurify.addHook('afterSanitizeAttributes', node => {
    const allowTags = ['SPAN', 'DIV', 'P', 'A', 'BR', 'FONT', 'UL', 'LI', 'OL'];

    if (allowTags.includes(node.tagName)) {
      for (let i = 0; i < Object.keys(node.style).length; i++) {
        if (!isNaN(Number(Object.keys(node.style)[i]))) {
          if (Config.regExp.strictHttp.test(node.style[node.style[[i]]])) {
            const styleCamelCase = camelCase(Object.values(node.style)[i]);
            node.style[styleCamelCase] = '';
          }
        } else {
          break;
        }
      }
    } else {
      node.remove();
    }
  });

  function newContent() {
    const cleanHTMLScripts = DOMPurify.sanitize(value);
    return (
      <div
        dir={textDirection}
        onBlur={() => {
          setTimeout(() => {
            onBlur();
          });
        }}
        onInput={() => {
          setTimeout(() => {
            onChange(getContentValue());
          });
        }}
        onKeyDown={onSelectAllByKeyboard}
        ref={contentEditableRef}
        contentEditable={true}
        suppressContentEditableWarning={true}
        className='tint-wysiwyg-editor'
        onClick={e => {
          onUpdateContentProperties();
          if (e.detail === countClickToSelectWholeText) {
            onSelectAll(commandIdList.SELECT_ALL);
          }
        }}
        dangerouslySetInnerHTML={{
          __html: cleanHTMLScripts,
        }}
      />
    );
  }

  function getContentValue() {
    const contentValues = contentEditableRef.current;
    return contentValues && contentValues.innerHTML;
  }

  function onSelectAllByKeyboard(e) {
    if (e.keyCode === keyCodes.command || e.keyCode === keyCodes.ctrl) {
      if (keyCodes.ctrl && keyCodes.aLetter) {
        onSelectAll(commandIdList.SELECT_ALL);
      }
    }
  }

  function oldContent() {
    const cleanHTMLScripts = DOMPurify.sanitize(value);

    return (
      <div
        onBlur={() => {
          setTimeout(() => {
            onChange(getContentValue());
            onBlur();
          });
        }}
        onInput={() => {
          setTimeout(() => {
            onChange(getContentValue());
          });
        }}
        onKeyDown={onSelectAllByKeyboard}
        ref={contentEditableRef}
        contentEditable={true}
        suppressContentEditableWarning={true}
        className='tint-wysiwyg-editor'
        onClick={e => {
          onUpdateContentProperties();
          if (e.detail === countClickToSelectWholeText) {
            onSelectAll(commandIdList.SELECT_ALL);
          }
        }}
        //Firefox only
        style={{
          textAlign: style?.textAlign,
          fontFamily: Config.fonts.secondaryFont,
        }}>
        {style?.urlLink ? (
          <a href={style?.urlLink} target='_blank' rel='noopener noreferrer'>
            <span
              style={{
                fontFamily: `'${style?.fontFamily?.name}'`,
                textAlign: style?.textAlign,
                fontStyle: style?.fontStyle,
                textDecoration: style?.textDecoration,
                backgroundColor: ColorParser.defaultColor(backgroundColor),
                fontWeight: style?.fontWeight === 500 ? 400 : style?.fontWeight,
                color: ColorParser.defaultColor(style?.color),
                fontSize: style?.fontSize,
              }}>
              {cleanHTMLScripts}
            </span>
          </a>
        ) : (
          <span
            style={{
              fontFamily: `'${style?.fontFamily?.name}'`,
              textAlign: style?.textAlign,
              fontStyle: style?.fontStyle,
              textDecoration: style?.textDecoration,
              backgroundColor: ColorParser.defaultColor(backgroundColor),
              fontWeight: style?.fontWeight === 500 ? 400 : style?.fontWeight,
              color: ColorParser.defaultColor(style?.color),
              fontSize: style?.fontSize,
            }}>
            {cleanHTMLScripts}
          </span>
        )}
      </div>
    );
  }

  function renderWysiwygElement() {
    return Config.regExp.htmlTag.test(value) ? newContent() : oldContent();
  }

  return renderWysiwygElement();
};

export default WysiwygContentEditor;

WysiwygContentEditor.propTypes = {
  style: PropTypes.object,
  value: PropTypes.string,
  onChange: PropTypes.func,
  onUpdateContentProperties: PropTypes.func,
  contentEditableRef: PropTypes.object,
  onSelectAll: PropTypes.func,
};
