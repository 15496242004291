import { ACTIONS } from '../actions/experiences';
import { produce } from 'immer';

const initialState = {
  data: undefined,
  isFetching: false,
  error: undefined,
  selectedExperiences: [],
};

const experiences = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_EXPERIENCES.REQUEST:
    case ACTIONS.UPDATE_EXPERIENCES_STATUS.REQUEST:
    case ACTIONS.DELETE_EXPERIENCES.REQUEST:
      return produce(state, draft => {
        draft.isFetching = true;
        draft.isError = false;
      });

    case ACTIONS.GET_EXPERIENCES.SUCCESS:
      return produce(state, draft => {
        draft.isFetching = false;
        draft.isError = false;
        draft.data = action.payload;
      });

    case ACTIONS.GET_EXPERIENCES.FAILURE:
    case ACTIONS.DELETE_EXPERIENCES.FAILURE:
    case ACTIONS.UPDATE_EXPERIENCES_STATUS.FAILURE:
      return produce(state, draft => {
        draft.isFetching = false;
        draft.isError = action.payload;
      });

    case ACTIONS.SELECT_EXPERIENCE:
      return produce(state, draft => {
        draft.selectedExperiences = [...draft.selectedExperiences, action.payload];
      });

    case ACTIONS.UNSELECT_EXPERIENCE:
      return produce(state, draft => {
        draft.selectedExperiences = draft.selectedExperiences.filter(({ id }) => {
          return action.payload !== id;
        });
      });

    case ACTIONS.UNSELECT_ALL_EXPERIENCES:
      return produce(state, draft => {
        draft.selectedExperiences = [];
      });

    case ACTIONS.UPDATE_EXPERIENCES_STATUS.SUCCESS:
      return produce(state, draft => {
        draft.selectedExperiences = draft.selectedExperiences.map(exp => ({
          ...exp,
          attributes: {
            ...exp.attributes,
            status: exp.attributes.status === 'active' ? 'archived' : 'active',
          },
        }));
        draft.data.data = draft.data.data.map(exp => {
          if (draft.selectedExperiences.find(selectedExperiences => selectedExperiences.id === exp.id)) {
            return {
              ...exp,
              attributes: {
                ...exp.attributes,
                status: exp.attributes.status === 'active' ? 'archived' : 'active',
              },
            };
          }
          return exp;
        });
      });

    case ACTIONS.DELETE_EXPERIENCES.SUCCESS:
      return produce(state, draft => {
        draft.selectedExperiences = [];
        draft.isFetching = false;
        draft.isError = false;
        draft.data.data = draft.data.data.filter(data => !action.payload.some(d => d.id === data.id));
      });

    default:
      return state;
  }
};

export default experiences;
