import store from '../../../../store';

export const prepareExperienceObject = experience => {
  const _experience = JSON.parse(JSON.stringify(experience));
  delete _experience.id;
  delete _experience.attributes.updated_at;
  delete _experience.attributes.created_at;
  delete _experience.relationships;
  return _experience;
};

export const getPageSectionIndex = pageSectionKey => {
  let state = store.getState();
  return state.experience.dataDraft.attributes.data.draft.findIndex(e => e.sectionType === pageSectionKey);
};
