import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
//import { render } from 'react-dom';
import { hydrate } from 'react-dom';
import { Provider } from 'react-redux';
import store from '../public/js/react/store';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../app/css/reset.sass';
import '../app/css/main.sass';
import '../public/js/react/containers/experience.sass';
import TingPages from './tint-pages';
require('formdata-polyfill');

hydrate(
  <Provider store={store}>
    <TingPages />
  </Provider>,
  document.getElementById('tint-app')
);
