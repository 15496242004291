import React, { useEffect, useRef, useState } from 'react';
import './search-input.sass';
import SearchList, { SearchListType } from './search-list/search-list';
import * as PropTypes from 'prop-types';
import useClickOutside from '../../../../../components/hooks/use-click-outside/use-click-outside';
import { keyCodes } from '../../../../../components/hooks/use-key-down/keyCodes';

export const SearchInput = ({ images, videos, onChange, onValueChange, onEnter, searchPhrase }) => {
  const [searchTerm, setSearchTerm] = useState(searchPhrase);
  const node = useRef();
  const [status, setStatus] = useClickOutside(node);

  const [imagesList, setObjectsList] = useState(images);
  const [videosList, setTagList] = useState(videos);

  useEffect(() => {
    images && setObjectsList(findSearchTerm(images));
    videos && setTagList(findSearchTerm(videos));
  }, [searchTerm, images, videos]);

  const findSearchTerm = array => {
    return !searchTerm
      ? array
      : array.filter(
          item => item.name !== null && item.name?.toLowerCase?.()?.includes(searchTerm.toLocaleLowerCase())
        );
  };

  const onItemSelect = (value, type) => {
    setSearchTerm(value.name);
    setStatus(false);
    onChange(mapSelectedItem(value, type));
  };

  const onInputChange = event => {
    if (event.target.value !== '') {
      setStatus(true);
    } else {
      setStatus(false);
    }
    setSearchTerm(event.target.value);
    onValueChange(event.target.value);
  };

  const mapSelectedItem = (item, type) => {
    return {
      value: item ? item.value : '',
      name: item ? item.name : '',
      type: type,
      isActive: true,
    };
  };

  const onClearInput = () => {
    setSearchTerm('');
    onItemSelect(mapSelectedItem(undefined), SearchListType.NONE);
  };

  const onKeyDown = event => {
    if (event.keyCode === keyCodes.enter) {
      if (event.target.value === '') {
        onItemSelect(mapSelectedItem(undefined), SearchListType.NONE);
      } else {
        onEnter && onEnter(event.target.value);
      }
      setStatus(false);
      event.target.blur();
    }
  };

  return (
    <div className='tint-search-input' ref={node}>
      <span className='icon far fa-search' />
      <input
        placeholder='Search...'
        value={searchTerm}
        onChange={onInputChange}
        data-testid='dropdownSearchInput'
        onKeyDown={onKeyDown}
      />
      {searchTerm.length > 0 ? (
        <span className='icon icon-clear fal fa-times' onClick={onClearInput} data-testid='inputClearButton' />
      ) : null}

      {status ? (
        <SearchList images={imagesList} videos={videosList} searchTerm={searchTerm} onChange={onItemSelect} />
      ) : null}
    </div>
  );
};

SearchInput.propTypes = {
  images: PropTypes.array,
  videos: PropTypes.array,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  onEnter: PropTypes.func,
};
