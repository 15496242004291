import { ACTIONS } from '../../actions/request-rights/request-rights.actions';
import { REQUEST_RIGHTS_SOURCE, REQUEST_RIGHTS_STEP } from '../../components/request-rights/request-rights';
import produce from 'immer';

export const initialState = {
  requestRightsData: undefined,
  requestRightsPostData: undefined,
  postMessageData: undefined,
  termsData: undefined,
  step: REQUEST_RIGHTS_STEP.PRIMARY,
  isFetching: false,
  data: [],
  termsAndConditions: {},
  modal: {
    hashtag: '',
    template: '',
    templateName: '',
    right_request_term_id: '',
    source: '',
    user_id: '',
    external_id: '',
    account_id: '',
    subaccount_id: '',
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.DELETE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.REQUEST:
    case ACTIONS.POST_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.REQUEST:
    case ACTIONS.UPDATE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.REQUEST:
    case ACTIONS.GET_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.REQUEST:
      return {
        ...state,
        termsAndConditions: {
          ...state.termsAndConditions,
          isFetching: true,
        },
      };

    case ACTIONS.POST_REQUEST_RIGHTS.REQUEST:
    case ACTIONS.GET_REQUEST_RIGHTS_TERMS.REQUEST:
      return {
        ...state,
        isFetching: true,
      };

    case ACTIONS.POST_REQUEST_RIGHTS.SUCCESS:
      return {
        ...state,
        requestRightsPostData: action.payload,
      };
    case ACTIONS.SET_MODAL_PROGRESS:
      return {
        ...state,
        modal: { ...state.modal, ...action.payload },
      };
    case ACTIONS.OPEN_REQUEST_RIGHTS:
      return {
        ...state,
        postMessageData: action.payload,
      };

    case ACTIONS.CLOSE_REQUEST_RIGHTS:
      return {
        ...initialState,
        termsData: state.termsData,
      };

    case ACTIONS.SET_REQUEST_RIGHT_STEP:
      return {
        ...state,
        step: action.payload,
      };

    case ACTIONS.DELETE_REQUEST_RIGHTS_TEMPLATE.SUCCESS:
      return produce(state, draft => {
        draft.requestRightsData.data.filter(el => el.id !== action.payload);
      });

    case ACTIONS.DELETE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.SUCCESS:
      return produce(state, draft => {
        draft.termsAndConditions.data = draft.termsAndConditions.data.filter(el => el.id !== action.payload);
        draft.termsAndConditions.isFetching = false;
      });

    case ACTIONS.POST_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.SUCCESS:
      return produce(state, draft => {
        draft.termsAndConditions.data = [action.payload.data, ...draft.termsAndConditions.data];
        draft.termsAndConditions.isFetching = false;
      });

    case ACTIONS.UPDATE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.SUCCESS:
      return produce(state, draft => {
        draft.termsAndConditions.data[action.payload.data.id] = action.payload.data;
        draft.termsAndConditions.isFetching = false;
      });

    case ACTIONS.GET_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.SUCCESS:
      return produce(state, draft => {
        draft.termsAndConditions = action.payload;
        draft.termsAndConditions.isFetching = false;
      });

    case ACTIONS.POST_REQUEST_RIGHTS_TEMPLATE.SUCCESS:
      return produce(state, draft => {
        draft.requestRightsData?.data.push(action.payload.data);
      });

    case ACTIONS.GET_REQUEST_RIGHTS_TEMPLATES.SUCCESS:
      return {
        ...state,
        requestRightsData: action.payload,
      };

    case ACTIONS.DELETE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.FAILURE:
    case ACTIONS.POST_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.FAILURE:
    case ACTIONS.UPDATE_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.FAILURE:
    case ACTIONS.GET_REQUEST_RIGHTS_TERMS_AND_CONDITIONS.FAILURE:
      return {
        ...state,
        termsAndConditions: {
          ...state.termsAndConditions,
          isFetching: false,
          error: action.payload,
        },
      };

    case ACTIONS.POST_REQUEST_RIGHTS.FAILURE:
    case ACTIONS.GET_REQUEST_RIGHTS_TERMS.FAILURE:
      return {
        ...state,
        isFetching: false,
      };

    case ACTIONS.GET_REQUEST_RIGHTS_TERMS.SUCCESS:
      return {
        ...state,
        termsData: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const isCommentSectionFilled = state => {
  const modal = state.modal;
  if (modal.source === REQUEST_RIGHTS_SOURCE.INSTAGRAM_BUSINESS) {
    return !!(modal.subaccount_id && modal.hashtag && modal.template);
  }
  return !!(modal.source && modal.hashtag && modal.template);
};

export const isTermsSectionFilled = state => {
  const modal = state.modal;
  return !!modal.right_request_term_id;
};
