import React, { forwardRef } from 'react';
import { useDispatch } from 'react-redux';
import './form-field-item.sass';
import * as PropTypes from 'prop-types';
import { EditInput } from '../../../../index';
import { transformToSendAble } from '../../form-helper';
import { EditFormFieldRenderer } from '../../edit/edit-form-field-renderer';
import EditCheckbox from '../../../../edit-checkbox/edit-checkbox';

const FormFieldItem = forwardRef(
  (
    {
      item,
      isDragging,
      data,
      getTranslation,
      onTranslationUpdate,
      handleChange,
      errors,
      keyInputUpdate,
      removeField,
      checkBoxValue,
      onArrayPropertyChange,
      index,
      dismissEditModal,
    },
    componentRef
  ) => {
    const dispatch = useDispatch();

    const renderField = ({ e, i, errors, handleChange }) => {
      return EditFormFieldRenderer.render({
        getTranslation,
        e,
        i,
        data,
        onTranslationUpdate,
        handleChange,
        errors,
        removeField,
        dismissEditModal,
        dispatch,
      });
    };

    return (
      <div
        className={`drag-and-drop-item-form-field ${isDragging ? 'drag-and-drop-item-form-field--dragging' : ''}`}
        ref={componentRef}>
        <div className='fields-wrapper' key={item.placeholder}>
          <span className='icon fas fa-grip-vertical' />
          <div>
            {renderField({ e: item, i: index, errors, handleChange })}
            {data.hasAdvancedSettings ? (
              <EditInput
                name={`inputFields[${item.elementId}].key`}
                placeholder='Form Field ID'
                touched={true}
                error={
                  errors.fields && errors.fields[item.elementId] ? errors.fields[item.elementId].key !== '' : false
                }
                errorMsg={errors.fields && errors.fields[item.elementId] ? errors.fields[item.elementId].key : ''}
                onChange={event => {
                  keyInputUpdate(event.target.value, item.elementId);
                  handleChange(event);
                }}
                defaultValue={item.key || transformToSendAble(getTranslation(item.placeholder))}
              />
            ) : null}
            {item.isHidden ? (
              <EditInput
                name={`inputFields[${item.elementId}].hiddenValue`}
                placeholder='Static value'
                touched={true}
                error={
                  errors.fields && errors.fields[item.elementId]
                    ? errors.fields[item.elementId].hiddenValue !== ''
                    : false
                }
                errorMsg={
                  errors.fields && errors.fields[item.elementId] ? errors.fields[item.elementId].hiddenValue : ''
                }
                onChange={event => {
                  keyInputUpdate(event.target.value, item.elementId);
                  handleChange(event);
                }}
              />
            ) : null}
            <EditCheckbox
              label='Required'
              value={checkBoxValue(item, 'required')}
              onChange={e => {
                onArrayPropertyChange({
                  index: index,
                  value: e.target.checked,
                  property: 'required',
                });
              }}
            />
          </div>
        </div>
      </div>
    );
  }
);
FormFieldItem.displayName = 'FormFieldItem';
export default FormFieldItem;

FormFieldItem.propTypes = {
  item: PropTypes.object,
  isDragging: PropTypes.bool,
  componentRef: PropTypes.object,
};
