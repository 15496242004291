const Poll = async ({ request, validate, interval }) => {
  const executePoll = async (resolve, reject) => {
    const result = await request();
    if (validate(result)) {
      return resolve(result);
    } else {
      setTimeout(executePoll, interval, resolve, reject);
    }
  };
  return new Promise(executePoll);
};

export const LongPollingService = {
  Poll,
};
