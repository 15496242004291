import { createPrefixedActionName } from '../../services/functions/redux-routines/redux-routines';
import { UI } from '../consts';

export const ACTIONS = {
  OPEN_MODAL: createPrefixedActionName(UI, 'OPEN_MODAL'),
  CLOSE_MODAL: createPrefixedActionName(UI, 'CLOSE_MODAL'),
  OPEN_SIDEBAR: createPrefixedActionName(UI, 'OPEN_SIDEBAR'),
  CLOSE_SIDEBAR: createPrefixedActionName(UI, 'CLOSE_SIDEBAR'),
  SET_DROPDOWN_DATA: createPrefixedActionName(UI, 'SET_DROPDOWN_DATA'),
  UPDATE_DROPDOWN_DATA: createPrefixedActionName(UI, 'UPDATE_DROPDOWN_DATA'),
  CLEAR_DROPDOWN_DATA: createPrefixedActionName(UI, 'CLEAR_DROPDOWN_DATA'),
  SET_MEDIA_DATA: createPrefixedActionName(UI, 'SET_MEDIA_DATA'),
};

export const openModal = (id, data) => ({
  type: ACTIONS.OPEN_MODAL,
  payload: { id, data },
});

export const closeModal = id => ({
  type: ACTIONS.CLOSE_MODAL,
  payload: id,
});

export const openSidebar = (id, data) => ({
  type: ACTIONS.OPEN_SIDEBAR,
  payload: { id, data },
});

export const closeSidebar = id => ({
  type: ACTIONS.CLOSE_SIDEBAR,
  payload: id,
});

export const setDropdownData = ({ id, data, query, links }) => ({
  type: ACTIONS.SET_DROPDOWN_DATA,
  payload: { id, data, links, query },
});

export const updateDropdownData = ({ id, data }) => ({
  type: ACTIONS.UPDATE_DROPDOWN_DATA,
  payload: { id, data },
});

export const clearDropdownData = ({ id }) => ({
  type: ACTIONS.CLEAR_DROPDOWN_DATA,
  payload: { id },
});

export const setMediaData = ({ id, data }) => ({
  type: ACTIONS.SET_MEDIA_DATA,
  payload: { id, data },
});
