import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import './edit-cookie-banner-content.sass';
import { EditInput, EditTextArea } from '../../../';

export class EditCookieBannerContent extends Component {
  render() {
    return (
      <EditModalBody>
        <form
          className='edit-cookie-banner-content'
          noValidate
          onSubmit={e => {
            e.preventDefault();
          }}>
          <div className='edit-cookie-banner__group'>
            <EditTextArea
              onChange={e => {
                this.props.onTranslationUpdate(this.props.data.content.cookieText, e.target.value);
              }}
              defaultValue={this.props.getTranslation(this.props.data.content.cookieText)}
              label='Banner Message'
              placeholder='Sample text'
            />
          </div>
          <div className='edit-cookie-banner__group'>
            <EditInput
              onChange={e => {
                this.props.onTranslationUpdate(this.props.data.content.cookieAllowButton, e.target.value);
              }}
              defaultValue={this.props.getTranslation(this.props.data.content.cookieAllowButton)}
              label='Accept Button Text'
              placeholder='Allow'
            />

            <EditInput
              onChange={e => {
                this.props.onTranslationUpdate(this.props.data.content.cookieDismissButton, e.target.value);
              }}
              defaultValue={this.props.getTranslation(this.props.data.content.cookieDismissButton)}
              label='Dismiss  Button Text'
              placeholder='Decline Optional Cookies'
            />
          </div>
          <div className='edit-cookie-banner__group'>
            <EditInput
              onChange={e => {
                this.props.onTranslationUpdate(this.props.data.content.cookiePolicyText, e.target.value);
              }}
              defaultValue={this.props.getTranslation(this.props.data.content.cookiePolicyText)}
              label='Cookie Policy'
              placeholder='Cookie Policy'
            />

            <EditInput
              onChange={e => {
                this.props.onTranslationUpdate(this.props.data.content.cookiePolicyHref, e.target.value);
              }}
              defaultValue={this.props.getTranslation(this.props.data.content.cookiePolicyHref)}
              placeholder='Enter URL'
            />

            <EditInput
              onChange={e => {
                this.props.onTranslationUpdate(this.props.data.content.privacyPolicyText, e.target.value);
              }}
              defaultValue={this.props.getTranslation(this.props.data.content.privacyPolicyText)}
              label='Privacy Policy'
              placeholder='Privacy Policy'
            />

            <EditInput
              onChange={e => {
                this.props.onTranslationUpdate(this.props.data.content.privacyPolicyHref, e.target.value);
              }}
              defaultValue={this.props.getTranslation(this.props.data.content.privacyPolicyHref)}
              placeholder='Enter URL'
            />
          </div>
          <button style={{ display: 'none' }} type='submit'>
            Submit
          </button>
        </form>
      </EditModalBody>
    );
  }
}
