import React, { useEffect, useState } from 'react';
import './add-tags.sass';
import InputAddTag from '../inputs/input-add-tag/input-add-tag';
import { useFormik } from 'formik';
import { Config } from '../../../utils/config';
import { TagList } from '../tag-list/tag-list';

export const TagsPosition = {
  TOP: 'top',
  BOTTOM: 'bottom',
};

const AddTags = ({
  label,
  tagList,
  onDeleteTag,
  onAddTag,
  placeholder,
  validationSchema,
  tagsPosition = TagsPosition.TOP,
  focusOnInit = false,
  shouldConfirmDelete = false,
  onFocus,
  onBlur,
  selectOnBlur = true,
  onInputChange,
  shouldResetInput,
}) => {
  const [currentList, setCurrentList] = useState(tagList);

  const onRemoveTag = index => {
    if (!shouldConfirmDelete) {
      const filteredList = [...currentList.filter((_, i) => i !== index)];
      setCurrentList(filteredList);
      onDeleteTag?.(filteredList, currentList[index]);
    } else {
      onDeleteTag?.(currentList, index);
    }
  };

  useEffect(() => {
    setCurrentList(tagList);
  }, [tagList]);

  useEffect(() => {
    shouldResetInput && setFieldValue('tags', '');
  }, [shouldResetInput]);

  const checkIfItemExist = (arr, q) => arr.findIndex(item => q && q.toLowerCase() === item.toLowerCase()) !== -1;

  const onAddElement = item => {
    const parsedItem = item.split(/[\s,]+/).filter(Boolean);
    const isItemExist = checkIfItemExist(currentList, ...parsedItem);
    const items = isItemExist ? [...currentList] : [...currentList, ...parsedItem];

    setCurrentList(items);
    item.length > 0 && onAddTag && onAddTag(items, item);
  };

  const {
    handleSubmit,
    errors,
    values,
    dirty,
    touched,
    handleChange,
    handleBlur,
    submitForm,
    setFieldValue,
  } = useFormik({
    validateOnChange: true,
    initialValues: {
      tags: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values, { resetForm }) => {
      onAddElement(values.tags);
      resetForm();
    },
  });

  const handleSubmitForm = e => {
    if (Config.regExp.tagsRegex.test(e.target.value)) return submitForm();
  };

  const renderTagList = () => <TagList list={currentList} onRemove={onRemoveTag} />;

  return (
    <div className='tint-assets-add-tags'>
      {label && (
        <div className='tint-assets-add-tags__label'>
          <span>{label}</span>
        </div>
      )}
      {tagsPosition === TagsPosition.TOP && renderTagList()}

      <div className='tint-assets-add-tags__add'>
        <form id='formId' noValidate onSubmit={handleSubmit}>
          <InputAddTag
            focusOnInit={focusOnInit}
            name='tags'
            type='text'
            value={values.tags}
            placeholder={placeholder || 'Add tag'}
            dirty={dirty}
            error={errors.tags}
            touched={touched.tags}
            handleChange={e => {
              onInputChange?.(e);
              handleSubmitForm(e);
              return handleChange(e);
            }}
            onClick={submitForm}
            handlePaste={() => setTimeout(submitForm)}
            handleBlur={e => {
              if (selectOnBlur) {
                submitForm(e);
              } else {
                handleBlur(e);
                onBlur?.();
              }
            }}
            onFocus={onFocus}
            errorMsg='Tag already exist'
          />
        </form>
      </div>
      {tagsPosition === TagsPosition.BOTTOM && renderTagList()}
    </div>
  );
};

export default AddTags;
