import { createPrefixedActionName, createRoutine } from '../../../../services/functions/redux-routines/redux-routines';
import { PRODUCT_ACCOUNTS } from '../../consts';

export const ACTIONS = {
  GET_PRODUCT_ACCOUNTS: createRoutine(createPrefixedActionName(PRODUCT_ACCOUNTS, 'GET_PRODUCT_ACCOUNTS')),
  GET_PRODUCT_ACCOUNT: createRoutine(createPrefixedActionName(PRODUCT_ACCOUNTS, 'GET_PRODUCT_ACCOUNT')),
  DELETE_PRODUCT_ACCOUNT: createRoutine(createPrefixedActionName(PRODUCT_ACCOUNTS, 'DELETE_PRODUCT_ACCOUNT')),
  POST_PRODUCT_ACCOUNT_BY_OAUTH: createRoutine(
    createPrefixedActionName(PRODUCT_ACCOUNTS, 'POST_PRODUCT_ACCOUNT_BY_OAUTH')
  ),
  REFRESH_PRODUCT_ACCOUNT: createRoutine(createPrefixedActionName(PRODUCT_ACCOUNTS, 'REFRESH_PRODUCT_ACCOUNT')),
  POST_PRODUCT_ACCOUNT: createRoutine(createPrefixedActionName(PRODUCT_ACCOUNTS, 'POST_PRODUCT_ACCOUNT')),
  PATCH_PRODUCT_ACCOUNT: createRoutine(createPrefixedActionName(PRODUCT_ACCOUNTS, 'PATCH_PRODUCT_ACCOUNT')),
  FILTER_PRODUCT_ACCOUNTS: createRoutine(createPrefixedActionName(PRODUCT_ACCOUNTS, 'FILTER_PRODUCT_ACCOUNTS')),
};

export const getProductAccountsStart = () => ({
  type: ACTIONS.GET_PRODUCT_ACCOUNTS.REQUEST,
});

export const getProductAccountsSuccess = data => ({
  type: ACTIONS.GET_PRODUCT_ACCOUNTS.SUCCESS,
  payload: data,
});

export const getProductAccountsError = err => ({
  type: ACTIONS.GET_PRODUCT_ACCOUNTS.FAILURE,
  payload: err,
});

export const getProductAccountStart = () => ({
  type: ACTIONS.GET_PRODUCT_ACCOUNT.REQUEST,
});

export const getProductAccountSuccess = data => ({
  type: ACTIONS.GET_PRODUCT_ACCOUNT.SUCCESS,
  payload: data,
});

export const getProductAccountError = err => ({
  type: ACTIONS.GET_PRODUCT_ACCOUNT.FAILURE,
  payload: err,
});

export const deleteProductAccountStart = () => ({
  type: ACTIONS.DELETE_PRODUCT_ACCOUNT.REQUEST,
});

export const deleteProductAccountSuccess = data => ({
  type: ACTIONS.DELETE_PRODUCT_ACCOUNT.SUCCESS,
  payload: data,
});

export const deleteProductAccountError = err => ({
  type: ACTIONS.DELETE_PRODUCT_ACCOUNT.FAILURE,
  payload: err,
});

export const postProductAccountStartByOAuthStart = () => ({
  type: ACTIONS.POST_PRODUCT_ACCOUNT_BY_OAUTH.REQUEST,
});

export const postProductAccountByOAuthSuccess = data => ({
  type: ACTIONS.POST_PRODUCT_ACCOUNT_BY_OAUTH.SUCCESS,
  payload: data,
});

export const postProductAccountByOAuthError = err => ({
  type: ACTIONS.POST_PRODUCT_ACCOUNT_BY_OAUTH.FAILURE,
  payload: err,
});

export const refreshProductAccountStart = () => ({
  type: ACTIONS.REFRESH_PRODUCT_ACCOUNT.REQUEST,
});

export const refreshProductAccountSuccess = data => ({
  type: ACTIONS.REFRESH_PRODUCT_ACCOUNT.SUCCESS,
  payload: data,
});

export const refreshProductAccountError = err => ({
  type: ACTIONS.REFRESH_PRODUCT_ACCOUNT.FAILURE,
  payload: err,
});

export const patchProductAccountStart = () => ({
  type: ACTIONS.PATCH_PRODUCT_ACCOUNT.REQUEST,
});

export const patchProductAccountSuccess = data => ({
  type: ACTIONS.PATCH_PRODUCT_ACCOUNT.SUCCESS,
  payload: data,
});

export const patchProductAccountError = err => ({
  type: ACTIONS.PATCH_PRODUCT_ACCOUNT.FAILURE,
  payload: err,
});

export const postProductAccountStart = () => ({
  type: ACTIONS.POST_PRODUCT_ACCOUNT.REQUEST,
});

export const postProductAccountSuccess = data => ({
  type: ACTIONS.POST_PRODUCT_ACCOUNT.SUCCESS,
  payload: data,
});

export const postProductAccountError = err => ({
  type: ACTIONS.POST_PRODUCT_ACCOUNT.FAILURE,
  payload: err,
});

export const filterProductAccountsStart = () => ({
  type: ACTIONS.FILTER_PRODUCT_ACCOUNTS.REQUEST,
});

export const filterProductAccountsSuccess = data => ({
  type: ACTIONS.FILTER_PRODUCT_ACCOUNTS.SUCCESS,
  payload: data,
});

export const filterProductAccountsError = err => ({
  type: ACTIONS.FILTER_PRODUCT_ACCOUNTS.FAILURE,
  payload: err,
});
