import { createPrefixedActionName, createRoutine } from '../../../../services/functions/redux-routines/redux-routines';
import { WEBHOOKS } from '../../consts';

export const ACTIONS = {
  GET_WEBHOOKS: createRoutine(createPrefixedActionName(WEBHOOKS, 'GET_WEBHOOKS')),
  POST_WEBHOOK: createRoutine(createPrefixedActionName(WEBHOOKS, 'POST_WEBHOOK')),
  UPDATE_WEBHOOK: createRoutine(createPrefixedActionName(WEBHOOKS, 'UPDATE_WEBHOOK')),
  DELETE_WEBHOOK: createRoutine(createPrefixedActionName(WEBHOOKS, 'DELETE_WEBHOOK')),
};

export const updateWebhookStart = () => ({
  type: ACTIONS.UPDATE_WEBHOOK.REQUEST,
});

export const updateWebhookSuccess = data => ({
  type: ACTIONS.UPDATE_WEBHOOK.SUCCESS,
  payload: data,
});

export const updateWebhookError = err => ({
  type: ACTIONS.UPDATE_WEBHOOK.FAILURE,
  payload: err,
});

export const getWebhooksStart = () => ({
  type: ACTIONS.GET_WEBHOOKS.REQUEST,
});

export const getWebhooksSuccess = data => ({
  type: ACTIONS.GET_WEBHOOKS.SUCCESS,
  payload: data,
});

export const getWebhooksError = err => ({
  type: ACTIONS.GET_WEBHOOKS.FAILURE,
  payload: err,
});

export const postWebhookStart = () => ({
  type: ACTIONS.POST_WEBHOOK.REQUEST,
});

export const postWebhookSuccess = data => ({
  type: ACTIONS.POST_WEBHOOK.SUCCESS,
  payload: data,
});

export const postWebhookError = err => ({
  type: ACTIONS.POST_WEBHOOK.FAILURE,
  payload: err,
});

export const deleteWebhookStart = () => ({
  type: ACTIONS.DELETE_WEBHOOK.REQUEST,
});

export const deleteWebhookSuccess = id => ({
  type: ACTIONS.DELETE_WEBHOOK.SUCCESS,
  payload: id,
});

export const deleteWebhookError = err => ({
  type: ACTIONS.DELETE_WEBHOOK.FAILURE,
  payload: err,
});
