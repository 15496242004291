import { ACTIONS } from '../actions/submissions';

const initialState = {
  selectedSubmission: undefined,
  selectedSubmissionIndex: undefined,
  submissionsExperience: undefined,
  data: undefined,
  isFetching: false,
  error: undefined,
  isAnalyticsDisplayed: false,
  metrics: undefined,
};

const submissions = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_SUBMISSION_METRICS.SUCCESS:
      return { ...state, metrics: action.payload };
    case ACTIONS.GET_SUBMISSIONS.REQUEST:
    case ACTIONS.GET_SUBMISSIONS_EXPERIENCE.REQUEST:
      return Object.assign({}, state, {
        isFetching: true,
        isError: false,
      });

    case ACTIONS.GET_SUBMISSIONS.SUCCESS:
      return Object.assign({}, state, {
        data: action.payload,
        isFetching: false,
        isError: false,
      });
    case ACTIONS.GET_SUBMISSIONS_EXPERIENCE.SUCCESS:
      return { ...state, submissionsExperience: action.payload };

    case ACTIONS.GET_SUBMISSIONS.FAILURE:
    case ACTIONS.GET_SUBMISSIONS_EXPERIENCE.FAILURE:
      return Object.assign({}, state, {
        isError: action.payload,
        isFetching: false,
      });
    case ACTIONS.SELECT_SUBMISSION:
      return {
        ...state,
        selectedSubmission: action.payload.submission,
        selectedSubmissionIndex: action.payload.submissionIndex,
      };
    case ACTIONS.DELETE_SUBMISSIONS.REQUEST:
      return { ...state, isFetching: true, isError: false };

    case ACTIONS.DELETE_SUBMISSIONS.SUCCESS:
    case ACTIONS.DELETE_SUBMISSIONS.FAILURE:
      return { ...state, isError: action.payload, isFetching: false };

    case ACTIONS.DISPLAY_ANALYTICS:
      return { ...state, isAnalyticsDisplayed: true };
    case ACTIONS.HIDE_ANALYTICS:
      return { ...state, isAnalyticsDisplayed: false };
    case ACTIONS.CLEAR_SUBMISSIONS_STATE: {
      return { ...initialState };
    }

    default:
      return state;
  }
};

export default submissions;
