import { colors } from '../../utils/variables/colors';
import { breakpoints } from '../../utils/variables/breakpoints';

export const variables = {
  breakpoints: { ...breakpoints },
  colors: { ...colors },
  zIndexes: {
    zIndexMainNavigation: '1051',
  },
  borderRadius: {
    main: '6px',
    customizableButtonRadius: '8px',
    exploreBasicsBorderRadius: '10px',
  },
  fonts: {
    families: {
      main: 'Metropolis',
    },
    sizes: {
      h3: '22px',
      buttonBig: '20px',
      h4: '18px',
      cellLineHeight: '17px',
      textPrimary: '14px',
      label: '12px',
      small: '11px',
    },
    weights: {
      main: '400',
    },
  },
};
