import React, { useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DropdownInfiniteList from '../../interface/dropdown/lists/infinite-list/dropdown-infinite-list';
import { DropdownHeader } from '../../interface/dropdown/button/header/dropdown-header';
import { InputGroup } from '../../../components/interface/inputs/input-group/input-group';
import DropdownButton, { ButtonType, DropdownButtonType } from '../../interface/dropdown/button/dropdown-button';
import { toast } from 'react-toastify';
import { mapDropdownList, mapDropdownListItem } from '../utils/utils';
import { getPersonalization, getPersonalizations } from '../../../actions/account/account.fat-actions';
import { getTeam } from '../../../reducers/session';
import { Config } from '../../../utils/config';
import useFeatureAccessibility from '../../hooks/use-feature-accessibility/use-feature-accessibility';
import { GuardNames } from '../../../guards/guards-list';

const dropdownAdditionalSelectField = {
  name: 'Add Personalization',
  value: 'ADD_PERSONALIZATION',
};

const dropdownNoneSelectField = {
  name: 'None',
  value: null,
};

const SelectPersonalizationWrapper = ({
  currentItemId,
  onChange,
  label = 'Select Personalization',
  isNonePersonalization = false,
  disableAdding = false,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [filterValue, setFilterValue] = useState();
  const [contentList, setContentList] = useState([]);
  const [currentItem, setCurrentItem] = useState();

  const canActivePersonalizationsEdit = disableAdding
    ? false
    : useFeatureAccessibility(GuardNames.PERSONALIZATIONS_EDIT);
  const team = useSelector(getTeam);
  const { id: teamId } = team;
  const currentPersonalizations = useSelector(state => state.account.personalizations?.data);
  const currentPersonalization = useSelector(state => state.account?.personalization);
  const isAbleToLoad = useSelector(state => state.account.personalizations?.links?.next);

  const getPersonalizationDetails = useCallback(() => {
    dispatch(getPersonalization({ id: currentItemId })).then(res => {
      setCurrentItem(mapDropdownListItem(res));
    });
  }, [currentItemId]);

  useEffect(() => {
    (!currentPersonalization || currentPersonalization.id !== currentItemId) &&
      currentItemId &&
      getPersonalizationDetails();
  }, [currentItemId]);

  const getCurrentItem = useCallback(() => {
    if (filterValue !== undefined) return;

    if (currentPersonalizations && currentPersonalizations.length) {
      const item = currentPersonalizations.find(item => item.id === currentItemId);
      if (item) {
        setCurrentItem(mapDropdownListItem(item));
      } else if (currentItemId) getPersonalizationDetails();
    }
  }, [currentItemId, currentPersonalizations, filterValue, getPersonalizationDetails]);

  useEffect(() => {
    if (filterValue === undefined) return;
    const fetchFilteredPersonalizations = () => {
      setContentList([]);
      dispatch(getPersonalizations({ teamId, filterValue, status: 'active' })).catch(() =>
        toast('Cannot fetch personalization list')
      );
    };

    fetchFilteredPersonalizations();
  }, [filterValue, teamId, dispatch]);

  useEffect(() => {
    const fetchPersonalizations = () => {
      dispatch(getPersonalizations({ teamId, status: 'active' })).catch(() =>
        toast('Cannot fetch personalization list')
      );
    };

    fetchPersonalizations();
  }, [teamId, dispatch]);

  useEffect(() => {
    if (
      currentPersonalizations &&
      currentPersonalizations.length &&
      !contentList.find(cl => cl.id === currentPersonalizations[0].id)
    ) {
      const ids = new Set(contentList.map(el => el.id));
      const personalizations = currentPersonalizations.filter(el => !ids.has(el.id));
      setContentList(cl => [...personalizations, ...cl]);
    }

    getCurrentItem();
  }, [currentPersonalizations, getCurrentItem]);

  const onLoadData = () => dispatch(getPersonalizations({ personalizationsUrl: isAbleToLoad }));
  const onInputChange = query => setFilterValue(query);

  const onChangeValue = selectedItem => {
    dispatch(getPersonalization({ id: selectedItem.value })).then(res => {
      setCurrentItem(mapDropdownListItem(res));
    });

    if (selectedItem.value === dropdownAdditionalSelectField.value) {
      history.push(Config.routePaths.personalizations.path);
    } else {
      onChange(selectedItem);
    }
  };

  return (
    <InputGroup label={label}>
      <DropdownButton
        currentItem={currentItem}
        dropdownType={DropdownButtonType.SEARCH_INPUT}
        buttonType={ButtonType.BUTTON_SORT}
        dropdownHeader={props => <DropdownHeader {...props} />}
        dropdownList={props => (
          <DropdownInfiniteList
            {...props}
            onLoadData={onLoadData}
            isAbleToLoad={contentList.length >= 25 && isAbleToLoad}
            onInputChange={e => onInputChange(e)}
          />
        )}
        list={[
          ...(isNonePersonalization ? [dropdownNoneSelectField] : []),
          ...mapDropdownList(contentList),
          ...(canActivePersonalizationsEdit ? [dropdownAdditionalSelectField] : []),
        ]}
        placeholder='Select Personalization'
        iconRight='fa fa-caret-down'
        onChangeValue={onChangeValue}
      />
    </InputGroup>
  );
};

export default SelectPersonalizationWrapper;
