const ACCOUNT = 'ACCOUNT';
const ACCOUNTS = 'ACCOUNTS';
const ROUTING = 'ROUTING';
const EDIT_MODAL = 'EDIT_MODAL';
const UI = 'UI';
const SESSION = 'SESSION';
const DROPDOWN = 'DROPDOWN';
const FILTERABLE = 'FILTERABLE';
const SIDEBAR = 'SIDEBAR';
const FORM_BUILDER = 'FORM_BUILDER';
const MISSION_HUBS = 'MISSION_HUBS';

const names = {
  ACCOUNT,
  ACCOUNTS,
  ROUTING,
  EDIT_MODAL,
  MODALS: UI,
  SESSION,
  DROPDOWN,
  SIDEBAR,
  FILTERABLE,
  FORM_BUILDER,
  MISSION_HUBS,
};

export {
  names as default,
  ACCOUNT,
  ACCOUNTS,
  ROUTING,
  EDIT_MODAL,
  UI,
  SESSION,
  DROPDOWN,
  SIDEBAR,
  FILTERABLE,
  FORM_BUILDER,
  MISSION_HUBS,
};
