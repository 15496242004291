import React from 'react';
import './dropdown-multiselect-list-header.sass';

export const DropdownMultiSelectListHeader = ({ title, iconLeft, iconRight, iconCenter, count }) => {
  return (
    <div className={'dropdown-multi-select-header'}>
      {iconLeft && !iconCenter ? <span className={`icon icon__left ${iconLeft}`} /> : null}
      {title && !iconCenter ? <span>{title}</span> : null}
      {count ? <span className={'dropdown-multi-select-header__counter'}>{count}</span> : null}
      {iconRight && !iconCenter && (count === undefined || count === 0) ? (
        <span className={`icon icon__right ${iconRight}`} />
      ) : null}
    </div>
  );
};
