import { ACTIONS } from '../../actions/custom-domains/custom-domains.actions';
import { produce } from 'immer';

export const initialState = {
  domain: undefined,
  error: undefined,
  isPending: false,
  customDomainExperiences: [],
};

export const customDomains = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.PATCH_CUSTOM_DOMAIN_EXPERIENCES.REQUEST:
    case ACTIONS.GET_CUSTOM_DOMAIN_EXPERIENCES.REQUEST:
    case ACTIONS.POST_CUSTOM_DOMAIN.REQUEST:
    case ACTIONS.DELETE_CUSTOM_DOMAIN.REQUEST:
    case ACTIONS.GET_CUSTOM_DOMAINS.REQUEST:
      return {
        ...state,
        isPending: true,
      };

    case ACTIONS.PATCH_CUSTOM_DOMAIN_EXPERIENCES.SUCCESS:
      return produce(state, draft => {
        const experiences = draft.data.filter(domain => domain.id !== action.payload.data.id);
        draft.data = [
          {
            ...action.payload.data.attributes,
            id: action.payload.data.id,
            defaultExperienceId: action.payload.data.relationships.default_experience.data?.id,
          },
          ...experiences,
        ];
        draft.isPending = false;
      });

    case ACTIONS.GET_CUSTOM_DOMAIN_EXPERIENCES.SUCCESS:
      return {
        ...state,
        customDomainExperiences: action.payload,
        isPending: false,
        error: undefined,
      };

    case ACTIONS.GET_CUSTOM_DOMAINS.SUCCESS:
      return {
        data: action.payload,
        isPending: false,
        error: undefined,
      };

    case ACTIONS.POST_CUSTOM_DOMAIN.SUCCESS:
      return {
        data: [...state.data, action.payload],
        isPending: false,
        error: undefined,
      };

    case ACTIONS.DELETE_CUSTOM_DOMAIN.SUCCESS:
      return {
        data: state.data.filter(domain => domain.id !== action.payload),
        isPending: false,
        error: undefined,
      };

    case ACTIONS.PATCH_CUSTOM_DOMAIN_EXPERIENCES.FAILURE:
    case ACTIONS.GET_CUSTOM_DOMAIN_EXPERIENCES.FAILURE:
    case ACTIONS.POST_CUSTOM_DOMAIN.FAILURE:
    case ACTIONS.DELETE_CUSTOM_DOMAIN.FAILURE:
    case ACTIONS.GET_CUSTOM_DOMAINS.FAILURE:
      return {
        ...state,
        isPending: false,
        error: action.payload,
      };

    default:
      return state;
  }
};

export default customDomains;

export const getCustomDomainDefaultExperience = (state, domainId) =>
  state.customDomains.data.find(experience => experience.id === domainId)?.defaultExperienceId;
