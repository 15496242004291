import { ROUTING } from './consts.js';
import { createPrefixedActionName } from '../services/functions/redux-routines/redux-routines';

export const ACTIONS = {
  CHANGE_MAIN_APP_ROUTE: createPrefixedActionName(ROUTING, 'CHANGE_MAIN_APP_ROUTE'),
  SET_PAGE_TITLE: createPrefixedActionName(ROUTING, 'SET_PAGE_TITLE'),
  SET_PAGE_HEADER: createPrefixedActionName(ROUTING, 'SET_PAGE_HEADER'),
};

export const changeMainAppRoute = (route, id) => ({
  type: ACTIONS.CHANGE_MAIN_APP_ROUTE,
  payload: {
    route,
    id,
  },
});

export const setPageTitle = title => ({
  type: ACTIONS.SET_PAGE_TITLE,
  payload: title,
});

export const setPageHeader = header => ({
  type: ACTIONS.SET_PAGE_HEADER,
  payload: header,
});
