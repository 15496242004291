import {
  deleteCustomDomainError,
  deleteCustomDomainStart,
  deleteCustomDomainSuccess,
  getCustomDomainExperiencesError,
  getCustomDomainExperiencesStart,
  getCustomDomainExperiencesSuccess,
  getCustomDomainsError,
  getCustomDomainsStart,
  getCustomDomainsSuccess,
  patchCustomDomainExperiencesError,
  patchCustomDomainExperiencesStart,
  patchCustomDomainExperiencesSuccess,
  postCustomDomainError,
  postCustomDomainStart,
  postCustomDomainSuccess,
} from './custom-domains.actions';
import { ApiService } from '../../../../services/api-service/api-service';

export const getCustomDomainsExperience = ({ url }) => {
  return function(dispatch) {
    dispatch(getCustomDomainExperiencesStart());
    return ApiService.get(url)
      .then(res => {
        dispatch(getCustomDomainExperiencesSuccess(res.data));
        return res.data;
      })
      .catch(err => {
        dispatch(getCustomDomainExperiencesError(err));
        throw err;
      });
  };
};

export const setCustomDomainsExperience = ({ domainId, experienceId }) => {
  return function(dispatch) {
    dispatch(patchCustomDomainExperiencesStart());
    return ApiService.patch(`/domains/${domainId}`, {
      data: {
        type: 'domain',
        attributes: {
          default_experience_id: experienceId,
        },
      },
    })
      .then(res => {
        dispatch(patchCustomDomainExperiencesSuccess(res.data));
        return res.data;
      })
      .catch(err => {
        dispatch(patchCustomDomainExperiencesError(err));
        throw err;
      });
  };
};

export const getCustomDomains = () => {
  return function(dispatch) {
    dispatch(getCustomDomainsStart());
    return ApiService.get('/domains')
      .then(res => {
        const mapDomainList = res.data.data.map(domain => {
          return {
            id: domain.id,
            defaultExperienceId: domain.relationships.default_experience.data?.id,
            ...domain.attributes,
          };
        });
        return dispatch(getCustomDomainsSuccess(mapDomainList));
      })
      .catch(err => {
        dispatch(getCustomDomainsError(err));
        throw err;
      });
  };
};

export const deleteCustomDomain = domainId => {
  return function(dispatch) {
    dispatch(deleteCustomDomainStart());
    return ApiService.delete(`/domains/${domainId}`)
      .then(() => {
        return dispatch(deleteCustomDomainSuccess(domainId));
      })
      .catch(err => {
        dispatch(deleteCustomDomainError(err));
        throw err;
      });
  };
};

export const postCustomDomain = domainAddress => {
  return function(dispatch) {
    dispatch(postCustomDomainStart());
    return ApiService.post('/domains', {
      data: {
        type: 'domain',
        attributes: {
          name: domainAddress,
        },
      },
    })
      .then(res => {
        const mapDomainData = {
          id: res.data.data.id,
          ...res.data.data.attributes,
        };
        return dispatch(postCustomDomainSuccess(mapDomainData));
      })
      .catch(err => {
        dispatch(postCustomDomainError(err));
        throw err;
      });
  };
};
