import React, { forwardRef } from 'react';
import ValidationAlert from '../validation-alert/validation-alert';
import './input-add-tag.sass';
import PropTypes from 'prop-types';

const InputAddTag = forwardRef(
  (
    {
      name,
      type,
      value,
      label,
      placeholder,
      error,
      touched,
      handleBlur,
      handleChange,
      readonly,
      disabled,
      onClick,
      handlePaste,
      focusOnInit,
      onFocus,
      onKeyDown,
    },
    ref
  ) => {
    const isError = error && touched;
    const errorClassName = isError ? 'input-primary--error' : '';

    return (
      <div className={`input-add-tag ${errorClassName}`}>
        <div className='input-add-tag__wrapper'>
          {label ? <label>{label}</label> : null}
          <input
            autoFocus={focusOnInit}
            ref={ref}
            placeholder={placeholder}
            onChange={handleChange}
            onPaste={handlePaste}
            onBlur={handleBlur}
            onFocus={onFocus}
            name={name}
            type={type}
            onKeyDown={onKeyDown}
            value={value}
            readOnly={readonly}
            disabled={disabled}
            autoComplete='off'
          />
          <span className='icon fas fa-plus' onClick={onClick} />
        </div>

        <ValidationAlert errorMsg={error} isVisible={isError} />
      </div>
    );
  }
);

InputAddTag.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  touched: PropTypes.bool,
  handleBlur: PropTypes.func,
  dirty: PropTypes.bool,
  handleChange: PropTypes.func,
  handlePaste: PropTypes.func,
  readonly: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

InputAddTag.displayName = 'InputAddTag';
export default InputAddTag;
