import { SidebarItemTypes } from '../utils/meta/consts';
import { publicPostingDisabledButton, SocialFeedsFooterLabels } from './helpers';
import * as Yup from 'yup';

const validationSchemaReviewsRatings = () => {
  return Yup.object({});
};

const initializeAuthorNameCheckedValues = socialFeed =>
  socialFeed?.attributes?.options?.author_name ? 'authorName' : null;
const initializeEmailCheckedValues = socialFeed => (socialFeed?.attributes?.options?.email ? 'email' : null);
const initializeMediaUrlCheckedValues = socialFeed => (socialFeed?.attributes?.options?.media_url ? 'mediaUrl' : null);
const initializeCustomTermsCheckedValues = socialFeed =>
  socialFeed?.attributes?.options?.terms ? 'customTerms' : null;
const initializeMultipleFilesValues = socialFeed =>
  socialFeed ? (socialFeed.attributes?.options?.multiple_files === false ? null : 'multipleFiles') : null;

const initializeCustomTermsTextareaValues = socialFeed => socialFeed?.attributes?.options?.terms || '';

export const reviewsRatings = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext,
    },
    editFooter: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepSave,
    },
    accountSourceType: 'reviews_ratings',
    selectedOption: { id: 'reviews', title: 'Add Ratings & Reviews' },
    header: 'Add Ratings & Reviews',
    description:
      'Ratings & Reviews adds a button to the top right of your display, allowing users to post reviews directly to your board.',
    formSchema: {
      reviews: {
        validationSchema: validationSchemaReviewsRatings(),
        submitMapper: values => {
          const checked = Object.keys({
            mediaUrl: 'mediaUrl',
            authorName: 'authorName',
            email: 'email',
            customTerms: 'customTerms',
            multipleFiles: 'multipleFiles',
          }).reduce((acc, curr) => {
            acc[curr] = !!values.checked.find(i => i === curr);
            return acc;
          }, {});

          return {
            options: {
              terms: checked.customTerms ? values.customTermsTextarea : '',
              author_name: checked.authorName,
              email: checked.email,
              media_url: checked.mediaUrl,
              multiple_files: checked.multipleFiles,
            },
            source: 'public_post',
          };
        },
        inputs: [
          {
            id: 'mediaUrl',
            backendId: 'media_url',
            label: 'People who submit a Review must provide a photo or video.',
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeMediaUrlCheckedValues,
          },
          {
            id: 'authorName',
            backendId: 'author_name',
            label: 'People who submit a Review must provide their name.',
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeAuthorNameCheckedValues,
          },
          {
            id: 'email',
            backendId: 'email',
            label: 'People who submit a Review must provide their email address.',
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeEmailCheckedValues,
          },
          {
            id: 'multipleFiles',
            backendId: 'multiple_files',
            label: 'People who post to your board can upload multiple files.',
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeMultipleFilesValues,
          },
          {
            id: 'customTerms',
            label: 'Upload custom Terms & Conditions.',
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeCustomTermsCheckedValues,
          },
          {
            id: 'customTermsTextarea',
            backendId: 'terms',
            placeholder: 'Paste your custom terms & conditions here...',
            type: SidebarItemTypes.TEXTAREA,
            defaultValue: initializeCustomTermsTextareaValues,
            disabled: publicPostingDisabledButton,
          },
        ],
        editInputs: [
          {
            id: 'mediaUrl',
            backendId: 'media_url',
            label: 'People who submit a Review must provide a photo or video.',
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeMediaUrlCheckedValues,
          },
          {
            id: 'authorName',
            backendId: 'author_name',
            label: 'People who submit a Review must provide their name.',
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeAuthorNameCheckedValues,
          },
          {
            id: 'email',
            backendId: 'email',
            label: 'People who submit a Review must provide their email address.',
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeEmailCheckedValues,
          },
          {
            id: 'multipleFiles',
            backendId: 'multiple_files',
            label: 'People who post to your board can upload multiple files.',
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeMultipleFilesValues,
          },
          {
            id: 'customTerms',
            label: 'Upload custom Terms & Conditions.',
            type: SidebarItemTypes.CHECKBOX,
            defaultValue: initializeCustomTermsCheckedValues,
          },
          {
            id: 'customTermsTextarea',
            backendId: 'terms',
            placeholder: 'Paste your custom terms & conditions here...',
            type: SidebarItemTypes.TEXTAREA,
            defaultValue: initializeCustomTermsTextareaValues,
            disabled: publicPostingDisabledButton,
          },
        ],
      },
    },
  },
];
