import React from 'react';
import './form-builder.form.interface.consent.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';

const FormBuilderFormInterfaceConsent = ({ item, styles }) => {
  if (!item) return null;

  const { t } = useTranslation();

  const checkbox = item.elements.find(element => element.nodeName === 'input');
  const label = item.elements.find(element => element.nodeName === 'label');
  const text = label.elements.find(e => e.nodeName === '#text');
  const a = label.elements.find(element => element.nodeName === 'a');

  const onFocus = e => {
    e.target.style.outlineColor = styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_FOCUS]?.outlineColor;
  };

  return (
    <div
      className={`${item.class}`}
      style={{
        ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.INPUT_CLASS],
        ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.CONSENT],
      }}>
      <input
        style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.CONSENT_INPUT] }}
        type={checkbox?.type}
        name={checkbox?.name}
        onFocus={onFocus}
        id={checkbox?.id}
        required={checkbox?.required}
        value={checkbox.value ? checkbox.value : ''}
      />

      <label htmlFor={label.for} style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.CONSENT_LABEL] }}>
        {text && `${t(text?.nodeValue)} `}
        {a && (
          <a
            href={a?.href}
            target='_blank'
            rel='noopener noreferrer'
            style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.CONSENT_A]}>
            {t(a?.nodeValue)}
          </a>
        )}
      </label>
    </div>
  );
};

FormBuilderFormInterfaceConsent.displayName = 'FormBuilderFormInterfaceConsent';
export default React.memo(FormBuilderFormInterfaceConsent);
