import { ACTIONS } from '../../actions/email-domains/email-domains.actions';
import { produce } from 'immer';

export const initialState = {
  data: [],
  isFetching: false,
  currentDomain: undefined,
  error: undefined,
};

export const emailDomains = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_EMAIL_DOMAINS.REQUEST:
    case ACTIONS.GET_EMAIL_DOMAIN.REQUEST:
    case ACTIONS.POST_EMAIL_DOMAINS.REQUEST:
    case ACTIONS.DELETE_EMAIL_DOMAIN.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };

    case ACTIONS.GET_EMAIL_DOMAINS.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        links: action.payload.links,
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.GET_EMAIL_DOMAINS.FAILURE:
    case ACTIONS.GET_EMAIL_DOMAIN.FAILURE:
    case ACTIONS.POST_EMAIL_DOMAINS.FAILURE:
    case ACTIONS.DELETE_EMAIL_DOMAIN.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: action.payload,
      };

    case ACTIONS.GET_EMAIL_DOMAIN.SUCCESS:
      return {
        ...state,
        currentDomain: action.payload.data,
        links: action.payload.links,
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.POST_EMAIL_DOMAINS.SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        links: action.payload.links,
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.DELETE_EMAIL_DOMAIN.SUCCESS:
      return produce(state, draft => {
        draft.data = draft.data.filter(domain => domain.id !== action.payload);
        draft.isFetching = false;
      });

    default: {
      return state;
    }
  }
};
