import React from 'react';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import EditColorInput from '../../../edit-color-input/edit-color-input';
import PropTypes from 'prop-types';

const TagOWarStyles = ({ data, dataUpdate }) => {
  const styleData = {
    leftSide: {
      backgroundColor: data.backgroundColorLeft,
      backgroundRange: data.backgroundRangeLeft,
      backgroundPostColor: data.backgroundColorPostLeft,
      textPostColor: data.textColorPostLeft,
      textColor: data.textColorLeft,
    },
    rightSide: {
      backgroundColor: data.backgroundColorRight,
      backgroundRange: data.backgroundRangeRight,
      backgroundPostColor: data.backgroundColorPostRight,
      textColor: data.textColorRight,
      textPostColor: data.textColorRight,
    },
  };

  function renderLeftGroupField(data) {
    return (
      <>
        <EditColorInput
          color={data.backgroundColor}
          label='Primary color'
          onChange={value => {
            dataUpdate({
              backgroundColorLeft: value,
            });
          }}
        />
        <EditColorInput
          color={data.backgroundRange}
          label='Secondary color'
          onChange={value => {
            dataUpdate({
              backgroundRangeLeft: value,
            });
          }}
        />
        <EditColorInput
          color={data.textColor}
          label='Text color'
          onChange={value => {
            dataUpdate({ textColorLeft: value });
          }}
        />
        <EditColorInput
          color={data.backgroundPostColor}
          label='Background post color'
          onChange={value => {
            dataUpdate({
              backgroundColorPostLeft: value,
            });
          }}
        />
        <EditColorInput
          color={data.textPostColor}
          label='Post text color'
          onChange={value => {
            dataUpdate({ textColorPostLeft: value });
          }}
        />
      </>
    );
  }

  function renderRightGroupField(data) {
    return (
      <>
        <EditColorInput
          color={data.backgroundColor}
          label='Primary color'
          onChange={value => {
            dataUpdate({
              backgroundColorRight: value,
            });
          }}
        />
        <EditColorInput
          color={data.backgroundRange}
          label='Secondary color'
          onChange={value => {
            dataUpdate({
              backgroundRangeRight: value,
            });
          }}
        />

        <EditColorInput
          color={data.textColor}
          label='Text color'
          onChange={value => {
            dataUpdate({ textColorRight: value });
          }}
        />
        <EditColorInput
          color={data.backgroundPostColor}
          label='Background post color'
          onChange={value => {
            dataUpdate({ backgroundColorPostRight: value });
          }}
        />

        <EditColorInput
          color={data.textPostColor}
          label='Post text color'
          onChange={value => {
            dataUpdate({ textColorPostRight: value });
          }}
        />
      </>
    );
  }

  return (
    <div className='tint-tag-o-war-styles'>
      <EditModalBody title='Customize your Tag-o-War by changing the color fields below.'>
        <div className='tint-edit-modal-body__group'>
          <p className='tint-edit-modal-body__header'>Left side</p>
          {renderLeftGroupField(styleData.leftSide)}
        </div>
        <div className='tint-edit-modal-body__group'>
          <p className='tint-edit-modal-body__header'>Right side</p>
          {renderRightGroupField(styleData.rightSide)}
        </div>
      </EditModalBody>
    </div>
  );
};

TagOWarStyles.propTypes = {
  dataUpdate: PropTypes.func,
  data: PropTypes.shape({
    backgroundColorLeft: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    backgroundColorRight: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    backgroundRangeLeft: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    backgroundRangeRight: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    textColorLeft: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    textColorRight: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
};

export default TagOWarStyles;
