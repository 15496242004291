import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { Button } from '../../../../../../../../components/interface/button/button';
import { FileStack } from '../../../../../../../../services/filestack/file-stack';
import './video-content.sass';
import { EditInput } from '../../../../index';
import EditCheckbox from '../../../../edit-checkbox/edit-checkbox';

export class VideoContent extends Component {
  onUploadClick = () => {
    new FileStack().openFilePicker({
      accept: ['video/*'],
      maxSize: this.props.maxVideoFileSize,
      onUploadDone: res => {
        if (res.filesUploaded.length > 0) {
          this.props.onContentUpdate({
            mediaUrl: res.filesUploaded[0].url,
            mediaName: res.filesUploaded[0].filename,
          });
        }
      },
    });
  };

  render() {
    return (
      <EditModalBody titleClass='bold' title='UPLOAD VIDEO' className='edit-content'>
        <form
          noValidate
          onSubmit={e => {
            e.preventDefault();
          }}>
          <div className='video-content-upload-container'>
            <Button
              onClick={this.onUploadClick}
              type='gray'
              size='full-width'
              text='Upload media'
              icon='far fa-arrow-to-top'
            />
            <span className='video-content-upload-container__text'>Limited to 1GB</span>
          </div>
          <EditInput
            onChange={event => {
              this.props.onContentUpdate({
                mediaUrl: event.target.value,
                mediaName: '',
              });
            }}
            label={'or paste Video url'}
            placeholder='Video url'
            value={this.props.data.content.mediaUrl}
          />
          <span className='tint-edit-media__input-description'>
            Supported: Facebook, YouTube, Vimeo, Twitch, TikTok
          </span>
          <button style={{ display: 'none' }} type='submit'>
            Submit
          </button>
          <EditCheckbox
            value={!!this.props.data.content.hasAutoplay}
            onChange={e => this.props.onContentUpdate({ hasAutoplay: e.target.checked })}
            name='autoPlay'
            id='autoPlay'
            label='Turn ON autoplay'
          />
        </form>
      </EditModalBody>
    );
  }
}
