import { ACTIONS } from '../../actions/product-feeds/add-product.actions';

export const initialState = {
  isOpen: false,
  data: undefined,
};

export const addProductReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_MODAL:
      return {
        ...state,
        data: action.payload,
        isOpen: true,
      };

    case ACTIONS.CLOSE_MODAL:
      return {
        ...state,
        data: undefined,
        isOpen: false,
      };

    default:
      return state;
  }
};
