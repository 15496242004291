import { ACTIONS } from '../actions/plan-addons.actions';
import { produce } from 'immer';

const initialState = {
  features: { data: [] },
  isPending: false,
  selectedFeature: 'All',
  modal: { video_url: '', isOpened: false },
};

export const planAddonsReducer = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_FEATURES.REQUEST:
      return produce(state, draftState => {
        draftState.isPending = true;
        return draftState;
      });

    case ACTIONS.GET_FEATURES.FAILURE:
      return produce(state, draftState => {
        draftState.isPending = false;
        return draftState;
      });

    case ACTIONS.GET_FEATURES.SUCCESS:
      return produce(state, draftState => {
        draftState.features = action.payload;
        draftState.isPending = false;
        return draftState;
      });

    case ACTIONS.SELECT_FEATURE:
      return produce(state, draftState => {
        draftState.selectedFeature = action.payload;
        return draftState;
      });

    case ACTIONS.OPEN_MODAL:
      return produce(state, draftState => {
        draftState.modal = action.payload;
        return draftState;
      });

    case ACTIONS.CLOSE_MODAL:
      return produce(state, draftState => {
        draftState.modal = {};
        return draftState;
      });

    default:
      return state;
  }
};

export const getSelectedFeatures = state => {
  if (state?.selectedFeature === 'All') {
    return state.features.data;
  }
  return state.features?.data?.filter(e => e.attributes.tags.includes(state.selectedFeature)) || [];
};
