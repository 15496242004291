import React, { forwardRef } from 'react';
import './input-primary.sass';
import ValidationAlert from '../validation-alert/validation-alert';

const InputPrimary = forwardRef(
  (
    {
      name,
      type,
      value,
      label,
      placeholder,
      error,
      touched,
      handleBlur,
      dirty,
      handleChange,
      onKeyDown,
      defaultValue,
      readonly,
      disabled,
      errorColor,
      style,
      onFocus,
      isCustomPlaceholderColor = false,
      rightIcon,
      rightIconAction,
      autoComplete = 'on',
      min,
      max,
      classes,
      autoFocus = false,
    },
    ref
  ) => {
    const isError = error && touched;
    const errorClassName = isError && !errorColor ? 'input-primary--error' : '';
    const validClassName = !error && touched !== undefined && dirty ? 'input-primary--valid' : '';
    const customPlaceholderColorClass = isCustomPlaceholderColor ? 'input-primary--custom-placeholder-color' : '';

    const getErrorStyles = () => {
      return isError ? { border: `1px solid ${errorColor}`, color: `${errorColor}` } : {};
    };

    return (
      <div
        className={`input-primary ${errorClassName} ${validClassName} ${customPlaceholderColorClass} ${classes}`}
        data-testid={`input_${name}`}>
        {label ? <label>{label}</label> : null}
        <div className={'input-primary__wrapper'}>
          <input
            data-testid='inputPrimary'
            ref={ref}
            min={min}
            max={max}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            name={name}
            type={type}
            autoFocus={autoFocus}
            onKeyDown={onKeyDown}
            value={value}
            defaultValue={defaultValue}
            readOnly={readonly}
            disabled={disabled}
            style={{ ...style, ...getErrorStyles() }}
            onFocus={onFocus}
            autoComplete={autoComplete}
          />
          {rightIcon && <span className={`icon icon--right ${rightIcon}`} onClick={rightIconAction} />}
        </div>
        <ValidationAlert errorMsg={error} isVisible={isError} color={errorColor} />
      </div>
    );
  }
);

InputPrimary.displayName = 'InputPrimary';
export default InputPrimary;
