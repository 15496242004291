import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { Button } from '../../../../../../../components/interface/button/button';
import './edit-header-content.sass';
import { FileStack } from '../../../../../../../services/filestack/file-stack';
import { isUrlAddress, getUrlAddress } from '../../../../../../../services/functions/url-checker/url-address';
import { toast } from 'react-toastify';
import { EditForm, EditInput } from '../../../';

export class EditHeaderContent extends Component {
  onLogoButtonClick() {
    const fileStack = new FileStack();
    fileStack.openFilePicker({
      maxSize: this.props.maxFileSize,
      accept: ['image/*'],
      onUploadDone: res => {
        this.props.dataUpdate({ logoUrl: res.filesUploaded[0].url });
      },
    });
  }

  onFormSend() {
    if (this.props.data.content.logoRedirectUrl === '' || isUrlAddress(this.props.data.content.logoRedirectUrl)) {
      this.props.saveData();
      this.props.closeModal();
    } else {
      toast.error('It is not correct address URL, example: https://example.com');
    }
  }

  renderViewWithLogo() {
    return (
      <div className='tint-edit-header-content tint-edit-header-content--center'>
        <img src={this.props.data.content.logoUrl} />
        <Button
          onClick={() => {
            this.props.dataUpdate({ logoUrl: '' });
          }}
          type='gray'
          size='full-width'
          text='Remove Logo'
        />
        <EditInput
          label='logo link'
          placeholder='Enter Url'
          value={this.props.data.content.logoRedirectUrl}
          onChange={e => {
            this.props.isUrlValid(e.target.value === '' ? true : isUrlAddress(e.target.value));
            this.props.dataUpdate({
              logoRedirectUrl: getUrlAddress(e.target.value),
            });
          }}
        />
      </div>
    );
  }

  renderViewWithoutLogo() {
    return (
      <div className='tint-edit-header-content'>
        <Button
          onClick={this.onLogoButtonClick.bind(this)}
          type='gray'
          size='full-width'
          text='Upload Logo'
          icon='far fa-arrow-to-top'
        />
      </div>
    );
  }

  render() {
    return (
      <EditModalBody title='Upload an image below to add it to this block on your page.'>
        <EditForm onSubmit={this.onFormSend}>
          {this.props.data.content.logoUrl ? this.renderViewWithLogo() : this.renderViewWithoutLogo()}
        </EditForm>
      </EditModalBody>
    );
  }
}
