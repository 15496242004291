import React from 'react';
import './form-builder.form.interface.paragraph.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';

const FormBuilderFormInterfaceParagraph = ({ item, styles }) => {
  if (!item) return null;

  const { t } = useTranslation();

  return (
    <p className={item.class} style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.PARAGRAPH]}>
      {t(item?.nodeValue)}
    </p>
  );
};

FormBuilderFormInterfaceParagraph.displayName = 'FormBuilderFormInterfaceParagraph';
export default React.memo(FormBuilderFormInterfaceParagraph);
