import { ACTIONS } from '../../actions/social-connections/social-connections-modal.actions';

export const ACTION_TYPES = {
  ADD_SOCIAL_CONNECTION: 'add_social_connection',
  TRANSFER_SOCIAL_FEEDS: 'transfer_social_feeds',
};

const modals = {
  [ACTION_TYPES.ADD_SOCIAL_CONNECTION]: {
    opened: false,
    type: '',
  },
  [ACTION_TYPES.TRANSFER_SOCIAL_FEEDS]: {
    opened: false,
    socialFeed: '',
    ownerId: '',
  },
};

export const initialState = {
  modals,
};

export const socialConnectionsModal = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload.modal]: {
            ...state.modals[action.payload.modal],
            ...action.payload.fields,
            opened: true,
          },
        },
      };

    case ACTIONS.UPDATE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload.modal]: {
            ...state.modals[action.payload.modal],
            ...action.payload.fields,
          },
        },
      };

    case ACTIONS.CLOSE_MODAL:
      return {
        ...state,
        modals: {
          ...state.modals,
          [action.payload.modal]: {
            ...action.payload.fields,
            opened: false,
          },
        },
      };
    default:
      return state;
  }
};
