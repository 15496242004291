import React from 'react';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';

export const FormBuilderFormInterfaceSpacer = ({ item, styles }) => {
  return (
    <div
      id={item.id}
      className={`${item.class}`}
      style={{ ...styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SPACER], ...styles[`div-spacer[id=${item.id}]`] }}
    />
  );
};
