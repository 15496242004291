import * as Sentry from '@sentry/browser';
import { ACTIONS } from '../actions/session';

export const SentryReduxMiddleware = () => next => action => {
  if (action.type === ACTIONS.GET_SESSION.SUCCESS) {
    Sentry.setUser({ team_id: action.payload.team_id, id: action.payload.id });
  }

  return next(action);
};
