import { ACTIONS } from '../../actions/experience/experience.actions';
import { TRANSLATION_ACTIONS } from '../../actions/experience/experience-translations.actions';
import update from 'immutability-helper/index';
import produce from 'immer';
import store from '../../../../store';
import { clone } from '../../../../services/functions/clone/clone';
import { ExperienceConfig } from '../../utils/experience.config';

export const initialState = {
  data: undefined,
  dataDraft: undefined,
  isFetching: false,
  error: undefined,
};

export const experience = (state = initialState, action) => {
  switch (action.type) {
    case TRANSLATION_ACTIONS.UPDATE_TRANSLATION:
      return produce(state, draft => {
        draft.dataDraft.attributes.data.translations.draft[action.payload.currentLanguage][action.payload.key] =
          action.payload.value;
      });

    case TRANSLATION_ACTIONS.SAVE_NEW_TRANSLATION:
      return produce(state, draft => {
        const translationEntries = Object.keys(draft.dataDraft.attributes.data.translations.draft);
        translationEntries.map(translationKey => {
          draft.dataDraft.attributes.data.translations.draft[translationKey][action.payload.key] = action.payload.value;
        });
      });

    case TRANSLATION_ACTIONS.STANDARDIZE_TRANSLATIONS:
      return produce(state, draft => {
        if (
          draft.dataDraft.attributes.data.translations.draft[action.payload.currentLanguage][action.payload.value] !==
          undefined
        ) {
          return state;
        }
        const translationEntries = Object.keys(draft.dataDraft.attributes.data.translations.draft);

        translationEntries.map(translationKey => {
          draft.dataDraft.attributes.data.translations.draft[translationKey][action.payload.key] = action.payload.value;
        });
      });

    case ACTIONS.GET_EXPERIENCE.REQUEST:
    case ACTIONS.POST_EXPERIENCE.REQUEST:
    case ACTIONS.PUT_EXPERIENCE.REQUEST:
    case ACTIONS.DELETE_EXPERIENCE.REQUEST:
      return { ...state, isFetching: true, isError: false };

    case ACTIONS.GET_EXPERIENCE.SUCCESS:
    case ACTIONS.POST_EXPERIENCE.SUCCESS:
    case ACTIONS.PUT_EXPERIENCE.SUCCESS:
      return {
        ...state,
        data: action.payload,
        dataDraft: clone(action.payload),
        isFetching: false,
        isError: false,
      };
    case ACTIONS.GET_EXPERIENCE.FAILURE:
    case ACTIONS.POST_EXPERIENCE.FAILURE:
    case ACTIONS.PUT_EXPERIENCE.FAILURE:
    case ACTIONS.DELETE_EXPERIENCE.FAILURE:
      return { ...state, isError: action.payload, isFetching: false };

    case ACTIONS.DELETE_EXPERIENCE.SUCCESS:
    case ACTIONS.CLEAR_EXPERIENCE:
      return {
        ...state,
        data: undefined,
        isError: undefined,
        isFetching: false,
        dataDraft: undefined,
      };

    case ACTIONS.SAVE_DATA_ATTRIBUTES_DRAFT:
      return {
        ...state,
        dataDraft: {
          ...state.dataDraft,
          attributes: { ...state.dataDraft.attributes, ...action.payload },
        },
      };

    case ACTIONS.REORDER_EXPERIENCE:
      return produce(state, draftState => {
        draftState.dataDraft.attributes.data.draft = action.payload.data.draft;
      });

    case ACTIONS.REORDER_MULTI_COLUMN_EXPERIENCE:
      return produce(state, draftState => {
        draftState.dataDraft.attributes.data.draft[action.payload.index].data.content.sections = action.payload.data;
      });

    case ACTIONS.SAVE_DATA_CONTENT_DRAFT:
      return produce(state, draftState => {
        //clone is used here because we are getting objects inside payload.data so we cannot use simple spread operator
        //for example footerLinks, formFields
        const newContent = {
          ...draftState.dataDraft.attributes.data.draft[action.payload.pageSectionIndex].data.content,
          ...clone(action.payload.data),
        };
        draftState.dataDraft.attributes.data.draft[action.payload.pageSectionIndex].data.content = newContent;
      });

    case ACTIONS.SAVE_DATA_STYLES_DRAFT:
      return update(state, {
        dataDraft: {
          attributes: {
            data: {
              draft: {
                [action.payload.pageSectionIndex]: {
                  data: { styles: { $merge: action.payload.data } },
                },
              },
            },
          },
        },
      });

    case ACTIONS.SAVE_DATA_MULTI_COLUMN_STYLES_DRAFT:
      return update(state, {
        dataDraft: {
          attributes: {
            data: {
              draft: {
                [action.payload.parentSectionIndex]: {
                  data: {
                    content: {
                      sections: {
                        [action.payload.pageSectionIndex]: {
                          data: { styles: { $merge: action.payload.data } },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      });

    case ACTIONS.DELETE_MULTI_COLUMN_SECTION:
      return update(state, {
        dataDraft: {
          attributes: {
            data: {
              draft: {
                [action.payload.parentSectionIndex]: {
                  data: {
                    content: {
                      sections: { $set: action.payload.updatedSectionList },
                    },
                  },
                },
              },
            },
          },
        },
      });

    case ACTIONS.SAVE_DATA_MULTI_COLUMN_CONTENT_DRAFT:
      return update(state, {
        dataDraft: {
          attributes: {
            data: {
              draft: {
                [action.payload.parentSectionIndex]: {
                  data: {
                    content: {
                      sections: {
                        [action.payload.pageSectionIndex]: {
                          data: { content: { $set: action.payload.data } },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      });

    case ACTIONS.SAVE_CUSTOMIZABLE_MULTI_COLUMN_TEXT_DRAFT:
      return update(state, {
        dataDraft: {
          attributes: {
            data: {
              draft: {
                [action.payload.parentSectionIndex]: {
                  data: {
                    content: {
                      sections: {
                        [action.payload.pageSectionIndex]: {
                          data: {
                            content: {
                              customizableHeaders: {
                                [action.payload.customizableHeaderIndex]: {
                                  $merge: action.payload.customizableHeader,
                                },
                              },
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      });

    case ACTIONS.SAVE_CUSTOMIZABLE_TEXT_DRAFT:
      return update(state, {
        dataDraft: {
          attributes: {
            data: {
              draft: {
                [action.payload.pageSectionIndex]: {
                  data: {
                    content: {
                      customizableHeaders: {
                        [action.payload.customizableHeaderIndex]: {
                          $merge: action.payload.customizableHeader,
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      });

    case ACTIONS.DELETE_SECTION:
    case ACTIONS.ADD_CUSTOM_EXPERIENCE:
      return produce(state, draftState => {
        if (action.payload.attribute) {
          const key = Object.keys(action.payload.attribute)[0];
          draftState.data.attributes[key] = Object.values(action.payload.attribute)[0];
          draftState.data.attributes[key] = Object.values(action.payload.attribute)[0];
        }
        draftState.data.attributes.data.draft = action.payload.experience;
        draftState.dataDraft.attributes.data.draft = action.payload.draftExperience;
      });

    case ACTIONS.POST_CUSTOM_FONT:
      return produce(state, draftState => {
        if (!state.data.attributes.data.customFonts) {
          draftState.data.attributes.data = {
            ...state.data.attributes.data,
            customFonts: {
              draft: [],
            },
          };

          draftState.dataDraft.attributes.data = {
            ...state.dataDraft.attributes.data,
            customFonts: {
              draft: [],
            },
          };
        }

        draftState.data.attributes.data.customFonts.draft.push(action.payload);
        draftState.dataDraft.attributes.data.customFonts.draft.push(action.payload);
      });

    case ACTIONS.ADD_FONT_FAMILY:
      return update(state, {
        data: {
          attributes: {
            data: {
              fontFamilies: { $set: action.payload.fontFamily },
            },
          },
        },
        dataDraft: {
          attributes: {
            data: {
              fontFamilies: { $set: action.payload.fontFamily },
            },
          },
        },
      });

    case ACTIONS.ADD_CUSTOM_LANGUAGE:
      return produce(state, draftState => {
        const defaultTranslations =
          draftState.dataDraft.attributes.data.translations.draft[
            state.dataDraft.attributes.data.locales.draft.find(e => e.isDefault).value
          ];

        draftState.dataDraft.attributes.data.translations.draft[action.payload.value] = defaultTranslations;

        draftState.dataDraft.attributes.data.locales.draft.push(action.payload);
      });

    case ACTIONS.DELETE_CUSTOM_LANGUAGE:
      return produce(state, draftState => {
        draftState.dataDraft.attributes.data.locales.draft = draftState.dataDraft.attributes.data.locales.draft.filter(
          (lang, i) => {
            return i !== action.payload;
          }
        );
      });

    case ACTIONS.SET_DEFAULT_LANGUAGE:
      return produce(state, draftState => {
        draftState.dataDraft.attributes.data.locales.draft = draftState.dataDraft.attributes.data.locales.draft.map(
          e => {
            e.isDefault = e.value === action.payload.value.replace('_', '-');
            return e;
          }
        );
      });

    case ACTIONS.DISCARD_DRAFT_DATA:
      return {
        ...state,
        dataDraft: JSON.parse(JSON.stringify(state.data)),
      };
    case ACTIONS.PUBLISH_EXPERIENCE:
    default:
      return state;
  }
};

export default experience;
export const selectExperienceType = state => (state.data ? state.data.attributes.type : undefined);

export const getCurrentExperience = state => {
  return state?.experience?.dataDraft;
};

export const getAllExperiences = state => {
  return state?.experiences?.data?.data;
};

export const selectFontFamily = state => (state.data ? state.data.attributes.data.fontFamilies : undefined);

export const getTranslation = (state, key, translations) => {
  if (translations !== undefined) {
    return translations[store.getState().languages.currentLanguage.value][key];
  }
  return '';
};

export const getCustomLanguageList = (state, property) => {
  return state && state.attributes.data.locales[property]
    ? state.attributes.data.locales[property]
    : [
        {
          value: ExperienceConfig.languages.defaultLanguage.value,
          name: ExperienceConfig.languages.defaultLanguage.name,
        },
      ];
};

export const getDefaultLanguage = (state, property) => {
  return state && state.attributes.data.locales[property]
    ? state.attributes.data.locales[property].find(e => e.isDefault || e.default).value
    : ExperienceConfig.languages.defaultLanguage.value;
};

export const getCustomFontsList = state => {
  return (
    state &&
    state.attributes.data.customFonts &&
    state.attributes.data.customFonts[state.attributes.data.customFonts.draft ? 'draft' : 'published']
  );
};
