import createRoutine, { createPrefixedActionName } from '../../../services/functions/redux-routines/redux-routines';

const prefix = 'SOCIAL_PUBLISHING';

export const ACTIONS = {
  GET_SCHEDULED_CONTENT: createRoutine(createPrefixedActionName(prefix, 'GET_SCHEDULED_CONTENT')),
  GET_LONG_POOLING_SCHEDULED_CONTENT: createRoutine(
    createPrefixedActionName(prefix, 'GET_LONG_POOLING_SCHEDULED_CONTENT')
  ),
  GET_SCHEDULED_CONTENT_SOCIAL_CONNECTIONS: createRoutine(
    createPrefixedActionName(prefix, 'GET_SCHEDULED_CONTENT_SOCIAL_CONNECTIONS')
  ),
  POST_SCHEDULED_CONTENT: createRoutine(createPrefixedActionName(prefix, 'POST_SCHEDULED_CONTENT')),
  PATCH_SCHEDULED_CONTENT: createRoutine(createPrefixedActionName(prefix, 'PATCH_SCHEDULED_CONTENT')),
  DELETE_SCHEDULED_CONTENT: createRoutine(createPrefixedActionName(prefix, 'DELETE_SCHEDULED_CONTENT')),
  DUPLICATE_SCHEDULED_CONTENT: createRoutine(createPrefixedActionName(prefix, 'DUPLICATE_SCHEDULED_CONTENT')),
  CHECK_SCHEDULED_CONTENT_STATUS: createRoutine(createPrefixedActionName(prefix, 'CHECK_SCHEDULED_CONTENT_STATUS')),
  GET_SCHEDULED_CONTENT_AGGREGATIONS: createRoutine(
    createPrefixedActionName(prefix, 'GET_SCHEDULED_CONTENT_AGGREGATIONS')
  ),
  UPDATE_ASSOCIATIONS: createPrefixedActionName(prefix, 'UPDATE_ASSOCIATIONS'),
  CLEAR_DATA: createPrefixedActionName(prefix, 'CLEAR_DATA'),
};

export const clearData = () => ({
  type: ACTIONS.CLEAR_DATA,
});

export const updateAssociations = data => ({
  type: ACTIONS.UPDATE_ASSOCIATIONS,
  payload: data,
});

export const getScheduledContentSocialConnectionsStart = () => ({
  type: ACTIONS.GET_SCHEDULED_CONTENT_SOCIAL_CONNECTIONS.REQUEST,
});

export const getScheduledContentSocialConnectionsSuccess = data => ({
  type: ACTIONS.GET_SCHEDULED_CONTENT_SOCIAL_CONNECTIONS.SUCCESS,
  payload: data,
});

export const getScheduledContentSocialConnectionsError = err => ({
  type: ACTIONS.GET_SCHEDULED_CONTENT_SOCIAL_CONNECTIONS.FAILURE,
  payload: err,
});

export const duplicateScheduledContentStart = () => ({
  type: ACTIONS.DUPLICATE_SCHEDULED_CONTENT.REQUEST,
});

export const duplicateScheduledContentSuccess = data => ({
  type: ACTIONS.DUPLICATE_SCHEDULED_CONTENT.SUCCESS,
  payload: data,
});

export const duplicateScheduledContentError = err => ({
  type: ACTIONS.DUPLICATE_SCHEDULED_CONTENT.FAILURE,
  payload: err,
});

export const getScheduledContentStart = () => ({
  type: ACTIONS.GET_SCHEDULED_CONTENT.REQUEST,
});

export const getScheduledContentSuccess = data => ({
  type: ACTIONS.GET_SCHEDULED_CONTENT.SUCCESS,
  payload: data,
});

export const getScheduledContentError = err => ({
  type: ACTIONS.GET_SCHEDULED_CONTENT.FAILURE,
  payload: err,
});

export const getLongPoolingScheduledContentStart = () => ({
  type: ACTIONS.GET_LONG_POOLING_SCHEDULED_CONTENT.REQUEST,
});

export const getLongPoolingScheduledContentSuccess = data => ({
  type: ACTIONS.GET_LONG_POOLING_SCHEDULED_CONTENT.SUCCESS,
  payload: data,
});

export const getLongPoolingScheduledContentError = err => ({
  type: ACTIONS.GET_LONG_POOLING_SCHEDULED_CONTENT.FAILURE,
  payload: err,
});

export const deleteScheduledContentStart = () => ({
  type: ACTIONS.DELETE_SCHEDULED_CONTENT.REQUEST,
});

export const deleteScheduledContentSuccess = data => ({
  type: ACTIONS.DELETE_SCHEDULED_CONTENT.SUCCESS,
  payload: data,
});

export const deleteScheduledContentError = err => ({
  type: ACTIONS.DELETE_SCHEDULED_CONTENT.FAILURE,
  payload: err,
});

export const getScheduledContentAggregationsStart = () => ({
  type: ACTIONS.GET_SCHEDULED_CONTENT_AGGREGATIONS.REQUEST,
});

export const getScheduledContentAggregationsSuccess = data => ({
  type: ACTIONS.GET_SCHEDULED_CONTENT_AGGREGATIONS.SUCCESS,
  payload: data,
});

export const getScheduledContentAggregationsError = err => ({
  type: ACTIONS.GET_SCHEDULED_CONTENT_AGGREGATIONS.FAILURE,
  payload: err,
});

export const postScheduledContentStart = () => ({
  type: ACTIONS.POST_SCHEDULED_CONTENT.REQUEST,
});

export const postScheduledContentSuccess = data => ({
  type: ACTIONS.POST_SCHEDULED_CONTENT.SUCCESS,
  payload: data,
});

export const postScheduledContentError = err => ({
  type: ACTIONS.POST_SCHEDULED_CONTENT.FAILURE,
  payload: err,
});

export const patchScheduledContentStart = () => ({
  type: ACTIONS.PATCH_SCHEDULED_CONTENT.REQUEST,
});

export const patchScheduledContentSuccess = (id, data) => ({
  type: ACTIONS.PATCH_SCHEDULED_CONTENT.SUCCESS,
  payload: { id, data: data.data, included: data.included },
});

export const patchScheduledContentError = err => ({
  type: ACTIONS.PATCH_SCHEDULED_CONTENT.FAILURE,
  payload: err,
});

export const checkScheduledContentStatusStart = () => ({
  type: ACTIONS.CHECK_SCHEDULED_CONTENT_STATUS.REQUEST,
});

export const checkScheduledContentStatusSuccess = data => ({
  type: ACTIONS.CHECK_SCHEDULED_CONTENT_STATUS.SUCCESS,
  payload: data,
});

export const checkScheduledContentStatusError = err => ({
  type: ACTIONS.CHECK_SCHEDULED_CONTENT_STATUS.FAILURE,
  payload: err,
});
