import { createPrefixedActionName, createRoutine } from '../../../../services/functions/redux-routines/redux-routines';
import { FORM_BUILDER } from '../../../../actions/consts';

const prefix = 'FORM_BUILDER.SUBMISSION';

export const ACTIONS = {
  GET_SUBMISSION: createRoutine(createPrefixedActionName(prefix, 'GET_SUBMISSION')),
  GET_METRICS: createRoutine(createPrefixedActionName(prefix, 'GET_METRICS')),
  DELETE_SUBMISSION: createRoutine(createPrefixedActionName(prefix, 'DELETE_SUBMISSION')),
  POST_SUBMISSION: createRoutine(createPrefixedActionName(prefix, 'POST_SUBMISSION')),
  RESET_STATE: createPrefixedActionName(FORM_BUILDER, 'RESET_STATE'),
};

export const getFormSubmissionStart = () => ({
  type: ACTIONS.GET_SUBMISSION.REQUEST,
});

export const getFormSubmissionSuccess = data => ({
  type: ACTIONS.GET_SUBMISSION.SUCCESS,
  payload: data,
});

export const getFormSubmissionError = err => ({
  type: ACTIONS.GET_SUBMISSION.FAILURE,
  payload: err,
});

export const postFormSubmissionStart = () => ({
  type: ACTIONS.POST_SUBMISSION.REQUEST,
});

export const postFormSubmissionSuccess = data => ({
  type: ACTIONS.POST_SUBMISSION.SUCCESS,
  payload: { data },
});

export const postFormSubmissionError = err => ({
  type: ACTIONS.POST_SUBMISSION.FAILURE,
  payload: err,
});

export const deleteFormSubmissionStart = () => ({
  type: ACTIONS.DELETE_SUBMISSION.REQUEST,
});

export const deleteFormSubmissionSuccess = data => ({
  type: ACTIONS.DELETE_SUBMISSION.SUCCESS,
  payload: { data },
});

export const deleteFormSubmissionError = err => ({
  type: ACTIONS.DELETE_SUBMISSION.FAILURE,
  payload: err,
});

export const getFormSubmissionMetricsStart = () => ({
  type: ACTIONS.GET_METRICS.REQUEST,
});

export const getFormSubmissionMetricsSuccess = data => ({
  type: ACTIONS.GET_METRICS.SUCCESS,
  payload: { data },
});

export const getFormSubmissionMetricsError = err => ({
  type: ACTIONS.GET_METRICS.FAILURE,
  payload: err,
});
