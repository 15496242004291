import { COMMON_SETTINGS } from './common-settings';
import { languageList } from '../configs/personalization-languages';

export const GRID = {
  name: 'Grid',
  key: 'hypeTemplate_realtime',
  premiumDesign: true,
  default_settings: {
    ...COMMON_SETTINGS,
    themeName: 'hypeTemplate_realtime',
    animationIndex: '0',
    post_duration: '8000',
  },
  guards: {
    CTA: false,
    waterfall: false,
    popup: true,
    shareIcons: false,
    removeDuplicateImages: true,
    genericAvatarBackgroundColor: true,
    genericAvatarTextColor: true,
    customCSS: true,
    backgroundImage: true,
    banner: true,
  },
  themes_settings: [
    {
      key: 'animationIndex',
      label: 'Animation',
      type: 'dropdown',
      options: [
        {
          value: '0',
          name: 'Fade In Up',
        },
        {
          value: '1',
          name: 'Fade In Down',
        },
        {
          value: '4',
          name: 'Flip In',
        },
        {
          value: '6',
          name: 'Bounce In',
        },
        {
          value: '7',
          name: 'Rotate In',
        },
        {
          value: '8',
          name: 'Roll In',
        },
      ],
    },
    {
      key: 'post_duration',
      label: 'Post Highlight Duration',
      type: 'dropdown',
      options: [
        {
          value: '4000',
          name: '4 seconds',
        },
        {
          value: '6000',
          name: '6 seconds',
        },
        {
          value: '8000',
          name: '8 seconds',
        },
        {
          value: '10000',
          name: '10 seconds',
        },
        {
          value: '15000',
          name: '15 seconds',
        },
        {
          value: '30000',
          name: '30 seconds',
        },
        {
          value: '60000',
          name: '60 seconds',
        },
      ],
    },
    {
      key: 'gridwidth',
      type: 'slider',
      label: 'Min Column Width',
      min: '150',
      max: '500',
    },
    {
      key: 'gridheight',
      type: 'slider',
      label: 'Min Row Height',
      min: '150',
      max: '500',
    },
    {
      key: 'gridpadding',
      type: 'slider',
      label: 'Grid Padding',
      min: '0',
      max: '100',
    },
  ],
  font_settings: [
    {
      key: 'fontcolor_post',
      label: 'Font Color',
      type: 'colorPicker',
    },
    {
      key: 'fontsize_secondary',
      type: 'slider',
      label: 'Font size',
      min: '8',
      max: '50',
    },
    {
      key: 'color_namebar_buttons',
      label: 'Accent Color',
      type: 'colorPicker',
    },
  ],
  extra_settings: [
    {
      key: 'time_language',
      type: 'dropdown',
      label: 'Language for post timestamp',
      options: languageList,
    },
  ],
  extra_popup_settings: [
    // {
    //   key: 'popup_products_visibility',
    //   label: 'Hide Product Hotspots',
    //   control_type: 'Checkbox',
    //   target: '#personalize-popup-controls',
    //   add_class: 'control-divide-above',
    //   required_flags: ['ecommerce'],
    // },
  ],
};
