import { dropdownAdditionalSelectField } from '../utils/common/common';

// Object to change copy for footer label in all social feeds
export const SocialFeedsFooterLabels = {
  firstStepPrev: 'Cancel',
  firstStepNext: 'Next',
  firstStepSave: 'Save',
  finalStepPrev: 'Previous',
  finalStepNext: 'Add Social Feed',
  finalStepUpgrade: 'Upgrade',
};

export const flickrDisabledButton = values => {
  return (
    values.account === undefined ||
    values.account === '' ||
    values.account?.value?.value === dropdownAdditionalSelectField.value
  );
};

export const twitterDisabledButton = values => {
  return (
    values.account === undefined ||
    values.account === '' ||
    values.account?.value?.value === dropdownAdditionalSelectField.value
  );
};

export const tumblrDisabledButton = values => {
  return (
    values.account === undefined ||
    values.account === '' ||
    values.account?.value?.value === dropdownAdditionalSelectField.value
  );
};

export const instagramDisabledButton = (values, additionalCondition) => {
  return values?.account === '' || additionalCondition;
};

export const facebookDisabledButton = (values, additionalCondition) => {
  return values?.account === '' || additionalCondition;
};

export const publicPostingDisabledButton = values => {
  return !values.checked?.includes('customTerms');
};

export const linkedinDisabledButton = (values, additionalCondition) => {
  return values?.account === '' || additionalCondition;
};

export const slackDisabledButton = (values, additionalCondition) => {
  return values?.account === '' || additionalCondition;
};

export const hootsuiteDisabledButton = (values, additionalCondition) => {
  return values?.account === '' || additionalCondition;
};

export const webexDisabledButton = (values, additionalCondition) => {
  return values?.account === '' || additionalCondition;
};

export const experienceBuilderDisabledButton = values => {
  return !values?.experience?.value || values?.experience?.value === '';
};

export const formsDisabledButton = values => {
  return !values?.form?.value || values?.form?.value === '';
};

export const postMediaAltTextDisabledButton = values => {
  return !values?.media?.value || values?.media?.value === '';
};

export const pinterestDisabledButton = (values, additionalCondition) => {
  return values?.account === '' || additionalCondition;
};

export const creatorIqDisabledButton = (values, additionalCondition) => {
  return (
    values.account === undefined ||
    values.account === '' ||
    values.account?.value === '' ||
    values.account?.value === dropdownAdditionalSelectField.value ||
    additionalCondition
  );
};
