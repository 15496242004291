import React from 'react';
import TintModal from '../modal/modal';
import ModalHeader from '../modal/components/modal-header/modal-header';
import ModalFooter from '../modal/components/footers/modal-footer/modal-footer';

export const ConfirmModalResult = {
  CONFIRM: 'confirm',
  REJECT: 'reject',
};

export const confirmModalId = 'confirm-modal-id';

const ConfirmModal = ({
  onChange,
  fn,
  leftButtonText,
  rightButtonText,
  confirmButtonType = 'primary',
  description,
}) => {
  return (
    <TintModal
      headerTitle='Are you sure?'
      modalHeader={props => <ModalHeader {...props} />}
      modalFooter={props => (
        <ModalFooter
          {...props}
          closeButtonText={leftButtonText}
          confirmButtonType={confirmButtonType}
          confirmButtonText={rightButtonText}
        />
      )}
      isOpen={true}
      onConfirm={() => onChange(ConfirmModalResult.CONFIRM, fn)}
      onClose={() => onChange(ConfirmModalResult.REJECT, fn)}>
      <p className='secondary-text'>{description}</p>
    </TintModal>
  );
};

export default ConfirmModal;
