import React, { useState } from 'react';
import './sidebar-switcher.sass';
import TintSidebar from '../sidebar/sidebar';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

export const TintSidebarSwitcherStateType = {
  MAIN: 'main',
  EDIT: 'edit',
  CREATE: 'create',
};

const TintSidebarSwitcher = ({
  modalDetails,
  onSubmit,
  onChangeContent,
  onRemove,
  onClose,
  closeSidebarAfterSubmit = true,
}) => {
  const debounceDelayMs = 100;
  const [sidebarDetails, setSidebarDetails] = useState();
  const [isModalVisible, setModalVisibility] = useState(modalDetails.isVisible);
  const [isEditMode] = useState(modalDetails.isEditMode);

  const onChangeData = debounce(data => {
    setSidebarDetails(data);
  }, debounceDelayMs);

  const renderCurrentComponent = () => {
    return (
      modalDetails.component &&
      modalDetails.component({
        onChangeContent: onChangeContent,
        onChangeData: onChangeData,
        onRemove: onRemove,
        isEditMode,
        data: modalDetails.data,
        state: modalDetails.state,
      })
    );
  };

  const onFooterLeftButtonClick = () => {
    switch (modalDetails.state) {
      case TintSidebarSwitcherStateType.MAIN:
        onSidebarClose();
        break;

      case TintSidebarSwitcherStateType.CREATE:
        onChangeContent(TintSidebarSwitcherStateType.MAIN);
        break;

      case TintSidebarSwitcherStateType.EDIT:
        onChangeContent(TintSidebarSwitcherStateType.MAIN);
        break;

      default:
        return;
    }
  };

  const onRightButtonClick = () => {
    onSubmit?.(
      {
        ...sidebarDetails,
        isValid: undefined,
      },
      modalDetails.state,
      modalDetails.data?.id
    );
    closeSidebarAfterSubmit && onSidebarClose();
  };

  const onSidebarClose = () => {
    setModalVisibility(false);
    onClose && onClose();
  };

  return (
    isModalVisible && (
      <TintSidebar
        sidebarClassName={'tint-sidebar-switcher'}
        title={modalDetails.headerTitle}
        isOpen={isModalVisible}
        onClose={onSidebarClose}
        defaultFooterStyle={false}
        onLeftButtonClick={onFooterLeftButtonClick}
        oneButton={modalDetails.state === TintSidebarSwitcherStateType.MAIN}
        leftButtonText={modalDetails.state === TintSidebarSwitcherStateType.MAIN ? 'Confirm' : 'Previous'}
        rightButtonText={modalDetails.state !== TintSidebarSwitcherStateType.MAIN ? 'Save' : 'Edit'}
        isRightButtonDisabled={!sidebarDetails || (sidebarDetails && !sidebarDetails.isValid)}
        onRightButtonClick={onRightButtonClick}>
        <div className='tint-sidebar-switcher__content'>{renderCurrentComponent()}</div>
      </TintSidebar>
    )
  );
};

TintSidebarSwitcher.propTypes = {
  mainComponent: PropTypes.any,
  onChangeContent: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default TintSidebarSwitcher;
