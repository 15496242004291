import { createPrefixedActionName } from '../../../../services/functions/redux-routines/redux-routines';
import { EXPERIENCES } from '../../../../feature/experience/consts';

export const ACTIONS = {
  SET_CURRENT_LANGUAGE: createPrefixedActionName(EXPERIENCES, 'SET_CURRENT_LANGUAGE'),
};

export const setCurrentLanguage = currentLanguage => ({
  type: ACTIONS.SET_CURRENT_LANGUAGE,
  payload: currentLanguage,
});
