import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { WebSocketService } from '../../../services/ws-service/ws.service';

export const useWebsocket = ({ onMessage, type }) => {
  const session = useSelector(state => state.session);
  let ws;

  useEffect(() => {
    ws = WebSocketService.connect({ session, onMessage, type: type });
    return () => {
      WebSocketService.unsubscribe({ pusher: ws, session });
    };
  }, []);

  return ws;
};
