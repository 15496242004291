import React from 'react';
import './tint-editor-top-navigation.sass';
import { faArrowToRight } from '@fortawesome/pro-solid-svg-icons';
import { faCheck, faTrash, faLock } from '@fortawesome/free-solid-svg-icons';
import { Button } from '../../../../../components/interface/button/button';
import TintIcon from '../../../../../components/interface/icon/icon';
import { useSelector } from 'react-redux';
import { TintEditorViewState } from '../../../containers/tint-editor/tint-editor.container';

export const FeedQuery = {
  ALL: 'public,private',
  PUBLIC: 'public',
  PRIVATE: 'private,image_moderation_failed',
  TRASH: 'deleted,moderation_deleted,image_moderation_deleted,privacy_deleted',
};

const feedOptions = [
  {
    name: TintEditorViewState.ALL,
    value: FeedQuery.ALL,
  },
  {
    name: TintEditorViewState.PUBLIC,
    value: FeedQuery.PUBLIC,
    icon: faCheck,
  },
  {
    name: TintEditorViewState.PRIVATE,
    value: FeedQuery.PRIVATE,
    icon: faLock,
  },
  {
    name: TintEditorViewState.TRASH,
    value: FeedQuery.TRASH,
    icon: faTrash,
  },
];

const TintEditorTopNavigation = ({ onToggleClick, isGlobalPageState, isSettingsSidebarExpanded, onFeedTabClick }) => {
  const sortByStatus = useSelector(state => state.tintEditorPosts?.sortPostsBy?.status);

  return (
    <div className='tint-editor-top-navigation'>
      <ul className='top-bar-navigation'>
        {!isGlobalPageState && (
          <li
            className={`top-bar-navigation__item top-bar-navigation__item--toggle ${
              !isSettingsSidebarExpanded ? 'top-bar-navigation__item--visible' : 'top-bar-navigation__item--hidden'
            }`}>
            <Button type='no-style' onClick={onToggleClick} icon={faArrowToRight} />
          </li>
        )}
        {feedOptions.map(el => (
          <li
            key={el.value}
            className={`top-bar-navigation__item ${
              sortByStatus === el.value ? 'top-bar-navigation__item--active' : ''
            }`}>
            <a onClick={() => onFeedTabClick(el.value, el.name)}>
              {el.icon && <TintIcon icon={el.icon} />}
              {!el.icon ? el.name : <span className='top-bar-navigation__name'>{el.name}</span>}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TintEditorTopNavigation;
