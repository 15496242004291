import React from 'react';
import './form-builder.form.interface.success-message.sass';
import { useTranslation } from 'react-i18next';
import { FORM_BUILDER_STYLE_ELEMENT_TYPE } from '../../../../utils/form-builder.types';
import { Typography } from '../../../../../../components/interface/typography/typography';

const EditSuccessMessageSidebarInputTypes = {
  TITLE: 'h2',
  MESSAGE: 'p',
  LINK: 'a',
};

const FormBuilderFormInterfaceSuccessMessage = ({ styles, item }) => {
  if (!item) return null;

  const { t } = useTranslation();
  const successMessageData = item.elements;

  const linksArray = successMessageData.filter(el => el.nodeName === EditSuccessMessageSidebarInputTypes.LINK);

  return (
    <output className='div div-success-message'>
      <span
        className='div-success-message__title'
        style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SUCCESS_MESSAGE_TITLE]}>
        {t(successMessageData?.find(el => el.nodeName === 'h2')?.nodeValue)}
      </span>
      <div className='div-success-message__caption-container'>
        <span
          className='div-success-message__caption'
          style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SUCCESS_MESSAGE_CAPTION]}>
          {t(successMessageData?.find(el => el.nodeName === 'p')?.nodeValue)}
        </span>
      </div>
      <div className='div-success-message__links'>
        {linksArray.map(link => {
          return (
            <div className='div-success-message__link' key={link?.key}>
              <Typography component='a' href={link?.nodeValue}>
                {link?.name}
              </Typography>
            </div>
          );
        })}
      </div>
      {/*<button*/}
      {/*  className='div-success-message__button'*/}
      {/*  style={styles[FORM_BUILDER_STYLE_ELEMENT_TYPE.SUCCESS_MESSAGE_BUTTON]}>*/}
      {/*  OK*/}
      {/*</button>*/}
    </output>
  );
};

FormBuilderFormInterfaceSuccessMessage.displayName = 'FormBuilderFormInterfaceSuccessMessage';
export default React.memo(FormBuilderFormInterfaceSuccessMessage);
