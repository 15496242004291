import React from 'react';
import PropTypes from 'prop-types';
import { ApiService } from '../../../../../../../../services/api-service/api-service';
import { DropdownHeader } from '../../../../../../../../components/interface/dropdown/button/header/dropdown-header';
import DropdownSortList from '../../../../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list';
import { toast } from 'react-toastify';
import DropdownButton, {
  ButtonType,
} from '../../../../../../../../components/interface/dropdown/button/dropdown-button';
import { SocialFeedsSource, SocialFeedsTypes } from '../../../../../../../../types/social-feeds';

export const tintSelectorDefaultValue = 'Select TINT';
export const TintSelector = ({ defaultTint, dataUpdate, onChange, onSocialFeedSelect, isPublicPosting = true }) => {
  const [tints, setTints] = React.useState();
  const [filteredTints, setFilteredTints] = React.useState([]);
  const [included, setIncluded] = React.useState([]);
  const [isFetching, setFetching] = React.useState(false);
  const [currentTint, setCurrentTint] = React.useState(defaultTint);

  React.useEffect(() => {
    setFetching(true);
    ApiService.get('/tints?include=social_feeds&page[size]=500')
      .then(res => {
        const _filteredTints = isPublicPosting ? filterByPublicPosting(res.data) : res.data.data;
        setFilteredTints(_filteredTints);

        if (defaultTint !== tintSelectorDefaultValue) {
          onSocialFeedSelect &&
            onSocialFeedSelect(getFeed({ data: res.data, tintSlug: defaultTint }).attributes.options);
        }

        setCurrentTint(_filteredTints.find(el => el.attributes.slug === defaultTint)?.attributes?.name);
        setIncluded(res.data.included);
        setTints(res.data);
      })
      .finally(() => {
        setFetching(false);
      });
  }, []);

  const getFeed = ({ data, tintSlug }) => {
    const tintSocialFeeds = data.data.find(e => e.attributes.slug === tintSlug).relationships.social_feeds.data;
    return data.included
      .filter(e => e.type === 'social_feed' && (!isPublicPosting || e.attributes.source === 'public_post'))
      .find(e => tintSocialFeeds.some(r => e.id === r.id));
  };

  const filterByPublicPosting = data => {
    const publicPostArrayId = [];
    data.included.map(e => {
      if (e.type === 'social_feed' && e.attributes.source === 'public_post') {
        publicPostArrayId.push(e.id);
      }
    });
    return data.data.filter(e =>
      publicPostArrayId.some(r => !!e.relationships.social_feeds.data.find(socialFeed => socialFeed.id === r))
    );
  };

  React.useEffect(() => {
    if (tints?.data?.length > 0) {
      onChange?.({ formBuilderId: getFormBuilderId(defaultTint) });
    }
  }, [tints]);

  const getFormBuilderId = tintSlug => {
    if (!tintSlug) return '';

    const tint = tints?.data?.find(e => e.attributes.slug === tintSlug);
    const socialFeedIds = tint?.relationships?.social_feeds?.data.map(el => el.id);

    const socialFeedsPublicPosting = included
      .filter(
        el => el.attributes.type === SocialFeedsTypes.POST && el.attributes.source === SocialFeedsSource.PUBLIC_POST
      )
      .map(el => el.id);
    const publicPostId = socialFeedsPublicPosting.filter(id => socialFeedIds?.includes(id));
    const formId = included.find(el => publicPostId.includes(el.id))?.attributes?.options?.form_id;

    return formId?.toString() || '';
  };

  const getTintNames = () => {
    const defaultValue = {
      value: null,
      name: tintSelectorDefaultValue,
    };

    const mapTintNames = filteredTints.map(e => ({
      value: e.attributes.slug,
      name: e.attributes.name,
    }));

    return [defaultValue, ...mapTintNames];
  };

  const onTintSelect = _value => {
    const value = _value.value;
    let socialFeedOptions;
    if (value !== null) {
      if (getFeed({ data: tints, tintSlug: value }) === undefined) {
        toast.error(`Tint ${value} doesn't have any social feeds`);
      }
      socialFeedOptions = getFeed({ data: tints, tintSlug: value }).attributes.options;
    } else {
      socialFeedOptions = tintSelectorDefaultValue;
    }
    onSocialFeedSelect && onSocialFeedSelect(socialFeedOptions);
    const tintSlug = value;
    const tint = tints.data.find(e => e.attributes.slug === tintSlug);

    dataUpdate({
      tintSlug,
      socialFeedId: tint && tint.relationships.social_feeds.data[0] ? tint.relationships.social_feeds.data[0].id : '',
      formBuilderId: getFormBuilderId(tintSlug),
    });
  };

  return isFetching ? null : (
    <div className='tint-edit-input-container'>
      <span className='tint-edit-input-container__label'>TINT</span>
      <DropdownButton
        dropdownHeader={props => <DropdownHeader {...props} />}
        dropdownList={props => <DropdownSortList {...props} />}
        list={getTintNames()}
        iconRight='fa fa-caret-down'
        placeholder={currentTint ? currentTint : tintSelectorDefaultValue}
        styles={{ height: '28px' }}
        buttonType={ButtonType.BUTTON_GRAY_BORDER}
        onChangeValue={value => {
          onTintSelect(value);
          setCurrentTint(value.name);
        }}
      />
    </div>
  );
};

TintSelector.propTypes = {
  defaultTint: PropTypes.string,
};
