import React from 'react';
import EditModalButton from '../../../../../../../components/edit-modal/edit-modal-footer/edit-modal-button/edit-modal-button';
import { EditModalStep } from '../../../../../../../components/edit-modal/edit-modal-step/edit-modal-step';
import { EditTextArea } from '../../../index';

export const FormRulesStep = ({
  stepTitle,
  onContentUpdate,
  editTextAreaPlaceholder,
  textareaDefaultValue,
  closeModal,
  selectStep,
}) => {
  return (
    <>
      <EditModalStep
        closeModalMain={closeModal}
        footer={<EditModalButton onClick={() => selectStep(0)} text='Done' size='secondary' />}
        title={stepTitle}>
        <EditTextArea
          onChange={onContentUpdate}
          placeholder={editTextAreaPlaceholder}
          defaultValue={textareaDefaultValue}
        />
      </EditModalStep>
    </>
  );
};
