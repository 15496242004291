import { insightsContent } from './insights-content.reducer';
import { insightsPerformance } from './insights-performance.reducer';
import { Config } from '../../../utils/config';
import { ACTIONS } from '../actions/content/insights-content.actions';
import { produce } from 'immer';
import { insightsCommerce } from './insights-commerce.reducer';
import { insightsSocial } from './insight-social.reducer';

export const sevenDaysPastDate = new Date().setDate(new Date().getDate() - 7);
export const dateDropdownPlaceholder = `${Config.monthList[new Date(sevenDaysPastDate).getMonth()]} ${new Date(
  sevenDaysPastDate
).getDate()}, ${new Date(sevenDaysPastDate).getFullYear()} - ${
  Config.monthList[new Date().getMonth()]
} ${new Date().getDate()}, ${new Date().getFullYear()}`;

const initialState = {
  filter: {
    tintId: undefined,
    personalizationId: undefined,
    locationId: undefined,
    date: {
      endDate: new Date(),
      name: dateDropdownPlaceholder,
      startDate: sevenDaysPastDate,
    },
    timeframe: {
      name: 'Last 14 days',
      value: 'last_14_days',
    },
  },
  download: false,
  insightsType: undefined,
};

const insights = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_DOWNLOAD:
      return { ...state, download: action.payload.download, insightsType: action.payload.insightsType };

    case ACTIONS.SET_INSIGHT_FILTER:
      return produce(state, draftState => {
        draftState.filter = { ...draftState.filter, ...action.payload };
      });

    default:
      return state;
  }
};

export const insightsReducers = {
  insights,
  insightsContent,
  insightsPerformance,
  insightsCommerce,
  insightsSocial,
};
