import { filterSettingsList, getAssetUrlWithFilters } from '../utils/filter-helper/filter-helper';
import useFeatureAccessibility from '../../../components/hooks/use-feature-accessibility/use-feature-accessibility';
import { GuardNames } from '../../../guards/guards-list';
import { PublishAssetsRequestStatus } from '../components/asset-marketing-integrations-sidebar/asset-marketing-integrations-sidebar';
import { toggleBulkTagStart } from '../actions/asset-manager.actions';
import {
  downloadAssets,
  getAssetDownloadStatus,
  getAssets,
  updateAssetStatus,
} from '../actions/asset-manager.fat-actions';
import { clearUploadDialog, setUploadDialog } from '../../../actions/upload-dialog/upload-dialog';
import { UPLOAD_DIALOG_TYPE } from '../../../components/uploading-dialog/uploading-dialog';
import { LongPollingService } from '../../../services/long-polling/long-polling.service';
import { toast } from 'react-toastify';
import copyToClipboard from '../../../services/functions/copy-to-clipboard/copy-to-clipboard';
import { openCollectionModal } from '../actions/asset-collections/asset-collections.actions';
import { AssetStatus } from '../components/settings-dropdown/asset-settings-dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { Config } from '../../../utils/config';
import { useEffect, useState } from 'react';
import { getSelectedAssets } from '../reducers/asset-manager.reducer';
const SettingValues = {
  DOWNLOAD: 'Download as Zip',
  ARCHIVE: 'Archive',
  SHARE_LINK: 'Share Link',
  ADD_TAGS: 'Add tags',
  ADD_TO_COLLECTION: 'Add to Collection',
  COPY_EMBED_LINK: 'Copy Embed Link',
};

const addTagsSettingsElement = { name: 'Add Tags', value: SettingValues.ADD_TAGS };
const copyEmbedLinkElement = { name: 'Copy Embed Link', value: SettingValues.COPY_EMBED_LINK };

const settingsList = [
  { name: 'Download as Zip', value: SettingValues.DOWNLOAD },
  { name: 'Archive', value: SettingValues.ARCHIVE },
  { name: 'Share Link', value: SettingValues.SHARE_LINK },
  { name: 'Add to Collection', value: SettingValues.ADD_TO_COLLECTION },
];

export const useCurrentAssetSettingList = () => {
  const [filteredSettingsList, setFilteredSettingsList] = useState(settingsList);
  const [embedLinkElement, setEmbedLinkElement] = useState([]);
  const canActiveAssetsEdit = useFeatureAccessibility(GuardNames.ASSETS_EDIT);
  const assetManager = useSelector(state => state.assetManager);
  const selectedAssets = getSelectedAssets(assetManager);
  const selectedAssetLength = selectedAssets.length;
  const assetsList = useSelector(state => state.assetManager).data;
  const currentAsset = assetsList?.find(asset => asset.isSelected);
  const selectedVariantIds = selectedAssets.map(e => e.id);
  const variantUrl = currentAsset ? currentAsset.attributes.url : undefined;
  const dispatch = useDispatch();

  useEffect(() => {
    setFilteredSettingsList(getFilteredSettingsList());
  }, [selectedAssetLength]);

  useEffect(() => {
    setEmbedLinkElement(getEmbedLinkElement());
  }, [selectedVariantIds]);

  const getEmbedLinkElement = () => {
    const isEmbedLinkElementNeeded = embedLinkElement.find(link => link === copyEmbedLinkElement);
    return selectedVariantIds && selectedVariantIds.length === 1 && isEmbedLinkElementNeeded
      ? [copyEmbedLinkElement]
      : embedLinkElement;
  };

  const getFilteredSettingsList = () => {
    if (!canActiveAssetsEdit) {
      return filterSettingsList(filteredSettingsList, [SettingValues.ARCHIVE, SettingValues.ADD_TO_COLLECTION]);
    } else {
      return settingsList;
    }
  };

  const getShareLink = () => {
    let url = '';
    selectedAssets.map((e, i) => {
      if (selectedAssets.length - 1 === i) {
        url += getAssetUrl(e);
      } else {
        url += `${getAssetUrl(e)}\n`;
      }
    });
    return url;
  };

  const getAssetUrl = asset => {
    return `${location.host}${Config.routePaths.assetManager.asset.creatUrl(asset.id)}`;
  };

  const onSettingChange = selectedItem => {
    let validator = { isCancelled: false };

    const validate = result => {
      if (validator.isCancelled) {
        return true;
      }
      switch (result && result.payload.status) {
        case PublishAssetsRequestStatus.FAILURE:
        case PublishAssetsRequestStatus.SUCCESS:
          return true;

        default:
          return false;
      }
    };

    switch (selectedItem.value) {
      case SettingValues.ADD_TAGS:
        dispatch(toggleBulkTagStart(true));
        break;

      case SettingValues.DOWNLOAD:
        dispatch(downloadAssets({ ids: selectedAssets.map(e => e.id) })).then(res => {
          dispatch(
            setUploadDialog({
              type: UPLOAD_DIALOG_TYPE.DOWNLOAD,
              isPending: true,
              title: 'Downloading assets',
              onCloseClick: () => {
                validator.isCancelled = true;
              },
              refreshFn: () => dispatch(getAssetDownloadStatus(res.payload.data.data.id)),
            })
          );
          LongPollingService.Poll({
            request: () => dispatch(getAssetDownloadStatus(res.payload.data.data.id)),
            validate,
            interval: 2000,
          }).then(data => {
            if (data.payload) {
              switch (data.payload.status) {
                case PublishAssetsRequestStatus.FAILURE:
                  toast.error("We couldn't fetch your assets, please contact support.");
                  dispatch(clearUploadDialog());
                  break;
                case PublishAssetsRequestStatus.SUCCESS:
                  dispatch(
                    setUploadDialog({
                      isFinished: true,
                      files: [
                        {
                          filename: `${data.payload.id}.zip`,
                          url: data.payload.url,
                          refreshFn: () => dispatch(getAssetDownloadStatus(res.payload.id)),
                        },
                      ],
                    })
                  );
                  return true;
              }
            }
          });
        });
        //zipAssets({ assets: selectedAssets, access_token: session.data.access_token, teamId: ApiService.getTeamId() });
        break;
      case SettingValues.ARCHIVE:
        dispatch(
          updateAssetStatus(
            AssetStatus.ARCHIVED,
            selectedAssets.map(item => item.id)
          )
        ).then(() => {
          toast.success('Asset archived successfully');
          dispatch(getAssets({ assetsUrl: getAssetUrlWithFilters() }));
        });
        break;
      case SettingValues.SHARE_LINK:
        copyToClipboard(getShareLink());
        toast.success('Link has been copied to your clipboard');
        break;
      case SettingValues.ADD_TO_COLLECTION:
        dispatch(openCollectionModal());
        break;
      case SettingValues.COPY_EMBED_LINK:
        copyToClipboard(variantUrl);
        toast.success('Link has been copied to your clipboard');
        break;
      default:
        return;
    }
  };

  return {
    currentSettingsList: [
      ...filteredSettingsList,
      ...embedLinkElement,
      ...(selectedAssets?.length > 1 ? [addTagsSettingsElement] : []),
    ],
    onSettingChange,
  };
};
