import { createPrefixedActionName, createRoutine } from '../../../../services/functions/redux-routines/redux-routines';
import { PRODUCT_FEEDS } from '../../consts';

export const ACTIONS = {
  GET_PRODUCT_FEEDS: createRoutine(createPrefixedActionName(PRODUCT_FEEDS, 'GET_PRODUCT_FEEDS')),
  GET_PRODUCT_FEED: createRoutine(createPrefixedActionName(PRODUCT_FEEDS, 'GET_PRODUCT_FEED')),
  DELETE_PRODUCT_FEED: createRoutine(createPrefixedActionName(PRODUCT_FEEDS, 'DELETE_PRODUCT_FEED')),
  POST_PRODUCT_FEED: createRoutine(createPrefixedActionName(PRODUCT_FEEDS, 'POST_PRODUCT_FEED')),
};

export const getProductFeedsStart = () => ({
  type: ACTIONS.GET_PRODUCT_FEEDS.REQUEST,
});

export const getProductFeedsSuccess = data => ({
  type: ACTIONS.GET_PRODUCT_FEEDS.SUCCESS,
  payload: data,
});

export const getProductFeedsError = err => ({
  type: ACTIONS.GET_PRODUCT_FEEDS.FAILURE,
  payload: err,
});

export const getProductFeedStart = () => ({
  type: ACTIONS.GET_PRODUCT_FEED.REQUEST,
});

export const getProductFeedSuccess = data => ({
  type: ACTIONS.GET_PRODUCT_FEED.SUCCESS,
  payload: data,
});

export const getProductFeedError = err => ({
  type: ACTIONS.GET_PRODUCT_FEED.FAILURE,
  payload: err,
});

export const deleteProductFeedStart = () => ({
  type: ACTIONS.DELETE_PRODUCT_FEED.REQUEST,
});

export const deleteProductFeedSuccess = data => ({
  type: ACTIONS.DELETE_PRODUCT_FEED.SUCCESS,
  payload: data,
});

export const deleteProductFeedError = err => ({
  type: ACTIONS.DELETE_PRODUCT_FEED.FAILURE,
  payload: err,
});

export const postProductFeedStart = () => ({
  type: ACTIONS.POST_PRODUCT_FEED.REQUEST,
});

export const postProductFeedSuccess = data => ({
  type: ACTIONS.POST_PRODUCT_FEED.SUCCESS,
  payload: data,
});

export const postProductFeedError = err => ({
  type: ACTIONS.POST_PRODUCT_FEED.FAILURE,
  payload: err,
});
