import React from 'react';

const FormBuilderFormInterfaceHidden = ({ item, values }) => {
  if (!item) return null;

  const isInputHiddenElement = item.class.includes('input-hidden');
  const defaultValues = values.getAll(item.name);
  const isSubmitMultiple = item.name.includes('[]');
  const newInputValues =
    isInputHiddenElement && isSubmitMultiple
      ? defaultValues.filter((val, index) => {
          return index !== 0;
        })
      : null;

  return (
    <>
      <input
        type={item.type}
        name={item.name}
        id={item.id}
        className={item.class}
        value={isInputHiddenElement ? defaultValues[0] : defaultValues}
        data-association-type={item?.['data-association-type'] ? item?.['data-association-type'] : null}
      />
      {isInputHiddenElement &&
        newInputValues &&
        newInputValues.map((value, index) => {
          const cleanHiddenElementId = item.id.split('_', 2).join('_');
          const newInputId = `${cleanHiddenElementId}_${index + 1}`;

          return (
            <input
              id={newInputId}
              key={`${value}_${index + 1}`}
              value={value}
              name={item.name}
              type={item.type}
              className={item.class}
            />
          );
        })}
    </>
  );
};

FormBuilderFormInterfaceHidden.displayName = 'FormBuilderFormInterfaceHidden';
export default React.memo(FormBuilderFormInterfaceHidden);
