import { ACTIONS } from '../../actions/products/products.actions';

import { ACTIONS as UI_ECOMMERCE_ACTIONS } from '../../../ecommerce/actions/ui-ecommerce.actions';

export const initialState = {
  data: [],
  links: undefined,
  infiniteLoadedData: [],
  infiniteLoadedLinks: undefined,
  assignedProducts: [],
  source: '',
  meta: undefined,
  isFetching: false,
  error: undefined,
};

export const products = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_PRODUCTS.REQUEST:
    case ACTIONS.GET_INFINITE_LOADED_PRODUCTS.REQUEST:
    case ACTIONS.GET_FILTERED_PRODUCTS.REQUEST:
    case ACTIONS.DELETE_PRODUCT.REQUEST:
    case ACTIONS.POST_PRODUCT.REQUEST:
    case ACTIONS.PATCH_PRODUCT.REQUEST:
    case ACTIONS.GET_ASSIGNED_PRODUCTS.REQUEST:
      return {
        ...state,
        isFetching: true,
        error: undefined,
      };
    case UI_ECOMMERCE_ACTIONS.ASSIGN_PRODUCT:
      return {
        ...state,
        assignedProducts: [...state.assignedProducts, action.payload.productDetails],
      };
    case ACTIONS.PATCH_PRODUCT.SUCCESS:
      return { ...state, isFetching: false };
    case ACTIONS.GET_ASSIGNED_PRODUCTS.SUCCESS:
      return {
        ...state,
        assignedProducts: action.payload.data,
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.GET_PRODUCTS.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        included: action.payload?.included,
        links: action.payload.links,
        infiniteLoadedData: action.payload.data,
        infiniteLoadedLinks: action.payload.links,
        meta: action.payload.meta,
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.SET_DEFAULT_FILTERED_PRODUCTS:
      return {
        ...state,
        infiniteLoadedData: state.data,
        infiniteLoadedLinks: state.links,
      };

    case ACTIONS.GET_INFINITE_LOADED_PRODUCTS.SUCCESS:
      return {
        ...state,
        infiniteLoadedData: [...state.infiniteLoadedData, ...action.payload.data],
        infiniteLoadedLinks: action.payload.links,
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.GET_FILTERED_PRODUCTS.SUCCESS:
      return {
        ...state,
        infiniteLoadedData: action.payload.data,
        infiniteLoadedLinks: action.payload.links,
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.DELETE_PRODUCT.SUCCESS:
      return {
        ...state,
        isFetching: false,
        error: undefined,
        data: state.data.filter(e => e.id !== action.payload),
      };
    case ACTIONS.POST_PRODUCT.SUCCESS:
      return {
        ...state,
        data: [action.payload, ...state.data],
        isFetching: false,
        error: undefined,
      };
    case ACTIONS.GET_PRODUCTS.FAILURE:
    case ACTIONS.GET_INFINITE_LOADED_PRODUCTS.FAILURE:
    case ACTIONS.GET_FILTERED_PRODUCTS.FAILURE:
    case ACTIONS.DELETE_PRODUCT.FAILURE:
    case ACTIONS.POST_PRODUCT.FAILURE:
    case ACTIONS.PATCH_PRODUCT.FAILURE:
    case ACTIONS.GET_ASSIGNED_PRODUCTS.FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
      };
    default:
      return state;
  }
};

export const getCategories = state => state.meta?.aggregations?.categories;
export const getBrand = state => state.meta?.aggregations?.brand;
export const getAlreadyAddedSkuValues = state => (state ? state.map(e => e.attributes.sku) : []);
