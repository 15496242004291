import { ACTIONS } from '../actions/edit-modal';

const editModal = (
  state = {
    isOpened: false,
    isOrderModal: false,
  },
  action
) => {
  switch (action.type) {
    case ACTIONS.OPEN_EDIT_MODAL:
      return {
        ...state,
        isOpened: true,
        isOrderModal: false,
      };

    case ACTIONS.CLOSE_EDIT_MODAL:
      return {
        ...state,
        isOpened: false,
      };

    case ACTIONS.OPEN_ORDER_MODAL:
      return {
        ...state,
        isOrderModal: true,
        isOpened: false,
      };

    case ACTIONS.OPEN_ORDER_MODAL_FOR_MULTI_COLUMN:
      return {
        ...state,
        isOrderModal: true,
        isOpened: false,
        multiColumnData: action.payload.multiColumnData,
      };

    case ACTIONS.CLOSE_ORDER_MODAL:
      return {
        ...state,
        isOrderModal: false,
        multiColumnData: undefined,
      };

    default:
      return state;
  }
};

export default editModal;
