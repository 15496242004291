import { ACTIONS } from '../../actions/oauth-applications/oauth-applications.actions';

export const initialState = {
  data: [],
  links: undefined,
  isFetching: false,
  error: undefined,
};

export const oAuthApplications = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_OAUTH_APPLICATIONS.REQUEST:
    case ACTIONS.POST_OAUTH_APPLICATION.REQUEST:
    case ACTIONS.DELETE_OAUTH_APPLICATION.REQUEST:
    case ACTIONS.PATCH_OAUTH_APPLICATION.REQUEST:
    case ACTIONS.GET_OAUTH_APPLICATION_SECRET_ID.REQUEST:
      return {
        ...state,
        ...{
          isFetching: true,
          error: undefined,
        },
      };

    case ACTIONS.GET_OAUTH_APPLICATIONS.SUCCESS:
      return {
        ...state,
        data: action.payload.data,
        links: action.payload.links,
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.DELETE_OAUTH_APPLICATION.SUCCESS:
      return {
        ...state,
        data: state.data.filter(e => e.id !== action.payload),
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.PATCH_OAUTH_APPLICATION.SUCCESS:
    case ACTIONS.GET_OAUTH_APPLICATION_SECRET_ID.SUCCESS:
      return {
        ...state,
        data: state.data.map(e =>
          e.id === action.payload.data.id
            ? { ...e, attributes: { ...e.attributes, ...action.payload.data.attributes } }
            : e
        ),
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.POST_OAUTH_APPLICATION.SUCCESS:
      return {
        ...state,
        data: [action.payload.data, ...state.data],
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.GET_OAUTH_APPLICATIONS.FAILURE:
    case ACTIONS.POST_OAUTH_APPLICATION.FAILURE:
    case ACTIONS.DELETE_OAUTH_APPLICATION.FAILURE:
    case ACTIONS.PATCH_OAUTH_APPLICATION.FAILURE:
    case ACTIONS.GET_OAUTH_APPLICATION_SECRET_ID.FAILURE:
      return {
        ...state,
        ...{
          isFetching: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
