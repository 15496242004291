import React from 'react';
import './preloader.sass';

export const PreloaderPositionType = {
  ABSOLUTE: 'absolute',
  RELATIVE: 'relative',
};

export const PreloaderBackdropType = {
  TRANSPARENT: 'transparent',
  SEMI_COVER: 'semi-cover',
  COVER: 'cover',
};

export const PreloaderBackdropColorType = {
  LIGHT: '#fff',
  DARK: '#1b242d',
  NONE: 'none',
  TRANSPARENT: 'transparent',
};

export const PreloaderColorType = {
  DEFAULT: '#1b242d',
  LIGHT: '#e9edf3',
  WHITE: '#fff',
};

export const PreloaderSizeType = {
  BIG: '64px',
  NORMAL: '32px',
  SMALL: '16px',
};

const PreloaderComponent = ({
  backdropType = PreloaderBackdropType.TRANSPARENT,
  backdropColor = PreloaderBackdropColorType.LIGHT,
  positionType = PreloaderPositionType.RELATIVE,
  color = PreloaderColorType.DEFAULT,
  size = PreloaderSizeType.NORMAL,
  style,
}) => {
  return (
    <div
      data-testid='preloaderId'
      className={`preloader-component preloader-component--${positionType}`}
      style={{
        fontSize: size,
        color: color,
        ...style,
      }}>
      <div
        className={`preloader-component__backdrop preloader-component__backdrop--${backdropType}`}
        style={{
          backgroundColor: backdropColor,
        }}
      />
      <span className='icon tint-preloader-icon' />
    </div>
  );
};

export default PreloaderComponent;
