import { ACTIONS as GLOBAL_INSIGHTS_ACTIONS } from '../actions/content/insights-content.actions';
import { ACTIONS } from '../actions/commerce/insights-commerce.actions';
import { produce } from 'immer';
import { eachDayOfInterval, format } from 'date-fns';
const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const initialState = {
  ordersCount: {
    data: undefined,
    isFetching: false,
  },
  ordersSales: {
    data: undefined,
    isFetching: false,
  },
  aovAfterEngagingFromEmbed: {
    data: undefined,
    isFetching: false,
  },
  aovNotEngagingWithTint: {
    data: undefined,
    isFetching: false,
  },
  aosAfterEngagingFromEmbed: {
    data: undefined,
    isFetching: false,
  },
  aosNotEngagingWithTint: {
    data: undefined,
    isFetching: false,
  },

  productionConversionRate: {
    data: undefined,
    isFetching: false,
  },
  totalUniqueUsersClicked: {
    data: undefined,
    isFetching: false,
  },

  assistedOrderCount: {
    data: undefined,
    isFetching: false,
  },
  totalOrderCount: {
    data: undefined,
    isFetching: false,
  },

  totalProduct: {
    data: undefined,
    isFetching: false,
  },

  totalRevenue: {
    data: undefined,
    isFetching: false,
  },

  topEmbedClickedPosts: {
    data: undefined,
    isFetching: false,
  },

  postConversion: {
    data: undefined,
    isFetching: false,
  },
  postClicks: {
    data: undefined,
    isFetching: false,
  },

  pageLoads: {
    data: undefined,
    isFetching: false,
  },
  embedPostProductClicked: {
    data: undefined,
    isFetching: false,
  },
  addToCart: {
    data: undefined,
    isFetching: false,
  },
  conversion: {
    data: undefined,
    isFetching: false,
  },
};

export const insightsCommerce = (state = initialState, action) => {
  switch (action.type) {
    case GLOBAL_INSIGHTS_ACTIONS.CLEAR_ALL_INSIGHTS:
      return initialState;

    case ACTIONS.GET_PAGE_LOADS.FAILURE:
      return produce(state, draftState => {
        draftState.pageLoads.isFetching = false;
      });

    case ACTIONS.GET_PAGE_LOADS.REQUEST:
      return produce(state, draftState => {
        draftState.pageLoads.isFetching = true;
      });

    case ACTIONS.GET_PAGE_LOADS.SUCCESS:
      return produce(state, draftState => {
        draftState.pageLoads.isFetching = false;
        draftState.pageLoads.data = action.payload;
      });

    case ACTIONS.GET_EMBED_POST_PRODUCT_CLICKED.FAILURE:
      return produce(state, draftState => {
        draftState.embedPostProductClicked.isFetching = false;
      });

    case ACTIONS.GET_EMBED_POST_PRODUCT_CLICKED.REQUEST:
      return produce(state, draftState => {
        draftState.embedPostProductClicked.isFetching = true;
      });

    case ACTIONS.GET_EMBED_POST_PRODUCT_CLICKED.SUCCESS:
      return produce(state, draftState => {
        draftState.embedPostProductClicked.isFetching = false;
        draftState.embedPostProductClicked.data = action.payload;
      });

    case ACTIONS.GET_ADD_TO_CART.FAILURE:
      return produce(state, draftState => {
        draftState.addToCart.isFetching = false;
      });

    case ACTIONS.GET_ADD_TO_CART.REQUEST:
      return produce(state, draftState => {
        draftState.addToCart.isFetching = true;
      });

    case ACTIONS.GET_ADD_TO_CART.SUCCESS:
      return produce(state, draftState => {
        draftState.addToCart.isFetching = false;
        draftState.addToCart.data = action.payload;
      });

    case ACTIONS.GET_CONVERSION.FAILURE:
      return produce(state, draftState => {
        draftState.conversion.isFetching = false;
      });

    case ACTIONS.GET_CONVERSION.REQUEST:
      return produce(state, draftState => {
        draftState.conversion.isFetching = true;
      });

    case ACTIONS.GET_CONVERSION.SUCCESS:
      return produce(state, draftState => {
        draftState.conversion.isFetching = false;
        draftState.conversion.data = action.payload;
      });

    case ACTIONS.GET_POST_CLICKS.FAILURE:
      return produce(state, draftState => {
        draftState.postClicks.isFetching = false;
      });

    case ACTIONS.GET_POST_CLICKS.REQUEST:
      return produce(state, draftState => {
        draftState.postClicks.isFetching = true;
      });

    case ACTIONS.GET_POST_CLICKS.SUCCESS:
      return produce(state, draftState => {
        draftState.postClicks.isFetching = false;
        if (Array.isArray(draftState.postClicks.data)) {
          draftState.postClicks.data.push(action.payload);
        } else {
          draftState.postClicks.data = [action.payload];
        }
      });

    case ACTIONS.GET_POST_CONVERSION.FAILURE:
      return produce(state, draftState => {
        draftState.postConversion.isFetching = false;
      });

    case ACTIONS.GET_POST_CONVERSION.REQUEST:
      return produce(state, draftState => {
        draftState.postConversion.isFetching = true;
      });

    case ACTIONS.GET_POST_CONVERSION.SUCCESS:
      return produce(state, draftState => {
        draftState.postConversion.isFetching = false;
        if (Array.isArray(draftState.postConversion.data)) {
          draftState.postConversion.data.push(action.payload);
        } else {
          draftState.postConversion.data = [action.payload];
        }
      });

    case ACTIONS.GET_TOP_EMBED_CLICKED_POSTS.FAILURE:
      return produce(state, draftState => {
        draftState.topEmbedClickedPosts.isFetching = false;
      });

    case ACTIONS.GET_TOP_EMBED_CLICKED_POSTS.REQUEST:
      return produce(state, draftState => {
        draftState.topEmbedClickedPosts.isFetching = true;
      });

    case ACTIONS.GET_TOP_EMBED_CLICKED_POSTS.SUCCESS:
      return produce(state, draftState => {
        draftState.topEmbedClickedPosts.isFetching = false;
        draftState.topEmbedClickedPosts.data = action.payload;
      });

    case ACTIONS.GET_TOTAL_REVENUE.FAILURE:
      return produce(state, draftState => {
        draftState.totalRevenue.isFetching = false;
      });

    case ACTIONS.GET_TOTAL_REVENUE.REQUEST:
      return produce(state, draftState => {
        draftState.totalRevenue.isFetching = true;
      });

    case ACTIONS.GET_TOTAL_REVENUE.SUCCESS:
      return produce(state, draftState => {
        draftState.totalRevenue.isFetching = false;
        draftState.totalRevenue.data = action.payload;
      });

    case ACTIONS.GET_TOTAL_PRODUCT.FAILURE:
      return produce(state, draftState => {
        draftState.totalProduct.isFetching = false;
      });

    case ACTIONS.GET_TOTAL_PRODUCT.REQUEST:
      return produce(state, draftState => {
        draftState.totalProduct.isFetching = true;
      });

    case ACTIONS.GET_TOTAL_PRODUCT.SUCCESS:
      return produce(state, draftState => {
        draftState.totalProduct.isFetching = false;
        draftState.totalProduct.data = action.payload;
      });

    case ACTIONS.GET_ASSISTED_ORDER_COUNT.FAILURE:
      return produce(state, draftState => {
        draftState.assistedOrderCount.isFetching = false;
      });

    case ACTIONS.GET_ASSISTED_ORDER_COUNT.REQUEST:
      return produce(state, draftState => {
        draftState.assistedOrderCount.isFetching = true;
      });

    case ACTIONS.GET_ASSISTED_ORDER_COUNT.SUCCESS:
      return produce(state, draftState => {
        draftState.assistedOrderCount.isFetching = false;
        draftState.assistedOrderCount.data = action.payload;
      });

    case ACTIONS.GET_TOTAL_ORDER_COUNT.FAILURE:
      return produce(state, draftState => {
        draftState.totalOrderCount.isFetching = false;
      });

    case ACTIONS.GET_TOTAL_ORDER_COUNT.REQUEST:
      return produce(state, draftState => {
        draftState.totalOrderCount.isFetching = true;
      });

    case ACTIONS.GET_TOTAL_ORDER_COUNT.SUCCESS:
      return produce(state, draftState => {
        draftState.totalOrderCount.isFetching = false;
        draftState.totalOrderCount.data = action.payload;
      });

    case ACTIONS.GET_TOTAL_UNIQUE_USERS_CLICKED.FAILURE:
      return produce(state, draftState => {
        draftState.totalUniqueUsersClicked.isFetching = false;
      });

    case ACTIONS.GET_TOTAL_UNIQUE_USERS_CLICKED.REQUEST:
      return produce(state, draftState => {
        draftState.totalUniqueUsersClicked.isFetching = true;
      });

    case ACTIONS.GET_TOTAL_UNIQUE_USERS_CLICKED.SUCCESS:
      return produce(state, draftState => {
        draftState.totalUniqueUsersClicked.isFetching = false;
        draftState.totalUniqueUsersClicked.data = action.payload;
      });

    case ACTIONS.GET_PRODUCTION_CONVERSION_RATE.FAILURE:
      return produce(state, draftState => {
        draftState.productionConversionRate.isFetching = false;
      });

    case ACTIONS.GET_PRODUCTION_CONVERSION_RATE.REQUEST:
      return produce(state, draftState => {
        draftState.productionConversionRate.isFetching = true;
      });

    case ACTIONS.GET_PRODUCTION_CONVERSION_RATE.SUCCESS:
      return produce(state, draftState => {
        draftState.productionConversionRate.isFetching = false;
        draftState.productionConversionRate.data = action.payload;
      });

    case ACTIONS.GET_AOS_AFTER_ENGAGING_FROM_EMBED.FAILURE:
      return produce(state, draftState => {
        draftState.aosAfterEngagingFromEmbed.isFetching = false;
      });

    case ACTIONS.GET_AOS_AFTER_ENGAGING_FROM_EMBED.REQUEST:
      return produce(state, draftState => {
        draftState.aosAfterEngagingFromEmbed.isFetching = true;
      });

    case ACTIONS.GET_AOS_AFTER_ENGAGING_FROM_EMBED.SUCCESS:
      return produce(state, draftState => {
        draftState.aosAfterEngagingFromEmbed.isFetching = false;
        draftState.aosAfterEngagingFromEmbed.data = action.payload;
      });

    case ACTIONS.GET_AOV_AFTER_ENGAGING_FROM_EMBED.FAILURE:
      return produce(state, draftState => {
        draftState.aovAfterEngagingFromEmbed.isFetching = false;
      });

    case ACTIONS.GET_AOV_AFTER_ENGAGING_FROM_EMBED.REQUEST:
      return produce(state, draftState => {
        draftState.aovAfterEngagingFromEmbed.isFetching = true;
      });

    case ACTIONS.GET_AOV_AFTER_ENGAGING_FROM_EMBED.SUCCESS:
      return produce(state, draftState => {
        draftState.aovAfterEngagingFromEmbed.isFetching = false;
        draftState.aovAfterEngagingFromEmbed.data = action.payload;
      });

    case ACTIONS.GET_AOV_NOT_ENGAGING_WITH_TINT.FAILURE:
      return produce(state, draftState => {
        draftState.aovNotEngagingWithTint.isFetching = false;
      });

    case ACTIONS.GET_AOV_NOT_ENGAGING_WITH_TINT.REQUEST:
      return produce(state, draftState => {
        draftState.aovNotEngagingWithTint.isFetching = true;
      });

    case ACTIONS.GET_AOV_NOT_ENGAGING_WITH_TINT.SUCCESS:
      return produce(state, draftState => {
        draftState.aovNotEngagingWithTint.isFetching = false;
        draftState.aovNotEngagingWithTint.data = action.payload;
      });

    case ACTIONS.GET_AOS_NOT_ENGAGING_WITH_TINT.FAILURE:
      return produce(state, draftState => {
        draftState.aosNotEngagingWithTint.isFetching = false;
      });

    case ACTIONS.GET_AOS_NOT_ENGAGING_WITH_TINT.REQUEST:
      return produce(state, draftState => {
        draftState.aosNotEngagingWithTint.isFetching = true;
      });

    case ACTIONS.GET_AOS_NOT_ENGAGING_WITH_TINT.SUCCESS:
      return produce(state, draftState => {
        draftState.aosNotEngagingWithTint.isFetching = false;
        draftState.aosNotEngagingWithTint.data = action.payload;
      });

    case ACTIONS.GET_ORDER_SALES.FAILURE:
      return produce(state, draftState => {
        draftState.ordersSales.isFetching = false;
      });

    case ACTIONS.GET_ORDER_SALES.REQUEST:
      return produce(state, draftState => {
        draftState.ordersSales.isFetching = true;
      });

    case ACTIONS.GET_ORDER_SALES.SUCCESS:
      return produce(state, draftState => {
        draftState.ordersSales.isFetching = false;
        draftState.ordersSales.data = action.payload;
      });

    case ACTIONS.GET_ORDER_COUNT.FAILURE:
      return produce(state, draftState => {
        draftState.ordersCount.isFetching = false;
      });

    case ACTIONS.GET_ORDER_COUNT.REQUEST:
      return produce(state, draftState => {
        draftState.ordersCount.isFetching = true;
      });

    case ACTIONS.GET_ORDER_COUNT.SUCCESS:
      return produce(state, draftState => {
        draftState.ordersCount.isFetching = false;
        draftState.ordersCount.data = action.payload;
      });

    default:
      return state;
  }
};

export const getConversionFunnelData = state => {
  //return [
  //  {
  //    amount: 3877,
  //    amt: 3877,
  //  },
  //  {
  //    amount: 2782,
  //    amt: 2782,
  //  },
  //  {
  //    amount: 1676,
  //    amt: 1676,
  //  },
  //  {
  //    amount: 656,
  //    amt: 656,
  //  },
  //];
  //

  if (
    !state ||
    !state.pageLoads.data ||
    !state.embedPostProductClicked.data ||
    !state.addToCart.data ||
    !state.conversion.data
  ) {
    return [];
  }
  const pageLoads = state.pageLoads.data.data[0]?.attributes?.count || 0;
  const productClicked = state.embedPostProductClicked.data.data[0]?.attributes?.count || 0;
  const addToCart = state.addToCart.data.data[0]?.attributes?.aggregate || 0;
  const conversion = state.conversion.data.data[0]?.attributes?.aggregate || 0;
  return {
    data: [{ amount: pageLoads }, { amount: productClicked }, { amount: addToCart }, { amount: conversion }],
    pageLoads,
    productClicked,
    addToCart,
    conversion,
  };
};

export const getConversionDetailsData = insightsState => {
  if (!insightsState || !insightsState.ordersCount.data || !insightsState.ordersSales.data) {
    return { data: [] };
  }

  let assistedOrders = 0;
  insightsState.ordersCount.data.data.map(e => {
    assistedOrders += e.attributes.aggregate;
  });

  let aovAfterEngagingFromEmbed = 0;
  insightsState.aovAfterEngagingFromEmbed?.data?.data.map(e => {
    aovAfterEngagingFromEmbed += e.attributes.aggregate;
  });

  let aovNotEngagingWithTint = 0;
  insightsState.aovNotEngagingWithTint?.data?.data.map(e => {
    aovNotEngagingWithTint += e.attributes.aggregate;
  });

  let aosAfterEngagingFromEmbed = 0;
  insightsState.aosAfterEngagingFromEmbed?.data?.data.map(e => {
    aosAfterEngagingFromEmbed += e.attributes.aggregate;
  });

  let aosNotEngagingWithTint = 0;
  insightsState.aosNotEngagingWithTint?.data?.data.map(e => {
    aosNotEngagingWithTint += e.attributes.aggregate;
  });

  const orderSales = insightsState.ordersSales?.data?.data.reduce((acc, e) => acc + e.attributes.aggregate, 0);

  return {
    assistedOrders: assistedOrders || 0,
    orderSales: orderSales || 0,
    avgOrderValueUplift: aovAfterEngagingFromEmbed / aovNotEngagingWithTint || 0,
    avgOrderSizeValueUplift: aosAfterEngagingFromEmbed / aosNotEngagingWithTint || 0,
    avgOrderSize: aosAfterEngagingFromEmbed,
    avgOrderValue: aovAfterEngagingFromEmbed,
  };
};

export const getProductConversionRateData = (insightsState, state) => {
  if (!insightsState || !insightsState.productionConversionRate.data || !insightsState.totalUniqueUsersClicked.data) {
    return { data: [] };
  }
  const { data } = insightsState.productionConversionRate?.data;
  const _helperArr = data.map((e, i) => {
    const totalUniqueUsers = insightsState.totalUniqueUsersClicked?.data?.data[i]?.attributes?.aggregate;
    const time = new Date(e.attributes.dimensions.time);
    return {
      time: time,
      name: `${monthNames[time.getMonth()]?.substring(0, 3)} ${time.getDate()}`,
      count: totalUniqueUsers ? Number((e.attributes.count / totalUniqueUsers) * 100).toFixed(2) : 0,
    };
  });

  const dayArray = fillArrayWithDates(state.filter.date.startDate, state.filter.date.endDate);

  return { data: getResultArray(dayArray, _helperArr) };
};

export const getAssistedOrderShareData = (insightsState, state) => {
  if (!insightsState || !insightsState.totalOrderCount.data || !insightsState.assistedOrderCount.data) {
    return { data: [] };
  }
  const { data } = insightsState.totalOrderCount?.data;
  const _helperArr = data.map((e, i) => {
    const assistedOrderCount = insightsState.assistedOrderCount?.data?.data[i]?.attributes?.aggregate;
    const time = new Date(e.attributes.dimensions.time);
    return {
      time: time,
      name: `${monthNames[time.getMonth()]?.substring(0, 3)} ${time.getDate()}`,
      count: assistedOrderCount ? Number((e.attributes.aggregate / assistedOrderCount) * 100).toFixed(2) : 0,
    };
  });

  const dayArray = fillArrayWithDates(state.filter.date.startDate, state.filter.date.endDate);

  return { data: getResultArray(dayArray, _helperArr) };
};

export const getTotalProductData = (insightsState, state) => {
  if (!insightsState || !insightsState.totalProduct.data) {
    return { data: [] };
  }
  const { data } = insightsState.totalProduct?.data;
  const _helperArr = data.map(e => {
    const time = new Date(e.attributes.dimensions.time);
    return {
      time: time,
      name: `${monthNames[time.getMonth()]?.substring(0, 3)} ${time.getDate()}`,
      count: e.attributes.aggregate,
    };
  });

  const dayArray = fillArrayWithDates(state.filter.date.startDate, state.filter.date.endDate);

  return { data: getResultArray(dayArray, _helperArr) };
};

export const getTotalRevenueData = (insightsState, state) => {
  if (!insightsState || !insightsState.totalRevenue.data) {
    return { data: [] };
  }
  const { data } = insightsState.totalRevenue?.data;
  const _helperArr = data.map(e => {
    const time = new Date(e.attributes.dimensions.time);
    return {
      time: time,
      name: `${monthNames[time.getMonth()]?.substring(0, 3)} ${time.getDate()}`,
      count: e.attributes.aggregate,
    };
  });

  const dayArray = fillArrayWithDates(state.filter.date.startDate, state.filter.date.endDate);

  return { data: getResultArray(dayArray, _helperArr) };
};

const getResultArray = (dayArray, valueArray) => {
  const _arr = dayArray.map(e => {
    const time = new Date(e);
    const element = valueArray.find(f => {
      return format(f.time, 'yyyy-MM-dd') === e;
    });
    return {
      time: time,
      name: `${monthNames[time.getMonth()]?.substring(0, 3)} ${time.getDate()}`,
      count: element ? Number(element.count) : 0,
    };
  });
  return _arr;
};
const fillArrayWithDates = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  // Get array of dates between start and end dates, inclusive
  const dayArray = eachDayOfInterval({ start, end }).map(date => format(date, 'yyyy-MM-dd'));

  return dayArray;
};

export const getTopConvertingPostsData = (topEmbedClickedPosts, postConversions) => {
  if (!topEmbedClickedPosts.data) {
    return [];
  }

  const _data = topEmbedClickedPosts.data.map(e => {
    const post = topEmbedClickedPosts.included.find(f => f.id === e.relationships.post.data.id);
    const postConversion = postConversions.find(f => f.postId === post.id);
    return {
      ...post.attributes,
      clicks: e.attributes.count,
      conversions: postConversion ? postConversion.data.data[0].attributes.aggregate : 0,
    };
  });
  return _data;
};
