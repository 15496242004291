import { createPrefixedActionName } from '../../services/functions/redux-routines/redux-routines';
import { assetDisplayType } from '../../feature/asset-manager/model/asset-display-type';
import { clone } from '../../services/functions/clone/clone';

export const defaultConfig = {
  SELECT: createPrefixedActionName('TINT', 'SELECT_ELEMENT'),
  UNSELECT: createPrefixedActionName('TINT', 'UNSELECT_ELEMENT'),
  SELECT_ALL: createPrefixedActionName('TINT', 'SELECT_ALL_ELEMENTS'),
  UNSELECT_ALL: createPrefixedActionName('TINT', 'UNSELECT_ALL_ELEMENTS'),
  SET_DISPLAY_TYPE: createPrefixedActionName('TINT', 'SET_ELEMENT_DISPLAY_TYPE'),
};

export const initialState = {
  data: [],
  meta: undefined,
  links: undefined,
  isFetching: false,
  error: undefined,
  isCreateAssetModalOpened: false,
  displayType: assetDisplayType.GRID_SMALL,
};

export function selectable(reducer, config, initialState) {
  const _config = { ...defaultConfig, ...config };

  return (state = initialState, action) => {
    switch (action.type) {
      case _config.SELECT:
        return {
          ...state,
          ...{
            data: state.data.map(e => {
              const _e = clone(e);
              _e.id === action.payload ? (_e.isSelected = true) : null;
              return _e;
            }),
            selectedAssetId: action.payload,
          },
        };

      case _config.UNSELECT:
        return {
          ...state,
          ...{
            data: state.data.map(e => {
              const _e = clone(e);
              _e.id === action.payload ? (_e.isSelected = false) : null;
              return _e;
            }),
            selectedAssetId: action.payload,
          },
        };

      case _config.SELECT_ALL:
        return {
          ...state,
          ...{ data: state.data.map(e => ({ ...e, isSelected: true })) },
        };

      case _config.UNSELECT_ALL:
        return {
          ...state,
          ...{ data: state.data.map(e => ({ ...e, isSelected: false })) },
        };

      case _config.SET_DISPLAY_TYPE:
        return {
          ...state,
          ...{ displayType: action.payload },
        };

      default:
        return reducer(state, action);
    }
  };
}
