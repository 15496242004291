import React, { useState } from 'react';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { EditInput, EditInputDropdown, EditTextArea } from '../../../';
import './edit-form-content.sass';
import { formUpload } from '../../../../../../../model/form-upload.model';
import { EditInputContainer } from '../../../';
import { createTranslationInputArrayKey } from '../../../../../containers/experience/translation-creator';
import { FIELD_TYPE, transformToSendAble } from '../form-helper';
import { EditFormHelper } from './edit-form-helper/edit-form-helper';
import { clone } from '../../../../../../../services/functions/clone/clone';
import { useFormik } from 'formik';
import EditCheckbox from '../../../edit-checkbox/edit-checkbox';
import { EditTime } from '../../timer/edit/edit-time/edit-time';
import DropdownButton, { ButtonType } from '../../../../../../../components/interface/dropdown/button/dropdown-button';
import { DropdownHeader } from '../../../../../../../components/interface/dropdown/button/header/dropdown-header';
import DropdownSortList from '../../../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list';
import DragAndDropContainer from '../../../../../containers/experience/components/drag-and-drop/drag-and-drop-container';
import FormFieldItem from './form-field-item/form-field-item';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import DropdownClickList from '../../../../../../../components/interface/dropdown/lists/click-list/dropdown-click-list';
import useFeatureAccessibility from '../../../../../../../components/hooks/use-feature-accessibility/use-feature-accessibility';
import { GuardNames } from '../../../../../../../guards/guards-list';

const uploadSettings = [
  {
    name: 'Upload Multiple Files',
    value: formUpload.MANY,
  },
  {
    name: 'Upload Single File',
    value: formUpload.SINGLE,
  },
];
const advancedSettings = [
  {
    name: 'OFF',
    value: 'off',
  },
  {
    name: 'ON',
    value: 'on',
  },
];
export const EditFormContent = ({
  data,
  getTranslation,
  onNewTranslationUpdate,
  onTranslationUpdate,
  experienceType,
  dataUpdate,
  selectStep,
  editSuccessMessage,
  saveData,
  setApplyButtonDisabled,
  openEditModal,
  dismissEditModal,
}) => {
  const [currentFields, setCurrentFields] = useState(data.fields);
  const removeField = ({ index }) => {
    let _tmp = [...data['fields']];
    _tmp.splice(index, 1);
    setCurrentFields(_tmp);
    dataUpdate({ ['fields']: _tmp });
  };

  const keyInputUpdate = (value, i) => {
    const tmpInputFields = currentFields.map((field, index) => {
      return index === i
        ? {
            ...field,
            key: value,
          }
        : {
            ...field,
          };
    });
    setCurrentFields(tmpInputFields);
    dataUpdate({ fields: tmpInputFields });
  };

  const renderUploadSettingsDropdown = () => {
    return data.isPhotoUploadEnabled ? (
      <div className='margin-bottom-40'>
        <EditInputContainer title='upload settings'>
          <EditInputDropdown
            label='upload limit'
            onChange={e => dataUpdate({ uploadPhotoType: e.target.value })}
            options={uploadSettings}
            defaultValue={data.uploadPhotoType}
          />
          <EditInput
            label='upload button text'
            placeholder='Enter value'
            defaultValue={getTranslation(data.uploadButtonText)}
            onChange={event => {
              onTranslationUpdate(data.uploadButtonText, event.target.value);
            }}
          />
          <EditInput
            label='upload error text'
            placeholder='Enter value'
            defaultValue={getTranslation(data.uploadErrorText)}
            onChange={event => {
              onTranslationUpdate(data.uploadErrorText, event.target.value);
            }}
          />
          <EditInput
            label='Required field text'
            placeholder='*Required'
            defaultValue={getTranslation(data.requiredFieldText)}
            onChange={event => {
              onTranslationUpdate(data.requiredFieldText, event.target.value);
            }}
          />
          {data.isPhotoUploadEnabled ? (
            <EditCheckbox
              value={!!data.isPhotoUploadRequired}
              onChange={e => dataUpdate({ isPhotoUploadRequired: e.target.checked })}
              name='uploadingRequired'
              id='uploadingRequired'
              label='Uploading required'
            />
          ) : null}
        </EditInputContainer>
      </div>
    ) : null;
  };

  const addElement = ({ type }) => {
    let _index = 0;
    let lastIndex;
    if (data.fields.length > 0) {
      lastIndex = data.fields
        .slice()
        .sort((a, b) => Number(a.key.split('_').pop()) - Number(b.key.split('_').pop()))
        .pop();

      const keyNumber = Number(lastIndex.key.split('_').pop());
      if (!keyNumber) {
        _index = data.fields.length;
      } else {
        _index = keyNumber;
      }
    }

    _index += 1;

    let translationValue = '';
    switch (type) {
      case FIELD_TYPE.INPUT:
        translationValue = `Sample Single-Line #${_index}`;
        break;
      case FIELD_TYPE.TEXTAREA:
        translationValue = `Sample Multi-Line #${_index}`;
        break;
      case FIELD_TYPE.DROPDOWN:
        translationValue = `Sample Dropdown #${_index}`;
        break;
      case FIELD_TYPE.CHECKBOX:
        translationValue = `Sample Checkbox #${_index}`;
        break;
      case FIELD_TYPE.HIDDEN_INPUT:
        translationValue = `Static Value Passed To Your Form Submission #${_index}`;
        break;
    }
    const translateKey = createTranslationInputArrayKey(experienceType, 'form', 'placeholder', data.fields.length);
    const tmpFields = [...data['fields']];
    const newField = {
      type,
      placeholder: translateKey,
      key: transformToSendAble(translationValue),
      isRequired: true,
    };

    if (type === FIELD_TYPE.DROPDOWN || type === FIELD_TYPE.CHECKBOX) {
      newField.options = [];
    }

    tmpFields.push(newField);
    dataUpdate({ ['fields']: tmpFields });
    setCurrentFields(tmpFields);
    onNewTranslationUpdate(translateKey, translationValue);
  };

  const onArrayPropertyChange = ({ property, value, index }) => {
    const _array = clone(data.fields);
    _array[index][property] = value;
    setCurrentFields(_array);
    dataUpdate({ ['fields']: _array });
  };

  const isExperiencesFieldsHiddenAvailable = useFeatureAccessibility(GuardNames.EXPERIENCES_FIELDS_HIDDEN);

  const renderButtonAdded = () => {
    const dropdownList = [
      { name: 'Input', value: FIELD_TYPE.INPUT },
      { name: 'Textarea', value: FIELD_TYPE.TEXTAREA },
      { name: 'Dropdown', value: FIELD_TYPE.DROPDOWN },
      { name: 'Checkbox', value: FIELD_TYPE.CHECKBOX },
    ];

    if (isExperiencesFieldsHiddenAvailable) {
      dropdownList.push({ name: 'Hidden input', value: FIELD_TYPE.HIDDEN_INPUT });
    }

    return (
      <DropdownButton
        dropdownHeader={props => <DropdownHeader {...props} />}
        dropdownList={props => <DropdownClickList {...props} />}
        list={dropdownList}
        placeholder='Add Element'
        iconRight='fa fa-caret-down'
        buttonType={ButtonType.BUTTON_GRAY}
        styles={{ width: '100%', maxWidth: '100%' }}
        onChangeValue={selectedItem => addElement({ type: selectedItem.value })}
      />
    );
  };

  const renderAdvancedSettings = () => {
    return (
      <div className='tint-edit-input-container'>
        <span className='tint-edit-input-container__label'>display advanced settings</span>
        <DropdownButton
          dropdownHeader={props => <DropdownHeader {...props} />}
          dropdownList={props => <DropdownSortList {...props} />}
          list={advancedSettings}
          iconRight='fa fa-caret-down'
          placeholder={data.hasAdvancedSettings ? 'ON' : 'OFF'}
          styles={{ height: '28px' }}
          buttonType={ButtonType.BUTTON_GRAY_BORDER}
          onChangeValue={selectedItem => {
            const _value = selectedItem.value;
            dataUpdate({
              hasAdvancedSettings: _value === 'on',
            });
          }}
        />
      </div>
    );
  };

  const renderCheckboxOptions = ({
    urlLabel,
    urlProperty,
    textProperty,
    agreeProperty,
    uploadText,
    uploadStep,
    linkTitle,
  }) => {
    return (
      <>
        <EditInput
          label={urlLabel}
          placeholder='Enter URL'
          defaultValue={getTranslation(data[urlProperty])}
          onChange={event => {
            onTranslationUpdate(data[urlProperty], event.target.value);
          }}
        />
        {getTranslation(data[urlProperty]) || getTranslation(data[textProperty]) ? (
          <>
            <EditInput
              placeholder='I agree to'
              defaultValue={getTranslation(data[agreeProperty])}
              onChange={event => {
                onTranslationUpdate(data[agreeProperty], event.target.value);
              }}
            />
            <EditInput
              placeholder='Link Name'
              defaultValue={getTranslation(data[linkTitle])}
              onChange={event => {
                onTranslationUpdate(data[linkTitle], event.target.value);
              }}
            />
          </>
        ) : null}
        <div className='tint-edit-form-content__line-adder' onClick={() => selectStep(uploadStep)}>
          <div className='tint-edit-form-content__line-adder-element'>
            <span>{uploadText}</span>
          </div>
        </div>
      </>
    );
  };

  const checkBoxValue = (e, property) => {
    if (e[property] === undefined) {
      return true;
    }
    return e[property];
  };

  const onChangeOrder = fields => {
    const mapFieldList = fields.map(field => ({
      ...field,
      elementId: undefined,
    }));
    if (JSON.stringify(mapFieldList) !== JSON.stringify(data.fields)) {
      setCurrentFields(mapFieldList);
      setFieldValue('fields', mapFieldList);
      dataUpdate({ fields: mapFieldList });
    }
  };

  const renderDnD = (list, handleChange, errors) => {
    return (
      <DndProvider backend={HTML5Backend}>
        <DragAndDropContainer
          sectionList={currentFields}
          onChangeOrder={onChangeOrder}
          dndItemComponent={props => (
            <FormFieldItem
              {...props}
              ref={props.componentRef}
              openEditModal={openEditModal}
              dismissEditModal={dismissEditModal}
              data={data}
              getTranslation={getTranslation}
              onTranslationUpdate={onTranslationUpdate}
              handleChange={handleChange}
              errors={errors}
              keyInputUpdate={keyInputUpdate}
              removeField={removeField}
              checkBoxValue={checkBoxValue}
              onArrayPropertyChange={onArrayPropertyChange}
            />
          )}
        />
      </DndProvider>
    );
  };

  const isFormValid = ({ errors }) => {
    const a = errors.fields.find(e => e.key !== undefined || e.placeholder !== undefined);
    return a === undefined;
  };

  const validateForm = values => {
    const allValues = [];
    values.fields.map(e => {
      allValues.push(e.placeholder);
      allValues.push(e.key);
    });
    let errors = {};
    errors = {
      ...errors,
      ...EditFormHelper.validateInputs({
        values,
        getTranslation,
      }),
    };
    EditFormHelper.validateDuplicates({
      values,
      getTranslation,
      allValues,
      errors,
      properties: ['fields'],
      keys: ['placeholder', 'key'],
    });
    setApplyButtonDisabled(!isFormValid({ errors }));
    return errors;
  };

  const { errors, values, handleChange, setFieldValue } = useFormik({
    validateOnMount: true,
    enableReinitialize: true,
    initialValues: { fields: data.fields },
    onSubmit: () => {},
    validate: validateForm,
  });

  return (
    <EditModalBody title='Edit the fields below to customize your Form.'>
      <form
        className='tint-edit-form-content'
        noValidate
        onSubmit={e => {
          e.preventDefault();
          saveData();
        }}>
        {renderAdvancedSettings()}
        {renderDnD(values.fields, handleChange, errors)}
        {renderButtonAdded()}
        <div className='margin-bottom-40'>
          <EditInput
            label='button text'
            placeholder='Enter value'
            defaultValue={getTranslation(data.buttonText)}
            onChange={event => {
              onTranslationUpdate(data.buttonText, event.target.value);
            }}
          />
        </div>
        {renderUploadSettingsDropdown()}
        <button style={{ display: 'none' }} type='submit'>
          Submit
        </button>
        <EditInputContainer title='LEGAL LINKS'>
          {renderCheckboxOptions({
            uploadStep: 2,
            urlLabel: 'TERMS & conditions',
            agreeProperty: 'termsConditionsAgree',
            textProperty: 'termsConditions',
            urlProperty: 'termsConditionsUrl',
            uploadText: 'Upload Terms',
            linkTitle: 'termsConditionsLinkTitle',
          })}
          {renderCheckboxOptions({
            uploadStep: 4,
            urlLabel: 'Privacy Policy',
            agreeProperty: 'privacyPolicyAgree',
            textProperty: 'privacyPolicy',
            urlProperty: 'privacyPolicyUrl',
            uploadText: 'Upload Privacy Policy',
            linkTitle: 'privacyPolicyLinkTitle',
          })}
          {renderCheckboxOptions({
            uploadStep: 1,
            urlLabel: 'contest rules',
            agreeProperty: 'contestRulesAgree',
            textProperty: 'contestRules',
            linkTitle: 'contestRulesLinkTitle',
            urlProperty: 'contestRulesUrl',
            uploadText: 'Upload Contest Rules',
          })}
        </EditInputContainer>
        <EditInput
          label='OPT IN MESSAGE'
          placeholder='Yes, I want to receive emails from...'
          defaultValue={getTranslation(data.gdprCheckboxLabelText)}
          onChange={event => {
            onTranslationUpdate(data.gdprCheckboxLabelText, event.target.value);
          }}
        />
        <EditTextArea
          label='OPT IN DESCRIPTION'
          placeholder='Note: Your data will not be used for reasons other than helping to choose and announce a winner.'
          defaultValue={getTranslation(data.gdprCheckboxDescriptionText)}
          onChange={event => {
            onTranslationUpdate(data.gdprCheckboxDescriptionText, event.target.value);
          }}
        />
        <div
          className='tint-edit-form-content__line-adder'
          onClick={() => {
            editSuccessMessage();
            selectStep(3);
          }}>
          <div className='tint-edit-form-content__line-adder-element'>
            <span>Edit Success Message</span>
          </div>
        </div>
      </form>
      <EditCheckbox
        value={!!data.isEndTimeEnabled}
        onChange={e => {
          const updateObject = { isEndTimeEnabled: e.target.checked };
          if (data.endDate === undefined) {
            updateObject.endDate = new Date();
          }
          dataUpdate(updateObject);
        }}
        label='SUBMISSION END TIME'
      />
      {data.isEndTimeEnabled ? (
        <EditTime
          onDateChange={date => {
            dataUpdate({
              endDate: new Date(date),
            });
          }}
          data={data}
          minDate={() => {
            const currentDate = new Date();
            return new Date(currentDate.setDate(currentDate.getDate() + 1));
          }}
        />
      ) : null}
    </EditModalBody>
  );
};
