import { SidebarIcons, SidebarItemTypes } from '../utils/meta/consts';
import { linkedinDisabledButton, SocialFeedsFooterLabels } from './helpers';
import { validationSchemaLinkedin } from '../utils/validation-schema/linkedin-schema';

const dropdownCompanyDefaultValue = {
  name: 'Select company...',
  value: 'select-company',
};

const clearSubAccountsDropdown = formik => formik.setFieldValue('company', dropdownCompanyDefaultValue);

export const linkedin = [
  {
    header: 'Add LinkedIn',
    description: 'Choose a Social Feed type below:',
    typeList: [
      {
        id: 'company',
        title: 'Brand Content',
        description: "This social feed type allows you to access your authorized LinkedIn company's posts.",
      },
      {
        id: 'tagged',
        title: 'Tagged',
        description:
          'This social feed type allows you to access LinkedIn Posts in which your company has been photo tagged.',
      },
      {
        id: 'mentioned',
        title: 'Mentions',
        description:
          'This social feed type allows you to access LinkedIn Posts in which your company has been mentioned.',
      },
    ],
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.firstStepNext,
    },
    zendeskUrl: undefined,
    isAlert: false,
    formSchema: undefined,
  },
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext,
    },
    accountSourceType: 'linkedin',
    header: undefined,
    description: undefined,
    zendeskUrl: undefined,
    formSchema: {
      company: {
        validationSchema: validationSchemaLinkedin(),
        submitMapper: values => {
          return {
            account_id: values.account.value,
            external_id: values.account.externalId,
            search_term: values.company.value,
          };
        },
        inputs: [
          {
            id: 'account',
            backendId: 'account_id',
            label: 'SELECT ACCOUNT',
            placeholder: 'Select account...',
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
            clearSubAccountsDropdown,
          },
          {
            id: 'company',
            backendId: 'search_term',
            label: 'SELECT COMPANY',
            placeholder: 'Select company...',
            icon: SidebarIcons.COMPANY,
            type: SidebarItemTypes.SELECT,
            disabled: linkedinDisabledButton,
          },
        ],
      },
      tagged: {
        validationSchema: validationSchemaLinkedin(),
        submitMapper: values => {
          return {
            account_id: values.account.value,
            external_id: values.account.externalId,
            search_term: values.company.value,
          };
        },
        inputs: [
          {
            id: 'account',
            backendId: 'account_id',
            label: 'SELECT ACCOUNT',
            placeholder: 'Select account...',
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
            clearSubAccountsDropdown,
          },
          {
            id: 'company',
            backendId: 'search_term',
            label: 'SELECT COMPANY',
            placeholder: 'Select company...',
            icon: SidebarIcons.COMPANY,
            type: SidebarItemTypes.SELECT,
            disabled: linkedinDisabledButton,
          },
        ],
      },
      mentioned: {
        validationSchema: validationSchemaLinkedin(),
        submitMapper: values => {
          return {
            account_id: values.account.value,
            external_id: values.account.externalId,
            search_term: values.company.value,
          };
        },
        inputs: [
          {
            id: 'account',
            backendId: 'account_id',
            label: 'SELECT ACCOUNT',
            placeholder: 'Select account...',
            icon: SidebarIcons.USER,
            type: SidebarItemTypes.ACCOUNT,
            lineBreak: true,
            clearSubAccountsDropdown,
          },
          {
            id: 'company',
            backendId: 'search_term',
            label: 'SELECT COMPANY',
            placeholder: 'Select company...',
            icon: SidebarIcons.COMPANY,
            type: SidebarItemTypes.SELECT,
            disabled: linkedinDisabledButton,
          },
        ],
      },
    },
  },
];
