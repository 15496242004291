import { SocialFeedsFooterLabels } from './helpers';
import * as Yup from 'yup';

export const email = [
  {
    isAlert: true,
    footer: {
      leftButtonLabel: SocialFeedsFooterLabels.firstStepPrev,
      rightButtonLabel: SocialFeedsFooterLabels.finalStepNext,
    },
    accountSourceType: 'email',
    header: 'Add Email',
    selectedOption: { id: 'email', title: 'Add Email' },
    description:
      'Click "Add" to get an email assigned to your TINT. Your audience can then send emails to this address to submit posts.',
    formSchema: {
      email: {
        submitMapper: () => ({
          source: 'email',
        }),
        validationSchema: Yup.object({}),
        inputs: [],
      },
    },
  },
];
