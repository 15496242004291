import { ACTIONS } from '../../actions/social-connections/social-connections.actions';
import produce from 'immer';

const INCLUDE_TYPES = {
  TINT: 'tint',
  SOCIAL_FEED: 'social_feed',
};

const initialDropdownSubAccountsState = {
  subAccounts: [],
  id: undefined,
};

export const initialState = {
  accounts: {
    data: [],
    links: {},
  },
  dropdown: initialDropdownSubAccountsState,
  subAccounts: [],
  isSubAccountsPending: false,
  subAccountsData: {},
  filteredAccounts: [],
  socialsToTransfer: [],
  error: undefined,
  isFetching: false,
  isUpdatingSocialAccount: false,
  isFilteringSocialAccounts: false,
};

export const socialConnections = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_SUB_ACCOUNTS.SUCCESS:
      return {
        ...state,
        subAccountsData: action.payload,
      };
    case ACTIONS.PATCH_SOCIAL_FEED.REQUEST:
      return {
        ...state,
        error: undefined,
        isUpdatingSocialAccount: true,
      };
    case ACTIONS.FILTER_SOCIAL_ACCOUNTS.REQUEST:
      return {
        ...state,
        error: undefined,
        isFilteringSocialAccounts: true,
      };

    case ACTIONS.GET_SOCIAL_CONNECTIONS.REQUEST:
    case ACTIONS.GET_SOCIAL_CONNECTION.REQUEST:
    case ACTIONS.DELETE_SOCIAL_CONNECTION.REQUEST:
    case ACTIONS.POST_SOCIAL_CONNECTION.REQUEST:
      return {
        ...state,
        error: undefined,
        isFetching: true,
      };

    case ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS.REQUEST:
    case ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS_DROPDOWN.REQUEST:
      return {
        ...state,
        error: undefined,
        isSubAccountsPending: true,
      };

    case ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS.FAILURE:
    case ACTIONS.GET_SOCIAL_CONNECTIONS.FAILURE:
    case ACTIONS.GET_SOCIAL_CONNECTION.FAILURE:
    case ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS_DROPDOWN.FAILURE:
    case ACTIONS.POST_SOCIAL_CONNECTION.FAILURE:
    case ACTIONS.PATCH_SOCIAL_FEED.FAILURE:
    case ACTIONS.FILTER_SOCIAL_ACCOUNTS.FAILURE:
    case ACTIONS.ADD_SOCIAL_CONNECTION.FAILURE:
    case ACTIONS.REFRESH_SOCIAL_CONNETION.FAILURE:
    case ACTIONS.DELETE_SOCIAL_CONNECTION.FAILURE:
      return {
        ...state,
        error: action.payload,
        isFetching: false,
        isSubAccountsPending: false,
        isUpdatingSocialAccount: false,
        isFilteringSocialAccounts: false,
        subAccounts: [],
      };

    case ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS.SUCCESS:
      return produce(state, draft => {
        draft.subAccounts = action.payload.data;
        draft.isSubAccountsPending = false;
        draft.error = undefined;
        draft.isFetching = false;
      });

    case ACTIONS.GET_SOCIAL_CONNECTIONS.SUCCESS:
      return {
        ...state,
        accounts: action.payload,
        error: undefined,
        isFetching: false,
      };

    case ACTIONS.GET_SOCIAL_CONNECTION.SUCCESS:
      return {
        ...state,
        accounts: {
          data: state.accounts.data.length
            ? state.accounts.data.map(e =>
                e.id === action.payload.data.id
                  ? {
                      ...action.payload.data,
                      included: action.payload.included,
                    }
                  : e
              )
            : [{ ...action.payload.data, included: action.payload.included }],
        },
        subAccounts: action.payload.included ? action.payload.included : [],
        error: undefined,
        isFetching: false,
      };

    case ACTIONS.GET_SOCIAL_CONNECTION_SUB_ACCOUNTS_DROPDOWN.SUCCESS:
      return {
        ...state,
        isSubAccountsPending: false,
        dropdown: {
          subAccounts: action.payload.isReFetch
            ? [...state.dropdown.subAccounts, ...action.payload.data]
            : action.payload.data,
          links: action.payload.links,
          id: action.payload.id,
        },
      };

    case ACTIONS.FILTER_SOCIAL_ACCOUNTS.SUCCESS:
      return {
        ...state,
        filteredAccounts: action.payload,
        error: undefined,
        isFilteringSocialAccounts: false,
      };
    case ACTIONS.ADD_SOCIAL_CONNECTION.SUCCESS:
    case ACTIONS.POST_SOCIAL_CONNECTION.SUCCESS:
      return produce(state, draft => {
        const accounts = draft.accounts.data.filter(account => account.id !== action.payload.id);
        draft.accounts.data = [action.payload, ...accounts];
        draft.error = undefined;
        draft.isFetching = false;
      });

    case ACTIONS.DELETE_SOCIAL_CONNECTION.SUCCESS:
      return {
        ...state,
        accounts: { data: [...state.accounts.data.filter(acc => acc.id !== action.payload.id)] },
        dropdown: state.dropdown?.id === action.payload.id ? initialDropdownSubAccountsState : state.dropdown,
        error: undefined,
        isFetching: false,
      };

    case ACTIONS.REFRESH_SOCIAL_CONNETION.SUCCESS:
      return {
        ...state,
        accounts: { data: state.accounts.data.map(e => (e.id === action.payload.id ? action.payload : e)) },
        error: undefined,
        isFetching: false,
      };
    case ACTIONS.PATCH_SOCIAL_FEED.SUCCESS:
      return {
        ...state,
        error: undefined,
        isUpdatingSocialAccount: false,
      };
    default:
      return state;
  }
};

export const selectAccount = (state, id) => state.accounts.data.find(e => e.id === id);

export const getSocialAccounts = state => state?.socialConnections?.accounts?.data;
export const getSubaccounts = state => {
  return state?.socialConnections?.subAccounts;
};

export const selectTints = (state, id) => {
  const account = selectAccount(state, id);
  return account && account.included ? account.included.filter(e => e.type === INCLUDE_TYPES.TINT) : [];
};

export const selectSocialFeeds = (state, id) => {
  const account = selectAccount(state, id);
  return account && account.included ? account.included.filter(e => e.type === INCLUDE_TYPES.SOCIAL_FEED) : [];
};

export const selectSocialFeedsPerTint = (state, id) => {
  const tints = selectTints(state, id);
  const socialFeeds = selectSocialFeeds(state, id);
  if (tints.length === 0 || socialFeeds.length === 0) {
    return [];
  }

  return socialFeeds.reduce((acc, curr) => {
    return !acc[curr.relationships.tint.data.id]
      ? {
          ...acc,
          [curr.relationships.tint.data.id]: [{ id: curr.id }],
        }
      : {
          ...acc,
          [curr.relationships.tint.data.id]: [...acc[curr.relationships.tint.data.id], { id: curr.id }],
        };
  }, {});
};

export const findSocialFeed = (id, socialFeeds) => socialFeeds.find(e => e.id === id);

export const findTint = (tints, id) => tints.find(tint => tint.id === id);

export const filterSocialFeedByType = (type, socialFeeds) => socialFeeds.filter(e => e.attributes.source === type);

export const mapSocialAccountsForDropdown = (list = []) =>
  list?.map(e => ({
    name: e.attributes.name || e.attributes.username,
    value: e.id,
    externalId: e?.attributes?.external_id,
    id: e.id,
    status: e?.attributes?.status,
    source: e?.attributes?.type,
  }));

export const selectSubaccounts = (state, accountId) => {
  const account = state.data.find(item => item.id === accountId);
  return account?.relationships?.subaccounts?.data || [];
};
