import { ACTIONS } from '../actions/account/account.actions';
import { produce } from 'immer';

const initialState = {
  tints: [],
  personalizations: {
    data: [],
  },
  isFetching: false,
  hasError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_TINTS.REQUEST:
    case ACTIONS.GET_TINT.REQUEST:
    case ACTIONS.GET_PERSONALIZATION.REQUEST:
    case ACTIONS.GET_PERSONALIZATIONS.REQUEST:
      return {
        ...state,
        isFetching: true,
        hasError: false,
      };

    case ACTIONS.GET_TINTS.FAILURE:
    case ACTIONS.GET_TINT.FAILURE:
    case ACTIONS.GET_PERSONALIZATIONS.FAILURE:
    case ACTIONS.GET_PERSONALIZATION.FAILURE:
      return {
        ...state,
        err: action.payload,
        isFetching: true,
        hasError: true,
      };

    case ACTIONS.GET_TINTS.SUCCESS:
      return {
        ...state,
        tints: action.payload,

        isFetching: false,
        hasError: false,
      };

    case ACTIONS.GET_TINT.SUCCESS:
      return {
        ...state,
        tint: action.payload,
        isFetching: false,
        hasError: false,
      };

    case ACTIONS.GET_PERSONALIZATIONS.SUCCESS:
      return produce(state, draft => {
        (draft.personalizations = action.payload), (draft.isFetching = false);
        draft.hasError = false;
      });

    case ACTIONS.GET_PERSONALIZATION.SUCCESS:
      return produce(state, draft => {
        draft.personalization = action.payload;
        draft.isFetching = false;
        draft.hasError = false;
      });

    default:
      return state;
  }
};

export const getSlugName = state => state && state?.account?.tint?.attributes?.slug;
export const getCurrentTint = (state, id) =>
  state &&
  state?.account?.tints?.data?.find(el => {
    return el.id === id;
  });
