import React, { Fragment } from 'react';
import './sidebar-define-list.sass';
import PropTypes from 'prop-types';

const TintSidebarDefineList = ({ list }) => {
  return (
    <dl className='sidebar-define-list'>
      {list.map((listElement, i) => {
        if (listElement?.dd?.length)
          return (
            <Fragment key={i}>
              <dt>{listElement?.dt}</dt>
              {listElement?.dd.map((e, i) => (
                <dd key={i}>{e}</dd>
              ))}
            </Fragment>
          );
      })}
    </dl>
  );
};

TintSidebarDefineList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      dt: PropTypes.string,
      dd: PropTypes.array,
    })
  ),
};

export default TintSidebarDefineList;
