import React, { Component } from 'react';
import { EditParagraphStyles } from './edit/edit-paragraph-styles';
import { PageSectionHoc } from '../../../../../../../components/HOC/page-section/page-section.hoc';
import { EditModal } from '../../../../../../../components/edit-modal/edit-modal/edit-modal';
import { EditModalStep } from '../../../../../../../components/edit-modal/edit-modal-step/edit-modal-step';
import './paragraph-section.sass';
import PropTypes from 'prop-types';
import ColorParser from '../../../../../../../services/color-parser/color-parser';

class ParagraphSection extends Component {
  render() {
    return (
      <div
        className={`tint-page-section tint-page-section--paragraph tint-custom-section tint-custom-section--paragraph ${this.props.getSectionClass()}`}
        style={{
          backgroundColor: ColorParser.defaultColor(this.props.data.styles.backgroundColor),
          paddingTop: `${this.props.data.styles.sectionPadding}px`,
          paddingBottom: `${this.props.data.styles.sectionPadding}px`,
        }}>
        {this.props.renderGenericComponents()}

        {this.props.isModalOpen && (
          <EditModal
            nextStep={this.props.nextStep}
            isVisible={this.props.isModalOpen}
            saveData={this.props.onDataSave}
            dismissModal={this.props.dismissModal}
            closeModal={this.props.closeModal}>
            <EditModalStep title='Edit Paragraph'>
              <EditParagraphStyles
                data={this.props.data}
                saveData={this.props.onDataSave}
                dataUpdate={this.props.onContentUpdate}
                styleDataUpdate={this.props.onStylesUpdate}
              />
            </EditModalStep>
          </EditModal>
        )}
      </div>
    );
  }
}

export default PageSectionHoc(ParagraphSection);

ParagraphSection.propTypes = {
  isModalOpen: PropTypes.bool,
  nextStep: PropTypes.func,
  onStylesUpdate: PropTypes.func,
  closeModal: PropTypes.func,
  onDataSave: PropTypes.func,
  dismissModal: PropTypes.func,
  isPreviewMode: PropTypes.bool,

  data: PropTypes.shape({
    styles: PropTypes.shape({
      sectionHeight: PropTypes.string,
      backgroundColor: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    }),
  }),
};
