import React, { Component } from 'react';
import './custom-component.sass';
import PropTypes from 'prop-types';

export class CustomComponent extends Component {
  onClick = () => {
    if (!this.props.isBlocked) {
      this.props.onClick();
      this.props.action();
    }
  };

  render() {
    return (
      <div
        className={`tint-custom-component ${this.props.isBlocked ? 'tint-custom-component--disabled' : ''}`}
        onClick={this.onClick}>
        <span className={`far ${this.props.icon}`} />
        <p className='tint-custom-component__title'>{this.props.title}</p>
      </div>
    );
  }
}

CustomComponent.propTypes = {
  onClick: PropTypes.func,
  action: PropTypes.func,
  icon: PropTypes.string,
  title: PropTypes.string,
  isBlocked: PropTypes.bool,
};
