import { EXPERIENCE_BUILDER } from '../consts';
import { createPrefixedActionName } from '../../../services/functions/redux-routines/redux-routines.js';

export const ACTIONS = {
  SET_PAGE_BUILDER_WIDTH: createPrefixedActionName(EXPERIENCE_BUILDER, 'SET_PAGE_BUILDER_WIDTH'),
  SET_PREVIEW_MODE: createPrefixedActionName(EXPERIENCE_BUILDER, 'SET_PREVIEW_MODE'),
  SET_SELECTED_TEXTAREA: createPrefixedActionName(EXPERIENCE_BUILDER, 'SET_SELECTED_TEXTAREA'),
};

export const setExperienceBuilderWidth = width => ({
  type: ACTIONS.SET_PAGE_BUILDER_WIDTH,
  payload: width,
});

export const setPreviewMode = isPreviewMode => ({
  type: ACTIONS.SET_PREVIEW_MODE,
  payload: isPreviewMode,
});
