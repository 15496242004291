import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { EditInputDropdown, EditUploadDesign } from '../../../';
import EditColorInput from '../../../edit-color-input/edit-color-input';
import './edit-cookie-banner-styles.sass';

const outlineSettings = [
  {
    name: 'None',
    value: false,
  },
  {
    name: 'Yes',
    value: true,
  },
];

export class EditCookieBannerStyles extends Component {
  render() {
    return (
      <EditModalBody>
        <form
          className='edit-cookie-banner'
          noValidate
          onSubmit={e => {
            e.preventDefault();
          }}>
          <div className='edit-cookie-banner__group'>
            <EditColorInput
              color={this.props.data.styles.buttonAllowStyleTextColor}
              label='Accept button text color'
              onChange={value => {
                this.props.styleDataUpdate({ buttonAllowStyleTextColor: value });
              }}
            />
            <EditColorInput
              color={this.props.data.styles.buttonAllowStyleBackground}
              label='Accept button color'
              onChange={value => {
                this.props.styleDataUpdate({ buttonAllowStyleBackground: value });
              }}
            />
            <EditInputDropdown
              label='ACCEPT BUTTON OUTLINE'
              onChange={e => this.props.styleDataUpdate({ buttonAllowStyleOutline: e.target.value })}
              options={outlineSettings}
              defaultValue={
                this.props.data.styles.buttonAllowStyleOutline
                  ? this.props.data.styles.buttonAllowStyleOutline
                  : outlineSettings[0].value
              }
            />
            <EditColorInput
              color={this.props.data.styles.buttonAllowStyleOutlineColor}
              label='Accept button outline color'
              onChange={value => {
                this.props.styleDataUpdate({ buttonAllowStyleOutlineColor: value });
              }}
            />
          </div>
          <div className='edit-cookie-banner__group'>
            <EditColorInput
              color={this.props.data.styles.buttonDismissStyleTextColor}
              label='Dismiss BUTTON TEXT COLOR'
              onChange={value => {
                this.props.styleDataUpdate({ buttonDismissStyleTextColor: value });
              }}
            />
            <EditColorInput
              color={this.props.data.styles.buttonDismissStyleBackground}
              label='Dismiss button color'
              onChange={value => {
                this.props.styleDataUpdate({ buttonDismissStyleBackground: value });
              }}
            />
            <EditInputDropdown
              label='Dismiss BUTTON OUTLINE'
              onChange={e => this.props.styleDataUpdate({ buttonDismissStyleOutline: e.target.value })}
              options={outlineSettings}
              defaultValue={
                this.props.data.styles.buttonDismissStyleOutline
                  ? this.props.data.styles.buttonDismissStyleOutline
                  : outlineSettings[0].value
              }
            />
            <EditColorInput
              color={this.props.data.styles.buttonDismissStyleOutlineColor}
              label='Dismiss button outline color'
              onChange={value => {
                this.props.styleDataUpdate({ buttonDismissStyleOutlineColor: value });
              }}
            />
          </div>

          <div className='edit-cookie-banner__group edit-cookie-banner__group--background'>
            <EditColorInput
              color={this.props.data.styles.bannerTextColor}
              label='Banner Text Color'
              onChange={value => {
                this.props.styleDataUpdate({ bannerTextColor: value });
              }}
            />
            <EditUploadDesign
              isPaddingDisabled={true}
              isImageUploadDisabled={true}
              dataUpdate={this.props.dataUpdate}
              styleDataUpdate={this.props.styleDataUpdate}
              data={this.props.data}
            />
          </div>

          <button style={{ display: 'none' }} type='submit'>
            Submit
          </button>
        </form>
      </EditModalBody>
    );
  }
}
