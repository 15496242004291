import { createPrefixedActionName } from '../../services/functions/redux-routines/redux-routines';
import { FILTERABLE } from '../consts';

export const ACTIONS = {
  SET_FILTERS: createPrefixedActionName(FILTERABLE, 'SET_FILTERS'),
  CLEAR_FILTERS: createPrefixedActionName(FILTERABLE, 'CLEAR_FILTERS'),
};

export const selectFilters = (data, type) => ({
  type: ACTIONS.SET_FILTERS,
  payload: {
    data,
    type,
  },
});

export const clearFilters = () => ({
  type: ACTIONS.CLEAR_FILTERS,
});
