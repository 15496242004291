export const ProductAccounts = {
  FTP: 'ftp',
  SFTP: 'sftp',
  FACEBOOK: 'facebook',
  MAGENTO: 'magento',
  SHOPIFY: 'shopify',
  WOOCOMMERCE: 'woocommerce',
  BIGCOMMERCE: 'bigcommerce',
  SALESFORCE_COMMERCE_CLOUD: 'salesforce_commerce_cloud',
  HTTP: 'http',
  S3: 's3',
  CSV: 'csv',
  CUSTOM: 'custom',
  BAZAARVOICE: 'bazaarvoice',
};
