import React, { Component } from 'react';
import './count-down.sass';
import ColorParser from '../../../../../../../services/color-parser/color-parser';

export class CountDown extends Component {
  constructor(props) {
    super(props);
    this.state = { currentTimer: this.getCountTimeLeft() };
  }

  componentDidMount() {
    this.startTimer();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.endDate !== this.props.endDate) {
      this.startTimer();
    }
  }

  componentWillUnmount() {
    if (this.intervaId) {
      window.clearInterval(this.intervaId);
      this.intervaId = undefined;
    }
  }

  startTimer() {
    this.intervaId = window.setInterval(() => {
      this.setState({ currentTimer: this.getCountTimeLeft() });
    }, 1000);
  }

  stopTimer() {
    window.clearInterval(this.intervaId);
  }

  getCountTimeLeft() {
    const timeLeft = new Date(this.props.endDate).getTime() - Date.now();

    if (timeLeft < 0) {
      this.stopTimer();
      return [{ value: 0 }, { value: 0 }, { value: 0 }, { value: 0 }];
    }

    let seconds = Math.floor(timeLeft / 1000);
    let minutes = Math.floor(seconds / 60);
    let hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    hours = hours - days * 24;
    minutes = minutes - days * 24 * 60 - hours * 60;
    seconds = seconds - days * 24 * 60 * 60 - hours * 60 * 60 - minutes * 60;

    return [{ value: days }, { value: hours }, { value: minutes }, { value: seconds }];
  }

  renderElement(e, title, isLineDeleted) {
    return (
      <div className='tint-page-section--timer__number-container'>
        <span
          className='tint-page-section--timer__number-container__time'
          style={{
            color: ColorParser.defaultColor(this.props.styles.timerTextColor),
          }}>
          {e.value}
          {isLineDeleted ? null : (
            <div
              style={{
                backgroundColor: ColorParser.defaultColor(this.props.styles.dividerColor),
              }}
              className='tint-page-section--timer__number-container__time__line'
            />
          )}
        </span>
        <span
          dir={this.props.textDirection}
          className='tint-page-section--timer__number-container__label'
          style={{
            color: ColorParser.defaultColor(this.props.styles.labelTextColor),
          }}>
          {title}
        </span>
      </div>
    );
  }

  render() {
    const titleDays = this.props.getTranslation(this.props.content.labelDays) || 'days';

    const titleHours = this.props.getTranslation(this.props.content.labelHours) || 'hours';

    const titleMinutes = this.props.getTranslation(this.props.content.labelMinutes) || 'minutes';

    const titleSeconds = this.props.getTranslation(this.props.content.labelSeconds) || 'seconds';

    return (
      <div className='count-down' dir='auto'>
        {this.renderElement(this.state.currentTimer[0], titleDays)}
        {this.renderElement(this.state.currentTimer[1], titleHours)}
        {this.renderElement(this.state.currentTimer[2], titleMinutes)}
        {this.renderElement(this.state.currentTimer[3], titleSeconds, true)}
      </div>
    );
  }
}
