import React, { Component } from 'react';
import { connect } from 'react-redux';
import { setCurrentLanguage } from '../../../feature/experience/actions/languages/languages.actions';
import { getCustomLanguageList } from '../../../feature/experience/reducers/experience/experience';
import { getLanguageDirection } from '../../../services/functions/language/language';

export const URLLanguageParamsHoc = PassedComponent => {
  const queryParamName = 'lang';

  class URLLanguageParams extends Component {
    constructor(props) {
      super(props);
    }

    determineCurrentLanguage = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const langParam = urlParams.get(queryParamName);

      //priorities - 1 url 2 default lang 3 navigator
      if (langParam && this.isInLanguageList(langParam)) {
        this.setCurrentLanguage(langParam);
        return;
      }

      const defaultLanguage = this.getDefaultLanguage();
      if (defaultLanguage) {
        this.setCurrentLanguage(defaultLanguage.value);
      }
    };

    getDefaultLanguage() {
      return this.props.languages.find(lang => lang.isDefault);
    }

    setCurrentLanguage(language) {
      this.props.setCurrentLanguage({
        value: language,
        direction: getLanguageDirection(language),
      });
    }

    isInLanguageList(language) {
      if (Array.isArray(this.props.languages)) {
        return this.props.languages.some(lang => lang.value === language);
      }
    }

    onUpdateUrlParameter = language => {
      const params = new URLSearchParams(window.location.search);
      params.set('lang', language);
      let newRelativePathQuery = window.location.pathname + '?' + params.toString();
      this.setCurrentLanguage(language);
      history.pushState(null, '', newRelativePathQuery);
    };

    componentDidUpdate(prevProps) {
      if (this.props.experience !== prevProps.experience && this.props.languages) {
        this.determineCurrentLanguage();
      }
    }

    render() {
      return (
        <>
          <PassedComponent
            {...this.props}
            updateLanguageQueryParam={this.onUpdateUrlParameter}
            currentLanguage={this.props.currentLanguage}
          />
        </>
      );
    }
  }

  const mapStateToProps = state => ({
    currentLanguage: state.languages.currentLanguage.value,
    languages: getCustomLanguageList(
      state.experience.data,
      state.experience.data && state.experience.data.attributes.data.draft ? 'draft' : 'published'
    ),
  });

  const mapDispatchToProps = dispatch => ({
    setCurrentLanguage: lang => dispatch(setCurrentLanguage(lang)),
  });

  return connect(mapStateToProps, mapDispatchToProps)(URLLanguageParams);
};
