import React, { useRef } from 'react';
import './language-list.sass';
import useDropdownListPosition from '../../../../hooks/use-dropdown-list-position/use-dropdown-list-position';
import PropTypes from 'prop-types';
import { DropdownHeader } from '../../button/header/dropdown-header';
import { Button } from '../../../button/button';
import FeatureGuard from '../../../../../guards/feature-guard';

const LanguageList = ({
  onChange,
  parentRef,
  onChangeTextComponent,
  iconLeft,
  iconRight,
  iconCenter,
  currentItems,
  onAction,
  currentLanguage,
  isAddLanguageButton = true,
  manageLanguageFeatureGuard,
}) => {
  const elementRef = useRef();
  const { positionHorizontal, positionVertical } = useDropdownListPosition(elementRef, parentRef);

  const DropdownTextElement = title => {
    return <DropdownHeader iconLeft={iconLeft} iconRight={iconRight} iconCenter={iconCenter} title={title} />;
  };

  const renderManageLanguageOption = () => {
    return (
      <div className='dropdown-list__item'>
        <Button onClick={onAction} type='info' text='Manage Languages' />
      </div>
    );
  };

  return (
    <div
      className={`dropdown-list dropdown-list--language dropdown-list--${positionHorizontal} dropdown-list--${positionVertical}`}
      ref={elementRef}
      data-testid='dropdown-simple-list'>
      <ul>
        {currentItems.map((item, i) =>
          item.value === currentLanguage ? (
            <li
              className={`dropdown-list__item ${item.value === currentLanguage ? 'active' : ''}`}
              key={i}
              onClick={() => {
                onChange(item);
                onChangeTextComponent(DropdownTextElement(item.name));
              }}>
              {item.value === currentLanguage ? <span className='icon fas fa-check' /> : null}
              <span>{item.name}</span>
              {item.isDefault ? <span className='dropdown-list-default'>Default</span> : null}
            </li>
          ) : null
        )}

        {currentItems.map((item, i) =>
          item.value !== currentLanguage ? (
            <li
              className={`dropdown-list__item ${item.isActive ? 'active' : ''}`}
              key={i}
              onClick={() => {
                onChange(item);
                onChangeTextComponent(DropdownTextElement(item.name));
              }}>
              <span>{item.name}</span>
              {item.isDefault ? <span className='dropdown-list-default'>Default</span> : null}
            </li>
          ) : null
        )}
      </ul>

      <div className='dropdown-list-fixed-section'>
        {isAddLanguageButton && (
          <div className='dropdown-list__item dropdown-list__item--special' onClick={onAction}>
            <span>+ Add Another</span>
          </div>
        )}
        {manageLanguageFeatureGuard ? (
          <FeatureGuard featureName={manageLanguageFeatureGuard}>{renderManageLanguageOption()}</FeatureGuard>
        ) : (
          renderManageLanguageOption()
        )}
      </div>
    </div>
  );
};

export default LanguageList;

LanguageList.propTypes = {
  onChange: PropTypes.func,
  currentItems: PropTypes.array,
  parentRef: PropTypes.any,
  onChangeTextComponent: PropTypes.func,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  iconCenter: PropTypes.string,
};
