import { useRef } from 'react';
import * as PropTypes from 'prop-types';
import { useDrag, useDrop } from 'react-dnd';

const DragAndDropItem = ({
  index,
  onMoveElement,
  onElementDropped,
  onElementDrag,
  dndItemComponent,
  item,
  isVerticalDisplay = true,
  itemType = 'item',
  currentDragClass,
  isAnyElementDragging,
}) => {
  const ref = useRef();
  const [, drop] = useDrop({
    accept: itemType,
    hover(item, monitor) {
      isVerticalDisplay ? verticalDisplay(item, monitor) : horizontalDisplay(item, monitor);
    },
  });

  const horizontalDisplay = (item, monitor) => {
    if (!ref.current) {
      return;
    }

    const dragIndex = item.index;
    const hoverIndex = index;
    const hoverBoundingRect = ref.current.getBoundingClientRect();

    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 4;
    const hoverMiddleX = (hoverBoundingRect.right - hoverBoundingRect.left) / 4;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;
    const hoverClientX = clientOffset.x - hoverBoundingRect.left;

    const upwards = dragIndex > hoverIndex && hoverClientY > hoverMiddleY;
    const downwards = dragIndex < hoverIndex && hoverClientY < hoverMiddleY;
    const leftwards = dragIndex > hoverIndex && hoverClientX > hoverMiddleX;
    const rightwards = dragIndex < hoverIndex && hoverClientX < hoverMiddleX;

    if (upwards && (leftwards || rightwards)) {
      return;
    }

    if (downwards && (leftwards || rightwards)) {
      return;
    }

    onMoveElement(dragIndex, hoverIndex);
    item.index = hoverIndex;
  };

  const verticalDisplay = (item, monitor) => {
    if (!ref.current) {
      return;
    }

    const dragIndex = item.index;
    const hoverIndex = index;

    if (dragIndex === hoverIndex) {
      return;
    }

    const hoverBoundingRect = ref.current.getBoundingClientRect();
    const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 3;
    const clientOffset = monitor.getClientOffset();
    const hoverClientY = clientOffset.y - hoverBoundingRect.top;

    if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
      return;
    }
    if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
      return;
    }

    onMoveElement(dragIndex, hoverIndex);
    item.index = hoverIndex;
  };

  const [{ isDragging, didDrop }, drag] = useDrag({
    item: { type: itemType, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
      didDrop: monitor.didDrop(),
    }),
    begin: () => {
      onElementDrag(index);
    },
    end: () => {
      onElementDropped(index);
    },
  });

  drag(drop(ref));

  const renderItem = () => {
    return dndItemComponent({
      isAnyElementDragging: isAnyElementDragging,
      isDraggable: currentDragClass ? currentDragClass === itemType : true,
      index: index,
      item: item,
      isDragging,
      didDrop,
      componentRef: ref,
    });
  };

  return renderItem();
};

export default DragAndDropItem;

DragAndDropItem.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string,
  index: PropTypes.number,
  onMoveElement: PropTypes.func,
  onElementDropped: PropTypes.func,
  onElementDrag: PropTypes.func,
};
