import React from 'react';
import './icon.sass';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const TintIcon = ({ className, icon, color, svgIconSrc, size, fontSize, style, onClick }) => {
  return (
    <span className={`tint-icon icon ${className}`} style={{ fontSize }} onClick={() => onClick && onClick()}>
      {icon ? (
        <FontAwesomeIcon icon={icon} size={size} style={{ color, ...style }} />
      ) : (
        <img src={svgIconSrc} style={{ ...style }} />
      )}
    </span>
  );
};

export default TintIcon;
