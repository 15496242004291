import React from 'react';
import PropTypes from 'prop-types';

import './edit-error.sass';

const EditError = ({ message, touched }) => {
  return message && touched ? <div className='tint-edit-error'>{message}</div> : null;
};

EditError.propTypes = {
  message: PropTypes.string,
  touched: PropTypes.bool,
};

export default EditError;
