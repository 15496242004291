import { ACTIONS } from '../../actions/profile-dropdown/profile-dropdown.actions';

export const initialState = {
  isPending: false,
  data: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.GET_TEAM_PAYMENTS.SUCCESS:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
