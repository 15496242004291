import React from 'react';
import PropTypes from 'prop-types';
import DropdownButton, { ButtonType } from '../../../../../../components/interface/dropdown/button/dropdown-button';
import { DropdownHeader } from '../../../../../../components/interface/dropdown/button/header/dropdown-header';
import DropdownSortList from '../../../../../../components/interface/dropdown/lists/sort-list/dropdown-sort-list';

export const MarketingIntegrationsDropdownSettingsType = {
  DELETE: 'delete',
};

const MarketingIntegrationsDropdownSettingsList = [
  {
    type: MarketingIntegrationsDropdownSettingsType.DELETE,
    name: 'Delete',
    icon: 'fas fa-trash',
  },
];

export const MarketingIntegrationsSettingsList = ({ marketingIntegration, onRemove }) => {
  const onDropdownClick = item => {
    switch (item.type) {
      case MarketingIntegrationsDropdownSettingsType.DELETE:
        onRemove(item.value);
        break;
      default:
        return;
    }
  };

  return (
    <DropdownButton
      dropdownHeader={props => <DropdownHeader {...props} />}
      dropdownList={props => <DropdownSortList {...props} />}
      list={MarketingIntegrationsDropdownSettingsList.map(item => ({
        ...item,
        value: marketingIntegration.id,
      }))}
      iconCenter='far fa-ellipsis-h'
      buttonType={ButtonType.BUTTON_SORT}
      onChangeValue={onDropdownClick}
    />
  );
};

MarketingIntegrationsSettingsList.propTypes = {
  marketingIntegration: PropTypes.object,
  onRemove: PropTypes.func,
};
