import { ACTIONS } from '../../actions/api/api.actions';
import produce from 'immer';
import { Config } from '../../../../utils/config';

export const initialState = {
  data: [],
  isFetching: false,
  error: undefined,
};

export const webhooks = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.POST_WEBHOOK.REQUEST:
    case ACTIONS.GET_WEBHOOKS.REQUEST:
    case ACTIONS.UPDATE_WEBHOOK.REQUEST:
    case ACTIONS.DELETE_WEBHOOK.REQUEST:
      return {
        ...state,
        ...{
          isFetching: true,
          error: undefined,
        },
      };

    case ACTIONS.DELETE_WEBHOOK.FAILURE:
    case ACTIONS.POST_WEBHOOK.FAILURE:
    case ACTIONS.UPDATE_WEBHOOK.FAILURE:
    case ACTIONS.GET_WEBHOOKS.FAILURE:
      return {
        ...state,
        isFetching: false,
        error: undefined,
      };

    case ACTIONS.GET_WEBHOOKS.SUCCESS:
      return {
        ...state,
        ...{
          data: action.payload.data,
          links: action.payload.links,
          error: undefined,
        },
        isFetching: false,
      };

    case ACTIONS.UPDATE_WEBHOOK.SUCCESS:
      return produce(state, draft => {
        const index = draft.data.findIndex(el => el.id === action.payload.id);
        draft.data[index] = action.payload;
        draft.isFetching = false;
      });

    case ACTIONS.POST_WEBHOOK.SUCCESS:
      return produce(state, draft => {
        const draftData = [...draft.data];
        if (draftData.length > Config.limits.webhooksListMaxElements - 1)
          draftData.length = Config.limits.webhooksListMaxElements - 1;

        draft.data = [action.payload, ...draftData];
        draft.isFetching = false;
      });

    case ACTIONS.DELETE_WEBHOOK.SUCCESS:
      return produce(state, draft => {
        draft.data = draft.data.filter(webhook => webhook.id !== action.payload);
        draft.isFetching = false;
      });

    default:
      return state;
  }
};
