import { ACTIONS } from '../../actions/filters/asset-manager-filters.actions';

export const initialState = {
  sortValue: '',
  searchPhrase: '',
};

export const assetCollectionsFilters = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.SET_SORT_OPTION:
      return {
        ...state,
        ...{
          sortValue: action.payload,
        },
      };

    case ACTIONS.SET_SEARCH_PHRASE:
      return {
        ...state,
        ...{
          searchPhrase: action.payload,
        },
      };

    default:
      return state;
  }
};
