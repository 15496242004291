import React, { Component } from 'react';
import './experience-language-settings.sass';
import { Button } from '../../../../../../components/interface/button/button';
import EditInputDropdown from '../../edit-input-dropdown/edit-input-dropdown';
import EditInput from '../../edit-input/edit-input';
import * as PropTypes from 'prop-types';
import { toast } from 'react-toastify';

export class ExperienceLanguageSettings extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedList: this.props.selectedLanguages,
      currentAddedLanguage: {
        value: undefined,
        name: undefined,
      },
    };
  }

  addLanguage = () => {
    if (this.state.currentAddedLanguage.value) {
      this.setState({
        selectedList: [...this.state.selectedList, { ...this.state.currentAddedLanguage }],
        currentAddedLanguage: {
          value: undefined,
          name: undefined,
          isMarkAsDeleted: false,
        },
      });

      this.props.onAddLanguage({
        value: this.state.currentAddedLanguage.value.includes('_')
          ? this.state.currentAddedLanguage.value.replace('_', '-')
          : this.state.currentAddedLanguage.value,
        name: this.state.currentAddedLanguage.name,
      });
    }
  };

  onLanguageChange = lang => {
    if (!this.isLanguageSelected(lang.value)) {
      this.setState({
        currentAddedLanguage: this.getLanguage(lang.value),
      });
    } else {
      toast.info('You have already added this language');
    }
  };

  isLanguageSelected(lang) {
    return this.state.selectedList.find(language => language.value === lang);
  }

  getLanguage = isoLang => {
    return this.props.languages.find(language => language.value === isoLang);
  };

  onDeleteLanguageClick = (el, index) => {
    if (el.isMarkAsDeleted) {
      this.deleteLanguage(index);
    } else {
      this.markAsDeleted(el, index);
      toast.info('Click again to approve delete language');
    }
  };

  deleteLanguage = index => {
    const selectedList = [...this.state.selectedList.slice(0, index), ...this.state.selectedList.slice(index + 1)];
    this.setState({
      selectedList: selectedList,
    });

    this.props.onDeleteLanguage(index);
  };

  markAsDeleted = (el, index) => {
    const selectedItem = {
      ...this.getLanguage(el.value),
      isMarkAsDeleted: true,
    };

    const currentList = [
      ...this.state.selectedList.slice(0, index),
      selectedItem,
      ...this.state.selectedList.slice(index + 1),
    ];

    this.setState({
      selectedList: currentList,
    });
  };

  renderLanguageList = () => {
    return (
      this.state.selectedList.length > 0 &&
      this.state.selectedList.map((el, i) => (
        <div
          data-testid='selectedLanguageSection'
          className={`language-list__item ${el.isMarkAsDeleted ? 'language-list__item--deleted' : ''}`}
          key={`tint-slug-${i}`}>
          <EditInput
            value={el.name}
            type='text'
            rightIcon='fas fa-trash'
            readOnly={true}
            isRightIconVisible={this.isAbleToDelete(el)}
            rightIconAction={() => {
              this.isAbleToDelete(el) ? this.onDeleteLanguageClick(el, i) : undefined;
            }}
          />
        </div>
      ))
    );
  };

  isAbleToDelete = el => el.value !== this.props.defaultLanguage && el.value !== this.props.currentLanguage;

  render() {
    return (
      <div className='tint-experience-language-settings'>
        <div>
          <span className='tint-edit-modal-body__description'>
            Translate your page into multiple languages. Add and remove languages below.
          </span>

          <div className='language-list'>{this.renderLanguageList()}</div>

          <EditInputDropdown
            isControlled={true}
            value={this.state.currentAddedLanguage.value}
            id={'languageDropdown'}
            placeholder={'Select Language...'}
            onChange={e => {
              const getCurrentLang = this.props.languages.find(language => language.value === e.target.value);
              this.onLanguageChange(getCurrentLang);
            }}
            options={this.props.languages}
          />

          <Button
            id='addLanguageButton'
            onClick={this.addLanguage}
            type='upload'
            size='full-width'
            text='Add Language'
          />
        </div>
        <div>
          <EditInputDropdown
            id={'defaultLanguageDropdown'}
            value={this.props.defaultLanguage}
            isControlled={true}
            placeholder={'Select Language...'}
            onChange={e => {
              const lang = e.target.value.replace('-', '_');
              const getCurrentLang = this.props.languages.find(language => language.value === lang);
              this.props.onDefaultLanguageChange(getCurrentLang);
            }}
            options={this.props.selectedLanguages}
            label={'Default Language'}
          />

          <span className='tint-edit-modal-body__description'>{this.props.description}</span>
        </div>
      </div>
    );
  }
}

ExperienceLanguageSettings.propTypes = {
  onDefaultLanguageChange: PropTypes.func,
  onAddLanguage: PropTypes.func,
  onDeleteLanguage: PropTypes.func,
  languages: PropTypes.array,
  selectedLanguages: PropTypes.array,
  defaultLanguage: PropTypes.string,
  currentLanguage: PropTypes.object | PropTypes.string,
};
