import { ACTIONS } from '../../actions/collaborators/collaborators-sidebar.actions';

export const initialState = {
  data: undefined,
  type: undefined,
  isOpen: false,
  isEditMode: false,
};

export const collaboratorsSidebar = (state = initialState, action) => {
  switch (action.type) {
    case ACTIONS.OPEN_SIDEBAR:
      return {
        ...state,
        type: action.payload,
        isOpen: true,
        isEditMode: true,
      };

    case ACTIONS.SET_PENDING_COLLABORATOR:
      return {
        ...state,
        ...{
          data: { ...state.data, ...action.payload },
          isEditMode: true,
        },
      };

    case ACTIONS.CLEAR_PENDING_COLLABORATOR:
      return {
        ...state,
        ...{
          data: undefined,
          isEditMode: false,
        },
      };

    case ACTIONS.CLOSE_SIDEBAR:
      return {
        ...state,
        type: undefined,
        isOpen: false,
      };
    default:
      return state;
  }
};
