import React, { Component } from 'react';
import { EditModalBody } from '../../../../../../../components/edit-modal/edit-modal-body/edit-modal-body';
import { EditInput } from '../../../';
import { Button } from '../../../../../../../components/interface/button/button';
import { FileStack } from '../../../../../../../services/filestack/file-stack';
import './edit-footer-content.sass';
import { createTranslationKey } from '../../../../../containers/experience/translation-creator';

export class EditFooterContent extends Component {
  componentWillMount() {
    this.footerLinksTmp = JSON.parse(JSON.stringify(this.props.data.footerLinks));
  }

  onLogoButtonClick() {
    const fileStack = new FileStack();
    fileStack.openFilePicker({
      accept: ['image/*'],
      maxSize: this.props.maxFileSize,
      onUploadDone: res => {
        if (res.filesUploaded.length > 0) {
          this.props.dataUpdate({ logoUrl: res.filesUploaded[0].url });
        }
      },
    });
  }

  renderViewWithLogo() {
    return (
      <div className='tint-edit-header-content tint-edit-header-content__center'>
        <img src={this.props.data.logoUrl} />
        <Button
          onClick={() => {
            this.props.dataUpdate({ logoUrl: '' });
          }}
          type='gray'
          size='full-width'
          text='Remove Logo'
        />
        <EditInput
          label='logo link'
          placeholder='Enter Url'
          defaultValue={this.props.data.logoRedirectUrl}
          onChange={e => {
            this.props.dataUpdate({ logoRedirectUrl: e.target.value });
          }}
        />
        {this.props.cookieEnabled ? (
          <EditInput
            label='Manage Cookies Label'
            placeholder='Cookies label'
            defaultValue={this.props.data.manageCookiesLabel}
            onChange={e => {
              this.props.dataUpdate({ manageCookiesLabel: e.target.value });
            }}
          />
        ) : null}
      </div>
    );
  }

  renderViewWithoutLogo() {
    return (
      <div className='tint-edit-header-content'>
        <Button
          onClick={this.onLogoButtonClick.bind(this)}
          type='gray'
          size='full-width'
          text='Upload Logo'
          icon='far fa-arrow-to-top'
        />
      </div>
    );
  }

  removeFormField(footerLinkIndex) {
    this.footerLinksTmp = this.footerLinksTmp.filter(
      (footerLink, _index, array) => array.indexOf(footerLink) !== footerLinkIndex
    );
    this.props.dataUpdate({ footerLinks: this.footerLinksTmp });
  }

  renderEditInput() {
    return this.props.data.footerLinks.map((e, i) => {
      return (
        <div key={i}>
          <EditInput
            onChange={event => {
              this.props.onTranslationUpdate(e.text, event.target.value);
            }}
            label={`Footer LINK ${i + 1}`}
            placeholder='Enter Text'
            rightIcon='fas fa-trash'
            rightIconAction={() => this.removeFormField(i)}
            defaultValue={this.props.getTranslation(e.text)}
          />
          <EditInput
            onChange={event => {
              this.props.onTranslationUpdate(e.url, event.target.value);
            }}
            placeholder='Enter URL'
            defaultValue={this.props.getTranslation(e.url)}
          />
        </div>
      );
    });
  }

  render() {
    return (
      <EditModalBody title='Upload an image below to add it to this block on your page.'>
        <form
          noValidate
          onSubmit={e => {
            e.preventDefault();
            this.props.saveData();
          }}>
          {this.props.data.logoUrl ? this.renderViewWithLogo() : this.renderViewWithoutLogo()}
          <EditInput
            onChange={e => {
              this.props.onTranslationUpdate(this.props.data.footerCopyright, e.target.value);
            }}
            placeholder='Enter Text'
            label='footer copyright'
            defaultValue={this.props.getTranslation(this.props.data.footerCopyright)}
          />
          {this.renderEditInput()}
          <Button
            onClick={e => {
              e.preventDefault();
              const footerLinkKey = createTranslationKey(
                this.props.experienceType,
                'FOOTER',
                'LINK',
                this.props.data.footerLinks.length
              );
              const footerTextKey = createTranslationKey(
                this.props.experienceType,
                'FOOTER',
                'TEXT',
                this.props.data.footerLinks.length
              );
              this.footerLinksTmp.push({
                url: footerLinkKey,
                text: footerTextKey,
              });

              this.props.onNewTranslationUpdate(footerLinkKey, '');
              this.props.onNewTranslationUpdate(footerTextKey, 'Footer link text');
              this.props.dataUpdate({ footerLinks: this.footerLinksTmp });
            }}
            type='gray'
            size='full-width'
            text='Add Footer Link'
          />
          <button style={{ display: 'none' }} type='submit'>
            Submit
          </button>
        </form>
      </EditModalBody>
    );
  }
}
