export const languageList = [
  { name: 'English', value: 'en' },
  { name: 'English (Shortened)', value: 'en-short' },
  { name: 'Arabic', value: 'ar' },
  { name: 'Chinese (Simplified)', value: 'zh-CN' },
  { name: 'Chinese (Traditional)', value: 'zh-TW' },
  { name: 'Danish', value: 'da' },
  { name: 'Dutch', value: 'nl' },
  { name: 'German', value: 'de' },
  { name: 'German (Shortened)', value: 'de-short' },
  { name: 'Finnish', value: 'fi' },
  { name: 'French', value: 'fr' },
  { name: 'French (Shortened)', value: 'fr-short' },
  { name: 'Japanese', value: 'ja' },
  { name: 'Norwegian', value: 'no' },
  { name: 'Polish', value: 'pl' },
  { name: 'Portuguese', value: 'pt' },
  { name: 'Portuguese (Shortened)', value: 'pt-short' },
  { name: 'Swedish', value: 'sv' },
  { name: 'Spanish', value: 'es' },
  { name: 'Spanish (Shortened)', value: 'es-short' },
];

export default {
  languageList,
};
