import { SOCIAL_FEEDS } from '../consts';
import {
  createPrefixedActionName,
  createRoutine,
} from '../../../../../../../services/functions/redux-routines/redux-routines';

export const ACTIONS = {
  CLEAR_STATE: createPrefixedActionName(SOCIAL_FEEDS, 'CLEAR_STATE'),
  CLEAR_SOCIAL_FEED_FORM_STATE: createPrefixedActionName(SOCIAL_FEEDS, 'CLEAR_SOCIAL_FEED_FORM_STATE'),
  POST_SOCIAL_FEED: createRoutine(createPrefixedActionName(SOCIAL_FEEDS, 'POST_SOCIAL_FEED')),
  PATCH_SOCIAL_FEED: createRoutine(createPrefixedActionName(SOCIAL_FEEDS, 'PATCH_SOCIAL_FEED')),
  REMOVE_SOCIAL_FEED: createRoutine(createPrefixedActionName(SOCIAL_FEEDS, 'REMOVE_SOCIAL_FEED')),
  GET_SOCIAL_FEEDS: createRoutine(createPrefixedActionName(SOCIAL_FEEDS, 'GET_SOCIAL_FEEDS')),
  GET_EXPERIENCES: createRoutine(createPrefixedActionName(SOCIAL_FEEDS, 'GET_EXPERIENCES')),
  GET_EXPERIENCE: createRoutine(createPrefixedActionName(SOCIAL_FEEDS, 'GET_EXPERIENCE')),
  GET_FORM: createRoutine(createPrefixedActionName(SOCIAL_FEEDS, 'GET_FORM')),
  GET_FORMS: createRoutine(createPrefixedActionName(SOCIAL_FEEDS, 'GET_FORMS')),
  GET_MISSIONS: createRoutine(createPrefixedActionName(SOCIAL_FEEDS, 'GET_MISSIONS')),
};

export const clearSocialFeedState = () => ({
  type: ACTIONS.CLEAR_STATE,
});

export const clearSocialFeedFormState = () => ({
  type: ACTIONS.CLEAR_SOCIAL_FEED_FORM_STATE,
});

export const postSocialFeedStart = () => ({
  type: ACTIONS.POST_SOCIAL_FEED.REQUEST,
});

export const postSocialFeedSuccess = data => ({
  type: ACTIONS.POST_SOCIAL_FEED.SUCCESS,
  payload: data,
});

export const postSocialFeedError = err => ({
  type: ACTIONS.POST_SOCIAL_FEED.FAILURE,
  payload: err,
});

export const patchSocialFeedStart = () => ({
  type: ACTIONS.PATCH_SOCIAL_FEED.REQUEST,
});

export const patchSocialFeedSuccess = data => ({
  type: ACTIONS.PATCH_SOCIAL_FEED.SUCCESS,
  payload: data,
});

export const patchSocialFeedError = err => ({
  type: ACTIONS.PATCH_SOCIAL_FEED.FAILURE,
  payload: err,
});

export const deleteSocialFeedStart = () => ({
  type: ACTIONS.REMOVE_SOCIAL_FEED.REQUEST,
});

export const deleteSocialFeedSuccess = id => ({
  type: ACTIONS.REMOVE_SOCIAL_FEED.SUCCESS,
  payload: id,
});

export const deleteSocialFeedError = err => ({
  type: ACTIONS.REMOVE_SOCIAL_FEED.FAILURE,
  payload: err,
});

export const getSocialFeedsStart = () => ({
  type: ACTIONS.GET_SOCIAL_FEEDS.REQUEST,
});

export const getSocialFeedsSuccess = data => ({
  type: ACTIONS.GET_SOCIAL_FEEDS.SUCCESS,
  payload: data,
});

export const getSocialFeedsError = err => ({
  type: ACTIONS.GET_SOCIAL_FEEDS.FAILURE,
  payload: err,
});

export const getFormsStart = () => ({
  type: ACTIONS.GET_FORMS.REQUEST,
});

export const getFormsSuccess = data => ({
  type: ACTIONS.GET_FORMS.SUCCESS,
  payload: data,
});

export const getFormsError = err => ({
  type: ACTIONS.GET_FORMS.FAILURE,
  payload: err,
});

export const getFormStart = () => ({
  type: ACTIONS.GET_FORM.REQUEST,
});

export const getFormSuccess = data => ({
  type: ACTIONS.GET_FORM.SUCCESS,
  payload: data,
});

export const getFormError = err => ({
  type: ACTIONS.GET_FORM.FAILURE,
  payload: err,
});

export const getExperiencesStart = () => ({
  type: ACTIONS.GET_EXPERIENCES.REQUEST,
});

export const getExperiencesSuccess = data => ({
  type: ACTIONS.GET_EXPERIENCES.SUCCESS,
  payload: data,
});

export const getExperiencesError = err => ({
  type: ACTIONS.GET_EXPERIENCES.FAILURE,
  payload: err,
});

export const getExperienceStart = () => ({
  type: ACTIONS.GET_EXPERIENCE.REQUEST,
});

export const getExperienceSuccess = data => ({
  type: ACTIONS.GET_EXPERIENCE.SUCCESS,
  payload: data,
});

export const getExperienceError = err => ({
  type: ACTIONS.GET_EXPERIENCE.FAILURE,
  payload: err,
});

export const getMissionsStart = () => ({
  type: ACTIONS.GET_MISSIONS.REQUEST,
});

export const getMissionsSuccess = data => ({
  type: ACTIONS.GET_MISSIONS.SUCCESS,
  payload: data,
});

export const getMissionsError = err => ({
  type: ACTIONS.GET_MISSIONS.FAILURE,
  payload: err,
});
